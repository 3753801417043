<div class="col-12" role="document">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{payee }} <span *ngIf="payee_name"> ( Name : {{ payee_name }} )</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="ngbActiveModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <form novalidate #payForm="ngForm" (ngSubmit)="payPurchaseOrder()">
    <div class="row modal-body">
      <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
        <label class="col-form-label ">{{field_name}} Method</label>
        <div class="">
          <ng-select (change)="setPaymentAccountHeaderConfig()" [items]="payment_methodList" name="payment_method"
            required #payment_method="ngModel" [(ngModel)]="payment.payment_method">
            <ng-template ng-label-tmp let-item="item">
              {{item.name}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              {{item.name}}
            </ng-template>
          </ng-select>

        </div>
      </div>

      <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 required">
        <label class="col-form-label ">{{payment?.payment_method?.account_head==1031? 'Cash Accounts': 'Bank
          Accounts'}}</label>
        <app-ledgers required (onSelectLedger)="updatePaymentAccountLedger($event)"
          [ledgerAccountsHeads]="paymentAccountConfig">
        </app-ledgers>

      </div>

      <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3" *ngIf="payType == 'multiple' ">
        <label for="total_amount">Amount</label>
        <input *ngIf="payType == 'multiple' " type="number" [min]="1" [max]="balance_amt" required class="form-control"
          name="total_amount" [(ngModel)]="payment.total_payment_amount" />

      </div>


      <div class="row" *ngIf="(payee != 'Expense')">
        <div class="form-group required col-12" *ngIf="payType == 'single'">
          <label for="total_amount"> Amount : {{payment.total_payment_amount|currency:currency}} / Balance :
            {{balance_amount|currency:currency}}</label>
          <input type="number" [min]="0" (keyup)="changeBalanceAmount($event.target.value)"
            [(ngModel)]="payment.paying_amount" name="paying_amount" class="form-control">
        </div>
      </div>

      <div class="form-group required  col-4">
        <label for="due_date">{{field_name}} Date</label>
        <div class="input-group">
          <input class="form-control" (click)="dp_duedate.toggleCalendar()" name="due_date" #due_date="ngModel" required
            placeholder="Select a date" angular-mydatepicker #dp_duedate="angular-mydatepicker" [(ngModel)]="tx_date"
            [options]="dueDateOptions" />
          <div class="input-group-append">
            <button type="button" class="btn btn-secondary" *ngIf="tx_date" (click)="dp_duedate.clearDate()">
              <i class="fa fa-close"></i>
            </button>
          </div>
          <div class="input-group-append">
            <button type="button" class="btn btn-secondary" (click)="dp_duedate.toggleCalendar()">
              <i class="fa fa-calendar-o"></i>
            </button>
          </div>
        </div>
        <app-validation-errors [mformField]="due_date" label="Due Date">
        </app-validation-errors>
      </div>
      <div *ngIf="selectedarray_data.hide_advance_div" class="form-group  col-4">
        <label for="ref_id">Ref Id</label>
        <div class="input-group">
          <input type="text" class="form-control" name="ref_id" placeholder="Reference ID" [(ngModel)]="ref_id" />
        </div>

      </div>
      <div *ngIf="selectedarray_data.hide_advance_div" class="form-group  col-4">
        <label for="voucher_number">Voucher Number</label>
        <div class="input-group">
          <input type="text" class="form-control" name="voucher_number" placeholder="Voucher Number"
            [(ngModel)]="voucher_number" />
        </div>

      </div>
      <div
        *ngIf="(selectedarray_data['payment_from']=='Sales' ||  selectedarray_data['payment_from']=='Purchase') && selectedarray_data.hide_advance_div ==true">
        <div class="form-group col-12 custom-switch pl-5">
          <input type="checkbox" value="advance" (change)="check_adv_status($event.target.checked)"
            [(ngModel)]="advance" name="advance" class="custom-control-input" id="advance">
          <label class="custom-control-label" for="advance">Do you need to include advance amount ?</label>
        </div>
        <div class="form-group col-12 custom-switch pl-5">
          <input type="checkbox" value="debi_or_credit" (change)="check_debit_status($event.target.checked)"
            [(ngModel)]="debi_or_credit" name="debi_or_credit" class="custom-control-input" id="debi_or_credit">
          <label class="custom-control-label" for="debi_or_credit">Do you need to include Debit/Credit Note amount
            ?</label>
        </div>
      </div>
      <!-- <div class="form-row"> -->
      <div class="row modal-body">
        <!-- <div class="row justify-content-between"> -->
        <!-- <div class="form-group col-sm-4 col-md-4 col-xl-12"> -->
        <div class="col-5">
          <div class="form-group col-12 mt-3">
            <label for="payment_note">Notes</label>
            <textarea class="form-control" placeholder="notes" name="payment_note"
              [(ngModel)]="payment.payment_note"></textarea>
          </div>
        </div>
        <div class="col-2"></div>
        <div class="col-5 float-right">
          <table class="table" width="100%">
            <tbody>
              <tr
                *ngIf="(payee == 'Purchase' || payee == 'Sales') && selectedarray_data.hide_advance_div ==true && advance">
                <th><b>Advance Amount</b></th>
                <td class="money">{{payment.total_adv_amount|currency:currency}}</td>
              </tr>
              <tr
                *ngIf="(payee == 'Purchase' || payee == 'Sales') && selectedarray_data.hide_advance_div ==true && debi_or_credit">
                <th><b>Debit/Credit Amount</b></th>
                <td class="money">{{payment.total_debit_credit_amount|currency:currency}}</td>
              </tr>
              <tr>
                <th><b>Paid Amount</b></th>
                <td class="money">{{payment.paying_amount|currency:currency}}</td>
              </tr>
              <tr>
                <th><b>Total Paid</b></th>
                <td class="money">{{payment.paying_amount + payment.total_debit_credit_amount +
                  payment.total_adv_amount|currency:currency}}</td>
              </tr>
              <tr>
                <th><b>Balance Amount</b></th>
                <td class="money">{{balance_amount|currency:currency}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <div class="form-group col-3 mt-3 ml-6">
      <div *ngIf="(payee == 'Purchase' || payee == 'Sales') && selectedarray_data.hide_advance_div ==true">
          <label>Total Advance Amount :{{payment.total_adv_amount}} </label>
        </div>
      </div> -->
        <!-- <div class="form-group col-3 mt-3">
          <div *ngIf="(payee == 'Purchase' || payee == 'Sales') && selectedarray_data.hide_advance_div ==true">
          <label>Total Debit/Credit Amount :{{payment.total_debit_credit_amount}}</label>
        </div>
      </div> -->
        <!-- <div class="form-group col-3 mt-3">
        <label>Total Paying Amount : {{payment.paying_amount|currency:currency}}</label>
      </div> -->
        <!-- <div class="form-group col-3 mt-3">
        {{payment.paying_amount + payment.total_payment_amount}}
        <label>Total Payment Amount : {{payment.paying_amount + payment.total_payment_amount|currency:currency}}</label>
      </div> -->
        <!-- <div class="form-group col-3 mt-3">
        <label>Balance Amount : {{balance_amount|currency:currency}}</label>
      </div> -->
        <!-- </div> -->
        <!-- </div> -->
      </div>
      <!-- {{payment.total_payment_amount|currency:currency}} / Balance : {{balance_amount|currency:currency}} -->
      <!-- <div class="row" *ngIf="(payee == 'Purchase' || payee == 'Sales') && selectedarray_data.hide_advance_div ==true">
        <div class="form-group col-6">
          <label>Total Advance Amount :{{payment.total_adv_amount}} </label>
        </div>
        <div class="form-group col-6">
          <label>Total Debit/Credit Amount :{{payment.total_debit_credit_amount}}</label>
        </div>
      </div> -->
      <table *ngIf="payType == 'multiple'" class="table-bordered table ">
        <tr align="center">
          <th>Purchase-ID</th>
          <th>PO Date</th>
          <th>To be paid</th>
          <th>Pay</th>
        </tr>
        <tbody>
          <tr *ngFor="let item of payment_array; let i = index">
            <td>{{ item?.order_number }}</td>
            <td>{{ item?.order_date | date }}</td>
            <td>{{ item?.amount }}</td>
            <td>
              <input class="form-control" required type="number" #payment_amount="ngModel" [min]="1" [max]="item.amount"
                name="payment_amount{{ i }}" [(ngModel)]="item.to_be_paid" />
              <app-validation-errors [mformField]="payment_amount" label="Pay Amount">
              </app-validation-errors>
            </td>
          </tr>
        </tbody>
      </table>

      <table *ngIf="advance" class="table-bordered table ">
        <tr align="center">
          <th></th>
          <th>Advance-Description</th>
          <th>Advance Date</th>
          <th>Amount</th>
        </tr>
        <tbody>
          <tr *ngFor="let item of adv_payment_array; let i = index">
            <td><input type="checkbox" (change)="selectedAdvance(item,i ,$event.target.checked)" required
                #checkbox_advance_select="ngModel" name="checkbox_advance_select{{ i }}"
                [(ngModel)]="item.advance_selected" />
            </td>
            <td>{{ item?.description }}</td>
            <td>{{ item?.payment_date | date }}</td>
            <td>
              <input class="form-control" [disabled]="!item.advance_selected" (keyup)="selectedAdvance(item,i,2)"
                type="number" #advance_amount="ngModel" [min]="1" [max]="item.advance_amount"
                name="advance_amount{{ i }}" [(ngModel)]="item.advance_amount" />

            </td>
          </tr>
        </tbody>
      </table>
      <table *ngIf="debi_or_credit && selectedarray_data.hide_advance_div" class="table table-bordered">
        <tr align="center">
          <th></th>
          <th>Debit/Credit ID</th>
          <th>Note</th>
          <th>Debit/Credit Date</th>
          <th>Amount</th>
        </tr>
        <tbody>
          <tr *ngFor="let value of debit_payment_array; let i = index">
            <td><input type="checkbox" (change)="selectedDebitCredit(value,i ,$event.target.checked)"
                #checkbox_debit_credit_select="ngModel" name="checkbox_debit_credit_select{{i}}"
                [(ngModel)]="value.checkbox_debit_credit_select" />
            </td>
            <td *ngIf="is_credit_or_debit=='credit'">{{ value?.credit_number }}</td>
            <td *ngIf="is_credit_or_debit=='debit'">{{ value?.debit_number }}</td>
            <td>{{ value?.description }}</td>
            <td *ngIf="is_credit_or_debit=='credit'">{{ value?.credit_date | date }}</td>
            <td *ngIf="is_credit_or_debit=='debit'">{{ value?.debit_date | date }}</td>
            <td>
              <input class="form-control" [disabled]="!value.checkbox_debit_credit_select"
                (keyup)="selectedDebitCredit(value,i,2)" type="number" #debit_credit_amount="ngModel" [min]="1"
                [max]="value.balance_amount" name="debit_credit_amount{{ i }}" [(ngModel)]="value.balance_amount" />

            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer" *ngIf="close_pay">
      <div *ngIf="payee == 'Sales'" class="col-9 float-left">
        <small>* Balance Amount Will be Added as Sales Advance</small>
      </div>
      <button type="button" class="btn btn-danger" (click)="ngbActiveModal.dismiss('Cross click')">Cancel
      </button>
      <button type="submit" class="btn btn-primary"
        [disabled]="!payForm.valid || loading==true">{{selectedarray_data["payment_from"] == 'Sales' ||
        selectedarray_data["payment_from"] == 'Receipt' ? 'Receive' : 'Pay'}}
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
      </button>

    </div>

  </form>
</div>