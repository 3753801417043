<div class="col-12" role="document">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Advance Mapping
    </h4>
    <!-- <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button> -->
  </div>

  <form novalidate #payForm="ngForm" (ngSubmit)="payBill()">
    <div class="row modal-body">


      <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
        <label class="col-form-label ">Payment Method</label>
        <div class="">
          <ng-select (change)="setPaymentAccountHeaderConfig()" [items]="payment_methodList" name="payment_method"
            #payment_method="ngModel" [(ngModel)]="payment.payment_method">
            <ng-template ng-label-tmp let-item="item">
              {{item.name}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              {{item.name}}
            </ng-template>
          </ng-select>
          <app-validation-errors [mformField]="payment_method" label="Payment Method">
          </app-validation-errors>
        </div>
      </div>

      <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 required">
        <label
          class="col-form-label ">{{payment?.payment_method?.account_head==1031? 'Cash Accounts': 'Bank Accounts'}}</label>
        <app-ledgers (onSelectLedger)="updatePaymentAccountLedger($event)" [ledgerAccountsHeads]="paymentAccountConfig">
        </app-ledgers>
      </div>


      <div class="form-group col-4">
        <label for="total_amount">Amount</label>
        <input type="number" disabled #total_amount="ngModel" required class="form-control" name="total_amount"
          [(ngModel)]="advance_amount" />

      </div>
      <div class="form-group  col-6">
        <label for="due_date">Transaction Date</label>
        <div class="input-group">
          <input class="form-control" name="due_date" #due_date="ngModel" required placeholder="Select a date"
            angular-mydatepicker #dp_duedate="angular-mydatepicker" [(ngModel)]="tx_date" [options]="dueDateOptions" />
          <div class="input-group-append">
            <button type="button" class="btn btn-secondary" *ngIf="tx_date" (click)="dp_duedate.clearDate()">
              <i class="fa fa-close"></i>
            </button>
          </div>
          <div class="input-group-append">
            <button type="button" class="btn btn-secondary" (click)="dp_duedate.toggleCalendar()">
              <i class="fa fa-calendar-o"></i>
            </button>
          </div>
        </div>
        <app-validation-errors [mformField]="due_date" label="Due Date">
        </app-validation-errors>
      </div>

      <div class="form-group col-12">
        <label for="payment_note">Notes</label>
        <input type="text" class="form-control" placeholder="notes" name="payment_note" [(ngModel)]="payment_note" />
      </div>
      <table class="table-bordered table ">
        <tr align="center">
          <th>Bill/Invoice Number</th>
          <th>Amount</th>
        </tr>
        <tbody>
          <tr *ngFor="let item of payment_array; let i = index">
            <td>{{ item?.bill_number }}</td>
            <td>
              <input class="form-control" disabled required type="number" #payment_amount="ngModel"
                name="advance_to_be_paid{{ i }}" [(ngModel)]="item.bill_amount" />


          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary">Pay</button>
    </div>
  </form>
</div>
