import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import swal from 'sweetalert2';
import { JournalService } from '../../_services/journal.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthenticationService } from '../../_services/authentication.service';
@Component({
  selector: 'app-stock-journal',
  templateUrl: './stock-journal.component.html',
  styleUrls: ['./stock-journal.component.scss']
})
export class StockJournalComponent implements OnInit {
  filters: any = {};
  loadingIndicator = true;
  reorderable = true;
  journal: any = {};
  journalList: any = [];
  journalListColumns: any = [];
  page: any = { offset: 0, limit: 10 };
  sort: any = { journal_date: 1 };
  dt_default_sort = [{ prop: "journal_no", dir: "asc" }];
  company_id: any;
  branch_id: any;
  currency: string;
  columns: any = {};
  // @ViewChild('actionTmpl', { static: true }) actionTmpl: TemplateRef<any>;
  @ViewChild('myTable', { static: true }) table: any;
  companyDetails: any;
  selectFiYear: any;
  constructor(
    private journalservice: JournalService,
    private authenticationService: AuthenticationService,

  ) { }


  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.companyDetails = this.authenticationService.getCompanyDetails();
    this.currency = this.companyDetails.base_currency;


    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];
    this.filters.journal_date = {
      isRange: true,
      singleDate: null,
      dateRange: {
        beginDate: { year: this.selectFiYear.start_year, month: 4, day: 1 },
        beginJsDate: new Date(this.selectFiYear.start_year, 3, 1),
        endDate: { year: this.selectFiYear.end_year, month: 3, day: 31 },
        endJsDate: new Date(this.selectFiYear.end_year, 2, 31),
      }
    };
    this.currency = this.authenticationService.getCompanyDetails()[
      "base_currency"
    ];
    this.journalListColumns = [
      {

          name: "Journal Date",
          prop: "journal_date",
          sortable: "false",
          flexGrow: 2,
          type: 'date',
          show: true,
          is_detailed_row: false

      },
      {
        name: "Journal ID",
        prop: "journal_no",
        sortable: "journal_no",
        flexGrow: 2,
        show: true,
        field_name: "journal_no",
        is_detailed_row: false
      },
      // {
      //   name: "Total Amount",
      //   prop: "amount",
      //   sortable: "amount",
      //   flexGrow: 2,
      //   show: true,
      //   field_name: "amount",
      //   is_detailed_row: false
      // },
      {
        name: "Action",
        type: "action",
        prop: "_id",
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false
      }
      ];
      this.journalListColumns.forEach((column) => {
        this.columns[column.prop] = 1;
      });
    this.getJournalsList(this.page);
  }
  getJournalsList(page_info: any) {
    if (page_info != null) {
      this.page = page_info;
    }
    this.filters["company_id"] = this.company_id;
    this.filters["tx_type_id"] = 101;
    this.columns['journal_items'] = 1;
    // if (this.branch_id != '') {
    //   this.filters["branch_id"] = this.branch_id;
    // }
    this.journalservice.getJournals(this.filters, this.columns, this.sort, this.page).subscribe(result => {
      this.loadingIndicator = false;
      this.journalList = result['data'];
      this.page.count = result['count'];
    });
  }
  onSort(event) {
    this.loadingIndicator = true;
    this.sort = {};
    let sort_details = event.sorts[0];
    this.sort[sort_details.prop] = sort_details.dir == "asc" ? 1 : -1;
    this.getJournalsList(this.page);
  }
  toggleExpandRow(row) {
    // console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }
  onDetailToggle(event) {
    // console.log('event', event)
  }
  clearJournalFilters() {
    this.journal = {};
    this.getJournalsList({});
  }
  deleteJournal(muid, tx_type_id) {
    tx_type_id = 101;
    swal({
      title: 'Are you sure want to delete?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result) {
        this.journalservice.deleteJournal(muid, tx_type_id).subscribe(result => {
          if (result['ok'] === 1) {
            swal('Deleted!', 'Journal has been deleted.', 'success');
            this.getJournalsList({});
          }
        });
      }
    });
  }
}
