import {
  Component,
  OnInit,
  ChangeDetectorRef,
  EventEmitter,
} from "@angular/core";
import { Salesorder } from "../../../_models/salesorder";
import { SalesOrderItem } from "../../../_models/salesorder-items";
import { SalesorderService } from "../../../_services/salesorder.service";
import { MastersService } from "../../../_services/masters.service";
import { CustomerService } from "../../../_services/customer.service";
import { CompanyService } from "../../../_services/company.service";
import { ProductService } from "../../../_services/product.service";
import { AuthenticationService } from "../../../_services/authentication.service";
import { SequenceService } from "../../../_services/sequence.service";
import { HsnService } from "../../../_services/hsn.service";
import swal from "sweetalert2";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { TransactionService } from "../../../_services/transaction.service";
import { ConstantService } from "../../../_config/constants";
import { ContactAddress } from "../../../_models/contact_address.model";
// import { ReverseStringPipe } from "../../../reverse-string.pipe";
import {
  AddressTemplate,
  AddressTemplateEmitConfig,
} from "../../../_models/address_template.model";
import { differenceInDays } from "date-fns";
@Component({
  selector: "app-sales-order-add",
  templateUrl: "./sales-order-add.component.html",
  styleUrls: ["./sales-order-add.component.scss"],
})
export class SalesOrderAddComponent implements OnInit {
  loading:boolean =false;
  customer: any;
  header: string;
  dueDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };
  salesorderDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    disableSince: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };
  ewayBillDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  myDateInit: boolean = true;
  model: IMyDateModel = null;
  currency_list: any;
  company_id: any;
  branch_id: any;
  id: string;
  is_edit_form: boolean = false;
  is_same_state: boolean = false;
  public sales_order: Salesorder = new Salesorder();
  public sales_order_item: SalesOrderItem = new SalesOrderItem();
  customer_lists: any;
  customerListTypeahead = new EventEmitter<string>();
  product_lists: any;
  productListTypeahead = new EventEmitter<string>();
  company_details: any = {};
  sequence_no: any;
  sequence_id: any;
  sales_order_id: any;
  currency: any;
  is_debit_note: boolean = false;
  // shippingAddress: ContactAddress = new ContactAddress();
  // billingAddress: ContactAddress = new ContactAddress();
  shippingAddressTemplateConfig: AddressTemplate = new AddressTemplate();
  billingAddressTemplateConfig: AddressTemplate = new AddressTemplate();
  shippingAddressListTmplConfig: AddressTemplate = new AddressTemplate();
  billingAddressListTmplConfig: AddressTemplate = new AddressTemplate();
  account_head_lists: any;
  accountHeadListTypeahead = new EventEmitter<string>();
  is_pos: boolean = false;
  is_gst_applicable: boolean = true;
  salesorder_number: any;
  overall_discount_in_percentage: any;
  selectFiYear: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private salesorderService: SalesorderService,
    private mastersService: MastersService,
    private customerService: CustomerService,
    private companyService: CompanyService,
    private productService: ProductService,
    private sequenceService: SequenceService,
    private hsnService: HsnService,
    private authenticationService: AuthenticationService,
    private transactionService: TransactionService,
    private cd: ChangeDetectorRef // private reverseStringPipe: ReverseStringPipe
  ) {}

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.currency = this.authenticationService.getCompanyDetails()[
      "base_currency"
    ];
    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];
    this.getCurrencies();
    this.getCompanyDetails();
    this.route.paramMap.subscribe((params) => {
      this.sales_order_id = params.get("id");

      var result1 = this.router.url.match("/edit/");
      var result2 = this.router.url.match("/debit-note/");
      // let result = this.router.url.match("/edit/")
      if (result1 != null) {
        this.viewSalesOrder();
        if (result1[0] === "/edit/" && this.sales_order_id != null) {
          this.is_edit_form = true;
          this.header = "Edit";
        }
      } else if (result2 != null) {
        if (result2[0] === "/debit-note/" && this.sales_order_id != null) {
          this.is_edit_form = true;
          this.is_debit_note = true;
          this.viewSalesOrder();
          this.header = "Debit Note";
        }
      } else {
        this.header = "";
        let today = differenceInDays(new Date(this.selectFiYear.end_year, 2, 31), new Date()) > 0 && differenceInDays(new Date(), new Date(this.selectFiYear.start_year, 3, 1)) > 0 ? new Date() : new Date(this.selectFiYear.end_year, 2, 31);
        this.sales_order.due_date = {
          isRange: false,
          singleDate: {
            jsDate: today,
          },
        };
        this.sales_order.sales_order_date = {
          isRange: false,
          singleDate: {
            jsDate: today,
          },
        };
      }
    });

    this.getCustomers("").subscribe((result) => {
      this.customer_lists = result;
    });
    this.getCustomersSearch();

    this.getProducts("").subscribe((result) => {
      this.product_lists = result;
    });
    this.getProductSearch();

    //TODO fix array push issue
    // this.sales_order.sales_order_items = [];
    this.getCoaData();
    this.getCoaHeadSearch();
    this.addNewItem();
  }

  unSetProductWiseDiscount() {
    this.sales_order.discount = 0;
    this.overall_discount_in_percentage = 0;

    this.sales_order.sales_order_items.forEach((element) => {
      if (this.sales_order.is_discount === false) {
        element.is_discount = false;
        this.sales_order.is_productwise_discount = false;
        this.sales_order.discount_in_percentage = false;
      }
      element.discount_in_percentage = 0;
      element.discount_in_amount = 0;

      this.calculatePrice(element);
    });
  }

  // unSetProductWiseDiscount() {
  //   this.sales_order.discount = 0;
  //   this.sales_order.sales_order_items.forEach((element) => {
  //     element.discount = 0;
  //     if (this.sales_order.is_productwise_discount == false) {
  //       let actual_amount = element.units * element.price;
  //       let tax_amount = (actual_amount / 100) * element.tax;
  //       if (this.sales_order.discount_in_percentage == false) {
  //         element.discount_in_amount = 0;
  //       } else {
  //         element.discount_in_percentage = 0;
  //       }

  //       element.amount = actual_amount + tax_amount;
  //     } else {
  //       this.sales_order.balancing_items.forEach((balancingObj, key) => {
  //         if (balancingObj.account_head != null) {
  //           if (
  //             balancingObj.account_head.flattened_data.name ==
  //             ConstantService.coa_heads.Discount
  //           ) {
  //             // this.invoice.discount = 0;
  //             if (this.sales_order.balancing_items.length > 1) {
  //               this.sales_order.balancing_items.splice(key, true);
  //             } else {
  //               this.sales_order.balancing_items.splice(key, true);
  //               // this.addNewBalancingItem();
  //             }
  //           }
  //         }
  //       });
  //     }
  //   });
  // }

  applyDiscountforProductItems() {
    if (this.sales_order.is_discount == true) {
      this.sales_order.sales_order_items.forEach((itemsObj) => {
        let actual_amount = 0;
        let tax_amount = 0;
        let total_amount = 0;
        let discount_in_percentage = 0;
        actual_amount = itemsObj.units * itemsObj.price;
        if (this.company_details.is_lut) {
          this.checkForLutSez();
          if (this.is_gst_applicable == true) {
            tax_amount = (actual_amount / 100) * itemsObj.tax;
            itemsObj.amount = actual_amount + tax_amount;
          } else {
            tax_amount = 0;
            itemsObj.amount = actual_amount;
          }
        } else if (itemsObj.tax != undefined) {
          tax_amount = (actual_amount / 100) * itemsObj.tax;
          itemsObj.amount = actual_amount + tax_amount;
        }
        total_amount = actual_amount + tax_amount;
        if (this.sales_order.is_productwise_discount == true) {
          if (
            itemsObj.discount_in_percentage > 0 &&
            this.sales_order.discount_in_percentage == true
          ) {
            itemsObj.discount_in_amount = 0;
            discount_in_percentage =
              (total_amount / 100) * itemsObj.discount_in_percentage;
            itemsObj.discount = discount_in_percentage;
            // this.invoice.discount = itemsObj.discount;
            // itemsObj.amount = total_amount - discount_in_percentage;
          } else if (
            itemsObj.discount_in_amount > 0 &&
            this.sales_order.discount_in_percentage == false
          ) {
            itemsObj.discount_in_percentage = 0;
            itemsObj.discount = itemsObj.discount_in_amount;
            // this.invoice.discount = itemsObj.discount;
            // itemsObj.amount = total_amount - itemsObj.discount_in_amount;
          }
        }
      });
    }
  }

  checkForLutSez() {
    if (this.company_details.is_lut == true) {
      if (
        this.company_details.country._id ==
        this.sales_order.customer.country._id
      ) {
        if (this.sales_order.customer.is_sez == true) {
          this.is_gst_applicable = false;
        } else {
          this.is_gst_applicable = true;
        }
      } else {
        this.is_gst_applicable = false;
      }
    } else {
      this.is_gst_applicable = true;
    }

    // if (this.company_details.country._id == this.invoice.customer.country._id
    //   ) {
    //   this.is_gst_applicable = true;
    // } else {
    //   this.is_gst_applicable = false;
    // }
  }
  changePos() {
    if (this.is_pos) {
      this.is_pos = false;
    } else {
      this.is_pos = true;
    }
  }

  getCoaData() {
    let in_condition = [];
    if (
      this.sales_order.is_discount == true &&
      this.sales_order.is_productwise_discount == false
    ) {
      in_condition = [
        ConstantService.coa_heads.Other_Income,
        ConstantService.coa_heads.Discount,
      ];
    } else if (
      this.sales_order.is_discount == true &&
      this.sales_order.is_productwise_discount == true
    ) {
      in_condition = [ConstantService.coa_heads.Other_Income];
    } else {
      in_condition = [ConstantService.coa_heads.Other_Income];
    }

    this.getCoaHead("", in_condition).subscribe((result) => {
      this.account_head_lists = result["response"];
      // this.addRemoveDiscountHead();
    });
  }
  getCoaHead(searchString, in_condition) {
    this.account_head_lists = [];
    return this.mastersService.getCoaHead(this.company_id, searchString, {
      in: in_condition,
      is_parent: true,
    });
  }
  getCoaHeadSearch() {
    this.accountHeadListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getCoaHead(searchString, ""))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.account_head_lists = result["response"];
          this.addRemoveDiscountHead();
        },
        (err) => {
          console.log(err);
          this.account_head_lists = [];
        }
      );
  }
  addRemoveDiscountHead() {
    let in_condition = [];
    if (
      this.sales_order.is_discount == true &&
      this.sales_order.is_productwise_discount == false
    ) {
      in_condition = [
        ConstantService.coa_heads.Other_Income,
        ConstantService.coa_heads.Discount,
      ];
    } else if (
      this.sales_order.is_discount == true &&
      this.sales_order.is_productwise_discount == true
    ) {
      in_condition = [ConstantService.coa_heads.Other_Income];
    } else {
      in_condition = [ConstantService.coa_heads.Other_Income];
    }
    let account_head_data = this.account_head_lists;
    this.account_head_lists = [];
    account_head_data.forEach((element) => {
      in_condition.forEach((head_name) => {
        if (element.flattened_data.name == head_name) {
          this.account_head_lists.push(element);
        }
      });
    });
  }
  onDateChanged(event: IMyDateModel): void {
    // date selected
  }

  getCurrencies() {
    this.mastersService.getCurrencies().subscribe((result) => {
      this.currency_list = result["response"];
    });
  }

  addUpdateSalesorder() {
    if (this.is_edit_form === true) {
      this.editSalesOrder();
    } else {
      this.addSalesOrder();
    }
  }

  getProductQty(product, itemsObj) {
    if (
      product.type != 2 &&
      this.company_details["is_inventory_needed"] == true
    ) {
      let filter = {};
      filter["product"] = product["_id"];
      filter["company_id"] = this.company_id;
      this.transactionService
        .getProductsQuantity(filter)
        .subscribe((result) => {
          let total_qty;
          if (result["response"]["data"][0]) {
            let in_qty = result["response"]["data"][0]["total_in_qty"];
            let out_qty = result["response"]["data"][0]["total_out_qty"];
            total_qty = in_qty - out_qty;
          }
          let current_qty = 0;
          this.sales_order.sales_order_items.forEach((element) => {
            if (element.product["_id"] == product["_id"]) {
              current_qty += parseInt(element["units"]);
            }
          });
          if (current_qty > total_qty) {
            itemsObj.units = itemsObj.units - (current_qty - total_qty);
            swal(
              "Quantity Exceeded!",
              itemsObj.product.name + " has only " + total_qty + " quantity",
              "warning"
            );
            // if (itemsObj.units <= 0) {
            //   itemsObj = {};
            // }
          }
        });
    }
  }

  setCurrency(currency) {
    this.sales_order.currency = currency;
  }

  addSalesOrder() {
    this.loading =true;
    if (this.company_id != "") {
      if (
        this.overall_discount_in_percentage > 0 &&
        this.sales_order.is_discount == true &&
        this.sales_order.discount_in_percentage == true
      ) {
        this.sales_order.overall_discount_in_percentage = this.overall_discount_in_percentage;
      }
      this.sales_order.company_id = this.company_id;
      this.salesorderService
        .addSalesorder(this.sales_order)
        .subscribe((result) => {
          if (result["muid"]) {
            this.loading =false;
            this.updateSequence();
            this.router.navigate(["sales-order/list"]);
            swal("Success", "Sales order created successfully", "success");
          } else {
            swal("Error", "Some error occured", "error");
          }
        });
    }
  }

  editSalesOrder() {
    this.loading =true;
    if (this.company_id != "") {
      this.sales_order.company_id = this.company_id;
      this.salesorderService
        .editSalesorder(this.sales_order, this.sales_order_id)
        .subscribe((result) => {
          if (result["response"]["status_code"] == 1) {
            this.loading =false;
            swal("Success", "Sales order updated successfully", "success");
            this.router.navigateByUrl("/sales-order/list");
          } else {
            swal("Error", "Some error occured", "error");
          }
        });
    }
  }

  deleteItem(index) {
    if (index != 0) {
      this.sales_order.sales_order_items.splice(index, true);
    }
  }

  getCustomers(searchString) {
    this.customer_lists = [];
    let condition: any = {};
    let columns: any = {};
    if (searchString != "") {
      condition["customer_name"] = searchString;
    }
    condition["company_id"] = this.company_id;

    columns["customer_name"] = 1;
    columns["first_name"] = 1;
    columns["last_name"] = 1;
    columns["currency"] = 1;
    columns["address_line1"] = 1;
    columns["billing_address"] = 1;
    columns["shipping_address"] = 1;
    columns["payment_term"] = 1;
    columns["gst_number"] = 1;
    columns["country"] = 1;
    columns["state"] = 1;
    columns["coa_data"] = 1;
    columns["phone"] = 1;
    columns["contact_address"] = 1;
    return this.customerService.getCustomersData(condition, columns);
  }
  getCustomersSearch() {
    this.customerListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getCustomers(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.customer_lists = result;
        },
        (err) => {
          console.log(err);
          this.customer_lists = [];
        }
      );
  }
  setBillShipAddress() {
    this.shippingAddressTemplateConfig = new AddressTemplate();
    this.shippingAddressListTmplConfig = new AddressTemplate();
    this.billingAddressListTmplConfig = new AddressTemplate();
    this.billingAddressTemplateConfig = new AddressTemplate();
    if (this.sales_order.customer != null) {
      // Address Details
      this.shippingAddressTemplateConfig.contact_address = this.sales_order.customer.contact_address[0];
      this.shippingAddressTemplateConfig.user_role = "customer";
      this.shippingAddressTemplateConfig.user_muid = this.sales_order.customer._id;
      this.shippingAddressTemplateConfig.action_type = "view";

      this.shippingAddressListTmplConfig.action_type = "dropdown_viewall";
      this.shippingAddressListTmplConfig.user_muid = this.sales_order.customer._id;
      this.shippingAddressListTmplConfig.user_role = "customer";
      this.shippingAddressListTmplConfig.address_type = "shipping_address";

      this.billingAddressTemplateConfig.action_type = "view";
      this.billingAddressTemplateConfig.user_role = "customer";
      this.billingAddressTemplateConfig.contact_address = this.sales_order.customer.contact_address[0];
      this.billingAddressTemplateConfig.user_muid = this.sales_order.customer._id;

      this.billingAddressListTmplConfig.action_type = "dropdown_viewall";
      this.billingAddressListTmplConfig.address_type = "billing_address";
      this.billingAddressListTmplConfig.user_muid = this.sales_order.customer._id;
      this.billingAddressListTmplConfig.user_role = "customer";
    }
  }
  getAmount(itemsObj) {
    let tax_amount: any = 0;
    itemsObj.amount = 0;

    if (this.company_details.is_lut) {
      this.checkForLutSez();
      if (
        this.is_gst_applicable == true &&
        this.company_details.is_composite_company == false
      ) {
        tax_amount = itemsObj.tax_amount;
        itemsObj.amount = itemsObj.item_price + tax_amount;
      } else if (
        this.company_details.is_composite_company == false &&
        this.company_details.is_gst_filing == true
      ) {
        tax_amount = itemsObj.tax_amount;
        itemsObj.amount = itemsObj.item_price + tax_amount;
      } else {
        tax_amount = 0;
        itemsObj.amount = itemsObj.item_price;
      }
    } else if (
      this.company_details.is_composite_company == false &&
      this.company_details.is_gst_filing == true
    ) {
      tax_amount = itemsObj.tax_amount;
      itemsObj.amount = itemsObj.item_price + tax_amount;
    } else {
      tax_amount = 0;
      itemsObj.amount = itemsObj.item_price;
    }

    itemsObj.amount = parseFloat(itemsObj.amount.toFixed(2));
    itemsObj.tax_amount = parseFloat(itemsObj.tax_amount.toFixed(2));

    this.getTotalAmount();
  }

  getTotalAmount() {
    this.sales_order.total_amount = 0;
    this.sales_order.actual_total_amount = 0;
    this.sales_order.balance_amount = 0;
    this.sales_order.total_tax_percentage = 0;
    this.sales_order.total_tax_amount = 0;
    this.sales_order.discount = 0;
    this.sales_order.sub_total_amount = 0;
    this.sales_order.grand_total_amount = 0;
    this.checkForLutSez();

    this.sales_order.sales_order_items.forEach((element, index) => {
      if (element["tax_amount"] > 0 && this.is_gst_applicable == true) {
        this.sales_order.total_tax_amount += element["tax_amount"];
        this.sales_order.total_tax_percentage += element["tax"];
      }
      this.sales_order.total_tax_amount = parseFloat(
        this.sales_order.total_tax_amount.toFixed(2)
      );
      if (this.sales_order.is_discount == true) {
        this.sales_order.discount += element["discount"];
      }

      this.sales_order.actual_total_amount += element["actual_amount"];
      this.sales_order.sub_total_amount =
        this.sales_order.actual_total_amount - this.sales_order.discount;
      this.sales_order.balance_amount += element["amount"];
      this.sales_order.total_amount += element["amount"];
      this.sales_order.sub_total_amount = parseFloat(
        this.sales_order.sub_total_amount.toFixed(2)
      );
      if (this.sales_order.customer) {
        if (
          this.sales_order.customer.state["zone_id"] ==
            this.company_details.state["zone_id"] &&
          this.is_gst_applicable == true
        ) {
          this.sales_order.cgst = this.sales_order.total_tax_amount / 2;
          this.sales_order.sgst = this.sales_order.total_tax_amount / 2;
          this.is_same_state = true;
        } else if (this.is_gst_applicable == true) {
          this.sales_order.igst = this.sales_order.total_tax_amount;
          this.is_same_state = false;
        }
      }
    });

    this.sales_order.cgst = parseFloat(this.sales_order.cgst.toFixed(2));
    this.sales_order.sgst = parseFloat(this.sales_order.sgst.toFixed(2));
    this.sales_order.igst = parseFloat(this.sales_order.igst.toFixed(2));
  }

  applyDiscountforOverallItems() {
    // this.invoice.sub_total_amount = 0;
    this.sales_order.balancing_items.forEach((element) => {
      if (element.account_head != null) {
        // if (
        //   element.account_head.flattened_data.name ==
        //   ConstantService.coa_heads.Freight
        // ) {
        //   this.invoice.freight = element.amount;
        // }

        if (
          element.account_head.flattened_data.name ==
            ConstantService.coa_heads.Discount &&
          this.sales_order.is_productwise_discount == false
        ) {
          // this.invoice.discount = 0;
          if (this.sales_order.discount_in_percentage == false) {
            this.sales_order.discount = element.amount;
          } else {
            this.sales_order.discount = element.percentage;
          }
          // this.invoice.grand_total_amount = this.invoice.sub_total_amount - this.invoice.discount;
        }
      }
    });
  }

  getCompanyDetails() {
    this.companyService.viewCompany(this.company_id).subscribe((result) => {
      this.company_details = result["response"];

      if (this.sales_order_id == "" || this.sales_order_id == null) {
        this.getNextSequence();
      }

      if (this.company_details["sales_column_settings"]["items"] != "") {
        // this.myOptions.dateFormat = this.company_details.date_format;
        this.salesorderDateOptions.dateFormat = this.company_details.date_format;
        this.dueDateOptions.dateFormat = this.company_details.date_format;
        let book_beginning_date = new Date(this.company_details.book_beginning);
        book_beginning_date.setDate(book_beginning_date.getDate() - 1);
        let currentDueDateOptions = this.getCopyOfOptions();
        currentDueDateOptions.disableUntil = {
          year: book_beginning_date.getFullYear(),
          month: book_beginning_date.getMonth() + 1,
          day: book_beginning_date.getDate(),
        };
        this.dueDateOptions = currentDueDateOptions;
        let currentSalesOrderDtOptions = this.getCopyOfOptions();
        // currentSalesOrderDtOptions.disableUntil = {
        //   year: book_beginning_date.getFullYear(),
        //   month: book_beginning_date.getMonth() + 1,
        //   day: book_beginning_date.getDate(),
        // };
        currentSalesOrderDtOptions.disableUntil = {year:this.selectFiYear.start_year, month:3, day:31};
        currentSalesOrderDtOptions.disableSince = {year:this.selectFiYear.end_year, month:4, day:1};
        this.salesorderDateOptions = currentSalesOrderDtOptions;
        this.dueDateOptions = currentDueDateOptions;
      } else {
        swal(
          "Update Settings!",
          "Update Sales Order Settings In Company And Then Add SO",
          "warning"
        );
        this.router.navigateByUrl("/company/edit/" + this.company_id);
      }
    });
  }

  updateAddress(addressInfo: AddressTemplateEmitConfig, dropdownEvent: any) {
    if (addressInfo.address_type == "billing_address") {
      this.billingAddressTemplateConfig = new AddressTemplate();
      this.billingAddressTemplateConfig.contact_address =
        addressInfo.contact_address;
      this.billingAddressTemplateConfig.action_type = "view";
      this.billingAddressTemplateConfig.user_role = "customer";
      this.billingAddressTemplateConfig.user_muid = addressInfo.user_muid;
    } else if (addressInfo.address_type == "shipping_address") {
      this.shippingAddressTemplateConfig = new AddressTemplate();
      this.shippingAddressTemplateConfig.contact_address =
        addressInfo.contact_address;
      this.shippingAddressTemplateConfig.action_type = "view";
      this.shippingAddressTemplateConfig.user_role = "customer";
      this.shippingAddressTemplateConfig.user_muid = addressInfo.user_muid;
    }
  }

  getCopyOfOptions(): IAngularMyDpOptions {
    return JSON.parse(JSON.stringify(this.salesorderDateOptions));
    return JSON.parse(JSON.stringify(this.dueDateOptions));
  }

  addNewItem() {
    this.sales_order.sales_order_items.push({
      product: null,
      description: "",
      units: 1,
      price: 0,
      amount: 0,
      actual_amount: 0,
      tax_amount: 0,
      discount: 0,
      discount_in_percentage: 0,
      discount_in_amount: 0,
      is_invoiced: 1,
    });
  }

  getProducts(searchString) {
    this.product_lists = [];
    let columns = {
      name: 1,
      sales: 1,
      is_purchase: 1,
      is_sell: 1,
      type: 1,
      sku: 1,
      unit: 1,
      code: 1,
      hsn_muid: 1,
    };
    let condition: any = {};
    if (searchString != "") {
      condition["name"] = searchString;
    }
    // this.sales_order.sales_order_items.forEach(element => {
    //   if (product_obj != '') {
    //     let data = [];
    //     data.push(element['product']['_id'])
    //     condition['nin'] = data;
    //   }
    // });

    condition["company_id"] = this.company_id;
    condition["is_sell"] = true;
    return this.productService.getAllProducts(condition, "all", columns);
  }

  // validateProduct() {
  //   this.sales_order.sales_order_items.forEach(element => {
  //     this.product_lists = this.product_lists.filter((data) => data._id != element.product._id);
  //   });
  // }

  getProductSearch() {
    this.productListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getProducts(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.product_lists = result;
          // this.validateProduct()
        },
        (err) => {
          console.log(err);
          this.product_lists = [];
        }
      );
  }

  getTaxAmount(itemsObj) {
    itemsObj.tax_amount = 0;
    if (this.company_details.is_gst_filing == true) {
      if (itemsObj.tax > 0) {
        let tax_amount: any;
        tax_amount = (itemsObj.item_price / 100) * itemsObj.tax;
        itemsObj.tax_amount = tax_amount;
      }
    }
    this.getAmount(itemsObj);
  }
  removeproduct(itemsObj) {
    this.product_lists = this.product_lists.filter(itemsObj);
  }
  getPriceFromHSN(product_data, itemsObj) {
    if (product_data != null) {
      let condition: any = {};
      let sort: any = {};
      sort["effects_from"] = "desc";
      condition["_id"] = product_data["hsn_muid"];
      condition["effects_from_is_active"] = true;
      let data = {};
      this.hsnService.getHSNByCondition(condition, sort).subscribe((result) => {
        if (result["response"][0] != undefined) {
          result["response"][0]["effects_from"].filter((e) => {
            if (e.is_active) {
              data = e;
              itemsObj["tax"] = e.igst;
              itemsObj["sgst"] = e.sgst;
              itemsObj["cgst"] = e.cgst;
              itemsObj["igst"] = e.igst;
              this.calculatePrice(itemsObj);
            }
          });
        }
      });
    } else {
      itemsObj["tax"] = 0;
    }
  }

  getNextSequence() {
    let sales_sequence: any = {};
    sales_sequence["seq_name"] = "salesorder";
    sales_sequence["company_id"] = this.company_id;
    this.sequenceService.viewSequence(sales_sequence).subscribe((result) => {
      if (result["response"] != "" && result["response"] != null) {
        if (result["response"]["seq_no"]) {
          this.salesorder_number = result["response"]["seq_no"]
            .toString()
            .padStart(
              this.company_details["sales_column_settings"][
                "ordernumber_length"
              ],
              0
            );

          this.sales_order.sales_order_number =
            this.company_details["sales_column_settings"][
              "sales_order_prefix"
            ] +
            this.salesorder_number +
            this.company_details["sales_column_settings"]["sales_order_suffix"];
          this.sequence_no = result["response"]["seq_no"];
          this.sequence_id = result["response"]["_id"];
        } else {
          swal(
            "Update Settings!",
            "Update Sales Order Settings In Company And Then Add SO",
            "warning"
          );
          this.router.navigateByUrl("/company/edit/" + this.company_id);
        }
      } else {
        swal(
          "Update Settings!",
          "Update Sales Order Settings In Company And Then Add SO",
          "warning"
        );
        this.router.navigateByUrl("/company/edit/" + this.company_id);
      }
    });
  }

  updateSequence() {
    let sequence_data: any = {};
    let sequence_muid: any = {};
    sequence_data["seq_no"] = parseInt(this.sequence_no) + 1;
    sequence_muid["muid"] = this.sequence_id;
    sequence_data["company_id"] = this.company_id;
    this.sequenceService
      .editSequence(sequence_data, sequence_muid["muid"])
      .subscribe((result) => {});
  }

  viewSalesOrder() {
    let condition: any = {};
    condition["company_id"] = this.company_id;

    this.salesorderService
      .viewSalesorder(this.sales_order_id, condition)
      .subscribe((result) => {
        this.sales_order = result["response"];
        this.sales_order.due_date = {
          isRange: false,
          singleDate: {
            jsDate: new Date(this.sales_order.due_date),
          },
        };
        this.sales_order.sales_order_date = {
          isRange: false,
          singleDate: {
            jsDate: new Date(this.sales_order.sales_order_date),
          },
        };

        if (
          this.sales_order.is_discount == true &&
          this.sales_order.is_productwise_discount == false &&
          this.sales_order.discount_in_percentage == true
        ) {
          this.overall_discount_in_percentage = this.sales_order.overall_discount_in_percentage;
        }

        this.sales_order.sales_order_items.forEach((element) => {
          this.calculatePrice(element);
        });
      });
  }
  calculatePrice(itemsObj) {
    let item_price: number = 0;
    itemsObj.actual_amount = 0;
    itemsObj.amount = 0;
    itemsObj.tax_amount = 0;
    itemsObj.item_price = 0;
    itemsObj.discount = 0;
    item_price = itemsObj.units * itemsObj.price;
    itemsObj.actual_amount = itemsObj.units * itemsObj.price;
    if (
      this.sales_order.discount_in_percentage ||
      this.sales_order.is_productwise_discount
    ) {
      if (this.sales_order.discount_in_percentage) {
        itemsObj.item_price =
          item_price - item_price * (itemsObj.discount_in_percentage / 100);
        itemsObj.discount =
          item_price * (itemsObj.discount_in_percentage / 100);
      } else {
        itemsObj.item_price = item_price - itemsObj.discount_in_amount;
        itemsObj.discount = itemsObj.discount_in_amount;
      }
    } else {
      itemsObj.item_price = item_price;
    }

    this.getTaxAmount(itemsObj);
  }
  calculateDisount() {
    this.sales_order.sales_order_items.forEach((element) => {
      element.discount_in_percentage = this.overall_discount_in_percentage;
      this.calculatePrice(element);
    });
  }
}
