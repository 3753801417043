<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <!-- <form novalidate #searchPurchaseorderForm="ngForm"
        (ngSubmit)="getFixedAssestsList({ offset: 0, pageSize: 10 })"> -->
      <div class="card">
        <div class="card-body">
          <div class=" mr-3 mb-2 float-right">

            <a class="btn btn-primary float-right mb-4" routerLink="/fixed_assets/add">
              <i class="fa fa-plus"></i> Add FixedAssets
            </a>

          </div>
          <h3 class="table-title"><i class="fa {{page_icon}}"></i> Fixed Assests</h3>

        </div>
      </div>
      <!-- </form> -->

      <div class="card p-sm-0">
        <div class="card-body">

          <ngx-datatable [rows]="fixedAssestsList" [loadingIndicator]="loadingIndicator" [externalPaging]="true"
            [columnMode]="'force'" [count]="page.count" [limit]="page.pageSize" [offset]="page.offset"
            (page)="getFixedAssestsList($event)" [externalSorting]="true" (sort)="onSort($event)" [footerHeight]="50"
            [rowHeight]="'auto'" class="material striped" [sorts]="this.dt_default_sort" [selectionType]="'checkbox'"
            [summaryRow]="'true'" [summaryPosition]="'bottom'" [summaryHeight]="'auto'">

            <ngx-datatable-column *ngFor="let col of fixedAssestsListColumns" [flexGrow]=" col.flexGrow"
              [name]="col.name" [sortable]="col.sortable" [cellClass]="col.css_class" [prop]="col.prop">
              <ng-template ngx-datatable-header-template let-sort="sortFn">
                <span (click)="sort()">{{ col.name }}</span>
              </ng-template>
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <div [ngSwitch]="col.type">
                  <span *ngSwitchCase="'percentage'">{{row[col.prop]}}%</span>
                  <span *ngSwitchCase="'action'">

                    <div class="btn-group mr-3">

                      <a class="btn btn-primary" routerLink="/fixed_assets/edit/{{row._id}}" name="edit{{i}}"
                        type="button" rel="tooltip">Edit</a>
                      <div class="btn-group" placement="bottom-right" container="body" role="group">
                        <a class="btn btn-primary"></a>

                      </div>
                    </div>

                  </span>
                  <span *ngSwitchCase="'object'"> {{ row[col.prop][col.field_name] }}</span>
                  <span *ngSwitchCase="'date'"> {{ row[col.prop]|date }}</span>

                  <span *ngSwitchDefault>
                    {{ row[col.prop] }}
                  </span>
                </div>

              </ng-template>
            </ngx-datatable-column>

          </ngx-datatable>
        </div>
      </div>
    </div>

  </div>
</div>
