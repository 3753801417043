import { Injectable } from "@angular/core";
import { ApiService } from "../_services/api.service";
import { AuthenticationService } from "./authentication.service";
@Injectable()
export class SalesorderService {
  branch_id: string;
  constructor(private http: ApiService,
    private authenticationService: AuthenticationService) {
    this.branch_id = this.authenticationService.getDefaultBranchId();
  }

  addSalesorder(salesorder_data: any) {
    if (this.branch_id != '') {
      salesorder_data['branch_id'] = this.branch_id;
    }
    return this.http.post(
      ApiService.geturl("salesorder", "add"),
      salesorder_data
    );
  }
  editSalesorder(salesorder_data: any, id: string) {
    return this.http.post(ApiService.geturl("salesorder", "edit"), {
      data: salesorder_data,
      salesorder_id: id
    });
  }
  deleteSalesorder(id: string) {
    return this.http.post(ApiService.geturl("salesorder", "delete"), {
      id: id
    });
  }

  viewSalesorder(id: string, condiiton: {}) {
    if (this.branch_id != '') {
      condiiton['branch_id'] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("salesorder", "view"), {
      salesorder_id: id,
      condition: condiiton
    });
  }

  getSalesorders(
    filters: any = {},
    columns?: any,
    sort: any = {},
    page: any = {}
  ) {
    if (this.branch_id != '') {
      filters['branch_id'] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("salesorder", "viewall"), {
      filters: filters,
      columns: columns,
      sort: sort,
      page: page
    });
  }
}
