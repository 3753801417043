<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">

        <div class="card-header">
          <h4>Add Group Product</h4>
        </div>
        <form class="form-horizontal" (ngSubmit)="addGroupProduct()" #addproductForm="ngForm" novalidate
          enctype="multipart/form-data">
          <div class="card-body">
            <div class="row clearfix">

              <div class="form-row">
                <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                  <label for="name">Product Group</label>
                  <ng-select name="group" #group="ngModel" [loading]="loading" [addTag]="addNewGroup"
                    [items]="groupList" bindLabel="group_name" [(ngModel)]="product.group" placeholder="Select Group">
                  </ng-select>

                  <app-validation-errors [mformField]="group" label="group">
                  </app-validation-errors>

                </div>
                <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                  <label for="name">Product Name</label>
                  <input type="text" required class="form-control" placeholder="Product Name" id="name" name="name"
                    #name="ngModel" [(ngModel)]="product.name">
                  <app-validation-errors [mformField]="name" label="Product Name"></app-validation-errors>

                </div>

              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>