import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { CompanyService } from "../../../_services/company.service";
import { MastersService } from "../../../_services/masters.service";
import { AuthenticationService } from "../../../_services/authentication.service";
import { SequenceService } from "../../../_services/sequence.service";
import swal from "sweetalert2";
import { Company } from "../../../_models/company.model";
import { Contact } from "../../../_models/company.contact.model";
import { Bank } from "../../../_models/bank.model";
import { CountryService } from "../../../_services/country.service";
import { UtilsService } from "../../../_services/utils.service";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
// import { TreeModel } from 'ng2-tree';
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { Branch } from "src/app/_models/branch.model";
import { Warehouse } from "src/app/_models/warehouse.model";
import { isArray } from "util";
import { ConstantService } from "../../../_config/constants";
import { sequence } from "@angular/animations";
import { AccountsHeadTemplate } from "../../../_models/accounts-head-template.model";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';



interface TreeNode {
  label: string;
  child: TreeNode[];
}
@Component({
  selector: "app-company-add",
  templateUrl: "./company-add.component.html",
  styleUrls: ["./company-add.component.scss"],
})
export class CompanyAddComponent implements OnInit {
  validUploadType = ["image/jpeg", "image/png"];
  files: File[] = [];
  validComboDrag: any;
  lastInvalids: any;
  lastFileAt: any;
  loading = false;
  active = 1;
  @Output() added_bank = new EventEmitter();
  add_head: boolean = false;
  member_name: string;
  is_member_tab: boolean = false;
  is_edit_form: boolean = false;
  is_ledger: boolean = false;
  settingsObj: Object;
  stateList: any = [];
  branchStateList: any = [];
  branch_country_list: any = [];
  country_list: any = [];
  company_branches: any = [];
  loadingIndicator: boolean = false;
  countryListTypeahead = new EventEmitter<string>();
  branchCountryListTypeahead = new EventEmitter<string>();
  salesorder_collapse: boolean = true;
  purchaseorder_collapse: boolean = true;
  coa_collapse: boolean = true;
  inventory_collapse: boolean = true;
  satuatory_collapse: boolean = true;
  // toggle:boolean=false;
  currency_list: any;
  chart_of_accounts: any = [];
  last_sequence: any;
  // company_contacts: Contact = new Contact();
  bank_details: Bank = new Bank();
  warehouse_details: Warehouse = new Warehouse();
  company_id: string;
  navTab = "company";
  offset: 0;
  limit: 10;
  branch_list_columns: any = [];
  clientobject: any = {};
  invoiceTemplateList: any = []

  invoiceAddAccountConfig: AccountsHeadTemplate = new AccountsHeadTemplate();
  receiptsAddAccountConfig: AccountsHeadTemplate = new AccountsHeadTemplate();
  billAddAccountConfig: AccountsHeadTemplate = new AccountsHeadTemplate();
  expenseAddAccountConfig: AccountsHeadTemplate = new AccountsHeadTemplate();
  paymentAddAccountConfig: AccountsHeadTemplate = new AccountsHeadTemplate();

  currentAccountHeads = [];
  company_types: any = [
    { name: "Individual / Proprietorship", id: 1 },
    { name: "Partnership Firm", id: 2 },
    { name: "Private Limited", id: 3 },
    { name: "Limited Liability", id: 4 },
    { name: "HUF(Hindu Undivided Family)", id: 5 },
    { name: "AOP (Association Of Person) / Trust", id: 6 },
    // { name: "Trust", id: 7 },
    { name: "Public Limited", id: 8 },
    // { name: "Proprietorship ", id: 9 },
  ];

  company_categories: any = [
    { name: "Trade&Service", type: 1 },
    { name: "Service", type: 2 },
    { name: "Manufacturing&Service", type: 3 },
    { name: "Trade", type: 4 },
    { name: "Manufacturing", type: 5 },
  ];
  gst_mappings: any = [{ name: "HSN", type: 1 }];
  stock_sorts: any = ["FIFO", "LIFO", "Weighted Average Method"];

  public company_settings: Company = new Company();
  result: any;
  coa_list: any;
  // public custom: TreeModel ;
  type: number;
  // fiscalDateRangeOptions: IAngularMyDpOptions = {
  //   dateRange: true,
  //   dateFormat: 'dd/mm/yyyy'

  // }
  incorporationDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  bookBeginningDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  gstEffectiveDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  branchGstEffectiveDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  tdsEffectiveDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  cinEffectiveDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  dinEffectiveDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  pfEffectiveDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  esiEffectiveDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  lutEffectiveDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  memberDobDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  registeredDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  branch_details: any = {
    name: "",
    address: "",
    gst: "",
    state: "",
    city: "",
    country: {},
    is_gst_filing: false,
    contacts: [],
  };
  branch_gst_filing: Boolean = false;

  selectedTreeNode: any;
  flattened_data: any = [];
  image_path: string;
  invoiceNumber: any;
  finalzeBtnStatus: number;
  constructor(
    private companyService: CompanyService,
    public authenticationService: AuthenticationService,
    private sequenceService: SequenceService,
    private mastersService: MastersService,
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private countryService: CountryService,
    public constantService: ConstantService,
    private modalService: NgbModal,
    private router: Router,
    public ngbCarouselConfig: NgbCarouselConfig
  ) { ngbCarouselConfig.interval = 50000; }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    //TODOfix add row
    this.company_settings.contacts = [];
    this.company_settings.member_details = [];
    this.company_settings.warehouse = [];
    this.company_settings.bank = [];
    this.company_settings.branch = [];
    this.company_settings.branch.contacts = [];
    this.addBranchContactRow();
    this.member_name = "Member";
    this.branch_list_columns = [
      {
        prop: "name",
        name: "Branch Name",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
      },
      {
        prop: "gst",
        name: "GST",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
      },
      {
        prop: "country",
        name: "Country",
        sortable: true,
        flexGrow: 1,
        field_name: "name",
        show: true,
        type: "object",
        is_detailed_row: false,
      },
      {
        prop: "state",
        name: "State",
        sortable: true,
        flexGrow: 1,
        show: true,
        type: "object",
        field_name: "name",
        is_detailed_row: false,
      },
      {
        prop: "city",
        name: "City",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
      },
      {
        name: "Action",
        type: "action",
        prop: "_id",
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false,
      },
    ];
    this.route.paramMap.subscribe((params) => {
      // this.company_id = params.get('id');
      let result = this.router.url.match("/edit/");
      if (result != null) {
        if (result[0] === "/edit/" && this.company_id != null) {
          this.is_edit_form = true;
          this.getCoa(this.company_id);
          this.getCompany();
          this.getCompanySequence();
          this.getImagePath();
          this.getPdfTemplates();

        }
      } else {
        this.company_settings.stock_sort = this.stock_sorts[0];
        this.company_settings.company_category = null;
        this.company_settings.company_type = null;
        this.getCountryData("");
        this.getCurrencies();

      }
    });
    // this.getCountrySearch();

    // this.getBranchCountryData('').subscribe(result => {
    //   this.branch_country_list = result;
    // });
    // this.getBranchStateByCountrySearch();
    this.company_settings.sales_column_settings.payment_terms = "60";
    this.company_settings.purchase_column_settings.payment_terms = "60";
    this.company_settings.gst_mapping = this.gst_mappings[0];
    this.company_settings.country = null;
    this.company_settings.currency = null;
    // this.getCountries();

    this.addContactRow();
    this.addMemberRow();

    // this.setBillAccountHeaderConfig("");
    // this.setReceiptAccountHeaderConfig("");
    // this.setInvoiceAccountHeaderConfig("");
    // this.setExpenseAccountHeaderConfig("");
    // this.setPaymentAccountHeaderConfig("");

  }

  setMemberName() {
    switch (true) {
      case this.company_settings.company_type.id == 2 ||
        this.company_settings.company_type.id == 4:
        this.member_name = "Partner";
        break;
      case this.company_settings.company_type.id == 3 ||
        this.company_settings.company_type.id == 8:
        this.member_name = "Director";
        break;
      case this.company_settings.company_type.id == 5:
        this.member_name = "Member";
        break;
      case this.company_settings.company_type.id == 6 ||
        this.company_settings.company_type.id == 7:
        this.member_name = "Trustee";
        break;
    }
    if (this.company_settings.company_type.id != 1) {
      this.is_member_tab = true;
    } else {
      this.is_member_tab = false;
    }
  }

  addEditCompany() {
    if (this.is_edit_form == true) {
      this.editCompany();
    } else {
      this.addCompany();
    }
  }

  test(form_data) {
    // console.log('form_data=========', form_data);
  }
  getFinancialyear(book_beginning) {
    return book_beginning.month < 3 ? { start_year: parseInt(book_beginning.year) - 1, end_year: parseInt(book_beginning.year), status: "Not Finalized" } : { start_year: parseInt(book_beginning.year), end_year: parseInt(book_beginning.year) + 1, status: "Not Finalized" };
  }
  addCompany() {
    this.loading = true;
    this.company_settings.date_format = ConstantService.DateFormat;
    this.company_settings.fiscal_year_end = "01-April to 31-March";
    let sequence_data = this.getCompanySettings();
    this.company_settings.branch.push({
      name: this.company_settings.company,
      address: this.company_settings.address1,
      gst: this.company_settings.gst,
      state: this.company_settings.state,
      city: this.company_settings.city,
      country: this.company_settings.country,
      contacts: [{}],
      is_head_office: true,
    });

    this.company_settings["purchase_column_settings"] =
      sequence_data["purchase_column_settings"];
    this.company_settings["sales_column_settings"] =
      sequence_data["sales_column_settings"];
    this.company_settings["rcm_column_settings"] =
      sequence_data["rcm_column_settings"];
    this.company_settings['financial_year'] = [];
    this.company_settings['financial_year'].push(this.getFinancialyear(this.company_settings['book_beginning']['singleDate']['date']));

    this.companyService
      .companyAdd(this.company_settings)
      .subscribe(async (result) => {
        // result['muid'] = result['_id'];
        if (result["status_code"] == 1) {
          if (this.files.length > 0) {

            let data: any = {
              company_id: result["response"]["_id"]
            };




            this.uploadCompanyLogo(this.files, data);
          }
          this.loading = false;
          let sequence_result = this.createNewSequence(
            result["response"]["_id"]
          );
          swal("Success", "Company has been added successfully", "success");
          this.router.navigateByUrl("/home");
        } else {
          if (result["error_message"] != "") {
            swal("Warning", result["error_message"], "warning");
          } else {
            swal("Warning", result["response"], "warning");
          }
        }
      });
  }

  uploadCompanyLogo(files, company_id) {
    this.companyService
      .uploadLogo(files, company_id)
      .subscribe((result) => { });
  }
  setGst() {
    if (this.company_settings.gst) {
      let gst_data = this.utilsService.checkGstState(
        this.company_settings.gst,
        this.branch_details.gst,
        this.stateList
      );
      if (gst_data.is_gst_same == true) {
        this.branch_details.gst = this.company_settings.gst;
        this.branch_details.stateList = {
          [gst_data.gst_state.zone_id]: this.stateList[
            gst_data.gst_state.zone_id
          ],
        };
        // this.branch_details.state = this.branch_details.stateList[
        //   gst_data.gst_state.zone_id
        // ].name;
        this.branch_gst_filing = false;
        this.branch_details.is_gst_filing = false;
      } else {
        this.branch_gst_filing = true;
        this.branch_details.state = "";
        this.branch_details.stateList = [];
      }
    }
  }

  editBranch(value) {
    // console.log('value-------', value)

    this.getBranchStateByCountry(value.country, value.state);
    this.getStateByCountry();
    this.branch_details.name = value.name;
    this.branch_details.address = value.address;
    this.branch_details.gst = value.gst;
    this.branch_details.country = value.country;

    // this.branch_details.stateList = value.state;

    // let state_key = Object.keys(value.state);
    // state_key.forEach(element => {
    //   this.branch_details.state = this.branch_details.stateList[element];
    //   console.log('this.branch_details.stateList', this.branch_details.state)

    // });
    this.branch_details.city = value.city;
    this.branch_details.contacts = value.contacts;
    this.branch_details._id = value._id ? value._id : "";
  }

  getCountryData(searchString) {
    this.country_list = [];
    let filters = {};
    filters["name"] = searchString;
    return this.countryService
      .getCountryData(filters, 0)
      .subscribe((result) => {
        this.country_list = result;
        this.country_list.filter((e) => {
          if (e.name == "India") {
            this.company_settings.country = e;
          }
        });
        this.stateList.push({

          zone_id: 1503,
          country_id: 99,
          name: "Tamil Nadu",
          Gst_code: 33,
          code: "TN",
          status: 1

        });
        this.stateList.filter((e) => {
          if (e.name == "Tamil Nadu") {
            this.company_settings.state = e;
          }
        });
        this.getStateByCountry();
      });
  }
  // getCountrySearch() {
  //   this.countryListTypeahead
  //     .pipe(
  //       debounceTime(300),
  //       distinctUntilChanged(),
  //       switchMap(searchString => this.getCountryData(searchString))
  //     )
  //     .subscribe(
  //       result => {
  //         this.cd.markForCheck();
  //         this.country_list = result;
  //       },
  //       err => {
  //         console.log(err);
  //         this.country_list = [];
  //       }
  //     );
  // }

  getStateByCountry() {
    let country_id = this.company_settings.country._id
      ? this.company_settings.country._id
      : this.branch_details.country._id;
    this.countryService
      .getStateByCountry({ muid: country_id })
      .subscribe((result) => {
        this.stateList = result[0]["zones"];
        if (this.company_settings.state) {
          this.company_settings.state = this.stateList[
            this.company_settings.state["zone_id"]
          ];
        }
      });
  }

  getBranchStateByCountry(country, state) {
    country = country != null ? country : this.branch_details.country;
    this.countryService
      .getStateByCountry({ muid: country._id })
      .subscribe((result) => {
        this.branch_details["stateList"] = result[0]["zones"];
        if (state) {
          this.branch_details.state = this.branch_details["stateList"][
            state["zone_id"]
          ];
        }
      });
  }

  editCompany() {
    this.loading = true;
    this.company_settings.branch.forEach((element) => {
      if (element["_id"] == this.branch_details._id) {
        delete this.branch_details.stateList;
        element["_id"] = this.branch_details["_id"];
        element["name"] = this.branch_details["name"];
        element["address"] = this.branch_details["address"];
        element["gst"] = this.branch_details["gst"];
        element["state"] = {
          [this.branch_details["state"]["zone_id"]]: this.branch_details[
            "state"
          ],
        };
        element["city"] = this.branch_details["city"];
        element["country"] = this.branch_details["country"];
      }
    });
    if (
      this.branch_details._id == undefined &&
      this.branch_details.name != ""
    ) {
      delete this.branch_details._id;
      this.company_settings.branch.push(this.branch_details);
    }
    // this.company_settings['book_beginning'] = {
    //   isRange: false,
    //   singleDate: {
    //     jsDate: new Date(this.company_settings['book_beginning']),
    //   },
    // };
    let financial_year: any = [];
    financial_year.push(this.getFinancialyear(this.company_settings['book_beginning']['singleDate']['date']));
    //to remove the duplicate financial year
    // this.company_settings['financial_year'] = Object.values(financial_year.reduce((a, c) => {
    //   a[c.end_year + '|' + c.start_year] = c; return a
    // }, {}));

    this.companyService
      .companyEdit(this.company_id, this.company_settings)
      .subscribe((result) => {
        if (result["status_code"] == 1) {
          if (this.files.length > 0) {
            let data: any = {
              company_id: this.company_id
            };
            this.uploadCompanyLogo(this.files, data);
          }
          this.loading = false;
          this.createBankHead();
          swal("Success", "Company has been updated successfully", "success");
          this.getCompany();
        } else if (result["status_code"] == 0) {
          swal("Error", "Some error occured while updating Company", "error");
        }
      });
  }

  createNewSequence(company_id) {
    let sales_sequence: any = {};
    let invoice_sequence: any = {};
    let bill_sequence: any = {};
    let purchase_sequence: any = {};
    let contra_sequence: any = {};
    let journal_sequence: any = {};
    let stock_journal_sequence: any = {};
    let rcm_sequence: any = {};
    // let voucher_sequences:any = this.company_settings.sales_column_settings.voucher;
    let data: any = [];
    sales_sequence["seq_no"] =
      this.company_settings.sales_column_settings.sales_order_number !=
        undefined
        ? this.company_settings.sales_column_settings.sales_order_number
        : 100;
    sales_sequence["seq_name"] = "salesorder";
    sales_sequence["prefix"] =
      this.company_settings.sales_column_settings.sales_order_prefix !=
        undefined
        ? this.company_settings.sales_column_settings.sales_order_prefix
        : "sales";
    sales_sequence["suffix"] =
      this.company_settings.sales_column_settings.sales_order_suffix !=
        undefined
        ? this.company_settings.sales_column_settings.sales_order_suffix
        : "order";
    sales_sequence["company_id"] = company_id;
    purchase_sequence["seq_no"] =
      this.company_settings.purchase_column_settings.purchase_order_number !=
        undefined
        ? this.company_settings.purchase_column_settings.purchase_order_number
        : 1;
    purchase_sequence["seq_name"] = "purchaseorder";
    purchase_sequence["prefix"] =
      this.company_settings.purchase_column_settings.purchase_order_prefix !=
        undefined
        ? this.company_settings.purchase_column_settings.purchase_order_prefix
        : "purchase";
    purchase_sequence["suffix"] =
      this.company_settings.purchase_column_settings.purchase_order_suffix !=
        undefined
        ? this.company_settings.purchase_column_settings.purchase_order_suffix
        : "order";
    purchase_sequence["company_id"] = company_id;

    invoice_sequence["seq_no"] =
      this.company_settings.sales_column_settings.invoice_order_number !=
        undefined
        ? this.company_settings.sales_column_settings.invoice_order_number
        : 1;
    invoice_sequence["seq_name"] = "invoice";
    invoice_sequence["prefix"] =
      this.company_settings.sales_column_settings.invoice_order_prefix !=
        undefined
        ? this.company_settings.sales_column_settings.invoice_order_prefix
        : "invoice";
    invoice_sequence["suffix"] =
      this.company_settings.sales_column_settings.invoice_order_suffix !=
        undefined
        ? this.company_settings.sales_column_settings.invoice_order_suffix
        : "order";
    invoice_sequence["company_id"] = company_id;

    bill_sequence["seq_no"] =
      this.company_settings.purchase_column_settings.bill_order_number !=
        undefined
        ? this.company_settings.purchase_column_settings.bill_order_number
        : 1;
    bill_sequence["seq_name"] = "bill";
    bill_sequence["prefix"] =
      this.company_settings.purchase_column_settings.bill_order_prefix !=
        undefined
        ? this.company_settings.purchase_column_settings.bill_order_prefix
        : "bill";
    bill_sequence["suffix"] =
      this.company_settings.purchase_column_settings.bill_order_suffix !=
        undefined
        ? this.company_settings.purchase_column_settings.bill_order_suffix
        : "order";
    bill_sequence["company_id"] = company_id;

    contra_sequence["seq_no"] = 1;
    contra_sequence["seq_name"] = "contra";
    contra_sequence["prefix"] = "CO-";
    contra_sequence["suffix"] = "-" + new Date().getFullYear();
    contra_sequence["company_id"] = company_id;
    journal_sequence["seq_no"] = 1;
    journal_sequence["seq_name"] = "journal";
    journal_sequence["prefix"] = "JO-";
    journal_sequence["suffix"] = "-" + new Date().getFullYear();
    journal_sequence["company_id"] = company_id;

    stock_journal_sequence["seq_no"] = 1;
    stock_journal_sequence["seq_name"] = "stockjournal";
    stock_journal_sequence["prefix"] = "SJO-";
    stock_journal_sequence["suffix"] = "-" + new Date().getFullYear();
    stock_journal_sequence["company_id"] = company_id;

    rcm_sequence["seq_no"] =
      this.company_settings.rcm_column_settings.rcm_order_number != undefined
        ? this.company_settings.rcm_column_settings.rcm_order_number
        : 100;
    rcm_sequence["seq_name"] = "rcm";
    rcm_sequence["prefix"] =
      this.company_settings.rcm_column_settings.rcm_order_prefix != undefined
        ? this.company_settings.rcm_column_settings.rcm_order_prefix
        : "rcm_";
    rcm_sequence["suffix"] =
      this.company_settings.rcm_column_settings.rcm_order_suffix != undefined
        ? this.company_settings.rcm_column_settings.rcm_order_suffix
        : "_order";
    rcm_sequence["company_id"] = company_id;

    // voucher_sequences.forEach((doc) =>{
    //   let voucher_sequence :any={};
    //   voucher_sequence["seq_no"] = doc.invoice_order_number;
    //   voucher_sequence["seq_name"] = "voucher_type_"+doc.voucher_type;
    //   voucher_sequence["prefix"] =
    //   doc.invoice_order_prefix !=
    //     undefined
    //     ? doc.invoice_order_prefix
    //     : "invoice";
    //     voucher_sequence["suffix"] =
    //   doc.invoice_order_suffix !=
    //     undefined
    //     ? doc.invoice_order_suffix
    //     : "order";
    //     voucher_sequence["company_id"] = company_id;

    //     data.push(voucher_sequence);
    // })

    data.push(
      sales_sequence,
      purchase_sequence,
      invoice_sequence,
      bill_sequence,
      contra_sequence,
      journal_sequence,
      stock_journal_sequence,
      rcm_sequence
    );
    this.sequenceService.addSequence(data).subscribe((result) => {
      if (result["status_code"] == 1) {
        swal("Success", "Company has been added successfully", "success");
        this.router.navigateByUrl("/home");
      } else {
        if (result["error_message"] != "") {
          swal("Warning", result["error_message"], "warning");
        } else {
          swal("Warning", "Cant create sequence", "warning");
        }
      }
    });
  }

  addContactRow() {
    this.company_settings.contacts.push({
      contact_name: "",
      phone_number: "",
      email: "",
      designation: "",
    });
  }

  addMemberRow() {
    this.company_settings.member_details.push({
      name: "",
      father_name: "",
      pan: "",
      din: "",
      dob: "",
      aadhar: "",
      mobile: "",
      address: "",
      email: "",
    });
  }

  addWarehouseContactRow() {
    this.company_settings.warehouse.push({
      name: "",
      billing_address: "",
      shipping_address: "",
      state: "",
      branch_name: {},
      contact_name: "",
      phone_number: "",
      email: "",
      designation: "",
    });
  }

  addBankRow() {
    this.company_settings.bank.push({
      name: "",
      branch: "",
      ifsc_code: "",
      account_number: "",
    });
  }

  addBranchRow(i) {
    this.company_settings.branch.push({
      name: "",
      address: "",
      gst: "",
      state: "",
      city: "",
      country: null,
      is_warehouse: false,
      contacts: [],
      stateList: {},
    });
    if (this.company_settings.branch[i + 1]) {
      this.company_settings.branch[i + 1]["contacts"].push({
        contact_name: "",
        phone_number: "",
        email: "",
        designation: "",
      });
    }
  }
  addBranchContactRow() {
    this.branch_details.contacts.push({
      contact_name: "",
      phone_number: "",
      email: "",
      designation: "",
    });
  }
  deleteMemberRow(index) {
    if (index != 0) {
      this.company_settings.member_details.splice(index, true);
    }
  }
  deleteContactRow(index) {
    if (index != 0) {
      this.company_settings.contacts.splice(index, true);
    }
  }
  deleteWarehouseContactRow(index) {
    if (index != 0) {
      this.company_settings.warehouse.splice(index, true);
    }
  }
  deleteBankRow(index) {
    this.company_settings.bank.splice(index, true);
  }
  deleteBranchRow(index) {
    this.company_settings.branch.splice(index, true);
  }
  deleteBranchContactRow(index) {
    this.branch_details.contacts.splice(index, true);
  }
  getCurrencies() {
    this.mastersService.getCurrencies().subscribe((result) => {
      this.currency_list = result["response"];
      this.currency_list.filter((e) => {
        if (e.name == "INR") {
          this.company_settings.currency = e;
        }
      });
    });
  }

  getCompany() {
    this.companyService.viewCompany(this.company_id).subscribe((result) => {
      if (result["response"] != "" || result["response"] != null) {
        this.company_settings = result["response"];

        this.finalzeBtnStatus = this.company_settings.financial_year.findIndex((year, index) => {
          if (!year.status || year.status == "Not Finalized") {
            return index
          }
        });
        this.finalzeBtnStatus = this.finalzeBtnStatus == -1 ? 0 : this.finalzeBtnStatus;

        this.setBillAccountHeaderConfig(this.company_settings.purchase_column_settings);
        this.setInvoiceAccountHeaderConfig(this.company_settings.sales_column_settings);
        this.setExpenseAccountHeaderConfig(this.company_settings.purchase_column_settings);

        if (!this.company_settings.sales_column_settings.voucher ||
          this.company_settings.sales_column_settings.voucher.length == 0) {
          this.company_settings.sales_column_settings.voucher = [];
          this.addNewItem();
        } else {
          this.getVoucherSequence(this.company_settings.sales_column_settings.voucher);
        }
        if (!this.company_settings.purchase_column_settings.voucher ||
          this.company_settings.purchase_column_settings.voucher.length == 0
        ) {
          console.log("this.company_settings.purchase_column_settings.voucher");
          this.company_settings.purchase_column_settings.voucher = [];
          this.addVoucherPurchase();
        }
        this.getStateByCountry();
        // this.getBranchStateByCountry();
        let branches_data: any = {};
        if (this.company_settings.branch.length > 0) {
          this.company_settings.branch.forEach((element, index) => {
            // this.getBranchStateByCountry(index);
            branches_data = {
              name: element.name,
              address: element.address,
              gst: element.gst,
              city: element.city,
              country: element.country,
            };
            this.company_branches.push(branches_data);
          });
        } else {
          this.addBranchRow(0);
          this.addBranchContactRow();
        }
        if (this.company_settings.bank.length == 0) {
          this.addBankRow();
        }
        if (this.company_settings.warehouse.length == 0) {
          this.addWarehouseContactRow();
        }
        let set_dates: any = [
          "cin_effective_date",
          "gst_effective_date",
          "tds_effective_date",
          "pf_effective_date",
          "esi_effective_date",
          "book_beginning",
          "incorporation_date",
          "lut_effective_date",
          "registered_date",
        ];
        set_dates.forEach((element) => {
          if (this.company_settings[element]) {
            this.company_settings[element] = {
              isRange: false,
              singleDate: {
                date: this.getDateObject(this.company_settings[element]),
                jsDate: new Date(this.company_settings[element]),
              },
            };
          }
        });
        this.company_settings.member_details.forEach((memberObj) => {
          if (memberObj.dob) {
            memberObj.dob = {
              isRange: false,
              singleDate: {
                jsDate: new Date(memberObj.dob),
              },
            };
          }
        });
        this.getCompanySequence();
        this.setMemberName();
      }

    });
  }
  getDateObject(dateString) {
    let yearValue: number = 0;
    let monthValue: number = 0;
    let dayValue: number = 0;
    let dateValue = new Date(dateString);
    yearValue = dateValue.getFullYear();
    monthValue = dateValue.getMonth();
    dayValue = dateValue.getDay();
    return { year: yearValue, month: monthValue, day: dayValue };
  }
  getCoa(company_id) {
    this.mastersService.getCoa(company_id).subscribe((result) => {
      this.chart_of_accounts = {
        child: result["response"]["chart_of_accounts"],
        skip_first_child: true,
        is_open: true,
        name: "COA",
        id: 1,
        is_editable: 0,
        is_root: true,
      };
      this.flattened_data = result["response"]["flattened_data"];

    });
  }

  toggleNode(node) {
    node.is_open = !node.is_open;
  }
  selectNode(node: TreeNode): void {
    this.selectedTreeNode = node;

    // console.group("Selected Tree Node", node);
    // console.log("Label:", node.label);
    // console.log("Children:", node.child.length);
    // console.groupEnd();
  }

  createBankHead() {
    let bank_data: Boolean = false;
    this.chart_of_accounts.child.forEach((coa_element) => {
      if (coa_element["id"] == 1000) {
        //1000 => Assets
        this.last_sequence = coa_element["last_sequence"];
        coa_element.child.forEach((value, key) => {
          //1030 => Bank
          if (value["id"] == 1017) {
            value.child.forEach((currentAssetsChild, key) => {
              if (currentAssetsChild["id"] == 1030) {
                if (this.last_sequence + 1 < 2000) {
                  currentAssetsChild["is_ledger"] = false;
                  this.company_settings.bank.forEach((element) => {
                    if (element.name != undefined) {
                      let existing_bank_list = currentAssetsChild.child.filter(
                        (data) => data.name == element.name
                      );
                      if (existing_bank_list.length == 0) {
                        bank_data = true;
                        currentAssetsChild.child.push({
                          name: element.name,
                          id: this.last_sequence + 1,
                          is_editable: true,
                          is_ledger: true,
                          child: [],
                        });
                        this.last_sequence = this.last_sequence + 1;
                        coa_element["last_sequence"] = this.last_sequence;
                        let parent_name: any = [];
                        let parent_id: any = [];
                        //Pushing new object in to flattened data
                        this.flattened_data.forEach((flatObj) => {
                          if (flatObj.id == 1030) {
                            parent_name.push(flatObj.name);
                            parent_id.push(flatObj.id);
                            flatObj.parent_name.forEach((parent_names) => {
                              parent_name.push(parent_names);
                            });
                            flatObj.parent_id.forEach((parent_ids) => {
                              parent_id.push(parent_ids);
                            });
                            flatObj["is_ledger"] = false;
                            this.flattened_data.push({
                              name: element.name,
                              id: this.last_sequence,
                              parent_name: parent_name,
                              parent_id: parent_id,
                              is_editable: true,
                              is_ledger: true,
                            });
                          }
                        });
                      }
                    }
                  });
                } else {
                  swal(
                    "Exceeded The Limit Of Adding Head!",
                    "Preferred Limit 1999",
                    "warning"
                  );
                }
              }
            });
          }
        });
      }
    });
    if (bank_data == true) {
      this.updateCoa();
    }
  }

  updateCoa() {
    let condition: any = {};
    condition["company_id"] = this.company_id;
    // console.log('this.chart_of_accounts child=====', this.chart_of_accounts['child'])
    this.mastersService
      .updateCoa(condition, {
        chart_of_accounts: this.chart_of_accounts["child"],
        flattened_data: this.flattened_data,
      })
      .subscribe((result) => {
        this.added_bank.emit(true);
      });
  }

  setBookBeginningValidation(inc_date) {
    let temp_date = new Date(inc_date["singleDate"]["jsDate"]);
    temp_date.setDate(temp_date.getDate() - 1);
    let currentDateOptions = this.getCopyOfOptions();
    currentDateOptions.disableUntil = {
      year: temp_date.getFullYear(),
      month: temp_date.getMonth() + 1,
      day: temp_date.getDate(),
    };
    this.bookBeginningDateOptions = currentDateOptions;
  }
  getCopyOfOptions(): IAngularMyDpOptions {
    return JSON.parse(JSON.stringify(this.bookBeginningDateOptions));
  }
  // getOrderLength(value) {
  //   this.company_settings.sales_column_settings.ordernumber_length = value.toString().length;
  // }
  // getInvoiceLength(value) {
  //   this.company_settings.sales_column_settings.invoice_ordernumber_length = value.toString().length;
  // }
  // getPurchaseOrderLength(value) {
  //   this.company_settings.purchase_column_settings.ordernumber_length = value.toString().length;
  // }
  // getBillOrderLength(value) {
  //   this.company_settings.purchase_column_settings.bill_ordernumber_length = value.toString().length;
  // }
  getCompanySettings() {
    let column_settings = {};
    column_settings["purchase_column_settings"] = {
      items_display: true,
      desc_display: true,
      units_display: true,
      price_display: true,
      amount_display: true,
      payment_terms: "60",
      title: "Purchase",
      sub_heading: "",
      purchase_order_prefix: "purchase",
      purchase_order_number: 1,
      ordernumber_length: 1,
      purchase_order_suffix: "order",
      bill_order_prefix: "bill",
      bill_order_number: 1,
      bill_ordernumber_length: 1,
      bill_order_suffix: "order",
      items: "Items",
      description: "Description",
      units: "units",
      price: "price",
      amount: "amount",
      footer: "Purchase Invoice",
    };
    column_settings["sales_column_settings"] = {
      items_display: true,
      desc_display: true,
      units_display: true,
      price_display: true,
      amount_display: true,
      delivery_note_display: false,
      suppliers_ref_display: false,
      others_ref_display: false,
      despatched_through_display: false,
      destination_display: false,
      discount_display: false,
      motor_vehicle_no_display: false,
      eway_bill_no_display: false,
      eway_bill_date_display: false,
      payment_terms: "60",
      title: "Sales Invoice",
      sub_heading: "Sales Invoice",
      sales_order_prefix: "sales",
      sales_order_number: 1,
      ordernumber_length: 1,
      invoice_order_prefix: "invoice",
      invoice_order_number: 1,
      invoice_ordernumber_length: 1,
      items: "Items",
      description: "Desc",
      units: "units",
      price: "price",
      amount: "Amount",
      sales_order_suffix: "order",
      invoice_order_suffix: "order",
      footer: "Sales Invoice",
    };

    column_settings["rcm_column_settings"] = {
      rcm_order_prefix: "rcm_",
      rcm_order_number: 100,
      ordernumber_length: 1,
      rcm_order_suffix: "_order",
    };
    return column_settings;
  }
  getCompanySequence() {
    let condition = {};
    condition["company_id"] = this.company_id;
    let settings = {
      salesorder: {
        key: "sales_column_settings",
        prefix: "sales_order_prefix",
        suffix: "sales_order_suffix",
        seq_no: "sales_order_number",
      },
      invoice: {
        key: "sales_column_settings",
        prefix: "invoice_order_prefix",
        suffix: "invoice_order_suffix",
        seq_no: "invoice_order_number",
      },
      purchaseorder: {
        key: "purchase_column_settings",
        prefix: "purchase_order_prefix",
        suffix: "purchase_order_suffix",
        seq_no: "purchase_order_number",
      },
      bill: {
        key: "purchase_column_settings",
        prefix: "bill_order_prefix",
        suffix: "bill_order_suffix",
        seq_no: "bill_order_number",
      },
      rcm: {
        key: "rcm_column_settings",
        prefix: "rcm_order_prefix",
        suffix: "rcm_order_suffix",
        seq_no: "rcm_order_number",
      },
    };

    this.sequenceService.getSequences(condition).subscribe((response) => {
      let result: any = [];
      for (var i in response) {
        result.push(response[i]);
      }

      if (result[0] != undefined) {
        result.forEach((element) => {
          let settingDetail: any = {};
          settingDetail = settings[element.seq_name];
          this.company_settings[settingDetail.key][settingDetail.prefix] =
            element["prefix"];
          this.company_settings[settingDetail.key][settingDetail.seq_no] =
            element["seq_no"];
          this.company_settings[settingDetail.key][settingDetail.suffix] =
            element["suffix"];
        });
      } else {
        this.company_settings.sales_column_settings.sales_order_prefix = "";
        this.company_settings.sales_column_settings.sales_order_number = 0;
        this.company_settings.sales_column_settings.sales_order_suffix = "";

        this.company_settings.sales_column_settings.invoice_order_prefix = "";
        this.company_settings.sales_column_settings.invoice_order_number = 0;
        this.company_settings.sales_column_settings.invoice_order_suffix = "";

        this.company_settings.purchase_column_settings.purchase_order_prefix =
          "";
        this.company_settings.purchase_column_settings.purchase_order_number = 0;
        this.company_settings.purchase_column_settings.purchase_order_suffix =
          "";

        this.company_settings.purchase_column_settings.bill_order_prefix = "";
        this.company_settings.purchase_column_settings.bill_order_number = 0;
        this.company_settings.purchase_column_settings.bill_order_suffix = "";
      }
    });
  }

  deleteItem(index) {
    if (index != 0) {
      this.company_settings.sales_column_settings.voucher.splice(index, 1);
    }
  }
  deleteVoucherPurchase(index) {
    if (index != 0) {
      this.company_settings.purchase_column_settings.voucher.splice(index);
    }
  }

  addNewItem() {
    this.company_settings.sales_column_settings.voucher.push({
      voucher_type: "",
      invoice_order_number: "",
      invoice_order_prefix: "",
      invoice_order_suffix: "",
      invoice_ordernumber_length: ""

    });
  }
  addFinancialYear() {
    const lastName = this.company_settings.financial_year[this.company_settings.financial_year.length - 1]
    this.company_settings.financial_year.push({
      start_year: lastName["start_year"] + 1,
      end_year: lastName["end_year"] + 1,
      status: "Not Finalized",

    });

  }
  finalizeFinancialYear(financialYear) {
    console.log(financialYear)
    financialYear.company_id = this.company_id;
    this.companyService.finalizeFinancialYear(financialYear).subscribe((result: any) => {
      if (result.status_code == 1) {
        swal("Success", financialYear.start_year + "-" + financialYear.end_year + " Financial Year finalized successfully", "success");
        this.getCompany();
      }
    })


  }

  addVoucherPurchase() {
    this.company_settings.purchase_column_settings.voucher.push({
      voucher_type: "",
      voucher_code: null,
    });
  }
  setBillAccountHeaderConfig(columnSettings) {
    let currentAccountHeads: any = [];
    if (columnSettings.bill_ledger != undefined) {
      console.log("===", columnSettings.bill_ledger.id);
      currentAccountHeads.push(columnSettings.bill_ledger.id);
      this.billAddAccountConfig.accountsHeads = currentAccountHeads;
      this.billAddAccountConfig.is_required = true;
      this.billAddAccountConfig.field_name == columnSettings.bill_ledger.name;
      this.billAddAccountConfig.label_name == "Bill Accounts";
      this.billAddAccountConfig.is_set = true;
    } else {
      currentAccountHeads = [5040, 5041, 5042, 5043, 5046, 5047, 5026, 5022, 5039];
      this.billAddAccountConfig.accountsHeads = currentAccountHeads;
      this.billAddAccountConfig.is_required = true;
      this.billAddAccountConfig.field_name = "bill_accounts";
      this.billAddAccountConfig.label_name = "Bill Accounts";
    }
  }
  setInvoiceAccountHeaderConfig(columnSettings) {
    let currentAccountHeads: any = [];

    if (columnSettings.invoice_ledger != undefined) {
      console.log("===", columnSettings.invoice_ledger.id);
      currentAccountHeads.push(columnSettings.invoice_ledger.id);
      this.invoiceAddAccountConfig.accountsHeads = currentAccountHeads;
      this.invoiceAddAccountConfig.is_required = true;
      this.invoiceAddAccountConfig.field_name = columnSettings.invoice_ledger.name;
      this.invoiceAddAccountConfig.label_name = "Invoice Accounts";
      this.invoiceAddAccountConfig.is_set = true;

    } else {
      currentAccountHeads = [4015, 4016, 4017];
      this.invoiceAddAccountConfig.accountsHeads = currentAccountHeads;
      this.invoiceAddAccountConfig.is_required = true;
      this.invoiceAddAccountConfig.field_name = "invoice_accounts";
      this.invoiceAddAccountConfig.label_name = "Invoice Accounts";
    }

  }
  setExpenseAccountHeaderConfig(columnSettings) {
    let currentAccountHeads: any = [];
    if (columnSettings.expense_ledger != undefined) {
      currentAccountHeads.push(columnSettings.expense_ledger.id);
      this.expenseAddAccountConfig.accountsHeads = currentAccountHeads;
      this.expenseAddAccountConfig.is_required = true;
      this.expenseAddAccountConfig.field_name = columnSettings.expense_ledger.name;
      this.expenseAddAccountConfig.label_name = "Expense Accounts";
      this.expenseAddAccountConfig.is_set = true;
    } else {
      currentAccountHeads = [5021];
      this.expenseAddAccountConfig.accountsHeads = currentAccountHeads;
      this.expenseAddAccountConfig.is_required = true;
      this.expenseAddAccountConfig.field_name = "expense_accounts";
      this.expenseAddAccountConfig.label_name = "Expense Accounts";
    }
  }
  updateLedger(ledger: any, column_type, ledger_type) {
    if (column_type == "sales") {
      this.company_settings.sales_column_settings[ledger_type] = ledger.flattened_data;
      this.company_settings.sales_column_settings[ledger_type].ref_id = ledger.flattened_data["_id"];

    } else if (column_type == "purchase") {
      this.company_settings.purchase_column_settings[ledger_type] = ledger.flattened_data;
      this.company_settings.purchase_column_settings[ledger_type].ref_id = ledger.flattened_data["_id"];
    }
  }
  onFileChange(fileChangeEvent) {
    Object.keys(fileChangeEvent.target.files).forEach(key => {
      const file: File = fileChangeEvent.target.files[key];
      if (this.validUploadType.includes(file.type)) {
        this.files.push(file);
      }
    });
  }
  getDate() {
    return new Date();
  }

  getImagePath() {
    this.image_path = ConstantService.IMG_BASE_URL;
  }
  getPdfTemplates() {
    this.companyService.getPdfTemplates().subscribe((result) => {
      this.invoiceTemplateList = result['response']

      this.invoiceTemplateList.forEach(element => {
        element['preview_img_path'] = ConstantService.IMG_BASE_URL + element['preview_img_path']
      });
    })
  }
  openLg(content) {
    this.modalService.open(content, { size: 'lg' });
  }

  checkInvoiceNumberLength(type, indexVal) {
    this.invoiceNumber = "";
    if (type == "sales") {
      this.invoiceNumber = this.company_settings.sales_column_settings.invoice_order_prefix.concat(this.company_settings.sales_column_settings.invoice_order_suffix) + this.company_settings.sales_column_settings.invoice_order_number;
      if (this.invoiceNumber.length > 16) {
        swal("Warning", "Invoice number cant exceed 16 Digit", "warning");
        this.company_settings.sales_column_settings.invoice_order_prefix = "";
        this.company_settings.sales_column_settings.invoice_order_suffix = "";
      }
    } else if (type == "voucher") {
      let prefix = this.company_settings.sales_column_settings.voucher[indexVal].invoice_order_prefix;
      let suffix = this.company_settings.sales_column_settings.voucher[indexVal].invoice_order_suffix;
      this.invoiceNumber = prefix.concat(suffix) + this.company_settings.sales_column_settings.voucher[indexVal].invoice_order_number;
      console.log("this.invoiceNumber", this.invoiceNumber);

      if (this.invoiceNumber.toString().length > 16) {
        swal("Warning", "Invoice number cant exceed 16 Digit", "warning");
        this.company_settings.sales_column_settings.voucher[indexVal].invoice_order_prefix = "";
        this.company_settings.sales_column_settings.voucher[indexVal].invoice_order_suffix = "";
      }
    }
  }

  getVoucherSequence(data) {
    this.company_settings.sales_column_settings.voucher = [];
    data.forEach(element => {
      let condition: any = {};
      condition["company_id"] = this.company_id;
      condition["seq_name"] = "voucher_type_" + element.voucher_type;
      this.sequenceService.viewSequence(condition).subscribe((response) => {
        if (!response['response']) {
          this.addNewItem()
        } else {
          this.company_settings.sales_column_settings.voucher.push({
            voucher_type: element.voucher_type,
            invoice_order_number: response["response"]["seq_no"],
            invoice_order_prefix: response["response"]["prefix"],
            invoice_order_suffix: response["response"]["suffix"],
            invoice_ordernumber_length: response["response"]["number_length"]

          })
        }
      })
    });


  }
}
