import { HsnSettings } from "./hsn-settings.model";
export class Hsn {
  group_code: Number;
  code: Number;
  type: Number;
  description: any;
  effects_from: [HsnSettings];
  country_code: Object;
  from_date: any;
  gst_percent: any;
  chapter: Number;
}
