import { Injectable } from "@angular/core";
import { ApiService } from "../_services/api.service";
import { AuthenticationService } from "./authentication.service";
@Injectable()
export class ProductService {
  branch_id: string;
  constructor(private http: ApiService,
    private authenticationService: AuthenticationService) {
    this.branch_id = this.authenticationService.getDefaultBranchId();
  }

  addProduct(product_data: any) {
    // let product_url = ApiService.geturl('product', 'add');
    let fileArray = [];
    // fileArray.push(product_data.image);
    //console.log("=======productURL",product_url,"===========productIMG===========>",product_data.image['File']);
    if (this.branch_id != '') {
      product_data['branch_id'] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("product", "add"), product_data);
    //  return this.http.uploadFile(product_url,fileArray,product_data);
  }

  editProduct(product_data: any, id: string) {
    return this.http.post(ApiService.geturl("product", "edit"),
      product_data

    );
  }
  getProductStock(company_id: any, product_id: string) {
    console.log('8778887777');  
    return this.http.post(ApiService.geturl("stock", "view"),
    {company_id,
    product_id}
    
    );
  }
  deleteProduct(id: string) {
    return this.http.post(ApiService.geturl("product", "delete"), { id: id });
  }

  viewProduct(id: string) {
    return this.http.post(ApiService.geturl("product", "view"), {
      product_id: id
    });
  }

  getProducts(
    filter: any = {},
    sort_by: any = {},
    columns: any = {},
    page: any = {}
  ) {
    if (this.branch_id != '') {
      filter['branch_id'] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("product", "viewall"), {
      filter: filter,
      sort_by: sort_by,
      columns: columns,
      page: page
    });
  }
  getAllProducts(condition, type, columns) {
    if (this.branch_id != '') {
      condition['branch_id'] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("product", "data"), {
      condition: condition,
      type: type,
      columns: columns
    });
  }

  getPaginatedProduct(filters: any = {}, columns?: any, sort: any = {}, page: any = {}) {
    if (this.branch_id != '') {
      filters['branch_id'] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("product", "viewall"), {
      filters: filters,
      columns: columns,
      sort: sort,
      page: page
    });
  }

  addGroupSet(data, company_id) {
    let branch_id
    branch_id = this.branch_id ? this.branch_id : '';
    return this.http.post(ApiService.geturl("product", "group_add"), {
      group_name: data,
      company_id,
      branch_id
    });
  }

  getProductGroup(filter: {}, columns) {
    if (this.branch_id != '') {
      filter['branch_id'] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("product", "viewall_group"), {
      filter,
      columns
    });
  }

  uploadFiles(files, data) {
    if (this.branch_id != '') {
      data['branch_id'] = this.branch_id;
    }
    console.log("data", data);
    
    return this.http.uploadFile(ApiService.geturl('product', 'upload'),
      files,
      data
    );

  }
}
