<div class="card">
  <div class="card-header d-flex align-items-center">
    <h3 class="table-title">Sales Order {{header}}</h3>
    <h6 class="ml-3 pt-2">SO-Number:
      <span class="badge badge-primary ml-2 mb-2">
        {{sales_order.sales_order_number}}
      </span>
    </h6>
  </div>
  <div class="card-body">
    <div class="row clearfix">
      <form class="form-horizontal" (ngSubmit)="addUpdateSalesorder()" novalidate #saveSalesOrder="ngForm">
        <div class="form-row ">
          <div class="form-group required col-8 col-sm-8 col-md-8 col-xl-8">
            <label for="customer">Customer</label>
            <ng-select [items]="customer_lists" [typeahead]="customerListTypeahead" #customer="ngModel"
              (change)="setBillShipAddress();setCurrency(sales_order.customer.currency);getTotalAmount();" required
              name="customer" bindLabel="customer_name" [(ngModel)]="sales_order.customer"
              placeholder="Select Customer">
              <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div [ngOptionHighlight]="search">
                  {{ item.customer_name }} - {{item.currency?.name}}
                </div>
              </ng-template>
            </ng-select>
            <app-validation-errors [mformField]="customer" label="Customer"></app-validation-errors>
          </div>
          <div *ngIf="sales_order?.customer?.currency?.name || sales_order?.currency?.name"
            class="col-1 col-lg-1 col-lg-1 col-md-1">
            <label class="invisible">Currency</label>
            <span class="badge badge-primary">
              {{is_debit_note? sales_order.currency.name: sales_order.customer.currency.name}}
            </span>
          </div>

          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="sales_order_date">Sales Order Date</label>
            <div class="input-group">
              <input class="form-control" name="sales_order_date" placeholder="Select a date" angular-mydatepicker
                #dp_salesorder="angular-mydatepicker" (click)="dp_salesorder.toggleCalendar()"
                [(ngModel)]="sales_order.sales_order_date" [options]="salesorderDateOptions" />
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" *ngIf="sales_order.sales_order_date"
                  (click)="dp_salesorder.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp_salesorder.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>
            </div>
          </div>

          <div *ngIf="sales_order.customer" class="form-group col-12 col-sm-6 col-md-6 col-xl-6">
            <label for="billing_address">Billing Address
              <div class="d-inline-block" ngbDropdown #billingAddressDropdown>
                <a href="javascript:void(0)" id="billing_address_dropdown" ngbDropdownToggle>Change</a>
                <div ngbDropdownMenu aria-labelledby="billing_address_dropdown">
                  <app-address-template (updateAddress)="updateAddress($event, billingAddressDropdown)"
                    [addressConfig]="billingAddressListTmplConfig"></app-address-template>
                </div>
              </div>
            </label>
            <app-address-template [addressConfig]="billingAddressTemplateConfig"></app-address-template>
          </div>
          <div *ngIf="sales_order.customer" class="form-group col-12 col-sm-6 col-md-6 col-xl-6">
            <label for="shipping_address">Shipping Address
              <div class="d-inline-block" ngbDropdown #shippingAddressDropdownEvent>
                <a (click)="shippingAddressDropdownEvent.open()" href="javascript:void(0)"
                  id="shipping_address_dropdown" ngbDropdownToggle>Change</a>
                <div ngbDropdownMenu aria-labelledby="shipping_address_dropdown">
                  <app-address-template (updateAddress)="updateAddress($event, shippingAddressDropdownEvent)"
                    [addressConfig]="shippingAddressListTmplConfig"></app-address-template>
                </div>
              </div>
            </label>
            <app-address-template [addressConfig]="shippingAddressTemplateConfig"></app-address-template>
          </div>

          <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="due_date">Due Date</label>
            <div class="input-group">
              <input class="form-control" name="due_date" placeholder="Select a date" angular-mydatepicker
                #dp_duedate="angular-mydatepicker" (click)="dp_duedate.toggleCalendar()"
                [(ngModel)]="sales_order.due_date" [options]="dueDateOptions" />
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" *ngIf="sales_order.due_date"
                  (click)="dp_duedate.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp_duedate.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>
            </div>

          </div> -->
          <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                  <label for="billing_address">Billing Address</label>
                  <textarea class="form-control" name="billing_address" id="billing_address"
                    placeholder="Billing Address" [(ngModel)]="sales_order.billing_address"></textarea>

                </div>
                <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                  <label for="shipping_address">Shipping Address</label>
                  <textarea class="form-control" name="shipping_address" id="shipping_address"
                    placeholder="Shipping Address" [(ngModel)]="sales_order.shipping_address"></textarea>

                </div> -->
          <div *ngIf="company_details?.sales_column_settings?.delivery_note_display"
            class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="delivery_note">Delivery Note</label>
            <textarea class="form-control" name="delivery_note" id="delivery_note" placeholder="Delivery Note"
              [(ngModel)]="sales_order.delivery_note"></textarea>

          </div>
          <div *ngIf="company_details?.sales_column_settings?.suppliers_ref_display"
            class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="suppliers_ref">Suppliers Ref</label>
            <textarea class="form-control" name="suppliers_ref" id="suppliers_ref" placeholder="Suppliers Ref"
              [(ngModel)]="sales_order.suppliers_ref"></textarea>
          </div>
          <div *ngIf="company_details?.sales_column_settings?.others_ref_display"
            class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="others_ref">Others Ref</label>
            <textarea class="form-control" name="others_ref" id="others_ref" placeholder="Others Ref"
              [(ngModel)]="sales_order.others_ref"></textarea>
          </div>
          <div *ngIf="company_details?.sales_column_settings?.despatched_through_display"
            class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="despatched_through">Despatched Through</label>
            <input type="text" class="form-control" name="despatched_through" id="despatched_through"
              placeholder="Despatched Through" [(ngModel)]="sales_order.despatched_through">

          </div>
          <div *ngIf="company_details?.sales_column_settings?.destination_display"
            class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="destination">Destination</label>
            <input type="text" class="form-control" name="destination" id="destination" placeholder="Destination"
              [(ngModel)]="sales_order.destination">
          </div>
          <div *ngIf="company_details?.sales_column_settings?.motor_vehicle_no_display"
            class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="motor_vehicle_no">Motor Vehicle No</label>
            <input type="text" class="form-control" name="motor_vehicle_no" id="motor_vehicle_no"
              placeholder="Motor Vehicle No" [(ngModel)]="sales_order.motor_vehicle_no">
          </div>
          <div *ngIf="company_details?.sales_column_settings?.eway_bill_no_display"
            class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="eway_bill_no">Eway Bill No</label>
            <input type="text" class="form-control" name="eway_bill_no" id="eway_bill_no" placeholder="Eway Bill No"
              [(ngModel)]="sales_order.eway_bill_no">
          </div>
          <div *ngIf="company_details?.sales_column_settings?.eway_bill_date_display"
            class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="eway_bill_date">Eway Bill Date</label>
            <div class="input-group">
              <input class="form-control" name="eway_bill_date" placeholder="Select a date" angular-mydatepicker
                #dp_eway_bill_date="angular-mydatepicker" [(ngModel)]="sales_order.eway_bill_date"
                [options]="ewayBillDateOptions" />
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" *ngIf="sales_order.eway_bill_date"
                  (click)="dp_eway_bill_date.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp_eway_bill_date.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>

            </div>


            <!-- <div *ngIf="is_debit_note">
              {{sales_order.eway_bill_no"}}
            </div> -->
          </div>
          <!-- <div class="form-group col-12 required col-sm-6 col-md-3 col-xl-3" *ngIf="is_debit_note">
            <label for="debit_date">Debit Date</label>
            <div class="input-group">
              <input class="form-control" required name="debit_date" placeholder="Select a date" angular-mydatepicker
                #dp_debit_date="angular-mydatepicker" [(ngModel)]="sales_order.debit_date"
                [options]="DebitDateOptions" />
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" *ngIf="sales_order.debit_date"
                  (click)="dp_debit_date.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp_debit_date.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>
            </div>
          </div> -->
          <!-- <div class="form-group col-12 required col-sm-6 col-md-3 col-xl-3" *ngIf="is_debit_note">
            <label for="debit_notes">Debit Notes</label>
            <textarea class="form-control" name="debit_notes" id="debit_notes" placeholder="Debit Notes"
              [(ngModel)]="sales_order.debit_notes"></textarea>
          </div> -->
          <div class="card">
            <div class="card-body form-row p-0 pb-3">
              <table class="col-12 table table-stripped table-hover table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 4%;"><b>S.No</b></th>
                    <th style="width: 40%;"><b>{{company_details?.sales_column_settings?.items}}</b></th>
                    <!-- <th>{{company_details?.sales_column_settings?.description}}</th> -->
                    <th style="width: 8%;"><b>{{company_details?.sales_column_settings?.units}}</b></th>
                    <th style="width: 10%;"><b>{{company_details?.sales_column_settings?.price}}</b></th>
                    <!-- <th style="width: 8%;">Tax</th> -->
                    <th style="width: 8%;"
                      *ngIf="sales_order.is_productwise_discount && sales_order.discount_in_percentage">
                      <b>Discount-(%)
                      </b>
                    </th>
                    <th style="width: 8%;"
                      *ngIf="sales_order.is_productwise_discount && sales_order.discount_in_percentage==false">
                      <b>Discount-(flat)
                      </b>
                    </th>
                    <!-- <th style="width: 8%;" *ngIf="company_details?.sales_column_settings?.discount_display">
                            <b>{{company_details?.sales_column_settings?.discount}}</b></th> -->
                    <th style="width: 8%;"><b>Tax</b></th>
                    <th style="width: 8%;"><b>Amount</b></th>
                    <th style="width: 12%;"><b>Action</b></th>
                  </tr>

                </thead>
                <tbody *ngFor="let itemsObj of sales_order.sales_order_items; let i = index">
                  <tr>
                    <td>
                      {{i+1}}
                    </td>
                    <td>
                      <!-- validateProduct(); -->
                      <ng-select *ngIf="!is_debit_note" [items]="product_lists" [typeahead]="productListTypeahead"
                        #product="ngModel" required name="itemsObj.product{{i}}" bindLabel="name"
                        [(ngModel)]="itemsObj.product"
                        (change)="getPriceFromHSN(itemsObj.product,itemsObj);getAmount(itemsObj);getProductQty(itemsObj.product,itemsObj);"
                        placeholder="Select product">
                        <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index"
                          let-search="searchTerm">
                          <div [ngOptionHighlight]="search">
                            {{ item.name }}
                          </div>
                        </ng-template>
                      </ng-select>
                      <app-validation-errors [mformField]="product" label="Product"></app-validation-errors>
                      <textarea type="text" class="form-control mt-1" name="description{{i}}" placeholder="Description"
                        [(ngModel)]="itemsObj.description"></textarea>
                    </td>
                    <!-- <td>
                            <input type="text" class="form-control" name="description{{i}}" placeholder="Description"
                              [(ngModel)]="itemsObj.description">
                          </td> -->
                    <td>
                      <input [disabled]="itemsObj?.product?.type == 2" type="number" [min]="1" class="form-control"
                        #units="ngModel" required name="itemsObj.units{{i}}" placeholder="Units"
                        (change)="calculatePrice(itemsObj);getProductQty(itemsObj.product,itemsObj)"
                        [(ngModel)]="itemsObj.units">
                      <app-validation-errors [mformField]="units" label="Units"></app-validation-errors>
                    </td>
                    <td>
                      <input type="number" [min]="1" class="form-control" #price="ngModel" required
                        name="itemsObj.price{{i}}" placeholder="Price" (change)="calculatePrice(itemsObj);"
                        [(ngModel)]="itemsObj.price">
                      <app-validation-errors [mformField]="price" label="Price"></app-validation-errors>
                    </td>
                    <td *ngIf="sales_order.is_productwise_discount && sales_order.discount_in_percentage">
                      <input type="number" *ngIf="!is_debit_note" class="form-control"
                        name="itemsObj.discount_in_percentage{{i}}" placeholder="Discount in percentage"
                        (change)="calculatePrice(itemsObj);" [(ngModel)]="
                        itemsObj.discount_in_percentage" title="Discount in percentage">
                      <span *ngIf="is_debit_note">
                        {{itemsObj.discount_in_percentage}}
                      </span>

                    </td>


                    <td *ngIf="sales_order.is_productwise_discount && sales_order.discount_in_percentage==false">
                      <input type="number" class="form-control" *ngIf="!is_debit_note"
                        name="itemsObj.discount_in_amount{{i}}" placeholder="Discount in Amount"
                        (change)="calculatePrice(itemsObj);" [(ngModel)]=" itemsObj.discount_in_amount"
                        title="Discount in Amount">
                      <span *ngIf="is_debit_note">
                        {{itemsObj.discount_in_amount}}
                      </span>
                    </td>

                    <td>
                      {{itemsObj.tax_amount}}

                      <span *ngIf="itemsObj.tax!=undefined"
                        title="sgst:{{itemsObj.sgst}} cgst:{{itemsObj.cgst}} igst:{{itemsObj.igst}}">
                        ({{itemsObj.tax}}%)
                      </span>
                    </td>

                    <td>
                      {{(itemsObj.item_price + itemsObj.tax_amount)}}
                    </td>

                    <td>
                      <button type="button" class="btn btn-danger" (click)="deleteItem(i);getTotalAmount();">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </button>
                      <button type="button" class="btn btn-primary mr-2 ml-2" (click)="addNewItem()">
                        <i class="fa fa-plus"></i></button>
                    </td>
                  </tr>

                </tbody>
              </table>
              <div class="float-right" style="display: inline-block !important;" *ngIf="sales_order.is_discount==true && sales_order.discount_in_percentage==true &&
                sales_order.is_productwise_discount==false">
                <label for="overall_discount_in_percentage">Discount In Percentage:</label>
                <input type="number" [min]="1" [max]="100" [(ngModel)]="overall_discount_in_percentage"
                  (change)="calculateDisount()" class="form-control" name="overall_discount_in_percentage">
              </div>
            </div>

            <div class="row justify-content-between">
              <div class="form-group col-sm-4 col-md-4 col-xl-6">

                <div class="pb-4 form-group col-12 custom-switch col-sm-6 col-md-3 col-xl-4 pl-5"
                  *ngIf="!is_debit_note">
                  <input type="checkbox" value="true" [(ngModel)]="sales_order.is_discount" name="is_discount"
                    class="custom-control-input" (change)="getCoaData();unSetProductWiseDiscount();getTotalAmount();"
                    id="is_discount">
                  <label class="custom-control-label" for="is_discount">Discount ?</label>
                </div>
                <div class="pb-4 form-group col-12 custom-switch col-sm-6 col-md-3 col-xl-6 pl-5"
                  *ngIf="sales_order.is_discount==true && !is_debit_note">
                  <input type="checkbox" value="true" [(ngModel)]="sales_order.is_productwise_discount"
                    name="is_productwise_discount" (change)="unSetProductWiseDiscount();getCoaData();"
                    class="custom-control-input" id="is_productwise_discount">
                  <label class="custom-control-label" for="is_productwise_discount">Product-Wise Discount</label>
                </div>
                <div class="pb-4 form-group col-12 custom-switch col-sm-6 col-md-3 col-xl-2"
                  *ngIf="sales_order.is_discount==true && !is_debit_note">
                  <input type="checkbox" value="true" [(ngModel)]="sales_order.discount_in_percentage"
                    name="discount_in_percentage" (change)="unSetProductWiseDiscount();" class="custom-control-input"
                    id="discount_in_percentage">
                  <label class="custom-control-label" for="discount_in_percentage">Discount In %</label>
                </div>
                <!-- <div class="pb-3 form-group col-12 custom-switch pl-5">
                  <input type="checkbox" (click)="changePos()" value="true" [(ngModel)]="is_pos" name="is_pos"
                    class="custom-control-input pl-5" id="is_pos">
                  <label class="custom-control-label" for="is_pos">I have received the payment</label>
                </div> -->
                <div class="form-group col-12">
                  <label for="credit_notes">Notes</label>
                  <textarea class="form-control notes" name="credit_notes" id="credit_notes" placeholder="Notes"
                    [(ngModel)]="sales_order.credit_notes"></textarea>
                </div>
              </div>

              <table class="float-right table col-4">
                <tbody>
                  <tr>
                    <th><b>Actual Total Amount</b></th>
                    <td class="money"><b>{{sales_order.actual_total_amount| currency: currency}}</b></td>
                  </tr>
                  <tr *ngIf="sales_order.is_discount==true">
                    <th><b>Total Discount Amount</b></th>
                    <td class="money"><b>{{sales_order.discount| currency: currency}}</b></td>
                  </tr>
                  <tr>
                    <th><b>Sub Total Amount</b></th>
                    <td class="money"><b>{{sales_order.sub_total_amount| currency: currency}}</b></td>
                  </tr>

                  <tr *ngIf="is_same_state">
                    <th><b>CGST Amount</b></th>
                    <td class="money"><b>{{sales_order.cgst| currency: currency}}</b></td>
                  </tr>
                  <tr *ngIf="is_same_state">
                    <th><b>SGST Amount</b></th>
                    <td class="money"><b>{{sales_order.sgst| currency: currency}}</b></td>
                  </tr>
                  <tr *ngIf="is_same_state==false">
                    <th><b>IGST Amount</b></th>
                    <td class="money"><b>{{sales_order.igst| currency: currency}}</b></td>
                  </tr>
                  <tr>
                    <th><b>Total Tax Amount</b></th>
                    <td class="money"><b>{{sales_order.total_tax_amount| currency: currency}}</b></td>
                  </tr>

                  <tr>
                    <th><b>Total Amount</b></th>
                    <td class="money"><b>{{sales_order.total_amount| currency: currency}}</b></td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>

        </div>
        <button type="submit" class="btn btn-primary" [disabled]="!saveSalesOrder.valid || loading==true">Save Sales
          Order
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
        </button>
      </form>
    </div>
  </div>
</div>