import { Component, OnInit, EventEmitter } from "@angular/core";
import { CustomerService } from "../../../_services/customer.service";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { Receipts } from "../../../_models/receipts";
import { MastersService } from "src/app/_services/masters.service";
import { CompanyService } from "src/app/_services/company.service";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { ReceiptsService } from "src/app/_services/receipts.service";
import swal from "sweetalert2";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { ConstantService } from "../../../_config/constants";
import { AccountsHeadTemplate } from "../../../_models/accounts-head-template.model";
import { InvoiceService } from "src/app/_services/invoice.service";
// import { Params } from "@megamtech-opensource/datalytics/lib/models/report-customization";
import { id } from "@swimlane/ngx-datatable";
import { differenceInDays } from "date-fns";

@Component({
  selector: "app-receipts-customer-add",
  templateUrl: "./receipts-customer-add.component.html",
  styleUrls: ["./receipts-customer-add.component.scss"],
})
export class ReceiptsCustomerAddComponent implements OnInit {
  loading: boolean = false;
  receipts: Receipts = new Receipts();
  payment_dateDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };
  curren_tx_dateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    disableSince: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };
  customer: object;
  customer_lists: any;
  company_id: "";
  // payment_methodList: any = [
  //   { name: "Cash", id: 1 },
  //   { name: "Bank", id: 2 },
  // ];
  payment_methodList: any = [
    { name: "Cash", id: 1031 },
    { name: "Bank", id: 1030 },
    //   { name: "Check", id: 3 }
  ];
  customerListTypeahead = new EventEmitter<string>();
  payment_accountList: any;
  company_details: any;
  receiptCustomerAccountConfig: AccountsHeadTemplate = new AccountsHeadTemplate();
  page: any = { offset: 0, pageSize: 10 };
  filters: any = {};
  sort: any = {};
  columns: any = {};
  count: any;
  invoiceList: any;
  id: string;
  is_view_form: boolean = false;
  header: string;
  receiptList: any = [];
  selectFiYear: any;
  constructor(
    private customerService: CustomerService,
    private authenticationService: AuthenticationService,
    private countryservice: MastersService,
    private companyService: CompanyService,
    private receiptService: ReceiptsService,
    private router: Router,
    private route: ActivatedRoute,
    private invoiceService: InvoiceService
  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];
    this.receipts.payment_method = this.payment_methodList;

    this.getCustomers("").subscribe((result) => {
      this.customer_lists = result;
    });
    this.receipts.payment_date={
      isRange: false,
      singleDate: {
        jsDate: differenceInDays(new Date(this.selectFiYear.end_year, 2, 31), new Date()) > 0 && differenceInDays(new Date(), new Date(this.selectFiYear.start_year, 3, 1)) > 0 ? new Date() : new Date(this.selectFiYear.end_year, 2, 31),
      },
    };

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get("id");
      if (this.id != null) {
        this.getReceiptCustomer();
      }
      var result = this.router.url.match("/view/");
      if (result != null) {
        if (result[0] === "/view/" && this.id != null) {
          this.is_view_form = true;
          this.header = "View";
        }
      }
    })
    this.getCompanyDetails();
    this.setInvoiceAccountHeaderConfig();
  }


  getInvoices(pageInfo: any = {}) {
    if (pageInfo !== null) {
      this.page = pageInfo;
    }
    this.filters["company_id"] = this.company_id;
    this.filters["customer_muid"] = this.receipts.customer._id;
    return this.invoiceService
      .getInvoices(this.filters, this.columns, this.sort, this.page)
      .subscribe((result) => {
        this.invoiceList = result["response"]["data"];
        this.count = result["response"]["count"];
      });
  }
  getReceiptCustomer() {
    let condition: any = {};
    condition['company_id'] = this.company_id;
    return this.receiptService.viewPurchaseAdvance(this.id, condition)
      .subscribe((result) => {
        console.log('result=====>>>>>>>>', result);
        this.receiptList = result;
      })
  }
  getCustomers(searchString) {
    let condition: any = {};
    let columns: any = {};
    if (searchString != "") {
      condition["customer_name"] = searchString;
    }
    condition["company_id"] = this.company_id;
    columns["customer_name"] = 1;
    columns["first_name"] = 1;
    columns["last_name"] = 1;
    columns["currency"] = 1;
    columns["address_line1"] = 1;
    columns["billing_address"] = 1;
    columns["shipping_address"] = 1;
    columns["payment_term"] = 1;
    columns["gst_number"] = 1;
    columns["country"] = 1;
    columns["state"] = 1;
    columns["coa_data"] = 1;
    columns["phone"] = 1;
    return this.customerService.getCustomersData(condition, columns);
  }

  getPaymentInChange() {
    this.getPaymentCoaHead("").subscribe((result) => {
      this.payment_accountList = result["response"];
    });
  }
  getPaymentCoaHead(searchString) {
    this.payment_accountList = [];
    return this.countryservice.getCoaHead(this.company_id, searchString, {
      in: [this.receipts.payment_method["name"]],
    });
  }
  onpayment_dateDateChanged(event: IMyDateModel): void {
    // date selected
  }
  addCustomerReceipts() {
    this.loading = true;
    console.log("1111111111", this.receipts.customer);
    this.receipts.type = "customer";
    this.receipts["party"] = {
      id: this.receipts.customer["_id"],
      name: this.receipts.customer["customer_name"],
      coa_id: this.receipts.customer["coa_data"]["id"],
    };

    this.receipts.company_id = this.company_id;
    this.receipts['tx_type_id'] = 61;

    this.receiptService
      .addCustomerReceipts(this.receipts)
      .subscribe((result) => {
        if (result["ok"] == 1) {
          this.loading = false;
          swal("Success", "Advance Added Successfully", "success");
          this.router.navigateByUrl("/receipt/customer/list");
        } else {
          this.showErrors(result[" message "]);
        }
      });
  }
  showErrors(errors) {
    let error_string: string;
    error_string = " ";
    errors.forEach((item) => {
      error_string += " < br / > " + item;
    });
    swal({
      title: "Error",
      html: error_string,
      type: "error",
    }).then(function () { });
  }
  book_pay() {
    if (this.receipts.invoice_number != "") {
      this.receipts.type = "customer";
      this.receipts["party"] = {
        id: this.customer["_id"],
        name: this.customer["customer_name"],
        coa_id: this.customer["coa_data"]["id"],
      };
      this.receipts.bill_number = this.receipts.invoice_number;
      this.receipts.company_id = this.company_id;
      this.receiptService.book_pay(this.receipts).subscribe((result) => {
        if (result["response"]["status_code"] == 1) {
          swal(
            "Success",
            "Advance added and payment done for bill successfully ",
            "success"
          );
          this.router.navigateByUrl("/receipt/customer/list");
        } else {
          swal(
            "Error",
            "Advance not paid for the bill ! Something went wrong ",
            "error"
          );
        }
      });
    } else {
      swal("Error", "Enter the invoice number", "error");
    }
  }
  getCompanyDetails() {
    this.companyService.viewCompany(this.company_id).subscribe((result) => {
      this.company_details = result["response"];
      this.payment_dateDpOptions.dateFormat = this.company_details.date_format;
      let book_beginning_date = new Date(this.company_details.book_beginning);
      book_beginning_date.setDate(book_beginning_date.getDate() - 1);
      let curren_tx_dateOptions = this.getCopyOfOptions();
      curren_tx_dateOptions.disableUntil = {
        year: book_beginning_date.getFullYear(),
        month: book_beginning_date.getMonth() + 1,
        day: book_beginning_date.getDate(),
      };
      curren_tx_dateOptions.disableUntil = {year:this.selectFiYear.start_year, month:3, day:31};
      curren_tx_dateOptions.disableSince = {year:this.selectFiYear.end_year, month:4, day:1};
      this.payment_dateDpOptions = curren_tx_dateOptions;
    });
  }
  getCopyOfOptions(): IAngularMyDpOptions {
    return JSON.parse(JSON.stringify(this.payment_dateDpOptions));
  }
  updateLedger(ledger: any) {
    this.receipts.ledger.name = ledger.flattened_data.name;
    this.receipts.ledger.id = ledger.flattened_data.id;
    this.receipts.ledger.ref_id = ledger.flattened_data["_id"];
    this.receipts.ledger.parent_id = ledger.flattened_data.parent_id;
    this.receipts.ledger.parent_name = ledger.flattened_data.parent_name;
  }

  setInvoiceAccountHeaderConfig() {
    this.receiptCustomerAccountConfig.accountsHeads = [4015, 4016];
    this.receiptCustomerAccountConfig.is_required = true;
    this.receiptCustomerAccountConfig.field_name = "bill_accounts";
    this.receiptCustomerAccountConfig.label_name = "Bill Accounts";
  }
}
