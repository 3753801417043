import { Component, OnInit } from '@angular/core';
import { UserService } from '../../_services/user.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  username: string;
  constructor(private userService: UserService) { }

  ngOnInit() {
  }

  forgotPassword() {
    console.log(this.username);
    if (this.username != undefined) {
      this.userService.forgotPassword(this.username).subscribe(result => {
        if (result['status_code'] === 1) {
          swal('Success', 'Password sent to your email successfully', 'success');
          this.username = '';
        } else {
          swal('Warning !', result['response'], 'warning');
        }
      });
    } else {
      swal('Warning !', 'Please Enter Valid User Name', 'warning');
    }
  }
}
