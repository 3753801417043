import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef
} from "@angular/core";
import swal from "sweetalert2";
import { ContraService } from "../_services/contra.service";
import { MastersService } from "../_services/masters.service";
import { ConstantService } from "src/app/_config/constants";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { AuthenticationService } from "../_services/authentication.service";
@Component({
  selector: "app-contra",
  templateUrl: "./contra.component.html",
  styleUrls: ["./contra.component.scss"]
})
export class ContraComponent implements OnInit {
  filters: any = {};
  contra: any = {};
  contraList: any = [];
  contraListColumns: any = [];
  loadingIndicator = true;
  reorderable = true;
  page: any = { offset: 0, pageSize: 10 };
  from_bankList: any = [];
  fromBankListTypeahead = new EventEmitter<string>();
  to_bankList: any = [];
  currency: string;
  columns: any = {};
  page_icon: any;
  sort: any = { contra_id: -1 };
  dt_default_sort = [{ prop: "contra_id", dir: "desc" }];
  toBankListTypeahead = new EventEmitter<string>();
  company_id: string;
  branch_id: string;
  dispCheckBox: boolean = true;
  contraItems: any = [];
  expanded: any = {};
  tx_dateDpOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: true,
    dateFormat: ConstantService.DateFormat
  };
  // @ViewChild('actionTmpl', { static: true }) actionTmpl: TemplateRef<any>;
  @ViewChild('myTable', { static: true }) table: any;
  companyDetails: any;
  selectFiYear: any;
  constructor(
    private contraservice: ContraService,
    private masterService: MastersService,
    private authenticationService: AuthenticationService,
    private cd: ChangeDetectorRef,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.companyDetails = this.authenticationService.getCompanyDetails();
    this.currency = this.companyDetails.base_currency;

    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];
    this.filters.tx_date = {
      isRange: true,
      singleDate: null,
      dateRange: {
        beginDate: { year: this.selectFiYear.start_year, month: 4, day: 1 },
        beginJsDate: new Date(this.selectFiYear.start_year, 3, 1),
        endDate: { year: this.selectFiYear.end_year, month: 3, day: 31 },
        endJsDate: new Date(this.selectFiYear.end_year, 2, 31),
      }
    };

    this.currency = this.authenticationService.getCompanyDetails()[
      "base_currency"
    ];
    this.contraListColumns = [
      {
        name: "Contra ID",
        prop: "contra_id",
        sortable: "contra_id",
        flexGrow: 2,
        show: true,
        field_name: "contra_id",
        is_detailed_row: false
      },
      {
        name: "Transaction Date",
        prop: "tx_date",
        sortable: "false",
        flexGrow: 2,
        type: 'date',
        show: true,
        is_detailed_row: false
      },
      // {
      //   name: "Account Head",
      //   prop: "contra_items",
      //   sortable: "false",
      //   flexGrow: 2,
      //   show: true,
      //   field_name: 'account_head',
      //   type: "custom",
      //   is_detailed_row: false
      // },
      // {
      //   name: "Debit",
      //   prop: "debit",
      //   sortable: "amount",
      //   flexGrow: 2,
      //   show: true,
      //   is_detailed_row: false,
      //   field_name: 'debit',
      //   type: "currency",
      //   // type: "custom",
      //   css_class: "align-right"
      // },
      // {
      //   name: "Credit",
      //   prop: "credit",
      //   sortable: "amount",
      //   flexGrow: 2,
      //   show: true,
      //   is_detailed_row: false,
      //   field_name: 'credit',
      //   type: "currency",
      //   css_class: "align-right"
      // },
      {
        name: "Action",
        type: "action",
        prop: "muid",
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false
      }
    ];
    this.contraListColumns.forEach(column => {
      this.columns[column.prop] = 1;
    });
    this.page_icon = this.route.snapshot.data.menu.icon;
    if (this.route.snapshot.queryParams["q"] != "undefined")
      this.filters["vendor"] = this.route.snapshot.queryParams["q"];
    this.getContrasList(this.page);
    this.getBankTo("").subscribe(result => {
      this.to_bankList = result["response"];
    });
    this.getBankToSearch();
    this.getBankFrom("").subscribe(result => {
      this.from_bankList = result["response"];
    });
    this.getBankFromSearch();
  }

  onSort(event) {
    this.loadingIndicator = true;
    this.sort = {};
    let sort_details = event.sorts[0];
    this.sort[sort_details.prop] = sort_details.dir == "asc" ? 1 : -1;
    this.getContrasList(this.page);
  }

  getContrasList(page_info: any) {
    if (page_info != null) {
      this.page = page_info;
    }
    this.filters["company_id"] = this.company_id;
    if (this.branch_id != '') {
      this.filters["branch_id"] = this.branch_id;
    }
    this.contraItems = [];
    this.columns['contra_items'] = 1;
    this.contraservice
      .getContras(this.filters, this.columns, this.sort, this.page)
      .subscribe(result => {
        this.loadingIndicator = false;
        this.contraList = result["data"];
        // this.contraList.forEach(element => {
        //   element.contra_items.forEach(contra_items => {
        //     // contra_items['contra_id'] = element['contra_id'];
        //     let data: any = {}
        //     data['contra_id'] = element['contra_id'];
        //     data['credit'] = contra_items['credit'];
        //     data['debit'] = contra_items['debit'];
        //     if (contra_items['account_head']['flattened_data']) {
        //       data['account_head'] = contra_items['account_head']['flattened_data']['name'];
        //     }
        //     this.contraItems.push(data);

        //   });
        // });
        // console.log('contra List', this.contraItems);
        // this.page.count = this.contraItems.length;
        this.page.count = result["count"];
      });
  }

  getBankTo(searchString) {
    this.to_bankList = [];
    return this.masterService.getCoaHead(this.company_id, searchString, {
      in: [ConstantService.coa_heads.Bank, ConstantService.coa_heads.Cash]
    });
  }
  getBankToSearch() {
    this.toBankListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(searchString => this.getBankTo(searchString))
      )
      .subscribe(
        result => {
          this.cd.markForCheck();
          this.to_bankList = result["response"];
        },
        err => {
          console.log(err);
          this.to_bankList = [];
        }
      );
  }

  getBankFrom(searchString) {
    this.from_bankList = [];
    return this.masterService.getCoaHead(this.company_id, searchString, {
      in: [ConstantService.coa_heads.Bank, ConstantService.coa_heads.Cash]
    });
  }

  getBankFromSearch() {
    this.fromBankListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(searchString => this.getBankFrom(searchString))
      )
      .subscribe(
        result => {
          this.cd.markForCheck();
          this.from_bankList = result["response"];
        },
        err => {
          console.log(err);
          this.from_bankList = [];
        }
      );
  }

  clearContraFilters() {
    this.filters = {};
    this.getContrasList({ offset: 0, pageSize: 10 });
  }
  deleteContra(muid) {
    swal({
      title: "Are you sure want to delete?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result) {
        this.contraservice.deleteContra(muid).subscribe(result => {
          if (result["ok"] === 1) {
            swal("Deleted!", "Contra has been deleted.", "success");
            this.getContrasList({});
          }
        });
      }
    });
  }
  onDetailToggle(event) {
    // console.log('event', event)
  }
  toggleExpandRow(row) {
    // console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }
}
