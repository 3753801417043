import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { SalesorderService } from "../../_services/salesorder.service";
import { AuthenticationService } from "../../_services/authentication.service";
import { PaymentSettings } from "../../_models/payment-settings.model";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { PurchaseorderService } from "../../_services/purchaseorder.service";
import { Router, ActivatedRoute } from "@angular/router";
import { CustomerService } from "../../_services/customer.service";
import {
  distinctUntilChanged,
  debounceTime,
  switchMap,
  filter,
} from "rxjs/operators";

import swal from "sweetalert2";
import { ConstantService } from "../../_config/constants";

@Component({
  selector: "app-sales-order",
  templateUrl: "./sales-order.component.html",
  styleUrls: ["./sales-order.component.scss"],

  providers: [NgbModalConfig, NgbModal]
})
export class SalesOrderComponent implements OnInit {
  filters: any = {};
  page: any = { offset: 0, pageSize: 10 };
  sort: any = { sales_order_number: -1 };
  dt_default_sort = [{ prop: "sales_order_number", dir: "desc" }];
  sales_order_lists: any = [];
  columns: any = {};
  salesOrderTableListColumns: any = [];
  count: number;
  loadingIndicator = true;
  dispCheckBox: boolean = true;
  reorderable = true;
  dueDateOptions: IAngularMyDpOptions = {
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat
  };
  salesorderDateOptions: IAngularMyDpOptions = {
    dateRange: true,
    disableUntil: { year: 0, month: 0, day: 0 },
    disableSince: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat
  };
  model: IMyDateModel = null;
  // @ViewChild("SalesorderTable", { static: false }) table: any;
  currency: string;
  selected_items = [];
  selectedOrder: any;
  payType: string = "";
  selecteditem_array: any;
  item_selected: any;
  headList: any;
  balance_amt: number = 0;
  sum_of_bal_qty: number = 0;
  transaction_head: string;
  tx_date: IMyDateModel;
  pay_head: string;

  public payment = new PaymentSettings();
  invoice_array: any = [];

  // @ViewChild("actionTmpl", { static: true }) actionTmpl: TemplateRef<any>;
  customer_lists: any = [];
  company_id: string;
  check_status: boolean;
  branch_id: any;
  id: any;
  customerListTypeahead = new EventEmitter<string>();
  soNumberList: any[];
  soNumberListTypeahead = new EventEmitter<string>();
  sales_status_list: any = [
    { name: "Not-Invoiced", id: 1 },
    { name: "Invoiced", id: 2 },
    { name: "Partially-Invoiced", id: 3 },
  ];
  companyDetails: any;
  user_id: any;
  selectFiYear:any;
  getCompanyDetails: any;
  constructor(
    private salesorderService: SalesorderService,
    private purchaseorderservice: PurchaseorderService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    public route: ActivatedRoute,
    public customerService: CustomerService,
    public cd: ChangeDetectorRef,
    private router: Router
  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.companyDetails = this.authenticationService.getCompanyDetails();
    this.currency = this.companyDetails.base_currency;


    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];
    this.filters.so_date = {
      isRange: true,
      singleDate: null,
      dateRange: {
        beginDate: { year: this.selectFiYear.start_year, month: 4, day: 1 },
        beginJsDate: new Date(this.selectFiYear.start_year, 3, 1),
        endDate: { year: this.selectFiYear.end_year, month: 3, day: 31 },
        endJsDate: new Date(this.selectFiYear.end_year, 2, 31),
      }
    };

    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];
    this.getCustomers("").subscribe((result) => {
      this.customer_lists = result["response"]["data"];
    });
    this.getCustomersSearch();
    this.getSONumberList("").subscribe((result) => {
      this.soNumberList = result["response"]["data"];
    });
    this.getSONumberListSearch();
    this.salesOrderTableListColumns = [
      {
        prop: "sales_order_number",
        name: "SO Number",
        sortable: "sales_order_number",
        flexGrow: 1,
        show: true,
        is_detailed_row: false
      },
      {
        prop: "customer",
        name: "Customer",
        sortable: "customer_name",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "object",
        field_name: "customer_name"
      },
      {
        prop: "sales_order_date",
        name: "SO Date",
        sortable: "sales_order_date",
        flexGrow: 1,
        show: true,
        type: "date",
        is_detailed_row: false
      },

      // {
      //   prop: "due_date",
      //   name: "Due Date",
      //   sortable: "due_date",
      //   flexGrow: 1,
      //   show: true,
      //   type: "date",
      //   is_detailed_row: false
      // },
      {
        prop: "total_amount",
        name: "Total Amount",
        sortable: "total_amount",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "currency",
        css_class: "align-right"
      },
      // {
      //   prop: "balance_amount",
      //   name: "Balance Due",
      //   sortable: "balance_due",
      //   flexGrow: 1,
      //   show: true,
      //   is_detailed_row: false,
      //   type: "currency",
      //   css_class: "align-right"
      // },
      {
        prop: "sales_status_name",
        name: "Sales Status",
        sortable: "sales_status_name",
        flexGrow: 1,
        show: true,
        type: 'status',
        is_detailed_row: false
      },
      {
        name: "Action",
        type: "action",
        prop: "_id",
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false
      }
    ];
    this.salesOrderTableListColumns.forEach(column => {
      this.columns[column.prop] = 1;
    });
    if (this.route.snapshot.queryParams["q"] != "undefined")
      this.filters["customer_name"] = this.route.snapshot.queryParams["q"];
    this.getSalesordersList(this.page);
    this.tx_date = { isRange: false, singleDate: { jsDate: new Date() } };
  }

  viewPurchaseorder() {
    this.router.navigateByUrl("/sales-order/view");
  }

  getSONumberList(searchString: string) {
    let condition: any = {};
    condition["company_id"] = this.company_id;
    // if (searchString != "") {
    condition["so_number_search_string"] = searchString;
    // }
    return this.salesorderService.getSalesorders(
      condition,
      { sales_order_number: 1 },
      {},
      { offset: 0, limit: 10 }
    );
  }

  getSONumberListSearch() {
    this.soNumberListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getSONumberList(searchString))
      )
      .subscribe(
        (result) => {
          this.soNumberList = [];
          this.cd.markForCheck();
          this.soNumberList = result["response"]["data"];
        },
        (err) => {
          console.log(err);
          this.soNumberList = [];
        }
      );
  }

  getCustomers(searchString) {
    this.customer_lists = [];
    let condition: any = {};
    let columns: any = {};
    if (searchString != "") {
      condition["customer_name"] = searchString;
    }
    condition["company_id"] = this.company_id;
    columns["customer_name"] = 1;
    columns["first_name"] = 1;
    columns["last_name"] = 1;
    columns["currency"] = 1;
    columns["address_line1"] = 1;
    columns["address_line2"] = 1;
    columns["valid_upto"] = 1;
    columns["country"] = 1;
    columns["state"] = 1;
    columns["phone"] = 1;
    columns["coa_data"] = 1;
    columns["credit_note_data"] = 1;
    columns["is_sez"] = 1;
    return this.customerService.getCustomers(condition, columns, {}, { limit: 10 });
  }
  getCustomersSearch() {
    this.customerListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getCustomers(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.customer_lists = result["response"]["data"];
        },
        (err) => {
          console.log(err);
          this.customer_lists = [];
        }
      );
  }

  open(content, id) {
    this.id = id;
    this.modalService.open(content, {size: "lg"});
  }

  onSort(event) {
    this.loadingIndicator = true;
    // console.log("event", event);
    this.sort = {};
    let sort_details = event.sorts[0];
    this.sort[sort_details.prop] = sort_details.dir == "asc" ? 1 : -1;
    this.getSalesordersList(this.page);
  }

  getSalesordersList(pageInfo: any = {}) {
    if (pageInfo !== null) {
      this.page = pageInfo;
    }
    this.filters["company_id"] = this.company_id;
    if (this.branch_id != '') {
      this.filters["branch_id"] = this.branch_id;
    }
    this.salesorderService
      .getSalesorders(this.filters, this.columns, this.sort, this.page)
      .subscribe(result => {
        // console.log('result',result);
        this.loadingIndicator = false;
        this.sales_order_lists = result['response']["data"];
        this.count = result['response']["count"];
      });
  }
  clearPurchaseorderFilters() {
    this.filters = {};
    this.getSalesordersList({ offset: 0, pageSize: 10 });
  }

  onSelectOrders({ selected }) {
    this.selecteditem_array = [];

    this.selected_items = [];

    selected.forEach(element => {
      this.selected_items.push(element.customer._id);
    });

    this.item_selected = this.selected_items[0];
    if (this.selected_items.length > 1) {
      this.payType = "multiple";
    } else {
      this.payType = "single";
    }
    this.selecteditem_array = selected;
  }
  payAmountModal(payAmount) {
    this.invoice_array = [];
    this.invoice_array.amount = 0;
    this.balance_amt = 0;
    this.invoice_array.debit = 0;

    if (this.selected_items.length == 1 || this.selected_items.length > 1) {
      this.payment.company_id = this.company_id;
      this.payment.customer_name = this.selecteditem_array[0].customer.customer_name;
      this.payment.customer_id = this.selecteditem_array[0].customer;
      if (this.payType == "single") {
        this.modalService.open(payAmount, { size: "lg" });
      } else if (this.payType == "multiple") {
        let passed = this.selected_items.every(this.isEqualVendor);
        if (passed) {
          this.modalService.open(payAmount, { size: "lg" });
        } else {
          swal("Error!", "Please select same Customer.", "error");
        }
      } else {
        swal("Error!", "Please select an order.", "error");
      }

      this.selecteditem_array.forEach(element => {
        this.payment.currency = element.currency;
        this.balance_amt = this.balance_amt + parseInt(element.balance_amount);
        this.payment.total_payment_amount = this.balance_amt;
        this.invoice_array.push({
          sales_order_number: element.sales_order_number,
          sales_order_date: element.sales_order_date,
          sales_id: element._id,
          amount: element.balance_amount,
          credit: element.balance_amount,
          debit: 0
        });
      });
    } else {
      swal("Error!", "Please select an order.", "error");
    }
  }
  isEqualVendor(element, index, array) {
    return element == array[0];
  }

  payPurchaseOrder() {
    this.sum_of_bal_qty = this.invoice_array.reduce(function (r, a) {
      return r + a["credit"];
    }, 0);
    if (this.invoice_array.length > 1) {
      if (this.sum_of_bal_qty == this.payment.total_payment_amount) {
        this.check_status = true;
      } else {
        this.check_status = false;
      }
    } else {
      this.check_status = true;
    }
    if (this.check_status) {
      // console.log("thisad========>>", this.payment);
      this.payment.account_head = this.transaction_head;
      this.payment.payment_array = this.invoice_array;
      this.payment.tx_date = this.tx_date["singleDate"]["jsDate"];
      this.payment.tx_type = 11;
      this.payment.party_id = [
        {
          customer_id: this.payment.customer_id["_id"]
        }
      ];

      this.purchaseorderservice
        .createPayment(this.payment)
        .subscribe(result => {
          if (result["statusCode"] == 200) {
            swal("Success!", "Payment Successful.", "success");
          } else {
            swal("Error!", "Payment Unsuccessful.", "error");
          }
        });
    } else {
      swal("Error!", "The amount doesnt tally during split up.", "error");
    }
  }
  getPaymentHead() {
    if (this.payment.payment_mode == 1) {
      this.pay_head = "Bank";
    } else if (this.payment.payment_mode == 2) {
      this.pay_head = "Cash";
    }

    this.headList = [
      {
        flattened_data: {
          name: "Account Payable",
          id: "gjgj7779"
        }
      }
    ];

    // this.masterService
    //   .getCoaHead(this.company_id, this.pay_head)
    //   .subscribe(result => {
    //     this.headList = result["response"];
    //   });
  }
}
