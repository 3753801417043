import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { MastersService } from "../../_services/masters.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import swal from "sweetalert2";
import { HsnService } from "src/app/_services/hsn.service";
import { CostgroupService } from "src/app/_services/costgroup.service";
import { BillService } from "src/app/_services/bill.service";
import { PaymenttransactionsService } from "src/app/_services/paymenttransactions.service";
import { isArray } from "util";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { AuthenticationService } from "../../_services/authentication.service";
import { FixedAssestsService } from "../../_services/fixed-assests.service";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { element } from "protractor";
import { ConstantService } from "../../_config/constants";

interface TreeNode {
  label: string;
  child: TreeNode[];
}
@Component({
  selector: "app-coa",
  templateUrl: "./coa.component.html",
  styleUrls: ["./coa.component.scss"],
})
export class CoaComponent implements OnInit {
  is_add_ledger_btn_disabled = false;
  @Input() company_id: String | Number = '';
  chart_of_accounts: any = [];
  coa_list: any;
  selectedTreeNode: any;
  flattened_data: any;
  last_sequence: any;
  is_ledger: boolean = false;
  is_coa_add: boolean = false;
  is_fixed_assets: boolean = false;
  is_gst: boolean = false;
  is_cost_center: boolean = false;
  hsncodeArray: any = [];
  fixedAssetsArray: any = [];
  hsnListTypeahead = new EventEmitter<string>();
  fixedAssetsListTypeahead = new EventEmitter<string>();
  costCenterListTypeahead = new EventEmitter<string>();
  hsn_code: any;
  group_name: any;
  cost_center_list: any = [];
  cost_center_id: any;
  fixedAssets: any;
  is_openingbalance_amt: boolean = false;
  credit_sum: number = 0;
  debit_sum: number = 0;
  balance: number;
  credit_array: any = [];
  debit_array: any = [];
  tree_node_array: any = [];
  modal: any;
  ledgers: any;
  show_create_button: boolean;
  balance_openingbalance: any = [];
  return_result: void;
  openingbalance_data_array: any = [];
  openingBalanceAmount: {} = { 'credit': {}, 'debit': {} };
  totalCredit: number = 0;
  totalDebit: number = 0;
  creditValues: any = [];
  debitValues: any = [];
  creditdebit: any = [];
  selectedIndex = 1000;
  check_creditswitchstatus: boolean = true;
  child_amount: number = 0;
  currentSelectedNode: any;
  constructor(
    private mastersService: MastersService,
    private costgroupService: CostgroupService,
    private billService: BillService,
    private paymenttransactionsService: PaymenttransactionsService,
    private router: Router,
    private hsnService: HsnService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private fixedAssestsService: FixedAssestsService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,

  ) { }

  ngOnInit() {
    let result = this.router.url.match("/add");
    if (result != null) {
      if (result[0] === "/add") {
        this.company_id = "-1";
        this.is_coa_add = true;
      }
    }
    this.getCoa();
    this.getHsnCode("").subscribe((result) => {
      this.hsncodeArray = result;
    });

    this.getFixedAssets("").subscribe((result) => {
      this.fixedAssetsArray = result['response']['data'];
    });
    this.getHsncodeSearch();
    this.getFixedAssetsSearch();
    this.getCostCenter("").subscribe((result) => {
      this.cost_center_list = result["data"];
    });
    this.getCostCenterSearch();
    // this.getOpeningBalance();
  }

  getHsnCode(searchString) {
    let condition: any = {};
    let columns: any = {};
    this.hsncodeArray = [];
    if (searchString != "") {
      condition["search_string"] = searchString;
    }
    condition["type"] = 1;
    columns["code"] = 1;
    columns["description"] = 1;
    columns["group_code "] = 1;
    columns["country_code"] = 1;
    columns["effects_from"] = 1;

    return this.hsnService.getHsnCode(condition, columns, {}, { limit: 3 });
  }

  getFixedAssets(searchString) {
    let condition: any = {};
    let columns: any = {};
    this.fixedAssetsArray = [];
    if (searchString != "") {
      condition["search_string"] = searchString;
    }
    condition["type"] = 1;
    // columns["code"] = 1;
    // columns["description"] = 1;
    // columns["group_code "] = 1;
    // columns["country_code"] = 1;
    // columns["effects_from"] = 1;
    columns["group_name"] = 1;
    columns["description"] = 1;
    columns["useful_life"] = 1;
    columns["income_tax_rate"] = 1;

    return this.fixedAssestsService.getFixedAssests(condition, columns, {}, { limit: 3 });
  }

  getCostCenter(searchString) {
    let condition: any = {};
    let columns: any = {};
    this.cost_center_list = [];
    if (searchString != "") {
      condition["costcategory_name"] = searchString;
    }
    condition["company_id"] = this.authenticationService.getDefaultCompanyId();
    // condition["type"] = 1;
    columns["costcategory_name"] = 1;
    // columns["description"] = 1;
    // columns["group_code "] = 1;
    // columns["country_code"] = 1;
    // columns["effects_from"] = 1;

    return this.costgroupService.getCostgroups(
      condition,
      columns,
      {},
      { limit: 5 }
    );
  }

  getHsncodeSearch() {
    this.hsnListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getHsnCode(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.hsncodeArray = result;
        },
        (err) => {
          console.log(err);
          this.hsncodeArray = [];
        }
      );
  }

  getFixedAssetsSearch() {
    this.fixedAssetsListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getFixedAssets(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.fixedAssetsArray = result;
        },
        (err) => {
          console.log(err);
          this.fixedAssetsArray = [];
        }
      );
  }

  getCostCenterSearch() {
    this.costCenterListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getCostCenter(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.cost_center_list = result["data"];
        },
        (err) => {
          console.log(err);
          this.cost_center_list = [];
        }
      );
  }

  getCoa() {
    this.chart_of_accounts = [];
    this.is_gst = false;
    this.is_ledger = false;
    this.hsn_code = "";
    if (this.company_id == '') {
      this.company_id = this.authenticationService.getDefaultCompanyId();
    }
    this.mastersService.getCoa(this.company_id).subscribe((result) => {
      if (result["response"]['opening_balance'] != undefined && result["response"]['opening_balance'][0] != undefined) {
        this.openingBalanceAmount['credit'] = result["response"]['opening_balance'][0]['credit'] == undefined ? {} : result["response"]['opening_balance'][0]['credit'];
        this.openingBalanceAmount['debit'] = result["response"]['opening_balance'][0]['debit'] == undefined ? {} : result["response"]['opening_balance'][0]['debit'];
        this.totalCredit = this.sumNumbersFromObject(this.openingBalanceAmount['credit'], 'opening_amt');
        this.totalDebit = this.sumNumbersFromObject(this.openingBalanceAmount['debit'], 'opening_amt');

        if (this.totalCredit == this.totalDebit) {
          this.show_create_button = false;
        }
      }
      // let coa = [];
      // this.chart_of_accounts = [];
      result["response"]["chart_of_accounts"].forEach((major) => {
        console.log(major.child);
        major.child.is_open = true;
        // major.child.is_root= true;
        // major.child.is_main_group= true;
        this.chart_of_accounts.push({
          name: major.name,
          child: major.child,
          id: major.id,
          is_root: true,
          is_open: true,
          is_editable: false,
          is_ledger: false,
          is_main_group: true,
          last_sequence: major.last_sequence
        });

      });
      // this.chart_of_accounts[] = {
      //   child: result["response"]["chart_of_accounts"],
      //   skip_first_child: true,
      //   is_open: true,
      //   name: "COA",
      //   id: 1,
      //   is_editable: 0,
      //   is_root: true,
      // };
      this.flattened_data = result["response"]["flattened_data"];


      // console.log("response============>", this.chart_of_accounts);
    });
  }
  toggleNode(node) {
    node.is_open = !node.is_open;
  }
  selectNode(node: TreeNode): void {
    this.selectedTreeNode = node;

    // console.group("Selected Tree Node", node);
    // console.log("Label:", node.label);
    // console.log("Children:", node.child.length);
    // console.groupEnd();
  }
  addNewHead(node, child_name) {
    this.is_add_ledger_btn_disabled = true;

    //Using swith case to find last sequence of the preferred groups
    // ex: Assets(1000), Liability(2000), Equity(3000), Revenue(4000), Expense(5000)
    let head_id;
    // console.log('node', node)
    // console.log('child_name', child_name)
    switch (true) {
      case node.id < 2000: {
        head_id = 1000;
        break;
      }
      case node.id >= 2000 && node.id < 3000: {
        head_id = 2000;
        break;
      }
      case node.id >= 3000 && node.id < 4000: {
        head_id = 3000;
        break;
      }
      case node.id >= 4000 && node.id < 5000: {
        head_id = 4000;
        break;
      }
      case node.id >= 5000 && node.id < 6000: {
        head_id = 5000;
        break;
      }
    }

    this.chart_of_accounts.forEach((element) => {
      if (element.id == head_id) {
        console.log("element==", element);
        this.last_sequence = element["last_sequence"];
        if (child_name !== undefined || child_name != "") {
          // console.log('child_name', child_name)
          if (this.last_sequence + 1 < head_id + 1000) {
            this.fixedAssets = {};
            // console.log('inside condition', head_id)
            if (this.is_ledger == true) {
              this.fixedAssetsArray.filter(element => {
                if (element._id == this.group_name) {
                  this.fixedAssets = element;
                }
              });
            }


            node.child.push({
              name: child_name,
              id: this.last_sequence + 1,
              is_ledger: this.is_ledger,
              is_editable: true,
              child: [],
              hsn_code: this.hsn_code ? this.hsn_code : null,
              fixedAssets: this.fixedAssets ? this.fixedAssets : '',
              cost_center_id: this.cost_center_id ? this.cost_center_id : null,
            });

            let parent_name: any = [];
            let parent_id: any = [];
            this.flattened_data.forEach((data) => {
              if (node.id == data.id) {
                parent_name.push(node.name);
                if (isArray(data["parent_name"])) {
                  data["parent_name"].forEach((parent_names) => {
                    parent_name.push(parent_names);
                  });
                }
                parent_id.push(node.id);
                if (isArray(data["parent_id"])) {
                  data["parent_id"].forEach((parent_ids) => {
                    parent_id.push(parent_ids);
                  });
                }
              }
            });

            this.flattened_data.push({
              name: child_name,
              id: this.last_sequence + 1,
              parent_name: parent_name,
              parent_id: parent_id,
              is_ledger: this.is_ledger,
              hsn_code: this.hsn_code ? this.hsn_code : null,
              fixedAssets: this.fixedAssets ? this.fixedAssets : '',
              cost_center_id: this.cost_center_id ? this.cost_center_id : null,
            });
            element["last_sequence"] = this.last_sequence + 1;
            let condition: any = {};
            condition["company_id"] = this.company_id;
            child_name = "";

            this.mastersService
              .updateCoa(condition, {
                chart_of_accounts: this.chart_of_accounts,
                flattened_data: this.flattened_data,
              })
              .subscribe((result) => {
                this.is_add_ledger_btn_disabled = false;
                if (result['response']['ok'] == 1) {
                  this.modalService.dismissAll();
                  this.getCoa();
                }
              });
          } else {
            swal(
              "Exceeded The Limit Of Adding Head!",
              "Preferred Limit " + (head_id + 999),
              "warning"
            );
          }
        } else {
          swal("Warning!", "Please enter a name to add head.!", "warning");
        }
      }
    });
  }

  clearData(node) {
    node.add_head = false;
    node.child_name = "";
    this.cost_center_id = null;
    this.hsn_code = null;
    this.group_name = null;
    this.is_ledger = false;
    this.is_cost_center = false;
    this.is_gst = false;
  }

  updateCoa() {
    let condition: any = {};
    condition["company_id"] = this.company_id;

    this.mastersService
      .updateCoa(condition, {
        chart_of_accounts: this.chart_of_accounts["child"],
        flattened_data: this.flattened_data,
      })
      .subscribe((result) => { });
  }
  //sort is_main_group
  sortCOA(coaNodes) {
    function sortingLogic(coaNode1, coaNode2) {
      if (coaNode1.is_ledger == false && coaNode2.is_ledger == true) return 1;
      if (coaNode1.is_ledger == true && coaNode2.is_ledger == false) return -1;

      return 0;
    }

    return coaNodes.sort(sortingLogic);
  }

  createOpeningBalanceAmount() {
    if (this.totalCredit !== this.totalDebit) {
      let condition = {};
      //store in opening balance collection

      condition['company_id'] = this.company_id;
      this.mastersService.updateCoa(condition, {
        chart_of_accounts: this.chart_of_accounts,
        flattened_data: this.flattened_data,
        opening_balance: this.openingBalanceAmount
      }).subscribe(result => {
        if (result['response']["ok"] == 1) {
          swal("Opening Balance Created Successfully");
          //window.location.reload();
        }
      });
    } else {
      this.creditdebit = this.creditValues = this.debitValues = [];
      this.creditValues = Object.values(this.openingBalanceAmount['credit']);
      this.debitValues = Object.values(this.openingBalanceAmount['debit']);

      this.creditdebit = this.creditValues.concat(this.debitValues);
      //store in account transaction collection


      this.paymenttransactionsService.createOpeningBalancesTransactions(this.creditdebit, this.company_id).subscribe(result => {
        if (result['status_code'] == 1) {
          swal("Opening Balance Created Successfully");
         // window.location.reload();
        }
      });
    }

  }
  openModal(modelName, row_data) {
    const modal = this.modalService.open(modelName);
    modal.componentInstance.node = row_data;

  }
  openOpeningBalanceModal(type, modelName) {
    if (type == 1) {
      this.show_create_button = true;

    } else {
      this.show_create_button = false;
    }
    const modal = this.modalService.open(modelName);
  }
  getOpeningBalance() {
    let result_array = [];
    let financial_year = 2020;
    this.paymenttransactionsService.getOpeningBalance(this.company_id, financial_year).subscribe(result => {
      if (result['count'] > 0) {
        // this.is_openingbalance_amt = true;
        this.show_create_button = false;
        let openingbalance = result['data'][0]['opening_balance_object'];
        this.openingBalanceAmount['credit'] = {};
        this.openingBalanceAmount['debit'] = {};
        openingbalance.forEach(element => {
          if (element.is_credit) {
            this.openingBalanceAmount['credit'][element.id] = element;
            this.totalCredit = this.sumNumbersFromObject(this.openingBalanceAmount['credit'], 'opening_amt');

          } else {
            this.openingBalanceAmount['debit'][element.id] = element;
            this.totalDebit = this.sumNumbersFromObject(this.openingBalanceAmount['debit'], 'opening_amt');

          }

        });
        console.log(this.openingBalanceAmount);
        // result['data'].forEach(element => {

        //   if(element.is_credit){
        //     this.openingBalanceAmount['credit'][element.id]
        //   }

        // });
      } else {
        this.show_create_button = true;

      }
    });
  }

  getOpeningBalanceDetails(node) {

    let selected = this.openingbalance_data_array.filter(element => {
      return element.id == node.id;
    });
    if (selected.length > 0 && typeof node.is_default_OB == 'undefined') {
      node.opening_amt = selected[0]['opening_amt'];
      this.calculateDebitCreditBalance(node);
      node.is_default_OB = true;
    }

    return node.name;
  }
  calculateDebitCreditBalance(node) {
    // remove exisiting OB
    delete this.openingBalanceAmount['credit'][node.id];
    delete this.openingBalanceAmount['debit'][node.id];
    if (node.opening_amt != 0 && node.opening_amt != undefined) {
      let credit_debit = (node.is_credit == undefined || node.is_credit == false) ? 'debit' : 'credit';
      let is_credit = (node.is_credit == undefined || node.is_credit == false) ? false : true;
      let ledger_parent = this.flattened_data.filter(element => element.id == node.id);
      this.openingBalanceAmount[credit_debit][node.id] = { id: node.id, is_credit: is_credit, name: node.name, opening_amt: node.opening_amt, parent: ledger_parent[0] };
      this.totalCredit = this.sumNumbersFromObject(this.openingBalanceAmount['credit'], 'opening_amt');
      this.totalDebit = this.sumNumbersFromObject(this.openingBalanceAmount['debit'], 'opening_amt');
    }
  }
  keys(dataObject) {
    let ledgers = Object.keys(dataObject);
    return ledgers == undefined ? [] : ledgers;

  }
  sumNumbersFromObject(numbersObject, objectKey) {
    let total = 0;
    Object.keys(numbersObject).forEach(element => {

      total += parseFloat(numbersObject[element][objectKey]);
    });
    return total;
  }
  checkSwitchStatus(node) {
    if (node.id > 1000 && node.id < 2000 && node.is_credit != true) {
      node.is_credit = false;
    } else if (node.is_credit != true) {
      node.is_credit = true;
    }
  }
  redirectToParty(node) {
    let redirectURL = "";
    if (node.id == 1033) {
      this.router.navigateByUrl("/customer/add");
    } else if (node.id == 2011) {
      this.router.navigateByUrl("/vendor/add");

    }
  }
  childBasedOpeningBalance(node) {
    if (node.child.length > 0) {
      this.childBasedOpeningBalance(node);

    } else {
      node.child.forEach(element => {
        this.child_amount = + element.opening_amt;
      });
    }
    return this.child_amount;
  }
  openDialog(content, node) {
    const modalRef = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    console.log(node);
    this.currentSelectedNode = node;
    console.log(node);
    // modalRef.componentInstance.is_ledger = is_ledger;
    modalRef.result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
