import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import swal from "sweetalert2";
import { ContraService } from "../../_services/contra.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { MastersService } from "../../_services/masters.service";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { AuthenticationService } from "../../_services/authentication.service";
import { runInThisContext } from "vm";
import { SequenceService } from "../../_services/sequence.service";
import { Contra } from "src/app/_models/contra";
import { ConstantService } from "src/app/_config/constants";
import { CompanyService } from "src/app/_services/company.service";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { differenceInDays } from "date-fns";

@Component({
  selector: "app-contra-add",
  templateUrl: "./contra-add.component.html",
  // styleUrls: ['./contra-add.component.scss']
})
export class ContraAddComponent implements OnInit {
  loading: boolean =false;
  id: string;
  is_edit_form: boolean = false;
  contra: Contra = new Contra();
  from_bankList: any = [
    // {
    // flattened_data: {
    //   name: "HSBC",
    //   id: 1030
    // }
    // }
  ];
  fromBankListTypeahead = new EventEmitter<string>();
  to_bankList: any = [
    // {
    // flattened_data: {
    //   name: "HDFC",
    //   id: 1040
    // }
    // }
  ];
  toBankListTypeahead = new EventEmitter<string>();
  tx_dateDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };
  purchaseorderDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    disableSince: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };
  company_id: string;
  branch_id: string;
  transaction_date: any;

  company_details: any = {};
  sequence_no: any;
  sequence_id: any;
  accountList: any = [];
  accountHeadListTypeahead = new EventEmitter<string>();
  selectFiYear: any;
  // company_id(company_id: any) {
  //   throw new Error("Method not implemented.");
  // }
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private contraservice: ContraService,
    private masterService: MastersService,
    private authenticationService: AuthenticationService,
    private sequenceService: SequenceService,
    private companyService: CompanyService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];
    this.getBankTo("").subscribe((result) => {
      this.to_bankList = result["response"];
    });
    this.getBankToSearch();
    this.getBankFrom("").subscribe((result) => {
      this.from_bankList = result["response"];
    });
    this.getBankFromSearch();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get("id");
      if (this.id != null) {
        this.getContra();
      }
      this.addNewItem(0);
      this.getCoaHead("").subscribe((result) => {
        this.accountList = result["response"];
      });
      this.getCoaHeadSearch();

      var result = this.router.url.match("/edit/");
      if (result != null) {
        if (result[0] === "/edit/" && this.id != null) {
          this.is_edit_form = true;
        }
      } else {
        this.getNextSequence();

        this.contra.tx_date = {
          isRange: false,
          singleDate: {
            jsDate: differenceInDays(new Date(this.selectFiYear.end_year, 2, 31), new Date()) > 0 && differenceInDays(new Date(), new Date(this.selectFiYear.start_year, 3, 1)) > 0 ? new Date() : new Date(this.selectFiYear.end_year, 2, 31),
          },
        };
      }
    });

    this.getCompanyDetails();

    // this.masterService.getCoa(this.company_id).subscribe((result) => {
    //   this.to_bankList = result['response'];

    // });
  }
  sum_of_debit: number =0;
  sum_of_credit: number=0;

  //this.getContrasList();

  ontx_dateDateChanged(event: IMyDateModel): void {
    // date selected
  }
  addContra() {
    this.loading =true;
    this.contra.company_id = this.company_id;

    if (this.sum_of_credit == this.sum_of_debit) {
      this.transaction_date = this.contra.tx_date["singleDate"]["jsDate"];
      this.contra.tx_date = this.transaction_date.toISOString();
      this.contraservice.addContra(this.contra).subscribe((result) => {
        if (
          result["response"]["status_code"] === 1 &&
          result["response"]["_id"]
        ) {
          this.loading = false;
          this.updateSequence();
          swal("Added contra sucessfully!", "contra added.", "success");
          this.router.navigateByUrl("/contra/list");
        } else {
          this.showErrors(result["response"]["errors"]);
        }
      });
    } else {
      swal("Warning!", "Credit And Debit Amount Doesn't Tally", "warning");
    }
  }
  getCoaHead(searchString) {
    this.accountList = [];
    return this.masterService.getCoaHead(this.company_id, searchString, {
      in: [ConstantService.coa_heads.Bank, ConstantService.coa_heads.Cash],
    });
  }

  getCoaHeadSearch() {
    this.accountHeadListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getCoaHead(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.accountList = result["response"];
        },
        (err) => {
          console.log(err);
          this.accountList = [];
        }
      );
  }
  addNewItem(index) {
    this.contra.contra_items.push({
      account_head: [],
      debit: "",
      credit: "",
      description: "",
    });
  }
  getContra() {
    let condition : any = {};
    condition['company_id'] = this.company_id;
    return this.contraservice.viewContra(this.id, condition).subscribe((result) => {
      this.contra = result["response"];
      this.sumOfCreditDebit();
      if (this.is_edit_form) {
        this.contra.tx_date = {
          isRange : false,
          singleDate : {
            jsDate : new Date(this.contra.tx_date),
          },
        }
      }
    });
  }

  validateAccountHead() {
    this.contra.contra_items.forEach((itemsObj) => {
      if (itemsObj["account_head"] != null) {
        this.from_bankList = this.from_bankList.filter(
          (data) =>
            data.flattened_data._id !=
            itemsObj["account_head"]["flattened_data"]["_id"]
        );
      }
    });
  }

  getBankTo(searchString) {
    this.to_bankList = [];
    return this.masterService.getCoaHead(this.company_id, searchString, {
      in: [ConstantService.coa_heads.Bank, ConstantService.coa_heads.Cash],
    });
  }
  getBankToSearch() {
    this.toBankListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getBankTo(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.to_bankList = result["response"];
        },
        (err) => {
          console.log(err);
          this.to_bankList = [];
        }
      );
  }

  deleteItem(index) {
    this.contra.contra_items.splice(index, 1);
  }

  sumOfCreditDebit() {
    this.sum_of_debit = 0;
    this.sum_of_credit = 0;
    this.contra.contra_items.forEach((element) => {
      this.sum_of_debit += element.debit;
      this.sum_of_credit += element.credit;
    });
  }

  changeDebit(contra_items) {
    if (contra_items.credit != "") {
      contra_items.debit = 0;
    }
  }

  changeCredit(contra_items) {
    if (contra_items.debit != "") {
      contra_items.credit = 0;
    }
  }
  getBankFrom(searchString) {
    // ConstantService.coa_heads.Bank
    this.from_bankList = [];
    return this.masterService.getCoaHead(this.company_id, searchString, {
      in: [ConstantService.coa_heads.Bank, ConstantService.coa_heads.Cash],
    });
  }

  getBankFromSearch() {
    this.fromBankListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getBankFrom(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.from_bankList = result["response"];
          this.validateAccountHead();
        },
        (err) => {
          console.log(err);
          this.from_bankList = [];
        }
      );
  }

  addUpdateContra() {
    if (this.is_edit_form === true) {
      this.editContra();
    } else {
      this.addContra();
    }
  }

  editContra() {
    console.log('111111>>>>>>>');
    this.loading =true;
    this.contraservice.editContra(this.contra, this.id).subscribe((result) => {
      if (result["response"]["status_code"] == 1) {
        this.loading =false;
        swal(" Success ", " Contra Updated Successfully ");
        this.router.navigateByUrl("/contra/list");
      } else {
        this.showErrors(result[" response "][" errors "]);
      }
    });
  }

  showErrors(errors) {
    let error_string: string;
    error_string = " ";
    errors.forEach((item) => {
      error_string += " < br / > " + item;
    });
    swal({
      title: "Error",
      html: error_string,
      type: "error",
    }).then(function () {});
  }

  getNextSequence() {
    let contra_sequence: any = {};
    contra_sequence["seq_name"] = "contra";
    contra_sequence["company_id"] = this.company_id;
    this.sequenceService.viewSequence(contra_sequence).subscribe((result) => {
      let data = result["response"];
      if (data != "" && data != null) {
        this.contra.contra_id =
          data["prefix"] + data["seq_no"] + data["suffix"];
      }
      this.sequence_id = data["_id"];
      this.sequence_no = data["seq_no"];
    });
  }

  updateSequence() {
    let sequence_data: any = {};
    sequence_data["seq_no"] = parseInt(this.sequence_no) + 1;
    sequence_data["company_id"] = this.company_id;
    this.sequenceService
      .editSequence(sequence_data, this.sequence_id)
      .subscribe((result) => {});
  }

  getCompanyDetails() {
    this.companyService.viewCompany(this.company_id).subscribe((result) => {
      this.company_details = result["response"];
      this.tx_dateDpOptions.dateFormat = this.company_details.date_format;
      let book_beginning_date = new Date(this.company_details.book_beginning);
      book_beginning_date.setDate(book_beginning_date.getDate() - 1);
      let curren_tx_dateOptions = this.getCopyOfOptions();
      curren_tx_dateOptions.disableUntil = {
        year: book_beginning_date.getFullYear(),
        month: book_beginning_date.getMonth() + 1,
        day: book_beginning_date.getDate(),
      };
      curren_tx_dateOptions.disableUntil = {year:this.selectFiYear.start_year, month:3, day:31};
      curren_tx_dateOptions.disableSince = {year:this.selectFiYear.end_year, month:4, day:1};
      this.tx_dateDpOptions = curren_tx_dateOptions;
    });
  }
  getCopyOfOptions(): IAngularMyDpOptions {
    return JSON.parse(JSON.stringify(this.tx_dateDpOptions));
  }

  getVoucherType(voucher) {
    this.contra.voucher_name = "";
    this.contra.voucher_object = {};

    this.contra.voucher_name = voucher.name;
    this.contra.voucher_object = voucher;
  }
}
