import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";

import { MastersService } from "../../../_services/masters.service";
import { AuthenticationService } from "../../../_services/authentication.service";

import swal from "sweetalert2";

@Component({
  selector: 'app-units-add',
  templateUrl: './units-add.component.html',
  styleUrls: ['./units-add.component.scss']
})
export class UnitsAddComponent implements OnInit {
  loading =false;
  unit_name: any;
  unit_type: any;
  unit_code: any;

  constructor(
    private mastersService: MastersService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router

  ) { }

  ngOnInit() {
  }
  addUnits() {
    this.loading =true;
    let data = {};
    data = {
      name: this.unit_name,
      type: this.unit_type,
      code: this.unit_code,

    };
    this.mastersService.addUnits(data).subscribe(result => {
      if (result["muid"] != "") {
        this.loading =false;
        swal("Success", "Units has been added successfully", "success");
        this.router.navigate(["/masters/unit/list"]);
      }
    });
  }
}
