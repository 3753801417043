<div class="card">
  <div class="card-body">
    <div class="row clearfix">
      <form class="form-horizontal" (ngSubmit)="addEditCompany()" novalidate #saveCompany="ngForm">

        <div class="d-flex justify-content-between align-items-center">
          <ul class="nav nav-tabs page-header-tab">
            <li class="nav-item"><a class="nav-link" [ngClass]="{'active':navTab == 'company'}"
                (click)="navTab='company'" id="Project-tab" data-toggle="tab" href="javascript:void(0);">Company
                Info</a></li>
            <li class="nav-item" *ngIf="is_edit_form"><a class="nav-link" [ngClass]="{'active':navTab == 'settings'}"
                (click)="navTab='settings'" id="Project-tab" data-toggle="tab" href="javascript:void(0);">Settings</a>
            </li>
            <li class="nav-item"><a class="nav-link" [ngClass]="{'active':navTab == 'statutory'}"
                (click)="navTab='statutory'" id="Project-tab" data-toggle="tab" href="javascript:void(0);">Statutory
                Info</a>
            </li>
            <li *ngIf="is_member_tab && company_settings?.company_type?.id!=1" class="nav-item">
              <a class="nav-link" [ngClass]="{'active':navTab == 'member_details'}" (click)="navTab='member_details'"
                id="Project-tab" data-toggle="tab" href="javascript:void(0);">
                {{member_name}} Details
              </a>
            </li>
            <li class="nav-item" *ngIf="is_edit_form && company_settings.is_warehouse"><a class="nav-link"
                [ngClass]="{'active':navTab == 'warehouse'}" (click)="navTab='warehouse'" id="Project-tab"
                data-toggle="tab" href="javascript:void(0);">Warehouse</a>
            </li>
            <li class="nav-item" *ngIf="is_edit_form"><a class="nav-link"
                [ngClass]="{'active':navTab == 'bank_details'}" (click)="navTab='bank_details'" id="Project-tab"
                data-toggle="tab" href="javascript:void(0);">Bank Details</a>
            </li>
            <li class="nav-item" *ngIf="is_edit_form && company_settings.is_branch"><a class="nav-link"
                [ngClass]="{'active':navTab == 'branch'}" (click)="navTab='branch'" id="Project-tab" data-toggle="tab"
                href="javascript:void(0);">Branch</a>
            </li>

            <li class="nav-item"><a class="nav-link"  [ngClass]="{'active':navTab == 'financial'}"
                (click)="navTab='financial'" id="Project-tab" data-toggle="tab" href="javascript:void(0);">Financial Year</a>
            </li>

          </ul>
        </div>

        <div class="tab-content">
          <div class="tab-pane fade show active" [ngClass]="{'show active':navTab == 'settings' || !navTab}"
            id="settings" role="tabpanel">


            <div class="row">
              <div class="col-12">
                <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                  <li [ngbNavItem]="1">
                    <a ngbNavLink>Sales Invoice Settings</a>
                    <ng-template ngbNavContent>
                      <!-- <div id="collapseOne" [ngClass]="{'show':salesorder_collapse==false}" class="collapse"
              aria-labelledby="headingOne" data-parent="#accordionExample"> -->
                      <div class="card-body row">
                        <h4 class="col-sm-12">Sales Invoice Default(s)</h4>


                        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3 ">
                          <label for="payment_terms">Payment Terms</label>
                          <select class="form-control" required #payment_terms="ngModel" id="payment_terms"
                            name="payment_terms" [(ngModel)]="company_settings.sales_column_settings.payment_terms">
                            <option value="0" selected="selected">Due Upon Receipt</option>
                            <option value="15">Due within 15 days</option>
                            <option value="30">Due within 30 days</option>
                            <option value="45">Due within 45 days</option>
                            <option value="60">Due within 60 days</option>
                            <option value="90">Due within 90 days</option>
                          </select>
                          <app-validation-errors [mformField]="payment_terms" label="Payment Terms">
                          </app-validation-errors>
                        </div>

                        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="title">Title</label>
                          <input type="text" class="form-control" placeholder="Title" name="title" id="title"
                            [(ngModel)]="company_settings.sales_column_settings.title">
                        </div>
                        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="sub_heading">Sub Heading</label>
                          <input type="text" class="form-control" name="sub_heading" placeholder="Sub Heading"
                            id="sub_heading" [(ngModel)]="company_settings.sales_column_settings.sub_heading">
                        </div>
                        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="footer">Footer</label>
                          <input type="text" class="form-control" placeholder="Footer" name="footer" id="footer"
                            [(ngModel)]="company_settings.sales_column_settings.footer">
                        </div>
                        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="ordernumber_length">Sales Number Length</label>
                          <input type="text" class="form-control" placeholder="ordernumber_length"
                            name="ordernumber_length" id="ordernumber_length"
                            [(ngModel)]="company_settings.sales_column_settings.ordernumber_length">
                        </div>
                        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="sales_order_number">Sales Order Number</label>
                          <div class="input-group">
                            <input class="form-control" type="text"  #sales_order_prefix="ngModel"
                              id="sales_order_prefix" name="sales_order_prefix"
                              [(ngModel)]="company_settings.sales_column_settings.sales_order_prefix"
                              placeholder="Prefix">

                            <input type="number" class="form-control" id="sales_order_number" required
                              #sales_order_number="ngModel" name="sales_order_number"
                              [(ngModel)]="company_settings.sales_column_settings.sales_order_number"
                              placeholder="Sequence Number">

                            <input type="text" class="form-control" id="sales_order_suffix"
                              #sales_order_suffix="ngModel" name="sales_order_suffix"
                              [(ngModel)]="company_settings.sales_column_settings.sales_order_suffix"
                              placeholder="Suffix">

                            <!-- <app-validation-errors [mformField]="sales_order_prefix" label="Sales Order Prefix">
                            </app-validation-errors> -->
                            <app-validation-errors [mformField]="sales_order_number" label="Sales Order Number">
                            </app-validation-errors>
                            <!-- <app-validation-errors [mformField]="sales_order_suffix" label="Sales Order Suffix">
                            </app-validation-errors> -->
                          </div>

                        </div>

                        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="sales_order_number">Order Number Format</label>
                          <div>
                            {{company_settings.sales_column_settings.sales_order_prefix}}
                            {{company_settings.sales_column_settings.sales_order_number |
                            reverseString:company_settings.sales_column_settings.ordernumber_length}}
                            {{company_settings.sales_column_settings.sales_order_suffix}}
                          </div>
                        </div>
                        <!-- <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="invoice_ordernumber_length">Invoice Number Length</label>
                          <input type="text" class="form-control" placeholder="invoice_ordernumber_length"
                            name="invoice_ordernumber_length" id="invoice_ordernumber_length"
                            [(ngModel)]="company_settings.sales_column_settings.invoice_ordernumber_length">
                        </div> -->

                        <!-- <div class="form-group required col-12 col-sm-6 col-md-6 col-xl-3">
                          <label for="sales_order_number">Invoice Number</label>
                          <div class="input-group">
                            <input class="form-control" type="text" required #invoice_order_prefix="ngModel"
                              id="invoice_order_prefix" name="invoice_order_prefix" (change)="checkInvoiceNumberLength('sales',0)"
                              [(ngModel)]="company_settings.sales_column_settings.invoice_order_prefix"
                              placeholder="Prefix">

                            <input type="number" class="form-control" id="invoice_order_number" required
                              #invoice_order_number="ngModel" name="invoice_order_number" (change)="checkInvoiceNumberLength('sales',0)"
                              [(ngModel)]="company_settings.sales_column_settings.invoice_order_number"
                              placeholder="Sequence Number">

                            <input type="text" class="form-control" id="invoice_order_suffix" required
                              #invoice_order_suffix="ngModel" name="invoice_order_suffix" (change)="checkInvoiceNumberLength('sales',0)"
                              [(ngModel)]="company_settings.sales_column_settings.invoice_order_suffix"
                              placeholder="Suffix">

                            <app-validation-errors [mformField]="invoice_order_prefix" label="Invoice Order Prefix">
                            </app-validation-errors>
                            <app-validation-errors [mformField]="invoice_order_number" label="Invoice Order Number">
                            </app-validation-errors>
                            <app-validation-errors [mformField]="invoice_order_suffix" label="Invoice Order Suffix">
                            </app-validation-errors>
                          </div>
                        </div>
                        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="sales_order_number">Invoice Number Format</label>
                          <div>
                            {{company_settings.sales_column_settings.invoice_order_prefix}}
                            {{company_settings.sales_column_settings.invoice_order_number|
                            reverseString:company_settings.sales_column_settings.invoice_ordernumber_length}}
                            {{company_settings.sales_column_settings.invoice_order_suffix}}
                          </div>
                        </div> -->

                        <div class="card">
                          <div class="card-body form-row p-0 pb-3">
                            <table class="col-12 table table-stripped table-hover table-bordered">
                              <thead class="thead-light">
                                <tr>
                                  <th >S.No</th>
                                  <th >Voucher Type</th>
                                  <th width="25%">Invoice Number</th>
                                  <th width="20%">Invoice No Format</th>
                                  <th width="20%">Invoice No Length</th>
                                  <th ><b>Action</b></th>
                                </tr>
                              </thead>
                              <tbody
                                *ngFor="let itemsVoucher of company_settings.sales_column_settings.voucher; let i = index">
                                <tr>
                                  <td>
                                    {{i+1}}
                                  </td>
                                  <td>
                                    <input type="text" class="form-control" name="voucher_type_{{i}}" id="voucher_type"
                                      [(ngModel)]="itemsVoucher.voucher_type">
                                  </td>
                                  <td>
                                    <div class="input-group">
                                      <input class="form-control" type="text"  #invoice_order_prefix="ngModel"
                                        id="invoice_order_prefix" name="invoice_order_prefix_{{i}}" (change)="checkInvoiceNumberLength('voucher',i)"
                                        [(ngModel)]="itemsVoucher.invoice_order_prefix"
                                        placeholder="Prefix">

                                      <input type="number" data-ng-change="numbersOnly()" class="form-control" id="invoice_order_number" required
                                        #invoice_order_number="ngModel" name="invoice_order_number_{{i}}" (change)="checkInvoiceNumberLength('voucher',i)"
                                        [(ngModel)]="itemsVoucher.invoice_order_number"
                                        placeholder="Sequence Number">

                                      <input type="text" class="form-control" id="invoice_order_suffix"
                                        #invoice_order_suffix="ngModel" name="invoice_order_suffix_{{i}}" (change)="checkInvoiceNumberLength('voucher', i)"
                                        [(ngModel)]="itemsVoucher.invoice_order_suffix"
                                        placeholder="Suffix">

                                      <!-- <app-validation-errors [mformField]="invoice_order_prefix" label="Invoice Order Prefix">
                                      </app-validation-errors> -->
                                      <app-validation-errors [mformField]="invoice_order_number" label="Invoice Order Number">
                                      </app-validation-errors>
                                      <!-- <app-validation-errors [mformField]="invoice_order_suffix" label="Invoice Order Suffix">
                                      </app-validation-errors> -->
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      {{itemsVoucher.invoice_order_prefix}}
                                      {{itemsVoucher.invoice_order_number|
                                      reverseString:itemsVoucher.invoice_ordernumber_length}}
                                      {{itemsVoucher.invoice_order_suffix}}
                                    </div>
                                  </td>
                                  <td>
                                    <input type="number" class="form-control" placeholder="invoice_ordernumber_length"
                            name="invoice_ordernumber_length_{{i}}" id="invoice_ordernumber_length"
                            [(ngModel)]="itemsVoucher.invoice_ordernumber_length">
                                  </td>
                                  <!-- <td>
                                    <input type="number" class="form-control" name="voucher_code_{{i}}"
                                      id="voucher_code" (change)="checkInvoiceNumberLength('voucher',i)" [(ngModel)]="itemsVoucher.voucher_code">
                                  </td> -->
                                  <td>
                                    <button type="button" class="btn btn-danger" (click)="deleteItem(i)">
                                      <i class="fa fa-trash" aria-hidden="true"></i>
                                    </button>
                                    <button type="button" class="btn btn-primary ml-1" (click)="addNewItem()">
                                      <i class="fa fa-plus" aria-hidden="true"></i>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="row" *ngIf="authenticationService.getUserRole()==8">
                          <h3 class="col-sm-12 col-md-12 col-xs-12">Default Ledger Settings</h3>
                          <div class="form-group col-4">
                            <label for="invoice_ledger">Invoice Ledger</label>
                            <app-ledgers (onSelectLedger)="updateLedger($event,'sales','invoice_ledger')"
                              [ledgerAccountsHeads]="invoiceAddAccountConfig">
                            </app-ledgers>
                          </div>

                        </div>

                        <h3 class="col-sm-12 col-md-12 col-xs-12">Column Settings</h3>

                        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="items">Items</label>
                          <div class="input-group">
                            <input type="text" class="form-control" required #sales_items="ngModel" placeholder="Items"
                              name="items" id="items" [(ngModel)]="company_settings.sales_column_settings.items">

                            <!-- <div class="input-group-append">
                              <button class="btn btn-success" type="button"
                                (click)="company_settings.sales_column_settings.items_display=!company_settings.sales_column_settings.items_display"><i
                                  class="fa"
                                  [ngClass]="company_settings.sales_column_settings.items_display==true? 'fa-eye': 'fa-eye-slash'"></i></button>
                            </div> -->
                            <app-validation-errors [mformField]="sales_items" label="Items Name">
                            </app-validation-errors>
                          </div>
                        </div>

                        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="description">Description</label>
                          <div class="input-group">
                            <input type="text" class="form-control" name="description" #sales_description="ngModel"
                              required id="description" placeholder="Description"
                              [(ngModel)]="company_settings.sales_column_settings.description">

                            <!-- <div class="input-group-append">
                              <button class="btn btn-success" type="button"
                                (click)="company_settings.sales_column_settings.desc_display=!company_settings.sales_column_settings.desc_display"><i
                                  class="fa"
                                  [ngClass]="company_settings.sales_column_settings.desc_display==true? 'fa-eye': 'fa-eye-slash'"></i></button>
                            </div> -->
                            <app-validation-errors [mformField]="sales_description" label="Description">
                            </app-validation-errors>
                          </div>
                        </div>

                        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="units">Units</label>
                          <div class="input-group">
                            <input type="text" class="form-control" #sales_units="ngModel" required name="units"
                              id="units" placeholder="Units" [(ngModel)]="company_settings.sales_column_settings.units">

                            <div class="input-group-append">
                              <button class="btn btn-success" type="button"
                                (click)="company_settings.sales_column_settings.units_display=!company_settings.sales_column_settings.units_display"><i
                                  class="fa"
                                  [ngClass]="company_settings.sales_column_settings.units_display==true? 'fa-eye': 'fa-eye-slash'"></i></button>
                            </div>
                            <app-validation-errors [mformField]="sales_units" label="Units">
                            </app-validation-errors>
                          </div>
                        </div>

                        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="Price">Price</label>
                          <div class="input-group">
                            <input type="text" class="form-control" name="price" #sales_price="ngModel" required
                              id="price" placeholder="Price" [(ngModel)]="company_settings.sales_column_settings.price">

                            <div class="input-group-append">
                              <button class="btn btn-success" type="button"
                                (click)="company_settings.sales_column_settings.price_display=!company_settings.sales_column_settings.price_display"><i
                                  class="fa"
                                  [ngClass]="company_settings.sales_column_settings.price_display==true? 'fa-eye': 'fa-eye-slash'"></i></button>
                            </div>
                            <app-validation-errors [mformField]="sales_price" label="Price">
                            </app-validation-errors>
                          </div>
                        </div>

                        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="amount">Amount</label>
                          <div class="input-group">
                            <input type="text" #sales_amount="ngModel" required class="form-control" name="amount"
                              id="amount" placeholder="Amount"
                              [(ngModel)]="company_settings.sales_column_settings.amount">

                            <div class="input-group-append">
                              <button class="btn btn-success" type="button"
                                (click)="company_settings.sales_column_settings.amount_display=!company_settings.sales_column_settings.amount_display"><i
                                  class="fa"
                                  [ngClass]="company_settings.sales_column_settings.amount_display==true? 'fa-eye': 'fa-eye-slash'"></i></button>
                            </div>
                            <app-validation-errors [mformField]="sales_amount" label="Amount">
                            </app-validation-errors>
                          </div>
                        </div>

                        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="amount">HSN</label>
                          <div class="input-group">
                            <input type="text" #sales_amount="ngModel" required class="form-control" name="hsn" id="hsn"
                              placeholder="HSN" [(ngModel)]="company_settings.sales_column_settings.hsn">
                            <div class="input-group-append">
                              <button class="btn btn-success" type="button"
                                (click)="company_settings.sales_column_settings.hsn_display=!company_settings.sales_column_settings.hsn_display"><i
                                  class="fa"
                                  [ngClass]="company_settings.sales_column_settings.hsn_display==true? 'fa-eye': 'fa-eye-slash'"></i></button>
                            </div>
                            <app-validation-errors [mformField]="sales_amount" label="Amount">
                            </app-validation-errors>
                          </div>
                        </div>
                        <!-- <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="amount">Template Color</label>
                          <input [value]="company_settings.sales_column_settings.template_color"
                            [style.background]="company_settings.sales_column_settings.template_color"
                            [cpOKButton]="true" [cpSaveClickOutside]="false"
                            [cpOKButtonClass]="'btn btn-primary btn-xs'"
                            [(colorPicker)]="company_settings.sales_column_settings.template_color" />
                        </div> -->
                        <div class="col-12"></div>
                        <div class="col-1"></div>
                        <div class="form-group required col-12 col-sm-6 col-md-6 col-xl-5">
                          <label for="amount">Template Format</label>
                          <!-- <img src="{{item.preview_img_path}}" alt="Random first slide" (click)="openLg(content)"> -->
                          <!-- <img
                            src="{{constantService.IMG_BASE_URL}}/templates/invoices/{{company_settings.sales_column_settings.template_format}}/preview.png"
                            (click)="openLg(content)"> -->
                          <ngb-carousel [activeId]="company_settings.sales_column_settings.template_format">
                            <ng-template [id]="item.template_name" ngbSlide
                              *ngFor="let item of invoiceTemplateList;let i=index;">
                              <div class="text-center">
                                <button type="button" class="btn btn-light"
                                  (click)="company_settings.sales_column_settings.template_format=item.template_name">Select</button>
                              </div>
                              <div class="picsum-img-wrapper">
                                <img src="{{item.preview_img_path}}" alt="{{item.template_name}}"
                                  (click)="openLg(content);company_settings.sales_column_settings.template_format=item.template_name" />
                              </div>

                            </ng-template>
                          </ngb-carousel>
                        </div>
                        <ng-template #content let-modal>
                          <div class="modal-header">
                            <!-- <button type="button" class="btn btn-light"
                              (click)="modal.close('Close click')">Close</button> -->
                            <button type="button" class="close" aria-label="Close"
                              (click)="modal.dismiss('Cross click')">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body">
                            <img
                              src="{{constantService.IMG_BASE_URL}}/templates/invoices/{{company_settings.sales_column_settings.template_format}}/preview.png">
                            <!-- <ngb-carousel [activeId]="company_settings.sales_column_settings.template_format">
                              <ng-template [id]="item.template_name" ngbSlide
                                *ngFor="let item of invoiceTemplateList;let i=index;">
                                <div class="text-center">
                                  <button type="button" class="btn btn-light"
                                    (click)="company_settings.sales_column_settings.template_format=item.template_name;modal.close('Close click')">Apply</button>
                                </div>
                                <div class="picsum-img-wrapper">
                                  <img src="{{item.preview_img_path}}" alt="{{item.template_name}}" />
                                </div>

                              </ng-template>
                            </ngb-carousel> -->
                          </div>
                          <div class="modal-footer">
                            <!-- <div class="text-center">
                              <button type="button" class="btn btn-light"
                                (click)="company_settings.sales_column_settings.template_format=item.template_name;modal.close('Close click')">Apply</button>
                            </div> -->
                            <button type="button" class="btn btn-light"
                              (click)="modal.close('Close click')">Close</button>
                          </div>
                        </ng-template>
                        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="amount">Template Color</label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text p-3 border1"
                                [style.background]="company_settings.sales_column_settings.template_color"></span>
                            </div>
                            <input [value]="company_settings.sales_column_settings.template_color" class="form-control"
                              [cpOKButton]="true" [cpSaveClickOutside]="false"
                              [cpOKButtonClass]="'btn btn-primary btn-xs'"
                              [(colorPicker)]="company_settings.sales_column_settings.template_color" />
                          </div>
                        </div>



                        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3"
                          *ngIf="authenticationService.getUserRole()==-1">
                          <label for="delivery_note">Delivery Note</label>
                          <div class="input-group">
                            <input type="text" class="form-control" name="delivery_note" id="delivery_note"
                              placeholder="Delivery Note"
                              [(ngModel)]="company_settings.sales_column_settings.delivery_note">

                            <div class="input-group-append">
                              <button class="btn btn-success" type="button"
                                (click)="company_settings.sales_column_settings.delivery_note_display=!company_settings.sales_column_settings.delivery_note_display"><i
                                  class="fa"
                                  [ngClass]="company_settings.sales_column_settings.delivery_note_display==true? 'fa-eye': 'fa-eye-slash'"></i></button>
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3"
                          *ngIf="authenticationService.getUserRole()==-1">
                          <label for="suppliers_ref">Suppliers Ref</label>
                          <div class="input-group">
                            <input type="text" class="form-control" name="suppliers_ref" id="suppliers_ref"
                              placeholder="Suppliers Ref"
                              [(ngModel)]="company_settings.sales_column_settings.suppliers_ref">

                            <div class="input-group-append">
                              <button class="btn btn-success" type="button"
                                (click)="company_settings.sales_column_settings.suppliers_ref_display=!company_settings.sales_column_settings.suppliers_ref_display"><i
                                  class="fa"
                                  [ngClass]="company_settings.sales_column_settings.suppliers_ref_display==true? 'fa-eye': 'fa-eye-slash'"></i></button>
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3"
                          *ngIf="authenticationService.getUserRole()==-1">
                          <label for="others_ref">Others Ref</label>
                          <div class="input-group">
                            <input type="text" class="form-control" name="others_ref" id="others_ref"
                              placeholder="Others Ref" [(ngModel)]="company_settings.sales_column_settings.others_ref">

                            <div class="input-group-append">
                              <button class="btn btn-success" type="button"
                                (click)="company_settings.sales_column_settings.others_ref_display=!company_settings.sales_column_settings.others_ref_display"><i
                                  class="fa"
                                  [ngClass]="company_settings.sales_column_settings.others_ref_display==true? 'fa-eye': 'fa-eye-slash'"></i></button>
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3"
                          *ngIf="authenticationService.getUserRole()==-1">
                          <label for="despatched_through">Despatched Through</label>
                          <div class="input-group">
                            <input type="text" class="form-control" name="despatched_through" id="despatched_through"
                              placeholder="Despatched Through"
                              [(ngModel)]="company_settings.sales_column_settings.despatched_through">

                            <div class="input-group-append">
                              <button class="btn btn-success" type="button"
                                (click)="company_settings.sales_column_settings.despatched_through_display=!company_settings.sales_column_settings.despatched_through_display"><i
                                  class="fa"
                                  [ngClass]="company_settings.sales_column_settings.despatched_through_display==true? 'fa-eye': 'fa-eye-slash'"></i></button>
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3"
                          *ngIf="authenticationService.getUserRole()==-1">
                          <label for="destination">Destination</label>
                          <div class="input-group">
                            <input type="text" class="form-control" name="destination" id="destination"
                              placeholder="Destination"
                              [(ngModel)]="company_settings.sales_column_settings.destination">
                            <div class="input-group-append">
                              <button class="btn btn-success" type="button"
                                (click)="company_settings.sales_column_settings.destination_display=!company_settings.sales_column_settings.destination_display"><i
                                  class="fa"
                                  [ngClass]="company_settings.sales_column_settings.destination_display==true? 'fa-eye': 'fa-eye-slash'"></i></button>
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3"
                          *ngIf="authenticationService.getUserRole()==-1">
                          <label for="discount">Discount</label>
                          <div class="input-group">
                            <input type="text" class="form-control" name="discount" id="discount" placeholder="Discount"
                              [(ngModel)]="company_settings.sales_column_settings.discount">

                            <div class="input-group-append">
                              <button class="btn btn-success" type="button"
                                (click)="company_settings.sales_column_settings.discount_display=!company_settings.sales_column_settings.discount_display"><i
                                  class="fa"
                                  [ngClass]="company_settings.sales_column_settings.discount_display==true? 'fa-eye': 'fa-eye-slash'"></i></button>
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3"
                          *ngIf="authenticationService.getUserRole()==-1">
                          <label for="motor_vehicle_no">Motor Vehicle No</label>
                          <div class="input-group">
                            <input type="text" class="form-control" name="motor_vehicle_no" id="motor_vehicle_no"
                              placeholder="Motor Vehicle No"
                              [(ngModel)]="company_settings.sales_column_settings.motor_vehicle_no">

                            <div class="input-group-append">
                              <button class="btn btn-success" type="button"
                                (click)="company_settings.sales_column_settings.motor_vehicle_no_display=!company_settings.sales_column_settings.motor_vehicle_no_display"><i
                                  class="fa"
                                  [ngClass]="company_settings.sales_column_settings.motor_vehicle_no_display==true? 'fa-eye': 'fa-eye-slash'"></i></button>
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3"
                          *ngIf="authenticationService.getUserRole()==-1">
                          <label for="eway_bill_no">Eway Bill No</label>
                          <div class="input-group">
                            <input type="text" class="form-control" name="eway_bill_no" id="eway_bill_no"
                              placeholder="Eway Bill No"
                              [(ngModel)]="company_settings.sales_column_settings.eway_bill_no">

                            <div class="input-group-append">
                              <button class="btn btn-success" type="button"
                                (click)="company_settings.sales_column_settings.eway_bill_no_display=!company_settings.sales_column_settings.eway_bill_no_display"><i
                                  class="fa"
                                  [ngClass]="company_settings.sales_column_settings.eway_bill_no_display==true? 'fa-eye': 'fa-eye-slash'"></i></button>
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3"
                          *ngIf="authenticationService.getUserRole()==-1">
                          <label for="eway_bill_date">Eway Bill Date</label>
                          <div class="input-group">
                            <input type="text" class="form-control" name="eway_bill_date" id="eway_bill_date"
                              placeholder="Eway Bill Date"
                              [(ngModel)]="company_settings.sales_column_settings.eway_bill_date">

                            <div class="input-group-append">
                              <button class="btn btn-success" type="button"
                                (click)="company_settings.sales_column_settings.eway_bill_date_display=!company_settings.sales_column_settings.eway_bill_date_display"><i
                                  class="fa"
                                  [ngClass]="company_settings.sales_column_settings.eway_bill_date_display==true? 'fa-eye': 'fa-eye-slash'"></i></button>
                            </div>
                          </div>
                        </div>


                      </div>
                      <!-- </div> -->
                    </ng-template>

                  </li>
                  <li [ngbNavItem]="2">
                    <a ngbNavLink>Purchase Invoice Settings</a>
                    <ng-template ngbNavContent>
                      <!-- <div id="collapseOne" [ngClass]="{'show':purchaseorder_collapse==false}" class="collapse"
              aria-labelledby="headingOne" data-parent="#accordionExample"> -->
                      <div class="form-row">
                        <h4 class="col-sm-12">Purchase Invoice Default(s)</h4>


                        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="purchase_payment_terms">Payment Terms</label>
                          <select class="form-control" required #payment_terms="ngModel" id="purchase_payment_terms"
                            name="purchase_payment_terms"
                            [(ngModel)]="company_settings.purchase_column_settings.payment_terms">
                            <option value="0" selected="selected">Due Upon Receipt</option>
                            <option value="15">Due within 15 days</option>
                            <option value="30">Due within 30 days</option>
                            <option value="45">Due within 45 days</option>
                            <option value="60">Due within 60 days</option>
                            <option value="90">Due within 90 days</option>
                          </select>
                          <app-validation-errors [mformField]="payment_terms" label="Payment Terms">
                          </app-validation-errors>
                        </div>

                        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="purchase_title">Title</label>
                          <input type="text" class="form-control" placeholder="Title" name="purchase_title"
                            id="purchase_title" [(ngModel)]="company_settings.purchase_column_settings.title">
                        </div>
                        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="purchase_sub_heading">Sub Heading</label>
                          <input type="text" class="form-control" name="purchase_sub_heading" placeholder="Sub Heading"
                            id="purchase_sub_heading"
                            [(ngModel)]="company_settings.purchase_column_settings.sub_heading">
                        </div>
                        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="purchase_footer">Footer</label>
                          <input type="text" class="form-control" placeholder="Footer" name="purchase_footer"
                            id="purchase_footer" [(ngModel)]="company_settings.purchase_column_settings.footer">
                        </div>
                        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="purchase_number_length">Purchase Number Length</label>
                          <input type="text" class="form-control" placeholder="purchase_number_length"
                            name="purchase_number_length" id="purchase_number_length"
                            [(ngModel)]="company_settings.purchase_column_settings.ordernumber_length">
                        </div>
                        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="purchase_order_number">Purchase Order Number</label>
                          <div class="input-group">
                            <input class="form-control" type="text" required #purchase_order_prefix="ngModel"
                              id="purchase_order_prefix" name="purchase_order_prefix"
                              [(ngModel)]="company_settings.purchase_column_settings.purchase_order_prefix"
                              placeholder="Prefix">

                            <input type="number" class="form-control" id="purchase_order_number" required
                              #purchase_order_number="ngModel" name="purchase_order_number"
                              [(ngModel)]="company_settings.purchase_column_settings.purchase_order_number"
                              placeholder="Sequence Number">

                            <input type="text" class="form-control" id="purchase_order_suffix" required
                              #purchase_order_suffix="ngModel" name="purchase_order_suffix"
                              [(ngModel)]="company_settings.purchase_column_settings.purchase_order_suffix"
                              placeholder="Suffix">

                            <app-validation-errors [mformField]="purchase_order_prefix" label="Purchase Order Prefix">
                            </app-validation-errors>
                            <app-validation-errors [mformField]="purchase_order_number" label="Purchase Order Number">
                            </app-validation-errors>
                            <app-validation-errors [mformField]="purchase_order_suffix" label="Purchase Order Suffix">
                            </app-validation-errors>
                          </div>
                        </div>
                        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="purchase_order_number">Purchase Order Format</label>
                          <div>

                            {{company_settings.purchase_column_settings.purchase_order_prefix}}
                            {{company_settings.purchase_column_settings.purchase_order_number |
                            reverseString:company_settings.purchase_column_settings.ordernumber_length}}
                            {{company_settings.purchase_column_settings.purchase_order_suffix}}
                          </div>
                        </div>
                        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="bill_number_length">Bill Number Length</label>
                          <input type="text" class="form-control" placeholder="bill_number_length"
                            name="bill_number_length" id="bill_number_length"
                            [(ngModel)]="company_settings.purchase_column_settings.bill_ordernumber_length">
                        </div>
                        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="bill_order_number">Bill Number</label>
                          <div class="input-group">
                            <input class="form-control" type="text" #bill_order_prefix="ngModel" id="bill_order_prefix"
                              name="bill_order_prefix"
                              [(ngModel)]="company_settings.purchase_column_settings.bill_order_prefix"
                              placeholder="Prefix">

                            <input type="number" class="form-control" id="bill_order_number"
                              #bill_order_number="ngModel" name="bill_order_number"
                              [(ngModel)]="company_settings.purchase_column_settings.bill_order_number"
                              placeholder="Sequence Number">

                            <input type="text" class="form-control" id="bill_order_suffix" #bill_order_suffix="ngModel"
                              name="bill_order_suffix"
                              [(ngModel)]="company_settings.purchase_column_settings.bill_order_suffix"
                              placeholder="Suffix">
                          </div>
                        </div>
                        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="purchase_order_number">Bill Order Format</label>
                          <div>

                            {{company_settings.purchase_column_settings.bill_order_prefix}}
                            {{company_settings.purchase_column_settings.bill_order_number |
                            reverseString:company_settings.purchase_column_settings.bill_ordernumber_length}}
                            {{company_settings.purchase_column_settings.bill_order_suffix}}
                          </div>
                        </div>

                        <div class="card">
                          <div class="card-body form-row p-0 pb-3">
                            <table class="col-12 table table-stripped table-hover table-bordered">
                              <thead class="thead-light">
                                <tr>
                                  <th style="width: 2%;">S.No</th>
                                  <th style="width: 4%;">Voucher Type</th>
                                  <th style="width: 4%;">Code</th>
                                  <th style="width: 4%;"><b>Action</b></th>
                                </tr>
                              </thead>
                              <tbody
                                *ngFor="let itemsVoucher of company_settings.purchase_column_settings.voucher; let i = index">
                                <tr>
                                  <td>
                                    {{i+1}}
                                  </td>
                                  <td>
                                    <input type="text" class="form-control" name="voucher_type_{{i}}" id="voucher_type"
                                      [(ngModel)]="itemsVoucher.voucher_type">
                                  </td>
                                  <td>
                                    <input type="number" class="form-control" name="voucher_code_{{i}}"
                                      id="voucher_code" [(ngModel)]="itemsVoucher.voucher_code">
                                  </td>
                                  <td>
                                    <button type="button" class="btn btn-danger" (click)="deleteVoucherPurchase(i)">
                                      <i class="fa fa-trash" aria-hidden="true"></i>
                                    </button>
                                    <button type="button" class="btn btn-primary ml-1" (click)="addVoucherPurchase()">
                                      <i class="fa fa-plus" aria-hidden="true"></i>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div class="row" *ngIf="authenticationService.getUserRole()==8">
                          <h3 class="col-sm-12 col-md-12 col-xs-12">Default Ledger Settings</h3>
                          <div class="form-group col-4">
                            <label for="credit_notes">Bill Ledger</label>
                            <app-ledgers (onSelectLedger)="updateLedger($event,'purchase','bill_ledger')"
                              [ledgerAccountsHeads]="billAddAccountConfig">
                            </app-ledgers>
                          </div>

                          <div class="form-group col-4">
                            <label for="credit_notes">Expense Ledger</label>
                            <app-ledgers (onSelectLedger)="updateLedger($event,'purchase','expense_ledger')"
                              [ledgerAccountsHeads]="expenseAddAccountConfig">
                            </app-ledgers>
                          </div>


                        </div>

                        <h3 class="col-sm-12 col-md-12 col-xs-12">Column Settings</h3>
                        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="purchase_items">Items</label>
                          <div class="input-group">
                            <input type="text" class="form-control" placeholder="Items" name="purchase_items"
                              id="purchase_items" #purchase_items="ngModel" required
                              [(ngModel)]="company_settings.purchase_column_settings.items">
                            <div class="input-group-append">
                              <button class="btn btn-success" type="button"
                                (click)="company_settings.purchase_column_settings.items_display=!company_settings.purchase_column_settings.items_display"><i
                                  class="fa"
                                  [ngClass]="company_settings.purchase_column_settings.items_display==true? 'fa-eye': 'fa-eye-slash'"></i></button>
                            </div>
                            <app-validation-errors [mformField]="purchase_items" label="Items">
                            </app-validation-errors>
                          </div>
                        </div>

                        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="purchase_description">Description</label>
                          <div class="input-group">
                            <input type="text" class="form-control" #purchase_description="ngModel" required
                              name="purchase_description" id="purchase_description" placeholder="Description"
                              [(ngModel)]="company_settings.purchase_column_settings.description">
                            <div class="input-group-append">
                              <button class="btn btn-success" type="button"
                                (click)="company_settings.purchase_column_settings.desc_display=!company_settings.purchase_column_settings.desc_display"><i
                                  class="fa"
                                  [ngClass]="company_settings.purchase_column_settings.desc_display==true? 'fa-eye': 'fa-eye-slash'"></i></button>
                            </div>
                            <app-validation-errors [mformField]="purchase_description" label="Description">
                            </app-validation-errors>
                          </div>
                        </div>

                        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="purchase_units">Units</label>
                          <div class="input-group">
                            <input type="text" class="form-control" name="purchase_units" id="purchase_units"
                              placeholder="Units" #purchase_units="ngModel" required
                              [(ngModel)]="company_settings.purchase_column_settings.units">

                            <div class="input-group-append">
                              <button class="btn btn-success" type="button"
                                (click)="company_settings.purchase_column_settings.units_display=!company_settings.purchase_column_settings.units_display"><i
                                  class="fa"
                                  [ngClass]="company_settings.purchase_column_settings.units_display==true? 'fa-eye': 'fa-eye-slash'"></i></button>
                            </div>
                            <app-validation-errors [mformField]="purchase_units" label="Units">
                            </app-validation-errors>
                          </div>
                        </div>

                        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="purchase_price">Price</label>
                          <div class="input-group">
                            <input type="text" class="form-control" #purchase_price="ngModel" required
                              name="purchase_price" id="purchase_price" placeholder="Price"
                              [(ngModel)]="company_settings.purchase_column_settings.price">

                            <div class="input-group-append">
                              <button class="btn btn-success" type="button"
                                (click)="company_settings.purchase_column_settings.price_display=!company_settings.purchase_column_settings.price_display"><i
                                  class="fa"
                                  [ngClass]="company_settings.purchase_column_settings.price_display==true? 'fa-eye': 'fa-eye-slash'"></i></button>
                            </div>
                            <app-validation-errors [mformField]="purchase_price" label="Price">
                            </app-validation-errors>
                          </div>
                        </div>

                        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="purchase_amount">Amount</label>
                          <div class="input-group">
                            <input type="text" class="form-control" #purchase_amount="ngModel" required
                              name="purchase_amount" id="purchase_amount" placeholder="Amount"
                              [(ngModel)]="company_settings.purchase_column_settings.amount">

                            <div class="input-group-append">
                              <button class="btn btn-success" type="button"
                                (click)="company_settings.purchase_column_settings.amount_display=!company_settings.purchase_column_settings.amount_display"><i
                                  class="fa"
                                  [ngClass]="company_settings.purchase_column_settings.amount_display==true? 'fa-eye': 'fa-eye-slash'"></i></button>
                            </div>
                            <app-validation-errors [mformField]="purchase_amount" label="Amount">
                            </app-validation-errors>
                          </div>
                        </div>
                      </div>
                      <!-- </div> -->
                    </ng-template>

                  </li>

                  <li [ngbNavItem]="3">
                    <a ngbNavLink>Chart Of Accounts Settings</a>
                    <ng-template ngbNavContent>
                      <!-- <div id="collapseOne" [ngClass]="{'show':coa_collapse==false}" class="collapse"
                        aria-labelledby="headingOne" data-parent="#accordionExample"> -->
                      <!-- TODO call coa after adding bank (added_bank)="getCoa()" -->
                      <app-coa [company_id]="company_id"></app-coa>
                      <!-- </div> -->
                    </ng-template>
                  </li>

                  <li [ngbNavItem]="4">
                    <a ngbNavLink>Inventory</a>
                    <ng-template ngbNavContent>
                      <!-- <div id="collapseOne" [ngClass]="{'show':inventory_collapse==false}" class="collapse"
                        aria-labelledby="headingOne" data-parent="#accordionExample"> -->
                      <!-- <div class="card-body row"> -->
                      <div class="form-group col-12">
                        <label for="title">Stock Sorting</label>
                        <select class="form-control" required #stock_sort="ngModel" name="stock_sort"
                          [(ngModel)]="company_settings.stock_sort">
                          <option *ngFor="let stock_sort of stock_sorts" [ngValue]="stock_sort">{{stock_sort}}</option>
                        </select>
                        <app-validation-errors [mformField]="stock_sort" label="Stock sort">
                        </app-validation-errors>
                        <!-- <ng-select [items]="stock_sorts" required #stock_sort="ngModel" name="stock_sort"
                              bindLabel="name" [(ngModel)]="company_settings.stock_sort"
                              placeholder="Select Stock Sort Order">
                            </ng-select> -->
                      </div>
                      <div class="form-group col-12 custom-switch pl-5">
                        <input type="checkbox" value="warehouse" [(ngModel)]="company_settings.is_warehouse"
                          name="warehouse" class="custom-control-input" id="warehouse">
                        <label class="custom-control-label" for="warehouse">Do you have warehouse ?</label>
                      </div>

                      <!-- </div> -->
                      <!-- </div> -->
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="5">
                    <a ngbNavLink>RCM</a>
                    <ng-template ngbNavContent>
                      <div class="form-group col-12">
                        <div class="form-group required col-4 col-sm-6 col-md-3 col-xl-3">
                          <label for="ordernumber_length">RCM Number Length</label>
                          <input type="text" class="form-control" placeholder="ordernumber_length"
                            name="ordernumber_length" id="ordernumber_length"
                            [(ngModel)]="company_settings.rcm_column_settings.ordernumber_length">
                        </div>
                        <div class="form-group required col-8">
                          <label for="rcm_order_number">RCM Order Number</label>
                          <div class="input-group">
                            <input class="form-control" type="text" required #rcm_order_prefix="ngModel"
                              id="rcm_order_prefix" name="rcm_order_prefix"
                              [(ngModel)]="company_settings.rcm_column_settings.rcm_order_prefix" placeholder="Prefix">

                            <input type="number" class="form-control" id="rcm_order_number" required
                              #rcm_order_number="ngModel" name="rcm_order_number"
                              [(ngModel)]="company_settings.rcm_column_settings.rcm_order_number"
                              placeholder="Sequence Number">

                            <input type="text" class="form-control" id="rcm_order_suffix" required
                              #rcm_order_suffix="ngModel" name="rcm_order_suffix"
                              [(ngModel)]="company_settings.rcm_column_settings.rcm_order_suffix" placeholder="Suffix">

                            <app-validation-errors [mformField]="rcm_order_prefix" label="RCM Order Prefix">
                            </app-validation-errors>
                            <app-validation-errors [mformField]="rcm_order_number" label="RCM Order Number">
                            </app-validation-errors>
                            <app-validation-errors [mformField]="rcm_order_suffix" label="RCM Order Suffix">
                            </app-validation-errors>
                          </div>

                        </div>

                        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                          <label for="rcm_order_number">Order Number Format</label>
                          <div>
                            {{company_settings.rcm_column_settings.rcm_order_prefix}}
                            {{company_settings.rcm_column_settings.rcm_order_number |
                            reverseString:company_settings.rcm_column_settings.ordernumber_length}}
                            {{company_settings.rcm_column_settings.rcm_order_suffix}}
                          </div>
                        </div>

                      </div>
                    </ng-template>
                  </li>
                </ul>
              </div>
            </div>

            <div [ngbNavOutlet]="nav" class="mt-2"></div>


          </div>
          <div class="tab-pane fade show active" [ngClass]="{'show active':navTab == 'statutory' || !navTab}"
            id="statutory" role="tabpanel">
            <div class="form-row">
              <div *ngIf="company_settings.is_gst_filing" class="form-group col-12 col-sm-6 col-md-6 col-xl-6">
                <label for="gst">GST</label>
                <input maxlength="15" type="text" name="gst" class="form-control" [(ngModel)]="company_settings.gst">
              </div>
              <div *ngIf="company_settings.is_gst_filing"
                class="form-group required col-12 col-sm-6 col-md-6 col-xl-6 ">
                <label for="gst_effective_date">GST Effective Date</label>
                <div class="input-group">
                  <input class="form-control" name="gst_effective_date" #gst_effective_date="ngModel" required
                    placeholder="Select a date" angular-mydatepicker #dp_gst_effective_date="angular-mydatepicker"
                    [(ngModel)]="company_settings.gst_effective_date" [options]="gstEffectiveDateOptions" />
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="company_settings.gst_effective_date"
                      (click)="dp_gst_effective_date.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="dp_gst_effective_date.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>
                <app-validation-errors [mformField]="gst_effective_date" label="GST Effective Date">
                </app-validation-errors>
              </div>

              <div *ngIf="company_settings.is_tds_filing" class="form-group col-12 col-sm-6 col-md-6 col-xl-6">
                <label for="tds">TAN</label>
                <input type="text" name="tds" class="form-control" [(ngModel)]="company_settings.tds">
              </div>
              <div *ngIf="company_settings.is_tds_filing" class="form-group required col-12 col-sm-6 col-md-6 col-xl-6">
                <label for="tds_effective_date">TAN Effective Date</label>
                <div class="input-group">
                  <input class="form-control" name="tds_effective_date" #tds_effective_date="ngModel" required
                    placeholder="Select a date" angular-mydatepicker #dp_tds_effective_date="angular-mydatepicker"
                    [(ngModel)]="company_settings.tds_effective_date" [options]="tdsEffectiveDateOptions" />
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="company_settings.tds_effective_date"
                      (click)="dp_tds_effective_date.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="dp_tds_effective_date.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>
                <app-validation-errors [mformField]="tds_effective_date" label="TDS Effective Date">
                </app-validation-errors>
              </div>
              <div
                *ngIf="company_settings?.company_type?.id==3||company_settings?.company_type?.id==4||company_settings?.company_type?.id==8"
                class="form-group col-12 col-sm-6 col-md-6 col-xl-6">
                <label for="cin">CIN</label>
                <input type="text" name="cin" class="form-control" [(ngModel)]="company_settings.cin">
              </div>
              <div
                *ngIf="company_settings?.company_type?.id==3||company_settings?.company_type?.id==4||company_settings?.company_type?.id==8"
                class="form-group required col-12 col-sm-6 col-md-6 col-xl-6">
                <label for="cin_effective_date">CIN Effective Date</label>
                <div class="input-group">
                  <input class="form-control" name="cin_effective_date" #cin_effective_date="ngModel" required
                    placeholder="Select a date" angular-mydatepicker #dp_cin_effective_date="angular-mydatepicker"
                    [(ngModel)]="company_settings.cin_effective_date" [options]="cinEffectiveDateOptions" />
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="company_settings.cin_effective_date"
                      (click)="dp_cin_effective_date.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="dp_cin_effective_date.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>
                <app-validation-errors [mformField]="cin_effective_date" label="CIN Effective Date">
                </app-validation-errors>
              </div>

              <div *ngIf="company_settings.is_pf_filing" class="form-group col-12 col-sm-6 col-md-6 col-xl-6">
                <label for="pf">PF</label>
                <input type="text" name="pf" class="form-control" [(ngModel)]="company_settings.pf">
              </div>
              <div *ngIf="company_settings.is_pf_filing" class="form-group required col-12 col-sm-6 col-md-6 col-xl-6">
                <label for="pf_effective_date">PF Effective Date</label>
                <div class="input-group">
                  <input class="form-control" name="pf_effective_date" #pf_effective_date="ngModel" required
                    placeholder="Select a date" angular-mydatepicker #dp_pf_effective_date="angular-mydatepicker"
                    [(ngModel)]="company_settings.pf_effective_date" [options]="pfEffectiveDateOptions" />
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="company_settings.pf_effective_date"
                      (click)="dp_pf_effective_date.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="dp_pf_effective_date.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>
                <app-validation-errors [mformField]="pf_effective_date" label="PF Effective Date">
                </app-validation-errors>
              </div>
              <div *ngIf="company_settings.is_esi_filing" class="form-group col-12 col-sm-6 col-md-6 col-xl-6">
                <label for="esi">ESI</label>
                <input type="text" name="esi" class="form-control" [(ngModel)]="company_settings.esi">
              </div>
              <div *ngIf="company_settings.is_esi_filing" class="form-group required col-12 col-sm-6 col-md-6 col-xl-6">
                <label for="esi_effective_date">ESI Effective Date</label>
                <div class="input-group">
                  <input class="form-control" name="esi_effective_date" #esi_effective_date="ngModel" required
                    placeholder="Select a date" angular-mydatepicker #dp_esi_effective_date="angular-mydatepicker"
                    [(ngModel)]="company_settings.esi_effective_date" [options]="esiEffectiveDateOptions" />
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="company_settings.esi_effective_date"
                      (click)="dp_esi_effective_date.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="dp_esi_effective_date.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>
                <app-validation-errors [mformField]="esi_effective_date" label="ESI Effective Date">
                </app-validation-errors>
              </div>
              <div *ngIf="company_settings.is_lut" class="form-group col-12 col-sm-6 col-md-6 col-xl-6">
                <label for="lut">LUT</label>
                <input type="text" name="lut" class="form-control" [(ngModel)]="company_settings.lut">
              </div>
              <div *ngIf="company_settings.is_lut" class="form-group required col-12 col-sm-6 col-md-6 col-xl-6">
                <label for="lut_effective_date">LUT Effective Date</label>
                <div class="input-group">
                  <input class="form-control" name="lut_effective_date" #lut_effective_date="ngModel" required
                    placeholder="Select a date" angular-mydatepicker #dp_lut_effective_date="angular-mydatepicker"
                    [(ngModel)]="company_settings.lut_effective_date" [options]="lutEffectiveDateOptions" />
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="company_settings.lut_effective_date"
                      (click)="dp_lut_effective_date.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="dp_lut_effective_date.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>
                <app-validation-errors [mformField]="lut_effective_date" label="LUT Effective Date">
                </app-validation-errors>
              </div>
              <div class="form-group col-12 col-sm-6 col-md-6 col-xl-6">
                <label for="pan">PAN</label>
                <input type="text" maxlength="10" name="pan" id="pan" class="form-control"
                  [(ngModel)]="company_settings.pan">
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-xl-3 col-6 custom-switch" style="left: 2.5rem;">
                <input type="checkbox" value="true" [(ngModel)]="company_settings.is_composite_company"
                  name="is_composite_company" class="custom-control-input pl-5" id="is_composite_company">
                <label class="custom-control-label" for="is_composite_company">Is Composite Company ?</label>
              </div>
              <!-- <div *ngIf="company_settings.is_tds_filing==true" class="form-group col-12 col-sm-6 col-md-6 col-xl-6">
                <label for="tan">TAN</label>
                <input type="text" name="tan" id="tan" class="form-control" [(ngModel)]="company_settings.tan">
              </div> -->
            </div>
          </div>

          <div class="tab-pane fade show active" [ngClass]="{'show active':navTab == 'member_details' || !navTab}"
            id="member_details" role="tabpanel">

            <div class="card-header">
              <h4>Provide {{member_name}} Details </h4>
            </div>
            <div class="form-row m-2" *ngFor="let memberObj of company_settings.member_details; let mem_index = index">
              <div class="form-group required col-md-3">
                <label for="">Name</label>
                <input type="text" class="form-control" placeholder="Name" #mem_name="ngModel" required
                  id="mem_name{{mem_index}}" name="mem_name{{mem_index}}" [(ngModel)]="memberObj.name">
                <app-validation-errors [mformField]="mem_name" label="Name"></app-validation-errors>
              </div>
              <div class="form-group required col-md-3">
                <label for="">Father Name</label>
                <input type="text" class="form-control" placeholder="Father Name" #father_name="ngModel" required
                  id="father_name{{mem_index}}" name="father_name{{mem_index}}" [(ngModel)]="memberObj.father_name">
                <app-validation-errors [mformField]="father_name" label="Father Name"></app-validation-errors>
              </div>
              <div class="form-group required col-md-3">
                <label for="">PAN</label>
                <input type="text" class="form-control" maxlength="10" placeholder="PAN" #mem_pan="ngModel" required
                  id="mem_pan{{mem_index}}" name="mem_pan{{mem_index}}" [(ngModel)]="memberObj.pan">
                <app-validation-errors [mformField]="mem_pan" label="PAN"></app-validation-errors>
              </div>
              <div class="form-group required col-md-3"
                *ngIf="company_settings?.company_type?.id!=2 && company_settings?.company_type?.id!=5">
                <label for="">DIN</label>
                <input type="text" class="form-control" maxlength="10" placeholder="DIN" #mem_din="ngModel" required
                  id="mem_din{{mem_index}}" name="mem_din{{mem_index}}" [(ngModel)]="memberObj.din">
                <app-validation-errors [mformField]="mem_din" label="DIN"></app-validation-errors>
              </div>
              <div class="form-group required col-sm-3 col-md-3 col-xl-3">
                <label for="mem_dob">Date Of Birth</label>
                <div class="input-group">
                  <input class="form-control" name="mem_dob{{mem_index}}" #mem_dob="ngModel" required
                    placeholder="Select DOB" angular-mydatepicker #dp_mem_dob="angular-mydatepicker"
                    [(ngModel)]="memberObj.dob" [options]="memberDobDateOptions" />
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="memberObj.dob"
                      (click)="dp_mem_dob.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="dp_mem_dob.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>
                <app-validation-errors [mformField]="mem_dob" label="Date Of Birth">
                </app-validation-errors>
              </div>
              <div class="form-group required col-md-3">
                <label for="">Aadhar</label>
                <input type="text" maxlength="12" class="form-control" placeholder="Aadhar" #mem_aadhar="ngModel"
                  required id="mem_aadhar{{mem_index}}" name="mem_aadhar{{mem_index}}" [(ngModel)]="memberObj.aadhar"
                  number>
                <app-validation-errors [mformField]="mem_aadhar" label="Aadhar"></app-validation-errors>
              </div>
              <div class="form-group required col-md-3">
                <label for="">Mobile</label>
                <input type="text" maxlength="13" class="form-control" required #mem_mobile="ngModel"
                  placeholder="Mobile" id="mem_mobile{{mem_index}}" name="mem_mobile{{mem_index}}"
                  [(ngModel)]="memberObj.mobile" number>
                <app-validation-errors [mformField]="mem_mobile" label="Mobile">
                </app-validation-errors>
              </div>
              <div class="form-group required col-md-3">
                <label for="">Email</label>
                <input type="text" class="form-control" required #mem_email="ngModel" placeholder="Email"
                  id="mem_email{{mem_index}}" name="mem_email{{mem_index}}" [(ngModel)]="memberObj.email" email>
                <app-validation-errors [mformField]="mem_email" label="Email"></app-validation-errors>
              </div>
              <div class="form-group required col-md-3">
                <label for="">Address</label>
                <textarea maxlength="200" class="form-control" required #mem_address="ngModel" placeholder="Address"
                  id="mem_address{{mem_index}}" name="mem_address{{mem_index}}" [(ngModel)]="memberObj.address">
                </textarea>
                <app-validation-errors [mformField]="mem_address" label="Address"></app-validation-errors>
              </div>
              <!-- <div class="form-group required col-md-2">
              <label for="">Designation</label>
              <input type="text" class="form-control" required #designation="ngModel" placeholder="Designation" id="designation"
                name="contactsObj.designation{{i}}" [(ngModel)]="contactsObj.designation">
              <app-validation-errors [mformField]="designation" label="Designation"></app-validation-errors>
            </div> -->
              <div class="form-group col-md-2">
                <button class="btn btn-primary mr-2 mt-4" type="button" (click)="addMemberRow()">
                  <!-- <i class="fa fa-plus"></i> --> Add New
                </button>
                <button *ngIf="i!=0" class="btn btn-danger mt-4" type="button" (click)="deleteMemberRow(mem_index)">
                  <!-- <i class="fa fa-trash"></i> --> Delete
                </button>
              </div>
            </div>

          </div>

          <div class="tab-pane fade" [ngClass]="{'show active':navTab == 'company' || !navTab}" role="tabpanel">
            <div class="form-row">

              <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="company">Company Name</label>
                <input type="text" class="form-control" required #company="ngModel" name="company" id="company"
                  [(ngModel)]="company_settings.company">
                <app-validation-errors [mformField]="company" label="Company Name"></app-validation-errors>
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="email">Email</label>
                <input type="email" class="form-control" name="email" id="email" [(ngModel)]="company_settings.email">
              </div>

              <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="cin_number">CIN Number</label>
                <input type="text" class="form-control" name="cin_number" id="cin_number"
                  [(ngModel)]="company_settings.cin_number">
              </div> -->

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="address1">Address Line 1</label>
                <input type="text" maxlength="200" class="form-control" name="address1" id="address1"
                  [(ngModel)]="company_settings.address1">
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="address2">Address Line 2</label>
                <input type="text" class="form-control" maxlength="200" name="address2" id="address2"
                  [(ngModel)]="company_settings.address2">
              </div>



              <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="country">Country</label>
                <ng-select [items]="country_list" required #country="ngModel" name="country"
                  (change)="getStateByCountry()" bindLabel="name" [(ngModel)]="company_settings.country"
                  placeholder="Select Country">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.name }}
                    </div>
                  </ng-template>
                </ng-select>
                <app-validation-errors [mformField]="country" label="Country"></app-validation-errors>
              </div>
              <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="state">Province/State</label>
                <select type="text" class="form-control" #company_state="ngModel" required name="company_state"
                  id="company_state" placeholder="State" [(ngModel)]="company_settings.state">
                  <option *ngFor="let item of stateList| keyvalue" [ngValue]="item.value">{{item.value.name}}
                  </option>
                </select>
                <app-validation-errors [mformField]="company_state" label="State"></app-validation-errors>
              </div>
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="city">City</label>
                <input maxlength="100" type="text" class="form-control" name="city" id="city"
                  [(ngModel)]="company_settings.city">
              </div>
              <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="currency">Main Currency</label>
                <ng-select [items]="currency_list" required #currency="ngModel" name="currency" bindLabel="name"
                  [(ngModel)]="company_settings.currency" placeholder="Select Currency">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.name }}
                    </div>
                  </ng-template>
                </ng-select>
                <app-validation-errors [mformField]="currency" label="currency"></app-validation-errors>

              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="postal">Postal/Zip Code</label>
                <input type="text" maxlength="6" class="form-control" name="postal_code" id="postal_code"
                  placeholder="Postal Code" [(ngModel)]="company_settings.postal_code">
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="phone">Phone</label>
                <input type="number" maxlength="13" class="form-control" name="phone" id="phone" placeholder="phone"
                  [(ngModel)]="company_settings.phone">
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="postal">Logo</label>
                <div ngfDrop selectable="1" [(validDrag)]="validComboDrag" [(files)]="files"
                  [(lastInvalids)]="lastInvalids" class="well my-drop-zone" accept="image/*,application/pdf"
                  [class.invalid-drag]="validComboDrag===false" [class.valid-drag]="validComboDrag"
                  (filesChange)="lastFileAt=getDate()">
                  Drop/select <strong>Image</strong> here... (PNG, JPEG)
                </div>
                <div *ngIf="is_edit_form">
                  <img *ngIf="company_settings.logo !=undefined && company_settings.logo[0]!=undefined"
                    style="width: 50px;" src="{{image_path}}{{company_settings.logo[0]['file_path']}}" />
                </div>

              </div>


              <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="company_type">Company Type</label>
                <ng-select [items]="company_types" required #company_type="ngModel" name="company_type" bindLabel="name"
                  [(ngModel)]="company_settings.company_type" (change)="setMemberName()"
                  placeholder="Select Company Type">
                </ng-select>
                <app-validation-errors [mformField]="company_type" label="Company Type"></app-validation-errors>
              </div>
              <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="company_category">Company Category</label>
                <ng-select [items]="company_categories" required #company_category="ngModel" name="company_category"
                  bindLabel="name" [(ngModel)]="company_settings.company_category"
                  placeholder="Select Company Category">
                </ng-select>
                <app-validation-errors [mformField]="company_category" label="Company Category"></app-validation-errors>
              </div>
              <!-- <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3 col-6">
                <label for="gst_mapping">GST Mapping</label>
                <ng-select [items]="gst_mappings" required #gst_mapping="ngModel" name="gst_mapping" bindLabel="name"
                  [(ngModel)]="company_settings.gst_mapping" placeholder="Select GST Mapping">
                </ng-select>
                <app-validation-errors [mformField]="gst_mapping" label="GST Mapping"></app-validation-errors>
              </div> -->
              <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3 col-6">
                <label for="incorporation_date">Date Of Incorporation</label>
                <div class="input-group">
                  <input class="form-control" name="incorporation_date"
                    (dateChanged)="setBookBeginningValidation($event)" #incorporation_date="ngModel" required
                    placeholder="Select a date" angular-mydatepicker #dp_incorporation_date="angular-mydatepicker"
                    [(ngModel)]="company_settings.incorporation_date" [options]="incorporationDateOptions" />
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="company_settings.incorporation_date"
                      (click)="dp_incorporation_date.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="dp_incorporation_date.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>
                <app-validation-errors [mformField]="incorporation_date" label="Incorporation Date">
                </app-validation-errors>
              </div>

              <div *ngIf="company_settings.incorporation_date"
                class="form-group required col-12 col-sm-6 col-md-3 col-xl-3 col-6">
                <label for="book_beginning">Book Beginning</label>
                <div class="input-group">
                  <input class="form-control" name="book_beginning_date" #book_beginning_date="ngModel" required
                    placeholder="Select a date" angular-mydatepicker #dp_book_beginning="angular-mydatepicker"
                    [(ngModel)]="company_settings.book_beginning" [options]="bookBeginningDateOptions" />
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="company_settings.book_beginning"
                      (click)="dp_book_beginning.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="dp_book_beginning.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>
                <app-validation-errors [mformField]="book_beginning_date" label="Book Beginning">
                </app-validation-errors>
              </div>

              <div *ngIf="company_settings.company_type?.id==6 || company_settings.company_type?.id==7"
                class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="nature_of_activity">Nature Of Activity</label>
                <input type="text" class="form-control" name="nature_of_activity" id="nature_of_activity"
                  [(ngModel)]="company_settings.nature_of_activity">
              </div>

              <div *ngIf="company_settings.company_type?.id==6 || company_settings.company_type?.id==7"
                class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="authorized_person">Authorized Person</label>
                <input type="text" class="form-control" name="authorized_person" id="authorized_person"
                  [(ngModel)]="company_settings.authorized_person">
              </div>

              <div *ngIf="company_settings.company_type?.id==6 || company_settings.company_type?.id==7"
                class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="registered_number">12A / 80G Registered Number</label>
                <input type="text" class="form-control" name="registered_number" id="registered_number"
                  [(ngModel)]="company_settings.registered_number">
              </div>

              <div *ngIf="company_settings.company_type?.id==6 || company_settings.company_type?.id==7"
                class="form-group col-12 col-sm-6 col-md-3 col-xl-3 col-6">
                <label for="registered">12A / 80G Registered Date</label>
                <div class="input-group">
                  <input class="form-control" name="registered_date" #registered_date="ngModel"
                    placeholder="Select a date" angular-mydatepicker #dp_registered_date="angular-mydatepicker"
                    [(ngModel)]="company_settings.registered_date" [options]="registeredDateOptions" />
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="company_settings.registered_date"
                      (click)="dp_registered_date.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="dp_registered_date.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>
                <!-- <app-validation-errors [mformField]="registered_date" label="Registered Date">
                </app-validation-errors> -->
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-xl-3 col-6 custom-switch" style="left: 2.5rem;">
                <input type="checkbox" value="true" [(ngModel)]="company_settings.is_inventory_needed"
                  name="is_inventory_needed" class="custom-control-input pl-5" id="is_inventory_needed">
                <label class="custom-control-label" for="is_inventory_needed">Inventory Needed?</label>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-xl-3 col-6 custom-switch" style="left: 2.5rem;">
                <input type="checkbox" value="true" [(ngModel)]="company_settings.is_branch" name="is_branch"
                  class="custom-control-input pl-5" id="is_branch">
                <label class="custom-control-label" for="is_branch">Do you Have Branch?</label>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-xl-3 col-6 custom-switch" style="left: 2.5rem;">
                <input type="checkbox" value="true" [(ngModel)]="company_settings.is_gst_filing" name="is_gst_filing"
                  class="custom-control-input pl-5" id="is_gst_filing">
                <label class="custom-control-label" for="is_gst_filing"> GST</label>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-xl-3 col-6 custom-switch" style="left: 2.5rem;">
                <input type="checkbox" value="true" [(ngModel)]="company_settings.is_tds_filing" name="is_tds_filing"
                  class="custom-control-input pl-5" id="is_tds_filing">
                <label class="custom-control-label" for="is_tds_filing"> TDS</label>
              </div>

              <div class="col-12 col-sm-6 col-md-3 col-xl-3 col-6 custom-switch p-2" style="left: 2.5rem;">
                <input type="checkbox" value="true" [(ngModel)]="company_settings.is_pf_filing" name="is_pf_filing"
                  class="custom-control-input pl-5" id="is_pf_filing">
                <label class="custom-control-label" for="is_pf_filing"> PF</label>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-xl-3 col-6 custom-switch p-2" style="left: 2.5rem;">
                <input type="checkbox" value="true" [(ngModel)]="company_settings.is_esi_filing" name="is_esi_filing"
                  class="custom-control-input pl-5" id="is_esi_filing">
                <label class="custom-control-label" for="is_esi_filing"> ESI</label>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-xl-3 col-6 custom-switch p-2" style="left: 2.5rem;">
                <input type="checkbox" value="true" [(ngModel)]="company_settings.is_lut" name="is_lut"
                  class="custom-control-input pl-5" id="is_lut">
                <label class="custom-control-label" for="is_lut"> LUT</label>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-xl-3 col-6 custom-switch p-2" style="left: 2.5rem;">
                <input type="checkbox" value="true" [(ngModel)]="company_settings.is_sez" name="is_sez"
                  class="custom-control-input pl-5" id="is_sez">
                <label class="custom-control-label" for="is_sez"> SEZ</label>
              </div>

              <!-- <div class="col-12 col-sm-6 col-md-3 col-xl-3 col-6 custom-switch p-2" style="left: 2.5rem;"
                *ngIf="company_settings?.company_type?.id==3||company_settings?.company_type?.id==4||company_settings?.company_type?.id==8">
                <input type="checkbox" value="true" [(ngModel)]="company_settings.is_cin_filing" name="is_cin_filing"
                  class="custom-control-input pl-5" id="is_cin_filing">
                <label class="custom-control-label" for="is_cin_filing">CIN</label>
              </div> -->
              <div class="card mt-2">
                <div class="card-header">
                  <h4>Provide Company Contact Person Details </h4>
                </div>
                <div class="form-row m-2" *ngFor="let contactsObj of company_settings.contacts; let i = index">
                  <div class="form-group required col-md-3">
                    <label for="">Contact Name</label>
                    <input type="text" maxlength="100" class="form-control" placeholder="Contact Name"
                      #contact_name="ngModel" required id="example{{i}}" name="example{{i}}"
                      [(ngModel)]="contactsObj.contact_name">
                    <app-validation-errors [mformField]="contact_name" label="Contact Name"></app-validation-errors>
                  </div>
                  <div class="form-group required col-md-2">
                    <label for="">Phone </label>
                    <input type="text" class="form-control" maxlength="13" required #phone_number="ngModel"
                      placeholder="Phone Number" id="contactsObj.phone_number{{i}}" name="contactsObj.phone_number{{i}}"
                      [(ngModel)]="contactsObj.phone_number" number>
                    <app-validation-errors [mformField]="phone_number" label="Phone Number">
                    </app-validation-errors>
                  </div>
                  <div class="form-group required col-md-3">
                    <label for="">Email</label>
                    <input type="text" class="form-control" required #email="ngModel" placeholder="Email"
                      id="contactsObj.email{{i}}" name="contactsObj.email{{i}}" [(ngModel)]="contactsObj.email" email>
                    <app-validation-errors [mformField]="email" label="Email"></app-validation-errors>
                  </div>
                  <div class="form-group required col-md-2">
                    <label for="">Designation</label>
                    <input type="text" maxlength="100" class="form-control" required #designation="ngModel"
                      placeholder="Designation" id="designation{{i}}" name="contactsObj.designation{{i}}"
                      [(ngModel)]="contactsObj.designation">
                    <app-validation-errors [mformField]="designation" label="Designation"></app-validation-errors>
                  </div>
                  <div class="form-group col-md-2">
                    <button class="btn btn-primary mr-2 mt-4" type="button" (click)="addContactRow()">
                      <i class="fa fa-plus"></i>
                    </button>
                    <button *ngIf="i!=0" class="btn btn-danger mt-4" type="button" (click)="deleteContactRow(i)">
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="tab-pane fade"  [ngClass]="{'show active':navTab == 'financial' || !navTab}" id="financial"
            role="tabpanel">
            <div class="form-row">


<div class="card">
  <div class="card-body form-row p-0 pb-3">
    <button type="button"  class="btn btn-primary ml-1 mb-2"(click)="addFinancialYear()">
      <i class="fa fa-plus" aria-hidden="true"></i> Add Financial Year

    </button>
    <table class="col-12 table table-stripped table-hover table-bordered" width="100%">
      <thead class="thead-light">
        <tr>
          <th >S.No</th>
          <th >Financial year</th>
          <!-- <th width="25%">Invoice Number</th>  -->
          <th >Status</th>
          <!-- <th width="20%">Invoice No Length</th> -->
          <th >Action</th>
        </tr>
      </thead>
      <tbody
        *ngFor="let financial of company_settings.financial_year let i = index">
        <tr>
          <td>
            {{i+1}}
          </td>


          <td>
            {{financial.start_year}} - {{financial.end_year}}
          </td>

          <td>

            {{financial?.status}}



          <td>

            <button *ngIf="finalzeBtnStatus==i" type="button" class="btn btn-info -primary ml-1" (click)="finalizeFinancialYear(financial)">Finalize
              <i class="" aria-hidden="false"></i>
            </button>
            <!-- <button type="button" class="btn btn-danger" (click)="deleteItem(i)">
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button> -->

          </td>

      </tbody>
    </table>
  </div>
</div>
              <!-- <div class="form-group col-12 col-sm-6 col-md-4 col-xl-3">
                <label for="date_format">Date Format</label>
                <select class="form-control" id="date_format" name="date_format"
                  [(ngModel)]="company_settings.date_format">
                  <option label="3 Dec, 2019" value="string:d MMM, yyyy">3 Dec, 2019</option>
                  <option label="Dec 3, 2019" value="string:MMM d, yyyy">Dec 3, 2019</option>
                  <option label="3/12/2019" value="string:d/MM/yyyy">3/12/2019</option>
                  <option label="12/3/2019" value="string:MM/d/yyyy">12/3/2019</option>
                  <option label="3-12-19" value="string:d-MM-yy">3-12-19</option>
                  <option label="12-3-19" value="string:MM-d-yy">12-3-19</option>
                </select>
              </div> -->


              <!-- <div class="form-group col-12 col-sm-6 col-md-5 col-xl-3">
                <label for="date_format">Fiscal Year End</label>
                <select class="form-control" id="fiscal_year_end" name="fiscal_year_end"
                  [(ngModel)]="company_settings.fiscal_year_end">
                  <option value="1">01-April to 31-March</option>
                  <option value="2">01-January to 31-December</option>
                  <option value="3">Others</option>
                </select>
              </div> -->

              <!-- <div *ngIf="company_settings.fiscal_year_end=='3'" class="form-group col-12 col-sm-6 col-md-5 col-xl-3">
                <label for="fiscal_daterange">Fiscal Year DateRange</label>
                <div class="input-group">
                  <input class="form-control" name="fiscal_daterange" placeholder="Select Date Range"
                    angular-mydatepicker #dp_fiscal_daterange="angular-mydatepicker"
                    [(ngModel)]="company_settings.fiscal_daterange" [options]="fiscalDateRangeOptions"
                    (dateChanged)="ondate_rangeDateChanged($event)" />
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="company_settings.fiscal_daterange"
                      (click)="dp_fiscal_daterange.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="dp_fiscal_daterange.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>
              </div> -->


            </div>
          </div>
          <div class="tab-pane fade" [ngClass]="{'show active':navTab == 'warehouse' || !navTab}" id="warehouse"
            role="tabpanel">
            <div class="form-row">
              <!-- *ngIf="company_settings.warehouse" -->
              <div class="card mt-2">
                <div class="card-header">
                  <h4>Provide Warehouse Details </h4>
                </div>

                <div class="form-row m-2" *ngFor="let warehouseObj of company_settings.warehouse; let i = index">
                  <div class="form-group required col-md-4">
                    <label for="">Warehouse Name</label>
                    <input type="text" class="form-control" placeholder="Warehouse Name" #warehouse_name="ngModel"
                      required id="warehouse_name{{i}}" name="warehouseObj.name{{i}}" [(ngModel)]="warehouseObj.name">
                    <app-validation-errors [mformField]="warehouse_name" label="Warehouse Name">
                    </app-validation-errors>
                  </div>
                  <div class="form-group required col-md-4">
                    <label for="">Billing Address</label>
                    <input type="text" class="form-control" placeholder="Billing Address" #billing_address="ngModel"
                      required id="billing_address{{i}}" name="warehouseObj.billing_address{{i}}"
                      [(ngModel)]="warehouseObj.billing_address">
                    <app-validation-errors [mformField]="billing_address" label="Billing Address">
                    </app-validation-errors>
                  </div>
                  <div class="form-group required col-md-4">
                    <label for="">Shipping Address</label>
                    <input type="text" class="form-control" placeholder="Shipping Address" #shipping_address="ngModel"
                      required id="shipping_address{{i}}" name="warehouseObj.shipping_address{{i}}"
                      [(ngModel)]="warehouseObj.shipping_address">
                    <app-validation-errors [mformField]="shipping_address" label="Shipping Address">
                    </app-validation-errors>
                  </div>
                  <div class="form-group required col-md-4">
                    <label for="">Branch Name</label>
                    <ng-select [items]="company_branches" required #wh_branch_name="ngModel"
                      name="warehouseObj.branch_name{{i}}" bindLabel="name" [(ngModel)]="warehouseObj.branch_name"
                      placeholder="Select Branch Name">
                    </ng-select>
                    <app-validation-errors [mformField]="wh_branch_name" label="Branch Name">
                    </app-validation-errors>
                  </div>
                  <div class="form-group required col-md-4">
                    <label for="">Contact Name</label>
                    <input type="text" class="form-control" placeholder="Contact Name" #contact_name="ngModel" required
                      id="warehouse_contact_name{{i}}" name="warehouseObj.contact_name{{i}}"
                      [(ngModel)]="warehouseObj.contact_name">
                    <app-validation-errors [mformField]="contact_name" label="Contact Name">
                    </app-validation-errors>
                  </div>
                  <div class="form-group required col-md-4">
                    <label for="">Phone Number</label>
                    <input type="text" class="form-control" required #phone_number="ngModel" placeholder="Phone Number"
                      id="warehouse_phone_number{{i}}" name="warehouseObj.phone_number{{i}}"
                      [(ngModel)]="warehouseObj.phone_number">
                    <app-validation-errors [mformField]="phone_number" label="Phone Number">
                    </app-validation-errors>
                  </div>
                  <div class="form-group required col-md-4">
                    <label for="">Email</label>
                    <input type="text" class="form-control" required #email="ngModel" placeholder="Email"
                      id="warehouse_email{{i}}" name="warehouseObj.email{{i}}" [(ngModel)]="warehouseObj.email">
                    <app-validation-errors [mformField]="email" label="Email"></app-validation-errors>
                  </div>
                  <div class="form-group required col-md-4">
                    <label for="">Designation</label>
                    <input type="text" class="form-control" required #designation="ngModel" placeholder="Designation"
                      id="warehouse_designation{{i}}" name="warehouseObj.designation{{i}}"
                      [(ngModel)]="warehouseObj.designation">
                    <app-validation-errors [mformField]="designation" label="Designation">
                    </app-validation-errors>
                  </div>
                  <div class="form-group col-md-5">
                    <button class="btn btn-primary mr-2 mt-4" type="button" (click)="addWarehouseContactRow()">Add
                      New Warehouse</button>
                    <button *ngIf="i>0" class="btn btn-danger mt-4" type="button"
                      (click)="deleteWarehouseContactRow(i)">Delete
                      Warehouse</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" [ngClass]="{'show active':navTab == 'bank_details' || !navTab}" id="bank_details"
            role="tabpanel">
            <div class="form-row">
              <div class="card mt-2">
                <div class="card-header">
                  <h4>Provide Bank Details</h4>
                </div>
                <div class="form-row m-2" *ngFor="let bankObj of company_settings.bank; let i = index">
                  <div class="form-group required col-md-2">
                    <label for="">Bank Name</label>
                    <input type="text" class="form-control" placeholder="Bank Name" #bank_name="ngModel" required
                      id="bank_name{{i}}" name="bankObj.name{{i}}" [(ngModel)]="bankObj.name">
                    <app-validation-errors [mformField]="bank_name" label="Bank Name">
                    </app-validation-errors>
                  </div>
                  <div class="form-group col-md-2">
                    <label for="">Branch</label>
                    <input type="text" class="form-control" placeholder="Branch Name" id="branch{{i}}"
                      name="bankObj.branch{{i}}" [(ngModel)]="bankObj.branch">
                  </div>
                  <div class="form-group col-md-3">
                    <label for="">Account Number</label>
                    <input type="text" class="form-control" placeholder="Account Number" id="account_number{{i}}"
                      name="bankObj.account_number{{i}}" [(ngModel)]="bankObj.account_number">
                  </div>
                  <div class="form-group col-md-3">
                    <label for="">IFSC Code</label>
                    <input type="text" class="form-control" placeholder="IFSC Code" id="ifsc_code{{i}}"
                      name="bankObj.ifsc_code{{i}}" [(ngModel)]="bankObj.ifsc_code">
                  </div>
                  <div class="form-group col-md-2">
                    <button class="btn btn-primary mr-2 mt-4" type="button" (click)="addBankRow()">
                      <i class="fa fa-plus"></i>
                    </button>
                    <button *ngIf="i>0" class="btn btn-danger mt-4" type="button" (click)="deleteBankRow(i)">
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="tab-pane fade" [ngClass]="{'show active':navTab == 'branch' || !navTab}" id="branch"
            role="tabpanel">
            <div class="form-row">

              <div class="card mt-2">
                <div class="card-header">
                  <h4 style="width: 100%;">Provide Branch Details</h4>
                  <div style="width: 100%;">
                    <button type="button" class="btn btn-primary float-right"
                      (click)="branch_details={};branch_details.contacts=[{}]">Add New
                      Branch</button>
                  </div>
                </div>

                <div class="form-row m-2">
                  <div class="form-group required col-12 col-sm-6 col-md-4 col-xl-4">
                    <label for="">Branch Name</label>
                    <input type="text" class="form-control" placeholder="Branch Name" #branch_name="ngModel" required
                      id="branch_name" name="branch_name" [(ngModel)]="branch_details.name">
                    <app-validation-errors [mformField]="branch_name" label="Branch Name">
                    </app-validation-errors>
                  </div>
                  <div class="form-group required col-12 col-sm-6 col-md-4 col-xl-4">
                    <label for="">Address</label>
                    <input type="text" class="form-control" placeholder="Branch Address" #branch_address="ngModel"
                      required id="branch_address" name="branch_address" [(ngModel)]="branch_details.address">
                    <app-validation-errors [mformField]="branch_address" label="Branch Address">
                    </app-validation-errors>
                  </div>
                  <div class="form-group required col-12 col-sm-6 col-md-4 col-xl-4">
                    <label for="">GST</label>
                    <input type="text" maxlength="15" class="form-control" placeholder="GST" #branch_gst="ngModel"
                      required id="branch_gst" (keypress)="setGst()" name="branch_gst" [(ngModel)]="branch_details.gst">
                    <app-validation-errors [mformField]="branch_gst" label="Branch GST">
                    </app-validation-errors>
                  </div>
                  <div *ngIf="branch_gst_filing" class="col-12 col-sm-6 col-md-3 col-xl-4 col-6 custom-switch"
                    style="left: 2.5rem;">
                    <input type="checkbox" value="true" [(ngModel)]="branch_details.is_gst_filing"
                      name="branch_is_gst_filing" class="custom-control-input pl-5" id="branch_is_gst_filing">
                    <label class="custom-control-label" for="branch_is_gst_filing"> GST?</label>
                  </div>
                  <div *ngIf="branch_details.is_gst_filing"
                    class="form-group required col-12 col-sm-6 col-md-6 col-xl-4 ">
                    <label for="branch_gst_effective_date">GST Effective Date</label>
                    <div class="input-group">
                      <input class="form-control" name="branch_gst_effective_date" #branch_gst_effective_date="ngModel"
                        required placeholder="Select a date" angular-mydatepicker
                        #dp_branch_gst_effective_date="angular-mydatepicker"
                        [(ngModel)]="branch_details.gst_effective_date" [options]="branchGstEffectiveDateOptions" />
                      <div class="input-group-append">
                        <button type="button" class="btn btn-secondary" *ngIf="branch_details.gst_effective_date"
                          (click)="dp_branch_gst_effective_date.clearDate()">
                          <i class="fa fa-close"></i>
                        </button>
                      </div>
                      <div class="input-group-append">
                        <button type="button" class="btn btn-secondary"
                          (click)="dp_branch_gst_effective_date.toggleCalendar()">
                          <i class="fa fa-calendar-o"></i>
                        </button>
                      </div>
                    </div>
                    <app-validation-errors [mformField]="branch_gst_effective_date" label="GST Effective Date">
                    </app-validation-errors>
                  </div>
                  <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-4">
                    <label for="country">Country</label>
                    <ng-select [items]="country_list" required #branch_country="ngModel" name="branch_country"
                      (change)="getBranchStateByCountry(null,null)" bindLabel="name"
                      [(ngModel)]="branch_details.country" placeholder="Select Country">
                      <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <div [ngOptionHighlight]="search">
                          {{ item.name }}
                        </div>
                      </ng-template>
                    </ng-select>
                    <app-validation-errors [mformField]="country" label="Country"></app-validation-errors>
                  </div>
                  <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-4">
                    <label for="state">Province/State</label>
                    <select type="text" class="form-control" #state="ngModel" required name="branch_state"
                      id="branch_state" placeholder="State" [(ngModel)]="branch_details.state">
                      <option *ngFor="let item of branch_details.stateList| keyvalue" [ngValue]="item.value">
                        {{item?.value?.name}} </option>
                    </select>
                    <app-validation-errors [mformField]="state" label="State"></app-validation-errors>
                  </div>
                  <div class="form-group required col-12 col-sm-6 col-md-4 col-xl-4">
                    <label for="">City</label>
                    <input type="text" class="form-control" placeholder="City" #branch_city="ngModel" required
                      id="branch_city" name="branch_city" [(ngModel)]="branch_details.city">
                    <app-validation-errors [mformField]="branch_city" label="City Of Branch">
                    </app-validation-errors>
                  </div>
                  <!-- <div class="col-12 col-sm-6 col-md-4 col-xl-3 col-6 custom-switch" style="left: 2.5rem;">
                    <input type="checkbox" value="true" [(ngModel)]="branch_obj.is_warehouse" name="is_warehouse{{i}}"
                      class="custom-control-input pl-5" id="is_warehouse{{i}}">
                    <label class="custom-control-label" for="is_warehouse">Do you Have Warehouse For This
                      Branch?</label>
                  </div> -->

                  <div class="card mt-2">
                    <div class="card-header">
                      <h4>Provide Branch Contact Details</h4>
                    </div>
                    <div class="form-row m-2"
                      *ngFor="let branch_contact_obj of branch_details.contacts; let contact_index = index">
                      <div class="form-group required col-md-3">
                        <label for="">Contact Name</label>
                        <input type="text" class="form-control" placeholder="Contact Name"
                          #branch_contact_name="ngModel" required id="branch_contact_name{{contact_index}}"
                          name="branch_contact_name{{contact_index}}" [(ngModel)]="branch_contact_obj.contact_name">
                        <app-validation-errors [mformField]="branch_contact_name" label="Contact Name">
                        </app-validation-errors>
                      </div>
                      <div class="form-group required col-md-2">
                        <label for="">Phone Number</label>
                        <input type="text" maxlength="13" class="form-control" required #branch_phone_number="ngModel"
                          placeholder="Phone Number" id="branch_contact_phone{{contact_index}}"
                          name="branch_contact_phone{{contact_index}}" [(ngModel)]="branch_contact_obj.phone_number"
                          number>
                        <app-validation-errors [mformField]="branch_phone_number" label="Phone Number">
                        </app-validation-errors>
                      </div>
                      <div class="form-group required col-md-3">
                        <label for="">Email</label>
                        <input type="text" class="form-control" required #branch_contact_email="ngModel"
                          placeholder="Email" id="branch_contact_email{{contact_index}}"
                          name="branch_contact_email{{contact_index}}" [(ngModel)]="branch_contact_obj.email" email>
                        <app-validation-errors [mformField]="branch_contact_email" label="Email">
                        </app-validation-errors>
                      </div>
                      <div class="form-group required col-md-2">
                        <label for="">Designation</label>
                        <input type="text" class="form-control" required #branch_contact_designation="ngModel"
                          placeholder="Designation" id="branch_contact_designation{{contact_index}}"
                          name="branch_contact_designation{{contact_index}}"
                          [(ngModel)]="branch_contact_obj.designation">
                        <app-validation-errors [mformField]="branch_contact_designation" label="Designation">
                        </app-validation-errors>
                      </div>
                      <div class="form-group col-md-2">
                        <button class="btn btn-primary mr-2 mt-4" type="button" (click)="addBranchContactRow()">
                          <i class="fa fa-plus"></i>
                        </button>
                        <button *ngIf="contact_index!=0" class="btn btn-danger mt-4" type="button"
                          (click)="deleteBranchContactRow(contact_index)">
                          <i class="fa fa-trash"></i>
                        </button>
                      </div>
                    </div>


                  </div>
                  <!-- <div class="form-group col-md-5">
                      <button class="btn btn-primary mr-2 mt-4" type="button" (click)="addBranchRow(i)">Add
                        New Branch</button>
                      <button *ngIf="i!=0" class="btn btn-danger mt-4" type="button" (click)="deleteBranchRow(i)">Delete
                        Branch</button>
                    </div> -->
                </div>
                <!-- <div class="card p-sm-0 col-12"> -->
                <div class="card-body">
                  <ngx-datatable [rows]="company_settings.branch" [loadingIndicator]="loadingIndicator"
                    [externalPaging]="true" [columnMode]="'force'" [count]="company_settings.branch.length"
                    [limit]="limit" [offset]="offset" [footerHeight]="50" [rowHeight]="'auto'" class="material striped">
                    <ngx-datatable-column *ngFor="let col of branch_list_columns" [flexGrow]=" col.flexGrow"
                      [name]="col.name" [sortable]="col.sortable" [cellClass]="col.css_class" [prop]="col.prop">
                      <ng-template ngx-datatable-header-template let-sort="sortFn">
                        <span (click)="sort()">{{ col.name }}</span>
                      </ng-template>
                      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                        <div [ngSwitch]="col.type">
                          <span *ngSwitchDefault>
                            {{ row[col.prop] }}
                          </span>
                          <span *ngSwitchCase="'object'"> {{ row[col.prop][col.field_name] }}</span>
                          <span *ngSwitchCase="'action'">
                            <button *ngIf="row.is_head_office!=true" type="button" class="btn btn-primary"
                              (click)="editBranch(row)">Edit</button>
                          </span>
                        </div>
                      </ng-template>
                    </ngx-datatable-column>
                  </ngx-datatable>
                </div>
                <!-- </div> -->

              </div>

            </div>
          </div>
        </div>
        <!-- TODO Fix Form Validation  -->
        <!-- [disabled]="!saveCompany.valid" -->
        <button type="submit" class="btn btn-primary" [disabled]="loading==true">Save
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
        </button>
        <!-- ==={{saveCompany.valid}}=== -->
      </form>

      <!-- <pre>{{company_settings|json}}</pre> -->

    </div>
  </div>
</div>
