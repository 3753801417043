<div class="row">
  <h4>Chart Of Accounts</h4>
  <button class="btn btn-primary btn-sm ml-2" routerLink="/opening_balance"> Add Opening Balance</button>
</div>
<div class="d-flex justify-content-between align-items-center">

  <ul class="nav nav-tabs page-header-tab">
    <li class="nav-item" *ngFor="let coa_heads of chart_of_accounts">
      <!-- {{coa_heads|json}} -->
      <a class="nav-link" [ngClass]="{'active':coa_heads.id == selectedIndex}" (click)="selectedIndex=coa_heads.id"
        id="coa_heads[name]" data-toggle="tab" href="javascript:void(0);">{{coa_heads.name}}</a>
    </li>
  </ul>

</div>
<div class="tab-content">
  <div class="tab-pane fade show active" *ngFor="let coa_heads of chart_of_accounts"
    [ngClass]="{'active':coa_heads.id== selectedIndex}" id="{{coa_heads.id}}" role="tabpanel">
    <ul class="tree">
      <ng-template [ngTemplateOutlet]="nodeTemplateRef" [ngTemplateOutletContext]="{ $implicit: coa_heads }">
      </ng-template>
    </ul>
  </div>
</div>
<div class="card" [ngClass]="{'pl-40': is_coa_add}">
  <div>
    <!-- <div *ngIf="is_openingbalance_amt==true"><a class="btn btn-primary"
        (click)="openOpeningBalanceModal(2,opening_balance)">Opening Balance</a></div> -->
  </div>
  <div class="card-body row">

    <ng-template #nodeTemplateRef let-node>
      <li class="node" [ngClass]="{'isledger': node.is_ledger}" [class.node--selected]="( node === selectedTreeNode )"
        [attr.aria-expanded]="!node.is_open" (click)="toggleNode(node);$event.stopPropagation()">
        <a *ngIf="node.is_root !=true" (click)="selectNode( node )" class="node__label"
          [ngClass]="{'parent-node':node.is_main_group==true, 'first-child': node.is_main_group==undefined && node.is_ledger==false, 'second-child': node.is_main_group==undefined && node.is_ledger==true}">
          {{ node.name }}
          <!-- btn btn-primary -->
          <!-- btn btn-primary ;openModal(content,node) -->
          <!-- <span *ngIf="node.is_editable==1" class="mr-1"><i class="fa fa-edit"></i></span> -->
          <button
            *ngIf="company_id != -1 && is_coa_add==true && node.is_main_group==true && node.id !=2011 && node.id !=1033|| (company_id == -1&&node.is_ledger==false)"
            class="btn btn-info pull-right" (click)="openDialog(content,node)">
            <i class="fa fa-plus"></i>
          </button>

          <button *ngIf="node.is_editable==1 && node.is_ledger==false && is_coa_add==false"
            class="btn btn-info pull-right" (click)="openDialog(content,node);redirectToParty(node)">
            <i class="fa fa-plus"></i>
          </button>
          <span class="badge bg-light pull-right text-dark" *ngIf="node?.child?.length && node.skip_first_child!=true">
            {{node?.child?.length}}
          </span>
          <!-- <span class="pull-right badge bg-light text-dark"
            *ngIf="getOpeningBalanceSum()">
{{}}
          </span> -->

        </a>
        <button *ngIf="node.is_root ==true && company_id == -1" class="btn btn-info" (click)="openDialog(content,node)">
          <i class="fa fa-plus"></i>
        </button>

        <!-- <button
          *ngIf="company_id != -1 && is_coa_add==true && node.is_main_group==true && node.id !=2011 && node.id !=1033|| (company_id == -1&&node.is_ledger==false)"
          class="btn btn-info " (click)="openDialog(content,node)">
          <i class="fa fa-plus"></i>
        </button>

        <button *ngIf="node.is_editable==1 && node.is_ledger==false && is_coa_add==false" class="btn btn-info "
          (click)="openDialog(content,node);redirectToParty(node)">
          <i class="fa fa-plus"></i>
        </button> -->

        <div
          *ngIf="node.is_root !=true&& is_openingbalance_amt==false &&node.is_ledger==true  &&((node.id>1000 && node.id<2000 ) || (node.id>2000 && node.id<=3000) )"
          class="row">
          <!-- <div class="col-3 custom-switch">
            <input type="checkbox" [(ngModel)]="node.is_credit" [ngModelOptions]="{standalone: false}"
              (change)="calculateDebitCreditBalance(node)" name="is_credit{{node.id}}" class="custom-control-input"
              [checked]="checkSwitchStatus(node)" id="is_credit{{node.id}}">
            <label class="custom-control-label" for="is_credit{{node.id}}">Is credit ?</label>
          </div>
          <input type="number" class="col form-control opening_balance" placeholder="Opening Balance"
            name="opening_amt{{node.id}}" (change)="calculateDebitCreditBalance(node)" [(ngModel)]="node.opening_amt"> -->


        </div>

        <!-- <div *ngIf="node.add_head==true"> -->

        <!-- </div> -->
        <!-- list-group -->
        <ul *ngIf="node?.child?.length" [ngClass]="{'':
                          node.is_root !=true }" [ngbCollapse]="node.is_open !=true" class=" node__children">
          <!-- <p> {{node.child}}</p> -->
          <!-- Invoke the recursive template. -->
          <!-- <ng-template ngFor [ngForOf]="sortCOA(node.child) | sort: 'asc':'id'" [ngForTemplate]="nodeTemplateRef"> -->
          <ng-template ngFor [ngForOf]="sortCOA(node.child)" [ngForTemplate]="nodeTemplateRef">
            <!--
                                          NOTE: The "$implicit" property of the ngFor context is what will
                                          be made available to the template ref's implicit let-node binding.
                                      -->
          </ng-template>

        </ul>
      </li>

    </ng-template>

    <!-- <table class="table table-responsive table-bordered">
      <tr>
        <th>Ledger</th>
        <th>Debit </th>
        <th>Credit </th>
        <th>Balance</th>
      </tr>
      <tr *ngFor="let debit of keys(openingBalanceAmount['debit'])">
        <td>{{openingBalanceAmount['debit'][debit]['name']}}</td>
        <td>0</td>
        <td>{{openingBalanceAmount['debit'][debit]['opening_amt']}}</td>
        <td>&nbsp;</td>
      </tr>
      <tr *ngFor="let credit of keys(openingBalanceAmount['credit'])" >
        <td>{{openingBalanceAmount['credit'][credit]['name']}}</td>
        <td>{{openingBalanceAmount['credit'][credit]['opening_amt']}}</td>
        <td>0</td>
        <td>&nbsp;</td>
      </tr>
    
      <tr>
        <td><b>Total</b></td>
        <td>{{totalDebit}}</td>
        <td>{{totalCredit}}</td>
        <td><b>{{totalCredit-totalDebit}}</b></td>
      </tr>
    </table> -->
  </div>
  <!-- <div>
    <button class="btn btn-primary mt-1 ml-1" type="button" *ngIf="show_create_button"
      (click)="openOpeningBalanceModal(1,opening_balance)">Save
      Opening Balance</button>
  </div> -->
</div>
<ng-template #content let-modal>
  <div class="card">
    <!-- <div class="card-header">

    </div> -->
    <div class="card-body">
      <div class="form-group col-12 custom-switch pl-5 pb-2 pt-2">
        <input type="checkbox" value="true" [(ngModel)]="is_ledger" name="is_ledger{{currentSelectedNode.id}}"
          class="custom-control-input" id="is_ledger{{currentSelectedNode.id}}">
        <label class="custom-control-label" for="is_ledger{{currentSelectedNode.id}}">Add As Ledger ?</label>
      </div>
      <!-- <div class="form-group col-12 custom-switch pl-5 pb-2 pt-2" *ngIf="is_ledger==true">
      <input type="checkbox" value="true" [(ngModel)]="is_gst" name="is_gst{{node.id}}"
        class="custom-control-input" id="is_gst{{node.id}}">
      <label class="custom-control-label" for="is_gst{{node.id}}">Do You Need GST ?</label>
    </div> -->
      <div class="form-group col-12 custom-switch pl-5 pb-2 pt-2" *ngIf="is_ledger==true && is_coa_add==false">
        <input type="checkbox" value="true" [(ngModel)]="is_cost_center" name="is_cost_center{{currentSelectedNode.id}}"
          class="custom-control-input" id="is_cost_center{{currentSelectedNode.id}}">
        <label class="custom-control-label" for="is_cost_center{{currentSelectedNode.id}}">Do You Need Cost Center
          ?</label>
      </div>
      <div class="form-group col-12 custom-switch pl-5 pb-2 pt-2" *ngIf="is_ledger==true">
        <input type="checkbox" value="true" [(ngModel)]="is_fixed_assets"
          name="is_fixed_assets{{currentSelectedNode.id}}" class="custom-control-input"
          id="is_fixed_assets{{currentSelectedNode.id}}">
        <label class="custom-control-label" for="is_fixed_assets{{currentSelectedNode.id}}">Is Fixed Assets ?</label>
      </div>
      <div class="form-group col-12">
        <label *ngIf="is_ledger==false">Group Name</label>
        <label *ngIf="is_ledger==true">Ledger Name</label>
        <input type="text" placeholder="Enter Name" class="form-control mb-2" name="add_head{{currentSelectedNode.id}}"
          [(ngModel)]="currentSelectedNode.child_name" />
      </div>
      <div class="form-group col-12 col-sm-6 col-md-8 col-xl-8" *ngIf="is_ledger==true && is_fixed_assets==true">
        <label for="hsn_code">Fixed Assets</label>
        <ng-select class="fixed_assets_select" [typeahead]="fixedAssetsListTypeahead" name="group_name"
          #group_code="ngModel" [items]="fixedAssetsArray" bindLabel="_id" bindValue="_id" [(ngModel)]="group_name"
          placeholder="Select Fixed Assets">
          <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div [ngOptionHighlight]="search">
              <div> <b>{{ item.group_name }}</b> - {{ item.description }} -<b> {{item.useful_life}} Yrs</b> -
                <b>{{item.income_tax_rate}} %</b>
              </div>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="form-group col-12 col-sm-6 col-md-8 col-xl-8" *ngIf="is_ledger==true && is_cost_center==true">
        <label for="cost_center">Cost Center</label>
        <ng-select id="coa_cost_center" [typeahead]="costCenterListTypeahead" name="coa_cost_center"
          #coa_cost_center="ngModel" [items]="cost_center_list" bindLabel="costcategory_name" bindValue="_id"
          [(ngModel)]="cost_center_id" placeholder="Select Cost Center">
          <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div [ngOptionHighlight]="search">
              {{ item.costcategory_name }}
            </div>
          </ng-template>
        </ng-select>
        <app-validation-errors [mformField]="coa_cost_center" label="Cost Center"></app-validation-errors>
      </div>
    </div>
    <div class="card-footer">
      <button class="btn btn-primary mt-1" type="button" [disabled]="is_add_ledger_btn_disabled"
        (click)="addNewHead(currentSelectedNode,currentSelectedNode.child_name)">Add</button>
      <button class="btn btn-danger mt-1 ml-1" type="button"
        (click)="clearData(currentSelectedNode);modal.dismiss()">Close</button>
    </div>
  </div>


</ng-template>



<!-- <ng-template #opening_balance let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Opening Balance</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <table class="table-bordered table ">
      <tr>
        <th>Ledger</th>
        <th>Debit </th>
        <th>Credit </th>
        <th>Balance</th>
      </tr>
      <tr *ngFor="let debit of keys(openingBalanceAmount['debit'])">
        <td>{{openingBalanceAmount['debit'][debit]['name']}}</td>
        <td>0</td>
        <td>{{openingBalanceAmount['debit'][debit]['opening_amt']}}</td>
        <td>&nbsp;</td>
      </tr>
      <tr *ngFor="let credit of keys(openingBalanceAmount['credit'])">
        <td>{{openingBalanceAmount['credit'][credit]['name']}}</td>
        <td>{{openingBalanceAmount['credit'][credit]['opening_amt']}}</td>
        <td>0</td>
        <td>&nbsp;</td>
      </tr>

      <tr>
        <td><b>Total</b></td>
        <td>{{totalDebit}}</td>
        <td>{{totalCredit}}</td>
        <td><b>{{totalCredit-totalDebit}}</b></td>
      </tr>
    </table>
  </div>
  <div *ngIf="show_create_button==true" class="create_opening_balance">
    <button class="btn btn-primary mt-1 ml-1" type="button" (click)="createOpeningBalanceAmount()">Create Opening
      Balance</button>
  </div>

</ng-template> -->
<ng-template #openingBalance let-node="node" let-calculateDebitCreditBalance="calculateDebitCreditBalance">
  <span class="custom-switch">
    <input type="checkbox" [(ngModel)]="node.is_credit" [ngModelOptions]="{standalone: true}"
      (change)="calculateDebitCreditBalance(node)" name="is_credit{{node.id}}" class="custom-control-input"
      id="is_credit{{node.id}}">
    <label class="custom-control-label" for="is_credit{{node.id}}">Is credit ?</label>
  </span>
  <input type="number" class="form-control opening_balance" placeholder="Opening Balance" name="opening_amt{{node.id}}"
    (change)="calculateDebitCreditBalance(node)" value="true" [ngModelOptions]="{standalone: true}"
    [(ngModel)]="node.opening_amt">
</ng-template>