import { Injectable } from '@angular/core';
import { ApiService } from '../_services/api.service';

@Injectable({
  providedIn: 'root'
})
export class HsnService {

  constructor(private http: ApiService) { }
  addHsn(hsn_data: any) {
    return this.http.post(ApiService.geturl('hsn', 'add'),
      hsn_data);
  }
  getHsn(id) {

  }
  editHsn(hsn_data: any) {
    return this.http.post(ApiService.geturl('hsn', 'edit'),
      hsn_data);
  }

  getDescription(product_hsn) {
    return this.http.post(ApiService.geturl('hsn', 'data'),
      { "hsn_code": product_hsn });
  }
  getHSNByCondition(condition: any = {}, sort: any = {}) {
    return this.http.post(ApiService.geturl('hsn', 'custom'),
      { "condition": condition, "sort": sort });
  }
  getHsnCode(filters: {}, columns: any = {}, sort_by: any = {}, page: any = {}) {
    return this.http.post(ApiService.geturl('hsn', 'hsn_product'),
      {
        condition: filters,
        columns: columns,
        sort_by: sort_by,
        page: page
      });
  }
  getPaginatedHsn(filter: {}, columns: {}, sort: {}, page: {}) {
    return this.http.post(ApiService.geturl('hsn', 'viewall'),
      {
        filter,
        columns,
        sort,
        page
      });
  }
  uploadFiles(files, data) {
    return this.http.uploadFile(ApiService.geturl('hsn', 'upload'), files, data);

  }

}
