import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { AuthenticationService } from "./authentication.service";
@Injectable({ providedIn: "root" })
export class PaymenttransactionsService {
  branch_id: string;
  constructor(
    private http: ApiService,
    private authenticationService: AuthenticationService
  ) {
    this.branch_id = this.authenticationService.getDefaultBranchId();
  }

  addPaymenttransactions(paymenttransactions_data: any) {
    return this.http.post(ApiService.geturl("paymenttransactions", "add"), {
      data: paymenttransactions_data,
    });
  }
  editPaymenttransactions(paymenttransactions_data: any, id: string) {
    return this.http.post(ApiService.geturl("paymenttransactions", "edit"), {
      data: paymenttransactions_data,
      paymenttransactions_id: id,
    });
  }
  deletePaymenttransactions(id: string) {
    return this.http.post(ApiService.geturl("paymenttransactions", "delete"), {
      id: id,
    });
  }

  viewPaymenttransactions(id: string) {
    return this.http.post(ApiService.geturl("paymenttransactions", "view"), {
      paymenttransactions_id: id,
    });
  }

  getPaymenttransactionss(
    filters: any = {},
    columns?: any,
    sort: any = {},
    page: any = {}
  ) {
    return this.http.post(ApiService.geturl("paymenttransactions", "viewall"), {
      filters,
      columns,
      sort,
      page,
    });
  }
  getAccountBooksReport(
    filters: any = {},
    customized_query?: any,
    columns?: any,
    preSummary?: any,
    postSummary?: any,
    model?: any,
    sort: any = {},
    page: any = {}
  ) {
    return this.http.post(
      ApiService.geturl("paymenttransactions", "account_books"),
      {
        filters,
        customized_query,
        columns,
        preSummary,
        postSummary,
        model,
        sort,
        page,
      }
    );
  }
  getSalesorderReport(
    filters: any = {},
    customized_query?: any,
    columns?: any,
    sort: any = {},
    page: any = {}
  ) {
    return this.http.post(
      ApiService.geturl("paymenttransactions", "account_books_sales_order"),
      {
        filters,
        customized_query,
        columns,
        sort,
        page,
      }
    );
  }
  createOpeningBalancesTransactions(openingBalances,company_id){
    return this.http.post(
      ApiService.geturl("paymenttransactions", "doubleentry"),
      {
        debitCredits:openingBalances,
        company_id:company_id
      }
    );
  }
  getOpeningBalance(company_id,date){
    return this.http.post(
      ApiService.geturl("paymenttransactions", "get_opening_balance"),
      {
        company_id:company_id,
        f_year :date
      }
    );
  }
}
