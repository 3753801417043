import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from "../../../_services/authentication.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { DebitBillService } from '../../../_services/debit-bill.service';
import { Company } from "../../../_models/company.model";
import { CompanyService } from "../../../_services/company.service";

@Component({
  selector: 'app-debit-bill-view',
  templateUrl: './debit-bill-view.component.html',
  styleUrls: ['./debit-bill-view.component.scss']
})
export class DebitBillViewComponent implements OnInit {
  company_id: any;
  bill_id: string;
  bill: any = {};
  overall_total_amount: number;
  rem_amount: number;
  filters: any = {};
  branch_id: any;
  company_details: Company = new Company();
  currency: string;
  constructor(
    private creditBillService: DebitBillService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    public companyService: CompanyService
  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    // this.branch_id = this.authenticationService.getDefaultBranchId();
    this.currency = this.authenticationService.getCompanyDetails()[
      "base_currency"
    ];
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.bill_id = params.get("id");
      // console.log("========billID==", this.bill_id);
      if (this.bill_id != "") {
        this.viewBills();

      }
    });
    this.viewCompany()
  }
  viewBills() {
    let total_amount = 0;
    let condition: any = {};
    condition['comapny_id'] = this.company_id;
    this.creditBillService
      .viewDebitBill(this.bill_id, condition)
      .subscribe(result => {
        this.bill = result;
        this.bill.bill_items.forEach(element => {
          total_amount = total_amount + parseInt(element.actual_amount);
          this.overall_total_amount = total_amount;

        });
        this.rem_amount = this.bill.balance_amount;

        this.filters["vendor_id"] = result["vendor"]["_id"];
        this.filters["bill_id"] = this.bill_id;
        this.filters['company_id'] = this.company_id;
        // this.getAdvanceAmount(this.filters);
        // this.getCreditAmount(this.filters);
        // if (this.bill.image) {
        //   this.path = this.apiService.getImageUrl(this.bill.image.path);
        // }

      });
  }
  viewCompany() {
    this.companyService.viewCompany(this.company_id).subscribe(result => {
      this.company_details = result['response']
    })
  }
}
