import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import swal from "sweetalert2";
// import { UsersService } from '../../_services/users.service';
import { UserService } from "../../_services/user.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { AuthenticationService } from "../../_services/authentication.service";
import { CompanyService } from "../../_services/company.service";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { User } from "../../_models/user";
@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
  user: User = new User();
  filters: any = {};
  users: any = {};
  usersList: any = [];
  userNameList: any = [];
  emailList: any = [];
  usersListColumns: any = [];
  page: any = { offset: 0, pageSize: 10 };
  sort: any;
  loadingIndicator: boolean = false;
  companies_list: any;
  companiesListTypeahead = new EventEmitter<string>();
  userListTypeahead = new EventEmitter<string>();
  emailListTypeahead = new EventEmitter<string>();
  selected_company: any = {};
  branch_lists: any = [];
  selected_branch: any = {};
  @ViewChild("actionTmpl", { static: true }) actionTmpl: TemplateRef<any>;
  company_id: any;
  is_branch: boolean = false;
  user_roles: any = [
    { name: "Elixir - Admin", role_id: 1 },
    { name: "Elixir - Manager", role_id: 2 },
    { name: "Elixir - Accountant", role_id: 3 },
    { name: "Elixir - User", role_id: 6 },
    { name: "Elixir - External User", role_id: 7 },
    { name: "Mini ERP", role_id: 8 },
    { name: "Client - Admin", role_id: 9 },
    { name: "Client - Manager", role_id: 10 },
    { name: "Client - Accountant", role_id: 11 },
    { name: "Client - User", role_id: 12 }
  ];
  user_status: any = [
    { name: "Active", status_id: '1' },
    { name: "Deleted", status_id: '0' },
    { name: "Suspended", status_id: '-1' },
  ]
  user_role_id: any;
  constructor(
    private usersservice: UserService,
    private authenticationService: AuthenticationService,
    private companyService: CompanyService,
    private router: Router,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.selected_company = null;
    this.selected_branch = null;
    this.filters.status = "1";
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.user_role_id = this.authenticationService.getUserRole();
    // console.log('user_role', this.user_role)
    this.getCompanies("").subscribe((result) => {
      this.companies_list = result["response"];
      if (this.company_id) {
        this.setBranch();
      }
    });
    this.getCompaniesSearch();
    this.getUserName("").subscribe((result) => {
      this.userNameList = result["response"]["data"];
    });
    this.getUserNameSearch();
    this.getEmail("").subscribe((result) => {
      this.emailList = result["response"]["data"];
    });
    this.getEmailSearch();
    this.usersListColumns = [
      {
        name: "First Name",
        prop: "firstname",
        sortable: "true",
        flexGrow: 2,
        show: true,
        is_detailed_row: false,
      },
      {
        name: "User Name",
        prop: "username",
        sortable: "username",
        flexGrow: 2,
        show: true,
        is_detailed_row: false,
      },
      {
        name: "Role Name",
        prop: "role_name",
        sortable: "role_name",
        flexGrow: 2,
        show: true,
        is_detailed_row: false,
      },
      {
        name: "User Id",
        prop: "user_id",
        sortable: "true",
        flexGrow: 2,
        show: true,
        is_detailed_row: false,
      },
      {
        name: "Email",
        prop: "email",
        sortable: "email",
        flexGrow: 2,
        show: true,
        is_detailed_row: false,
      },
      {
        name: "Status",
        prop: "status_name",
        sortable: "true",
        flexGrow: 2,
        show: true,
        type: 'status',
        is_detailed_row: false,
      },
      {
        name: "Action",
        type: "action",
        prop: "_id",
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false,
      },
    ];
    this.usersListColumns.forEach((column) => {
      this.columns[column.prop] = 1;
    });
    this.getUsersList(this.page);
  }

  getUserName(searchString) {
    this.userNameList = [];
    let condition: any = {};
    if (searchString != "") {
      condition["username"] = searchString;
    }
    condition["company_id"] = this.company_id;
    return this.usersservice.viewall(condition);
  }
  getUserNameSearch() {
    this.userListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getUserName(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.userNameList = result["response"]["data"];
        },
        (err) => {
          console.log(err);
          this.userNameList = [];
        }
      );
  }
  getEmail(searchString) {
    this.emailList = [];
    let condition: any = {};
    if (searchString != "") {
      condition["email"] = searchString;
    }
    condition["company_id"] = this.company_id;
    return this.usersservice.viewall(condition);
  }
  getEmailSearch() {
    this.emailListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getEmail(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.emailList = result["response"]["data"];
        },
        (err) => {
          console.log(err);
          this.emailList = [];
        }
      );
  }

  getCompanies(searchString) {
    this.companies_list = [];
    let condition: any = {};
    if (searchString != "") {
      condition["company"] = searchString;
    }
    if (this.company_id) {
      condition['company_id'] = this.company_id;
    }
    return this.companyService.getCompanies(condition);
  }

  getCompaniesSearch() {
    this.companiesListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getCompanies(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.companies_list = result["response"];
        },
        (err) => {
          console.log(err);
          this.companies_list = [];
        }
      );
  }


  setBranch() {
    this.branch_lists = [];
    this.is_branch = false;
    if (this.company_id != '') {
      this.companies_list.forEach(data => {
        if (data.branch.length > 1) {
          this.is_branch = true;
          this.branch_lists = data.branch;
        }
      });
    } else {
      this.filters.companies.forEach(element => {
        this.companies_list.forEach(companyObj => {
          if (companyObj._id == element) {
            if (companyObj.branch.length > 1) {
              this.is_branch = true;
              this.branch_lists = companyObj.branch;
            } else {
              this.is_branch = false;
            }
          }
        });
      });
    }

    // else if ((this.filters.companies.branch.length = 1)) {
    //   this.is_branch = false;
    //   this.selected_branch = this.selected_company.branch[0];
    //   // this.updateDefaultCompany();
    // }
  }

  onSort(event) {
    this.loadingIndicator = true;
    this.sort = {};
    let sort_details = event.sorts[0];
    this.sort[sort_details.prop] = sort_details.dir == "asc" ? 1 : -1;
    this.getUsersList(this.page);
  }

  getUsersList(page_info: any) {
    if (page_info != null) {
      this.page = page_info;
    }
    if (this.company_id) {
      this.filters.companies = [];
      this.filters.companies.push(this.company_id)
    }
    this.usersservice
      .viewall(this.filters, this.columns, this.sort, this.page)
      .subscribe((result) => {
        this.usersList = result["response"]["data"];
        this.page.count = result["response"]["count"];
      });
  }
  columns(filters: any, columns: any, sort: any, page: any) {
    throw new Error("Method not implemented.");
  }
  clearUsersFilters() {
    this.filters = {};
    this.filters.status = '1';
    this.getUsersList({ offset: 0, pageSize: 10 });
  }

  deleteUser(muid) {
    swal({
      title: "Are you sure want to delete?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result) {
        this.usersservice.deleteUser(muid).subscribe((result) => {
          if (result["status_code"] === 1) {
            swal("Deleted!", "Users has been deleted.", "success");
            this.getUsersList({ offset: 0, pageSize: 10 });
          }
        });
      }
    });
  }

  suspendUser(muid) {
    swal({
      title: "Are you sure want to suspend?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, suspend user!",
    }).then((result) => {
      if (result) {
        this.usersservice.suspendUser(muid).subscribe((result) => {
          if (result["status_code"] === 1) {
            swal("Suspended!", "Users has been suspended.", "success");
            this.getUsersList({ offset: 0, pageSize: 10 });
          } else {
            swal("Error!", result['response'], "error");
          }
        });
      }
    });
  }

  activateUser(muid) {
    this.usersservice.activateUser(muid).subscribe((result) => {
      if (result["status_code"] === 1) {
        swal("Activated!", "Users has been activated.", "success");
        this.getUsersList({ offset: 0, pageSize: 10 });
      } else {
        swal("Error!", result['response'], "error");
      }
    });
  }


}
