export class PurchaseColumnSettings {
  payment_terms: string;
  title: string;
  sub_heading: string;
  footer: string;
  purchase_order_prefix: string;
  purchase_order_number: number;
  purchase_order_suffix: string;
  items_display: boolean = true;
  desc_display: boolean = true;
  units_display: boolean = true;
  price_display: boolean = true;
  amount_display: boolean = true;
  // delivery_note_display:boolean=false;
  // suppliers_ref_display:boolean=false;
  // others_ref_display:boolean=false;
  // despatched_through_display:boolean=false;
  // destination_display:boolean=false;
  // discount_display:boolean=false;
  // motor_vehicle_no_display:boolean=false;
  // eway_bill_no_display:boolean=false;
  // eway_bill_date_display:boolean=false;
  items: object;
  description: string;
  units: string;
  price: string;
  amount: number;
  // delivery_note:string;
  // suppliers_ref:string;
  // others_ref:string;
  // despatched_through:string;
  // destination:string;
  // discount:string;
  // motor_vehicle_no:number;
  // eway_bill_no:number;
  // eway_bill_date:string;
  bill_order_prefix: string;
  bill_order_number: number;
  bill_order_suffix: string;
  ordernumber_length: any;
  bill_ordernumber_length: any;
  voucher: any = [Voucher];
}

export class Voucher {
  voucher_type: string;
  voucher_code: number;
}
