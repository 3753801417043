import {
  Component,
  OnInit,
  OnChanges,
  EventEmitter,
  ChangeDetectorRef,
  Input,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import swal from "sweetalert2";
import { BillService } from "../../_services/bill.service";
import { AuthenticationService } from "../../_services/authentication.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { MastersService } from "../../_services/masters.service";
import { PaymentSettings } from "../../_models/payment-settings.model";
import { PaymentModalComponent } from "../payment-modal/payment-modal.component";
import { MapModalComponent } from "../map-modal/map-modal.component";
import { VendorService } from "../../_services/vendor.service";
import {
  distinctUntilChanged,
  debounceTime,
  switchMap,
  filter,
} from "rxjs/operators";
import { ConstantService } from "../../_config/constants";
import { Subscription } from "rxjs";
import { HttpEvent, HttpResponse } from "@angular/common/http";
@Component({
  selector: "app-bill",
  templateUrl: "./bill.component.html",
  styleUrls: ["./bill.component.scss"],
})
export class BillComponent implements OnInit {
  files: File[] = [];
  httpEmitter: Subscription;
  defaultPagination: any = { offset: 0, pageSize: 10 };
  payArray: any = [];
  filters: any = {};
  billsList: any = [];
  billsListColumns: any = [];
  user_id: any;
  validUploadType = ["image/png", "image/jpeg", "application/pdf"];
  httpEvent: HttpEvent<{}>
  progress: number;
  purchase_status_list: any = [
    { name: "PO", id: 1 },
    { name: "Bill", id: 2 },
    { name: "Un Paid", id: 3 },
    { name: "Partially Paid", id: 4 },
    { name: "Paid", id: 5 },
  ];
  count: number;
  loadingIndicator = true;
  reorderable = true;
  page: any = { offset: 0, pageSize: 20 };
  assignment_toggle: boolean = false;
  // @ViewChild("actionTmpl", { static: true }) actionTmpl: TemplateRef<any>;
  company_id: string;
  dueDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: true,
    dateFormat: "dd.mm.yyyy",
  };
  model: IMyDateModel = null;

  //selected = [];
  selected_items = [];
  selected_items_billid = [];

  rows = [];
  dispCheckBox: boolean = true;
  dt_default_sort = [{ prop: "bill_numbeinvoicer", dir: "desc" }];
  sort: any = { created_at: -1 };
  columns: any = {};

  // @ViewChild("billTable", { static: false }) table: any;

  selectedOrder: any;
  payType: string = "";
  selecteditem_array: any;
  item_selected: any;
  headList: any;
  transaction_head: string;
  tx_date: IMyDateModel;

  public payment = new PaymentSettings();
  invoice_array: any = [];
  pay_head: string;
  currency: string;
  module_from: string = "purchase";
  passed: boolean;
  passed_message: string;
  id: string;
  billListType: string;
  advance_id: string;
  adv_total_amount: any;
  adv_advance_based_amount: any;
  adv_total_bill_amount: any;
  selected_items_billnumber: any[];
  selected_items_bill_amount: any[];
  branch_id: any;
  is_bill_payment = false;
  vendor_lists: any = [];
  vendorListTypeahead = new EventEmitter<string>();
  isDisableBillPaymentBtn = true;

  bill_status: any = [
    {
      bill_status_id: 1,
      bill_status_name: "Unpaid",
    },
    {
      bill_status_id: 2,
      bill_status_name: "Partially Paid",
    },
    {
      bill_status_id: 3,
      bill_status_name: "Paid",
    },
    {
      bill_status_id: 4,
      bill_status_name: "Cancelled",
    },
    {
      bill_status_id: 5,
      bill_status_name: "Draft",
    }
  ];
  billNumberListTypeahead = new EventEmitter<string>();
  billNumberList: any[];
  billDateOptions: IAngularMyDpOptions = {
    dateRange: true,
    dateFormat: ConstantService.DateFormat,
  };
  modalReference: any;
  image_path: string;
  lastInvalids: any;
  validComboDrag: any;
  companyDetails: any;
  selectFiYear: any;
  constructor(
    // private purchaseorderservice: PurchaseorderService,
    private billService: BillService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private masterService: MastersService,
    public route: ActivatedRoute,
    private viewContainerRef: ViewContainerRef,
    private vendorService: VendorService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) { }

  ngOnInit() {

    this.filters.bill_status = [1, 2, 5];
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.user_id = this.authenticationService.getUserRole();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.companyDetails = this.authenticationService.getCompanyDetails();
    this.currency = this.companyDetails["base_currency"];

    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];
    this.filters.bill_date = {
      isRange: true,
      singleDate: null,
      dateRange: {
        beginDate: { year: this.selectFiYear.start_year, month: 4, day: 1 },
        beginJsDate: new Date(this.selectFiYear.start_year, 3, 1),
        endDate: { year: this.selectFiYear.end_year, month: 3, day: 31 },
        endJsDate: new Date(this.selectFiYear.end_year, 2, 31),
      }
    };

    this.route.paramMap.subscribe((params: ParamMap) => {
      let isInvoiceReceiveRoute = this.router.url.match("/payment");
      if (isInvoiceReceiveRoute != null) {
        this.is_bill_payment = true;
        this.bill_status = this.bill_status.filter(
          (bill_status) => bill_status.bill_status_id !== 3
        );
        this.filters.bill_status = [1, 2];
      }
      this.id = params.get("id");
      this.billListType = params.get("type");
      this.advance_id = params.get("advance_id");
      this.getVendors("").subscribe((result) => {
        this.vendor_lists = result["response"]["data"];
      });
      this.getVendorsSearch();
    });

    this.getBillNumberList("").subscribe((result) => {
      this.billNumberList = result["response"]["data"];
    });
    this.getBillNumberListSearch();

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get("id");
      this.billListType = params.get("type");
      this.advance_id = params.get("advance_id");
    });
    if (this.billListType == "purchaseadvance") {
      this.filters["vendor_id"] = this.id;
      this.getAdvanceAmount();
    }
    // this.filters.purchase_status = this.purchase_status_list[0];

    this.billsListColumns = [
      {
        prop: "bill_number",
        name: "#Purchase Invoice",
        sortable: "bill_number",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
      },
      {
        prop: "vendor",
        name: "Vendor",
        sortable: "vendor_name",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "object",
        field_name: "vendor_name",
      },

      {
        prop: "billing_date",
        name: "Date",
        sortable: "billing_date",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "date",
      },
      {
        prop: "total_amount",
        name: "Total Amount",
        sortable: "total_amount",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "currency",
        css_class: "align-right",
      },
      {
        prop: "balance_amount",
        name: "Balance",
        sortable: "balance_amount",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "currency",
        css_class: "align-right",
      },

      {
        prop: "bill_status_name",
        name: "Status",
        sortable: "bill_status_name",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "status",
      },
      // {
      //   prop: "bill_send_status_name",
      //   name: "Draft Status",
      //   sortable: "bill_status_name",
      //   flexGrow: 1,
      //   show: false,
      //   is_detailed_row: false,
      //   type: "status",
      // },
    ];
    if (this.id == "" || this.id == null) {
      this.billsListColumns.push({
        name: "Action",
        type: "action",
        prop: "_id",
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false,
      });
    }
    this.billsListColumns.forEach((column) => {
      this.columns[column.prop] = 1;
    });
    this.columns["ledger"] = 1;

    if (
      this.route.snapshot.queryParams["q"] != "undefined" &&
      this.is_bill_payment == false
    ) {
      this.filters["vendor"] = this.route.snapshot.queryParams["q"];
      this.getBillsList(this.page);
    }
    // this.getCoaHead();
    this.tx_date = { isRange: false, singleDate: { jsDate: new Date() } };
    this.getImageFromFileupload();
  }

  onSort(event) {
    this.loadingIndicator = true;
    this.sort = {};
    let sort_details = event.sorts[0];
    this.sort[sort_details.prop] = sort_details.dir == "asc" ? 1 : -1;
    this.getBillsList(this.page);
  }

  getBillNumberList(searchString: string) {
    let condition: any = {};
    condition["company_id"] = this.company_id;
    condition["bill_number_search_string"] = searchString;
    return this.billService.getBills(
      condition,
      { bill_number: 1 },
      {},
      { offset: 0, limit: 10 }
    );
  }

  getBillNumberListSearch() {
    this.billNumberListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getBillNumberList(searchString))
      )
      .subscribe(
        (result) => {
          this.billNumberList = [];
          this.cd.markForCheck();
          this.billNumberList = result["response"]["data"];
        },
        (err) => {
          console.log(err);
          this.billNumberList = [];
        }
      );
  }

  getVendors(searchString) {
    this.vendor_lists = [];
    let condition: any = {};
    let columns: any = {};
    if (searchString != "") {
      condition["vendor_name"] = searchString;
    }
    condition["company_id"] = this.company_id;
    columns["vendor_name"] = 1;
    columns["first_name"] = 1;
    columns["last_name"] = 1;
    columns["currency"] = 1;
    columns["address_line1"] = 1;
    columns["address_line2"] = 1;
    columns["valid_upto"] = 1;
    columns["country"] = 1;
    columns["state"] = 1;
    columns["phone"] = 1;
    columns["coa_data"] = 1;
    columns["credit_note_data"] = 1;
    columns["is_sez"] = 1;
    return this.vendorService.getVendors(condition, columns, {}, { limit: 10 });
  }
  getVendorsSearch() {
    this.vendorListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getVendors(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.vendor_lists = result["response"]["data"];
        },
        (err) => {
          console.log(err);
          this.vendor_lists = [];
        }
      );
  }

  getBillsList(page_info: any) {
    if (page_info != null) {
      this.page = page_info;
    }

    this.filters["company_id"] = this.company_id;
    if (this.branch_id) {
      this.filters["branch_id"] = this.branch_id;
    }
    this.billService
      .getBills(this.filters, this.columns, this.sort, this.page)
      .subscribe((result) => {
        this.loadingIndicator = false;
        // this.billsListColumns = [
        //   { name: "Action", cellTemplate: this.actionTmpl, prop: "muid" }
        // ];
        this.billsList = result["response"]["data"];

        this.page.count = result["response"]["count"];
      });
  }
  getVendorName() {
    console.log("-=--name--", this.billsList);
  }

  clearBillFilters() {
    this.filters = {};
    this.getBillsList({ offset: 0, pageSize: 2 });
  }
  deleteBill(muid) {
    swal({
      title: "Are you sure want to cancel?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    }).then((result) => {
      if (result) {
        this.billService.deleteBill(muid).subscribe((result) => {
          if (result["ok"] === 1) {
            swal("Deleted!", "Bill has been deleted.", "success");
            this.getBillsList({});
          }
        });
      }
    });
  }
  // payAmountModal() {
  //   this.invoice_array = [];
  //   this.invoice_array.amount = 0;
  //   this.balance_amt = 0;
  //   this.invoice_array.debit = 0;
  //   if (this.selected_items.length == 1 || this.selected_items.length > 1) {
  //     this.payment.company_id = this.selecteditem_array[0].company_id;
  //     this.payment.vendor_name = this.selecteditem_array[0].vendor.vendor_name;
  //     this.payment.vendor_id = this.selecteditem_array[0].vendor;
  //     if (this.payType == "single") {
  //       // this.modalService.open(payAmount, { size: "lg" });
  //     } else if (this.payType == "multiple") {
  //       this.passed = this.selected_items.every(this.isEqualVendor);
  //       if (!this.passed) {
  //         this.passed_message = "Select same vendor";
  //       }
  //     }
  //     this.selecteditem_array.forEach(element => {
  //       this.payment.currency = element.currency;
  //       this.balance_amt = this.balance_amt + parseInt(element.balance_amount);
  //       this.payment.total_payment_amount = this.balance_amt;
  //       this.invoice_array.push({
  //         bill_number: element.bill_number,
  //         bill_id: element._id,
  //         billing_date: element.billing_date,
  //         debit: element.balance_amount,
  //         amount: element.balance_amount,
  //         credit: 0
  //       });
  //     });
  //     this.payArray = {
  //       selected_items: this.selecteditem_array,
  //       invoice_array: this.invoice_array,
  //       payment: this.payment,
  //       pay_type: this.payType,
  //       passed_status: this.passed,
  //       passes_message: this.passed_message
  //     };
  //     return this.payArray;
  //   } else {
  //     return (this.payArray = {
  //       passed_status: false,
  //       passes_message: "Select an order to pay"
  //     });
  //   }
  // }

  isEqualVendor(element, index, array) {
    console.log(array);
    return element == array[0];
  }

  onSelectOrders(selected) {
    this.selected_items = [];
    this.selected_items_billid = [];
    this.selected_items_billnumber = [];
    this.selected_items_bill_amount = [];

    selected["selected"].forEach((element) => {
      this.selected_items.push(element.vendor._id);
      this.selected_items_billid.push(element._id);
      this.selected_items_billnumber.push(element.bill_number);
      this.selected_items_bill_amount.push(element.balance_amount);
    });
    this.disableEnableBillPayBtn();
  }

  openPaymentModal(row_data, pay_method) {
    let selected: any = [];

    if (row_data) {
      selected.push(row_data);
      this.onSelectOrders({ selected });
    }

    this.selecteditem_array = [];

    this.item_selected = this.selected_items[0];

    if (pay_method == "bulk") {
      if (this.selected_items.length == 1) {
        this.payType = "single";
        this.passed = true;
      } else {
        this.payType = "multiple";
        this.passed = this.selected_items.every(this.isEqualVendor);
      }
    } else if (pay_method == "single") {
      this.payType = "single";
      this.passed = true;
    }
    if (this.passed) {
      const modalRef = this.modalService.open(PaymentModalComponent, {
        size: "lg",
      });

      let dataArray = {
        bill_ids: this.selected_items_billid,
        payment_from: "Purchase",
        payment_type: this.payType,
        hide_advance_div: true
      };

      modalRef.componentInstance.selectedarray_data = dataArray;
      modalRef.result
        .then((result) => {
          console.log(result);
          this.getBillsList({ offset: 0, pageSize: 20 });
        })
        .catch((error) => {
          console.log(error);
          // To Do Comes also When Success response.
          this.getBillsList({ offset: 0, pageSize: 20 });
        });
    } else {
      swal("Error!", "Select same vendor / choose an order", "error");
    }
  }
  getAdvanceAmount() {
    let filters: any = {};
    if (this.advance_id) {
      filters["advance_id"] = this.advance_id;
    }

    this.company_id = this.authenticationService.getDefaultCompanyId();
    filters["vendor_id"] = this.id;
    filters["company_id"] = this.company_id;

    this.billService.getAdvanceAmount(filters).subscribe((result) => {
      this.adv_total_amount = result["total_adv_data"].reduce(function (a, b) {
        return a + b["advance_amount"];
      }, 0);
      this.adv_advance_based_amount = result["adv_data"][0]["advance_amount"];
      this.adv_total_bill_amount = result["bill_data"].reduce(function (a, b) {
        return a + b["balance_amount"];
      }, 0);
    });
  }

  mapBills() {
    const modalRef = this.modalService.open(MapModalComponent);

    let dataArray = {
      bill_ids: this.selected_items_billid,
      bill_amount: this.selected_items_bill_amount,
      bill_number: this.selected_items_billnumber,
      payment_from: "vendor",
      vendor_id: this.id,
      advanceid: this.advance_id,
      adv_total_amount: this.adv_total_amount,
      adv_advance_based_amount: this.adv_advance_based_amount,
      adv_total_bill_amount: this.adv_total_bill_amount,
    };

    modalRef.componentInstance.selectedarray_mapdata = dataArray;
    modalRef.result
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  disableEnableBillPayBtn() {
    if (this.is_bill_payment == true) {
      this.isDisableBillPaymentBtn =
        this.filters.vendor_muid == null ? true : false;
    } else {
      this.isDisableBillPaymentBtn =
        this.selected_items_billid.length == 0 ? true : false;
    }
  }

  validateAndGetBillList() {
    if (this.is_bill_payment == true) {
      if (!this.filters.vendor_muid) {
        this.filters.bill_status =
          this.filters.bill_status.length == 0
            ? [1, 2]
            : this.filters.bill_status;
        swal(
          "Warning",
          "You must select the Vendor to view the receivables",
          "warning"
        );
      } else if (this.filters.bill_status.length == 0) {
        this.filters.bill_status = [1, 2];
        swal(
          "Warning",
          "You must select at least any one of the status (Unpaid/Partially Paid)",
          "warning"
        );
        this.getBillsList(this.defaultPagination);
      } else {
        this.getBillsList(this.defaultPagination);
      }
    } else {
      this.getBillsList(this.defaultPagination);
    }
  }
  sendBill(row_data) {
    if (row_data['ledger'] != undefined && row_data['ledger']['name'] != undefined) {

      swal({
        title: "Approve the bill ?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Approve it!",
      }).then((result) => {
        if (result) {
          this.billService.sendBill(row_data).subscribe((result) => {
            if (result["ok"] === 1) {
              swal("Success!", "Bill has been approved.", "success");
              this.getBillsList({});
            }
          });
        }
      });
    } else {
      swal("Error!", "Select the ledger for approval.", "error");
    }
  }
  open(content) {
    this.modalReference = this.modalService.open(content, { size: "lg" });
  }
  open1(content, id) {
    this.id = id;
    this.modalReference = this.modalService.open(content, { size: "lg" });
  }

  onFileChange(fileChangeEvent) {
    Object.keys(fileChangeEvent.target.files).forEach(key => {

      const file: File = fileChangeEvent.target.files[key];
      if (this.validUploadType.includes(file.type)) {
        this.files.push(file);
      } else {
        swal('Invalid File Type', 'Invalid File Type' + file.type, 'error');
      }
    });

  }
  uploadFile() {
    if (this.files.length > 0) {
      let data: any = {
        company_id: this.authenticationService.getDefaultCompanyId(),
        branch_id: this.authenticationService.getDefaultBranchId(),
      };

      // this.httpEmitter =
      this.billService.uploadFiles(this.files, data).subscribe((event: any) => {
        // this.httpEvent = event;
        // if (event instanceof HttpResponse) {
        console.log('result==>', event);
        if (event['result']["_id"] != "") {
          this.modalReference.close("content");
          swal("Success", event['created_rows'] + 'file(s) Uploaded Successfully', "success");
          this.router.navigate(["/bill/list"]);
          this.files = [];
        }
        // }
      },
        error => swal('Error Uploading Files: ', error.message));
    } else {
      swal("Error", "No File Found To Upload", "error");
    }

  }
  getImageFromFileupload() {
    this.image_path = ConstantService.IMG_BASE_URL + "/uploads/invoice1.pdf";
  }
  getDate() {
    return new Date();
  }
}
