import { Injectable } from "@angular/core";
import { environment } from "@src/environments/environment";
@Injectable()
export class ConstantService {
  public static PROJECT_NAME = environment.PROJECT_NAME;
  public static PROTOCOL = environment.PROTOCOL;
  public static DOMAIN = environment.DOMAIN;
  public static whiteListedURLS = [ConstantService.DOMAIN];
  public static TOKEN_NAME = "aerp";
  public static BASE_URL = environment.BASE_URL;
  public static IMG_BASE_URL = environment.IMG_BASE_URL;
  public static sentry_env = environment.sentry_env;
  public static API_URL = {
    user: {
      login: "auth/login",
      forgot_password: "user/forgot_password",
      change_password: "user/change_password",
      reset_password: "user/reset_password",
      suspenduser: "user/suspend_user",
      activate_user: "user/activate_user",
      create: "user/create",
      view: "user/:id/view",
      edit: "user/:id/edit",
      viewall: "user/viewall",
      delete: "user/:id/delete",
      refresh_token: "auth/refresh_token",
      edit_details: "user/edit_details",
    },
    users: {
      add: "users/add",
      viewall: "users/list",
    },
    fixed_assests: {
      viewall: "fixed_assests/viewall",
      add: "fixed_assests/add",
      edit: "fixed_assests/edit",
      view: "fixed_assests/view",
    },
    client: {
      add: "client/add",
      viewall: "client/list",
    },
    company: {
      add: "company/add",
      view: "company/:id/view",
      data: "company/data",
      edit: "company/edit",
      logoUpload: "company/:company_id/logoUpload",
      getPdfTemplates: "company/getPdfTemplates",
      finalizeFinancialYear: "paymenttransactions/finalizeFinancialYear"
    },
    product: {
      add: "product/add",
      edit: "product/edit",
      view: "product/view",
      viewall: "product/viewall",
      data: "product/data",
      hsn_list: "product/hsnlist",
      group_add: "product/group_add",
      viewall_group: "product/viewall_group",
      upload: "product/:company_id/upload"
    },
    vendor: {
      add: "vendor/add",
      view: "vendor/view",
      viewall: "vendor/viewall",
      add_address: "vendor/add_address",
      data: "vendor/data",
      edit: "vendor/update",
      upload: "vendor/:company_id/upload"
    },
    sequence: {
      add: "sequence/add",
      view: "sequence/view",
      viewall: "sequence/viewall",
      data: "sequence/data",
      edit: "sequence/update",
    },
    customer: {
      add: "customer/add",
      view: "customer/view",
      viewall: "customer/viewall",
      data: "customer/data",
      data_report: "customer/data_report",
      add_address: "customer/add_address",
      edit: "customer/update",
      upload: "customer/:company_id/upload"
    },
    transaction: {
      quantity: "transaction/quantity",
      viewall: "transaction/viewall",
    },
    salesorder: {
      add: "salesorder/add",
      view: "salesorder/view",
      viewall: "salesorder/viewall",
      data: "salesorder/data",
      edit: "salesorder/edit",
    },
    stock: {
      view: "stock/view",
    },
    voucher_type: {
      add: "voucher-type/add",
      viewall: "voucher-type/viewall",
      edit: "voucher-type/update",
      view: "voucher-type/view",
      delete: "voucher-type/delete",
    },
    invoice: {
      add: "invoice/add",
      view: "invoice/:id/view",
      viewall: "invoice/viewall",
      data: "invoice/data",
      edit: "invoice/:id/edit",
      e_way: "invoice/:id/e_way",
      getAllInvoices: "invoice/getAllInvoices",
      getAdvanceAmount: "invoice/get_advance_amount",
      upload: "invoice/upload",
      updatetAdvanceAmountInInvoice: "invoice/updatetAdvanceAmountInInvoice",
      add_pay: "invoice/add_pay",
      book_pay: "invoice/book_pay",
      delete: "invoice/delete",
      sendInvoice: "invoice/sendInvoice",
      delete_draft: "invoice/:id/delete_draft",
      account_invoicebasedreport_books:
        "invoice/account_invoicebasedreport_books",
      account_ageing_books: "invoice/account_ageing_books",
      account_hsn_books: "invoice/account_hsn_books",
      update_invoice_filestatus: "invoice/update_invoice_filestatus",
      export: "invoice/export",
      updateExistsStock: "invoice/updateExistsStock",
    },
    payment: {
      add: "payment/add",
      addExpensePayment: "payment/addExpensePayment",
      addReceiptPayment: "payment/addReceiptPayment",
      add_receipt_payment: "payment/add_receipt_payment",
    },
    debitnote: {
      add: "debitnote/add",
      viewall: "debitnote/viewall",
      getUsersDebitAmount: "debitnote/getUsersDebitAmount",
      get_all: "debitnote/get_all",
      update_debitnote: "debitnote/update_debitnote",
      view: "debitnote/view",
      confirm: "debitnote/confirm",
      delete: "debitnote/delete",
      update: "debitnote/:id/update",
    },
    expense: {
      add: "expense/add",
      view: "expense/:id/view",
      bill_view: "expense/:id/bill_view",
      viewall: "expense/viewall",
      data: "expense/data",
      edit: "expense/:id/update",
      getAllExpense: "expense/getselectedexpense",
      updateRcm: "expense/updateRcm",
      delete: "expense/:id/delete",
      confirm_expense: "expense/confirm_expense",
      editExpense: "expense/edit",

      getExpenseInvoice: "expense/get_expense_invoice",
    },
    tds: {
      view: "tds/view",
      viewall: "tds/viewall",
    },
    bill: {
      add: "bill/add",
      view: "bill/:id/view",
      viewall: "bill/viewall",
      data: "bill/data",
      edit: "bill/:id/edit",
      getAllBills: "bill/getselectedbills",
      getAdvanceAmount: "bill/get_advance_amount",
      map_advancepayment: "bill/map_advancepayment",
      updatetAdvanceAmountInBill: "bill/updatetAdvanceAmountInBill",
      book_pay: "bill/book_pay",
      advance_payandmap: "bill/advance_payandmap",
      upload: "bill/:company_id/upload",
      doubleentryTransaction: "bill/doubleentryTransaction",
      reportallBills: "bill/reportallBills",
      delete: "bill/delete",
      sendBill: "bill/sendBill",
      delete_draft: "bill/:id/delete_draft",
      account_billpayable_books: "bill/account_billpayable_books",
      get_status_list: "bill/get_status_list",
    },
    creditnote: {
      add: "creditnote/add",
      viewall: "creditnote/viewall",
      getDebitAmount: "creditnote/getDebitAmount",
      getUsersDebitAmount:
        "creditnote/:company_id/:vendor_id/:bill_id/getUsersDebitAmount",
      updatetAmountInCreditBill: "creditnote/updatetAmountInCreditBill",
      get_all: "creditnote/get_all",
      update_creditnote: "creditnote/update_creditnote",
      view: "creditnote/view",
      confirm: "creditnote/confirm",
      delete: "creditnote/delete",
      update_creditnote_filestatus: "creditnote/update_creditnote_filestatus",
    },

    purchaseorder: {
      add: "purchaseorder/add",
      view: "purchaseorder/view",
      viewall: "purchaseorder/viewall",
      data: "purchaseorder/data",
      edit: "purchaseorder/edit",
    },
    masters: {
      get_currency: "currency/data",
      get_countries: "country/data",
      get_units: "unit/data",
      get_coa: "coa/data",
      add_coa: "coa/add",
      update_coa: "coa/edit",
      get_coa_head: "coa/get_coa_head",
      add_units: "unit/add_units",
      delete_units: "unit/delete_units",
      add_opening_balance: "openingbalance/add_opening_balance",
      get_opening_balance: "openingbalance/get_opening_balance",
    },
    hsn: {
      add: "hsn/add",
      edit: "hsn/edit",
      data: "hsn/data",
      hsn_code: "hsn/hsn_code",
      viewall: "hsn/viewall",
      custom: "hsn/custom",
      upload: "hsn/upload",
      hsn_product: "hsn/hsn_product",
    },

    mcountries: {
      add: "mcountries/add",
      edit: "mcountries/edit",
      view: "mcountries/view",
      viewall: "mcountries/viewall",
      delete: "mcountries/delete",
      nationality_list: "mcountries/nationality_list",
    },
    testcollection: {
      add: "testcollection/add",
      view: "testcollection/view",
      viewall: "testcollection/viewall",
      data: "testcollection/data",
      edit: "testcollection/update",
      upload: "testcollection/upload",
    },
    journal: {
      add: "journal/add",
      addstockjournal: "journal/addstockjournal",
      view: "journal/view",
      viewall: "journal/viewall",
      data: "journal/data",
      edit: "journal/update",
      upload: "journal/upload",
      delete: "journal/delete",
      opening_balance: "journal/opening_balance",
    },
    costgroup: {
      add: "costgroup/add",
      view: "costgroup/view",
      viewall: "costgroup/viewall",
      data: "costgroup/data",
      edit: "costgroup/update",
      upload: "costgroup/upload",
    },
    paymenttransactions: {
      add: "paymenttransactions/add",
      view: "paymenttransactions/view",
      viewall: "paymenttransactions/viewall",
      account_books: "paymenttransactions/account_books",
      account_books_sales_order:
        "paymenttransactions/account_books_sales_order",
      account_books_purchase_order:
        "paymenttransactions/account_books_purchase_order",
      data: "paymenttransactions/data",
      edit: "paymenttransactions/update",
      upload: "paymenttransactions/upload",
      stockitem_account_books: "paymenttransactions/stockitem_account_books",
      account_summary_books: "paymenttransactions/account_summary_books",
      doubleentry: "paymenttransactions/doubleentry",
      get_opening_balance: "paymenttransactions/get_opening_balance",
    },
    // stocktransactions: {
    //   account_books: "stock/account_books",
    // },
    transactions: {
      viewall: "transaction/viewall",
      account_books: "transaction/account_books",
    },
    country: {
      data: "country/data",
      state_data: "country/state_data",
    },
    contra: {
      add: "contra/add",
      view: "contra/view",
      viewall: "contra/viewall",
      data: "contra/data",
      edit: "contra/update",
      upload: "contra/upload",
      delete: "contra/delete",
    },
    gst: {
      generate_gstr1: "gst/generateR1",
      generater2: "gst/generateR2",
      viewall: "gst/viewall",
      file_gst: "gst/file_gst",
      update: "gst/update",
      regenerate_gstr1: "gst/regenerate_gstr1",
    },
    receipts: {
      add: "receipts/add",
      view: "receipts/view",
      viewall: "receipts/viewall",
      // vendor_viewall: "receipts/viewall",
      data: "receipts/data",
      edit: "receipts/edit",
      receiptsadd: "receipts/receiptsadd",
      confirm_receipt: "receipts/confirm_receipt",
      delete_receipt: "receipts/delete_receipt",
    },
    advance: {
      sales_advance_add: "advance/sales_advance_add",
      party_viewall: "advance/party_viewall",
      purchase_advance_add: "advance/purchase_advance_add",
      update_advance: "advance/update_advance",
      cancel_advance: "advance/cancel_advance",
      confirm_advance: "advance/confirm_advance",
      view: "advance/view",
    },
    receipttransaction: {
      add: "receipttransaction/add",
      viewall: "receipttransaction/viewall",
      getAllReceipt: "receipttransaction/getselectedexpense",
    },
    support_ticket: {
      create: "support_ticket/create",
      viewall: "support_ticket/viewall",
      sync_tickets: "support_ticket/sync_tickets",
      view: "support_ticket/view",
      update: "support_ticket/update",
    },
    reports: {
      get_customization_details: "reports/get_customization_details",
      update_customization_details: "reports/update_customization_details",
      get_current_vs_previous_month_sales_report:
        "reports/get_current_vs_previous_month_sales_report",
      get_cash_bank_balance: "reports/get_cash_bank_balance",
      get_outstanding_receivable_and_payables:
        "reports/get_outstanding_receivable_and_payables",
      get_income_vs_expense: "reports/get_income_vs_expense",
      income: "reports/income",
      get_dashboard_widget: "reports/get_dashboard_widget",
      get_invoice_progress_by_status: "reports/get_invoice_progress_by_status",
      getdata: "reports/getdata",
      reportdata: "reports/reportdata",
      generate_pdf_from_html: "reports/generate_pdf_from_html",
      openClosingStockData: "reports/openClosingStockData",
    },
    reportCustomization: {
      view: "report-customization/view",
    },
    fileupload: {
      viewall: "fileupload/viewall",
      view: "fileupload/view",
      delete: "fileupload/:id/delete",
    },
    // *********************** Auto generated Routes added Before this ************************ //
  };
  public static coa_heads = {
    Assets: "Assets",
    Liabilities: "Liabilities",
    Equity: "Equity",
    Revenue: "Revenue",
    Expenses: "Expenses",
    Bank: "Bank",
    Cash: "Cash",
    Taxes_Payable: "Taxes_Payable",
    Taxes_Receivable: "Taxes_Receivable",
    Accounts_Receivable: "Accounts_Receivable",
    Accounts_Payable: "Accounts_Payable",
    Transport_Charges: "Transport_Charges",
    Other_Income: "Other_Income",
    Freight: "Freight",
    Discount: "Discount",
    INCOME_FROM_SERVICES: "INCOME_FROM_SERVICES",
    INCOME_FROM_SALE_OF_GOODS: "INCOME_FROM_SALE_OF_GOODS",
    Materials_Purchases: "Materials_Purchases",
    Purchase_Accounts: "Purchase_Accounts",
    PURCHASE_DIRECT_EXPENSES: "PURCHASE_DIRECT_EXPENSES",
    Opening_Inventories: "Opening_Inventories",
    Direct_Expenses: "Direct_Expenses",
    INDIRECT_EXPENSES: "INDIRECT_EXPENSES",
    Loans_Liabilities: "Loans_Liabilities",
    Secured_Loan: "Secured_Loan",
    Unsecured_Loan: "Unsecured_Loan",
    Direct_Incomes: "Direct_Incomes",
    Personel_cost: "Personel_Cost",
    Rent: "Rent",
    Repairs_Maintenance: "Repairs_Maintenance",
    Insurance: "Insurance",
    Research_Development_Expenses: "Research_Development_Expenses",
    Selling_Expenses: "Selling_Expense",
    Finance_Charges_Banking_Charges: "Finance_Charges_Banking_Charges",
    Income_Round_Of: "Income_Round_Of",
    Expense_Round_Of: "Expense_Round_Of",
    Premilinary_Expenses: "Premilinary_Expenses",
    Closing_Inventories: "Closing_Inventories",
    Fixed_Assets_Tangible:"Fixed_Assets_Tangible",
    Fixed_Assets_Intangible:"Fixed_Assets_Intangible"
  };
  public static COA = {
    ASSETS: 1000,
    LIABILITIES: 2000,
    CURRENT_LIABILITIES: 2007,
  };
  // public static DateFormat = "dd/MM/yy, hh: mm";
  public static DateFormat = "dd/mm/yyyy";
  public static AngularDateFormat = "MMM dd, y";
  public static DateFormats = {
    parseInput: "l LT",
    fullPickerInput: "l LT",
    datePickerInput: "l",
    timePickerInput: "LT",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  };
  public static FY_START_DATE = 1;
  public static FY_START_MONTH = 3;
  public static COMPANY_CATEGORY_IN_SERVICES = [1, 2, 3];
  public static COMPANY_CATEGORY_IN_GOODS = [1, 3, 4, 5];
  constructor() { }
}
