<div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <form novalidate #searchHsnForm="ngForm" (ngSubmit)="getUnitsList({ offset: 0, pageSize: 10 })">
          <div class="card">
            <div class="card-body">
              <div class=" mr-3 mb-2 float-right">
                <button class="btn btn-primary float-right mb-4" routerLink="/masters/units/add">
                  <i class="icon icon-add"></i>Add Units
                </button>
              
              </div>
              <h3 class="table-title"><i class="fa fa-gear"></i> Units </h3>
              
              <div class="row">
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="name">Name</label>
                <ng-select (clear)="filters.name=[];" [items]="name_lists" [typeahead]="nameListTypeahead" [multiple] = true
                  #name="ngModel" name="name" bindLabel="name" bindValue="name"
                  [(ngModel)]="filters.name" placeholder="Select Name">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.name }} 
                    </div>
                  </ng-template>
  
                </ng-select>
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="code">Code</label>
                <ng-select (clear)="filters.code=[];" [items]="code_lists" [typeahead]="codeListTypeahead" [multiple] = true
                  #code="ngModel" name="code" bindLabel="code" bindValue="code"
                  [(ngModel)]="filters.code" placeholder="Select Code">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.code }} 
                    </div>
                  </ng-template>
  
                </ng-select>
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="type">Type</label>
                <ng-select (clear)="filters.type=[];" [items]="type_lists" [typeahead]="typeListTypeahead" [multiple] = true
                  #type="ngModel" name="type" bindLabel="type" bindValue="type"
                  [(ngModel)]="filters.type" placeholder="Select Type">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.type }} 
                    </div>
                  </ng-template>
  
                </ng-select>
              </div>
            </div>
            <div>
              <button type="submit" class="btn btn-primary mr-2">
                <i class="fa fa-search"></i> Search
              </button>
              <button type="button" (click)="clearUnitsFilters()" class="btn btn-danger">
                <i class="fa fa-close"></i>
                Clear
              </button>
            </div>
            </div>
          </div>
        </form>
  
  
        <!-- <div class=" card p-sm-0">
          <div class="card-body">
  
          </div>
  
         
        </div> -->
        <div class="card p-sm-0">
          <div class="card-body">
  
            <ngx-datatable [rows]="unitsList" [loadingIndicator]="loadingIndicator" [externalPaging]="true"
              [columnMode]="'force'" [count]="page.count" [limit]="page.pageSize" [offset]="page.offset"
              (page)="getUnitsList($event)" [externalSorting]="true" (sort)="onSort($event)" [footerHeight]="50"
              [rowHeight]="'auto'" class="material striped" [sorts]="this.dt_default_sort" [selectionType]="'checkbox'"
              [summaryRow]="'true'" [summaryPosition]="'bottom'"
              [summaryHeight]="'auto'">
  
              <ngx-datatable-column *ngFor="let col of unitsListColumns" [flexGrow]=" col.flexGrow"
                [name]="col.name" [sortable]="col.sortable" [cellClass]="col.css_class" [prop]="col.prop">
                <ng-template ngx-datatable-header-template let-sort="sortFn">
                  <span (click)="sort()">{{ col.name }}</span>
                </ng-template>
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                  <div [ngSwitch]="col.type">
                    <!-- <span *ngSwitchCase="'currency'"> {{row[col.prop]|currency:currency}}</span> -->
                    <span *ngSwitchCase="'percentage'">{{row[col.prop]}}%</span>
                    <span *ngSwitchCase="'action'">
                      <button class="btn btn-danger" (click)="deleteUnits(row._id)"><i class="fa fa-trash"></i></button>
                      
  
                    </span>
                    <!-- <span *ngSwitchCase="'object'"> {{ row[col.prop][col.field_name] }}</span>
                    <span *ngSwitchCase="'date'"> {{ row[col.prop]|date }}</span> -->
  
                    <!-- <span *ngSwitchCase="'status'">
                      <span class="badge badge-danger"
                        *ngIf="row.purchase_status_name=='unbilled'">{{ row[col.prop]}}</span>
                      <span class="badge badge-success"
                        *ngIf="row.purchase_status_name=='billed'">{{ row[col.prop]}}</span>
                      <span class="badge badge-warning"
                        *ngIf="row.purchase_status_name=='partially-billed'">{{ row[col.prop]}}</span>
                    </span> -->
                    <span *ngSwitchDefault>
                      {{ row[col.prop] }}
                    </span>
                  </div>
  
                </ng-template>
              </ngx-datatable-column>
  
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
    </div>
  