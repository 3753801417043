import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HsnService } from "../../_services/hsn.service";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { Router, ActivatedRoute } from "@angular/router";
import swal from "sweetalert2";
import { timingSafeEqual } from 'crypto';

// import * as moment from 'moment/moment';
@Component({
  selector: "app-hsn",
  templateUrl: "./hsn.component.html",
  styleUrls: ["./hsn.component.scss"],
  providers: [NgbModalConfig, NgbModal]
})
export class HsnComponent implements OnInit {
  reorderable = true;
  page: any = { offset: 0, pageSize: 10 };
  sort: any = { "group_code": -1 };
  dt_default_sort = [{ prop: 'group_code', dir: 'desc' }];
  filter: any = {};
  loadingIndicator = false;
  columns: any = {};
  hsn: any = {
    data: [],
    count: 0
  };
  hsnTable: any = [];
  hsnTableListcolumns: any = [];
  files: File[] = [];
  input_file: File;
  // limit = 10;

  // count: number;
  // @ViewChild("hsnTable", { static: true }) table: any;
  company_id: string;
  constructor(
    config: NgbModalConfig,
    public route: ActivatedRoute,
    private modalService: NgbModal,
    private hsnservice: HsnService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    config.backdrop = "static";
    config.keyboard = false;
  }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.hsnTableListcolumns = [
      {
        prop: "group_code",
        name: "Group Code",
        sortable: "hsn_group_code",
        flexGrow: 2,
        show: true,
        is_detailed_row: false
      },
      {
        prop: "code",
        name: "Hsn/Sac Code",
        sortable: "hsn_code",
        flexGrow: 2,
        show: true,
        is_detailed_row: false
      },

      {
        prop: "effects_from",
        name: "GST",
        sortable: "effects_from.igst",
        flexGrow: 2,
        show: true,
        is_detailed_row: false,
        type: 'effects_from'
      },

      {
        prop: "description",
        name: "Description",
        sortable: "description",
        flexGrow: 2,
        show: true,
        is_detailed_row: false
      },
      {
        name: "Action",
        type: "action",
        prop: "_id",
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false
      }
    ];
    this.hsnTableListcolumns.forEach((column) => {
      this.columns[column.prop] = 1;
    });
    if (this.route.snapshot.queryParams['q'] != 'undefined')
      this.filter['name'] = this.route.snapshot.queryParams['q'];
    this.getPaginatedHsn(this.page);
  }

  onSort(event) {
    this.loadingIndicator = true;
    console.log('event', event);
    this.sort = {};
    let sort_details = event.sorts[0];
    this.sort[sort_details.prop] = sort_details.dir == 'asc' ? 1 : -1;
    this.getPaginatedHsn(this.page);
  }

  getPaginatedHsn(pageInfo: any = {}) {
    if (pageInfo !== null) {
      this.page = pageInfo;
    }

    this.filter.company_id = this.company_id;
    this.hsnservice
      .getPaginatedHsn(this.filter, this.columns, this.sort, this.page)
      .subscribe(result => {
        // console.log('result',result);
        this.loadingIndicator = false;
        this.hsn.data = result["data"];
        this.hsn.count = result["count"];
        // this.hsn = result['response']['data'];
        // this.count = result['response']['count'];
        console.log("result", this.hsn);

      });
  }

  clearHsnList() {
    this.filter = {};
    this.getPaginatedHsn(this.page);
  }
  open(content) {
    this.modalService.open(content);
  }
  uploadFile() {
    let data: any = {};

    this.hsnservice.uploadFiles(this.files, data).subscribe(result => {
      if (result["statusCode"] == 200) {
        swal("Success", "Hsn/Sac has been uploaded successfully", "success");
        this.router.navigate(["/masters/hsn/list"]);
      }
    });
  }

  getDate() {
    return new Date();
  }

  deleteHSN(hsn_muid: string) {
    //TODO Call delete API
  }
}
