<!-- <div class="container-fluid"> -->
<!-- <div class="row"> -->
<!-- <div class="col-lg-12"> -->
<form novalidate #searchExpenseForm="ngForm">
  <div class="card">
    <div class="card-body">
      <div class="btn-group mr-3 mb-2 float-right">

        <button type="button" class="btn btn-primary" rel="tooltip" routerLink="/product/group/add">
          Add Groups
        </button>

      </div>
      <h3 class="table-title"><i class="fa fa-user"></i> Groups </h3>


      <div>
        <button type="submit" class="btn btn-primary mr-2"> <i class="fa fa-search"></i> Search</button>
        <button type="button" (click)="clearExpenseFilters();" class="btn btn-danger"><i class="fa fa-close"></i>
          Clear
        </button>

      </div>
    </div>
  </div>
</form>


<div class="p-sm-0">
  <div class="card-body">

    <!-- <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" cdkDropList (cdkDropListDropped)="drop($event)"> -->
      <!-- This is the tree node template for leaf nodes -->
      <!-- <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding cdkDrag [cdkDragData]="node" -->
        <!-- (mouseenter)="dragHover(node)" (mouseleave)="dragHoverEnd()" (cdkDragStarted)="dragStart()"
        (cdkDragReleased)="dragEnd()"> -->
        <!-- use a disabled button to provide padding for tree leaf -->
        <!-- <button mat-icon-button disabled>tt</button>
        {{node.name}}
      </mat-tree-node> -->
      <!-- This is the tree node template for expandable nodes -->
      <!-- <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding cdkDrag [cdkDragData]="node"
        (mouseenter)="dragHover(node)" (mouseleave)="dragHoverEnd()" (cdkDragStarted)="dragStart()"
        (cdkDragReleased)="dragEnd()">
        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        {{node.name}}
      </mat-tree-node>
    </mat-tree> -->
  </div>
</div>