<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <form novalidate #searchPurchaseorderForm="ngForm" (ngSubmit)="getSalesordersList({ offset: 0, pageSize: 10 })">
        <div class="card">
          <div class="card-body">
            <div class=" mr-3 mb-2 float-right">
              <!-- <button (click)="payAmountModal(payAmount)" class="btn btn-primary mr-2">
                Pay
              </button> -->
              <button *ngIf="this.companyDetails.fi_year.status!='Finalized'" type="button" class="btn btn-primary " routerLink="/sales-order/add">
                <i class="icon icon-add"></i>Add Sales Order
              </button>

            </div>
            <h3 class="table-title"><i class="fa fa-file-text"></i> Sales Orders</h3>

            <div class="card-body row">
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="customer">Customer</label>
                <ng-select (clear)="sales_order_lists=[]" [items]="customer_lists" [typeahead]="customerListTypeahead"
                  #customer="ngModel" name="customer" bindLabel="customer_name" bindValue="_id"
                  [(ngModel)]="filters.customer_muid" placeholder="Select Customer">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.customer_name }}
                    </div>
                  </ng-template>

                </ng-select>
              </div>

              <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="sales_order_number">Sales Order Number</label>
                <input type="text" class="form-control" name="sales_order_number" id="sales_order_number"
                  placeholder="Sales Order Number" [(ngModel)]="filters.sales_order_number">
              </div> -->
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="purchase_order_number">Sales Order Number</label>
                <ng-select (clear)="sales_order_lists=[]" [multiple]="true" [items]="soNumberList"
                  [typeahead]="soNumberListTypeahead" name="sales_order_number" bindLabel="sales_order_number"
                  bindValue="sales_order_number" [(ngModel)]="filters.sales_order_number"
                  placeholder="Sales Order Number">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.sales_order_number }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>

              <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="sales_status">Sales Status </label>
                <select id="sales_status" required class="form-control" placeholder="Sales Status" name="sales_status"
                  [(ngModel)]="filters.sales_status">
                  <option value="1">Not-Invoiced</option>
                  <option value="2">Invoiced</option>
                  <option value="3">Partially-Invoiced</option>
                </select>
              </div> -->

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="sales_status">Sales Status</label>
                <ng-select [items]="sales_status_list" name="sales_status" bindLabel="name"
                  [(ngModel)]="filters.sales_status" placeholder="Select Status" bindValue="id">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.name }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="sales_order_date">Sales Order Date</label>
                <div class="input-group">
                  <input class="form-control" name="sales_order_date" placeholder="Select a date" angular-mydatepicker
                    #dp_salesorder="angular-mydatepicker" (click)="dp_salesorder.toggleCalendar()"
                    [(ngModel)]="filters.so_date" [options]="salesorderDateOptions" />
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="filters.so_date"
                      (click)="dp_salesorder.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="dp_salesorder.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>
              </div>
              <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="due_date">Due Date</label>
                <div class="input-group">
                  <input class="form-control" name="due_date" placeholder="Select a date" angular-mydatepicker
                    #dp_duedate="angular-mydatepicker" (click)="dp_duedate.toggleCalendar()"
                    [(ngModel)]="filters.due_date" [options]="dueDateOptions" />
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="filters.due_date"
                      (click)="dp_duedate.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="dp_duedate.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>

              </div> -->
              <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="total_amount">Total Amount</label>
                <input type="text" class="form-control" name="total_amount" id="total_amount" placeholder="Total Amount"
                  [(ngModel)]="filters.total_amount">
              </div> -->
              <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="balance_due">Balance Due</label>
                <input type="text" class="form-control" name="balance_due" id="balance_due" placeholder="Balance Due"
                  [(ngModel)]="filters.balance_due">
              </div> -->

            </div>
            <div>
              <button type="submit" class="btn btn-primary mr-2"> <i class="fa fa-search"></i> Search</button>
              <button type="button" (click)="clearPurchaseorderFilters();" class="btn btn-danger"><i
                  class="fa fa-close"></i>
                Clear
              </button>

            </div>
          </div>
        </div>
      </form>


      <div class="card p-sm-0">
        <ngx-datatable [rows]="sales_order_lists" [loadingIndicator]="loadingIndicator" [externalPaging]="true"
          [columnMode]="'force'" [count]="count" [limit]="page.pageSize" [offset]="page.offset"
          (page)="getSalesordersList($event)" [externalSorting]=true (sort)="onSort($event)" [footerHeight]="50"
          [rowHeight]="'auto'" [selectionType]="'checkbox'" (select)="onSelectOrders($event)" class="material striped"
          [sorts]="this.dt_default_sort" [summaryRow]="'true'" [summaryPosition]="'bottom'" [summaryHeight]="'auto'">

          <ngx-datatable-column *ngFor="let col of salesOrderTableListColumns|filter:'is_detailed_row':false;"
            [flexGrow]=" col.flexGrow" [name]="col.name" [sortable]="col.sortable" [cellClass]="col.css_class"
            [prop]="col.prop">
            <ng-template ngx-datatable-header-template let-sort="sortFn">
              <span (click)="sort()">{{ col.name }}</span>
            </ng-template>
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
              <div [ngSwitch]="col.type">
                <span *ngSwitchCase="'currency'"> {{row[col.prop]|currency:currency}}</span>
                <span *ngSwitchCase="'percentage'">{{row[col.prop]}}%</span>
                <span *ngSwitchCase="'action'">


                  <div class="btn-group mr-3">
                    <!-- <a class="btn btn-primary" name="pay{{i}}" (click)="payAmountModal(payAmount)" type="button"
                      rel="tooltip">Pay</a> -->
                    <span *ngIf="row.sales_status_name !='invoiced'">
                      <a class="btn btn-primary"
                        *ngxPermissionsOnly="['Elixir - Admin', 'Elixir - Manager', 'Client - Admin', 'Client - Manager', 'Elixir - Accountant']"
                        routerLink="/sales-order/edit/{{row._id}}" name="edit{{i}}" type="button"
                        rel="tooltip">Edit</a></span>
                    <div class="btn-group" ngbDropdown placement="bottom-right" container="body" role="group">
                      <a class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></a>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <a class="dropdown-item"
                          *ngIf="row.sales_status_name=='not-invoiced'|| row.sales_status_name=='partially-invoiced' "
                          routerLink="/invoice/sales-invoice/{{row._id}}" type="button" rel="tooltip">Create Invoice</a>

                        <a class="dropdown-item " (click)="open(salesOderViewContent, row._id)">
                          <span class="text-blue">
                            <i class="icon-fa-eye"></i> View
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>


                </span>
                <span *ngSwitchCase="'object'"> {{ row[col.prop][col.field_name] }}</span>
                <span *ngSwitchCase="'date'"> {{ row[col.prop]|date }}</span>

                <span *ngSwitchCase="'status'">
                  <span class="badge badge-danger" *ngIf="row.sales_status_name=='not-invoiced'">{{
                    row[col.prop]}}</span>
                  <span class="badge badge-warning" *ngIf="row.sales_status_name=='partially-invoiced'">{{
                    row[col.prop]}}</span>
                  <span class="badge badge-success" *ngIf="row.sales_status_name=='invoiced'">{{ row[col.prop]}}</span>
                </span>

                <span *ngSwitchDefault>
                  {{ row[col.prop] }}
                </span>
              </div>

            </ng-template>
          </ngx-datatable-column>

          <!-- <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
              let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">


              <div class="page-count">{{ (curPage * pageSize)-pageSize+1 }} - {{ curPage * pageSize }} of
                {{ rowCount }}
              </div>
              <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
                [hidden]="!((rowCount / pageSize) > 1)" (change)="table.onFooterPage($event)">
              </datatable-pager>
            </ng-template>

          </ngx-datatable-footer> -->
        </ngx-datatable>


        <!-- <ngx-datatable class="material" [rows]="sales_order_lists" #SalesorderTable
          [loadingIndicator]="loadingIndicator" [columns]="columns"  footerHeight="50"
          [rowHeight]="'auto'" [reorderable]="reorderable" [offset]="page.offset" [limit]="page.pageSize"
          [columnMode]="'flex'" [sortType]="'multi'" (page)='getSalesordersList($event)' [count]="count"
          [externalPaging]="true">

          <ng-template #actionTmpl let-value="value">

            <div class="btn-group mr-3">
              <button class="btn btn-primary" routerLink="/sales-order/edit/{{value}}" type="button"
                rel="tooltip">Edit</button>
              <div class="btn-group" ngbDropdown placement="bottom-right" role="group">
                <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <button class="dropdown-item" routerLink="/sales-order/edit/{{value}}" type="button"
                    rel="tooltip">Edit</button>
                  <button class="dropdown-item " (click)="deletePurchaseorder(value)">
                    <span class="text-red">
                      <i class="icon-trash-can3"></i> Delete
                    </span>
                  </button>
                </div>
              </div>
            </div>



          </ng-template>
        </ngx-datatable> -->
      </div>
    </div>
  </div>
</div>
<ng-template #payAmount let-c="close" let-d="dismiss">
  <div class="col-12" role="document">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Sales - Payment Customer Name : {{payment.vendor_name}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <form novalidate #payForm="ngForm" (ngSubmit)="payPurchaseOrder()">
      <div class="row modal-body">

        <div class="form-group col-4">
          <label for="transaction_method">Payment Mode</label>
          <select id="payment_mode" required class="form-control" placeholder="payment_mode" name="payment_mode"
            (change)="getPaymentHead()" [(ngModel)]="payment.payment_mode">
            <option value="1">Bank</option>
            <option value="2">Cash on hand</option>
          </select>
          <app-validation-errors [mformField]="payment_mode" label="Payment Method">
          </app-validation-errors>
        </div>

        <div class="form-group col-4">
          <label for="transaction_method">Ledger</label>
          <ng-select [items]="headList" required #account_head="ngModel" name="account_head" bindLabel="name"
            [(ngModel)]="transaction_head" placeholder="Select Head">
            <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <div [ngOptionHighlight]="search">
                {{ item?.flattened_data?.name }}
              </div>
            </ng-template>
          </ng-select>

          <app-validation-errors [mformField]="account_head" label="Ledger">
          </app-validation-errors>
        </div>
        <!-- <div class="form-group col-12">
          <label for="currency">Currency</label>
          <input id="currency" #currency="ngModel" disabled class="form-control" placeholder="currency"
            name="pay_currency" [(ngModel)]="selecteditem_array[0].currency.name" />

        </div> -->

        <div class="form-group col-4">
          <label for="total_amount">Amount</label>
          <input type="number" #total_amount="ngModel" [min]="1" [max]="balance_amt" required class="form-control"
            name="total_amount" [(ngModel)]="payment.total_payment_amount" />
          <app-validation-errors [mformField]="total_amount" label="Total Amount">
          </app-validation-errors>
        </div>
        <div class="form-group  col-4">
          <label for="due_date">Payment Date</label>
          <div class="input-group">
            <input class="form-control" name="due_date" #due_date="ngModel" required placeholder="Select a date"
              angular-mydatepicker #dp_duedate="angular-mydatepicker" [(ngModel)]="tx_date"
              [options]="dueDateOptions" />
            <div class="input-group-append">
              <button type="button" class="btn btn-secondary" *ngIf="tx_date" (click)="dp_duedate.clearDate()">
                <i class="fa fa-close"></i>
              </button>
            </div>
            <div class="input-group-append">
              <button type="button" class="btn btn-secondary" (click)="dp_duedate.toggleCalendar()">
                <i class="fa fa-calendar-o"></i>
              </button>
            </div>
          </div>
          <app-validation-errors [mformField]="due_date" label="Due Date">
          </app-validation-errors>
        </div>

        <div class="form-group col-12">
          <label for="payment_note">Notes</label>
          <input type="text" class="form-control" placeholder="notes" name="payment_note"
            [(ngModel)]="payment.payment_note" />


        </div>
        <table *ngIf="payType =='multiple'" class="table-bordered table ">
          <tr align="center">
            <th>Purchase-ID</th>
            <th>Sales Date</th>
            <th>To be paid</th>
            <th>Pay</th>
          </tr>
          <tbody>
            <tr *ngFor="let item of invoice_array;let i =index">
              <td>{{item?.sales_order_number}}</td>
              <td>{{item?.sales_order_date|date}}</td>
              <td>{{item?.amount}}</td>
              <td><input class="form-control" required type="number" #payment_amount="ngModel" [min]="1"
                  [max]="item.amount" name="payment_amount{{i}}" [(ngModel)]="item.credit">
                <app-validation-errors [mformField]="payment_amount" label="Pay Amount">
                </app-validation-errors>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary">Pay</button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #salesOderViewContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Sales Order View</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-invoice-view [id]="id" [moduleName]="'sales_order'"></app-invoice-view>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
