<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header d-flex align-items-center">
          <div class="row" style="width: 100%;">
            <div class="col-md-11">
              <h3 class="table-title">View User</h3>
            </div>
            <div class="col-md-1">
              <button class="table-title btn btn-warning" (click)="goBack()">Go Back</button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="section-body">
            <div class="container-fluid">
              <div class="row clearfix">
                <div class="col-md-12">
                  <div class="card card-profile">
                    <div class="card-body text-center">
                      <h4 class="mb-3">{{user.fullname}}</h4>

                    </div>
                  </div>
                  <div class="col-12">
                    <ul class="nav nav-tabs mb-3" id="pills-tab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link" [ngClass]="{'active':navTab == 'profile'}" (click)="navTab='profile'"
                          id="pills-profile-tab" data-toggle="pill" href="javascript:void(0);" role="tab"
                          aria-controls="pills-profile" aria-selected="false">Profile</a>
                      </li>
                      <li class="nav-item"><a class="nav-link" [ngClass]="{'active':navTab == 'timeline'}"
                          (click)="navTab='timeline'" id="pills-timeline-tab" data-toggle="pill"
                          href="javascript:void(0);">Time Line
                        </a>
                      </li>
                      <!-- <li class="nav-item">
                        <a class="nav-link" id="pills-timeline-tab" data-toggle="pill" href="#pills-timeline" role="tab"
                          aria-controls="pills-timeline" aria-selected="true">Timeline</a>
                      </li> -->


                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="tab-pane fade px-5" [ngClass]="{'show active':navTab == 'profile' || !navTab}" role="tabpanel">
          <div class="form-row">

            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="username">User Name </label>
              <div>
                {{user.username}}
              </div>

            </div>
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="email">Email</label>
              <div>
                {{user.email}}
              </div>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="firstname">First Name </label>
              <div>
                {{user.firstname}}
              </div>

            </div>
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="lastname">Last Name </label>
              <div>
                {{user.lastname}}
              </div>

            </div>
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="role_name">Role </label>
              <div>
                {{user.role_name}}
              </div>

            </div>
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="address">Address </label>
              <div>
                {{user.address}}
              </div>

            </div>
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="city">City </label>
              <div>
                {{user.city}}
              </div>

            </div>
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="state">State</label>
              <div>
                {{user.state?.name}}
              </div>
            </div>
            <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="postal_code">Postal Code </label>
              <input type="text" class="form-control" #postal_code="ngModel" name="postal_code" id="postal_code"
                [(ngModel)]="company_settings.postal_code">
              <app-validation-errors [mformField]="postal_code" label="postal_code"></app-validation-errors>
            </div> -->
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="country">Country </label>
              <div>
                {{user.country?.name}}
              </div>

            </div>


          </div>
          <div class="card">
            <table class="table table-striped table-bordered col-6">
              <thead>
                <tr>
                  <th>Companies</th>
                  <th>Branches</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of company_branch_details">
                  <td>
                    {{data[0]['company_name']}}
                  </td>
                  <td>
                    <li *ngFor="let branch_data of data[1]">{{branch_data['branch_name']}}</li>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>