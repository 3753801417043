<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <form novalidate #searchPurchaseorderForm="ngForm"
        (ngSubmit)="getPurchaseordersList({ offset: 0, pageSize: 10 })">
        <div class="card">
          <div class="card-body">
            <div class=" mr-3 mb-2 float-right">

              <a  *ngIf="this.companyDetails.fi_year.status!='Finalized'"  class="btn btn-primary float-right mb-4" routerLink="/purchase-order/add">
                <i class="fa fa-plus"></i> Add PurchaseOrder
              </a>



            </div>
            <h3 class="table-title"><i class="fa {{page_icon}}"></i> Purchase Order</h3>
            <div class="card-body row">

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="vendor">Vendor</label>
                <ng-select (clear)="purchaseorderList=[]" [items]="vendor_lists" [typeahead]="vendorListTypeahead"
                  #vendor="ngModel" name="vendor" bindLabel="vendor_name" bindValue="_id"
                  [(ngModel)]="filters.vendor_muid" placeholder="Select Vendor">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.vendor_name }}
                    </div>
                  </ng-template>

                </ng-select>
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="purchase_order_number">Purchase Order Number</label>
                <ng-select (clear)="purchaseorderList=[]" [multiple]="true" [items]="poNumberList" [typeahead]="poNumberListTypeahead"
                  name="purchase_order_number" bindLabel="purchase_order_number" bindValue="purchase_order_number"
                  [(ngModel)]="filters.purchase_order_number" placeholder="Purchase Order Number">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.purchase_order_number }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>

              <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="balance_amount">Balance Amount</label>
                <input type="text" class="form-control" name="balance_amount" id="balance_amount"
                  placeholder="Balance Amount" [(ngModel)]="filters.balance_amount">
              </div> -->

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="purchase_status">Purchase Status</label>
                <ng-select [items]="purchase_status_list" name="purchase_status" bindLabel="name"
                  [(ngModel)]="filters.purchase_status" placeholder="Select Status" bindValue="id">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.name }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="purchaseorder_date">Purchase Order Date</label>
                <div class="input-group">
                  <input class="form-control" name="purchase_order_date" angular-mydatepicker
                    #dp_purchaseorder="angular-mydatepicker" placeholder="Select a date"
                    (click)="dp_purchaseorder.toggleCalendar()" [(ngModel)]="filters.po_date"
                    [options]="purchaseorderDateOptions"/>
                  <!-- <input type="text" class="form-control" name="purchase_order_date" ngxDaterangepicker startKey="start"
                    endKey="end" [ranges]="ranges" placeholder="Select a date" [isCustomDate]="true" [autoApply]="true"
                    [singleDatePicker]="false" [showDropdowns]="true" [(ngModel)]="filters.selected"
                    [customRangeDirection]="true" /> -->

                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="filters.po_date"
                      (click)="dp_purchaseorder.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>

                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>

              </div>

              <!-- <div class="form-group col-12  col-sm-6 col-md-3 col-xl-3">
                <label for="due_date">Due Date</label>
                <div class="input-group">
                  <input class="form-control" name="due_date" placeholder="Select a date" angular-mydatepicker
                    #dp_duedate="angular-mydatepicker" [(ngModel)]="filters.due_date" [options]="dueDateOptions"
                    (click)="dp_duedate.toggleCalendar()" />
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="filters.due_date"
                      (click)="dp_duedate.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="dp_duedate.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>
              </div> -->
            </div>
            <div>
              <button type="submit" class="btn btn-primary mr-2"><i class="fa fa-search"></i> Search</button>
              <button type="button" (click)="clearPurchaseorderFilters();" class="btn btn-danger"><i
                  class="fa fa-close"></i>
                Clear
              </button>

            </div>
          </div>
        </div>
      </form>
      <div class="p-sm-2">

        <!-- <button (click)="payAmountModal(payAmount)" class="btn btn-primary float-right mb-4 mr-2">
          Pay
        </button> -->
      </div>

      <div class="card p-sm-0">
        <div class="card-body">

          <ngx-datatable [rows]="purchaseorderList" [loadingIndicator]="loadingIndicator" [externalPaging]="true"
            [columnMode]="'force'" [count]="page.count" [limit]="page.pageSize" [offset]="page.offset"
            (page)="getPurchaseordersList($event)" [externalSorting]="true" (sort)="onSort($event)" [footerHeight]="50"
            [rowHeight]="'auto'" class="material striped" [sorts]="this.dt_default_sort" [selectionType]="'checkbox'"
            (select)="onSelectOrders($event)" [summaryRow]="'true'" [summaryPosition]="'bottom'"
            [summaryHeight]="'auto'">

            <ngx-datatable-column *ngFor="let col of purchaseorderListColumns" [flexGrow]=" col.flexGrow"
              [name]="col.name" [sortable]="col.sortable" [cellClass]="col.css_class" [prop]="col.prop">
              <ng-template ngx-datatable-header-template let-sort="sortFn">
                <span (click)="sort()">{{ col.name }}</span>
              </ng-template>
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <div [ngSwitch]="col.type">
                  <span *ngSwitchCase="'currency'"> {{row[col.prop]|currency:currency}}</span>
                  <span *ngSwitchCase="'percentage'">{{row[col.prop]}}%</span>
                  <span *ngSwitchCase="'action'">

                    <div class="btn-group mr-3">
                      <!-- <a class="btn btn-primary" name="pay{{i}}" (click)="payAmountModal(payAmount)" type="button"
                      rel="tooltip">Pay</a> -->
                      <span *ngIf="row.purchase_status_name !='billed'">
                        <a class="btn btn-primary"
                          *ngxPermissionsOnly="['Elixir - Admin', 'Elixir - Manager', 'Client - Admin', 'Client - Manager', 'Elixir - Accountant']"
                          routerLink="/purchase-order/edit/{{row._id}}" name="edit{{i}}" type="button"
                          rel="tooltip">Edit</a></span>
                      <a *ngIf="row.purchase_status_name =='billed'" (click)="open(content, row._id)"
                        class="btn btn-primary ">
                        <i class="icon-fa-eye"></i> View
                      </a>
                      <div *ngIf="row.purchase_status_name !='billed'" class="btn-group" ngbDropdown
                        placement="bottom-right" container="body" role="group">
                        <a class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></a>
                        <div class="dropdown-menu" ngbDropdownMenu>
                          <a class="dropdown-item"
                            *ngIf="row.purchase_status_name=='unbilled' ||row.purchase_status_name=='partially-billed'"
                            routerLink="/bill/purchase_order/{{row._id}}" type="button" rel="tooltip">Create Bill</a>

                          <a class="dropdown-item " (click)="open(content, row._id)">
                            <span class="text-blue">
                              <i class="icon-fa-eye"></i> View
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>

                  </span>
                  <span *ngSwitchCase="'object'"> {{ row[col.prop][col.field_name] }}</span>
                  <span *ngSwitchCase="'date'"> {{ row[col.prop]|date }}</span>

                  <span *ngSwitchCase="'status'">
                    <span class="badge badge-danger" *ngIf="row.purchase_status_name=='unbilled'"
                      ngbPopover="Purchase Invoice Not Created"
                      triggers="mouseenter:mouseleave">{{row[col.prop]}}</span>
                    <span class="badge badge-success" *ngIf="row.purchase_status_name=='billed'"
                      ngbPopover="Purchase Invoice Created" triggers="mouseenter:mouseleave">{{row[col.prop]}}</span>
                    <span class="badge badge-warning" *ngIf="row.purchase_status_name=='partially-billed'"
                      ngbPopover="Purchase Invoice Partially Billed"
                      triggers="mouseenter:mouseleave">{{row[col.prop]}}</span>
                  </span>
                  <span *ngSwitchDefault>
                    {{ row[col.prop] }}
                  </span>
                </div>

              </ng-template>
            </ngx-datatable-column>

          </ngx-datatable>
        </div>
      </div>
    </div>

  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Purchase Order View</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
       <app-invoice-view [id]="id" [moduleName]="'purchase_order'"></app-invoice-view>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>

