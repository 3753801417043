<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <form novalidate #searchGstForm="ngForm" (ngSubmit)="getGstsList(null)">
        <div class="card">
          <div class="card-header d-flex align-items-center">
            <h3 class="table-title">Gst List</h3>
          </div>
          <div class="card-body row">
          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-primary mr-2">Search</button>
            <button (click)="clearGstFilters();" class="btn btn-warning">
              Clear Search
            </button>

          </div>
        </div>
      </form>
      <div class="p-sm-2">
        <button type="button" class="btn btn-primary float-right" routerLink="/gst/add">
          <i class="icon icon-add"></i>Add Gst
        </button>
        <!-- <button type="button" class="btn btn-primary float-right" (click)="updateStock()">
          <i class="icon icon-add"></i>Upstock
        </button> -->
      </div>
      <div class="card">

        <ngx-datatable [rows]="gstList" [loadingIndicator]="loadingIndicator" [externalPaging]="true"
          [columnMode]="'force'" [count]="page.count" [limit]="page.limit" [offset]="page.offset"
          (page)='getGstsList($event)' [externalSorting]=true [footerHeight]="50" [rowHeight]="'auto'"
          class="material striped">


          <ngx-datatable-column *ngFor="let col of gstListColumns" [flexGrow]="col.flexGrow" [name]="col.name"
            [cellClass]="col.css_class" [sortable]="col.sortable" [prop]="col.prop">
            <ng-template ngx-datatable-header-template let-sort="sortFn">
              <span (click)="sort()">{{ col.name }}</span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              <div [ngSwitch]="col.type">
                <span *ngSwitchCase="'currency'"> {{row[col.prop]|currency:currency}}</span>
                <span *ngSwitchCase="'date'"> {{ row[col.prop] | date }}</span>
                <div *ngSwitchCase="'status'">
                  <span *ngIf="row.is_filed==1" class="badge badge-default">Not-Filed</span>
                  <span *ngIf="row.is_filed!=1" class="badge badge-success">Filed</span>
                </div>
                <span *ngSwitchCase="'action'">
                  <span *ngIf="row.is_filed!=2">
                    <div class="btn-group mr-3">
                      <!-- <div class="button-row"> -->
                      <!-- <span *ngIf="row[col.prop]!=2">
                      <button class="btn btn-default mr-2"
                        *ngxPermissionsOnly="['Elixir - Admin', 'Elixir - Manager', 'Client - Admin', 'Client - Manager']"
                        (click)="fileGst(row)" mat-mini-fab color="default" title="File">
                        <i class="fa fa-file"></i>
                      </button>
                    </span> -->
                      <span *ngIf="row.is_filed!=2">
                        <a type="button" (click)="reGenerateGSTR1(row)" class="btn btn-primary">ReGenerate
                          GSTR <i class="fa fa-spinner fa-spin" *ngIf="loading"></i></a>
                      </span>
                      <div class="btn-group" ngbDropdown placement="bottom-right" container="body" role="group">
                        <a class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></a>
                        <div class="dropdown-menu" ngbDropdownMenu>
                          <span *ngIf="row.is_filed!=2">
                            <a (click)="open(gstContent, row)" class="dropdown-item">Submit
                              <i class="fa fa-spinner fa-spin" *ngIf="loading"></i></a>
                          </span>
                          <a href="{{file_path+row['file_path'] + '/' + row['file_name']}}" download
                            class="dropdown-item"
                            *ngxPermissionsOnly="['Elixir - Admin', 'Elixir - Manager', 'Client - Admin', 'Client - Manager']"
                            mat-mini-fab color="success" title="Download">Download
                            <!-- <i class="fa fa-download"></i> -->
                          </a>
                           <a  routerLink="/gst/{{ row._id }}" class="dropdown-item" *ngxPermissionsOnly="['Elixir - Admin', 'Elixir - Manager', 'Client - Admin', 'Client - Manager']"  mat-mini-fab color="success"
                title="View">View
                <i class="fa fa-eye"></i>
                </a>
                          <!-- <button class="btn btn-danger" *ngxPermissionsOnly="['Elixir - Admin', 'Elixir - Manager', 'Client - Admin', 'Client - Manager']" (click)="showDeleteProductConfirmDialog(row._id)" mat-mini-fab
                    color="warn" title="Delete">
                    <i class="fa fa-trash"></i>
                  </button> -->
                          <!-- </div> -->
                        </div>
                      </div>
                    </div>
                  </span>
                  <span *ngIf="row.is_filed==2">
                    <a href="{{file_path+row['file_path'] + '/' + row['file_name']}}" download
                      class="btn btn-success mr-2"
                      *ngxPermissionsOnly="['Elixir - Admin', 'Elixir - Manager', 'Client - Admin', 'Client - Manager']"
                      mat-mini-fab color="success" title="Download">
                      <i class="fa fa-download"></i>
                    </a>
                    <a  routerLink="/gst/{{ row._id }}" class="btn btn-success mr-2" *ngxPermissionsOnly="['Elixir - Admin', 'Elixir - Manager', 'Client - Admin', 'Client - Manager']"  mat-mini-fab color="success"
                    title="View">View
                    <i class="fa fa-eye"></i>
                    </a>
                  </span>
                </span>
                <span *ngSwitchDefault>
                  {{ row[col.prop] }}
                </span>
              </div>

            </ng-template>
          </ngx-datatable-column>

        </ngx-datatable>

      </div>
    </div>

  </div>
</div>

<!-- <ng-template #actionTmpl let-value="value">

  <div class="btn-group mr-3">
    <button class="btn btn-primary" routerLink="/gst/edit/{{value}}" type="button" rel="tooltip">Edit</button>
    <div class="btn-group" ngbDropdown placement="bottom-right" role="group">
      <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
      <div class="dropdown-menu" ngbDropdownMenu>
        <button class="dropdown-item" routerLink="/gst/edit/{{value}}" type="button" rel="tooltip">Edit</button>
        <button class="dropdown-item " (click)="deleteGst(value)">
          <span class="text-red">
            <i class="icon-trash-can3"></i> Delete
          </span>
        </button>
      </div>
    </div>
  </div>



</ng-template> -->
<ng-template #gstContent let-modal>
  <form novalidate #addSubmitGstForm="ngForm" (ngSubmit)="updateGst()">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">GST Content</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
          <label for="submitted_by">Submitted By</label>
          <div class="inline-block">
            <input type="text" class="form-control" #submitted_gst_by="ngModel" required [(ngModel)]="submitted_by"
              name="submitted_by" />
          </div>
        </div>
        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
          <label for="submitted_date">Submitted Date</label>
          <div class="input-group">
            <input class="form-control" required name="submitted_date" placeholder="Select a date" angular-mydatepicker
              #dp_gstDate="angular-mydatepicker" (click)="dp_gstDate.toggleCalendar()" [(ngModel)]="submitted_date"
              [options]="gstDateOptions" />
            <div class="input-group-append">
              <button type="button" class="btn btn-secondary" (click)="dp_gstDate.clearDate()">
                <i class="fa fa-close"></i>
              </button>
            </div>
            <div class="input-group-append">
              <button type="button" class="btn btn-secondary" (click)="dp_gstDate.toggleCalendar()">
                <i class="fa fa-calendar-o"></i>
              </button>
            </div>
          </div>
          <!-- <div class="inline-block">
          <input type="text" class="form-control" #submitted_gst_date="ngModel" [(ngModel)]="submitted_date" name="submitted_date" />
        </div> -->
        </div>
        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
          <label for="amount_paid">Amount Paid</label>
          <div class="inline-block">
            <input type="number" class="form-control" required #amount_gst_paid="ngModel" [(ngModel)]="amount_paid"
              name="amount_paid" />
          </div>
        </div>
        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
          <label for="amount_paid_date">Amount Paid Date</label>
          <div class="input-group">
            <input class="form-control" required name="amount_paid_date" placeholder="Select Date" angular-mydatepicker
              #dp_gstAmountPaidDate="angular-mydatepicker" (click)="dp_gstAmountPaidDate.toggleCalendar()"
              [(ngModel)]="amount_paid_date" [options]="gstAmountPaidDateOptions" />
            <div class="input-group-append">
              <button type="button" class="btn btn-secondary" (click)="dp_gstAmountPaidDate.clearDate()">
                <i class="fa fa-close"></i>
              </button>
            </div>
            <div class="input-group-append">
              <button type="button" class="btn btn-secondary" (click)="dp_gstAmountPaidDate.toggleCalendar()">
                <i class="fa fa-calendar-o"></i>
              </button>
            </div>
          </div>
          <!-- <div class="inline-block">
          <input type="text" class="form-control" #submitted_gst_date="ngModel" [(ngModel)]="submitted_date" name="submitted_date" />
        </div> -->
        </div>
        <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
          <label for="ref_no">Ref No</label>
          <div class="inline-block">
            <input type="text" class="form-control" required #ref_gst_no="ngModel" [(ngModel)]="ref_no" name="ref_no" />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="modal-body">
       <app-invoice-view [id]="id" [moduleName]="'purchase_order'"></app-invoice-view>
  </div> -->
    <div class="modal-footer">
      <button type="button" class="btn btn-warning" (click)="modal.close('Save click')">Close</button>
      <button type="submit" [disabled]="!addSubmitGstForm.valid" class="btn btn-primary">Submit</button>
    </div>
  </form>
</ng-template>
<!-- (click)="updateGst()" -->