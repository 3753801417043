import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../../_services/reports.service';
import { AuthenticationService } from '../../_services/authentication.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  currentAndPreviousMonthSalesReport: any;
  salesVsReceiptsReport: any;
  incomeVsExpenseData: any = [];
  incomeReportData: any = [];
  cashAndBankBalanceData: any = [];
  tradePayablesAndReceivalesData: any = [];
  outstandingReceivablesAndPayables: any;
  expenseReportData: any = [];
  receivableReportData: any = [];
  payableReportData: any = [];
  incomeVsExpenseChartOptions: any = {
    legend: true,
    animation: true,
    title: 'Income VS Expenses',
    xaxis: 'Month'
  }
  cashAndBankBalanceChartOptions: any = {
    legend: true,
    animation: true,
    title: 'Cash and Bank Balance',
    xaxis: 'Balance'
  }
  expenseReportOptions: any = {
    legend: true,
    animation: true,
    // datalabel:  { visible: true, name: 'text', position: 'Outside' },
    title: 'Expense Wise Report',
    xaxis: 'Expenses'
  }
  incomeReportOptions: any = {
    legend: true,
    animation: true,
    // datalabel:  { visible: true, name: 'text', position: 'Outside' },
    title: 'Income',
    xaxis: 'Income'
  }
  outstandingReceivablesAndPayablesOptions: any = {
    legend: true,
    animation: true,
    title: 'Outstanding Trade Receivables and Payables',
    xaxis: 'Balance'
  }
  cashAndBankBalanceChartConfig: any = [
    {
      type: 'pie',
      xaxis: 'payment_type', yaxis: 'balance_amount',
      name: 'payment_type',
    }];
  // expenseReportChartConfig: any = [
  //   {
  //     type: 'pie',
  //     xaxis: 'expense_by', yaxis: 'expense_amount',
  //     dataLabel: { name: 'expense_amount' },
  //     name: 'expense_by',
  //   }];
  expenseReportChartConfig = [{
    type: 'doughnut', xaxis: 'expense_by', dataLabel: { name: 'expense_amount', showLabel: true, position: 'Outside' },
    yaxis: 'expense_amount', name: 'Amount', animation: true, radius: 70, innerRadius: 30,
    startAngle: 270, endAngle: 90,
    explode: true,
    explodeOffset: 50
  }];
  incomeReportChartConfig = [{
    type: 'pie', xaxis: '_id.group_by_name', dataLabel: { name: 'amount', showLabel: true, position: 'Outside' },
    yaxis: 'credit_total', name: 'Amount', animation: true, radius: 70, innerRadius: 30,
    startAngle: 270, endAngle: 90,
    explode: true,
    explodeOffset: 50
  }]
  tradePayablesAndReceivalesChartConfig: any = [
    {
      type: 'pie',
      xaxis: 'payment_type', yaxis: 'balance_amount',
      name: 'outstanding',
    }];
  incomeVsExpenseChartConfig: any = [
    {
      type: 'bar',
      xaxis: 'month', yaxis: 'income',
      name: 'Income',
      innerRadius: 30,
      radius: 100,
      explode: true,
      explodeOffset: 50

    },
    {
      type: 'bar',
      xaxis: 'month', yaxis: 'expense',
      name: 'Expense',
      innerRadius: 30,
      radius: 100,
      explode: true,
      explodeOffset: 50

    }
  ];
  widgetLabels: any = {
    labels_1:
    {
      cash_balance: 0,
      bank_balance: 0,
      overall_balance: 0
    }
  };
  invoiceProgress: any = { data: [], overall_total: 0 };
  constructor(
    private reportsService: ReportsService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit() {
    // this.getCurrentAndPreviousMonthSalesReport("601b9f2527a81cd52b4db4fb");
    this.getCashAndBankBalance("601be26827a81cd52b4dc9b8");
    // this.getOutstandingReceivableAndPayables("601cea7527a81cd52b4e1349");
    this.getincomeVsExpenseData("601ce90427a81cd52b4e12cf");
    this.getIncomeReport("62fe027239716b875c11bcc1");
    this.getDashboardWidgets();
    this.getInvoiceProgressByStatus();
  }
  getDashboardWidget(widgetType: number) {
    this.reportsService.getDashboradWidget(widgetType, this.authenticationService.getDefaultCompanyId())
      .subscribe((result:any) => {
        // this.widgetLabels['labels_' + widgetType] = result['reponse'];
        if (widgetType == 1) {
          this.widgetLabels['labels_' + widgetType] = {};
          this.widgetLabels['labels_' + widgetType]['overall_balance'] = 0;
          (result.response||[]).forEach(data => {
            if (data._id.account_head.includes(1031)) {
              this.widgetLabels['labels_' + widgetType]['cash_balance'] = data.balance;
              this.widgetLabels['labels_' + widgetType]['overall_balance'] += data.balance;
            } else {
              this.widgetLabels['labels_' + widgetType]['bank_balance'] = data.balance;
              this.widgetLabels['labels_' + widgetType]['overall_balance'] += data.balance;
            }
          });
        }
        if (widgetType == 2) {
          this.widgetLabels['labels_' + widgetType] = 0;
          this.expenseReportData = result['response']||[];
          this.expenseReportData.forEach(expense => {
            this.widgetLabels['labels_' + widgetType] += expense.expense_amount;
          });
        }
        if (widgetType == 3) {
          this.widgetLabels['labels_' + widgetType] = 0;
          this.receivableReportData = result['response']||[];
          this.receivableReportData.forEach(receivable => {
            this.widgetLabels['labels_' + widgetType] += receivable.receivable;
          });
        }
        if (widgetType == 4) {
          this.widgetLabels['labels_' + widgetType] = 0;
          this.payableReportData = result['response']||[];
          this.payableReportData.forEach(payable => {
            this.widgetLabels['labels_' + widgetType] += payable.payable_amount;
          });
          // this.widgetLabels['labels_' + widgetType] = result['response'];
        }
      });

  }
  getCurrentAndPreviousMonthSalesReport(customizationId: string) {
    this.reportsService.getCustomizationDetails(customizationId).then(result => {
      let reportDetails = this.reportsService.getReportInfo(result);

      reportDetails.filters = this.reportsService.getReportReplaceParamsCondition(reportDetails.filters);
      this.reportsService
        .getData(
          reportDetails.apiURL,
          reportDetails.filters,
          reportDetails.customized_query,
          reportDetails.tableColumns,
          reportDetails.preSummary,
          reportDetails.postSummary,
          reportDetails.model,
        )
        .then((result) => {
          this.currentAndPreviousMonthSalesReport = result['result'];

        });

    });

  }

  getCashAndBankBalance(customizationId: string) {
    this.reportsService.getCustomizationDetails(customizationId).then(result => {
      let reportDetails = this.reportsService.getReportInfo(result);

      reportDetails.filters = this.reportsService.getReportReplaceParamsCondition(reportDetails.filters);
      this.reportsService
        .getData(
          customizationId,
          reportDetails.apiURL,
          reportDetails.filters,
          reportDetails.customized_query,
          reportDetails.tableColumns,
          reportDetails.preSummary,
          reportDetails.postSummary,
          reportDetails.model,
        )
        .then((result) => {
          this.cashAndBankBalanceData = result['result'];

        });

    });
  }

  // getSalesVsReceiptsReport() {
  //   this.reportsService.getSalesVsReceiptsReport({}).subscribe(result => {
  //     this.salesVsReceiptsReport = result;
  //   });
  // }

  getincomeVsExpenseData(customizationId: string) {
    this.reportsService.getCustomizationDetails(customizationId).then(result => {
      let reportDetails = this.reportsService.getReportInfo(result);

      reportDetails.filters = this.reportsService.getReportReplaceParamsCondition(reportDetails.filters);
      this.reportsService
        .getData(
          customizationId,
          reportDetails.apiURL,
          reportDetails.filters,
          reportDetails.customized_query,
          reportDetails.tableColumns,
          reportDetails.preSummary,
          reportDetails.postSummary,
          reportDetails.model,
        )
        .then((result) => {
          this.incomeVsExpenseData = result['result'];
        });

    });
  }
  getIncomeReport(customizationId: string) {
    this.reportsService.getCustomizationDetails(customizationId).then(result => {
      let reportDetails = this.reportsService.getReportInfo(result);

      reportDetails.filters = this.reportsService.getReportReplaceParamsCondition(reportDetails.filters);
      if (reportDetails.apiURL.length > 0) {

        this.reportsService
          .getData(
		  customizationId,
            reportDetails.apiURL,
            reportDetails.filters,
            reportDetails.customized_query,
            reportDetails.tableColumns,
            reportDetails.preSummary,
            reportDetails.postSummary,
            reportDetails.model,
          )
          .then((result) => {
            this.incomeReportData = result['response'];
          });
      }

    });
  }

  getOutstandingReceivableAndPayables(customizationId: string) {
    this.reportsService.getCustomizationDetails(customizationId).then(result => {
      let reportDetails = this.reportsService.getReportInfo(result);

      reportDetails.filters = this.reportsService.getReportReplaceParamsCondition(reportDetails.filters);
      this.reportsService
        .getData(
          reportDetails.apiURL,
          reportDetails.filters,
          reportDetails.customized_query,
          reportDetails.tableColumns,
          reportDetails.preSummary,
          reportDetails.postSummary,
          reportDetails.model,
        )
        .then((result) => {
          this.outstandingReceivablesAndPayables = result['result'];

        });

    });
  }

  getInvoiceProgressByStatus() {
    this.reportsService.getInvoiceProgressByStatus(this.authenticationService.getDefaultCompanyId()).subscribe(result => {
      this.invoiceProgress['data'] = result['response']||[];
      this.invoiceProgress['data'].forEach(report => {
        this.invoiceProgress['overall_total'] += report.amount;
      });
      this.invoiceProgress['data'].forEach(report => {
        let percentage = (report.amount / this.invoiceProgress['overall_total']) * 100;
        report.percentage = percentage;
      });
    });
  }
  getPercentage(amount: number) {

    // console.log('percentage', 'width:' + percentage + '%');
    // return ;
  }

  getDashboardWidgets() {
    this.getDashboardWidget(1);
    this.getDashboardWidget(2);
    this.getDashboardWidget(3);
    this.getDashboardWidget(4);
  }
}
