<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header d-flex align-items-center">
          <h3 class="table-title">VoucherType Add</h3>
        </div>
        <form novalidate #addVoucherTypeForm="ngForm">
          <div class="card-body row">
            <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="name">Name</label>
              <input type="text" class="form-control" required id="name" name="name" #name="ngModel"
                [(ngModel)]="voucherType.name" placeholder="Name">
              <app-validation-errors [mformField]="name" label="Name"></app-validation-errors>
            </div>

            <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="code">Code</label>
              <input type="text" class="form-control" required id="code" name="code" #code="ngModel"
                [(ngModel)]="voucherType.code" placeholder="Code">
              <app-validation-errors [mformField]="code" label="Code"></app-validation-errors>
            </div>

            <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3" *ngIf="voucherCategoryCode==0">
              <label for="category_name">Category Name</label>
              <!-- <select class="form-control" #category_name="ngModel" required id="category_name" name="category_name" [(ngModel)]="voucherType.category_name">
                            <option value="1">Sales</option>
                            <option value="2">Purchase</option>
                            <option value="3">Cash</option>
                            <option value="4">Bank</option>
                            <option value="5">Contra</option>
                            <option value="6">Journal</option>
                        </select> -->
              <ng-select [items]="categoryList" required #category_name="ngModel" name="category_name"
                bindLabel="category_name" [(ngModel)]="category_code" placeholder="Select Category Name">
                <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div [ngOptionHighlight]="search">
                    {{ item.category_name }}
                  </div>
                </ng-template>
              </ng-select>
              <app-validation-errors [mformField]="category_name" label="Category Name"></app-validation-errors>
            </div>
          </div>

          <div class="card-footer">
            <button type="button" (click) = "addUpdateVoucherType()" [disabled]="!addVoucherTypeForm.valid" class="btn btn-primary">Save</button>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>
