<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <form class="form-horizontal" (ngSubmit)="getPaginatedVendors({ offset: 0, pageSize: 10 })" novalidate
        #filterCustomer="ngForm">
        <div class="card">
          <div class="card-body">
            <div class=" mr-3 mb-2 float-right">
              <button *ngIf="this.companyDetails.fi_year.status!='Finalized'" class="btn btn-primary float-right mb-4" routerLink="/receipt/vendor/add">
                <i class="icon icon-add"></i>Add Vendor Advance
              </button>

            </div>
            <h3 class="table-title"><i class="fa fa-address-card"></i> Purchase Advance</h3>
            <div class="card-body row">
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label class="col-form-label">Payee</label>
                <ng-select [items]="payeeList" [typeahead]="payeeListTypeahead" name="payee" #payee="ngModel"
                  [(ngModel)]="filters.vendors" id="payee" bindLabel="name">
                  <ng-template ng-label-tmp let-item="item">
                    <div [ngOptionHighlight]="search">
                      {{ item?.flattened_data?.name }}
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item?.flattened_data?.name }}
                    </div>
                  </ng-template>
                </ng-select>
                <app-validation-errors [mformField]="payee" label="Payee">
                </app-validation-errors>
              </div>
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="payment_date">Payment Date</label>
                <div class="input-group">
                  <input class="form-control" name="payment_date" angular-mydatepicker
                    #dp_payment_date="angular-mydatepicker" placeholder="Select Payment Date"
                    (click)="dp_payment_date.toggleCalendar()" [(ngModel)]="filters.payment_date"
                    [options]="paymentDateOptions"/>

                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="filters.payment_date"
                      (click)="dp_payment_date.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>

                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>

              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="purchase_status">Purchase Status</label>
                <ng-select [items]="purchase_status_list" name="purchase_status" bindLabel="name"
                  [(ngModel)]="filters.purchase_status" placeholder="Select Status" bindValue="id">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.name }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div>
              <button class="btn btn-primary mr-2" type="submit"><i class="fa fa-search"></i> Search</button>
              <button class="btn btn-danger" (click)="clearPurchaseAdvance()" type="button"><i
                  class="fa fa-close"></i> Clear</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="card p-sm-0">
  <ngx-datatable [rows]="vendors" [loadingIndicator]="loadingIndicator" [externalPaging]="true" [columnMode]="'force'"
    [count]="count" [limit]="page.limit" [offset]="page.offset" (page)='getPaginatedVendors($event)'
    [externalSorting]=true (sort)="onSort($event)" [footerHeight]="50" [rowHeight]="'auto'" class="material striped"
    [sorts]="this.dt_default_sort">

    <ngx-datatable-column *ngFor="let col of vendorTableListColumns" [flexGrow]="col.flexGrow" [name]="col.name"
      [sortable]="col.sortable" [prop]="col.prop" [cellClass]="col.css_class" [canAutoResize]="true">
      <ng-template ngx-datatable-header-template let-sort="sortFn">
        <span (click)="sort()">{{ col.name }}</span>
      </ng-template>
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <div [ngSwitch]="col.type">
          <span *ngSwitchCase="'currency'"> {{row[col.prop]|currency:currency}}</span>
          <span *ngSwitchCase="'object'">
            {{ row?.party?.name}}
          </span>

          <span *ngSwitchCase="'action'">
            <div class="btn-group mr-3" *ngIf="row.advance_status ==6">
              <a class=" btn btn-primary" name="send{{ i }}" type="button" (click)="confirmAdvanceStatus(row)"
                rel="tooltip">Confirm</a>
              <!-- <div class="button-row"> -->
              <div class="btn-group" ngbDropdown placement="bottom-right" container="body" role="group">
                <a class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></a>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <a class="dropdown-item" (click)="open(purchaseAdvanceViewContent, row._id)" mat-mini-fab
                    color="primary" title="View"><span class="text-blue">View</span></a>
                  <a class="dropdown-item " (click)="deleteVendorAdvance(row)" *ngIf="row.advance_status ==6">
                    <span class="text-red">
                      <i class="icon-trash-can3"></i> Cancel
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <button class="btn btn-success mr-2" type="button" *ngIf="row.advance_status !=6"
              (click)="open(purchaseAdvanceViewContent, row._id)"><i class="fa fa-eye"></i></button>
            <!-- <div class="button-row">

              <button class="btn btn-success mr-2" type="button"
                *ngIf="row.advance_status ==1 || row.advance_status ==2"
                routerLink="/bill/list/purchaseadvance/{{ row?.party?.id}}/{{row._id}}"><i
                  class="fa fa-eye"></i></button>
              <button class="btn btn-danger mr-2" (click)="deleteVendorAdvance(row)" mat-mini-fab color="warn"
                *ngIf="row.advance_status ==1" title="Delete">
                Cancel
              </button>
            </div> -->

          </span>
          <span *ngSwitchCase="'date'"> {{ row[col.prop] | date }}</span>
          <div *ngSwitchCase="'status'">
            <span *ngIf="row[col.prop]=='draft'" class="badge badge-default">{{row[col.prop]}}</span>
            <span class="badge badge-danger" *ngIf="row.advance_status_name=='unpaid'"
                      ngbPopover="Payment Not Created"
                      triggers="mouseenter:mouseleave">{{row[col.prop]}}</span>
          </div>
          <span *ngSwitchDefault>
            {{ row[col.prop] }}
          </span>

        </div>

      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>

<ng-template #purchaseAdvanceViewContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Purchase Advance View</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-receipts-vendor-view [id]="id" [moduleName]="'purchase_advance'"></app-receipts-vendor-view>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
