import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";

// import * as moment from 'moment/moment';
import { HsnService } from "../../../_services/hsn.service";
import { Hsn } from "../../../_models/hsn.model";
import { MastersService } from "../../../_services/masters.service";
import { AuthenticationService } from "../../../_services/authentication.service";

import { IAngularMyDpOptions } from "angular-mydatepicker";
import swal from "sweetalert2";
import { ConstantService } from "../../../_config/constants";

@Component({
  selector: "app-hsn-add",
  templateUrl: "./hsn-add.component.html",
  styleUrls: ["./hsn-add.component.scss"]
})
export class HsnAddComponent implements OnInit {
  loading:boolean =false;
  myOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat
  };

  effectsFromDateOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  country_list: any;
  id: string;
  is_edit_form: boolean = false;
  public hsn = new Hsn();
  hsnArray: any = [];
  hsn_paramType: any;
  type: Number;
  click_count: number = 0;
  isDisabled: boolean = false;
  hsnArray_length: number;
  hsnid: string;

  constructor(
    private hsnservice: HsnService,
    private mastersService: MastersService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) { }
  ngOnInit() {
    // this.getCountries();
    //  this.addItems();

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.hsn_paramType = params.get("type");
      if (this.hsn_paramType == "edit") {
        this.is_edit_form = true;
        this.hsnid = params.get("id");
        this.getHsnData(params.get("id"));
      }
    });


  }

  getHsnData(id) {
    let type_number: Number = 0;
    this.hsnservice.getHSNByCondition({ "_id": id }).subscribe(result => {
      let set_dates: any = [
        "from_date"];
      this.hsn = result["response"][0];
      type_number = this.hsn.type;
      this.type = type_number;
      this.hsn.code = this.hsn.code;
      this.hsn.chapter = this.hsn.chapter;
      this.hsn.group_code = this.hsn.group_code;
      this.hsnArray = this.hsn.effects_from;
      this.hsnArray_length = this.hsnArray.length;

    });


  }
  addHsnSubmit() {
    if (this.hsnid) {
      this.editHsn();
    } else {
      this.addHsn();
    }

  }
  editHsn() {
    this.loading =true;
    if (this.hsnArray) {
      this.hsn.effects_from = this.hsnArray;
    }
    this.hsn.type = this.type;
    this.hsn.country_code = {
      _id: "56dd4f381ffda7ee447019d3",
      name: "India"
    };

    this.hsn.effects_from.forEach((myObject, index) => {
      // console.log(myObject,"=======index---------ISO Date>>",(myObject.to_date).toISOString())      // "PT5M"

      if (index == (this.hsn.effects_from.length - 1)) {
        console.log(index);
        this.hsn.effects_from[index].from_date = myObject["from_date"][
          "singleDate"
        ]["jsDate"].toISOString();
        this.hsn.effects_from[index].to_date = "";
        this.hsn.effects_from[this.hsn.effects_from.length - 1].is_active = true;
      } else {
        this.hsn.effects_from[index].from_date = myObject["from_date"];
        this.hsn.effects_from[index].to_date = (myObject["to_date"]) ? myObject["to_date"] : new Date().toISOString();
      }
      this.hsn.effects_from[index].sgst = this.hsn.effects_from[index].igst / 2;
      this.hsn.effects_from[index].cgst = this.hsn.effects_from[index].igst / 2;

      if (index < (this.hsn.effects_from.length - 1)) {
        this.hsn.effects_from[index].is_active = false;

      }

    });
    this.hsnservice.editHsn(this.hsn).subscribe(result => {
      if (result['response']['status_code'] == 1) {
        this.loading =false;
        swal("Success", "Hsn has been added successfully", "success");
        this.router.navigate(["/masters/hsn/list"]);
      }
    });
  }
  addHsn() {
    this.loading =true;
    if (this.hsnArray) {
      this.hsn.effects_from = this.hsnArray;
    }
    this.hsn.type = this.type;
    this.hsn.country_code = {
      _id: "56dd4f381ffda7ee447019d3",
      name: "India"
    };
    console.log('4444444555555', this.hsn.effects_from);
    let hsnEffectsFromObj: any ={};
    hsnEffectsFromObj.from_date = this.hsn.from_date[
      "singleDate"
    ]["jsDate"].toISOString();

    hsnEffectsFromObj.igst = this.hsn.gst_percent;
    hsnEffectsFromObj.cgst = this.hsn.gst_percent / 2;
    hsnEffectsFromObj.sgst = this.hsn.gst_percent / 2;
    this.hsn.effects_from.push(hsnEffectsFromObj);

    this.hsnservice.addHsn(this.hsn).subscribe(result => {
      if (result["muid"] != "") {
        this.loading =false;
        swal("Success", "Hsn has been added successfully", "success");
        this.router.navigate(["/masters/hsn/list"]);
      }
    });
  }

  getCountries() {
    this.mastersService.getCountries().subscribe(result => {
      this.country_list = result["response"];
    });
  }
  addItems() {
    console.log(this.click_count);
    if (this.click_count >= 1) {
      swal("Info", "Only one effective date can be added", "info");
    } else {

      this.hsnArray = this.hsnArray;
      this.hsnArray.push({
        from_date: Date,
        to_date: "",
        sgst: "",
        cgst: "",
        igst: "",
        is_active: ""
      });

      console.log('99999999', this.hsnArray);
      this.click_count = this.click_count + 1;
      this.isDisabled = false;
    }


  }

  deleteItem(index_value) {
    console.log('0000000000', index_value);
    this.hsnArray.splice(index_value, 1);
    this.click_count = this.click_count - 1;
  }
}
