import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { ReportsService } from '@app/_services/reports.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ReportCustomization, Column } from '@app/_models/report-customization';




@Component({
  selector: 'app-report-customization-add',
  templateUrl: './report-customization-add.component.html',
  // styleUrls: ['./report-customization-add.component.scss']
})
export class ReportCustomizationAddComponent implements OnInit {
  id: string;
  is_edit_form: boolean = false;
  //TODO get roles from the backend or constants
  availableRoles: any = [
    { role_name: "Super Admin", role_id: -1 },
    { role_name: "Admin", role_id: 1 },
    { role_name: "Manager", role_id: 2 },
  ];
  reportCustomization: ReportCustomization = new ReportCustomization();
  apiurl: string;
  customizationDetails: any;
  data: any;
  addReportCustomization: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private reportsService: ReportsService

  ) { }


  ngOnInit() {
    console.log("TEST");
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
      if (this.id != null) {
        this.getReportCustomization();
      }

      var result = this.router.url.match('/edit/');
      if (result != null) {
        if (result[0] === '/edit/' && this.id != null) {
          this.is_edit_form = true;
        }
      }
    });


  }


  //   this.getReportCustomizationsList();


  updateReportCustomization(reportCustomization) {
    console.log('======>>', this.id);
    this.reportsService.updateReportCustomization(this.reportCustomization, this.id).subscribe(result => {
      if (result['status_code'] === 1) {
        swal('Added report-customization sucessfully!', 'report-customization added.', 'success');
        this.router.navigateByUrl('/report-customization/list');
      } else {
        this.showErrors(result[' response '][' errors ']);

      }

    });

  }



  getReportCustomization() {
    this.reportsService.getCustomizationDetails(this.id).then(result => {
      this.data = result['response']['data'];

      this.apiurl = JSON.stringify(this.reportCustomization.apiurl)
    });
  }

  addUpdateReportCustomization() {
    if (this.is_edit_form === true) {
      this.editReportCustomization();
    } else {
      // this.addReportCustomization();
    }
  }

  editReportCustomization() {
    // this.reportsService.editReportCustomization(this.reportCustomization, this.id).subscribe(result => {
    //   if (result[' response '] == 1) {
    //     swal(' Success ', ' Report-customization Updated Successfully ');
    //     this.router.navigateByUrl('/report-customization/list');
    //   } else {
    //     this.showErrors(result[' response '][' errors ']);
    //   }
    // });
  }

  showErrors(errors) {
    let error_string: string;
    error_string = ' ';
    errors.forEach((item) => {
      error_string += ' < br / > ' + item;
    });
    swal({
      title: 'Error',
      html: error_string,
      type: 'error'
    }).then(function () {
    });
  }

  // addColumnRow() {
  //   let columnObj: Column = new Column();
  //   this.reportCustomization.columns.push(columnObj);
  // }

}
