import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  defaultMenu: boolean = true;
  constructor(@Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
  }
  toggleMenu() {
    if (this.defaultMenu) {
      this.renderer.removeClass(this.document.body, 'offcanvas-active');
      this.defaultMenu = false;
    } else {
      this.renderer.addClass(this.document.body, 'offcanvas-active');
      this.defaultMenu = true;
    }

  }
}
