<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <form novalidate #searchContraForm="ngForm" (ngSubmit)="getContrasList({ offset: 0, pageSize: 10 })">
        <div class="card">
          <div class="card-body">
            <div class="btn-group mr-3 mb-2 float-right">

              <a *ngIf="this.companyDetails.fi_year.status!='Finalized'" class="btn btn-primary" routerLink="/contra/add">
                Add Contra
              </a>
            </div>
            <h3 class="table-title"><i class="fa fa-user"></i> Contra</h3>

            <div class="card-body row">
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label>Contra ID</label>
                <div class="">
                  <input type="text" class="form-control" name="contra_id" #contra_id="ngModel" id="contra_id"
                    [(ngModel)]="filters.contra_id" placeholder="">
                </div>
              </div>

              <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 ">
                <label class="col-form-label ">Amount</label>
                <div class="">
                  <input type="number" class="form-control" name="amount" #amount="ngModel" id="amount"
                    [(ngModel)]="filters.amount" placeholder="">
                </div>
              </div> -->

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 ">
                <label class="col-form-label ">Transaction Date</label>
                <div class="">
                  <div class="input-group">
                    <input angular-mydatepicker #tx_date="ngModel" placeholder="Transaction Date"
                      (click)="tx_date_dp.toggleCalendar()" #tx_date_dp="angular-mydatepicker" class="form-control"
                      name="tx_date" [(ngModel)]="filters.tx_date" [options]="tx_dateDpOptions">
                    <!-- (dateChanged)="ontx_dateDateChanged($event)" -->
                    <!-- clear date button -->
                    <div class="input-group-append">
                      <a type="button" class="btn btn-secondary" *ngIf="filters.tx_date"
                        (click)="tx_date_dp.clearDate();">
                        <i class="fa fa-close"></i>
                      </a>
                    </div>

                    <!-- toggle calendar button -->
                    <div class="input-group-append">
                      <a type="button" class="btn btn-primary" (click)="tx_date_dp.toggleCalendar()">
                        <i class="fa fa-calendar-o"></i>
                      </a>
                    </div>
                  </div>
                  <app-validation-errors [mformField]="tx_date" label="Transaction Date">
                  </app-validation-errors>
                </div>
              </div>

              <!-- <div class="form-group col-sm-6 col-md-3 col-xl-3 ">
                <label class="col-form-label ">Account Head</label>
                <div class="">
                  <ng-select [items]="from_bankList" [typeahead]="fromBankListTypeahead" name="from_bank"
                    #from_bank="ngModel" bindLabel="name" [(ngModel)]="filters.account_head" id="from_bank"
                    bindValue="name">
                    <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <div [ngOptionHighlight]="search">
                        {{ item?.flattened_data?.name }}
                      </div>

                    </ng-template>
                  </ng-select>
                </div>
              </div> -->
              <!-- <input type="text" class="form-control" name="from_bank" #from_bank="ngModel" id="from_bank"
                                [(ngModel)]="filters.from_bank" placeholder=""> -->
              <!-- </div>
                        </div> -->

              <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 ">
                            <label class="col-form-label ">To</label>
                            <div class="">
                                <ng-select [items]="to_bankList" [typeahead]="toBankListTypeahead" name="to_bank"
                                    #to_bank="ngModel" [(ngModel)]="contra.to_bank" bindLabel="name" id="to_bank"
                                    bindValue="name">
                                    <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index"
                                        let-search="searchTerm">
                                        <div [ngOptionHighlight]="search">
                                            {{ item?.flattened_data?.name }}
                                        </div>
                                    </ng-template>
                                </ng-select> -->
              <!-- <input type="text" class="form-control" name="to_bank" #to_bank="ngModel" id="to_bank"
                                [(ngModel)]="filters.to_bank" placeholder=""> -->
              <!-- </div>
                        </div> -->

            </div>
            <div>
              <button type="submit" class="btn btn-primary mr-2"><i class="fa fa-search"></i> Search</button>
              <a type="button" (click)="clearContraFilters();" class="btn btn-warning" class="btn btn-danger">
                <i class="fa fa-close"></i> Clear
              </a>
            </div>
            <!-- <div class="card-body row">
                    </div>
                    <div class="card-footer">


                    </div> -->
            <!-- </div> -->
          </div>
        </div>
      </form>
      <div class="p-sm-2">
        <!-- {{contraItems|json}} -->
      </div>

      <div class="card p-sm-0">
        <div class="card-body">

        <ngx-datatable [rows]="contraList" #myTable [loadingIndicator]="loadingIndicator" [externalPaging]="true"
          [columnMode]="'force'" [limit]="page.pageSize" [count]="page.count" [offset]="page.offset"
          (page)="getContrasList($event)" [externalSorting]="true" (sort)="onSort($event)" [footerHeight]="50"
          [rowHeight]="'auto'" class="material striped" [sorts]="this.dt_default_sort" [summaryRow]="'true'"
          [summaryPosition]="'bottom'" [summaryHeight]="'auto'">

          <ngx-datatable-column [width]="50" [resizeable]="false" [sortable]="false" [draggable]="false"
            [canAutoResize]="false">
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
              <a href="javascript:void(0)" [class.datatable-icon-right]="!expanded"
                [class.datatable-icon-down]="expanded" title="Expand/Collapse Row" (click)="toggleExpandRow(row)">
              </a>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-row-detail [rowHeight]="'auto'" #myDetailRow (toggle)="onDetailToggle($event)">
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
              <div style="padding-left:35px;">
                <table class="col-12 table table-hover table-vcenter table_custom text-nowrap spacing5 text-nowrap">
                  <thead>
                    <tr>
                      <th><strong>Account Head</strong></th>
                      <th><strong>Debit</strong></th>
                      <th><strong>Credit</strong></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let contra_items of row.contra_items">
                      <td>{{contra_items.account_head?.flattened_data?.name}}</td>
                      <td>{{contra_items.debit}}</td>
                      <td>{{contra_items.credit}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </ngx-datatable-row-detail>

          <ngx-datatable-column *ngFor="let col of contraListColumns" [flexGrow]=" col.flexGrow" [name]="col.name"
            [sortable]="col.sortable" [cellClass]="col.css_class" [prop]="col.prop">

            <ng-template ngx-datatable-header-template let-sort="sortFn">
              <span (click)="sort()">{{ col.name }}</span>
            </ng-template>
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
              <div [ngSwitch]="col.type">
                <span *ngSwitchCase="'currency'"> {{row[col.prop]|currency:currency}}</span>
                <span *ngSwitchCase="'percentage'">{{row[col.prop]}}%</span>
                <span *ngSwitchCase="'action'">

                  <div class="btn-group mr-3">
                    <a class="btn btn-primary" routerLink="/contra/edit/{{row._id}}" type="button" rel="tooltip">Edit</a>
                    <div class="btn-group" ngbDropdown placement="bottom-right" container="body" role="group">
                      <a class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></a>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <!-- <a class="dropdown-item" routerLink="/contra/edit/{{value}}" type="button"
                          rel="tooltip">Edit</a> -->
                        <a class="dropdown-item " (click)="deleteContra(row._id)">
                          <span class="text-red">
                            <i class="icon-trash-can3"></i> Delete
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>


                </span>
                <span *ngSwitchCase="'object'"> {{ row[col.prop][col.field_name] }}</span>
                <!-- <span *ngSwitchCase="'custom'"> {{ row[col.field_name] }}</span> -->
                <span *ngSwitchCase="'custom'"> {{ row[col.field_name] }}</span>
                <span *ngSwitchCase="'date'"> {{ row[col.prop]|date }}</span>

                <span *ngSwitchCase="'status'"> <span class="badge badge-success">{{ row[col.prop]}}</span></span>
                <span *ngSwitchDefault>
                  {{ row[col.prop] }}
                </span>
              </div>

            </ng-template>
          </ngx-datatable-column>

          <!-- <ngx-datatable-footer>
                            <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                                let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">


                                <div class="page-count">{{ (curPage * pageSize)-pageSize+1 }} - {{ curPage * pageSize }}
                                    of
                                    {{ rowCount }}
                                </div>
                                <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                    [pagerRightArrowIcon]="'datatable-icon-right'"
                                    [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                                    [page]="curPage" [size]="pageSize" [count]="rowCount"
                                    [hidden]="!((rowCount / pageSize) > 1)" (change)="table.onFooterPage($event)">
                                </datatable-pager>
                            </ng-template>

                        </ngx-datatable-footer> -->

        </ngx-datatable>
      </div>
      </div>
    </div>

  </div>
</div>
