import { MastersService } from './../../../_services/masters.service';
import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { TestcollectionService } from '../../../_services/testcollection.service';
import { ApiService } from '../../../_services/api.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
// import { ngfModule, ngf } from "angular-file"
@Component({
  selector: 'app-testcollection-add',
  templateUrl: './testcollection-add.component.html',
  styleUrls: ['./testcollection-add.component.scss']
})
export class TestcollectionAddComponent implements OnInit {
  id: string;
  is_edit_form: boolean = false;
  testcollection: any = {};
  files: File[] = [];
  input_file: File;
  countryList: any;
  selectList: any;
  tag_inputList: any;
  wysiwyg: any;
  addTagFn: any;
  stateList: any;
  model: any;
  valid_upto: any;
  currencyList: any = [];
  wysiwygEditorConfig: any = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  }; date_singleDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy'

  };
  date_rangeDpOptions: IAngularMyDpOptions = {
    dateRange: true,
    dateFormat: 'dd/mm/yyyy'

  }
  accept: any;
  maxSize: any;
  lastInvalids: any;
  lastFileAt: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private testcollectionservice: TestcollectionService,
    private apiService: ApiService,
    private countryservice: MastersService
  ) { }


  ngOnInit() {

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
      if (this.id != null) {
        this.getTestcollection();
      }

      var result = this.router.url.match('/edit/');
      if (result != null) {
        if (result[0] === '/edit/' && this.id != null) {
          this.is_edit_form = true;
        }
      }
    });
    this.countryservice.getCountries().subscribe((result) => {
      this.currencyList = result['response'];

    });
  }
  uploadFile() {
    let data: any = {};
    data['name'] = "test";
    this.testcollectionservice.uploadFiles(this.files, data).subscribe(result => {
      console.log('uploaded result', result);
    })
  }

  getDate() {
    return new Date()
  }

  //   this.getTestcollectionsList();

  ondate_singleDateChanged(event: IMyDateModel): void {
    // date selected
  }
  ondate_rangeDateChanged(event: IMyDateModel): void {
    // date selected
  }
  addTestcollection() {
    this.testcollectionservice.addTestcollection(this.testcollection).subscribe(result => {
      if (result['status_code'] === 1) {
        swal('Added testcollection sucessfully!', 'testcollection added.', 'success');
        this.router.navigateByUrl('/testcollection/list');
      } else {
        this.showErrors(result[' response '][' errors ']);

      }

    });
  }
  getTestcollection() {
    this.testcollectionservice.viewTestcollection(this.id).subscribe(result => {
      this.testcollection = result[' response '];
    });
  }

  addUpdateTestcollection() {
    if (this.is_edit_form === true) {
      this.editTestcollection();
    } else {
      this.addTestcollection();
    }
  }

  editTestcollection() {
    this.testcollectionservice.editTestcollection(this.testcollection, this.id).subscribe(result => {
      if (result[' response '] == 1) {
        swal(' Success ', ' Testcollection Updated Successfully ');
        this.router.navigateByUrl('/testcollection/list');
      } else {
        this.showErrors(result[' response '][' errors ']);
      }
    });
  }

  showErrors(errors) {
    let error_string: string;
    error_string = ' ';
    errors.forEach((item) => {
      error_string += ' < br / > ' + item;
    });
    swal({
      title: 'Error',
      html: error_string,
      type: 'error'
    }).then(function () {
    });
  }

}
