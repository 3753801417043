import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, RoutesRecognized } from "@angular/router";
import { AuthenticationService } from "../../_services/authentication.service";

@Component({
  selector: 'app-financial-years',
  templateUrl: './financial-years.component.html',
  styleUrls: ['./financial-years.component.scss']
})
export class FinancialYearsComponent implements OnInit {
  financial_year_list: any = [];
  @Input() financialYears: any = [];
  @Input() financialYearsConfig: any = [];
  @Input() class:string='form-group col-12';
  financial_year: any;
  company_details: any;
  financial_details: any = [];
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit() {
    console.log('MMMMMIIIIIIII', this.financialYears);
    this.company_details = this.authenticationService.getCompanyDetails();
    if(this.company_details.fi_year !=undefined && this.company_details.fi_year !=null){
      this.financial_year = this.company_details.fi_year ;
      console.log('this.financial_year======', this.financial_year);
    }
  }
  setUrl() {
    this.company_details = this.authenticationService.getCompanyDetails();

    this.company_details.fi_year = this.financial_year;
    this.financial_details = this.authenticationService.setDefaultCompany(this.company_details);
    let pathString = this.authenticationService.getLocalStorageItem('redirect_path');
    pathString = pathString ? pathString : '/dashboard';
    this.router.navigateByUrl(pathString).then(() => {
      location.reload();
    });
  }

}
