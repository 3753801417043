<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <form novalidate #searchBillForm="ngForm" (ngSubmit)="validateAndGetBillList()">
        <div class="card">
          <div class="card-body">
            <div class=" mr-3 mb-2 float-right">
              <button type="button" class="btn btn-primary float-right mb-4 ml-2" (click)="open(content)">Import
                Bill</button>
              <button  *ngIf="this.companyDetails.fi_year?.status!='Finalized'" class="btn btn-primary float-right mb-4" routerLink="/bill/add">
                <i class="fa fa-plus"></i> Purchase Invoice
              </button>
              <button [disabled]="isDisableBillPaymentBtn==true" type="button" (click)="openPaymentModal('','bulk')"
                class="btn btn-primary float-right mb-4 mr-2">
                Pay
              </button>
            </div>
            <h3 class="table-title"><i class="fa fa-file"></i> Purchase Invoices</h3>
            <div class="row">
              <div *ngIf="id==''" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label>Vendor Name</label>
                <input type="text" name="vendor_name" class="form-control" placeholder="Vendor Name"
                  [(ngModel)]="filters.vendor" />
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="vendor">Vendor</label>
                <ng-select (clear)="billsList=[]; isDisableBillPaymentBtn=true" [items]="vendor_lists"
                  [typeahead]="vendorListTypeahead" #vendor="ngModel" required name="vendor" bindLabel="vendor_name"
                  bindValue="_id" [(ngModel)]="filters.vendor_muid"
                  (change)="validateAndGetBillList(); isDisableBillPaymentBtn=false;" placeholder="Select Vendor">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.vendor_name }}
                    </div>
                  </ng-template>

                </ng-select>
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="bill_number">Bill Number</label>
                <ng-select [multiple]="true" [items]="billNumberList" [typeahead]="billNumberListTypeahead"
                  name="bill_number" bindLabel="bill_number" bindValue="bill_number" [(ngModel)]="filters.bill_number"
                  placeholder="Bill Number">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.bill_number }}
                    </div>
                  </ng-template>
                </ng-select>
                <!-- <input type="text" class="form-control" name="bill_number" id="bill_number" placeholder="Bill Number"
                  [(ngModel)]="filters.bill_number" /> -->
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="bill_status">Bill Status </label>
                <ng-select (change)="validateAndGetBillList()" [items]="bill_status" bindValue="bill_status_id"
                  bindLabel="bill_status_name" id="bill_status" required placeholder="Bill Status" name="bill_status"
                  [multiple]="true" [(ngModel)]="filters.bill_status">

                </ng-select>
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="bill_date">Bill Date</label>
                <div class="input-group">
                  <input class="form-control" name="bill_date" placeholder="Select a date" angular-mydatepicker
                    #dp_invoice="angular-mydatepicker" (click)="dp_invoice.toggleCalendar()"
                    [(ngModel)]="filters.bill_date" [options]="billDateOptions" />
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="filters.bill_date"
                      (click)="dp_invoice.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="dp_invoice.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="due_date">Due Date</label>
                <div class="input-group">
                  <input class="form-control" name="due_date" placeholder="Select Due Date" angular-mydatepicker
                    #de_invoice="angular-mydatepicker" (click)="de_invoice.toggleCalendar()"
                    [(ngModel)]="filters.due_date" [options]="dueDateOptions" />
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="filters.due_date"
                      (click)="de_invoice.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="de_invoice.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <button type="submit" class="btn btn-primary mr-2">
                <i class="fa fa-search"></i> Search
              </button>
              <button type="button" (click)="clearBillFilters()" class="btn btn-danger">
                <i class="fa fa-close"></i>
                Clear
              </button>
            </div>

          </div>
        </div>
      </form>
      <div *ngIf="billListType=='purchaseadvance'">
        <h5>Advance Amount:{{adv_advance_based_amount | currency: currency}}({{adv_total_amount| currency: currency}})
        </h5>
      </div>
      <div *ngIf="is_bill_payment == true && billsList.length == 0">
        <h6 style="text-align: center;"> Please Select the Vendor to view the Payment List. </h6>
      </div>
      <div class="card p-sm-0" *ngIf="(filters.vendor_muid && is_bill_payment==true) || is_bill_payment==false">
        <ngx-datatable [rows]="billsList" [loadingIndicator]="loadingIndicator" [externalPaging]="true"
          [columnMode]="'flex'" [count]="page.count" [limit]="page.pageSize" [offset]="page.offset"
          (page)="getBillsList($event)" [externalSorting]="true" (sort)="onSort($event)" [footerHeight]="50"
          [rowHeight]="'auto'" class="material striped" [sorts]="this.dt_default_sort" [selectionType]="'checkbox'"
          (select)="onSelectOrders($event)" [summaryRow]="'true'" [summaryPosition]="'bottom'" [summaryHeight]="'auto'">
          <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false" [draggable]="false"
            [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="dispCheckBox">
          </ngx-datatable-column>
          <ngx-datatable-column *ngFor="
              let col of billsListColumns | filter: 'is_detailed_row':false
            " [flexGrow]="col.flexGrow" [name]="col.name" [sortable]="col.sortable" [cellClass]="col.css_class"
            [canAutoResize]="true" [prop]="col.prop">
            <ng-template ngx-datatable-header-template let-sort="sortFn">
              <span (click)="sort()">{{ col.name }}</span>
            </ng-template>
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
              <div [ngSwitch]="col.type">
                <span *ngSwitchCase="'currency'">{{
                  row[col.prop] | currency: currency
                  }}</span>
                <span *ngSwitchCase="'percentage'">{{ row[col.prop] }}%</span>
                <span *ngSwitchCase="'action'">
                  <div class="btn-group mr-3" *ngIf="row.bill_status_name =='Draft'">
                    <a *ngIf="user_id!=8" class=" btn btn-primary" name="send{{ i }}" type="button" (click)="sendBill(row)"
                      rel="tooltip">Confirm</a>
                      <a  *ngIf="user_id==8" class=" btn btn-primary" (click)="open1(purchaseInvoiceViewContent, row._id)">
                        <span class="text-blue">View
                        </span>
                      </a>
                    <div class="btn-group" ngbDropdown placement="bottom-right" container="body" role="group">
                      <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <button class="dropdown-item" routerLink="/bill/edit/{{ row._id }}" type="button" rel="tooltip">
                          Edit
                        </button>
                        <button *ngIf="user_id!=8"  class="dropdown-item " (click)="deleteBill(value)">
                          <span class="text-red">
                            <i class="icon-trash-can3"></i> Cancel
                          </span>
                        </button>
                        <a  *ngIf="user_id!=8" class="dropdown-item " (click)="open1(purchaseInvoiceViewContent, row._id)">
                          <span class="text-blue">
                            <i class="icon-fa-eye"></i> View
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="btn-group mr-3" *ngIf="row.bill_status_name =='paid'">
                    <a class="btn btn-primary" (click)="open1(purchaseInvoiceViewContent, row._id)">
                      View
                    </a>
                    <div class="btn-group" ngbDropdown placement="bottom-right" container="body" role="group">
                      <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <button class="dropdown-item" routerLink="/" type="button" rel="tooltip">
                          Print With DC
                        </button>

                        <button class="dropdown-item" routerLink="/" type="button" rel="tooltip">
                          Print Without DC
                        </button>
                      </div>
                    </div>

                  </div>

                  <div class="btn-group mr-3" *ngIf="row.bill_status_name !='Draft' && row.bill_status_name !='paid'  && row.bill_status_name
                    !='cancelled'">
                    <a class=" btn btn-primary" name="pay{{ i }}" (click)="openPaymentModal(row,'single')" type="button"
                      rel="tooltip">Pay</a>

                    <div class="btn-group" ngbDropdown placement="bottom-right" container="body" role="group">
                      <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <!-- <a class="dropdown-item" name="pdf{{ i }}" href="{{image_path}}" target="_blank"
                          rel="tooltip"><span class="text-blue">Export as Pdf </span></a> -->
                        <a  *ngIf="user_id!=8" class="dropdown-item" routerLink="/bill/credit-note/{{ row._id }}" type="button"
                          rel="tooltip">
                          Debit Note
                        </a>

                        <a class="dropdown-item " (click)="open1(purchaseInvoiceViewContent, row._id)">
                          <span class="text-blue">
                            <i class="icon-fa-eye"></i> View
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </span>
                <span *ngSwitchCase="'object'">
                  {{ row[col.prop][col.field_name] }}</span>
                <span *ngSwitchCase="'date'"> {{ row[col.prop] | date }}</span>

                <div *ngSwitchCase="'status'">
                  <span *ngIf="row[col.prop]=='Draft'" class="badge badge-default">{{row[col.prop]}}</span>
                  <span *ngIf="row[col.prop]=='unpaid'" class="badge badge-danger">{{row[col.prop]}}</span>
                  <span *ngIf="row[col.prop]=='partially_paid'&& row[col.prop]!='Draft'"
                    class="badge badge-warning">{{row[col.prop]}}</span>
                  <span *ngIf="row[col.prop]=='paid' && row[col.prop]!='Draft'"
                    class="badge badge-success">{{row[col.prop]}}</span>
                  <span *ngIf="row[col.prop]=='cancelled'&&row[col.prop] !='Draft'"
                    class="badge badge-danger">{{row[col.prop]}}</span>
                </div>
                <span *ngSwitchDefault>
                  {{ row[col.prop] }}
                </span>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <!-- <ngx-datatable-footer>
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-selectedCount="selectedCount"
              let-curPage="curPage"
              let-offset="offset"
            >
              <div class="page-count">
                {{ curPage * pageSize - pageSize + 1 }} -
                {{ curPage * pageSize }} of
                {{ rowCount }}
              </div>
              <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
                [hidden]="!(rowCount / pageSize > 1)" (change)="table.onFooterPage($event)">
              </datatable-pager>
            </ng-template>
          </ngx-datatable-footer> -->
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>
<div *ngIf="billListType=='purchaseadvance'"><button type="button" (click)="mapBills()" class="btn btn-primary"
    name="map">Map</button></div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Upload File</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>File Upload </label>
    <div ngfDrop multiple="1" selectable="1" [(validDrag)]="validComboDrag" [(files)]="files"
      [(lastInvalids)]="lastInvalids" class="well my-drop-zone" accept="image/*,application/pdf"
      [class.invalid-drag]="validComboDrag===false" [class.valid-drag]="validComboDrag"
      (filesChange)="lastFileAt=getDate()">
      Drop/select <strong>Image/Pdf</strong> here... (PNG, JPEG, PDF)
    </div>
    <div class="bg-warning" *ngIf="lastInvalids?.length" style="margin-bottom: 40px">
      <h3 style="color:red;">Last {{ lastInvalids.length }} Invalid Selected Files</h3>

      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Error</th>
            <th>Type</th>
            <th>Size</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of lastInvalids;let i=index">
            <td>
              <!-- <div *ngIf="['image/gif','image/png','image/jpeg'].indexOf(item.file.type)>=0">
                <div class="previewIcon" [ngfBackground]="item.File"></div>
              </div> -->
              <strong>{{ item.file.name }}</strong>
            </td>
            <td nowrap>
              {{ item.type }}
            </td>
            <td nowrap>
              {{ item.file.type }}
            </td>
            <td nowrap>
              {{ item.file.size/1024/1024 | number:'.2' }} MB
            </td>
            <td nowrap>
              <button type="button" class="btn btn-danger btn-xs" (click)="lastInvalids.splice(i,1)">
                <span class="glyphicon glyphicon-trash"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style="margin-bottom: 40px" class="mt-2">
      <h5>{{ files?.length }} Queued Files</h5>
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Size</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of files;let i=index">
            <td>
              <!-- <div *ngIf="['application/pdf','image/png','image/jpeg'].indexOf(item.type)>=0">
                <div class="previewIcon" [ngfBackground]="item"></div>
              </div> -->
              <strong>{{ item.name }}</strong>
            </td>
            <td nowrap>
              {{ item.type }}
            </td>
            <td nowrap>
              {{ item.size/1024/1024 | number:'.2' }} MB
            </td>
            <td nowrap>
              <button type="button" class="btn btn-danger btn-xs" (click)="files.splice(i,1)">
                <span class="fa fa-trash"></span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- <ngfUploadStatus [(percent)]="progress" [httpEvent]="httpEvent"></ngfUploadStatus> -->
    <!-- Queue progress: -->
    <!-- <div class="progress" style="">
        <div class="progress-bar"
          role="progressbar"
          [ngStyle]="{ 'width': progress + '%' }"
        ></div>
      </div> -->


  </div>
  <div class="modal-footer">
    <button type="button" [disabled]="files.length == 0" class="btn btn-outline-dark"
      (click)="uploadFile()">Save</button>
  </div>
</ng-template>

<ng-template #purchaseInvoiceViewContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Purchase Invoice View</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
       <app-invoice-view [id]="id" [moduleName]="'purchase_invoice'"></app-invoice-view>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
