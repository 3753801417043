import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { AuthenticationService } from "./authentication.service";
@Injectable({
  providedIn: "root",
})
export class FixedAssestsService {
  branch_id: string;
  constructor(
    private http: ApiService,
    private authenticationService: AuthenticationService
  ) {}

  getFixedAssests(
    filters: any = {},
    columns?: any,
    sort: any = {},
    page: any = {}
  ) {
    if (this.branch_id != "") {
      filters["branch_id"] = this.branch_id;
    }
    console.log('columns======', columns);
    return this.http.post(ApiService.geturl("fixed_assests", "viewall"), {
      filters: filters,
      columns: columns,
      sort: sort,
      page: page,
    }); 
  }

  addFixedAssests(fixedassests_data: any) {
    if (this.branch_id != "") {
      fixedassests_data["branch_id"] = this.branch_id;
    }
    return this.http.post(
      ApiService.geturl("fixed_assests", "add"),
      fixedassests_data
    );
  }

  editFixedassests(fixedassests_data: any, id: string) {
    console.log("fixedassests_data====", fixedassests_data);
    return this.http.post(ApiService.geturl("fixed_assests", "edit"), {
      data: fixedassests_data,
      fixedassests_id: id,
    });
  }

  viewFixedassests(id: string, condition: {}) {
    if (this.branch_id != "") {
      condition["branch_id"] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("fixed_assests", "view"), {
      fixedassests_id: id,
      condition: condition,
    });
  }
}
