import { Component, OnInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
import swal from 'sweetalert2';
import { CostgroupService } from '../../../_services/costgroup.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CostCenter, Costgroup } from '../../../_models/costgroup';
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";



@Component({
  selector: 'app-costgroup-add',
  templateUrl: './costgroup-add.component.html',
  //  styleUrls: ['./costgroup-add.component.scss']
})
export class CostgroupAddComponent implements OnInit {
  loading : boolean = false;
  id: string;
  is_edit_form: boolean = false;
  costgroup: Costgroup = new Costgroup();
  underCostCenterList: any = [];
  costgroupitemsArray: any = [];
  company_id: any;
  underCostCenterListTypeahead = new EventEmitter<string>();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private costgroupservice: CostgroupService,
    private authenticationService: AuthenticationService,
    private cd: ChangeDetectorRef

  ) { }


  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
      if (this.id != null) {
        this.getCostgroup("");
      }

      var result = this.router.url.match('/edit/');
      if (result != null) {
        if (result[0] === '/edit/' && this.id != null) {
          this.is_edit_form = true;
        }
      }
    });
    this.addNewItem(0);
    this.getCostgroup("").subscribe(result => {
      this.underCostCenterList = result['data'];
      console.log('=====>>>>', this.underCostCenterList);
    });
    this.getCostgroupSearch();


  }


  //   this.getCostgroupsList();


  addCostgroup() {
    this.loading = true;
    this.costgroup.company_id = this.authenticationService.getDefaultCompanyId();
    this.costgroup.cost_center = this.costgroupitemsArray;
    this.costgroupservice.addCostgroup(this.costgroup).subscribe(result => {
      if (result['status_code'] === 1) {
        this.loading = false;
        swal('Added Cost Center sucessfully!', 'Cost Center added.', 'success');
        this.router.navigateByUrl('/costgroup/list');
      } else {
        this.showErrors(result[' response '][' errors ']);

      }

    });
  }
  // getCostgroup() {
  //   this.costgroupservice.viewCostgroup(this.id).subscribe(result => {
  //     this.costgroup = result[' response '];
  //   });
  // }
  getCostgroup(searchString) {
    this.underCostCenterList = [];
    let condition: any = {};
    let columns: any = {};
    condition["company_id"] = this.company_id;
    columns["under_cc_category"] = 1; 
    columns["costcategory_name"] = 1; 
    return this.costgroupservice.getCostgroups(condition, columns);
  }
  getCostgroupSearch() {
    this.underCostCenterListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(searchString => this.getCostgroup(searchString))
      )
      .subscribe(
        result => {
          this.cd.markForCheck();
          this.underCostCenterList = result['data'];
        },
        err => {
          console.log(err);
          this.underCostCenterList = [];
        }
      );
  }

  addUpdateCostgroup() {
    if (this.is_edit_form === true) {
      this.editCostgroup();
    } else {
      this.addCostgroup();
    }
  }

  editCostgroup() {
    this.loading = true;
    this.costgroupservice.editCostgroup(this.costgroup, this.id).subscribe(result => {
      if (result[' response '] == 1) {
        this.loading = false;
        swal(' Success ', ' Cost Center Updated Successfully ');
        this.router.navigateByUrl('/costgroup/list');
      } else {
        this.showErrors(result[' response '][' errors ']);
      }
    });
  }

  showErrors(errors) {
    let error_string: string;
    error_string = ' ';
    errors.forEach((item) => {
      error_string += ' < br / > ' + item;
    });
    swal({
      title: 'Error',
      html: error_string,
      type: 'error'
    }).then(function () {
    });
  }

  addNewItem(index) {
    let cost_center: CostCenter = new CostCenter()
    this.costgroupitemsArray.splice(index, 0, cost_center);
  }
  deleteItem(index) {
    this.costgroupitemsArray.splice(index, 1);
  }
}
