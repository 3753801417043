<div class="card">
  <div class="card-body">
    <form class="form-horizontal" (ngSubmit)="addUpdateCustomer()" novalidate #saveCustomer="ngForm">
      <div class="row clearfix">
        <div class="d-flex justify-content-between align-items-center">
          <ul class="nav nav-tabs page-header-tab">
            <li class="nav-item"><a class="nav-link" id="customer" data-toggle="tab"
                [ngClass]="{'active':navTab == 'customer'}" (click)="navTab='customer'"
                href="javascript:void(0);">Customer</a>
            </li>

            <li class="nav-item"><a class="nav-link" id="statutory" data-toggle="tab"
                [ngClass]="{'active':navTab == 'statutory'}" (click)="navTab='statutory'"
                href="javascript:void(0);">Statutory
                Info</a>
            </li>
            <li class="nav-item"><a class="nav-link" [ngClass]="{'active':navTab == 'contacts'}"
                (click)="navTab='contacts'" id="Project-tab" data-toggle="tab" href="javascript:void(0);">Contacts</a>
            </li>
            <li *ngIf="is_edit_form==true" class="nav-item"><a class="nav-link"
                [ngClass]="{'active':navTab == 'contact_address'}" (click)="navTab='contact_address'" id="Project-tab"
                data-toggle="tab" href="javascript:void(0);">Contact Address</a>
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-pane fade" [ngClass]="navTab == 'customer' ? 'show active':''" *ngIf="navTab=='customer'"
        id="customer" role="tabpanel">
        <div class="form-row ">
          <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
            <label>Customer Name</label>
            <div>
              <input type="text" name="customer_name" required #customer_name="ngModel" class="form-control"
                placeholder="Customer Name" [(ngModel)]="customer.customer_name">
              <app-validation-errors [mformField]="customer_name" label="Customer Name"></app-validation-errors>
            </div>
          </div>

          <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="title">Email</label>
            <input type="text" class="form-control" #email="ngModel" required placeholder="Email" name="email"
              id="email" [(ngModel)]="customer.email" email>
            <app-validation-errors [mformField]="email" label="Email"></app-validation-errors>
          </div>
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="first_name">Name Of Contact</label>
            <input type="text" class="form-control" #first_name="ngModel" name="first_name"
              placeholder="First Name Of Contact" id="first_name_of_contact" [(ngModel)]="customer.first_name">
          </div>
          <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="last_name">Last Name Of Contact</label>
            <input type="text" class="form-control" placeholder="Last Name Of Contact" name="last_name" id="last_name"
              [(ngModel)]="customer.last_name">
          </div> -->

          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="phone">Phone</label>
            <input maxlength="13" type="text" class="form-control" name="phone" id="phone" placeholder="phone"
              [(ngModel)]="customer.phone">
          </div>

          <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="mobile">Mobile</label>
            <input maxlength="13" type="text" class="form-control" #mobile="ngModel" required name="mobile" id="mobile"
              placeholder="Mobile" [(ngModel)]="customer.mobile" number>
            <app-validation-errors [mformField]="mobile" label="Mobile"></app-validation-errors>
          </div>
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="gst_number">GST Number</label>
            <input type="text" maxlength="15" class="form-control" name="gst_number" id="gst_number"
              placeholder="GST Number" [(ngModel)]="customer.gst_number">
          </div>
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 custom-switch pl-5">
            <input type="checkbox" value="true" [(ngModel)]="customer.is_sez" name="is_sez" class="custom-control-input"
              id="is_sez">
            <label class="custom-control-label" for="is_sez">Do you have SEZ ?</label>
          </div>
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="address_line1">Address</label>
            <input type="text" maxlength="200" class="form-control" #address1="ngModel" name="address_line1"
              id="address_line1" placeholder="Address" [(ngModel)]="customer.address_line1">
          </div>
          <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="country">Country</label>
            <ng-select [items]="countryList" required #country="ngModel" [typeahead]="countryListTypeahead" name="country"
              bindLabel="name" (change)="getStateByCountry()" [(ngModel)]="customer.country"
              placeholder="Select Country">
              <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div [ngOptionHighlight]="search">
                  {{ item.name }}
                </div>
              </ng-template>
            </ng-select>

          </div>
          <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="state">Province/State</label>
            <select type="text" class="form-control" #state="ngModel" required name="state" id="state"
              placeholder="State" [(ngModel)]="customer.state">
              <option *ngFor="let item of stateList| keyvalue" [ngValue]="item.value">{{item.value.name}} </option>
            </select>
            <app-validation-errors [mformField]="state" label="State"></app-validation-errors>
          </div>
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="city">City</label>
            <input type="text" maxlength="100" class="form-control" #city="ngModel" name="city" id="city"
              placeholder="City" [(ngModel)]="customer.city">
          </div>
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="postal_code">Zip/Postal Code</label>
            <input type="text" class="form-control" #postal_code="ngModel" name="postal_code" id="postal_code"
              (change)="appendBillShipAddress()" placeholder="Zip/Postal Code" [(ngModel)]="customer.postal_code" number
              maxlength="6">
          </div>
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="currency">Currency</label>
            <ng-select [items]="currency_list" #currency="ngModel" name="currency" bindLabel="name"
              [(ngModel)]="customer.currency" placeholder="Select Currency">
              <!-- <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div [ngOptionHighlight]="search">
                  {{ item.name }}
                </div>
              </ng-template> -->
            </ng-select>
          </div>
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="valid_upto">Payment Term</label>
            <select class="form-control" #payment_term="ngModel" id="valid_upto" name="valid_upto"
              [(ngModel)]="customer.payment_term">
              <option value="2" selected="selected">Valid Upto 2 days</option>
              <option value="3">Valid Upto 3 days</option>
              <option value="4">Valid Upto 4 days</option>
              <option value="5">Valid Upto 5 days</option>
              <option value="10">Valid Upto 10 days</option>
              <option value="15">Valid Upto 15 days</option>
              <option value="25">Valid Upto 25 days</option>
              <option value="30">Valid Upto 30 days</option>
              <option value="40">Valid Upto 40 days</option>
              <option value="50">Valid Upto 50 days</option>
              <option value="60">Valid Upto 60 days</option>
              <option value="70">Valid Upto 70 days</option>
              <option value="80">Valid Upto 80 days</option>
              <option value="90">Valid Upto 90 days</option>
            </select>
          </div>
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="billing_address">Billing Address</label>
            <textarea class="form-control" #billing_address="ngModel" maxlength="200" name="billing_address"
              id="billing_address" placeholder="Billing Address" [(ngModel)]="customer.billing_address"></textarea>
          </div>
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="shipping_address">Shipping Address</label>
            <textarea class="form-control" #shipping_address="ngModel" name="shipping_address" id="shipping_address"
              placeholder="Shipping Address" maxlength="200" [(ngModel)]="customer.shipping_address"></textarea>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" [ngClass]="navTab == 'statutory' ? 'show active':''" *ngIf="navTab=='statutory'"
        id="statutory" role="tabpanel">
        <div class="form-row">
          <div class="form-group col-12 col-sm-6 col-md-6 col-xl-6">
            <label for="gst">GST</label>
            <input maxlength="15" type="text" name="gst" class="form-control" [(ngModel)]="customer.gst_number">
          </div>
          <div class="form-group col-12 col-sm-6 col-md-6 col-xl-6 ">
            <label for="gst_effective_date">GST Effective Date</label>
            <div class="input-group">
              <input class="form-control" name="gst_effective_date" #gst_effective_date="ngModel"
                placeholder="Select a date" angular-mydatepicker #dp_gst_effective_date="angular-mydatepicker"
                [(ngModel)]="customer.gst_effective_date" [options]="gstEffectiveDateOptions" />
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp_gst_effective_date.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp_gst_effective_date.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>
            </div>
            <app-validation-errors [mformField]="gst_effective_date" label="GST Effective Date">
            </app-validation-errors>
          </div>

          <div class="form-group col-12 col-sm-6 col-md-6 col-xl-6">
            <label for="tds">TAN</label>
            <input type="text" name="tds" class="form-control" [(ngModel)]="customer.tds">
          </div>
          <div class="form-group  col-12 col-sm-6 col-md-6 col-xl-6">
            <label for="tds_effective_date">TAN Effective Date</label>
            <div class="input-group">
              <input class="form-control" name="tds_effective_date" #tds_effective_date="ngModel"
                placeholder="Select a date" angular-mydatepicker #dp_tds_effective_date="angular-mydatepicker"
                [(ngModel)]="customer.tds_effective_date" [options]="tdsEffectiveDateOptions" />
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp_tds_effective_date.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp_tds_effective_date.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>
            </div>
            <app-validation-errors [mformField]="tds_effective_date" label="TDS Effective Date">
            </app-validation-errors>
          </div>
          <!-- <div class="form-group col-12 col-sm-6 col-md-6 col-xl-6">
              <label for="cin">CIN</label>
              <input type="text" name="cin" class="form-control" [(ngModel)]="vendor.cin">
            </div>
            <div class="form-group required col-12 col-sm-6 col-md-6 col-xl-6">
              <label for="cin_effective_date">CIN Effective Date</label>
              <div class="input-group">
                <input class="form-control" name="cin_effective_date" #cin_effective_date="ngModel" required
                  placeholder="Select a date" angular-mydatepicker #dp_cin_effective_date="angular-mydatepicker"
                  [(ngModel)]="vendor.cin_effective_date" [options]="cinEffectiveDateOptions" />
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" (click)="dp_cin_effective_date.clearDate()">
                    <i class="fa fa-close"></i>
                  </button>
                </div>
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" (click)="dp_cin_effective_date.toggleCalendar()">
                    <i class="fa fa-calendar-o"></i>
                  </button>
                </div>
              </div>
              <app-validation-errors [mformField]="cin_effective_date" label="CIN Effective Date">
              </app-validation-errors>
            </div> -->

          <!-- <div class="form-group col-12 col-sm-6 col-md-6 col-xl-6">
              <label for="pf">PF</label>
              <input type="text" name="pf" class="form-control" [(ngModel)]="vendor.pf">
            </div>
            <div class="form-group required col-12 col-sm-6 col-md-6 col-xl-6">
              <label for="pf_effective_date">PF Effective Date</label>
              <div class="input-group">
                <input class="form-control" name="pf_effective_date" #pf_effective_date="ngModel" required
                  placeholder="Select a date" angular-mydatepicker #dp_pf_effective_date="angular-mydatepicker"
                  [(ngModel)]="vendor.pf_effective_date" [options]="pfEffectiveDateOptions" />
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" (click)="dp_pf_effective_date.clearDate()">
                    <i class="fa fa-close"></i>
                  </button>
                </div>
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" (click)="dp_pf_effective_date.toggleCalendar()">
                    <i class="fa fa-calendar-o"></i>
                  </button>
                </div>
              </div>
              <app-validation-errors [mformField]="pf_effective_date" label="PF Effective Date">
              </app-validation-errors>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-6 col-xl-6">
              <label for="esi">ESI</label>
              <input type="text" name="esi" class="form-control" [(ngModel)]="vendor.esi">
            </div>
            <div class="form-group required col-12 col-sm-6 col-md-6 col-xl-6">
              <label for="esi_effective_date">ESI Effective Date</label>
              <div class="input-group">
                <input class="form-control" name="esi_effective_date" #esi_effective_date="ngModel" required
                  placeholder="Select a date" angular-mydatepicker #dp_esi_effective_date="angular-mydatepicker"
                  [(ngModel)]="vendor.esi_effective_date" [options]="esiEffectiveDateOptions" />
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" (click)="dp_esi_effective_date.clearDate()">
                    <i class="fa fa-close"></i>
                  </button>
                </div>
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" (click)="dp_esi_effective_date.toggleCalendar()">
                    <i class="fa fa-calendar-o"></i>
                  </button>
                </div>
              </div>
              <app-validation-errors [mformField]="esi_effective_date" label="ESI Effective Date">
              </app-validation-errors>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-6 col-xl-6">
              <label for="lut">LUT</label>
              <input type="text" name="lut" class="form-control" [(ngModel)]="vendor.lut">
            </div>
            <div class="form-group required col-12 col-sm-6 col-md-6 col-xl-6">
              <label for="lut_effective_date">LUT Effective Date</label>
              <div class="input-group">
                <input class="form-control" name="lut_effective_date" #lut_effective_date="ngModel" required
                  placeholder="Select a date" angular-mydatepicker #dp_lut_effective_date="angular-mydatepicker"
                  [(ngModel)]="vendor.lut_effective_date" [options]="lutEffectiveDateOptions" />
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" (click)="dp_lut_effective_date.clearDate()">
                    <i class="fa fa-close"></i>
                  </button>
                </div>
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" (click)="dp_lut_effective_date.toggleCalendar()">
                    <i class="fa fa-calendar-o"></i>
                  </button>
                </div>
              </div>
              <app-validation-errors [mformField]="lut_effective_date" label="LUT Effective Date">
              </app-validation-errors>
            </div> -->
          <div class="form-group col-12 col-sm-6 col-md-6 col-xl-6">
            <label for="pan">PAN</label>
            <input type="text" maxlength="10" name="pan" id="pan" class="form-control" [(ngModel)]="customer.pan">
          </div>
          <!-- <div *ngIf="company_settings.is_tds_filing==true" class="form-group col-12 col-sm-6 col-md-6 col-xl-6">
                <label for="tan">TAN</label>
                <input type="text" name="tan" id="tan" class="form-control" [(ngModel)]="company_settings.tan">
              </div> -->
        </div>
      </div>
      <div class="tab-pane fade" [ngClass]="navTab == 'contacts' ? 'show active':''" *ngIf="navTab=='contacts'"
        id="contacts" role="tabpanel">
        <!-- <h4>Provide Contact Details </h4> -->

        <div class="form-row " *ngFor="let contactsObj of customer.contacts; let i = index">
          <div class="form-group required col-12 col-sm-6 col-md-6 col-xl-3">
            <label for="">Contact Name</label>
            <input type="text" maxlength="100" class="form-control" placeholder="Contact Name" #contact_name="ngModel"
              required id="example{{i}}" name="example{{i}}" [(ngModel)]="contactsObj.contact_name">
            <app-validation-errors [mformField]="contact_name" label="Contact Name"></app-validation-errors>
          </div>
          <div class="form-group required col-12 col-sm-6 col-md-6 col-xl-2">
            <label for="">Phone </label>
            <input type="text" class="form-control" maxlength="13" required #phone_number="ngModel"
              placeholder="Phone Number" id="contactsObj.phone_number{{i}}" name="contactsObj.phone_number{{i}}"
              [(ngModel)]="contactsObj.phone_number" number>
            <app-validation-errors [mformField]="phone_number" label="Phone Number">
            </app-validation-errors>
          </div>
          <div class="form-group required col-12 col-sm-6 col-md-6 col-xl-3">
            <label for="">Email</label>
            <input type="text" class="form-control" required #email="ngModel" placeholder="Email"
              id="contactsObj.email{{i}}" name="contactsObj.email{{i}}" [(ngModel)]="contactsObj.email" email>
            <app-validation-errors [mformField]="email" label="Email"></app-validation-errors>
          </div>
          <div class="form-group col-12 col-sm-6 col-md-6 col-xl-2">
            <label for="">Designation</label>
            <input type="text" maxlength="100" class="form-control" placeholder="Designation" id="designation{{i}}"
              name="contactsObj.designation{{i}}" [(ngModel)]="contactsObj.designation">
          </div>
          <div class="form-group col-md-2">
            <button class="btn btn-primary mr-2 mt-4" type="button" (click)="addContactRow()">
              <i class="fa fa-plus"></i>
            </button>
            <button *ngIf="i!=0" class="btn btn-danger mt-4" type="button" (click)="deleteContactRow(i)">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" [ngClass]="navTab == 'contact_address' ? 'show active':''"
        *ngIf="navTab=='contact_address'" id="contact_address" role="tabpanel">
        <div class="col-12">
          <app-address-template [addressConfig]="addressListConfig"></app-address-template>
        </div>
        <!-- <button class="btn btn-primary mb-4" (click)="showUpdateAddressDialog()">Add New Address</button> -->
      </div>
      <button type="submit" class="btn btn-primary" [disabled]="!saveCustomer.valid || loading==true">Save
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
      </button>
    </form>
  </div>
</div>
