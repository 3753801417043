export class SalesColumnSettings {
  payment_terms: string;
  title: string;
  sub_heading: string;
  footer: string;
  sales_order_prefix: string;
  sales_order_number: number;
  sales_order_suffix: string;
  purchase_order_prefix: string;
  purchase_order_number: number;
  purchase_order_suffix: string;
  items_display: boolean = true;
  desc_display: boolean = true;
  units_display: boolean = true;
  price_display: boolean = true;
  amount_display: boolean = true;
  hsn_display: boolean = true;
  hsn: string;
  delivery_note_display: boolean = false;
  suppliers_ref_display: boolean = false;
  others_ref_display: boolean = false;
  despatched_through_display: boolean = false;
  destination_display: boolean = false;
  discount_display: boolean = false;
  motor_vehicle_no_display: boolean = false;
  eway_bill_no_display: boolean = false;
  eway_bill_date_display: boolean = false;
  items: string;
  description: string;
  units: string;
  price: string;
  amount: number;
  delivery_note: string;
  suppliers_ref: string;
  others_ref: string;
  despatched_through: string;
  destination: string;
  discount: string;
  motor_vehicle_no: number;
  eway_bill_no: number;
  eway_bill_date: string;
  invoice_order_prefix: string;
  invoice_order_number: number;
  invoice_order_suffix: string;
  ordernumber_length: any;
  invoice_ordernumber_length: any;
  voucher: any = [Voucher];
  template_color: string = '#487774';

}

export class Voucher {
  voucher_type: string;
  invoice_order_prefix: string;
  invoice_order_number: number;
  invoice_order_suffix: string;
  invoice_ordernumber_length: number;
}
