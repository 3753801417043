<div class="container-fluid">
        <div class="row">
                <div class="col-lg-12">
                        <div class="card">
                                <div class="card-header d-flex align-items-center">
                                        <h3 class="table-title">Testcollection Add</h3>
                                </div>
                                <form novalidate #addTestcollectionForm="ngForm" (ngSubmit)="addTestcollection()">
                                        <div class="card-body row">
                                                <div class="col-4">
                                                        <div class="form-group    ">
                                                                <label class="col-form-label ">Customer Name</label>
                                                                <div class="">
                                                                        <input type="text"  class="form-control"
                                                                                name="customer" #customer="ngModel"
                                                                                id="customer"
                                                                                [(ngModel)]="testcollection.customer"
                                                                                placeholder="">
                                                                        <app-validation-errors [mformField]="customer"
                                                                                label="Customer Name">
                                                                        </app-validation-errors>
                                                                </div>
                                                        </div>
                                                        <div class="form-group    ">
                                                                <label class="col-form-label ">Email</label>
                                                                <div class="">
                                                                        <input type="email" 
                                                                                class="form-control" name="email"
                                                                                #email="ngModel" id="email"
                                                                                [(ngModel)]="testcollection.email"
                                                                                placeholder="">
                                                                        <app-validation-errors [mformField]="email"
                                                                                label="Email">
                                                                        </app-validation-errors>
                                                                </div>
                                                        </div>
                                                        <div class="form-group    ">
                                                                <label class="col-form-label ">First Name Of
                                                                        Contact</label>
                                                                <div class="">
                                                                        <input type="text"  class="form-control"
                                                                                name="first_name" #first_name="ngModel"
                                                                                id="first_name"
                                                                                [(ngModel)]="testcollection.first_name"
                                                                                placeholder="">
                                                                        <app-validation-errors [mformField]="first_name"
                                                                                label="First Name Of Contact">
                                                                        </app-validation-errors>
                                                                </div>
                                                        </div>
                                                        <div class="form-group  ">
                                                                <label class="col-form-label ">Last Name Of
                                                                        Contact</label>
                                                                <div class="">
                                                                        <input type="text" class="form-control"
                                                                                name="last_name" #last_name="ngModel"
                                                                                id="last_name"
                                                                                [(ngModel)]="testcollection.last_name"
                                                                                placeholder="">
                                                                        <app-validation-errors [mformField]="last_name"
                                                                                label="Last Name Of Contact">
                                                                        </app-validation-errors>
                                                                </div>
                                                        </div>
                                                        <div class="form-group  ">
                                                                <label class="col-form-label ">Country</label>
                                                                <div class="">
                                                                        <ng-select [items]="countryList" name="country"
                                                                                #country="ngModel"
                                                                                [(ngModel)]="testcollection.country"
                                                                                class="">
                                                                                <ng-template ng-label-tmp
                                                                                        let-item="item">
                                                                                        *
                                                                                </ng-template>
                                                                                <ng-template ng-option-tmp
                                                                                        let-item="item"
                                                                                        let-index="index"
                                                                                        let-search="searchTerm">
                                                                                        *
                                                                                </ng-template>
                                                                                <ng-template ng-footer-tmp>
                                                                                        <button type="button"
                                                                                                class="btn btn-primary"><i
                                                                                                        class="fa fa-plus"></i>Add
                                                                                                Country</button>
                                                                                </ng-template>
                                                                        </ng-select>

                                                                        <app-validation-errors [mformField]="country"
                                                                                label="Country">
                                                                        </app-validation-errors>
                                                                </div>
                                                        </div>
                                                        <div class="form-group  ">
                                                                <label class="col-form-label ">Text Area</label>
                                                                <div class="">
                                                                </div>
                                                        </div>
                                                </div>
                                                <div class="col-4">
                                                        <div class="form-group  ">
                                                                <label class="col-form-label ">Select</label>
                                                                <div class="">
                                                                        <ng-select [items]="selectList" name="select"
                                                                                #select="ngModel"
                                                                                [(ngModel)]="testcollection.select"
                                                                                class="">
                                                                                <ng-template ng-label-tmp
                                                                                        let-item="item">
                                                                                        *
                                                                                </ng-template>
                                                                                <ng-template ng-option-tmp
                                                                                        let-item="item"
                                                                                        let-index="index"
                                                                                        let-search="searchTerm">
                                                                                        *
                                                                                </ng-template>
                                                                                <ng-template ng-footer-tmp>
                                                                                        <button type="button"
                                                                                                class="btn btn-primary"><i
                                                                                                        class="fa fa-plus"></i>Add
                                                                                                Select</button>
                                                                                </ng-template>
                                                                        </ng-select>

                                                                        <app-validation-errors [mformField]="select"
                                                                                label="Select">
                                                                        </app-validation-errors>
                                                                </div>
                                                        </div>
                                                        <div class="form-group  ">
                                                                <label class="col-form-label ">WYSIWYG</label>
                                                                <div class="">
                                                                        <angular-editor name="wysiwyg"
                                                                                [(ngModel)]="wysiwyg"
                                                                                [config]="wysiwygEditorConfig">
                                                                        </angular-editor>
                                                                </div>
                                                        </div>
                                                        <div class="form-group    ">
                                                                <label class="col-form-label ">Radio</label>
                                                                <div class="">
                                                                        <div
                                                                                class="custom-control custom-radio form-check-inline">

                                                                                <input type="radio" id="radio0"
                                                                                        name="radio" value="Male"
                                                                                        class="custom-control-input"
                                                                                        >
                                                                                <label class="custom-control-label"
                                                                                        for="radio0">Male</label>
                                                                        </div>
                                                                        <div
                                                                                class="custom-control custom-radio form-check-inline">

                                                                                <input type="radio" id="radio1"
                                                                                        name="radio" value="Female"
                                                                                        class="custom-control-input"
                                                                                        >
                                                                                <label class="custom-control-label"
                                                                                        for="radio1">Female</label>
                                                                        </div>
                                                                        <div
                                                                                class="custom-control custom-radio form-check-inline">

                                                                                <input type="radio" id="radio2"
                                                                                        name="radio" value="TransGender"
                                                                                        class="custom-control-input"
                                                                                        >
                                                                                <label class="custom-control-label"
                                                                                        for="radio2">TransGender</label>
                                                                        </div>
                                                                        <!-- <app-validation-errors 
        [mformField] = "radio" 
        label = "Radio" >
         </app-validation-errors> -->
                                                                </div>
                                                        </div>
                                                        <div class="form-group  ">
                                                                <label class="col-form-label ">Tag Input</label>
                                                                <div class="">
                                                                        <ng-select [items]="tag_inputList"
                                                                                name="tag_input" #tag_input="ngModel"
                                                                                [(ngModel)]="testcollection.tag_input"
                                                                                class="" [addTag]="addTagFn"
                                                                                [hideSelected]="true" multiple="true">
                                                                                <ng-template ng-label-tmp
                                                                                        let-item="item">

                                                                                </ng-template>
                                                                                <ng-template ng-option-tmp
                                                                                        let-item="item"
                                                                                        let-index="index"
                                                                                        let-search="searchTerm">

                                                                                </ng-template>
                                                                                <ng-template ng-footer-tmp>
                                                                                        <button type="button"
                                                                                                class="btn btn-primary"><i
                                                                                                        class="fa fa-plus"></i>Add
                                                                                                Tag Input</button>
                                                                                </ng-template>
                                                                        </ng-select>

                                                                        <app-validation-errors [mformField]="tag_input"
                                                                                label="Tag Input">
                                                                        </app-validation-errors>
                                                                </div>
                                                        </div>
                                                        <div class="form-group    ">
                                                                <label class="col-form-label ">CheckBox</label>
                                                                <div class="">
                                                                        <div
                                                                                class="custom-control custom-checkbox form-check-inline">
                                                                                <input type="checkbox" id="checkBox"
                                                                                         name="checkBox"
                                                                                        value="allow"
                                                                                        class="custom-control-input">
                                                                                <label class="custom-control-label"
                                                                                        for="checkBox">CheckBox
                                                                                </label>
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div class="form-group    ">
                                                                <label class="col-form-label ">TypeSelect</label>
                                                                <div class="">
                                                                        <ng-select [items]="currencyList"
                                                                                name="currency" 
                                                                                #currency="ngModel"
                                                                                [(ngModel)]="testcollection.currency"
                                                                                class="">
                                                                                <ng-template ng-label-tmp
                                                                                        let-item="item">
                                                                                        {{item.CurrencyName}}
                                                                                        ({{item.Country}})
                                                                                </ng-template>
                                                                                <ng-template ng-option-tmp
                                                                                        let-item="item"
                                                                                        let-index="index"
                                                                                        let-search="searchTerm">
                                                                                        {{item.CurrencyName}}
                                                                                        ({{item.Country}})
                                                                                </ng-template>
                                                                                <ng-template ng-footer-tmp>
                                                                                        <button type="button"
                                                                                                class="btn btn-primary"><i
                                                                                                        class="fa fa-plus"></i>Add
                                                                                                TypeSelect</button>
                                                                                </ng-template>
                                                                        </ng-select>

                                                                        <app-validation-errors [mformField]="currency"
                                                                                label="TypeSelect">
                                                                        </app-validation-errors>
                                                                </div>
                                                        </div>
                                                </div>
                                                <div class="col-4">
                                                        <div class="form-group  ">
                                                                <label class="col-form-label ">Phone</label>
                                                                <div class="">
                                                                        <input type="number" class="form-control"
                                                                                name="phone" #phone="ngModel" id="phone"
                                                                                [(ngModel)]="testcollection.phone"
                                                                                placeholder="">
                                                                        <app-validation-errors [mformField]="phone"
                                                                                label="Phone">
                                                                        </app-validation-errors>
                                                                </div>
                                                        </div>
                                                        <div class="form-group  ">
                                                                <label class="col-form-label ">Mobile</label>
                                                                <div class="">
                                                                        <input type="number" class="form-control"
                                                                                name="mobile" #mobile="ngModel"
                                                                                id="mobile"
                                                                                [(ngModel)]="testcollection.mobile"
                                                                                placeholder="">
                                                                        <app-validation-errors [mformField]="mobile"
                                                                                label="Mobile">
                                                                        </app-validation-errors>
                                                                </div>
                                                        </div>
                                                        <div class="form-group    ">
                                                                <label class="col-form-label ">Date Single</label>
                                                                <div class="">
                                                                        <div class="input-group">
                                                                                <input angular-mydatepicker
                                                                                        placeholder="Date Single"
                                                                                        
                                                                                        (click)="date_single_dp.toggleCalendar()"
                                                                                        #date_single_dp="angular-mydatepicker"
                                                                                        class="form-control"
                                                                                        name="date_single"
                                                                                        [(ngModel)]="testcollection.date_single"
                                                                                        [options]="date_singleDpOptions"
                                                                                        (dateChanged)="ondate_singleDateChanged($event)">
                                                                                <!-- clear date button -->
                                                                                <div class="input-group-append">
                                                                                        <button type="button"
                                                                                                class="btn btn-secondary"
                                                                                                *ngIf="testcollection.date_single"
                                                                                                (click)="date_single_dp.clearDate()">
                                                                                                <i
                                                                                                        class="fa fa-close"></i>
                                                                                        </button>
                                                                                </div>

                                                                                <!-- toggle calendar button -->
                                                                                <div class="input-group-append">
                                                                                        <button type="button"
                                                                                                class="btn btn-secondary"
                                                                                                (click)="date_single_dp.toggleCalendar()">
                                                                                                <i
                                                                                                        class="fa fa-calendar-o"></i>
                                                                                        </button>
                                                                                </div>
                                                                        </div>
                                                                        <!-- <app-validation-errors 
                                                [mformField] = "date_single" 
                                                label = "Date Single" >
                                                 </app-validation-errors> -->
                                                                </div>
                                                        </div>
                                                        <div class="form-group  required  ">
                                                                <label class="col-form-label ">Date Range</label>
                                                                <div class="">
                                                                        <div class="input-group">
                                                                                <input angular-mydatepicker
                                                                                        placeholder="Date Range"
                                                                       required               
                                                                                        (click)="date_range_dp.toggleCalendar()"
                                                                                        #date_range_dp="angular-mydatepicker"
                                                                                        class="form-control"
                                                                                        name="date_range"
                                                                                        [(ngModel)]="testcollection.date_range"
                                                                                        [options]="date_rangeDpOptions"
                                                                                        (dateChanged)="ondate_rangeDateChanged($event)">
                                                                                <!-- clear date button -->
                                                                                <div class="input-group-append">
                                                                                        <button type="button"
                                                                                                class="btn btn-secondary"
                                                                                                *ngIf="testcollection.date_range"
                                                                                                (click)="date_range_dp.clearDate()">
                                                                                                <i
                                                                                                        class="fa fa-close"></i>
                                                                                        </button>
                                                                                </div>

                                                                                <!-- toggle calendar button -->
                                                                                <div class="input-group-append">
                                                                                        <button type="button"
                                                                                                class="btn btn-secondary"
                                                                                                (click)="date_range_dp.toggleCalendar()">
                                                                                                <i
                                                                                                        class="fa fa-calendar-o"></i>
                                                                                        </button>
                                                                                </div>
                                                                        </div>
                                                                        <!-- <app-validation-errors 
                                                [mformField] = "date_range" 
                                                label = "Date Range" >
                                                 </app-validation-errors> -->
                                                                </div>
                                                        </div>
                                                        <div class="form-group    ">
                                                                <label class="col-form-label ">File Upload</label>
                                                                <div class="form-control">
                                                                        <!-- <ngfFormData [files]="files" postName="file" [(FormData)]="sendableFormData"></ngfFormData>
        <ngfUploadStatus [(percent)]="progress" [httpEvent]="httpEvent"></ngfUploadStatus> -->
                                                                        <div class="inline-block">
                                                                                Multiple
                                                                                <input ngfSelect multiple type="file"
                                                                                        [(files)]="files"
                                                                                        [accept]="accept"
                                                                                        [maxSize]="maxSize"
                                                                                        [(lastInvalids)]="lastInvalids"
                                                                                        (filesChange)="lastFileAt=getDate()" />{{input_file}}
                                                                        </div>
                                                                </div>
                                                        </div>
                                                        <div class="">
                                                                <button class="btn btn-primary" type="button"
                                                                        (click)="uploadFile()">Upload File</button>
                                                        </div>

                                                </div>
                                        </div>
                                        <div class="card-footer">
                                                {{addTestcollectionForm.valid}}
                                                {{addTestcollectionForm|json}}
                                                <button type="submit" [disabled]="!addTestcollectionForm.valid"
                                                        class="btn btn-primary">Save</button>

                                        </div>
                                </form>
                        </div>
                </div>
        </div>
</div>