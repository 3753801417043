export class Fixedassests {
  // is_fixed_assests: boolean = false;
  fixed_assest;
  // ledger: any = {};
  group_name: string;
  description: string;
  useful_life: number;
  income_tax_rate: number;
  company_id: string;
}
