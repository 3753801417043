
        import { Injectable } from '@angular/core';
        import { ApiService } from './api.service';

        @Injectable({ providedIn: 'root',})
 export class SequenceService {

    constructor(private http: ApiService) { }

    addSequence(sequence_data: any) {
        return this.http.post(ApiService.geturl('sequence', 'add'),
            { data: sequence_data});
    }
    editSequence(sequence_data: any, id: string) {

        return this.http.post(ApiService.geturl('sequence', 'edit'),
         { data:  sequence_data,sequence_id: id });
    }
    deleteSequence(id: string) {
        return this.http.post(ApiService.geturl('sequence', 'delete'),
        {id: id});
    }

    viewSequence(sequence_data: any={}) {
        return this.http.post(ApiService.geturl('sequence', 'view'),
        { sequence_data });
    }

    getSequences(filters: any = {}, sort_by: any = {}, columns: any = {}, page: any = {}) {
        return this.http.post(ApiService.geturl('sequence', 'viewall'),
        {filters: filters, sort_by: sort_by, columns: columns, page: page});
    }
}