// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  PROTOCOL: "https://",
  DOMAIN: "demoapi.elixrbooks.com",
  BASE_URL: "https://demoapi.elixrbooks.com",
  IMG_BASE_URL: "https://demoebselixirbooksstrg.blob.core.windows.net",
  sentry_env: "development",
  PROJECT_NAME: "AccountingERP",
  isAzure: true,
  umamiInsights: {
    srcUrl: 'http://localhost:3000/umami.js',
    appCode: '22fb6ee2-44e1-4e2c-a662-7df050fd7a23',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
