import { Currency } from "./currency.model";
export class Invoice {
  customer: any;
  currency: Currency = new Currency();
  // desc: any;
  sales_order_number: string;
  sales_order_id: string;
  invoice_number: string;
  invoice_sequence_number: string;
  credit_notes: any;
  sales_order_date: any;
  invoice_date: any;
  due_date: any;
  invoice_items: any = [];
  balancing_items: any = [];
  is_discount: boolean = false;
  is_pos: boolean;
  is_productwise_discount: boolean = false;
  discount_in_percentage: boolean = false;
  // tax: string;
  company_id: string;
  shipping_address: string;
  billing_address: string;
  total_amount: number = 0;
  sub_total_amount: number = 0;
  discount: number = 0;
  freight: number = 0;
  transport_charges: number = 0;
  balance_amount: number = 0;
  total_tax_percentage: number = 0;
  total_tax_amount: number = 0;
  actual_total_amount: number = 0;
  grand_total_amount: number = 0;
  cgst: number = 0;
  sgst: number = 0;
  igst: number = 0;
  branch_id: string;
  sales_status: number;
  sales_status_name: string;
  original_items: any = [];
  ledger: Ledger = new Ledger();
  debit_number: any;
  overall_discount_in_percentage: number;
  voucher_name: any;
  voucher_object: any;
  eway_bill_no: number;
  vehicle_number: string;
  delivery_challan_no: number;
  voucher_type: any = {};
  round_of: number;
  round_value: number;
  rounded_balance: number;
  invoice_amount_wo_roundof: number;
  sequence_number: any;
  notes: string;
  invoice_status: number;
  invoice_status_name: string;
  is_without_gst: boolean = false;
}
export class Ledger {
  name: string;
  id: number;
  ref_id: string;
  parent_id: any = [];
  parent_name: any = [];
}
