<div class="container-fluid">
   <div class="row view">
      <div class="col-lg-12">
         <div class="card">
            <div class="card-header d-flex align-items-center">
               <h3 class="table-title">Credit Note</h3>
            </div>
            <!-- <form novalidate #addBillForm="ngForm" (ngSubmit)="UpdateBill()"> -->
            <div class="card-body row">
               <div class="form-group  col-12 col-sm-6 col-md-3 col-xl-3">
                  <label for="vendor">Customer</label>
                  <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3"> -->
                  <div>{{invoice?.customer?.customer_name}}</div>
                  <!-- </div> -->
               </div>
               <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                  <label for="invoice_number">Invoice Number</label>
                  <div>{{invoice?.credit_number}}</div>
               </div>
               <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                  <label for="billing_date">Bill Date</label>
                  <div>
                     {{invoice.invoice_date|date}}
                  </div>
               </div>
               <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                  <label for="due_date">Due Date</label>
                  <div>
                     {{invoice.due_date|date}}
                  </div>
               </div> -->
               <!-- <div *ngIf="is_bill_invoice==false && is_credit_note==false"
                 class=" form-group col-12 col-sm-6 col-md-3 col-xl-3">
                 <label for="purchase_order_date">PO Date</label>
                 <div class="input-group">
                    <input class="form-control" #purchase_order_date="ngModel" name="purchase_order_date"
                    angular-mydatepicker #dp_purchase_order_date="angular-mydatepicker" placeholder="Select a date"
                    (click)="dp_purchase_order_date.toggleCalendar()" [(ngModel)]="bill.purchase_order_date"
                    [options]="billPurchaseDateOptions" />
                    <div class="input-group-append">
                       <button type="button" class="btn btn-secondary" *ngIf="bill.purchase_order_date"
                       (click)="dp_purchase_order_date.clearDate()">
                       <i class="fa fa-close"></i>
                       </button>
                    </div>
                    <div class="input-group-append">
                       <button type="button" class="btn btn-secondary" (click)="dp_purchase_order_date.toggleCalendar()">
                       <i class="fa fa-calendar-o"></i>
                       </button>
                    </div>
                 </div>
                 <app-validation-errors [mformField]="purchase_order_date" label="Purchase Order Date">
                 </app-validation-errors>
              </div> -->
               <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                  <label>Invoice Date</label>
                  <div class="ml-4">{{invoice.invoice_date |date}}</div>
               </div> -->
               <!-- <div *ngIf="is_bill_invoice==false && is_credit_note==false"
                 class="form-group col-12 col-sm-6 col-md-3 col-xl-3 ">
                 <label for="purchase_order_number">PO Number</label>
                 <input type="text" class="form-control" #purchase_order_number="ngModel" name="purchase_order_number"
                 placeholder="Purchase Order Number" [(ngModel)]="bill.purchase_order_number" />
                 <app-validation-errors [mformField]="purchase_order_number" label="Purchase Order Number">
                 </app-validation-errors>
              </div> -->
               <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                  <label>Invoice Number</label>
                  <div class="ml-5">{{invoice.invoice_number}}</div>
               </div> -->
               <div *ngIf="invoice?.image" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                  <a href="{{this.path}}" target="_blank">{{invoice?.image?.originalname}}</a>
               </div>
               <!-- <div class="form-group col-12  col-sm-6 col-md-3 col-xl-3" *ngIf="is_credit_note">
                 <label for="credit_date">Credit Date</label>
                 <div class="input-group">
                    <input class="form-control" #credit_date="ngModel" name="credit_date" placeholder="Select a date"
                    angular-mydatepicker #dp_creditdate="angular-mydatepicker" [(ngModel)]="bill.credit_date"
                    [options]="creditDateOptions" (click)="dp_creditdate.toggleCalendar()" />
                    <div class="input-group-append">
                       <button type="button" class="btn btn-secondary" *ngIf="bill.credit_date"
                       (click)="dp_creditdate.clearDate()">
                       <i class="fa fa-close"></i>
                       </button>
                    </div>
                    <div class="input-group-append">
                       <button type="button" class="btn btn-secondary" (click)="dp_creditdate.toggleCalendar()">
                       <i class="fa fa-calendar-o"></i>
                       </button>
                    </div>
                 </div>
                 <app-validation-errors [mformField]="credit_date" label="Credit Date">
                 </app-validation-errors>
              </div> -->
               <div class="card">
                  <div class="card-body form-row p-0">
                     <table
                        class="col-12 table table-hover table-vcenter table_custom text-nowrap spacing5 text-nowrap mb-0">
                        <thead>
                           <tr>
                              <th>S.No</th>
                              <th class="">{{company_details?.purchase_column_settings?.items}}Items
                              </th>
                              <th>{{company_details?.purchase_column_settings?.description}}Description</th>
                              <th>{{company_details?.purchase_column_settings?.units}}Units</th>
                              <th>{{company_details?.purchase_column_settings?.price}}Price</th>
                              <th>Discount</th>
                              <th>Tax%</th>
                              <th>Tax Amount</th>
                              <th>Amount</th>
                              <!-- <th>Action</th> -->
                           </tr>
                        </thead>
                        <tbody *ngFor="let itemsObj of invoice.invoice_items; let i = index">
                           <tr>
                              <td>
                                 {{i+1}}
                              </td>
                              <td>{{itemsObj?.product?.name}}</td>
                              <td>{{itemsObj.description}}</td>
                              <td>{{itemsObj.units}}</td>
                              <td>{{itemsObj.price}}</td>
                              <td>{{itemsObj.discount}}</td>
                              <td *ngIf="itemsObj.tax==undefined">0%</td>
                              <td *ngIf="itemsObj.tax!=undefined"
                                 title="sgst:{{itemsObj.sgst}} cgst:{{itemsObj.cgst}} igst:{{itemsObj.igst}}">
                                 {{itemsObj.tax}}%
                              </td>
                              <td>{{itemsObj.tax_amount}}</td>
                              <td>
                                 {{itemsObj.amount}}
                              </td>
                           </tr>
                        </tbody>
                     </table>
                     <!-- Balancing Items Table -->
                     <div class="col-12">
                        <table
                           class="float-right col-4 table table-hover table-vcenter table_custom text-nowrap spacing5 text-nowrap mb-0">
                           <thead>
                              <tr>
                                 <!-- <th>S.NO</th> -->
                                 <th>Account Head</th>
                                 <th>Value</th>
                                 <!-- <th>Action</th> -->
                              </tr>
                           </thead>
                           <tbody *ngFor="let balancingObj of invoice.balancing_items let balancing_index = index">
                              <!-- <td>
                                    <ng-select [items]="account_head_lists" [disabled]="true"
                                       [typeahead]="accountHeadListTypeahead" #account_head="ngModel"
                                       name="balancingObj.account_head{{balancing_index}}" bindLabel="account_head"
                                       [(ngModel)]="balancingObj.account_head" placeholder="Select Account Head">
                                       <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index"
                                          let-search="searchTerm">
                                          <div [ngOptionHighlight]="search">
                                             {{ item?.flattened_data?.name }}
                                          </div>
                                       </ng-template>
                                    </ng-select>
                                    <app-validation-errors [mformField]="account_head" label="Account Head">
                                    </app-validation-errors>
                                 </td> -->
                              <td>{{balancingObj.account_head?.flattened_data?.name}}</td>
                              <!-- <td>
                                    <input type="number" [disabled]="true" name="amount{{balancing_index}}"
                                       #balancing_amount="ngModel" class="form-control"
                                       [(ngModel)]="balancingObj.amount" placeholder="Enter Amount">
                                    <app-validation-errors [mformField]="balancing_amount" label="Amount">
                                    </app-validation-errors>
                                 </td> -->
                              <td>{{balancingObj.amount}}</td>
                              <!-- <td>
                                    <button type="button" class="btn btn-danger"
                                       (click)="deleteBalancingItem(balancing_index)">
                                       <i class="fa fa-trash"></i>
                                    </button>
                                    <button type="button" class="btn btn-primary ml-2" (click)="addNewBalancingItem()">
                                       <i class="fa fa-plus"></i>
                                    </button>
                                 </td> -->
                           </tbody>
                        </table>
                     </div>
                  </div>
                  <!-- <div class="row">
                        <div class="col-12 col-sm-6 col-md-3 col-xl-4 col-6 custom-switch" style="left: 2.5rem;">
                           <input type="checkbox" (click)="changeStatus('advance')" [(ngModel)]="is_advance"
                              name="is_advance" class="custom-control-input pl-5" id="is_advance">
                           <label class="custom-control-label" for="is_advance">Advance?</label>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-xl-4 col-6 custom-switch" style="left: 2.5rem;">
                           <input type="checkbox" (click)="changeStatus('debitnote')" [(ngModel)]="is_debitnote"
                              name="is_debitnote" class="custom-control-input pl-5" id="is_debitnote">
                           <label class="custom-control-label" for="is_debitnote">Credit Note?</label>
                        </div>
                     </div> -->
                  <!-- <div>
                     <span class="mr-2 float-right">
                        <b> Actual Total Amount: {{invoice.actual_total_amount}}</b>
                     </span>
                     <br>
                     <span class="mr-2 float-right">
                        <b> CGST Amount: {{invoice.cgst}}</b>
                     </span>
                     <br>
                     <span class="mr-2 float-right">
                        <b> SGST Amount: {{invoice.sgst}}</b>
                     </span>
                     <br>
                     <span class="mr-2 float-right">
                        <b> IGST Amount: {{invoice.igst}}</b>
                     </span>
                     <br>
                     <span class="mr-2 float-right">
                        <b> Total Tax Amount: {{invoice.total_tax_amount}}</b>
                     </span>
                     <br>
                     <span class="mr-2 float-right">
                        <b>Freight: {{invoice.freight}}</b>
                     </span>
                     <br>
                     <span class="mr-2 float-right">
                        <b>Transport Charges: {{invoice.transport_charges}}</b>
                     </span>
                     <br>
                     <span class="mr-2 float-right">
                        <b>Sub Total Amount: {{invoice.sub_total_amount}}</b>
                     </span>
                     <br>
                     <span class="mr-2 float-right">
                        <b>Total Discount Amount: -{{invoice.discount}}</b>
                     </span>
                     <br> -->
                  <!-- <span class="mr-2 float-right">
                       <b>Grand Total Amount: {{bill.grand_total_amount}}</b>
                       </span> -->
                  <!-- <span class="mr-2 float-right">
                        <b>Total Amount: {{invoice.total_amount}}</b>
                     </span><br>
                     <span class="mr-2 float-right"><b>Balance Amount : {{ rem_amount}}</b></span><br> -->

                  <!-- <span class="mr-2 float-right"
                           *ngIf="is_advance"><b>Advance({{adv_total_amount}})</b></span><br>
                        <span class="mr-2 float-right" *ngIf="is_advance">
                           <input type="number" name="advance_amount" [max]="adv_total_amount" class="form-control"
                              [(ngModel)]="advance_amount" (change)="changeAmount('advance')"
                              placeholder="Enter Advance Amount">
                        </span><br>
                        <span class="mr-2 float-right" *ngIf="is_debitnote"><b>Credit Amount({{credit_balance}})</b><br>
                           <input type="number" [max]="credit_balance" name="credit_amt" class="form-control"
                              [(ngModel)]="credit_amt" (change)="changeDebitAmount()"
                              placeholder="Enter Advance Amount">
                        </span><br>
                        <span class="mr-2  float-right" *ngIf="is_advance || is_debitnote">
                           <button type="submit" class="btn btn-primary">Apply </button>
                        </span> -->
                  <!-- </div> -->
                  <div class="row justify-content-between">
                     <div class="col-4"></div>
                     <table class="float-right table col-4 final-table mt-4">
                        <tbody>
                           <tr>
                              <th><b>Actual Total Amount</b></th>
                              <td>
                                 <b>{{invoice.actual_total_amount | currency: invoice.currency.name}}</b>
                              </td>
                           </tr>
                           <tr>
                              <th><b>CGST Amount</b></th>
                              <td>
                                 <b>{{invoice.cgst | currency: invoice.currency.name}}</b>
                              </td>
                           </tr>
                           <tr>
                              <th><b>SGST Amount</b></th>
                              <td>
                                 <b>{{invoice.sgst | currency: invoice.currency.name}}</b>
                              </td>
                           </tr>
                           <tr>
                              <th><b>IGST Amount</b></th>
                              <td>
                                 <b>{{invoice.igst | currency: invoice.currency.name}}</b>
                              </td>
                           </tr>
                           <tr>
                              <th><b>Total Tax Amount</b></th>
                              <td>
                                 <b>{{invoice.total_tax_amount | currency: invoice.currency.name}}</b>
                              </td>
                           </tr>
                           <tr>
                              <th><b>Sub Total Amount</b></th>
                              <td>
                                 <b>{{invoice.sub_total_amount | currency: invoice.currency.name}}</b>
                              </td>
                           </tr>
                           <tr>
                              <th><b>Total Amount</b></th>
                              <td>
                                 <b>{{invoice.total_amount | currency: invoice.currency.name}}</b>
                              </td>
                           </tr>
                           <tr>
                              <th><b>Balance Amount</b></th>
                              <td>
                                 <b>{{ rem_amount | currency: invoice.currency.name}}</b>
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            <!-- </form> -->
         </div>
      </div>
   </div>
</div>