import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import swal from 'sweetalert2';
import { CostgroupService } from '../../_services/costgroup.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
@Component({
  selector: 'app-costgroup',
  templateUrl: './costgroup.component.html',
  styleUrls: ['./costgroup.component.scss']
})
export class CostgroupComponent implements OnInit {
  filters: any = {};
  costgroup: any = {};
  costgroupList: any = [];
  costgroupListColumns: any = [];
  loadingIndicator = false;
  reorderable = true;
  page: any = { offset: 0, pageSize: 2 };
  company_id: string;
  // @ViewChild('actionTmpl', { static: true }) actionTmpl: TemplateRef<any>;
  sort: any = { "costcategory_name": 'asc' };
  dt_default_sort = [{ prop: 'costcategory_name', dir: 'asc' }];
  columns: any = {};
  constructor(
    private costgroupservice: CostgroupService,
    private authenticationService: AuthenticationService,
    public route: ActivatedRoute,

  ) { }


  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.costgroupListColumns = [
      {
        prop: 'costcategory_name', name: 'Cost Category', sortable: true
        , flexGrow: 2, show: true, is_detailed_row: false
      },
      {
        prop: 'under_cc_category', type: 'under_cc_category', name: 'Parent ', sortable: true
        , flexGrow: 2, show: true, is_detailed_row: false
      },


      {
        name: 'Action',
        type: 'action',
        prop: '_id',
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false
      }

    ];
    this.costgroupListColumns.forEach((columns) => {
      this.columns[columns.prop] = 1;
    });
    if (this.route.snapshot.queryParams['q'] != 'undefined')
      this.filters['costcategory_name'] = this.route.snapshot.queryParams['q'];
    this.getCostgroupsList(this.page);

  }
  onSort(event) {
    this.loadingIndicator = true;
    this.sort = {};
    let sort_details = event.sorts[0];
    this.sort[sort_details.prop] = sort_details.dir == 'asc' ? 1 : -1;
    this.getCostgroupsList(this.page);
  }

  getCostgroupsList(page_info: any) {
    this.loadingIndicator = true;
    if (page_info != null) {
      this.page = page_info;
    }
    this.filters["company_id"] = this.company_id;

    this.costgroupservice.getCostgroups(this.filters, this.columns, this.sort, this.page).subscribe(result => {
      this.costgroupList = result['data'];
      this.page.count = result['count'];
      this.loadingIndicator = false;
    });
  }
  clearCostgroupFilters() {
    this.costgroup = {};
    this.getCostgroupsList({ offset: 0, pageSize: 2 });
  }
  deleteCostgroup(muid) {
    swal({
      title: 'Are you sure want to delete?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result) {
        this.costgroupservice.deleteCostgroup(muid).subscribe(result => {
          if (result['status_code'] === 1) {
            swal('Deleted!', 'Costgroup has been deleted.', 'success');
            this.getCostgroupsList({});
          }
        });
      }
    });
  }
}
