<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <form novalidate #searchTestcollectionForm="ngForm" (ngSubmit)="getTestcollectionsList(null)">
                <div class="card">
                    <div class="card-header d-flex align-items-center">


                        <h3 class="table-title">Testcollection List</h3>


                    </div>
                    <div class="card-body row">

                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary">Search</button>
                        <button (click)="clearTestcollectionFilters();" class="btn btn-warning">
                            Clear Search
                        </button>

                    </div>
                </div>
            </form>
            <div class="p-sm-2">
                <button class="btn btn-primary float-right" routerLink="/testcollection/add">
                    <i class="icon icon-add"></i>Add Testcollection
                </button>
            </div>

            <div class="p-sm-5">
                <ngx-datatable [rows]="testcollectionList" class="material" [columns]="testcollectionListColumns"
                    [loadingIndicator]="loadingIndicator" [externalPaging]="true" [columnMode]="'force'"
                    [limit]="page.limit" [count]="page.count" [offset]="page.offset"
                    (page)="getTestcollectionsList($event)" [reorderable]="reorderable" 
                    [footerHeight]="50" [rowHeight]="'auto'" class="material striped" [summaryRow]="'true'"
                    [summaryPosition]="'bottom'" [summaryHeight]="'auto'"></ngx-datatable>

            </div>
        </div>

    </div>
</div>

<ng-template #actionTmpl let-value="value">

    <div class="btn-group mr-3">
        <button class="btn btn-primary" routerLink="/testcollection/edit/{{value}}" type="button"
            rel="tooltip">Edit</button>
        <div class="btn-group" ngbDropdown placement="bottom-right" role="group">
            <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
            <div class="dropdown-menu" ngbDropdownMenu>
                <button class="dropdown-item" routerLink="/testcollection/edit/{{value}}" type="button"
                    rel="tooltip">Edit</button>
                <button class="dropdown-item " (click)="deleteTestcollection(value)">
                    <span class="text-red">
                        <i class="icon-trash-can3"></i> Delete
                    </span>
                </button>
            </div>
        </div>
    </div>



</ng-template>