import { SupportTicketService } from './../../_services/support-ticket.service';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { QuillModule } from 'ngx-quill';
import { MarkdownService } from 'ngx-markdown';
import swal from 'sweetalert2';
import TurndownService from 'turndown';
import {SupportTicket} from '../../_models/support-ticket.model';
let turndownService = new TurndownService();
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
@Component({
  selector: 'app-support-ticket',
  templateUrl: './support-ticket.component.html',
  styleUrls: ['./support-ticket.component.scss']
})
export class SupportTicketComponent implements OnInit {
  ticket: SupportTicket = new SupportTicket();
  is_edit_form: boolean = false;
  markdownText: any;
  testHtml: string;
  id: string;
  labelsList: any = [
    { value: "bug", label: "Bug", icon: "" },
    { value: "feature_request", label: "Feature Request", icon: "" },
    { value: "suggestion", label: "Suggestion", icon: "" },
    { value: "usability", label: "Usability", icon: "" },
    { value: "performance", label: "Performance", icon: "" },
  ];
  public editorOptions = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],


      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent

      ['link'],                                         // remove formatting button

      [{ 'align': [] }],

      ['clean'],                                         // remove formatting button


    ]
  };

  // support_ticket: SupportTicket = new SupportTicket();
  constructor(
    private ticketService: SupportTicketService,
    private route: ActivatedRoute,
    private markdownService: MarkdownService,
    private authservice: AuthenticationService,
    private router: Router
  ) {

  }


  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.id = params.get("id");
      var result = this.router.url.match("/edit/");
      if (result[0] === "/edit/" && this.id != null) {
        this.is_edit_form = true;
        this.getSupportTicket();
      }
    });
  }
  createTicket() {
    let user_info = this.authservice.getNameWithEmail();
    this.ticketService.create({
      title: this.ticket.title,
      description: turndownService.turndown(this.ticket.description_html),
      description_html: this.ticket.description_html,
      labels: this.ticket.label + ' ',
      application_user: user_info.firstname + ' ~~ ' + user_info.lastname + '(' + user_info.email + ')'
    }).subscribe((res) => {
      console.log(res);
      if (res['status_code'] === 1) {
        swal('Ticket Created sucessfully!', 'Ticked Created', 'success');
        this.router.navigateByUrl('/support-ticket-list');
      } else {
        this.showErrors(res[' response '][' errors ']);
      }
    });
  }
  updateTicket() {
    this.ticket.description = turndownService.turndown(this.ticket.description_html),
      this.ticket.description_html = this.ticket.description_html,
      this.ticketService.update(this.ticket, this.ticket['muid']).subscribe((result => {
        if (result['status_code'] === 1) {
          swal('Success', 'Ticket Updated Successfully', 'success');
          //  this.router.navigateByUrl('/support-ticket-list');
          this.getSupportTicket();
        } else {
          this.showErrors(result['response']['errors']);
        }
      }));
  }
  saveTicket() {
    if (this.is_edit_form == false) {
      this.createTicket();
    } else {
      this.updateTicket();
    }
  }
  showErrors(errors) {
    let error_string: string;
    error_string = ' ';
    errors.forEach((item) => {
      error_string += ' < br / > ' + item;
    });
    swal({
      title: 'Error',
      html: error_string,
      type: 'error'
    }).then(function () {
    });
  }
  onChangeContent(messageInfo: any) {
    console.log(messageInfo);
    this.ticket.description_html = messageInfo.html;
    // this.ticket.description = messageInfo.text;
    this.ticket.description = turndownService.turndown(this.ticket.description_html);

    console.log(this.ticket);
    // this.convertHtmlToMarkdown();
  }
  convertHtmlToMarkdown() {
    console.log(this.markdownService.compile(this.ticket.description_html, true));

  }

  getSupportTicket() {
    this.ticketService.viewSupportTicket(this.id).subscribe((result: {response: {data: SupportTicket}}) => {
      this.ticket = result['response']['data'];
      this.ticket.discussions.splice(0, 1);
    });
  }
}

