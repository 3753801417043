<div class="container-fluid">
    <div class="row">
        <div class="card">
            <div class="card-body">
                <div class="row clearfix">
                    <div class="d-flex justify-content-between align-items-center">
                        <ul class="nav nav-tabs page-header-tab">
                            <li class="nav-item"><a class="nav-link" [ngClass]="{'active':navTab == 'customer'}"
                                    (click)="navTab='customer'" id="Project-tab" data-toggle="tab"
                                    href="javascript:void(0);">Customer's</a>
                            </li>
                            <li class="nav-item"><a class="nav-link" [ngClass]="{'active':navTab == 'vendor'}"
                                    (click)="navTab='vendor'" id="Project-tab" data-toggle="tab"
                                    href="javascript:void(0);">Vendor's</a></li>
                        </ul>
                    </div>
                </div>
                <div class="tab-content">
                    <div class="tab-pane fade show active" [ngClass]="{'show active':navTab == 'customer' || !navTab}"
                        id="customer" role="tabpanel">
                        <app-customer></app-customer>
                    </div>
                    <div class="tab-pane fade show active" [ngClass]="{'show active':navTab == 'vendor' || !navTab}"
                        id="vendor" role="tabpanel">
                        <app-vendors></app-vendors>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>