import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { BillService } from "../../../_services/bill.service";
import { ApiService } from "../../../_services/api.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { AuthenticationService } from "../../../_services/authentication.service";
import swal from "sweetalert2";
import { Company } from "../../../_models/company.model";
import { CompanyService } from "../../../_services/company.service";

@Component({
  selector: "app-bill",
  templateUrl: "./bill-view.component.html",
  styleUrls: ["./bill-view.component.scss"]
})
export class BillViewComponent implements OnInit {
  filters: any = {};
  bill: any = {};
  billList: any = [];
  billListColumns: any = [];
  page: any = { offset: 0, limit: 10 };
  bill_id: string;
  // bill: any;
  company_id: any;
  db_columns: any = {};
  bill_ids: any = [];
  advance_id: any;
  adv_total_amount: any;
  adv_advance_based_amount: any;
  adv_total_bill_amount: any;
  is_advance = false;
  is_bill_invoice = false;
  is_credit_note = false;
  is_debitnote = false;
  advance_amt: any;
  rem_amount: number;
  totalAmount: any;
  overall_total_amount: number;
  advance_amount_id: any;
  branch_id: any;
  advance_amount: number;
  credit_amount_id: any;
  credit_balance: any;
  credit_amt: number;
  path: any;
  currency: string;
  company_details: Company = new Company();

  constructor(
    private billService: BillService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    public apiService: ApiService,
    public companyService: CompanyService
  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.currency = this.authenticationService.getCompanyDetails()[
      "base_currency"
    ];
    this.route.paramMap.subscribe(params => {
      this.bill_id = params.get("id");
      if (this.bill_id != "") {
        this.viewBills();

      }
    });
    this.viewCompany()
  }
  getAdvanceAmount(filters) {

    if (this.advance_id) {
      filters['advance_id'] = this.advance_id;
    }

    this.company_id = this.authenticationService.getDefaultCompanyId();


    this.billService.getAdvanceAmount(filters).subscribe(result => {
      if (result['adv_data'].length > 0) {
        this.advance_amount_id = result['adv_data'][0]['_id'];
        this.adv_total_amount = result['total_adv_data'].reduce(function (a, b) { return a + b['advance_amount']; }, 0);
        this.adv_advance_based_amount = result['adv_data'][0]['advance_amount'];
        this.adv_total_bill_amount = result['bill_data'].reduce(function (a, b) { return a + b['balance_amount']; }, 0);
      }
    });
  }
  getCreditAmount(filters) {


    this.company_id = this.authenticationService.getDefaultCompanyId();

    this.billService.getCreditAmount(filters).subscribe(result => {
      // console.log(result['response']);
      if (result['response'].length > 0) {
        this.credit_amount_id = result['response'][0]['_id'];
        this.credit_balance = result['response'][0]['balance_amount'];
      }
    });
  }
  viewBills() {
    let total_amount = 0;
    // let condition: any = {};
    // condition['comapny_id'] = this.company_id;
    this.billService
      .viewBill(this.bill_id)
      .subscribe(result => {
        this.bill = result["response"];
        this.bill.bill_items.forEach(element => {
          console.log("==11111111==", element.actual_amount);

          total_amount = total_amount + parseInt(element.actual_amount);
          this.overall_total_amount = total_amount;

        });
        this.rem_amount = this.bill.balance_amount;

        this.filters["vendor_id"] = result["response"]["vendor"]["_id"];
        this.filters["bill_id"] = this.bill_id;
        this.filters['company_id'] = this.company_id;
        this.getAdvanceAmount(this.filters);
        this.getCreditAmount(this.filters);
        if (this.bill.image) {
          this.path = this.apiService.getImageUrl(this.bill.image.path);
        }
        //     this.sales_order.due_date = {
        //         isRange: false, singleDate: {
        //             jsDate: new Date(this.sales_order.due_date)
        //         }
        //     };
        //     this.sales_order.sales_order_date = {
        //         isRange: false, singleDate: {
        //             jsDate: new Date(this.sales_order.sales_order_date)
        //         }
        //     };
      });
  }

  // goBack() {
  //     this.location.back();
  // }
  changeAmount(checkAdv) {
    console.log(this.is_advance);
    if (this.is_advance) {

      this.is_advance = true;
      let total_amount = 0;

      this.bill.bill_items.forEach(element => {
        console.log("==11111111==", element.actual_amount);

        total_amount = total_amount + parseInt(element.actual_amount);
        this.overall_total_amount = total_amount;

      });

      this.rem_amount = this.rem_amount - this.advance_amount;

      console.log(this.rem_amount);
      console.log(this.advance_amount);
    } else {
      console.log(2222);
      this.rem_amount = this.bill.balance_amount;
    }

  }

  UpdateBill() {
    let total_amount = 0;
    let data: any = {};
    let debitdata: any = {};

    data = {
      "advance_amount": this.advance_amount,
      "advance_tot_amount": this.adv_total_amount,
      "advance_status": this.is_advance,
      "over_all_amount": this.bill.balance_amount,
      "advance_reduced_amount": this.rem_amount,
      "bill_id": this.bill_id,
      "company_id": this.company_id,
      "advance_id": this.advance_amount_id,
      "advance_type": "bill",
      "bill_data": this.bill

    };
    debitdata = {
      "advance_amount": this.credit_amt,
      "advance_tot_amount": this.credit_balance,
      "advance_status": this.is_debitnote,
      "over_all_amount": this.bill.balance_amount,
      "advance_reduced_amount": this.rem_amount,
      "bill_id": this.bill_id,
      "company_id": this.company_id,
      "advance_id": this.credit_amount_id,
      "advance_type": "bill",
      "bill_data": this.bill

    };

    this.billService.updateBillForAdvance(data).subscribe(result => {
      if (this.is_debitnote) {
        this.billService.updateBillForDebit(debitdata).subscribe(result => {

          if (result["status_code"] == 1) {
            swal("Success", "Amount Updated", "success");
            this.router.navigateByUrl("/bill/list");
          } else {
            swal("Warning", "Advance not Updated", "warning");
          }

        });
      }
      if (result["status_code"] == 1) {
        swal("Success", "Amount Updated", "success");
        this.router.navigateByUrl("/bill/list");
      } else {
        swal("Warning", "Advance not Updated", "warning");
      }

    });
  }

  changeStatus(status) {

    if (status == "advance") {
      if (this.is_advance == false) {
        this.is_advance = true;
      }
      else {
        this.is_advance = false;
        this.rem_amount = this.bill.balance_amount;
        this.advance_amount = 0;

      }
    }
    else if (status == "debitnote") {
      if (this.is_debitnote == false) {
        this.is_debitnote = true;
      } else {
        this.is_debitnote = false;
        this.rem_amount = this.bill.balance_amount;
        this.advance_amount = 0;

      }
    }

  }
  changeDebitAmount() {
    if (this.is_debitnote) {

      this.is_debitnote = true;
      let total_amount = 0;



      this.rem_amount = this.rem_amount - this.credit_amt;

    }
  }
  viewCompany() {
    this.companyService.viewCompany(this.company_id).subscribe(result => {
      this.company_details = result['response']
    })
  }
}
