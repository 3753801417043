<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <div class="td_head">gstin</div>
                            </td>
                            <td>
                                <div class="td_row_even">{{gst['GSTIN']}}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="td_head">fp</div>
                            </td>
                            <td>
                                <div class="td_row_even">{{gst['fp']}}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="td_head">gt</div>
                            </td>
                            <td>
                                <div class="td_row_even">{{gst['gt']}}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="td_head">cur_gt</div>
                            </td>
                            <td>
                                <div class="td_row_even">{{gst['cur_gt']}}</div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <div class="td_head">b2b</div>
                                <table style="width:100%">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="td_head">ctin</div>
                                            </td>
                                            <td>
                                                <div class="td_head">inv</div>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let b2b of gst['b2b']">
                                            <td class="td_row_odd">
                                               <div class="td_row_odd">{{b2b['ctin']}}</div>
                                            </td>
                                            <td class="td_row_odd">
                                               <table style="width:100%">
                                                  <tbody>
                                                     <tr>
                                                        <td>
                                                           <div class="td_head">inum</div>
                                                        </td>
                                                        <td>
                                                           <div class="td_head">idt</div>
                                                        </td>
                                                        <td>
                                                           <div class="td_head">val</div>
                                                        </td>
                                                        <td>
                                                           <div class="td_head">pos</div>
                                                        </td>
                                                        <td>
                                                           <div class="td_head">rchrg</div>
                                                        </td>
                                                        <td>
                                                           <div class="td_head">itms</div>
                                                        </td>
                                                        <td>
                                                           <div class="td_head">inv_typ</div>
                                                        </td>
                                                     </tr>
                                                     <tr *ngFor="let inv of b2b['inv']">
                                                        <td class="td_row_even">
                                                           <div class="td_row_even">{{inv['inum']}}</div>
                                                        </td>
                                                        <td class="td_row_even">
                                                           <div class="td_row_even">{{inv['idt']}}</div>
                                                        </td>
                                                        <td class="td_row_even">
                                                           <div class="td_row_even">{{inv['val']}}</div>
                                                        </td>
                                                        <td class="td_row_even">
                                                           <div class="td_row_even">{{inv['pos']}}</div>
                                                        </td>
                                                        <td class="td_row_even">
                                                           <div class="td_row_even">{{inv['rchrg']}}</div>
                                                        </td>
                                                        <td class="td_row_even">
                                                           <table style="width:100%">
                                                              <tbody>
                                                                 <tr>
                                                                    <td>
                                                                       <div class="td_head">num</div>
                                                                    </td>
                                                                    <td>
                                                                       <div class="td_head">itm_det</div>
                                                                    </td>
                                                                 </tr>
                                                                 <tr  *ngFor="let itms of inv['itms']" >
                                                                    <td class="td_row_even">
                                                                       <div class="td_row_even">{{itms['num']}}</div>
                                                                    </td>
                                                                    <td class="td_row_even">
                                                                       <table style="width:100%">
                                                                          <tbody>
                                                                             <tr>
                                                                                <td>
                                                                                   <div class="td_head">txval</div>
                                                                                </td>
                                                                                <td>
                                                                                   <div class="td_row_even">{{itms['itm_det']['txval']}}</div>
                                                                                </td>
                                                                             </tr>
                                                                             <tr>
                                                                                <td>
                                                                                   <div class="td_head">rt</div>
                                                                                </td>
                                                                                <td>
                                                                                   <div class="td_row_even">{{itms['itm_det']['rt']}}</div>
                                                                                </td>
                                                                             </tr>
                                                                             <tr>
                                                                                <td>
                                                                                   <div class="td_head">camt</div>
                                                                                </td>
                                                                                <td>
                                                                                   <div class="td_row_even">{{itms['itm_det']['camt']}}</div>
                                                                                </td>
                                                                             </tr>
                                                                             <tr>
                                                                                <td>
                                                                                   <div class="td_head">samt</div>
                                                                                </td>
                                                                                <td>
                                                                                   <div class="td_row_even">{{itms['itm_det']['samt']}}</div>
                                                                                </td>
                                                                             </tr>
                                                                             <tr>
                                                                                <td>
                                                                                   <div class="td_head">csamt</div>
                                                                                </td>
                                                                                <td>
                                                                                   <div class="td_row_even">{{itms['itm_det']['csamt']}}</div>
                                                                                </td>
                                                                             </tr>
                                                                          </tbody>
                                                                       </table>
                                                                    </td>
                                                                 </tr>
                                                              </tbody>
                                                           </table>
                                                        </td>
                                                        <td class="td_row_even">
                                                           <div class="td_row_even">R</div>
                                                        </td>
                                                     </tr>
                                                   
                                                  </tbody>
                                               </table>
                                            </td>
                                         </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <div class="td_head">b2cs</div>
                                <table style="width:100%">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="td_head">rt</div>
                                            </td>
                                            <td>
                                                <div class="td_head">sply_ty</div>
                                            </td>
                                            <td>
                                                <div class="td_head">pos</div>
                                            </td>
                                            <td>
                                                <div class="td_head">typ</div>
                                            </td>
                                            <td>
                                                <div class="td_head">txval</div>
                                            </td>
                                            <td>
                                                <div class="td_head">camt</div>
                                            </td>
                                            <td>
                                                <div class="td_head">samt</div>
                                            </td>
                                            <td>
                                                <div class="td_head">csamt</div>
                                            </td>
                                        </tr>
                                        <tr  *ngFor="let b2c of gst['b2cs']">
                                            <td class="td_row_even">
                                                <div class="td_row_even">{{b2c['rt']}}</div>
                                            </td>
                                            <td class="td_row_even">
                                                <div class="td_row_even">{{b2c['sply_ty']}}</div>
                                            </td>
                                            <td class="td_row_even">
                                                <div class="td_row_even">{{b2c['pos']}}</div>
                                            </td>
                                            <td class="td_row_even">
                                                <div class="td_row_even">{{b2c['typ']}}</div>
                                            </td>
                                            <td class="td_row_even">
                                                <div class="td_row_even">{{b2c['txval']}}</div>
                                            </td>
                                            <td class="td_row_even">
                                                <div class="td_row_even">{{b2c['camt']}}</div>
                                            </td>
                                            <td class="td_row_even">
                                                <div class="td_row_even">{{b2c['samt']}}</div>
                                            </td>
                                            <td class="td_row_even">
                                                <div class="td_row_even">{{b2c['csamt']}}</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <div class="td_head">cdnr</div>
                                <table style="width:100%">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="td_head">ctin</div>
                                            </td>
                                            <td>
                                                <div class="td_head">nt</div>
                                            </td>
                                        </tr>
                                        <tr  *ngFor="let cdnr of gst['cdnr']">
                                            <td class="td_row_even">
                                                <div class="td_row_even">{{cdnr['ctin']}}</div>
                                            </td>
                                            <td class="td_row_even">
                                                <table style="width:100%">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div class="td_head">ntty</div>
                                                            </td>
                                                            <td>
                                                                <div class="td_head">nt_num</div>
                                                            </td>
                                                            <td>
                                                                <div class="td_head">nt_dt</div>
                                                            </td>
                                                            <td>
                                                                <div class="td_head">pos</div>
                                                            </td>
                                                            <td>
                                                                <div class="td_head">rchrg</div>
                                                            </td>
                                                            <td>
                                                                <div class="td_head">inv_typ</div>
                                                            </td>
                                                            <td>
                                                                <div class="td_head">val</div>
                                                            </td>
                                                            <td>
                                                                <div class="td_head">itms</div>
                                                            </td>
                                                        </tr>
                                                        <tr  *ngFor="let nt of cdnr['nt']">
                                                            <td class="td_row_even">
                                                                <div class="td_row_even">{{nt['ntty']}}</div>
                                                            </td>
                                                            <td class="td_row_even">
                                                                <div class="td_row_even">{{nt['nt_num']}}</div>
                                                            </td>
                                                            <td class="td_row_even">
                                                                <div class="td_row_even">{{nt['nt_dt']}}</div>
                                                            </td>
                                                            <td class="td_row_even">
                                                                <div class="td_row_even">{{nt['pos']}}</div>
                                                            </td>
                                                            <td class="td_row_even">
                                                                <div class="td_row_even">{{nt['rchrg']}}</div>
                                                            </td>
                                                            <td class="td_row_even">
                                                                <div class="td_row_even">{{nt['inv_typ']}}</div>
                                                            </td>
                                                            <td class="td_row_even">
                                                                <div class="td_row_even">{{nt['val']}}</div>
                                                            </td>
                                                            <td class="td_row_even">
                                                                <table style="width:100%">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <div class="td_head">num</div>
                                                                            </td>
                                                                            <td>
                                                                                <div class="td_head">itm_det</div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr  *ngFor="let nt_itms of nt['itms']">
                                                                            <td class="td_row_even">
                                                                                <div class="td_row_even">{{nt_itms['num']}}</div>
                                                                            </td>
                                                                            <td class="td_row_even">
                                                                                <table style="width:100%">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <div class="td_head">rt
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div
                                                                                                    class="td_row_even">
                                                                                                    {{nt_itms['itm_det']['rt']}}</div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <div class="td_head">
                                                                                                    txval</div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div
                                                                                                    class="td_row_even">
                                                                                                    {{nt_itms['itm_det']['txval']}}</div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <div class="td_head">
                                                                                                    camt</div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div
                                                                                                    class="td_row_even">
                                                                                                    {{nt_itms['itm_det']['camt']}}</div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <div class="td_head">
                                                                                                    samt</div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div
                                                                                                    class="td_row_even">
                                                                                                    {{nt_itms['itm_det']['samt']}}</div>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <div class="td_head">
                                                                                                    csamt</div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div
                                                                                                    class="td_row_even">
                                                                                                    {{nt_itms['itm_det']['csamt']}}</div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <div class="td_head">hsn</div>
                                <table style="width:100%">
                                    <tbody>
                                        <tr>
                                            <td colspan="2">
                                                <div class="td_head">data</div>
                                                <table style="width:100%">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div class="td_head">num</div>
                                                            </td>
                                                            <td>
                                                                <div class="td_head">hsn_sc</div>
                                                            </td>
                                                            <td>
                                                                <div class="td_head">desc</div>
                                                            </td>
                                                            <td>
                                                                <div class="td_head">uqc</div>
                                                            </td>
                                                            <td>
                                                                <div class="td_head">qty</div>
                                                            </td>
                                                            <td>
                                                                <div class="td_head">val</div>
                                                            </td>
                                                            <td>
                                                                <div class="td_head">txval</div>
                                                            </td>
                                                            <td>
                                                                <div class="td_head">iamt</div>
                                                            </td>
                                                            <td>
                                                                <div class="td_head">camt</div>
                                                            </td>
                                                            <td>
                                                                <div class="td_head">samt</div>
                                                            </td>
                                                            <td>
                                                                <div class="td_head">csamt</div>
                                                            </td>
                                                        </tr>
                                                        <tr *ngFor="let hsn_data of gst['hsn']['data']">
                                                            <td class="td_row_even">
                                                                <div class="td_row_even">{{hsn_data['num']}}</div>
                                                            </td>
                                                            <td class="td_row_even">
                                                                <div class="td_row_even">{{hsn_data['hsn_sc']}}</div>
                                                            </td>
                                                            <td class="td_row_even">
                                                                <div class="td_row_even">{{hsn_data['desc']}}</div>
                                                            </td>
                                                            <td class="td_row_even">
                                                                <div class="td_row_even">{{hsn_data['uqc']}}</div>
                                                            </td>
                                                            <td class="td_row_even">
                                                                <div class="td_row_even">{{hsn_data['qty']}}</div>
                                                            </td>
                                                            <td class="td_row_even">
                                                                <div class="td_row_even">{{hsn_data['val']}}</div>
                                                            </td>
                                                            <td class="td_row_even">
                                                                <div class="td_row_even">{{hsn_data['txval']}}</div>
                                                            </td>
                                                            <td class="td_row_even">
                                                                <div class="td_row_even">{{hsn_data['iamt']}}</div>
                                                            </td>
                                                            <td class="td_row_even">
                                                                <div class="td_row_even">{{hsn_data['camt']}}</div>
                                                            </td>
                                                            <td class="td_row_even">
                                                                <div class="td_row_even">{{hsn_data['samt']}}</div>
                                                            </td>
                                                            <td class="td_row_even">
                                                                <div class="td_row_even">{{hsn_data['csamt']}}</div>
                                                            </td>
                                                        </tr>  
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <div class="td_head">doc_issue</div>
                                <table style="width:100%">
                                    <tbody>
                                        <tr>
                                            <td colspan="2">
                                                <div class="td_head">doc_det</div>
                                                <table style="width:100%">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div class="td_head">doc_num</div>
                                                            </td>
                                                            <td>
                                                                <div class="td_head">doc_typ</div>
                                                            </td>
                                                            <td>
                                                                <div class="td_head">docs</div>
                                                            </td>
                                                        </tr>
                                                        <tr *ngFor="let doc_issue of gst['doc_issue']['doc_det']">
                                                            <td class="td_row_even">
                                                                <div class="td_row_even">{{doc_issue['doc_num']}}</div>
                                                            </td>
                                                            <td class="td_row_even">
                                                                <div class="td_row_even">{{doc_issue['doc_typ']}}
                                                                </div>
                                                            </td>
                                                            <td class="td_row_even">
                                                                <table style="width:100%">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <div class="td_head">num</div>
                                                                            </td>
                                                                            <td>
                                                                                <div class="td_head">from</div>
                                                                            </td>
                                                                            <td>
                                                                                <div class="td_head">to</div>
                                                                            </td>
                                                                            <td>
                                                                                <div class="td_head">totnum</div>
                                                                            </td>
                                                                            <td>
                                                                                <div class="td_head">cancel</div>
                                                                            </td>
                                                                            <td>
                                                                                <div class="td_head">net_issue</div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr  *ngFor="let docs of doc_issue['docs']" >
                                                                            <td class="td_row_even">
                                                                                <div class="td_row_even">{{docs['num']}}</div>
                                                                            </td>
                                                                            <td class="td_row_even">
                                                                                <div class="td_row_even">{{docs['from']}}</div>
                                                                            </td>
                                                                            <td class="td_row_even">
                                                                                <div class="td_row_even">{{docs['to']}}</div>
                                                                            </td>
                                                                            <td class="td_row_even">
                                                                                <div class="td_row_even">{{docs['totnumcancel']}}</div>
                                                                            </td>
                                                                            <td class="td_row_even">
                                                                                <div class="td_row_even">{{docs['cancel']}}</div>
                                                                            </td>
                                                                            <td class="td_row_even">
                                                                                <div class="td_row_even">{{docs['net_issue']}}</div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                       
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>