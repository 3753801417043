import { Component, OnInit, ViewChild } from "@angular/core";
import swal from "sweetalert2";
import { ProductService } from "../../_services/product.service";
// import { ProductService } from "src/app/_services/product.service";
import { ConstantService } from "./../../_config/constants";
import { Router, ActivatedRoute } from "@angular/router";
import { from } from "rxjs";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { CompanyService } from "src/app/_services/company.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"]
})
export class ProductComponent implements OnInit {
  reorderable = true;
  selectedValue: any;
  page: any = { offset: 0, pageSize: 10 };
  sort: any = { name: 1 };
  dt_default_sort = [{ prop: "name", dir: "asc" }];
  limit = 10;
  filters: any = {};
  loadingIndicator = false;
  columns: any = {};
  productList: any = [];
  productListColumns: any = [];
  page_icon: any;
  product: any = {
    // data: [],
    // count: 0
  };

  // @ViewChild("productTable", { static: true }) table: any;
  productUrl: { productPath: string; productName: string }[];
  company_id: string;
  branch_id: string;
  company_category: any = {};
  company_categories: any = [
    { name: "Service", type: 2 },
    { name: "Goods", type: 1 },
  ];
count : number;
  modalReference: any;
  file: any = {};
  files: any=[];
  person:any=[{name:'hi',age:1}]
  persons=JSON.stringify([{name:'hi',age:1}])
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private productService: ProductService,
    private companyService: CompanyService,
    private authenticationService: AuthenticationService,
    private ngbModal:NgbModal
  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id =this.authenticationService.getDefaultBranchId()
    
    this.getCompanyType(this.company_id);
    this.productListColumns = [
      {
        prop: "group_name",
        name: "Product Group",
        sortable: "name",
        flexGrow: 2,
        show: true,
        is_detailed_row: false
      },
      {
        prop: "name",
        name: "Product Name",
        sortable: "name",
        flexGrow: 2,
        show: true,
        is_detailed_row: false
      }
    ];
    this.productListColumns.push({
      prop: "code",
      name: "Hsn/Sac Code",
      sortable: "code",
      flexGrow: 2,
      show: true,
      is_detailed_row: false
    });
    this.productListColumns.forEach(column => {
      this.columns[column.prop] = 1;
    });
    this.columns.type = 1
    this.page_icon = this.route.snapshot.data.menu.icon;
    if (this.route.snapshot.queryParams["q"] != "undefined")
      this.filters["name"] = this.route.snapshot.queryParams["q"];
    this.getPaginatedProduct(this.page);
  }
  async getCompanyType(company_id) {
    await this.companyService.viewCompany(company_id).subscribe(resultObj => {
      this.company_category = resultObj["response"]["company_category"];
      if (ConstantService.COMPANY_CATEGORY_IN_SERVICES.includes(this.company_category.type)) {
        this.productListColumns.push(
          {
            prop: "type",
            name: "Goods/Services",
            sortable: "name",
            type: "goodsservice",
            flexGrow: 2,
            show: true,
            is_detailed_row: false
          });
      }
      this.productListColumns.push({
        name: "Action",
        type: "action",
        prop: "_id",
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false
      });

    });
  }

  onSort(event) {
    this.loadingIndicator = true;
    this.sort = {};
    let sort_details = event.sorts[0];
    this.sort[sort_details.prop] = sort_details.dir == "asc" ? 1 : -1;
    this.getPaginatedProduct(this.page);
  }
  clearProductFilters() {
    this.filters = {};
    this.getPaginatedProduct({ offset: 0, pageSize: 10 });
  }

  getPaginatedProduct(pageInfo: any = {}) {
    if (pageInfo !== null) {
      this.page = pageInfo;
    }

    this.filters.company_id = this.company_id;
    // this.sort["name"] = 1;
    this.productService
      .getPaginatedProduct(this.filters, this.columns, this.sort, this.page)
      .subscribe(result => {
        this.loadingIndicator = false;
        this.productList = result["data"];
        this.count = result["count"];
      });
  }

  //page Redirect
  goToPage() {
    if (this.selectedValue) {
      this.router.navigate([this.selectedValue]);
    }
  }

  
  open(content) {
    this.modalReference = this.ngbModal.open(content, { size: "lg" });
  }
  getDate() {
    return new Date()
  };
  uploadFile() {
    this.files=[];
    this.files.push(this.file);
    if (this.files.length > 0) {
      let data: any = {
        company_id: this.company_id,
        branch_id: this.branch_id,
      };

      // this.httpEmitter = 
      this.productService.uploadFiles(this.files, data).subscribe((event: any) => {
        // this.httpEvent = event;
        // if (event instanceof HttpResponse) {
        console.log('result==>', event);
        if (event['total'] >0) {
          this.modalReference.close("importProduct");
          let error_string= '<p>'+event['success']+'/'+ event['total'] + ' product(s) Added Successfully ('+event['failure'] + ' failed)</p>';
          Object.keys(event['errors']).forEach(number=>{
            error_string+='<p style="text-align:left;"> product '+number+' : '+event['errors'][number]+'</p>'
          })
          swal("Success", error_string, "success");
          // swal({
          //   position: 'center',
          //   type: 'success',
          //   title: 'Product Details',
          //   html: `
          //   <p> `+event['success']+`/`+ event['total'] + ` product(s) Added Successfully (`+event['failure'] + ` failed)</p>
          //   `+Object.keys(event['errors']).forEach(doc=>{+
          //     `<p>`+ doc +`:`+ event['errors'][doc]+` </p>`
          //   })
          // });
          
          this.router.navigate(["/product/list"]);
          this.files = [];
          this.file = {};
        }
        
        // }
      },
        error => swal('Error Uploading Files: ', error.message));
    } else {
      swal("Error", "No File Found To Upload", "error");
    }

  }
}
