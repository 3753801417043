import { TestcollectionAddComponent } from "./components/testcollection/testcollection-add/testcollection-add.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LoginComponent } from "./users/login/login.component";
import { ForgotPasswordComponent } from "./users/forgot-password/forgot-password.component";

import { CompanyAddComponent } from "./components/company/company-add/company-add.component";
import { UserAddComponent } from "./components/users/user-add/user-add.component";

import { UnauthorizedComponent } from "./users/unauthorized/unauthorized.component";

import { VendorsComponent } from "./components/vendors/vendors.component";
import { CustomerComponent } from "./components/customer/customer.component";
import { AuthGuard } from "./_guards/auth.guard";
import { VendorsAddComponent } from "./components/vendors/vendors-add/vendors-add.component";
import { ProductAddComponent } from "./components/product/product-add/product-add.component";
import { CustomerAddComponent } from "./components/customer/customer-add/customer-add.component";
import { SalesOrderComponent } from "./components/sales-order/sales-order.component";
import { SalesOrderAddComponent } from "./components/sales-order/sales-order-add/sales-order-add.component";
import { ProductComponent } from "./components/product/product.component";
import { TestcollectionComponent } from "./components/testcollection/testcollection.component";
import { HsnAddComponent } from "./components/hsn/hsn-add/hsn-add.component";
import { PurchaseorderComponent } from "./components/purchaseorder/purchaseorder.component";
import { PurchaseorderAddComponent } from "./components/purchaseorder/purchaseorder-add/purchaseorder-add.component";
import { BillAddComponent } from "./components/bill/bill-add/bill-add.component";
import { BillComponent } from "./components/bill/bill.component";
import { HsnComponent } from "./components/hsn/hsn.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { PartyComponent } from "./components/party/party.component";
import { JournalAddComponent } from "./components/journal/journal-add/journal-add.component";
import { JournalComponent } from "./components/journal/journal.component";
import { CostgroupAddComponent } from "./masters/costgroup/costgroup-add/costgroup-add.component";
import { CostgroupComponent } from "./masters/costgroup/costgroup.component";
import { PaymenttransactionsComponent } from "./components/paymenttransactions/paymenttransactions.component";
import { ContraAddComponent } from "./contra/contra-add/contra-add.component";
import { ContraComponent } from "./contra/contra.component";
import { ExpenseComponent } from "./components/expense/expense.component";
import { ExpenseAddComponent } from "./components/expense/expense-add/expense-add.component";
import { ExpenseViewComponent } from "./components/expense/expense-view/expense-view.component";
import { GstComponent } from "./gst/gst.component";
import { GstAddComponent } from "./gst/gst-add/gst-add.component";
import { HomeComponent } from "./components/home/home.component";
import { InvoiceComponent } from "./components/invoice/invoice.component";
import { InvoiceAddComponent } from "./components/invoice/invoice-add/invoice-add.component";
import { CoaComponent } from "./components/coa/coa.component";
import { ReceiptsComponent } from "./components/receipts/receipts.component";
import { ReceiptsCustomerAddComponent } from "./components/receipts-customer/receipts-customer-add/receipts-customer-add.component";
import { ReceiptsVendorAddComponent } from "./components/receipts-vendor/receipts-vendor-add/receipts-vendor-add.component";

import { ReceiptsCustomerComponent } from "./components/receipts-customer/receipts-customer.component";
import { ReceiptsVendorComponent } from "./components/receipts-vendor/receipts-vendor.component";
import { ReceiptsAddComponent } from "./components/receipts/receipts-add/receipts-add.component";
import { CreditBillComponent } from "./components/credit-bill/credit-bill.component";
import { DebitBillComponent } from "./components/debit-bill/debit-bill.component";
import { BillViewComponent } from "./components/bill/bill-view/bill-view.component";
import { SalesorderViewComponent } from "./components/sales-order/sales-order-view/sales-order-view.component";
import { InvoiceViewComponent } from "./components/invoice/invoice-view/invoice-view.component";
import { PurchaseorderViewComponent } from "./components/purchaseorder/purchaseorder-view/purchaseorder-view.component";
import { ReceiptvendorViewComponent } from "./components/receipts-vendor/receipts-vendor-view/receipts-vendor-view.component";
import { StockJournalAddComponent } from "./components/stock-journal/stock-journal-add/stock-journal-add.component";
import { StockJournalComponent } from "./components/stock-journal/stock-journal.component";
import { ProductGroupComponent } from "./components/product/product-group/product-group.component";
import { ProductGroupAddComponent } from "./components/product/product-group-add/product-group-add.component";
import { StocktransactionsComponent } from "./components/stocktransactions/stocktransactions.component";
import { UsersComponent } from "./components/users/users.component";
import { UsersViewComponent } from "./components/users/users-view/users-view.component";
import { UnitsComponent } from "./components/units/units.component";
import { UnitsAddComponent } from "./components/units/units-add/units-add.component";
import { ExpensePaymentComponent } from "./components/expense-payment/expense-payment.component";
import { ReceiptPaymentComponent } from "./components/receipt-payment/receipt-payment.component";
import { SupportTicketComponent } from "./users/support-ticket/support-ticket.component";
import { SupportTicketListComponent } from "./users/support-ticket-list/support-ticket-list.component";
import { BillRegisterComponent } from "./components/bill-register/bill-register.component";
import { CashbookComponent } from "./components/account-books/cashbook/cashbook.component";
import { BankbookComponent } from "./components/account-books/bankbook/bankbook.component";
import { CreditBillViewComponent } from "./components/credit-bill/credit-bill-view/credit-bill-view.component";
import { DebitBillViewComponent } from "./components/debit-bill/debit-bill-view/debit-bill-view.component";
import { ReportCustomizationAddComponent } from "@app/reports/report-customization/report-customization-add/report-customization-add.component";
import { ReportCustomizationComponent } from "@app/reports/report-customization/report-customization.component";
import { FixedAssetsComponent } from "./fixed-assets/fixed-assets.component";
import { FixedassestsAddComponent } from "./fixed-assets/fixed-assets-add/fixed_assests-add.component";
import { VoucherTypeComponent } from "./voucher-type/voucher-type.component";
import { VoucherTypeAddComponent } from "./voucher-type/voucher-type-add/voucher-type-add.component";
import { ReportComponent } from "./reports/report/report.component";
import { FileuploadComponent } from "./components/fileupload/fileupload.component";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { GstViewComponent } from "./gst/gst-view/gst-view.component";

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
    data: { title: "Login", required_login: false },
  },
  {
    path: "user/view/:id",
    component: UsersViewComponent,
    data: {
      title: "Users",
      // quick_search: false,
      required_login: true,

      permissions: [1, 2],
    },
  },
  {
    path: "forgot_password",
    component: ForgotPasswordComponent,
    data: { title: "Forgot Password", required_login: false },
  },
  {
    path: "support-ticket",
    component: SupportTicketComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Support Ticket",
      permissions: [1, 2, 3, 4, 5],
    },
  },
  {
    path: "support-ticket/edit/:id",
    component: SupportTicketComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Edit Support Ticket",
      permissions: [1, 2, 3, 4, 5],
    },
  },
  {
    path: "support-ticket-list",
    component: SupportTicketListComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Support Tickets",
      permissions: [1, 2, 3, 4, 5],
    },
  },
  {
    path: "company/add",
    component: CompanyAddComponent,
    canActivate: [AuthGuard],
    data: {
      menu: {
        id: 99,
        name: "Company Add",
        icon: "fa fa-money",
        childrens: [],
        type: 2,
        is_common_master: true,
        is_child: true,
      },
      title: "Company Add",
      required_login: true,
      permissions: [1, 2],
      // quick_add: { name: "Company" },
    },
  },
  {
    path: "company/edit/:id",
    component: CompanyAddComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Company Edit",
      required_login: true,
      permissions: [1, 2],
    },
  },
  {
    path: "unauthorized",
    component: UnauthorizedComponent,
    canActivate: [AuthGuard],
    data: { title: "Unauthorized" },
  },
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
    canActivate: [AuthGuard],
  },

  {
    path: "dashboard",
    component: DashboardComponent,
    data: {
      permissions: [1, 2],
      required_login: true,
    },
  },

  {
    path: "home",
    component: HomeComponent,
    data: {
      permissions: [1, 2],
      required_login: true,
    },
  },
  // 1st set of menu Purchase
  {
    path: "purchase-order/list",
    component: PurchaseorderComponent,
    data: {
      title: "Purchase-Orders",
      // quick_search: true,
      required_login: true,
      menu: {
        id: 100,
        name: "Purchase",
        icon: "fa fa-money",
        childrens: [6, 21, 37, 42, 70, 101, 102],
        type: 2,
        is_common_master: false,
      },
      permissions: [1, 2, 8],
    },
  },
  {
    path: "purchase-order/list",
    component: PurchaseorderComponent,
    data: {
      title: "Purchase-Orders",
      quick_search: true,
      required_login: true,
      menu: {
        id: 6,
        name: "Purchase Orders",
        icon: "fa fa-shopping-cart",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2, 8],
    },
    canActivate: [AuthGuard]
  },
  {
    path: "receipt/vendor/list",
    component: ReceiptsVendorComponent,
    data: {
      title: "Purchase Advance",
      // quick_search: true,
      required_login: true,
      menu: {
        id: 101,
        name: "Purchase Advance",
        icon: "fa fa-file-text",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
    canActivate: [AuthGuard]

  },
  {
    path: "report/ledger",
    component: CashbookComponent,
    data: {
      title: "Ledger",
      quick_search: false,
      required_login: true,
      menu: {
        id: 310,
        name: "Ledger",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "report/party",
    component: CashbookComponent,
    data: {
      title: "Party",
      quick_search: false,
      required_login: true,
      menu: {
        id: 314,
        name: "Party",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "receipt/vendor/add",
    component: ReceiptsVendorAddComponent,
    data: {
      title: "Purchase Advance",
      //      quick_search: true,
      required_login: true,
      menu: {
        id: 102,
        //  name: "Bill Expense",
        //  icon: "fa fa-shopping-cart",
        childrens: [],
        //  is_child: true,
        //  is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "credit-bill/list",
    component: CreditBillComponent,
    data: {
      title: "Credit Note List",
      // quick_search: true,
      required_login: true,
      menu: {
        id: 39,
        name: "Credit Note",
        icon: "fa fa-shopping-cart",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "credit-bill/view/:id",
    component: CreditBillViewComponent,
    data: {
      title: "Credit Note",
      // quick_search: true,
      required_login: true,
      // menu: {
      //   id: 39,
      //   name: "Debit Note",
      //   icon: "fa fa-shopping-cart",
      //   childrens: [],
      //   is_child: true,
      //   is_common_master: false,
      // },
      permissions: [1, 2],
    },
  },
  {
    path: "debit-bill/view/:id",
    component: DebitBillViewComponent,
    data: {
      title: "Debit Note",
      // quick_search: true,
      required_login: true,
      // menu: {
      //   id: 39,
      //   name: "Debit Note",
      //   icon: "fa fa-shopping-cart",
      //   childrens: [],
      //   is_child: true,
      //   is_common_master: false,
      // },
      permissions: [1, 2],
    },
  },
  {
    path: "debit-bill/edit/:id",
    component: BillAddComponent,
    data: {
      title: "Debit Note",
      // quick_search: true,
      required_login: true,
      // menu: {
      //   id: 39,
      //   name: "Debit Note",
      //   icon: "fa fa-shopping-cart",
      //   childrens: [],
      //   is_child: true,
      //   is_common_master: false,
      // },
      permissions: [1, 2],
    },
  },
  {
    path: "bill_expense/list",
    component: ExpenseComponent,
    data: {
      title: "Expense",
      // quick_search: true,
      required_login: true,
      menu: {
        id: 70,
        name: "Expense Invoice",
        icon: "fa fa-shopping-cart",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "bill_expense/add",
    component: BillAddComponent,
    data: {
      title: "Add Expense",
      required_login: true,
      quick_add: { name: "Expense Add ", icon: "fa fa-circle" },
      menu: { id: 73, childrens: [] },
      permissions: [1, 2],
      is_common_master: false,
    },
  },
  {
    path: "purchase-order/add",
    component: PurchaseorderAddComponent,
    data: {
      title: "Purchase Order",
      required_login: true,
      quick_add: { name: "Purchase-Order Add", icon: "fa fa-circle" },
      menu: { id: 17, childrens: [] },
      permissions: [1, 2],
      is_common_master: false,
    },
  },
  {
    path: "purchase-order/edit/:id",
    component: PurchaseorderAddComponent,
    data: {
      title: "Purchase-Order Edit",
      required_login: true,
      permissions: [1, 2],
    },
  },
  {
    path: "purchase-order/view/:id",
    component: PurchaseorderViewComponent,
    data: {
      title: "Purchase-Order View",
      required_login: true,
      permissions: [1, 2],
    },
  },
  {
    path: "bill/list",
    component: BillComponent,
    data: {
      title: "Purchase Invoice",
      quick_search: true,
      required_login: true,
      menu: {
        id: 21,
        name: "Purchase Invoice",
        icon: "fa fa-file",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2, 8],
    },
  },
  {
    path: "bill/view/:id",
    component: BillViewComponent,
    data: {
      title: "Bill View",
      // quick_search: true,
      required_login: true,
      menu: {
        id: 47,
        name: "Bill View",
        icon: "fa fa-file",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "bill/list/:type/:id/:advance_id",
    component: BillComponent,
    data: {
      title: "PurchaseAdvanceMapping",
      // quick_search: true,
      required_login: true,
      menu: {
        id: 38,
        name: "PurchaseAdvanceMapping",
        icon: "fa fa-file",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  // {
  //   path: "receipt/vendor/list",
  //   component: ReceiptsVendorComponent,
  //   data: {
  //     title: "Advance",
  //     quick_search: false,
  //     required_login: true,
  //     menu: {
  //       id: 37,
  //       name: "Purchase Advance",
  //       icon: "fa fa-address-book",
  //       childrens: [],
  //       // type: 1,
  //       is_child: true,
  //       is_common_master: false,
  //     },
  //     permissions: [1, 2],
  //   },
  // },
  // {
  //   path: "receipts/vendor/add",
  //   component: ReceiptsVendorAddComponent,
  //   data: {
  //     title: "Vendor Advance",
  //     quick_search: false,
  //     required_login: true,
  //     menu: {
  //       id: 36,
  //       name: "Vendor Advance",
  //       icon: "fa fa-address-book",
  //       childrens: [],
  //       // type: 1,
  //       is_child: true,
  //       is_common_master: false,
  //     },
  //     permissions: [1, 2],
  //   },
  // },

  {
    path: "bill/add",
    component: BillAddComponent,
    data: {
      title: "Invoice Entry",
      quick_search: false,
      required_login: true,
      quick_add: { name: "Purchase-Invoice Add", icon: "fa fa-circle" },
      menu: {
        id: 22,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "bill/image/:id",
    component: BillAddComponent,
    data: {
      title: "Invoice Entry",
      quick_search: false,
      required_login: true,
      quick_add: { name: "Purchase-Invoice Add", icon: "fa fa-circle" },
      menu: {
        id: 77,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "bill/expense/:id",
    component: BillAddComponent,
    data: {
      title: "Expense Invoice",
      quick_search: false,
      required_login: true,
      menu: {
        id: 72,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "bill_expense/image/:id",
    component: BillAddComponent,
    data: {
      title: "Expense Invoice",
      quick_search: false,
      required_login: true,
      menu: {
        id: 76,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "bill/edit/:id",
    component: BillAddComponent,
    data: {
      title: "Bill Edit",
      quick_search: false,
      required_login: true,
      menu: {
        id: 23,
      },
      permissions: [1, 2],
    },
  }, {
    path: "bill_expense/edit/:id",
    component: BillAddComponent,
    data: {
      title: "Expense Edit",
      quick_search: false,
      required_login: true,
      menu: {
        id: 74,
      },
      permissions: [1, 2],
    },
  }, {
    path: "expense/edit/:id",
    component: ExpenseAddComponent,
    data: {
      title: "Expense Edit",
      quick_search: false,
      required_login: true,
      menu: {
        id: 74,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "bill/purchase_order/:id",
    component: BillAddComponent,
    data: {
      title: "Bill Add",
      quick_search: false,
      required_login: true,
      menu: {
        id: 24,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "bill/credit-note/:id",
    component: BillAddComponent,
    data: {
      title: "Debit Note",
      quick_search: false,
      required_login: true,
      menu: {
        id: 25,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "bill/debitnote/add",
    component: BillAddComponent,
    data: {
      title: "Debit Note",
      quick_search: false,
      required_login: true,
      menu: {
        // id: 25,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "expense-debitnote/:id",
    component: BillAddComponent,
    data: {
      title: "Debit Note",
      quick_search: false,
      required_login: true,
      menu: {
        // id: 25,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "invoice/creditnote/add",
    component: InvoiceAddComponent,
    data: {
      title: "Credit Note",
      quick_search: false,
      required_login: true,
      menu: {
        id: 26,
      },
      permissions: [1, 2],
    },
  },


  // 2 nd set of menu sales
  {
    path: "sales-order/list",
    component: SalesOrderComponent,
    data: {
      title: "Sales Orders",
      // quick_search: true,
      required_login: true,
      menu: {
        id: 200,
        name: "Sales",
        icon: "fa fa-file-text",
        childrens: [7, 29, 34, 39, 35],
        type: 2,
        is_common_master: false,
      },
      permissions: [1, 2, 8],
    },
  },
  {
    path: "sales-order/list",
    component: SalesOrderComponent,
    data: {
      title: "Sales Orders",
      quick_search: true,
      required_login: true,
      menu: {
        id: 7,
        name: "Sales Orders",
        icon: "fa fa-file-text",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2, 8],
    },
  },
  {
    path: "debit-bill/list",
    component: DebitBillComponent,
    data: {
      title: "Debit Note List",
      // quick_search: true,
      required_login: true,
      menu: {
        id: 42,
        name: "Debit Note List",
        icon: "fa fa-shopping-cart",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "sales-order/add",
    component: SalesOrderAddComponent,
    data: {
      title: "Sales Order",
      required_login: true,
      quick_add: { name: "Sales-Order Add", icon: "fa fa-circle" },
      menu: {
        id: 16,
        name: "Sales Add",
        icon: "fa fa-user",
        childrens: [],
        show: false,
      },

      permissions: [1, 2],
    },
  },
  {
    path: "sales-order/view/:id",
    component: SalesorderViewComponent,
    data: {
      title: "Sales Order View",
      required_login: true,
      // quick_add: { name: "Sales-Order", icon: "fa fa-circle" },

      permissions: [1, 2],
    },
  },
  {
    path: "bill-expense/:id/view",
    component: ExpenseViewComponent,
    data: {
      title: "Expense View",
      required_login: true,
      // quick_add: { name: "Sales-Order", icon: "fa fa-circle" },

      permissions: [1, 2],
    },
  },
  {
    path: "sales-order/edit/:id",
    component: SalesOrderAddComponent,
    data: {
      title: "Sales Order Edit",
      required_login: true,
      permissions: [1, 2],
    },
  },
  {
    path: "invoice/debit-note/:id",
    component: InvoiceAddComponent,
    data: {
      title: "Credit Note",
      required_login: true,
      permissions: [1, 2],
    },
  },
  {
    path: "invoice/list",
    component: InvoiceComponent,
    data: {
      title: "Sales Invoice",
      quick_search: true,
      required_login: true,
      menu: {
        id: 29,
        name: "Sales Invoice",
        icon: "fa fa-file-text",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2, 8],
    },
  },
  {
    path: "invoice/list/:type/:id/:advance_id",
    component: InvoiceComponent,
    data: {
      title: "InvoicesAdvance",
      // quick_search: true,
      required_login: true,
      menu: {
        id: 41,
        name: "Advance",
        icon: "fa fa-file-text",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "invoice/add",
    component: InvoiceAddComponent,
    data: {
      title: "Invoice Entry",
      quick_search: false,
      required_login: true,
      quick_add: { name: "Sales-Invoice Add", icon: "fa fa-circle" },
      menu: {
        id: 30,
        // name: "Invoice Add",
        // icon: "fa fa-file-text",
        childrens: [],
      },
      permissions: [1, 2],
    },
  },
  // {
  //   path: "receipt/customer/list",
  //   component: ReceiptsCustomerComponent,
  //   data: {
  //     title: "Advance",
  //     quick_search: false,
  //     required_login: true,
  //     menu: {
  //       id: 34,
  //       name: "Sales Advance",
  //       icon: "fa fa-address-book",
  //       childrens: [],
  //       // type: 1,
  //       is_child: true,
  //       is_common_master: false,
  //     },
  //     permissions: [1, 2],
  //   },
  // },
  {
    path: "receipt/customer/list",
    component: ReceiptsCustomerComponent,
    data: {
      title: "Sales Advance",
      quick_search: false,
      required_login: true,
      menu: {
        id: 34,
        name: "Sales Advance",
        icon: "fa fa-address-book",
        childrens: [],
        // type: 1,
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "receipt/customer/add",
    component: ReceiptsCustomerAddComponent,
    data: {
      title: "Sales Advance",
      // quick_search: true,
      required_login: true,
      menu: {
        id: 35,
        // name: "Invoice Add",
        // icon: "fa fa-file-text",
        childrens: [],
      },
      permissions: [1, 2],
    },
  },
  {
    path: "receipt/customer/view/:id",
    component: ReceiptsCustomerAddComponent,
    data: {
      title: "Sales Advance View",
      // quick_search: true,
      required_login: true,
      menu: {
        id: 45,
        // name: "Invoice Add",
        // icon: "fa fa-file-text",
        childrens: [],
      },
      permissions: [1, 2],
    },
  },
  {
    path: "invoice/sales-invoice/:id",
    component: InvoiceAddComponent,
    data: {
      title: "Invoice Add",
      // quick_search: true,
      required_login: true,
      menu: {
        id: 31,
        // name: "Invoice Add",
        // icon: "fa fa-file-text",
        childrens: [],
      },
      permissions: [1, 2],
    },
  },
  {
    path: "invoice/edit/:id",
    component: InvoiceAddComponent,
    data: {
      title: "Invoice Edit",
      // quick_search: true,
      required_login: true,
      menu: {
        id: 32,
        // name: "Invoice Add",
        // icon: "fa fa-file-text",
        childrens: [],
      },
      permissions: [1, 2],
    },
  },
  {
    path: ":type/view/:id",
    component: InvoiceViewComponent,
    data: {
      title: "view",
      // quick_search: true,
      required_login: true,
      menu: {
        id: 45,
        // name: "Invoice Add",
        // icon: "fa fa-file-text",
        childrens: [],
      },
      permissions: [1, 2],
    },
  },
  {
    path: 'view/:type/:id',
    component: ExpenseViewComponent,
    data: {
      title: "View",
      // quick_search: true,
      required_login: true,
      menu: {
        id: 450,
        // name: "Invoice Add",
        // icon: "fa fa-file-text",
        children: [],
      },

      permissions: [1, 2],
    },
  },
  // {
  //   path: "expense/list",
  //   component: ExpenseComponent,
  //   canActivate: [AuthGuard],
  //   data: {
  //     quick_search: false,
  //     required_login: true,
  //     title: "Receipts & Expenses",
  //     menu: {
  //       id: 300,
  //       name: "Receipts & Expenses",
  //       icon: "fa fa-user",
  //       childrens: [10, 40, 63],
  //       type: 2,
  //       is_common_master: false,
  //     },
  //     permissions: [1, 2],
  //   },
  // },

  {
    path: "invoice/list/receive",
    component: InvoiceComponent,
    canActivate: [AuthGuard],
    data: {
      // quick_search: true,
      required_login: true,
      title: "Receivables",
      menu: {
        id: 56,
        name: "Receivables",
        icon: "fa  fa-sticky-note-o",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "bill/list/payment",
    component: BillComponent,
    canActivate: [AuthGuard],
    data: {
      // quick_search: true,
      required_login: true,
      title: "Payables",
      menu: {
        id: 57,
        name: "Payables",
        icon: "fa  fa-sticky-note-o",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  // {
  //   path: "expense/add",
  //   component: ExpenseAddComponent,
  //   data: {
  //     title: "Add Expense",
  //     quick_search: true,
  //     required_login: true,
  //     menu: {
  //       id: 63,
  //       name: "Expense",
  //       icon: "fa fa-user",
  //       childrens: [],
  //       is_child: true,
  //       is_common_master: false,
  //     },
  //     permissions: [1, 2],
  //   },
  // },

  {
    path: "receipts/add",
    component: ReceiptsAddComponent,
    data: {
      title: "Add Receipts",
      quick_search: true,
      required_login: true,
      menu: {
        id: 64,
        name: "Receipts Booking",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "receipt/list",
    component: ReceiptsComponent,
    // canActivate: [AuthGuard],
    data: {
      quick_search: false,
      required_login: true,
      title: "Receipt",
      menu: {
        id: 71,
        name: "Receipt",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2, 8],
    },
  },
  {
    path: "expense/add",
    component: ExpenseAddComponent,
    data: {
      title: "Add Expense",
      quick_search: true,
      required_login: true,
      menu: {
        id: 634,
        name: "Payment",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "expense/list",
    component: ExpensePaymentComponent,
    data: {
      title: "Payment List",
      quick_search: true,
      required_login: true,
      menu: {
        id: 63,
        name: "Payment",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2, 8],
    },
  },
  {
    path: "paymenttransactions/list",
    component: PaymenttransactionsComponent,
    data: {
      title: "Account Transactions ",
      quick_search: false,
      required_login: true,
      menu: {
        id: 400,
        name: "Transactions",
        icon: "fa fa-money",
        childrens: [71, 63, 9, 11, 43, 48, 60, 66, 56, 57],
        type: 2,
        is_common_master: false,
      },
      permissions: [1, 2, 8],
    },
  },
  // {
  //   path: "expense/direct_expense",
  //   component: ExpenseAddComponent,
  //   data: {
  //     title: "Expense payment",
  //     quick_search: false,
  //     required_login: true,
  //     quick_add: { name: "Direct Expense", icon: "fa fa-circle" },
  //     menu: {
  //       id: 65,
  //       name: "Direct Expense",
  //       icon: "fa fa-user",
  //       childrens: [],
  //       is_child: true,
  //       is_common_master: false,
  //     },
  //     permissions: [1, 2],
  //   },
  // },
  // {
  //   path: "expense/:type",
  //   component: ExpenseAddComponent,
  //   data: {
  //     title: "Add Expense",
  //     quick_search: true,
  //     required_login: true,
  //     menu: {
  //       id: 67,
  //       name: "Expense Payment",
  //       icon: "fa fa-user",
  //       childrens: [],
  //       is_child: true,
  //       is_common_master: false,
  //     },
  //     permissions: [1, 2],
  //   },
  // },
  // {
  //   path: "receipts/:type",
  //   component: ReceiptsAddComponent,
  //   data: {
  //     title: "Add Receipts",
  //     // quick_search: true,
  //     required_login: true,
  //     menu: {
  //       id: 68,
  //       name: "Receipts Payment",
  //       icon: "fa fa-user",
  //       childrens: [],
  //       is_child: true,
  //       is_common_master: false,
  //     },
  //     permissions: [1, 2],
  //   },
  // },
  {
    path: "receipts/:type/:id",
    component: ReceiptsAddComponent,
    data: {
      title: "Receipts",
      // quick_search: false,
      required_login: true,
      menu: {
        id: 103,
        childrens: [],
      },
      permissions: [1, 2],
    },
  },


  {
    path: "paymenttransactions/list",
    component: PaymenttransactionsComponent,
    data: {
      title: "Account Transactions ",
      // quick_search: true,
      required_login: true,
      menu: {
        id: 12,
        name: "Ledger",
        icon: "fa fa-money",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },

  {
    path: "contra/list",
    component: ContraComponent,
    data: {
      title: "Contra ",
      // quick_search: true,
      required_login: true,
      menu: {
        id: 9,
        name: "Contra",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "contra/add",
    component: ContraAddComponent,
    data: {
      title: "Contra Add",
      quick_search: false,
      required_login: true,
      menu: {
        id: 14,
        childrens: [],
      },
      permissions: [1, 2],
    },
  },
  {
    path: "contra/edit/:id",
    component: ContraAddComponent,
    data: {
      title: "Edit Contra",
      permissions: [1, 2],
    },
  },

  {
    path: "journal/list",
    component: JournalComponent,
    data: {
      title: "Journal",
      quick_search: false,
      required_login: true,
      // quick_add: { name: "Journal", icon: "fa fa-circle" },
      menu: {
        id: 11,
        name: "Journal",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },

  {
    path: "journal/add",
    component: JournalAddComponent,
    data: {
      title: "Journal Add",
      quick_search: false,
      required_login: true,
      menu: {
        id: 666,
        childrens: [],
      },
      permissions: [1, 2],
    },
  },
  {
    path: "opening_balance",
    component: JournalAddComponent,
    data: {
      title: "Opening Balance Add",
      quick_search: false,
      required_login: true,
      menu: {
        id: 666,
        childrens: [],
      },
      permissions: [1, 2],
    },
  },

  {
    path: "stockjournal/list",
    component: StockJournalComponent,
    data: {
      title: "Stock Journal",
      quick_search: false,
      required_login: true,
      // quick_add: { name: "Journal", icon: "fa fa-circle" },
      menu: {
        id: 60,
        name: "Stock Journal",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "stockjournal/add",
    component: StockJournalAddComponent,
    data: {
      title: "Stock Journal Add",
      quick_search: false,
      required_login: true,
      menu: {
        id: 667,
        childrens: [],
      },
      permissions: [1, 2],
    },
  },

  {
    path: "receipts/direct_receipt",
    component: ReceiptsAddComponent,
    data: {
      title: "Report ",
      quick_search: false,
      required_login: true,
      menu: {
        id: 600,
        name: "Reports",
        icon: "fa fa-list",
        childrens: [
          12,
          46,
          49,
          300,
          301,
          302,
          303,
          304,
          305,
          306,
          307,
          308,
          309,
          311,
          312,
          313,
          315,

          318,
          319,
          320,
          321,
          322,
          323,
          324,
          325,
          326,
        ],
        type: 2,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },

  {
    path: "receipts/direct_receipt",
    component: ReceiptsAddComponent,
    data: {
      title: "Receipt payment",
      // quick_search: false,
      required_login: true,
      // quick_add: { name: "Receipt payment", icon: "fa fa-circle" },
      menu: {
        id: 90,
        name: "Payment",
        icon: "fa fa-money",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "stocktransactions/list",
    component: StocktransactionsComponent,
    data: {
      title: "Stock Transactions",
      quick_search: false,
      required_login: true,
      // quick_add: { name: "Journal", icon: "fa fa-circle" },
      menu: {
        id: 46,
        name: "Stock Transactions",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "report/cashbook",
    component: CashbookComponent,
    data: {
      title: "Account Book",
      quick_search: false,
      required_login: true,
      // quick_add: { name: "Journal", icon: "fa fa-circle" },
      menu: {
        id: 300,
        name: "Cash Book",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "report/bankbook",
    component: CashbookComponent,
    data: {
      title: "Account Book",
      quick_search: false,
      required_login: true,
      // quick_add: { name: "Journal", icon: "fa fa-circle" },
      menu: {
        id: 301,
        name: "Bank Book",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "report/vendor_ledgeraccount",
    component: CashbookComponent,
    data: {
      title: "Account Book",
      quick_search: false,
      required_login: true,
      // quick_add: { name: "Journal", icon: "fa fa-circle" },
      menu: {
        id: 302,
        name: "Vendor Ledger",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "report/sales_register",
    component: CashbookComponent,
    data: {
      title: "Register",
      quick_search: false,
      required_login: true,
      // quick_add: { name: "Journal", icon: "fa fa-circle" },
      menu: {
        id: 303,
        name: "Sales Register",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "report/purchase_register",
    component: CashbookComponent,
    data: {
      title: "Register",
      quick_search: false,
      required_login: true,
      // quick_add: { name: "Journal", icon: "fa fa-circle" },
      menu: {
        id: 304,
        name: "Purchase Register",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "report/sales_ledger",
    component: CashbookComponent,
    data: {
      title: "Register",
      quick_search: false,
      required_login: true,
      // quick_add: { name: "Journal", icon: "fa fa-circle" },
      menu: {
        id: 305,
        name: "Sales Ledger",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "report/purchase_ledger",
    component: CashbookComponent,
    data: {
      title: "Register",
      quick_search: false,
      required_login: true,
      // quick_add: { name: "Journal", icon: "fa fa-circle" },
      menu: {
        id: 306,
        name: "Purchase Ledger",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "report/receipt",
    component: CashbookComponent,
    data: {
      title: "Register",
      quick_search: false,
      required_login: true,
      // quick_add: { name: "Journal", icon: "fa fa-circle" },
      menu: {
        id: 307,
        name: "Receipt Report",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "report/payment",
    component: CashbookComponent,
    data: {
      title: "Register",
      quick_search: false,
      required_login: true,
      // quick_add: { name: "Journal", icon: "fa fa-circle" },
      menu: {
        id: 308,
        name: "Payment Report",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "report/stock_summary",
    component: CashbookComponent,
    data: {
      title: "Stock Summary",
      quick_search: false,
      required_login: true,
      // quick_add: { name: "Journal", icon: "fa fa-circle" },
      menu: {
        id: 320,
        name: "Stock Summary",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "report/stock_item_report",
    component: CashbookComponent,
    data: {
      title: "Register",
      quick_search: false,
      required_login: true,
      // quick_add: { name: "Journal", icon: "fa fa-circle" },
      menu: {
        id: 321,
        name: "Stock Item Report",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "report/stock_ageing_analysis",
    component: CashbookComponent,
    data: {
      title: "Register",
      quick_search: false,
      required_login: true,
      // quick_add: { name: "Journal", icon: "fa fa-circle" },
      menu: {
        id: 322,
        name: "Stock Ageing Analysis",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "report/credit_note",
    component: CashbookComponent,
    data: {
      title: "Register",
      quick_search: false,
      required_login: true,
      // quick_add: { name: "Journal", icon: "fa fa-circle" },
      menu: {
        id: 312,
        name: "Credit Note Report",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "report/debit_note",
    component: CashbookComponent,
    data: {
      title: "Register",
      quick_search: false,
      required_login: true,
      // quick_add: { name: "Journal", icon: "fa fa-circle" },
      menu: {
        id: 313,
        name: "Debit Note Report",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "report/sales_order_register",
    component: CashbookComponent,
    data: {
      title: "Register",
      quick_search: false,
      required_login: true,
      menu: {
        id: 318,
        name: "SO Register Report",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "report/purchase_order_register",
    component: CashbookComponent,
    data: {
      title: "Register",
      quick_search: false,
      required_login: true,
      menu: {
        id: 319,
        name: "PO Register Report",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "report/bills_payable",
    component: CashbookComponent,
    data: {
      title: "Bills Payable",
      quick_search: false,
      required_login: true,
      // quick_add: { name: "Journal", icon: "fa fa-circle" },
      menu: {
        id: 323,
        name: "Bills Payable",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "report/bills_receivable",
    component: CashbookComponent,
    data: {
      title: "Bills Receivable",
      quick_search: false,
      required_login: true,
      // quick_add: { name: "Journal", icon: "fa fa-circle" },
      menu: {
        id: 324,
        name: "Bills Receivable",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "report/journal_register",
    component: CashbookComponent,
    data: {
      title: "Register",
      quick_search: false,
      required_login: true,
      // quick_add: { name: "Journal", icon: "fa fa-circle" },
      menu: {
        id: 315,
        name: "Journal Register Report",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  // {
  //   path: "report/bill-register/list",
  //   component: BillRegisterComponent,
  //   data: {
  //     title: "Register",
  //     quick_search: false,
  //     required_login: true,
  //     // quick_add: { name: "Journal", icon: "fa fa-circle" },
  //     menu: {
  //       id: 49,
  //       name: "Bill Register",
  //       icon: "fa fa-user",
  //       childrens: [],
  //       is_child: true,
  //       is_common_master: false,
  //     },
  //     permissions: [1, 2],
  //   },
  // },
  // {
  //   path: "report/bill-register/list",
  //   component: BillRegisterComponent,
  //   data: {
  //     title: "Register",
  //     quick_search: false,
  //     required_login: true,
  //     // quick_add: { name: "Journal", icon: "fa fa-circle" },
  //     menu: {
  //       id: 49,
  //       name: "Bill Register",
  //       icon: "fa fa-user",
  //       childrens: [],
  //       is_child: true,
  //       is_common_master: false,
  //     },
  //     permissions: [1, 2],
  //   },
  // },
  //dummy menu for showing before login
  {
    path: "masters/hsn/list",
    component: HsnComponent,
    data: {
      //quick_search: true,
      menu: {
        id: 500,
        name: "Masters",
        icon: "fa fa-gear",
        childrens: [28, 37, 62, 99, 201],
        type: 2,
        is_common_master: true,
      },
      permissions: [1, 2],
    },
  },
  //dummy menu for showing after login
  {
    path: "masters/hsn/list",
    component: HsnComponent,
    data: {
      //quick_search: true,
      menu: {
        id: 501,
        name: "Masters",
        icon: "fa fa-gear",
        childrens: [33, 31, 44, 8],
        type: 2,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "masters/hsn/list",
    component: HsnComponent,
    data: {
      //quick_search: true,
      menu: {
        id: 28,
        name: "GST",
        icon: "fa fa-file",
        childrens: [],
        is_child: true,
        is_common_master: true,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "masters/hsn/item/:type",
    component: HsnAddComponent,
    data: {
      // menu: { id: 21 },
      permissions: [1, 2],
    },
  },
  {
    path: "masters/hsn/item/:type/:id",
    component: HsnAddComponent,
    data: {
      // menu: { id: 21 },
      permissions: [1, 2],
    },
  },
  {
    path: "product/list",
    component: ProductComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Products",
      // quick_search: true,
      required_login: true,
      menu: {
        id: 31,
        name: "Products",
        icon: "fa fa-cube",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "product/group/list",
    component: ProductGroupComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Products Group",
      // quick_search: true,
      required_login: true,
      menu: {
        id: 44,
        name: "Products Group",
        icon: "fa fa-cube",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "product/add/:type",
    component: ProductAddComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Add Product",
      required_login: true,
      // quick_add: { name: "Product", icon: "fa fa-circle" },
      menu: { id: 20, childrens: [] },
      permissions: [1, 2],
    },
  },
  {
    path: "product/edit/:type",
    component: ProductAddComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Edit Product",
      required_login: true,
      // quick_add: { name: "Product", icon: "fa fa-circle" },
      menu: { id: 69, childrens: [] },
      permissions: [1, 2],
    },
  },
  {
    path: "product/group/add",
    component: ProductGroupAddComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Add Product Group",
      required_login: true,
      // quick_add: { name: "Product Group", icon: "fa fa-circle" },
      menu: { id: 52, childrens: [] },
      permissions: [1, 2],
    },
  },
  {
    path: "party/list",
    component: PartyComponent,
    data: {
      title: "Party",
      quick_search: false,
      required_login: true,
      menu: {
        id: 33,
        name: "Party",
        icon: "fa fa-address-book",
        childrens: [],
        // type: 1,
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },

  {
    path: "customer/list",
    component: CustomerComponent,
    data: {
      // quick_search: true,
      required_login: true,
      title: "Customers",
      menu: {
        id: 4,

        name: "Customers",
        icon: "fa fa-address-card",
        childrens: [],
        show: false,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "customer/add",
    component: CustomerAddComponent,
    data: {
      title: "Add Customer",
      // quick_add: { name: "Customer", icon: "fa fa-circle" },
      menu: { id: 19, childrens: [] },
      permissions: [1, 2],
    },
  },
  {
    path: "customer/edit/:id",
    component: CustomerAddComponent,
    data: {
      title: "Edit Customer",

      permissions: [1, 2],
    },
  },
  {
    path: "vendor/list",
    component: VendorsComponent,
    data: {
      title: "Vendors",
      permissions: [1, 2],
      // quick_search: true,
      required_login: true,
      menu: {
        id: 5,
        name: "Vendors",
        icon: "fa fa-circle",
        childrens: [],
        show: false,
        is_common_master: false,
      },
      // , name: 'Vendors', icon: 'fa fa-circle', childrens: [3]
    },
  },
  {
    path: "vendor/add",
    component: VendorsAddComponent,
    data: {
      title: "Add Vendors",
      permissions: [1, 2],
      // quick_add: { name: "Vendor", icon: "fa fa-circle" },
      menu: { id: 18, childrens: [] },
    },
  },
  {
    path: "vendor/edit/:id",
    component: VendorsAddComponent,
    data: {
      title: "Edit Vendor",
      permissions: [1, 2],
    },
  },

  {
    path: "costgroup/list",
    component: CostgroupComponent,
    data: {
      title: "Cost Center ",
      // quick_search: true,
      required_login: true,
      menu: {
        id: 8,
        name: "Cost Center",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "costgroup/add",
    component: CostgroupAddComponent,
    data: {
      title: "Cost Center Add",
      quick_search: false,
      required_login: true,
      menu: {
        id: 15,
        childrens: [],
      },
      permissions: [1, 2],
    },
  },

  {
    path: "tax/list",
    component: GstComponent,
    data: {
      title: "Tax ",
      quick_search: false,
      required_login: true,
      menu: {
        id: 36,
        name: "Tax",
        icon: "fa fa-user",
        childrens: [],
        is_common_master: true,
      },
      permissions: [1, 2],
    },
  },

  {
    path: "coa/add",
    component: CoaComponent,
    data: {
      title: "Chart Of Accounts",
      quick_search: false,
      required_login: true,
      menu: {
        id: 37,
        name: "Chart Of Accounts",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: true,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "coa/edit",
    component: CoaComponent,
    data: {
      title: "Chart Of Accounts",
      quick_search: false,
      required_login: true,

    },
  },
  {
    path: "masters/unit/list",
    component: UnitsComponent,
    data: {
      //quick_search: true,
      menu: {
        id: 62,
        name: "Units",
        icon: "fa fa-file",
        childrens: [],
        is_child: true,
        is_common_master: true,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "masters/units/add",
    component: UnitsAddComponent,
    data: {
      // menu: { id: 21 },
      permissions: [1, 2],
    },
  },
  {
    path: "masters/voucher_type/list",
    component: VoucherTypeComponent,
    data: {
      //quick_search: true,
      menu: {
        id: 201,
        name: "Voucher Type",
        icon: "fa fa-file",
        childrens: [],
        is_child: true,
        is_common_master: true,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "masters/voucher_type/add",
    component: VoucherTypeAddComponent,
    data: {
      // menu: { id: 21 },
      permissions: [1, 2],
    },
  },
  {
    path: "masters/voucher_type/edit/:id",
    component: VoucherTypeAddComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Edit Voucher Type",
      permissions: [1, 2, 3, 4, 5],
    },
  },
  {
    path: "gst/add",
    component: GstAddComponent,
    data: {
      title: "Add Gst",
      // quick_add: { name: "Gst", icon: "fa fa-circle" },
      menu: { id: 27, childrens: [], is_common_master: false },
      permissions: [1, 2],
    },
  },
  {
    path: "testcollection/list",
    component: TestcollectionComponent,
    data: {
      title: "Test Collection ",
      required_login: true,

      // , name: 'Test ', icon: 'fa fa-user', childrens: [10]
      permissions: [1, 2],
    },
  },
  {
    path: "testcollection/add",
    component: TestcollectionAddComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Test Add",
      required_login: true,
      permissions: [1, 2],
    },
  },
  {
    path: "user/list",
    component: UsersComponent,
    data: {
      title: "Users",
      // quick_search: false,
      required_login: true,
      menu: {
        id: 50,
        name: "Users",
        icon: "fa fa-user",
        childrens: [],
        is_common_master: true,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "fixed_assets/viewall",
    component: FixedAssetsComponent,
    data: {
      title: "Fixed Assets",
      quick_search: false,
      required_login: true,
      menu: {
        id: 401,
        name: "Fixed Assets",
        icon: "fa fa-user",
        childrens: [],
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "fixed_assets/add",
    component: FixedassestsAddComponent,
    data: {
      title: "Fixed-Assests Add",
      required_login: true,
      menu: { id: 402, childrens: [] },
      permissions: [1, 2],
      is_common_master: false,
    },
  },
  {
    path: "fixed_assets/edit/:id",
    component: FixedassestsAddComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Fixed-Assests Edit",
      // required_login: true,
      permissions: [1, 2],
    },
  },

  {
    path: "user/list",
    component: UsersComponent,
    data: {
      title: "Users",
      // quick_search: false,
      required_login: true,
      menu: {
        id: 51,
        name: "Users",
        icon: "fa fa-user",
        childrens: [],
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "user/add",
    component: UserAddComponent,
    canActivate: [AuthGuard],
    data: {
      title: "User Add",
      required_login: true,
      permissions: [1, 2],
    },
  },
  {
    path: "user/edit/:id",
    component: UserAddComponent,
    canActivate: [AuthGuard],
    data: {
      title: "User Edit",
      required_login: true,
      permissions: [1, 2],
    },
  },
  {
    path: "user/reset_password/:unique_id",
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Reset Password",
      required_login: false,
      // permissions: [1, 2]
    },
  },
  {
    path: "report-customization/add",
    component: ReportCustomizationAddComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Add Report",
      required_login: true,
      permissions: [1, 2],
    },
  },
  {
    path: "report-customization/edit/:id",
    component: ReportCustomizationAddComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Edit Report",
      required_login: true,
      permissions: [1, 2],
    },
  },
  {
    path: "report/hsn_report",
    component: CashbookComponent,
    data: {
      title: "Hsn",
      quick_search: false,
      required_login: true,
      // quick_add: { name: "Journal", icon: "fa fa-circle" },
      menu: {
        id: 326,
        name: "Hsn",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "report/aeging_report",
    component: CashbookComponent,
    data: {
      title: "Aeging",
      quick_search: false,
      required_login: true,
      // quick_add: { name: "Journal", icon: "fa fa-circle" },
      menu: {
        id: 325,
        name: "Aeging",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "reports",
    component: ReportComponent,
    data: {
      title: "Reports",
      menu: { id: 43, childrens: [], is_common_master: false },
      permissions: [1, 2],
    },
  },
  {
    path: "fileupload/list",
    component: FileuploadComponent,
    // canActivate: [AuthGuard],
    data: {
      quick_search: false,
      required_login: true,
      title: "Fileupload",
      menu: {
        id: 505,
        name: "Fileupload",
        icon: "fa fa-user",
        childrens: [],
        is_child: true,
        is_common_master: false,
      },
      permissions: [1, 2, 8],
    },
  },
  {
    path: "gst/list",
    component: GstComponent,
    data: {
      title: "GST",
      // quick_search: true,
      required_login: true,
      menu: {
        id: 801,
        name: "GST",
        icon: "fa fa-user",
        is_child: false,
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: "gst/:id",
    component: GstViewComponent,
    data: {
      title: "Gst View",
      // quick_search: true,
      menu: {
        id: 802,
        childrens: [],
        is_common_master: false,
      },
      permissions: [1, 2],
    },
  },
  {
    path: ":type/:id/view",
    component: ReceiptvendorViewComponent,
    data: {
      title: "View",
      required_login: true,
      permissions: [1, 2],
    }
  },
  {
    path: "report/:type/:id",
    component: ReportComponent,
    data: {
      title: "Account Book",
      quick_search: false,
      required_login: true,
      permissions: [1, 2],
    }
  },
  {
    path: "reportcustomization/:type/:id",
    component: ReportCustomizationAddComponent,
    data: {
      title: "ReportCustomizationAdd",
      quick_search: false,
      required_login: true,
      permissions: [1, 2],
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule { }