import { Country, State } from './country.model';
import { Currency } from './currency.model';
import { ContactAddress} from './contact_address.model';
import {Contacts} from './contacts.model';
export class Customer {
    customer_name: string;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    mobile: string;
    country: Country = new Country();
    state: State = new State();
    address_line1: string;
    address_line2: string;
    city: string;
    postal_code: string;
    currency: Currency = new Currency();
    payment_term: string;
    gst_number: string;
    company_id: string;
    branch_id: string;
    billing_address: string;
    shipping_address: string;
    is_sez: Boolean = false;
    contact_address: ContactAddress[] = [];
    gst_effective_date: any;
    tds_effective_date: any;
    pan: string;
    contacts: Contacts[] = [];
}
