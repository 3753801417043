export class Receipts {
  payment_method: Object;
  payment_account: any;
  receipt_items: any = [];
  description: string;
  customer: any = {};
  advance_amount: number;
  balance_amount: number;
  total_tax_amount: number;
  tx_type_id: number;
  total_receipt_amount: number;
  vendor: any = {};
  payee: Object;
  type: string;
  party: Object;
  company_id: string;
  payment_date: any;
  bill_number: string;
  invoice_number: string;
  party_id: Object;
  amount: number;
  is_booking_payment: number;
  ref_id: any;
  voucher_number: any;
  ledger: Ledger = new Ledger();
  cgst: number;
  sgst: number;
  igst: number;
  pay_type: number;
  voucher_name: string;
  voucher_object: {};
}
export class Ledger {
  name: string;
  id: number;
  ref_id: string;
  parent_id: any = [];
  parent_name: any = [];
}
