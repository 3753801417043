<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header d-flex align-items-center">
          <h3 class="table-title">Purchaseorder View</h3>
        </div>
        <form novalidate #addPurchaseorderForm="ngForm">
          <div class="card-body row view">
            <div class="form-group  col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="vendor">Vendor</label>
              <!-- <ng-select *ngIf="!is_credit_note" [items]="vendor_lists" [typeahead]="vendorListTypeahead"
                #vendor="ngModel"  name="vendor"
                (change)="setCurrency(purchaseorder.vendor.currency);getTotalAmount();" bindLabel="vendor_name"
                [(ngModel)]="purchaseorder.vendor" placeholder="Select Vendor">
                <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div [ngOptionHighlight]="search">
                    {{ item.vendor_name }}
                  </div>
                </ng-template> -->
              <!-- <ng-template *ngIf="vendor_lists==''">
                  <button (click)=""> Add Customer</button>
                </ng-template> -->
              <!-- </ng-select> -->
              <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3"> -->
              <div>
                {{purchaseorder?.vendor?.vendor_name}}
              </div>
              <!-- </div> -->
              <!-- <span>
                {{purchaseorder?.vendor?.currency.name}}
              </span>
              <app-validation-errors [mformField]="vendor" label="Vendor"></app-validation-errors> -->
            </div>

            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="purchaseorder_number">Purchase Order Number</label>
              <div> {{purchaseorder.purchase_order_number}}</div>
            </div>
            <div class="form-group  col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="purchaseorder_date">Purchase Order Date</label>
              <!-- <div class="input-group" *ngIf="!is_credit_note">
                <input class="form-control" #purchaseorder_date="ngModel" name="purchase_order_date"
                  angular-mydatepicker #dp_purchaseorder="angular-mydatepicker"  placeholder="Select a date"
                  (click)="dp_purchaseorder.toggleCalendar()" [(ngModel)]="purchaseorder.purchase_order_date"
                  [options]="purchaseorderDateOptions" />

                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" *ngIf="purchaseorder.purchase_order_date"
                    (click)="dp_purchaseorder.clearDate()">
                    <i class="fa fa-close"></i>
                  </button>

                </div>
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" (click)="dp_purchaseorder.toggleCalendar()">
                    <i class="fa fa-calendar-o"></i>
                  </button>
                </div>
              </div> -->
              <div>
                {{purchaseorder.purchase_order_date| date}}
              </div>
              <!-- <app-validation-errors [mformField]="purchaseorder_date" label="Purchaseorder Date">
              </app-validation-errors> -->
            </div>

            <!-- <div class="form-group col-12  col-sm-6 col-md-3 col-xl-3">
              <label for="due_date">Due Date</label> -->
            <!-- <div class="input-group" *ngIf="!is_credit_note">
                <input class="form-control" #due_date="ngModel" name="due_date" placeholder="Select a date"
                  angular-mydatepicker #dp_duedate="angular-mydatepicker" [(ngModel)]="purchaseorder.due_date"
                  [options]="dueDateOptions" (click)="dp_duedate.toggleCalendar()" />
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" *ngIf="purchaseorder.due_date"
                    (click)="dp_duedate.clearDate()">
                    <i class="fa fa-close"></i>
                  </button>
                </div>
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" (click)="dp_duedate.toggleCalendar()">
                    <i class="fa fa-calendar-o"></i>
                  </button>
                </div>
              </div> -->
            <!-- <div>
                {{purchaseorder.due_date| date}}
              </div> -->
            <!-- <app-validation-errors [mformField]="due_date" label="Due Date">
              </app-validation-errors> -->
            <!-- </div> -->


            <!-- <div class="form-group col-12  col-sm-6 col-md-3 col-xl-3" *ngIf="is_credit_note">
              <label for="credit_date">Credit Date</label> -->
            <!-- <div class="input-group">
                <input class="form-control" #credit_date="ngModel" name="credit_date" placeholder="Select a date"
                  angular-mydatepicker #dp_creditdate="angular-mydatepicker" [(ngModel)]="purchaseorder.credit_date"
                  [options]="creditDateOptions" (click)="dp_creditdate.toggleCalendar()" />
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" *ngIf="purchaseorder.credit_date"
                    (click)="dp_creditdate.clearDate()">
                    <i class="fa fa-close"></i>
                  </button>
                </div>
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" (click)="dp_creditdate.toggleCalendar()">
                    <i class="fa fa-calendar-o"></i>
                  </button>
                </div>
              </div> -->
            <!-- <div>
                {{purchaseorder.credit_date| date}}
              </div> -->
            <!-- <app-validation-errors [mformField]="credit_date" label="Credit Date">
              </app-validation-errors> -->
            <!-- </div> -->
            <!-- <div class="form-group col-12  col-sm-6 col-md-3 col-xl-3">
              <label for="credit_notes">Notes</label> -->
            <!-- <textarea class="form-control" name="credit_notes" id="credit_notes" placeholder="Notes"
                [(ngModel)]="purchaseorder.credit_notes"></textarea> -->
            <!-- </div> -->
            <!-- <div class="form-group  col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="account_head">Account Head-Liability</label>
              <ng-select [items]="account_head_list" #liability="ngModel"  name="account_head" bindLabel="name"
                [(ngModel)]="purchaseorder.account_head" placeholder="Select Liability Account Head">
                <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div [ngOptionHighlight]="search">
                    {{ item.name }}
                  </div>
                </ng-template>
              </ng-select>
              <app-validation-errors [mformField]="account_head" label="Account Head"></app-validation-errors>
            </div> -->
            <div class="card">
              <div class="card-body form-row p-0">
                <table
                  class="col-12 table table-hover table-vcenter table_custom text-nowrap spacing5 text-nowrap mb-0">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th class="">{{company_details.purchase_column_settings.items}}</th>
                      <th>{{company_details.purchase_column_settings.description}}</th>
                      <th>{{company_details.purchase_column_settings.units}}</th>
                      <th>{{company_details.purchase_column_settings.price}}</th>
                      <th>Tax%</th>
                      <th>Tax Amount</th>
                      <th>{{company_details.purchase_column_settings.amount}}</th>
                      <!-- <th>Action</th> -->
                    </tr>

                  </thead>
                  <tbody *ngFor="let itemsObj of purchaseorder.purchase_order_items; let i = index">
                    <tr>
                      <td>
                        {{i+1}}
                      </td>
                      <td>
                        <!-- <ng-select *ngIf="!is_credit_note" [items]="product_lists" [typeahead]="productListTypeahead"
                          #product="ngModel"  name="items{{i}}" bindLabel="name"
                          (change)="getPriceFromHSN(itemsObj.product,itemsObj);getTaxAmount(itemsObj);getAmount(itemsObj);"
                          [(ngModel)]="itemsObj.product" placeholder="Select Items">
                          <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index"
                            let-search="searchTerm">
                            <div [ngOptionHighlight]="search">
                              {{ item.name }}
                            </div>
                          </ng-template>
                        </ng-select> -->
                        <div>
                          {{itemsObj.product.name}}
                        </div>
                        <!-- <app-validation-errors [mformField]="product" label="Product"></app-validation-errors> -->

                      </td>
                      <td>
                        <!-- <input type="text" *ngIf="!is_credit_note" class="form-control" name="description{{i}}"
                          id="description" placeholder="Description" [(ngModel)]="itemsObj.description"> -->
                        <div>
                          {{itemsObj.description}}
                        </div>
                      </td>

                      <td>
                        <!-- <input type="number" [min]="1" class="form-control" #units="ngModel"  name="units{{i}}"
                          id="units{{i}}" placeholder="Units" [(ngModel)]="itemsObj.units"
                          (change)="getTaxAmount(itemsObj);getAmount(itemsObj);getTotalAmount();"> -->
                        <!-- <app-validation-errors [mformField]="units" label="Units"></app-validation-errors> -->
                        <div>{{itemsObj.units}}</div>

                      </td>

                      <td>
                        <!-- <input type="number" [min]="1" *ngIf="!is_credit_note" class="form-control" #price="ngModel"
                          name="price{{i}}" id="price{{i}}" placeholder="Price" [(ngModel)]="itemsObj.price"
                          (change)="getTaxAmount(itemsObj);getAmount(itemsObj);getTotalAmount();">
                        <app-validation-errors [mformField]="price" label="Price"></app-validation-errors>
                        <div *ngIf="is_credit_note">{{itemsObj.price}}</div> -->
                        <div>{{itemsObj.price | currency: currency}}</div>
                      </td>
                      <td *ngIf="itemsObj.tax==undefined">
                        0%
                        <!-- <input type="number" class="form-control" name="tax{{i}}" id="tax"
                          placeholder="Tax in percentage" [(ngModel)]="itemsObj.tax" title="Tax in percentage"> -->
                      </td>
                      <td *ngIf="itemsObj.tax!=undefined"
                        title="sgst:{{itemsObj.sgst}} cgst:{{itemsObj.cgst}} igst:{{itemsObj.igst}}">
                        {{itemsObj.tax}}%
                      </td>
                      <td>
                        {{itemsObj.tax_amount | currency: currency}}
                      </td>
                      <td>
                        {{itemsObj.amount | currency: currency}}
                      </td>

                      <!-- <td>
                        <button type="button" class="btn btn-danger" (click)="deleteItem(i);getTotalAmount();">
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
                <!-- <div>
                  <button type="button" *ngIf="!is_credit_note" class="btn btn-primary mr-2 ml-2 mb-2"
                    (click)="!addNewItem()">Add
                    New Item</button>
                </div> -->

              </div>
              <!-- <div>
                <span class="mr-2 float-right">
                  <b>Actual Total Amount: {{purchaseorder.actual_total_amount | currency: currency}}</b>
                </span>
                <br>
                <span class="mr-2 float-right">
                  <b> CGST Amount: {{purchaseorder.cgst | currency: currency}}</b>
                </span>
                <br>
                <span class="mr-2 float-right">
                  <b> SGST Amount: {{purchaseorder.sgst | currency: currency}}</b>
                </span>
                <br>
                <span class="mr-2 float-right">
                  <b> IGST Amount: {{purchaseorder.igst | currency: currency}}</b>
                </span>
                <br>
                <span class="mr-2 float-right">
                  <b> Total Tax Amount: {{purchaseorder.total_tax_amount | currency: currency}}</b>
                </span>
                <br>
                <span class="mr-2 float-right">
                  <b>Total Amount: {{purchaseorder.total_amount | currency: currency}}</b>
                </span>
                <br>
              </div> -->
              <div class="row justify-content-between">
                <div class="col-4"></div>
                <table class="float-right table col-4 final-table mt-4">
                  <tbody>
                    <tr>
                      <th><b>Actual Total Amount</b></th>
                      <td>
                        <b>{{purchaseorder.actual_total_amount | currency: currency}}</b>
                      </td>
                    </tr>
                    <tr *ngIf="purchaseorder.cgst>0">
                      <th><b>CGST Amount</b></th>
                      <td>
                        <b>{{purchaseorder.cgst | currency: currency}}</b>
                      </td>
                    </tr>
                    <tr *ngIf="purchaseorder.sgst>0">
                      <th><b>SGST Amount</b></th>
                      <td>
                        <b>{{purchaseorder.sgst | currency: currency}}</b>
                      </td>
                    </tr>
                    <tr *ngIf="purchaseorder.igst>0">
                      <th><b>IGST Amount</b></th>
                      <td>
                        <b>{{purchaseorder.igst | currency: currency}}</b>
                      </td>
                    </tr>
                    <tr>
                      <th><b>Total Tax Amount</b></th>
                      <td>
                        <b>{{purchaseorder.total_tax_amount | currency: currency}}</b>
                      </td>
                    </tr>
                    <tr>
                      <th><b>Total Amount</b></th>
                      <td>
                        <b>{{purchaseorder.total_amount | currency: currency}}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- <table class="float-right table col-4">
                <tbody>
                  <tr>
                    <th><b>Actual Total </b></th>
                    <td class="money">
                      <b>{{
                        bill.actual_total_amount | currency: currency
                        }}</b>
                    </td>
                  </tr>
                  <tr *ngIf="is_same_state &&  bill.is_without_gst==false">
                    <th><b>CGST </b></th>
                    <td class="money">
                      <b>{{ bill.cgst | currency: currency }}</b>
                    </td>
                  </tr>
                  <tr *ngIf="is_same_state  &&  bill.is_without_gst==false">
                    <th><b>SGST </b></th>
                    <td class="money">
                      <b>{{ bill.sgst | currency: currency }}</b>
                    </td>
                  </tr>
                  <tr *ngIf="is_same_state == false  &&  bill.is_without_gst==false">
                    <th><b>IGST </b></th>
                    <td class="money">
                      <b>{{ bill.igst | currency: currency }}</b>
                    </td>
                  </tr>
                
                  <tr *ngIf="bill.is_without_gst==false">
                    <th><b>Sub Total </b></th>
                    <td class="money">
                      <b>{{ bill.sub_total_amount | currency: currency }}</b>
                    </td>
                  </tr>
                  <tr *ngIf="bill.is_discount == true">
                    <th><b>Total Discount </b></th>
                    <td class="money">
                      <b>- {{ bill.discount | currency: currency }}</b>
                    </td>
                  </tr>
                  <tr *ngIf="bill.tds?.tds_deducted_amount > 0">
                    <th>
                      <b>
                        TDS ({{bill.tds.tds_percentage}} % of {{this.bill.actual_total_amount}})
                      </b>
                      <i style="font-size: 1rem;" class="fe fe-info" [ngbPopover]="bill.tds.nature_of_payment"> </i>
                    </th>
                    <td class="money">
                      <b>

                        {{bill.tds.tds_deducted_amount | currency: currency}}
                      </b>
                    </td>
                  </tr>
                  <tr *ngIf="bill.round_of!=0 && bill.round_of!=undefined">
                    <th><b>Rounding Off ({{bill.round_of < 0 && bill.round_of!=0 ?'-':'+'}}) </b>
                    </th>
                    <td class="money"> <b>{{bill.round_of}}</b></td>
                  </tr>
                  <tr>
                    <th><b>Total</b></th>
                    <td class="money">
                      <b>{{ bill.total_amount | currency: currency }}</b>
                    </td>
                  </tr>
                </tbody>
              </table> -->
            </div>
          </div>
          <!-- <div class="card-footer">
          <button type="submit" [disabled]="!addPurchaseorderForm.valid" class="btn btn-primary">Save</button>

        </div> -->
        </form>
      </div>
    </div>
  </div>
</div>
<!-- TODO Add vendor in purchase order if there is no data in vendor -->
<!-- <div>
<app-vendors-add></app-vendors-add>
</div> -->