import { Component, OnInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { CustomerService } from '../../../_services/customer.service';
import { MastersService } from '../../../_services/masters.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { Customer } from '../../../_models/customer';
import { CountryService } from '../../../_services/country.service';
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import swal from 'sweetalert2';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ContactAddress } from '../../../_models/contact_address.model'
import { ConstantService } from "src/app/_config/constants";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { NgbModal, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddressTemplateComponent } from '../../../common/address-template/address-template.component';
import { AddressTemplate } from '../../../_models/address_template.model';
@Component({
  selector: 'app-customer-add',
  templateUrl: './customer-add.component.html',
  styleUrls: ['./customer-add.component.scss']
})
export class CustomerAddComponent implements OnInit {
  loading: boolean = false;
  navTab = "customer";
  public customer: Customer = new Customer();
  countryList: any = [];
  stateList: any = [];
  currency_list: any = [];
  countryListTypeahead = new EventEmitter<string>();
  type: string;
  customer_id: any = {};
  is_edit_form: boolean = false;
  company_id: string;
  chart_of_accounts: any = {};
  accounts_receivable: any;
  debit_notes: any;
  assets_data: void;
  liability_data: any;
  flattened_data: any;
  branch_id: string;
  gstEffectiveDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  tdsEffectiveDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  contactAddressList: ContactAddress[] = [];
  addressListConfig: AddressTemplate = new AddressTemplate();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService,
    private cd: ChangeDetectorRef,
    private mastersService: MastersService,
    private authenticationService: AuthenticationService,
    private countryService: CountryService,
    public modalService: NgbModal
  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.customer.payment_term = "60";
    this.getCountryData('').subscribe(result => {
      this.countryList = result;
      this.countryList.push({
        country_id: 1,
        name: "India",
        _id: "56dd4f381ffda7ee447019d3"
      });
      this.countryList.filter((e) => {
        if (e.name == "India") {
          this.customer.country = e;
        }
      });
      this.stateList.push({

        zone_id: 1503,
        country_id: 99,
        name: "Tamil Nadu",
        Gst_code: 33,
        code: "TN",
        status: 1

      });
      this.stateList.filter((e) => {
        if (e.name == "Tamil Nadu") {
          this.customer.state = e;
        }
      });
      this.getStateByCountry();
    });
    this.getCountrySearch();
    // this.customer.country = null;
    this.customer.currency = null;
    this.route.paramMap.subscribe(params => {
      this.customer_id = params.get('id');
      let result = this.router.url.match("/edit/")
      if (result != null) {
        if (result[0] === "/edit/" && this.customer_id != null) {
          this.getCustomer();
          this.is_edit_form = true;
          this.initializeAddressTableList();
        }
      }
    });
    this.getCoa();
    this.getCurrencies();
    this.addContactRow();
    // this.getCountries();
  }

  addContactRow() {
    this.customer.contacts.push({
      contact_name: "",
      phone_number: "",
      email: "",
      designation: "",

    });
  }
  initializeAddressTableList() {
    this.addressListConfig.action_type = 'table_viewall';
    this.addressListConfig.user_muid = this.customer_id;
    this.addressListConfig.user_role = 'customer';
  }
  deleteContactRow(index) {
    if (index != 0) {
      this.customer.contacts.splice(index, 1);
    }
  }
  showUpdateAddressDialog(is_edit_form = false) {
    const addAddressModelRef = this.modalService.open(AddressTemplateComponent, { size: 'lg' });
    addAddressModelRef.componentInstance.addressConfig = new AddressTemplate();

    addAddressModelRef.componentInstance.addressConfig.user_role = 'customer';
    addAddressModelRef.componentInstance.addressConfig.user_muid = this.customer_id;

    addAddressModelRef.componentInstance.addressConfig.callbackFunction = this.getAddressList;
    if (this.is_edit_form == true) {
      addAddressModelRef.componentInstance.addressConfig.action_type = 'edit';
    } else {
      // addAddressModelRef.componentInstance.addressConfig.contact_address = contactAddress;
      addAddressModelRef.componentInstance.addressConfig.action_type = 'add';
    }
  }

  getAddressList = () => {
    this.customerService.viewCustomer({ muid: this.customer_id }).subscribe(result => {
      this.contactAddressList = result['response']['contacts'];
    });
  }

  appendBillShipAddress() {
    let data = '';
    if (this.customer.address_line1) {
      data += this.customer.address_line1 + ',';
    }
    if(this.customer.country['name']) {
      data += this.customer.country['name'] + ',';
    }
    if(this.customer.state['name']) {
      data += this.customer.state['name'] + ',';
    }
    if(this.customer.city) {
      data += this.customer.city + ',';
    }
    if(this.customer.postal_code) {
      data += this.customer.postal_code + ',';
    }

    this.customer.billing_address = data;
    this.customer.shipping_address = data;
  }

  addUpdateCustomer() {
    if (this.is_edit_form === true) {
      this.editCustomer();
    } else {
      this.addCustomer();
    }
  }

  getCountryData(searchString) {
    this.countryList = [];
    let filters = {};
    filters['name'] = searchString;

    return this.countryService.getCountryData(filters, 10);
  }
  getCountrySearch() {
    this.countryListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(searchString => this.getCountryData(searchString))
      )
      .subscribe(
        result => {
          this.cd.markForCheck();
          this.countryList = result;
        },
        err => {
          console.log(err);
          this.countryList = [];
        }
      );
  }
  getStateByCountry() {
    this.countryService.getStateByCountry({ muid: this.customer.country._id }).subscribe(result => {
      this.stateList = result[0]['zones'];
      if (this.customer.state) {
        this.customer.state = this.stateList[this.customer.state['zone_id']];
      }
    });
  }
  addCustomer() {
    this.loading = true;
    this.customer.company_id = this.company_id;
    let addressDetails = new ContactAddress();
    addressDetails.first_name = this.customer.first_name;
    addressDetails.last_name = this.customer.last_name;
    addressDetails.address_line1 = this.customer.address_line1;
    addressDetails.address_line2 = this.customer.address_line2;
    addressDetails.city = this.customer.city;
    addressDetails.state = this.customer.state;
    addressDetails.country = this.customer.country;
    addressDetails.postal_code = this.customer.postal_code;
    this.customer.contact_address.push(addressDetails);
    if (this.customer.company_id != '') {
      this.customerService.addCustomer(this.customer).subscribe(result => {
        console.log("result", result);
        
        if (result['error'].length>0) {
          swal('Error', result['error'], 'error');
        } else {
          if (result['muid'] != '') {
            this.loading = false;
            this.updateCoa(this.customer.customer_name, result['muid']);
            this.router.navigate([('party/list')]);
            swal('Success', 'Customer has been created successfully', 'success');
          }
        }

      });
    } else {
      swal('Warning', 'Company Is Not Selected', 'warning');
    }
  }

  getCurrencies() {
    this.mastersService.getCurrencies().subscribe(result => {
      this.currency_list = result['response'];
      this.currency_list.filter((e) => {
        if (e.name == "INR") {
          this.customer.currency = e;
        }
      });
    });
  }

  // getCountries() {
  //   this.mastersService.getCountries().subscribe(result => {
  //     this.countryList = result['response'];
  //   });
  // }

  getCustomer() {
    let customer_filter: any = {};
    customer_filter['muid'] = this.customer_id;
    this.customerService.viewCustomer(customer_filter).subscribe(result => {
      this.customer = result['response'];
      this.customer.gst_effective_date = {
        isRange: false,
        singleDate: {
          jsDate: new Date(this.customer.gst_effective_date),
        },
      };
      this.customer.tds_effective_date = {
        isRange: false,
        singleDate: {
          jsDate: new Date(this.customer.tds_effective_date),
        },
      };
      this.getStateByCountry();
    });
  }

  editCustomer() {
    this.loading = true;
    this.customerService.editCustomer(this.customer, this.customer_id).subscribe(result => {
      if (result['response']['status_code'] == 1) {
        this.loading = false;
        this.router.navigate([('party/list')]);
        swal('Success', 'Customer has been updated successfully', 'success');
      } else if (result['response']['status_code'] == 0) {
        swal('Error', 'Some error occured while updating customer', 'error');
      }
    });
  }

  getCoa() {
    this.mastersService.getCoa(this.company_id).subscribe(result => {
      this.chart_of_accounts = result['response']['chart_of_accounts'];
      this.flattened_data = result['response']['flattened_data'];
      this.chart_of_accounts.forEach(element => {
        if (element.id == 1000) {
          /**id-1000 => Assets */
          element['child'].forEach(value => {
            /**id-1017 => Current Assets */
            if (value.id == 1017) {
              value['child'].forEach(value2 => {
                /**id-1033 => Trade Receivable */
                if (value2.id == 1033) {
                  value2['is_ledger'] = false;
                  this.accounts_receivable = value2;
                }
              });

            }
          });
          this.assets_data = element;
        }
        if (element.id == 2000) {
          /**id-2000 => Liability */
          // element['child'].forEach(value => {
          /**id-2006 => Debit Notes */
          // if (value.id == 2006) {
          //   value['is_ledger'] = false;
          //   this.debit_notes = value;
          // }
          // });
          this.liability_data = element;
        }
      });
    })
  }


  //TODO move the update COA functionality to back end
  updateCoa(customer_name, customer_id) {
    if (this.assets_data['last_sequence'] + 1 < 2000) {
      let condition: any = {}
      condition['company_id'] = this.company_id;
      let account_receivable_data: any = {};
      let debit_notes_data: any = {};

      account_receivable_data['name'] = customer_name;
      account_receivable_data['ref_id'] = customer_id;
      account_receivable_data['id'] = this.assets_data['last_sequence'] + 1;
      this.assets_data['last_sequence'] = account_receivable_data['id'];
      account_receivable_data['is_editable'] = true;
      account_receivable_data['is_ledger'] = true;
      account_receivable_data['child'] = [];
      // if (this.liability_data['last_sequence'] + 1 < 3000) {
      //   debit_notes_data['name'] = customer_name;
      //   debit_notes_data['ref_id'] = customer_id;
      //   debit_notes_data['id'] = this.liability_data['last_sequence'] + 1;
      //   this.liability_data['last_sequence'] = debit_notes_data['id'];
      //   debit_notes_data['is_editable'] = true;
      //   debit_notes_data['is_ledger'] = true;
      //   debit_notes_data['child'] = [];
      //   //Pushing customer info in account receivable and debit notes head
      //   this.debit_notes.child.push(debit_notes_data);
      // }
      this.accounts_receivable.child.push(account_receivable_data);

      this.flattened_data.forEach(flatObj => {
        //Accounts Receivable flatten obj
        if (flatObj.id == 1033 && this.assets_data['last_sequence'] + 1 < 2000) {
          flatObj['is_ledger'] = false;
          let parent_name: any = [];
          let parent_id: any = [];
          parent_name.push(flatObj.name);
          parent_id.push(flatObj.id);
          flatObj.parent_name.forEach(parent_names => {
            parent_name.push(parent_names);
          });
          flatObj.parent_id.forEach(parent_ids => {
            parent_id.push(parent_ids);
          });
          this.flattened_data.push({
            name: customer_name,
            id: this.assets_data['last_sequence'],
            parent_name: parent_name,
            parent_id: parent_id,
            ref_id: customer_id,
            is_editable: true,
            is_ledger: true
          });
        }
        //Debit Notes flatten obj
        if (flatObj.id == 2006 && this.liability_data['last_sequence'] + 1 < 3000) {
          flatObj['is_ledger'] = false;
          let parent_name: any = [];
          let parent_id: any = [];
          parent_name.push(flatObj.name);
          parent_id.push(flatObj.id);
          flatObj.parent_name.forEach(parent_names => {
            parent_name.push(parent_names);
          });
          flatObj.parent_id.forEach(parent_ids => {
            parent_id.push(parent_ids);
          });
          this.flattened_data.push({
            name: customer_name,
            id: this.liability_data['last_sequence'],
            parent_name: parent_name,
            parent_id: parent_id,
            ref_id: customer_id,
            is_editable: true,
            is_ledger: true
          });
        }


      });
      // updating COA      
      this.mastersService.updateCoa(condition, { chart_of_accounts: this.chart_of_accounts, flattened_data: this.flattened_data }).subscribe(result => { });
      // updating customer with the coa data of account receivable data
      this.customerService.editCustomer({ coa_data: account_receivable_data, debit_note_data: debit_notes_data }, customer_id).subscribe(result => { });
    }
  }
}
