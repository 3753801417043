import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";

@Injectable({ providedIn: "root" })
export class GstService {
  constructor(private http: ApiService) {}

  addGst(gst_data: any) {
    return this.http.post(ApiService.geturl("gst", "add"), { data: gst_data });
  }
  editGst(gst_data: any, id: string) {
    return this.http.post(ApiService.geturl("gst", "update"), {
      data: gst_data,
      gst_id: id
    });
  }
  deleteGst(id: string) {
    return this.http.post(ApiService.geturl("gst", "delete"), { id: id });
  }

  // viewGst(condition) {
  //   return this.http.post(ApiService.geturl("gst", "view"), { gst_condition: condition });
  // }

  getGsts(
    filters: any = {},
    columns: any = {},
    page: any = {},

    sort_by: any = {}
  ) {
    return this.http.post(ApiService.geturl("gst", "viewall"), {
      filters: filters,
      sort_by: sort_by,
      columns: columns,
      page: page
    });
  }
  generateGSTR1(filters) {
    console.log('filters======<>>>>', filters);
    return this.http.post(ApiService.geturl("gst", "generate_gstr1"), {
      filters: filters
    });
  }
  reGenerateGSTR1(filters) {
    console.log('filters======<>>>>', filters);
    return this.http.post(ApiService.geturl("gst", "regenerate_gstr1"), {
      filters: filters,
    });
  }
  changeGstFiledStatus(condition){
    return this.http.post(ApiService.geturl("gst", "file_gst"), {
      filters: condition
    });
  }
}
