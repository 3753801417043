import { Injectable } from "@angular/core";
import { ApiService } from "../_services/api.service";
import { AuthenticationService } from '../_services/authentication.service'
import { startOfMonth, endOfMonth, subMonths } from "date-fns";
import { environment } from "@src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class ReportsService {
  public filter_columns: any = [];
  public customization_data: any;
  reportDetails: any = {};
financialYear: any = { start_year: new Date().getFullYear() - 1, end_year: new Date().getFullYear() } 
 
 

  constructor(private http: ApiService, private authenticationService: AuthenticationService) {
let companyDetails = this.authenticationService.getCompanyDetails();
    this.financialYear = companyDetails.fi_year

  }

  addReport(data: {}) {
    return this.http.post(ApiService.geturl("reports", "create"), {
      data: data,
    });
  }
  
  

  async getData(
    customizationId: string,
    apiURL: [string, string],
    filters: any = {},
    customized_query?: any,
    columns?: any,
    preSummary?: any,
    postSummary?: any,
    model?: any,
    sort: any = {},
    page: any = {},
    exportType?: string,
    timeZone: string = "Asia/Calcutta",
    apiData: any = {},
    method: string = "post"
  ) {
    if (apiURL.length > 0) {
      // console.log('apiURL', apiURL);
      let companyDetails = this.authenticationService.getEncryptedCompanyDetails();
      if (method == "post") {
        return await this.http.post(ApiService.geturl(apiURL[0], apiURL[1]), {
          customizationId,
          companyDetails,
          filters,
          sort,
          page,
          exportType,
          timeZone
        }).toPromise();
      }
      if (method == "get") {
        return await this.http.get(ApiService.geturl(apiURL[0], apiURL[1]), {
          filters,
          columns,
          customized_query,
          preSummary,
          postSummary,
          model,
          sort,
          page,
          timeZone,
        }).toPromise();
      }
    }
  }


  async getOpenClosing(
    customizationId: string,
    filters: any = {},
  ) {
    // console.log('apiURL', apiURL);
    let companyDetails = this.authenticationService.getEncryptedCompanyDetails();
    return await this.http.post(ApiService.geturl("reports", "openClosingStockData"), {
      customizationId,
      companyDetails,
      filters,
    }).toPromise();
  }

  getFilterData(
    collection: string,
    columns: any = [],
    filter: any = {},
    sort: any = {},
    limit: number = 0
  ) {
    return this.http.post(ApiService.geturl("reports", "filter_data"), {
      collection: collection,
      columns: columns,
      filter: filter,
      sort: sort,
      limit: limit,
    });
  }

  async getCustomizationDetails(customizationId: string) {
    return this.http.post(
      ApiService.geturl("reports", "get_customization_details"),
      { customizationId }
    ).toPromise();
  }
  updateReportCustomization(customization_data: any, customizationId: any) {
    return this.http.post(
      ApiService.geturl("reports", "update_customization_details"),
      { data: customization_data, customizationId: customizationId }
    );
  }

  getCustomizationsByUserId(condition: {}) {
    return this.http.post(
      ApiService.geturl("reports", "get_customization_by_user"),
      condition
    );
  }

  getReports(condition: {}) {
    return this.http.post(ApiService.geturl("reports", "get_report"), {
      condition: condition,
    });
  }

  getPaginatedData(
    condition: {},
    filters: any = {},
    sort_by: {},
    page: {},
    columns = {}
  ) {
    return this.http.post(ApiService.geturl("reports", "viewall"), {
      condition,
      filters: filters,
      columns: columns,
      sort_by: sort_by,
      page: page,
    });
  }

  downloadReport(
    condition: {},
    filters: any = {},
    sort_by: any = {},
    file_type: string,
    columns = {}
  ) {
    return this.http.post(ApiService.geturl("reports", "download_report"), {
      condition,
      filters: filters,
      sort_by: sort_by,
      file_type: file_type,
      columns: columns,
    });
  }

  getAllCustomizedReports() {
    return this.http.post(
      ApiService.geturl("reports", "all_customized_reports"),
      {}
    );
  }

  createCrossTabArray(customization_id: string) {
    return this.http.post(
      ApiService.geturl("reports", "create_cross_tab_array"),
      { customization_id: customization_id }
    );
  }

  getReportFile(path: string) {
    return this.http.getImageUrl(path);
  }

  formatCustomizationDetails() {
    console.log(this.customization_data, "customization_data");
    // this.customization_data = result['response'];
    const column_array = Object.keys(this.customization_data["columns"]);
    column_array.forEach((column_name, key) => {
      // TODO Defined data structure in mongo db
      if (
        typeof this.customization_data["columns"][column_name]["filter"][
        "type"
        ] !== "undefined"
      ) {
        if (
          this.customization_data["columns"][column_name]["filter"]["type"] ===
          "box"
        ) {
          this.filter_columns.push(
            this.customization_data["columns"][column_name]
          );
          if (typeof this.filter_columns[key] !== "undefined") {
            if (
              this.filter_columns[key]["filter"]["input_type"] ===
              "auto_suggest"
            ) {
              // let column_meta = this.filter_columns[key];
              // this.ListTypeahead[column_name] = new EventEmitter<string>();
              // this.getTypeAhead('', this.filter_columns[key]).subscribe(result => {
              //   this.typeAheadData[column_name] = result['response'];
              // });
            }
          }
          this.formatFilterData(key);
        }
      }
    });
  }

  formatFilterData(index: number) {
    if (typeof this.filter_columns[index] !== "undefined") {
      const column_meta = this.filter_columns[index]["filter"];
      if (typeof column_meta["collection"] !== "undefined") {
        this.getFilterData(
          column_meta["collection"],
          column_meta["columns"]
        ).subscribe((result) => {
          this.filter_columns[index]["filter_selection_data"] =
            result["response"];
        });
      }
    }
  }

  getFilterTypeaheadData(API_URL: string[], filters: any = {}) {
    return this.http.post(ApiService.geturl(API_URL[0], API_URL[1]), {
      filters,
    });
  }

  getReportInfo(result) {
    if (result["response"]["data"] != undefined && result["response"]["data"]["columns"] != undefined) {
      this.reportDetails.tableColumns = this.inlineTemplateColumns(
        result["response"]["data"]["columns"]
      );
    }
    this.reportDetails.reportType =
      (result["response"]["data"] != undefined && result["response"]["data"]["report_type"] != undefined)
        ? result["response"]["data"]["report_type"]
        : 1;
    if (this.reportDetails.reportType == 2) {
      this.reportDetails.groupColumns = result["response"]["data"]["group_columns"];
      this.reportDetails.rowColumns = result["response"]["data"]["row_columns"];
      this.reportDetails.summaryColumns = result["response"]["data"]["summary_columns"];
    }
    this.reportDetails.defaultFilters = result["response"]["data"] != undefined ? result["response"]["data"]["filters"] : "";
    this.reportDetails.filters = this.reportDetails.defaultFilters;
    if (result["response"]["data"] != undefined && result["response"]["data"]["preSummary"] != undefined) {
      this.reportDetails.preSummary = result["response"]["data"]["preSummary"];
    }
    if (result["response"]["data"] != undefined && result["response"]["data"]["model"] != undefined) {
      this.reportDetails.model = result["response"]["data"]["model"];
    }

    if (result["response"]["data"] != undefined && result["response"]["data"]["postSummary"] != undefined) {
      this.reportDetails.postSummary = result["response"]["data"]["postSummary"];
    }
    if (result["response"]["data"] != undefined && result["response"]["data"]["customized_query"] != undefined) {
      this.reportDetails.customized_query =
        result["response"]["data"]["customized_query"];
    }
    this.reportDetails.defaultSort = result["response"]["data"] != undefined ? result["response"]["data"]["default_sort"] : "";
    this.reportDetails.apiURL = result["response"]["data"] != undefined ? result["response"]["data"]["apiurl"] : "";
    return this.reportDetails;
    // this.getReportData();
    // this.initiateFilterTypeaheadData();
  }
  inlineTemplateColumns(columns: any) {
    columns.forEach((element, index) => {
      if (element["dataType"] == "template") {
        // const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.getComponentForCardType(this.data.component));
        // const viewContainerRef = this.container;
        columns[index]["template"] = element["template"].replace('"', "`");
      }
    });
    return columns;
  }

  getReportReplaceParamsCondition(filters: any = []) {

    let replaceParams = {};
    replaceParams[
      "~m~company_id"
    ] = this.authenticationService.getDefaultCompanyId();

    if (filters.length > 0) {
      filters.forEach((filter, key) => {

        if (typeof filter.value == "string") {
          if (filter.value.startsWith("~m~")) {
            filters[key]["value"] = replaceParams[filter.value];
          }
        }
        if (filter.type != "daterange") {
          if (Array.isArray(filter.value) && filter.type == "between") {
            //Condition only for dates
            filters[key]["value"][0] = this.stringToDate(filter.value[0]);
            filters[key]["value"][1] = this.stringToDate(filter.value[1]);
          }
        } else {
          for (var i in filters) {
            if (filters[i]["type"] == "between") {
              filters.splice(i, 1);
              break;
            }
          }
        }
      });
    }
    return filters;
  }
  stringToDate(dateString) {
    switch (dateString) {
      case "startOfMonth":
        dateString = startOfMonth(new Date());
        break;
      case "endOfMonth":
        dateString = endOfMonth(new Date());
        break;
      case "startOfPreviousMonth":
        dateString = subMonths(startOfMonth(new Date()), 1);
        break;
      case "startOfFiscalYear":
        //TODO get current finacial year by country and selected FY in the header  
         dateString = startOfMonth(new Date(this.financialYear.start_year,3));



//		dateString = startOfMonth(new Date(this.finacial_year.start_year,3,1));
		//"2022-04-01T00:00:00.000Z";
        break;
      case "endOfFiscalYear":
        //TODO get current finacial year by country and selected FY in the header
  //      dateString = endOfMonth(new Date(this.finacial_year.end_year,4,1));;
  dateString = endOfMonth(new Date(this.financialYear.end_year,2));
        break;
    }
    return dateString;
  }

  getDashboradWidget(widgetType: number, companyId: string) {
    return this.http.post(ApiService.geturl("reports", "get_dashboard_widget"), {
      widgetType, companyId
    });
  }
  getInvoiceProgressByStatus(companyId: string) {
    return this.http.post(
      ApiService.geturl("reports", "get_invoice_progress_by_status"),
      { companyId }
    );
  }
  generatePdfFromHtml(companyId: string, htmlString, pdf_format) {
    return this.http.post(
      ApiService.geturl("reports", "generate_pdf_from_html"),
      { companyId, htmlString, pdf_format }
    );
  }
}
