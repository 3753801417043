<div class="container-fluid view">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header d-flex align-items-center">
          <h3 class="table-title">{{paymentName}}</h3>
        </div>
        <div class="card-body row">
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label>{{label}} Method</label>
            <div>{{viewDetails.paymentMethod}}</div>
          </div>
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label>{{label}} Account</label>
            <div>{{viewDetails.paymentAccount}}</div>
          </div>
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label> Ref Id</label>
            <div>{{viewDetails.paymentRefId}}</div>
          </div>
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label>Voucher No</label>
            <div>{{viewDetails.paymentVoucherNo}}</div>
          </div>
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label>{{label}} Date</label>
            <div>{{viewDetails.paymentDate | date}}</div>
          </div>
          <div class="col-12 pb-3">
            <table class="table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>{{label}}</th>
                  <th>Amount</th>
                </tr>

              </thead>
              <tbody>
                <tr *ngFor="let itemsObj of viewDetails.items; let i = index">
                  <td>{{i+1}}</td>
                  <td> {{itemsObj?.category?.flattened_data.name}} </td>
                  <td> {{itemsObj.amount | currency : currency}} </td>
                </tr>
                <tr>
                  <th class="p-2" colspan="3">Total Amount
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{this.viewDetails.totalAmount |
                    currency : currency}}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>