<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header d-flex align-items-center">
          <h3 class="table-title">Vendor Advance </h3>
        </div>
        <form novalidate #addreceiptsForm="ngForm" (ngSubmit)="addVendorReceipts()">
          <div class="card-body">
            <div class="row">
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 required">
                <!-- <div class=""> -->
                <label class="col-form-label" for="vendor">Vendor</label>
                <ng-select [items]="vendor_lists" [typeahead]="vendorListTypeahead" #vendorModel="ngModel" required
                  name="vendor" bindLabel="vendor_name" [(ngModel)]="vendor" placeholder="Select vendor">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.vendor_name }}
                    </div>
                  </ng-template>
                </ng-select>
                <app-validation-errors [mformField]="vendorModel" label="vendor"></app-validation-errors>

                <!-- </div> -->
              </div>
              <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                <label class="col-form-label ">Payment Method</label>
                <!-- <div class=""> -->
                <ng-select [items]="payment_methodList" name="payment_method" #payment_method="ngModel"
                  [(ngModel)]="receipts.payment_method" (change)="getPaymentInChange()">
                  <ng-template ng-label-tmp let-item="item">
                    {{item.name}}
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    {{item.name}}
                  </ng-template>
                </ng-select>
                <app-validation-errors [mformField]="payment_method" label="Payment Method">
                </app-validation-errors>
                <!-- </div> -->
              </div>
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 required">
                <label class="col-form-label">Payment Account</label>
                <!-- <div class=""> -->
                <ng-select [items]="payment_accountList" name="payment_account" required #payment_account="ngModel"
                  bindLabel="name" [(ngModel)]="receipts.payment_account">
                  <ng-template ng-label-tmp let-item="item">
                    <div [ngOptionHighlight]="search">
                      {{item?.flattened_data?.name}}
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{item?.flattened_data?.name}}
                    </div>
                  </ng-template>

                </ng-select>
                <app-validation-errors [mformField]="payment_account" label="Payment Account">
                </app-validation-errors>
                <!-- </div> -->
              </div>
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 required">
                <label class="col-form-label ">Payment Date</label>
                <!-- <div class=""> -->
                <div class="input-group">
                  <input angular-mydatepicker placeholder="Payment Date" required
                    (click)="payment_date_dp.toggleCalendar()" #payment_date_dp="angular-mydatepicker"
                    class="form-control" name="payment_date" [(ngModel)]="receipts.payment_date"
                    [options]="payment_dateDpOptions" (dateChanged)="onpayment_dateDateChanged($event)">
                  <!-- clear date button -->
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="receipts.payment_date"
                      (click)="payment_date_dp.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <!-- toggle calendar button -->
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="payment_date_dp.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>
                <app-validation-errors [mformField]="payment_date_dp" label="Payment Date">
                </app-validation-errors>
                <!-- </div> -->
              </div>
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 required">
                <label class="col-form-label ">Advance Amount</label>
                <!-- <div class=""> -->
                <!-- <div class="input-group"> -->
                <input class="form-control" type="number" [min]="1" required name="advance_amount"
                  placeholder="Advance Amount" [(ngModel)]="receipts.advance_amount" #advance_amount="ngModel" />
                <!-- </div> -->
                <app-validation-errors [mformField]="advance_amount" label="Advance Amount">
                </app-validation-errors>
                <!-- </div> -->
              </div>


              <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="account_heads">Ledger</label>
              <app-ledgers (onSelectLedger)="updateLedger($event)" [ledgerAccountsHeads]="{accountsHeads: [5038]}">
              </app-ledgers>
            </div> -->
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 required">
                <label class="col-form-label ">Description</label>
                <!-- <div class=""> -->
                <!-- <div class="input-group"> -->
                <textarea class="form-control"  required name="description" placeholder="Description"
                  [(ngModel)]="receipts.description" #description="ngModel"></textarea>
                <!-- </div> -->
                <!-- <app-validation-errors [mformField]="description" label="Description">
                </app-validation-errors> -->
                <!-- </div> -->
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" [disabled]="!addreceiptsForm.valid || loading==true" class="btn btn-primary mr-2">Pay
              <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
            </button>
            <!-- <button type="button" (click)="book_pay()" class="btn btn-primary">Book & pay</button> -->
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
