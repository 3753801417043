
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthenticationService } from "./authentication.service";
@Injectable({ providedIn: 'root', })
export class JournalService {
    branch_id: string;
    constructor(private http: ApiService,
        private authenticationService: AuthenticationService) {
        this.branch_id = this.authenticationService.getDefaultBranchId();
    }
    viewSequence(sequence_data: any = {}) {
        return this.http.post(ApiService.geturl('sequence', 'view'),
            { sequence_data });
    }
    addJournal(journal_data: any) {
        if (this.branch_id != '') {
            journal_data['branch_id'] = this.branch_id;
        }
        return this.http.post(ApiService.geturl('journal', 'add'),
            journal_data);
    }
    addOpeningBalance(openingbalance_data: any, company_id: string, fyDate: Date) {

        return this.http.post(ApiService.geturl('journal', 'opening_balance'),
            { data: openingbalance_data, company_id ,fyDate});
    }
    addStockJournal(journal_data: any) {
        return this.http.post(ApiService.geturl('journal', 'addstockjournal'),
            journal_data);
    }
    editJournal(journal_data: any, id: string) {
        if (this.branch_id != '') {
            journal_data['branch_id'] = this.branch_id;
        }
        return this.http.post(ApiService.geturl('journal', 'edit'),
            { data: journal_data, journal_id: id });
    }
    deleteJournal(id: string, tx_type_id: any) {
        return this.http.post(ApiService.geturl('journal', 'delete'),
            { id: id, tx_type_id: tx_type_id });
    }

    viewJournal(id: string) {
        return this.http.post(ApiService.geturl('journal', 'view'),
            { journal_id: id });
    }

    getJournals(filters: any = {}, columns: any = {}, sort_by: any = {}, page: any = {}) {
        // if (this.branch_id != '') {
        // filters['branch_id'] = this.branch_id;
        // }
        return this.http.post(ApiService.geturl('journal', 'viewall'),
            { filters: filters, sort_by: sort_by, columns: columns, page: page });
    }
}