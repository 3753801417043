import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { ReceiptsService } from "src/app/_services/receipts.service";
import swal from "sweetalert2";
import { ExpenseService } from "../../_services/expense.service";
// import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { AuthenticationService } from "../../_services/authentication.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PaymentModalComponent } from "../payment-modal/payment-modal.component";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { MastersService } from "../../_services/masters.service";
import { ConstantService } from "src/app/_config/constants";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { Receipts } from "../../_models/receipts";

@Component({
  selector: "app-receipts",
  templateUrl: "./receipts.component.html",
  styleUrls: ["./receipts.component.scss"],
  providers: [NgbModalConfig, NgbModal],
})
export class ReceiptsComponent implements OnInit {
  is_customer_vendor: Boolean = false;
  navTab = "customer";
  total_expense_amount: true;
  filters: any = { voucher_number: null };
  sort: any = { created_at: -1 };
  dt_default_sort = [{ prop: "payee", dir: "asc" }];
  count: number;
  expense: any = {};
  expenseList: any = [];
  expenseListColumns: any = [];
  page: any = { offset: 0, limit: 20 };
  // limit =20;
  loadingIndicator = false;
  reorderable = true;
  dispCheckBox: boolean = true;
  // @ViewChild("expenseTable", { static: true }) table: any;
  columns: any = {};
  page_icon: any;
  selected_items = [];
  selectedOrder: any;
  payType: string = "";
  selecteditem_array: any;
  item_selected: any;
  invoice_array: any = [];
  pay_head: string;
  currency: string;
  module_from: string = "receipt";
  passed: boolean;
  passed_message: string;
  selected_items_billid = [];
  selected_type: any;
  passed_type: boolean;
  company_id: any;
  customerList: any = [];
  customerListTypeahead = new EventEmitter<string>();
  accountHeadListTypeahead = new EventEmitter<string>();
  receiptListTypeahead = new EventEmitter<string>();
  voucherNoTypeahead = new EventEmitter<string>();
  payment_accountList: any = [];
  voucherNoList: any = [];
  payment_methodList: any = [
    { name: "Cash", id: 1031 },
    { name: "Bank", id: 1030 },
  ];
  receiptList: any = [];
  receipt: Receipts = new Receipts();
  payment_dateDpOptions: IAngularMyDpOptions = {
    dateRange: true,
    dateFormat: ConstantService.DateFormat,
  };
  user_id: any;
  dateFormat: string = ConstantService.AngularDateFormat;
  id: string;
  modalReference: any;
  companyDetails: any;
  selectFiYear: any;
  constructor(
    private receiptService: ReceiptsService,
    private authenticationService: AuthenticationService,
    public route: ActivatedRoute,
    private modalService: NgbModal,
    private customerService: MastersService,
    private cd: ChangeDetectorRef,
    private router: Router,
  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.user_id = this.authenticationService.getUserRole();
    this.companyDetails = this.authenticationService.getCompanyDetails();
    this.currency = this.companyDetails.base_currency;

    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];
    this.filters.payment_date = {
      isRange: true,
      singleDate: null,
      dateRange: {
        beginDate: { year: this.selectFiYear.start_year, month: 4, day: 1 },
        beginJsDate: new Date(this.selectFiYear.start_year, 3, 1),
        endDate: { year: this.selectFiYear.end_year, month: 3, day: 31 },
        endJsDate: new Date(this.selectFiYear.end_year, 2, 31),
      }
    };


    this.currency = this.authenticationService.getCompanyDetails()[
      "base_currency"
    ];
    this.getCustomer("").subscribe((result) => {
      this.customerList = result["response"];
    });
    this.getCustomerSearch();
    this.getVoucherNoListSearch();

    this.expenseListColumns = [

      {
        prop: "payment_date",
        name: "Date",
        sortable: "payment_date",
        flexGrow: 1,
        type: "date",
        show: true,
        field_name: "Date",
        is_detailed_row: false,
        css_class: "align-left",
      },
      {
        prop: "leadger_name",
        name: "Receipts Head",
        sortable: "leadger_name",
        flexGrow: 1,
        type: "unwindVal",
        show: true,
        is_detailed_row: false,
        css_class: "align-left",
      },
      {
        prop: "voucher_number",
        name: "Voucher#",
        sortable: "voucher_number",
        flexGrow: 1,
        show: true,
        field_name: "Voucher Number",
        is_detailed_row: false,
        css_class: "align-left",
      },
      {
        prop: "payment_account",
        name: "Payment Type",
        sortable: "payment_account",
        flexGrow: 1,
        show: true,
        // field_name: "payment_account.flattened_data.name",
        is_detailed_row: false,
        css_class: "align-left",
      },
      // {
      //   prop: "payment_method",
      //   name: "Payment Method",
      //   sortable: "payment_method",
      //   flexGrow: 1,
      //   show: true,
      //   type: "object",
      //   field_name: "name",
      //   is_detailed_row: false,
      //   css_class: "align-right",
      // },

      {
        prop: "amount",
        name: "Amount",
        sortable: "amount",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        css_class: "align-right",
      },
      // {
      //   prop: "balance_amount",
      //   name: "Balance Amount",
      //   sortable: "Balance Amount",
      //   flexGrow: 1,
      //   show: true,
      //   is_detailed_row: false,
      //   css_class: "align-right",
      // },
      {
        prop: "receipt_status_name",
        name: "Receipt Status",
        sortable: "receipt_status",
        flexGrow: 1,
        show: true,
        type: "status",
        is_detailed_row: false,
        css_class: "align-center",
      },
      {
        name: "Action",
        type: "action",
        prop: "_id",
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false,
      },
    ];
    this.expenseListColumns.forEach((column) => {
      this.columns[column.prop] = 1;
      this.columns["draft_data"] = 1;
    });
    this.page_icon = this.route.snapshot.data.menu.icon;
    if (this.route.snapshot.queryParams["q"] != "undefined")
      this.filters["payee"] = this.route.snapshot.queryParams["q"];
    this.getExpensesList(this.page);

    this.getPaymentCoaHead("").subscribe((result) => {
      this.payment_accountList = result["response"];

    });
    this.getPaymentCoaHeadSearch();

    this.getIncomeCoaHead("").subscribe((result) => {
      this.receiptList = result["response"];
    });
    this.getIncomeCoaHeadSearch();
    this.getVoucherNoList("").subscribe((result) => {
      console.log('result', result);

      this.voucherNoList = result["response"]["data"].filter(element => element["voucher_number"] != undefined);
    });
  }

  open(content, id) {
    this.id = id;
    this.modalReference = this.modalService.open(content, { size: "lg" });
  }

  getCustomer(searchString) {
    this.customerList = [];
    return this.customerService.getCoaHead(this.company_id, searchString, {
      in: [ConstantService.coa_heads.Accounts_Receivable],
    });
  }

  getCustomerSearch() {
    this.customerListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getCustomer(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.customerList = result["response"];
        },
        (err) => {
          console.log(err);
          this.customerList = [];
        }
      );
  }

  onSort(event) {
    this.loadingIndicator = true;
    this.sort = {};
    if (event.sorts.length > 0) {
      let sort_details = event.sorts[event.sorts.length - 1];
      this.sort[sort_details.prop] = sort_details.dir == "asc" ? 1 : -1;
    }
    this.getExpensesList(this.page);
  }

  getExpensesList(page_info: any) {
    if (page_info != null) {
      this.page = page_info;
    }
    console.log('this.page---===111===>>>', this.page);
    this.columns["expense_type"] = 1;
    this.filters["company_id"] = this.company_id;
    this.loadingIndicator = true;
    this.receiptService
      .getExpenses(this.filters, this.columns, this.sort, this.page)
      .subscribe((result) => {
        this.loadingIndicator = false;
        this.expenseList = result["response"]["data"];
        this.page.count = result["response"]["count"];
      });
  }
  clearExpenseFilters() {
    this.filters = {};
    // this.getExpensesList({ offset: 0, pageSize: 20 });
  }
  onSelectOrders(selected) {
    this.selected_items = [];
    this.selected_items_billid = [];
    this.selected_type = [];
    selected["selected"].forEach((element) => {
      if (element.expense_type == "customer") {
        this.selected_type.push("customer");
        this.selected_items.push(element.payee["ref_id"]);
      } else {
        this.selected_type.push("vendor");
        this.selected_items.push(element.payee["ref_id"]);
      }
      this.selected_items_billid.push(element._id);
    });
  }
  openPaymentModal(row_data, pay_method) {
    let selected: any = [];
    let payment_by = "";
    if (row_data) {
      selected.push(row_data);
      this.onSelectOrders({ selected });
    }

    this.selecteditem_array = [];

    this.item_selected = this.selected_items[0];

    if (pay_method == "bulk") {
      if (this.selected_items.length == 1) {
        this.payType = "single";
        this.passed = true;
        this.passed_type = true;
      } else {
        this.payType = "multiple";
        this.passed = this.selected_items.every(this.isEqualVendor);
        this.passed_type = this.selected_type.every(this.isEqualType);
      }
    } else if (pay_method == "single") {
      this.payType = "single";
      this.passed = true;
      this.passed_type = true;
    }
    if (this.passed && this.passed_type) {
      const modalRef = this.modalService.open(PaymentModalComponent, {
        size: "lg",
      });

      let dataArray = {
        bill_ids: this.selected_items_billid,
        payment_from: "Receipt",
        payment_type: this.payType,
        payment_by: this.selected_type[0],
      };

      modalRef.componentInstance.selectedarray_data = dataArray;
      modalRef.result
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      swal("Error!", "Select same Payee / choose an order", "error");
    }
  }
  isEqualVendor(element, index, array) {
    return element == array[0];
  }
  isEqualType(element, index, array) {
    return element == array[0];
  }
  confirmReceipt(rowData) {
    if(rowData['leadger_name'] !=undefined){

    swal({
      title: "Are you sure want to confirm the receipt ?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, confirm it!",
    }).then((result) => {
      if (result) {
        rowData['tx_type'] = 111;
        this.receiptService
          .confirmReceipt(rowData)
          .subscribe((result) => {
            if (result["ok"]) {

              let payment_data = result["data"]["draft_data"]["payment_object"];
              payment_data["tx_ref_id"] = result["data"]["draft_data"]["receipt_id"];
              payment_data["receipt_from"] = "receipt";
              this.receiptService
                .createReceiptPayment(payment_data)
                .subscribe((result_payment) => {
                  if (result_payment["statusCode"] == 200) {
                    swal(
                      "Receipt payment done sucessfully!",
                      "Receipt added.",
                      "success"
                    );
                    // this.router.navigateByUrl("/receipt/list");
                    this.getExpensesList(this.page);
                  } else {
                    swal(
                      "Can't create payment for receipt",
                      "receipt error.",
                      "error"
                    );
                  }
                });
            }
          });
      }
    });}else{
      swal("Error!", "Select the ledger for approval.", "error");
    }
  }
  deleteReceipt(receipt_id) {
    swal({
      title: "Are you sure want to delete the receipt ?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result) {
        this.receiptService
          .deleteReceipt(receipt_id, this.company_id)
          .subscribe((result) => {
            if (result["ok"] == 1) {
              swal(
                "Receipt deleted sucessfully!",
                "Receipt Deleted.",
                "success"
              );
              this.getExpensesList(this.page);
            } else {
              swal(
                "Can't delete receipt",
                "receipt error.",
                "error"
              );
            }
          })
      }
    });
  }



  getPaymentCoaHeadSearch() {
    this.accountHeadListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getPaymentCoaHead(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.payment_accountList = result["response"];
        },
        (err) => {
          console.log(err);
          this.payment_accountList = [];
        }
      );
  }

  getPaymentCoaHead(searchString) {
    this.payment_accountList = [];
    return this.customerService.getCoaHead(this.company_id, searchString, {
      in: ['Cash', 'Bank'],
    });
  }

  getIncomeCoaHead(searchString) {
    this.receiptList = [];
    return this.customerService.getCoaHead(this.company_id, searchString, {
      not_in: [
        ConstantService.coa_heads.Closing_Inventories,
        ConstantService.coa_heads.Premilinary_Expenses
      ],
    });
  }
  getIncomeCoaHeadSearch() {
    this.receiptListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getIncomeCoaHead(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.receiptList = result["response"];
        },
        (err) => {
          console.log(err);
          this.receiptList = [];
        }
      );
  }

  getVoucherNoList(searchString: string = null) {
    this.voucherNoList = [];
    this.columns["voucher_number"] = 1;
    this.filters["company_id"] = this.company_id;
    if (searchString != "") {
      this.filters["voucher_number"] = searchString;
    }

    // this.filters["$and"] = [];
    // this.filters["$and"][0] = { "expense_object.voucher_number": { $exists: true } };
    // if (searchString != '') {
    //   this.filters["$and"][1] = {
    //     "expense_object.voucher_number": {
    //       "$regex": "^" + searchString,
    //       "$options": "i"
    //     }
    //   }
    // }
    // this.loadingIndicator = true;
    return this.receiptService
      // .getExpenses(this.filters, this.columns, this.sort, { offset: 0, pageSize: 5 })
      .getExpenses(this.filters, this.columns, this.sort, this.page)
  }

  getVoucherNoListSearch() {
    this.voucherNoTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getVoucherNoList(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.voucherNoList = result["response"]["data"].filter(element => element["voucher_number"] != undefined);;
        },
        (err) => {
          console.log(err);
          this.voucherNoList = [];
        }
      );
  }

}
