<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header d-flex align-items-center">
          <h3 *ngIf="!reset_password_form" class="table-title"> User {{title}}</h3>
          <h3 *ngIf="reset_password_form" class="table-title"> {{title}}</h3>
        </div>
        <form class="form-horizontal" (ngSubmit)="addUpdateUser()" novalidate #saveUser="ngForm">
          <div class="card-body row">

            <div *ngIf="!reset_password_form" class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="firstname">First Name</label>
              <input type="text" class="form-control" required id="firstname" name="firstname" #firstname="ngModel"
                [(ngModel)]="user.firstname" placeholder="First Name">
              <app-validation-errors [mformField]="firstname" label="Firstname"></app-validation-errors>
            </div>

            <div *ngIf="!reset_password_form" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="lastname">Last Name</label>
              <input type="text" class="form-control" id="lastname" name="lastname" [(ngModel)]="user.lastname"
                placeholder="Last Name">
            </div>

            <div *ngIf="!reset_password_form" class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="genders">Gender</label>
              <ng-select [items]="genders" required #gender="ngModel" name="gender" bindLabel="name"
                [(ngModel)]="user.gender" bindValue="id" placeholder="Select Gender">
                <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div [ngOptionHighlight]="search">
                    {{ item.name }}
                  </div>
                </ng-template>
              </ng-select>
              <app-validation-errors [mformField]="gender" label="Gender"></app-validation-errors>
            </div>

            <div *ngIf="!reset_password_form" class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="industry">Email</label>
              <input type="email" (focusout)="user.username = user.email" required placeholder="Email" class="form-control" id="email" name="email"
                #email="ngModel" [(ngModel)]="user.email" email>
              <app-validation-errors [mformField]="email" label="Email"></app-validation-errors>
            </div>

            <div *ngIf="!reset_password_form" class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="username">Username</label>
              <input type="text" placeholder="Username" class="form-control" id="username" name="username"
                #username="ngModel" required [(ngModel)]="user.username">
              <app-validation-errors [mformField]="username" label="Username"></app-validation-errors>
            </div>

            <div *ngIf="!is_edit_form && !reset_password_form"
              class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="password">Password</label>
              <input type="password" class="form-control" #password="ngModel" required placeholder="Password"
                id="password" name="password" [(ngModel)]="user.password">
              <app-validation-errors [mformField]="password" label="Password"></app-validation-errors>
            </div>

            <!-- <div *ngIf="!is_edit_form && !reset_password_form" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="password">Password</label>
              <input type="password" class="form-control" placeholder="Password" id="password" name="password"
                [(ngModel)]="user.password">
            </div> -->


            <div *ngIf="reset_password_form" class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="new_password">New Password</label>
              <input type="password" class="form-control" #new_password="ngModel" required placeholder="New Password"
                id="password" name="new_password" [(ngModel)]="user.new_password">
              <app-validation-errors [mformField]="new_password" label="New Password"></app-validation-errors>
            </div>

            <div *ngIf="reset_password_form" class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="confirm_password">Confirm Password</label>
              <input type="password" class="form-control" #confirm_password="ngModel" required
                placeholder="Confirm Password" id="confirm_password" name="confirm_password"
                [(ngModel)]="user.confirm_password" [equal]="user.new_password">
              <app-validation-errors [mformField]="confirm_password" label="Confirm Password"></app-validation-errors>
            </div>

            <div *ngIf="!reset_password_form" class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="user_roles">User Role</label>
              <ng-select [items]="user_roles" required (change)="getReportList(user.user_role)" #user_role="ngModel"
                name="user_role" bindLabel="name" [(ngModel)]="user.user_role" placeholder="Select Role">
                <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div [ngOptionHighlight]="search">
                    {{ item.name }}
                  </div>
                </ng-template>
              </ng-select>
              <app-validation-errors [mformField]="user_role" label="User Role"></app-validation-errors>
            </div>
            <div *ngIf="!reset_password_form && user.user_role?.role_id>1"
              class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="company">Company</label>
              <ng-select [items]="companies_list" (change)="setBranch()"
                [multiple]="(user.user_role.role_id ==8 || user.user_role.role_id ==9 || user.user_role.role_id ==10 || user.user_role.role_id ==11 || user.user_role.role_id ==12) ? false : true"
                (change)="companyId()" required #company="ngModel" name="company" bindLabel="company" [(ngModel)]="user.company_details"
                bindValue="_id" placeholder="Select Company">
                <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div [ngOptionHighlight]="search">
                    {{ item.company }}
                  </div>
                </ng-template>
              </ng-select>
              <app-validation-errors [mformField]="company" label="Company"></app-validation-errors>
            </div>

            <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
      <label for="branch">Branch</label>
      <ng-select [items]="branches_list" [multiple]="true" required #branch="ngModel" name="branch" bindLabel="name"
        [(ngModel)]="user.branch_ids" bindValue="_id" placeholder="Select Branch">
        <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div [ngOptionHighlight]="search">
            {{ item.name }}
          </div>
        </ng-template>
      </ng-select>
      <app-validation-errors [mformField]="branch" label="Branch"></app-validation-errors>
    </div> -->


            <!-- <div *ngIf="!reset_password_form" class="form-group col-sm-3 col-md-3 col-xl-3">
              <label for="dob">Date Of Birth</label>
              <div class="input-group">
                <input class="form-control" name="dob" placeholder="Select DOB" angular-mydatepicker
                  #dp_dob="angular-mydatepicker" [(ngModel)]="user.dob" [options]="dobDateOptions" />
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" *ngIf="user.dob" (click)="dp_dob.clearDate()">
                    <i class="fa fa-close"></i>
                  </button>
                </div>
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" (click)="dp_dob.toggleCalendar()">
                    <i class="fa fa-calendar-o"></i>
                  </button>
                </div>
              </div>
            </div> -->

            <!-- <div *ngIf="!reset_password_form" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="address">Address</label>
              <textarea type="text" maxlength="200" class="form-control" placeholder="Address" id="address"
                name="address" [(ngModel)]="user.address">
              </textarea>
            </div> -->

            <div *ngIf="!reset_password_form" class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="phone">Phone Number</label>
              <input type="number" pattern="^[0-9]{10,15}$" #phone_number="ngModel" class="form-control"
                placeholder="Phone Number" id="phone" name="phone" [(ngModel)]="user.phone">
              <app-validation-errors [mformField]="phone_number" label="Phone Number"></app-validation-errors>
            </div>

            <!-- <div *ngIf="!reset_password_form" class="form-group  col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="country">Country</label>
              <ng-select [items]="country_list" name="country" (change)="getStateByCountry()" bindLabel="name"
                [(ngModel)]="user.country" placeholder="Select Country">
                <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div [ngOptionHighlight]="search">
                    {{ item.name }}
                  </div>
                </ng-template>
              </ng-select>
            </div> -->

            <!-- <div *ngIf="!reset_password_form" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="state">Province/State</label>
              <select type="text" class="form-control" name="state" id="state" placeholder="State"
                [(ngModel)]="user.state">
                <option *ngFor="let item of stateList| keyvalue" [ngValue]="item.value">{{item.value.name}}
                </option>
              </select>
            </div> -->

            <!-- <div *ngIf="!reset_password_form" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="city">City</label>
              <input type="text" class="form-control" name="city" id="city" [(ngModel)]="user.city">
            </div> -->

            <div *ngIf="!reset_password_form" class="form-group col-md-3">
              <div
                *ngIf="(user.user_role.role_id == 1 || user.user_role.role_id == 2 || user.user_role.role_id == 3 || user.user_role.role_id == 6 || user.user_role.role_id == 7)">
                <label for="user_type">Reporting To</label>
                <ng-select [items]="username_list" [multiple]=true #reporting_to="ngModel" bindLabel="firstname"
                  [typeahead]="userNameListTypeahead" id="reporting_to" name="reporting_to"
                  [(ngModel)]="user.reporting_to" bindValue="firstname" placeholder="Select">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.firstname }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
          <button *ngIf="!reset_password_form" type="submit" class="btn btn-primary"
            [disabled]="!saveUser.valid || loading==true">Save
            User <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          </button>
          <button *ngIf="reset_password_form  && loading ==true" type="submit" class="btn btn-primary"
            [disabled]="!saveUser.valid">Save <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- TODO Users List -->