import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { AuthenticationService } from "./authentication.service";
@Injectable({ providedIn: "root" })
export class StocktransactionsService {
  branch_id: string;
  constructor(private http: ApiService,
    private authenticationService: AuthenticationService) {
    this.branch_id = this.authenticationService.getDefaultBranchId();
  }

  addStocktransactions(stocktransactions_data: any) {
    return this.http.post(ApiService.geturl("stocktransactions", "add"), {
      data: stocktransactions_data
    });
  }
  getAccountBooksReport(
    filters: any = {},
    columns?: any,
    sort: any = {},
    page: any = {}
  ) {
    console.log('BBBBBBBB');
    return this.http.post(
      ApiService.geturl("transactions", "account_books"),
      {
        filters,
        columns,
        sort,
        page,
      }
    );
  }
  editStocktransactions(stocktransactions_data: any, id: string) {
    return this.http.post(ApiService.geturl("stocktransactions", "edit"), {
      data: stocktransactions_data,
      stocktransactions_id: id
    });
  }
  deleteStocktransactions(id: string) {
    return this.http.post(ApiService.geturl("stocktransactions", "delete"), {
      id: id
    });
  }

  viewStocktransactions(id: string) {
    return this.http.post(ApiService.geturl("stocktransactions", "view"), {
      stocktransactions_id: id
    });
  }

  getStocktransactionss(
    filters: any = {},
    columns: any = {},
    sort_by: any = {},
    page: any = {}
  ) {
    if (this.branch_id != '') {
      filters['branch_id'] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("transactions", "viewall"), {
      filters: filters,
      columns: columns,
      sort_by: sort_by,
      page: page
    });
  }
}
