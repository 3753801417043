import { Component, OnInit } from "@angular/core";
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

interface FoodNode {
  name: string;
  children?: FoodNode[];
}

const TREE_DATA: FoodNode[] = [
  {
    name: 'Fruit',
    children: [
      { name: 'Apple' },
      { name: 'Banana' },
      { name: 'Fruit loops' },
    ]
  }, {
    name: 'Vegetables',
    children: [
      {
        name: 'Green',
        children: [
          { name: 'Broccoli' },
          { name: 'Brussels sprouts' },
        ]
      }, {
        name: 'Orange',
        children: [
          { name: 'Pumpkins' },
          { name: 'Carrots' },
        ]
      },
    ]
  },
];

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: "app-product-group",
  templateUrl: "./product-group.component.html",
  styleUrls: ["./product-group.component.scss"],
})
export class ProductGroupComponent implements OnInit {
  // public product = new Product();  
  groupList: any = [];
//   loading: boolean = false;
//   private _transformer = (node: FoodNode, level: number) => {
//     return {
//       expandable: !!node.children && node.children.length > 0,
//       name: node.name,
//       level: level,
//     };
//   }

//   treeControl = new FlatTreeControl<ExampleFlatNode>(
//     node => node.level, node => node.expandable);

//   treeFlattener = new MatTreeFlattener(
//     this._transformer, node => node.level, node => node.expandable, node => node.children);

//   dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


//   constructor() {
//     this.dataSource.data = TREE_DATA;
//   }
//   hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
// /**
//    * Handle the drop - here we rearrange the data based on the drop event,
//    * then rebuild the tree.
//    * */
//   drop(event: CdkDragDrop<string[]>) {
//     // console.log('origin/destination', event.previousIndex, event.currentIndex);
  
//     // ignore drops outside of the tree
//     if (!event.isPointerOverContainer) return;

//     // construct a list of visible nodes, this will match the DOM.
//     // the cdkDragDrop event.currentIndex jives with visible nodes.
//     // it calls rememberExpandedTreeNodes to persist expand state
//     const visibleNodes = this.visibleNodes();

//     // deep clone the data source so we can mutate it
//     const changedData = JSON.parse(JSON.stringify(this.dataSource.data));

//     // recursive find function to find siblings of node
//     function findNodeSiblings(arr: Array<any>, id: string): Array<any> {
//       let result, subResult;
//       arr.forEach(item => {
//         if (item.id === id) {
//           result = arr;
//         } else if (item.children) {
//           subResult = findNodeSiblings(item.children, id);
//           if (subResult) result = subResult;
//         }
//       });
//       return result;
//     }

//     // remove the node from its old place
//     const node = event.item.data;
//     const siblings = findNodeSiblings(changedData, node.id);
//     const siblingIndex = siblings.findIndex(n => n.id === node.id);
//     const nodeToInsert: FileNode = siblings.splice(siblingIndex, 1)[0];

//     // determine where to insert the node
//     const nodeAtDest = visibleNodes[event.currentIndex];
//     if (nodeAtDest.id === nodeToInsert.id) return;

//     // determine drop index relative to destination array
//     let relativeIndex = event.currentIndex; // default if no parent
//     const nodeAtDestFlatNode = this.treeControl.dataNodes.find(n => nodeAtDest.id === n.id);
//     const parent = this.getParentNode(nodeAtDestFlatNode);
//     if (parent) {
//       const parentIndex = visibleNodes.findIndex(n => n.id === parent.id) + 1;
//       relativeIndex = event.currentIndex - parentIndex;
//     }
//     // insert node 
//     const newSiblings = findNodeSiblings(changedData, nodeAtDest.id);
//     if (!newSiblings) return;
//     newSiblings.splice(relativeIndex, 0, nodeToInsert);
    
//     // rebuild tree with mutated data
//     this.rebuildTreeForData(changedData);
//   }

//   /**
//    * Experimental - opening tree nodes as you drag over them
//    */
//   dragStart() {
//     this.dragging = true;
//   }
//   dragEnd() {
//     this.dragging = false;
//   }
//   dragHover(node: FileFlatNode) {
//     if (this.dragging) {
//       clearTimeout(this.expandTimeout);
//       this.expandTimeout = setTimeout(() => {
//         this.treeControl.expand(node);
//       }, this.expandDelay);
//     }
//   }
//   dragHoverEnd() {
//     if (this.dragging) {
//       clearTimeout(this.expandTimeout);
//     }
//   }
  ngOnInit() { }
  clearExpenseFilters() { }
}
