import { Injectable, Injector } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpHeaders
} from "@angular/common/http";

import { ConstantService } from "../_config/constants";
import { Subscription } from "rxjs";
import { stringify } from "@angular/compiler/src/util";
import { AuthenticationService } from "./authentication.service";
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from './user.service';

@Injectable()
export class ApiService {
  data: any;
  result: any;
  isRefreshTokenInitiated: boolean = false;
  httpEvent: HttpEvent<Event>;

  static geturl(group, url) {
    const apiurl = ConstantService.API_URL[group][url];
    if (typeof url === "undefined") {
      return "";
    }
    return ConstantService.BASE_URL + "/" + apiurl;
  }
  constructor(public http: HttpClient,
    private jwtHelper: JwtHelperService,
    private injector: Injector) { }
  get(url: string, data: any = {}) {
    this.checkTokenExpirationTime();
    // this.data = data;
    console.log('url==>', url)
    url = url.replace(/:([^\/]+)/g, (_, key) => {
      // console.log('key==>', key);
      // console.log('data==>', data);
      return (typeof data[key] !== 'undefined') ? data[key] : '';
    });
    return this.http
      .get(url)
      ;
  }
  delete(url: string, data: any = {}) {
    this.checkTokenExpirationTime();
    // this.data = data;
    url = url.replace(/:([^\/]+)/g, (_, key) => {

      return (typeof data[key] !== 'undefined') ? data[key] : '';
    });
    return this.http
      .delete(url)
      ;
  }


  post(url: string, data: any = {}, options: any = {}) {
    this.checkTokenExpirationTime();
    // Call referesh token
    this.data = data;
    //check for Regex patterns
    url = url.replace(/:([^\/]+)/g, (_, key) => {

      return (typeof data[key] !== 'undefined') ? data[key] : '';
    });
    if (Object.keys(options).length == 0) {
      return this.http.post(url, this.data);
    } else {
      console.log('TEST', options);
      return this.http.post(url, this.data, options);
    }
  }

  put(url: string, data: any = {}, replace_params: any = {}) {
    this.checkTokenExpirationTime();
    this.data = data;
    //check for Regex patterns
    url = url.replace(/:([^\/]+)/g, (_, key) => {
      // console.log('key==>', key);
      // console.log('data==>', data);
      // console.log('url==>', url);
      return (typeof replace_params[key] !== 'undefined') ? replace_params[key] : '';
    });

    return this.http.put(url, this.data);
  }

  downloadFile(url: string, application_accept_type: string = 'application/text') {


    // let headers = new HttpHeaders();
    // headers = headers.set('Accept', application_accept_type);
    // return this.http.get(url, { headers: headers, responseType: 'blob' as 'json' });
    window.location.href = url;
  }
  uploadFile(url: string, files: File[], data: any) {
    this.checkTokenExpirationTime();
    let mFormData: FormData = new FormData();
    files.forEach((file, index) =>
      mFormData.append("file", file)
    );
    console.log("data in upload", mFormData);
    // Object.keys(data).forEach((key) => {
    // this.appendFormdata(mFormData, data, '');
    mFormData.append("data", JSON.stringify(data));
    // });
    url = url.replace(/:([^\/]+)/g, (_, key) => {
      // console.log('key==>', key);
      // console.log('data==>', data);
      // console.log('url==>', url);
      return (typeof data[key] !== 'undefined') ? data[key] : '';
    });
    const config = new HttpRequest<any>("POST", url, files, {
      reportProgress: true
      // , responseType: 'text'
    });
    console.log("config", config);    
    return this.http.post(url, mFormData);
  }

  appendFormdata(FormData: FormData, data: any, name: string) {
    name = name || "";
    console.log("data", name, typeof data);
    // console.log(Array.isArray(data), 'Testing Array');

    if (typeof data === "object") {
      Object.keys(data).forEach((key, index) => {
        if (name === "") {
          this.appendFormdata(FormData, data[key], key);
        } else {
          this.appendFormdata(FormData, data[key], name + "[" + key + "]");
        }
      });
    } else {
      FormData.append(name, data);
    }
  }
  getImageUrl(path: string) {
    console.log("====vvv==", ConstantService.IMG_BASE_URL)
    return ConstantService.IMG_BASE_URL + path;
  }

  checkTokenExpirationTime() {
   
    if (localStorage.getItem(ConstantService.TOKEN_NAME) != null) {
      let tokenExpirationTime = this.jwtHelper.decodeToken(
        localStorage.getItem(ConstantService.TOKEN_NAME)
      ).exp;
      let leastTime = 60 * 5;
      let expirationTime = (tokenExpirationTime - leastTime) - new Date().getTime() / 1000;
      let isTokenExpired = expirationTime <= 0 ? true : false;
      if (isTokenExpired == true && this.isRefreshTokenInitiated == false) {
        this.isRefreshTokenInitiated = true;
        this.http.post(ApiService.geturl('user', 'refresh_token'), {}).subscribe(result => {
          localStorage.setItem(ConstantService.TOKEN_NAME, result['token']);
          this.isRefreshTokenInitiated = false;
        });
        return isTokenExpired;
      }
    }
  }
}
