<!-- [typeahead]="accountHeadListTypeahead" -->


<ng-select (clear)="clearLedger()" (change)="updateLedger()" name="account_head"
  [required]="ledgerAccountsHeads?.is_required" [items]="ledgersList" name="ledger_name" bindLabel="name"
  [typeahead]="ledgersListTypeHead" #account_head="ngModel" [(ngModel)]="ledgerName" placeholder="Select Account">
  
  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
    <div style="font-weight: bold; m-0">
      {{ item.flattened_data.name }}
    </div>
    <div class="text-muted font-italic" style="font-size:12px" [innerHTML]="item.flattened_data.parent_name|join:'fa fa-caret-right m-2':true">


    </div>
  </ng-template>

  <ng-template ng-label-tmp let-item="item" let-index="index" let-search="searchTerm">
    <div [ngOptionHighlight]="search">
      {{ item?.flattened_data?.name }}
    </div>
  </ng-template>
</ng-select>
<app-validation-errors [mformField]="account_head" label="Account Head">
</app-validation-errors>

<!-- <ng-select [items]="ledgersList"  
    name="ledger" 
    bindLabel="account_head" [(ngModel)]="ledgerName" placeholder="Select Ledger">
    <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <div [ngOptionHighlight]="search">
            {{ item?.flattened_data?.name }}
        </div>
    </ng-template>
</ng-select> -->