import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  is_gst_same: boolean;

  constructor() { }

  checkGstState(gst1, gst2, state_data: any = {}) {
    gst1 = gst1.toString();
    gst2 = gst2.toString();
    let gst_string1 = gst1.slice(0, 2)
    let gst_string2 = gst2.slice(0, 2)
    if (gst_string1 == gst_string2) {
      // console.log('gst_string1', gst_string1)
      // console.log('gst_string2', gst_string2)
      let state_key = Object.keys(state_data);
      let gst_state: any = {}
      state_key.forEach((element) => {
        if (state_data[element]['Gst_code'] == gst_string2) {
          gst_state = state_data[element];
          // console.log('inside condition', state_data[element])
        }
      })
      return { is_gst_same: true, gst_state: gst_state };
    } else {
      return { is_gst_same: false };
    }

  }
}
