
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Routes } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { Title } from '@angular/platform-browser';
//import {tokenNotExpired} from 'angular2-jwt';

@Injectable()
export class AuthGuard implements CanActivate {
    role_id: any;

    menuPermission: any;

    constructor(private router: Router, private titleService: Title, private auth: AuthenticationService) { }


    loggedIn() {
        //        return tokenNotExpired();
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let routeData = route.data;
        // this.router.navigate(['/unauthorized']);
        // if (this.auth.isLoggedIn()) {
        //     //check for login page
        //     // logged in so return true
        //     let isuserroleallowed: boolean;

        //     if (!this.isLoginPage(state.url.toString())) {

        //         isuserroleallowed = this.checkForRolePermission(state.url.toString(), routeData.permissions);


        //     } else {
        //         isuserroleallowed = false;
        //     }
        //     if (isuserroleallowed === false) {
        //         this.router.navigate(['/unauthorized']);
        //         return true;
        //     }
        //     return isuserroleallowed;
        // } else if (routeData.required_login == false) {
        //     return true;
        // } else {
        //     // not logged in so redirect to login page
        //     if (this.isLoginPage(state.url.toString()) == false) {
        //         this.router.navigate(['/login']);
        //         return true;
        //     }
            return true;
        // }
    }

    isLoginPage(url: string) {
        var loginpages = ['/login'];
        if (loginpages.indexOf(url) >= 0) {
            return true;
        } else {
            return false;
        }
    }

    checkForRolePermission(url: string, permissions: any): boolean {

        if (permissions.indexOf(parseInt(this.auth.getUserRole())) >= 0) {
            return true;
        } else {

            return false;
        }

    }
    getMenus() {
        let menus: any = [];
        let parent_menus = [{}];
        this.router.config.forEach((config) => {
            if (typeof config.data != 'undefined') {
                if (typeof config.data.permissions != 'undefined') {
                    if (typeof config.data.menu != 'undefined' && config.data.permissions.indexOf(parseInt(this.auth.getUserRole())) >= 0) {
                        let current_menu_config: any = config.data.menu;
                        let is_parent = (typeof current_menu_config.id != 'undefined') ? true : false;
                        if (is_parent == true && current_menu_config.id != null) {
                            let menu: any = { url: config.path, display_name: current_menu_config.display_name, icon: current_menu_config.icon, id: current_menu_config.id, childrens: [] };
                            if (menu.display_name != '') {
                                menus[current_menu_config.id] = menu;
                            }

                        } else if (typeof current_menu_config.parent_id != 'undefined') {
                            let menu: any = { url: config.path, display_name: current_menu_config.display_name, icon: current_menu_config.icon, id: current_menu_config.parent_id, childrens: [] };
                            menus[current_menu_config.parent_id]['childrens'].push(menu)
                        }
                        //menus.push(menu);

                    }
                }
            }


        });
        return { menus: menus, parent_menus: parent_menus };
    }

    //     getSearchModules() {
    //         let searchModules: any = [];
    //         this.router.config.forEach((config) => {
    //             if (typeof config.data != 'undefined') {
    //                 if (typeof config.data.permissions != 'undefined') {
    //                     if (typeof config.data.search === true && config.data.permissions.indexOf(parseInt(this.auth.getUserRole())) >= 0) {
    //                         searchModules.push({ path: config.path })
    //                     }
    //                 }
    //             }
    // // elsa anna olaf spen chirstof

    //         });
    //         return { menus: menus, parent_menus: parent_menus };
    //     }

}
