import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import swal from "sweetalert2";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { CustomerService } from "../../../_services/customer.service";
import { Receipts } from "../../../_models/receipts";
// import { CustomersService } from '../../../app/_services/customers.service';
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { MastersService } from "../../../_services/masters.service";
import { CompanyService } from "../../../_services/company.service";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { ConstantService } from "src/app/_config/constants";
import { ReceiptsService } from "src/app/_services/receipts.service";
import { PaymentModalComponent } from "../../payment-modal/payment-modal.component";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HsnService } from "src/app/_services/hsn.service";
import { differenceInDays } from "date-fns";

@Component({
  selector: "app-receipts-add",
  templateUrl: "./receipts-add.component.html",
  // styleUrls: ['./receipt-add.component.scss']
})
export class ReceiptsAddComponent implements OnInit {
  id: string;
  filters: any = {};
  payment_accountList: any = [];
  payment_methodList: any = [
    { name: "Cash", id: 1031 },
    { name: "Bank", id: 1030 },
    // { name: "Check", id: 3 }
  ];
  is_edit_form: boolean = false;
  sort: any = {};
  // dt_default_sort = [{ prop: 'costcategory_name', dir: 'asc' }, { prop: 'under_cc_category', dir: 'asc' }];
  columns: any = {};
  page: any = { offset: 0, limit: 2 };
  receipt: Receipts = new Receipts();
  receiptList: any = [];
  payeeList: any = [];
  payeeListTypeahead = new EventEmitter<string>();
  payment_dateDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };
  purchaseorderDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    disableSince: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };
  receiptitemsArray: any = [];
  company_id: string;
  paymentMethodListTypeahead = new EventEmitter<string>();
  accountHeadListTypeahead = new EventEmitter<string>();
  receiptListTypeahead = new EventEmitter<string>();
  company_details: any;
  is_book_and_pay: boolean;
  receiptType: string;
  payType: string;
  passed_type: boolean;
  passed: boolean;
  is_payee: boolean = true;
  is_same_state: boolean;
  header: string;
  user_id: any;
  receipt_formtype: number;
  selectFiYear: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private receiptservice: ReceiptsService,
    private customerservice: CustomerService,
    private countryservice: MastersService,
    private authenticationService: AuthenticationService,
    private companyService: CompanyService,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private hsnService: HsnService
  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];
    this.user_id = this.authenticationService.getUserRole();

    this.receipt.payment_method = this.payment_methodList[0];
    this.getPaymentCoaHead("").subscribe((result) => {
      this.payment_accountList = result["response"];
    });
    this.getPaymentCoaHeadSearch();

    this.getIncomeCoaHead("").subscribe((result) => {
      this.receiptList = result["response"];
    });
    this.getIncomeCoaHeadSearch();
    // this.getPayee("").subscribe((result) => {
    //   console.log("------------------->>", result);
    //   this.payeeList = result["response"];
    // });
    // this.getPayeesearch();

    this.route.paramMap.subscribe((params) => {

      var result1 = this.router.url.match("/edit/");
      var result2 = this.router.url.match("/view/");
      var result3 = this.router.url.match("/book_pay/");

      if (result1 != null) {
        this.id = params.get("id");
        this.header = "Edit";
        this.receipt_formtype = 3;
        this.is_edit_form = true;
        this.viewReceipts(this.id);
      }
      else if (result2 != null) {
        this.id = params.get("id");
        this.header = "View";
        this.receipt_formtype = 2;
        this.is_edit_form = false;
        this.viewReceipts(this.id);

      } else if (result3 != null) {
        this.header = "Add";
        this.is_edit_form = false;
        this.receipt_formtype = 1;
        this.receipt.payment_date = {
          isRange: false,
          singleDate: {
            jsDate: differenceInDays(new Date(this.selectFiYear.end_year, 2, 31), new Date()) > 0 && differenceInDays(new Date(), new Date(this.selectFiYear.start_year, 3, 1)) > 0 ? new Date() : new Date(this.selectFiYear.end_year, 2, 31),
          },
        };
        this.receiptType = params.get("type");
        if (this.receiptType == "book_pay") {
          // this.is_book_and_pay = true;
          this.receipt.is_booking_payment = 1;
        } else if (this.receiptType == "direct_receipt") {
          // this.is_book_and_pay = true;
          this.receipt.is_booking_payment = 2;
        } else {
          this.receipt.is_booking_payment = 1;
          // this.is_book_and_pay = false;
        }
      }
    });

    this.addNewItem(0);
    this.getCompanyDetails();
  }

  //   this.getreceiptsList();

  onpayment_dateDateChanged(event: IMyDateModel): void {
    // date selected
  }
  addReceipt() {
    this.receipt.payment_date = this.receipt.payment_date["singleDate"][
      "jsDate"
    ].toISOString();
    this.receipt.company_id = this.company_id;
    this.receipt["tx_type"] = 111;

    let total_receipt_amount = 0;
    let total_tax_amount = 0;
    let balance_amount = 0;
    this.receipt.receipt_items.forEach((element) => {
      total_receipt_amount = total_receipt_amount + element["total_amount"];
      total_tax_amount = total_tax_amount + element["tax_amount"];
    });
    this.receipt.total_receipt_amount = total_receipt_amount;
    this.receipt.total_tax_amount = total_tax_amount;
    this.receipt.balance_amount = total_receipt_amount;
    this.receipt.is_booking_payment = 2;
    if (this.is_same_state) {
      this.receipt.cgst = parseFloat((total_tax_amount / 2).toFixed(2));
      this.receipt.sgst = parseFloat((total_tax_amount / 2).toFixed(2));
      this.receipt.igst = 0;
    } else {
      this.receipt.igst = parseFloat(total_tax_amount.toFixed(2));
      this.receipt.cgst = 0;
      this.receipt.sgst = 0;
    }
    if (this.is_payee) {
      this.receipt.pay_type = 2;
    } else {
      this.receipt.pay_type = 1;
    }
    this.receipt['receipt_status'] = 5;

    this.receiptservice.addReceipt(this.receipt).subscribe((result) => {
      if (result["ok"]==1) {
        delete this.receipt['receipt_status'];
        swal("Receipt created sucessfully!", "Receipt added.", "success");
        this.router.navigateByUrl("/receipt/list");
      } else {
        swal("Can't create receipt", "receipt error.", "error");
      }
    });
  }

  getHsnData(index) {
    console.log(index);
    if (
      this.receipt.receipt_items[index]["category"]["flattened_data"][
      "hsn_code"
      ] != undefined ||
      this.receipt.receipt_items[index]["category"]["flattened_data"][
      "hsn_code"
      ] != null
    ) {
      let condition = {};
      condition["_id"] = this.receipt.receipt_items[index]["category"][
        "flattened_data"
      ]["hsn_code"];
      condition["effects_from.is_active"] = true;
      this.hsnService.getHSNByCondition(condition, {}).subscribe((result) => {
        result["response"][0]["effects_from"].forEach((element) => {
          if (element.is_active == true) {
            this.receipt["receipt_items"][index]["tax"] = element.igst;
            console.log(this.is_same_state);
            if (this.is_same_state) {
              this.receipt["receipt_items"][index]["cgst_tax"] =
                element.igst / 2;
              this.receipt["receipt_items"][index]["sgst_tax"] =
                element.igst / 2;
            }

            if (!this.is_same_state) {
              this.receipt["receipt_items"][index]["igst_tax"] = element.igst;
            }

            this.calculateTotalAmount(
              this.receipt["receipt_items"][index]["amount"],
              index
            );
          }
        });
      });
    }
  }
  calculateGst(index, event) {
    if (this.is_same_state) {
      this.receipt.receipt_items[index]["cgst_amount"] =
        (event / 100) *
        parseInt(this.receipt["receipt_items"][index]["cgst_tax"]);
      this.receipt.receipt_items[index]["sgst_amount"] =
        (event / 100) *
        parseInt(this.receipt["receipt_items"][index]["sgst_tax"]);
    } else {
      this.receipt.receipt_items[index]["igst_amount"] =
        (event / 100) *
        parseInt(this.receipt["receipt_items"][index]["igst_tax"]);
    }
  }

  checkTaxBasedOnState() {
    let condition = {};
    this.companyService.viewCompany(this.company_id).subscribe((result) => {
      this.company_details = result["response"];
    });
    this.clearAmount();
    condition["_id"] = this.receipt.payee["flattened_data"]["ref_id"];
    this.customerservice.getCustomersData(condition, {}).subscribe((result) => {
      if (
        result["response"][0]["state"]["zone_id"] ==
        this.company_details.state["zone_id"]
      ) {
        this.is_same_state = true;
      } else {
        this.is_same_state = false;
      }
      //  this.calculateGst();
    });
  }

  calculateTotalAmount(event, index) {
    console.log("function calling", event);
    this.receipt.receipt_items[index]["tax_amount"] = 0;
    this.receipt.receipt_items[index]["total_amount"] = 0;
    if (!this.is_payee) {
      if (
        this.receipt["receipt_items"][index]["tax"] == "" ||
        this.receipt["receipt_items"][index]["tax"] == null
      ) {
        this.receipt.receipt_items[index]["total_amount"] = parseFloat(event);
        this.receipt.receipt_items[index]["tax_amount"] = 0;
      } else {
        console.log("======sdsdad======");
        this.receipt.receipt_items[index]["tax_amount"] =
          (event / 100) * parseInt(this.receipt["receipt_items"][index]["tax"]);

        this.receipt.receipt_items[index]["total_amount"] =
          this.receipt.receipt_items[index]["tax_amount"] + event;
        this.calculateGst(index, event);
      }
    } else {
      this.receipt.receipt_items[index]["total_amount"] = event;
    }
  }
  openPaymentModal(row_data, pay_method, bill_id) {
    let selected: any = [];
    let payment_by = "";
    // if (row_data) {
    //   selected.push(row_data);
    //   this.onSelectOrders({ selected });
    // }

    if (pay_method == "single") {
      this.payType = "single";
      this.passed = true;
      this.passed_type = true;
    }
    if (this.passed && this.passed_type) {
      console.log("-----3333");
      const modalRef = this.modalService.open(PaymentModalComponent, {
        size: "lg",
      });

      let dataArray = {
        payment_method: this.receipt.payment_method
          ? this.receipt.payment_method
          : "",
        is_booking: this.receipt.is_booking_payment,
        payment_date: this.receipt.payment_date
          ? this.receipt.payment_date
          : "",
        payment_account: this.receipt.payment_account
          ? this.receipt.payment_account
          : "",
        ref_id: this.receipt.ref_id ? this.receipt.ref_id : "",
        bill_ids: bill_id,
        payment_from: "Receipt",
        payment_type: this.payType,
        payment_by: "",
      };

      modalRef.componentInstance.selectedarray_data = dataArray;
      modalRef.result
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      swal("Error!", "Select same Payee / choose an order", "error");
    }
  }
  getReceipt() {
    // this.receiptservice.viewreceipt(this.id).subscribe((result) => {
    //   this.receipt = result[" response "];
    // });
  }

  getIncomeCoaHead(searchString) {
    this.receiptList = [];
    return this.countryservice.getCoaHead(this.company_id, searchString, {
      not_in: [
        ConstantService.coa_heads.Closing_Inventories,
        ConstantService.coa_heads.Premilinary_Expenses
      ],
    });
  }
  getIncomeCoaHeadSearch() {
    this.receiptListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getIncomeCoaHead(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.receiptList = result["response"];
        },
        (err) => {
          console.log(err);
          this.receiptList = [];
        }
      );
  }

  getPaymentCoaHead(searchString) {
    this.payment_accountList = [];
    return this.countryservice.getCoaHead(this.company_id, searchString, {
      in: [this.receipt.payment_method["name"]],
    });
  }

  getPaymentInChange() {
    this.getPaymentCoaHead("").subscribe((result) => {
      this.payment_accountList = result["response"];
    });
  }

  getPaymentCoaHeadSearch() {
    this.accountHeadListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getPaymentCoaHead(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.payment_accountList = result["response"];
        },
        (err) => {
          console.log(err);
          this.payment_accountList = [];
        }
      );
  }

  // getPayee(searchString) {
  //   this.payeeList = [];
  //   return this.countryservice.getCoaHead(this.company_id, searchString, {
  //     in: [ConstantService.coa_heads.Accounts_Receivable],
  //   });
  // }

  // getPayeesearch() {
  //   this.payeeListTypeahead
  //     .pipe(
  //       debounceTime(300),
  //       distinctUntilChanged(),
  //       switchMap((searchString) => this.getPayee(searchString))
  //     )
  //     .subscribe(
  //       (result) => {
  //         this.cd.markForCheck();
  //         this.payeeList = result["response"];
  //       },
  //       (err) => {
  //         console.log(err);
  //         this.payeeList = [];
  //       }
  //     );
  // }

  addUpdateReceipt() {
    if (this.is_edit_form === true) {
      this.editreceipt();
    } else {
      this.addReceipt();
    }
  }

  editreceipt() {
    let total_receipt_amount = 0;
    let total_tax_amount = 0;
    let balance_amount = 0;
    this.receipt.receipt_items.forEach((element) => {
      total_receipt_amount = total_receipt_amount + element["total_amount"];
      total_tax_amount = total_tax_amount + element["tax_amount"];
    });
    this.receipt.total_receipt_amount = total_receipt_amount;
    this.receipt.total_tax_amount = total_tax_amount;
    this.receipt.balance_amount = total_receipt_amount;
    this.receipt.is_booking_payment = 2;
    delete this.receipt['draft_data'];

    let draft_data = {};
    draft_data['payment_object'] = {};
    draft_data['payment_object'] = JSON.parse(JSON.stringify(this.receipt));

    draft_data['payment_object']['balance_amount'] = 0;
    draft_data['receipt_id'] = this.receipt['_id'];
    draft_data['payment_object']['balance_amount'] =
      this.receipt['total_receipt_amount'];
    this.receipt['draft_data'] = draft_data;
    this.receiptservice
      .editreceipt(this.receipt, this.id)
      .subscribe((result) => {
        if (result["ok"] == 1) {
          swal(" Success ", " Receipt Updated Successfully ");
          this.router.navigateByUrl("/receipt/list");
        } else {
          this.showErrors(result[" response "][" errors "]);
        }
      });
  }

  showErrors(errors) {
    let error_string: string;
    error_string = " ";
    errors.forEach((item) => {
      error_string += " < br / > " + item;
    });
    swal({
      title: "Error",
      html: error_string,
      type: "error",
    }).then(function () { });
  }

  addNewItem(index) {
    this.receipt.receipt_items.push({
      category: null,
      description: "",
      amount: "",
      tax: "",
    });
  }
  deleteItem(index) {
    this.receipt.receipt_items.splice(index, 1);
  }

  getCompanyDetails() {
    this.companyService.viewCompany(this.company_id).subscribe((result) => {
      this.company_details = result["response"];
      this.payment_dateDpOptions.dateFormat = this.company_details.date_format;
      let book_beginning_date = new Date(this.company_details.book_beginning);
      book_beginning_date.setDate(book_beginning_date.getDate() - 1);
      let curren_tx_dateOptions = this.getCopyOfOptions();
      curren_tx_dateOptions.disableUntil = {
        year: book_beginning_date.getFullYear(),
        month: book_beginning_date.getMonth() + 1,
        day: book_beginning_date.getDate(),
      };
      curren_tx_dateOptions.disableUntil = {year:this.selectFiYear.start_year, month:3, day:31};
      curren_tx_dateOptions.disableSince = {year:this.selectFiYear.end_year, month:4, day:1};
      this.payment_dateDpOptions = curren_tx_dateOptions;
    });
  }
  getCopyOfOptions(): IAngularMyDpOptions {
    return JSON.parse(JSON.stringify(this.payment_dateDpOptions));
  }
  clearAmount() {
    this.receipt.receipt_items.forEach((element, index) => {
      this.receipt.receipt_items[index]["total_amount"] = "";
      this.receipt.receipt_items[index]["tax_amount"] = "";
      this.receipt.receipt_items[index]["tax"] = "";
      this.receipt.receipt_items[index]["amount"] = "";
      this.receipt.receipt_items[index]["category"] = "";
    });
  }

  getVoucherType(voucher) {
    this.receipt.voucher_name = "";
    this.receipt.voucher_object = {};

    this.receipt.voucher_name = voucher.name;
    this.receipt.voucher_object = voucher;
  }
  viewReceipts(receiptId) {
    let condition = {};
    condition['company_id'] = this.company_id;
    condition['_id'] = receiptId;

    this.receiptservice.viewreceipt(condition).subscribe((result) => {
      this.receipt = result['data'];
      this.receipt.payment_date = {
        isRange: false,
        singleDate: {
          jsDate: new Date(this.receipt.payment_date),
        },
      };

      this.receipt.receipt_items.forEach((element, index) => {
        this.calculateTotalAmount(element.amount, index)
      });
      this.receipt.ref_id = this.receipt['draft_data']['payment_object']['ref_id'];

    });
  }
}
