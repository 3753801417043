<div class="card">
  <div class="card-body">
    <form class="form-horizontal" (ngSubmit)="addUpdateVendor()" novalidate #saveVendor="ngForm">
      <div class="row clearfix">
        <div class="d-flex justify-content-between align-items-center">
          <ul class="nav nav-tabs page-header-tab">
            <li class="nav-item"><a class="nav-link" id="vendor" data-toggle="tab"
                [ngClass]="{'active':navTab == 'vendor'}" (click)="navTab='vendor'"
                href="javascript:void(0);">Vendor</a>
            </li>
            <li class="nav-item"><a class="nav-link" id="bank_details" data-toggle="tab"
                [ngClass]="{'active':navTab == 'bank_details'}" (click)="navTab='bank_details'"
                href="javascript:void(0);">Bank
                Details</a>
            </li>
            <li class="nav-item"><a class="nav-link" id="statutory" data-toggle="tab"
                [ngClass]="{'active':navTab == 'statutory'}" (click)="navTab='statutory'"
                href="javascript:void(0);">Statutory
                Info</a>
            </li>
            <li class="nav-item"><a class="nav-link" [ngClass]="{'active':navTab == 'contacts'}"
                (click)="navTab='contacts'" id="Project-tab" data-toggle="tab" href="javascript:void(0);">Contacts</a>
            </li>
            <li *ngIf="is_edit_form==true" class="nav-item"><a class="nav-link"
                [ngClass]="{'active':navTab == 'contact_address'}" (click)="navTab='contact_address'" id="Project-tab"
                data-toggle="tab" href="javascript:void(0);">Contact Address</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="tab-pane fade" [ngClass]="navTab == 'vendor' ? 'show active':''" *ngIf="navTab=='vendor'" id="vendor"
        role="tabpanel">
        <div class="form-row ">
          <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
            <label>Vendor Name</label>
            <input type="text" name="vendor_name" required #vendor_name="ngModel" class="form-control"
              placeholder="Vendor Name" [(ngModel)]="vendor.vendor_name">
            <app-validation-errors [mformField]="vendor_name" label="Vendor Name"></app-validation-errors>
          </div>
          <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="title">Email</label>
            <input type="text" class="form-control" #email="ngModel" required placeholder="Email" name="email"
              id="email" [(ngModel)]="vendor.email" email>
            <app-validation-errors [mformField]="email" label="Email"></app-validation-errors>
          </div>
          <!-- <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="first_name">Name Of Contact</label>
            <input type="text" class="form-control" required #first_name="ngModel" name="first_name"
              placeholder="First Name Of Contact" id="first_name_of_contact" [(ngModel)]="vendor.first_name">
            <app-validation-errors [mformField]="first_name" label="Name Of Contact"></app-validation-errors>
          </div> -->
          <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="last_name">Last Name Of Contact</label>
              <input type="text" class="form-control" placeholder="Last Name Of Contact" name="last_name"
                id="last_name_of_contact" [(ngModel)]="vendor.last_name">
            </div> -->
          <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="phone">Phone</label>
            <input type="text" class="form-control" name="phone" id="phone" placeholder="phone"
              [(ngModel)]="vendor.phone" maxlength="13">
          </div>
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="fax">Fax</label>
            <input type="text" maxlength="20" class="form-control" name="fax" id="fax" placeholder="Fax"
              [(ngModel)]="vendor.fax">
          </div>
          <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="mobile">Mobile</label>
            <input type="text" class="form-control" #mobile="ngModel" required name="mobile" id="mobile"
              placeholder="Mobile" maxlength="13" [(ngModel)]="vendor.mobile" number>
            <app-validation-errors [mformField]="mobile" label="Mobile"></app-validation-errors>

          </div>
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="toll-free">Toll Free</label>
            <input type="text" maxlength="15" class="form-control" name="toll-free" id="toll-free"
              placeholder="Toll Free" [(ngModel)]="vendor.toll_free">
          </div> -->
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="web_site">Website</label>
            <input type="text" class="form-control" name="website" id="website" placeholder="Web Site"
              [(ngModel)]="vendor.website">
          </div>
          <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="gst_number">GST Number</label>
              <input type="text" class="form-control" name="gst_number" id="gst_number" placeholder="GST Number"
                [(ngModel)]="vendor.gst_number" maxlength="15">
            </div> -->
          
          <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="address_line1">Address Line 1</label>
            <input maxlength="200" type="text" class="form-control" #address="ngModel" required name="address_line1"
              id="address_line1" placeholder="Address Line 1" [(ngModel)]="vendor.address_line1">
            <app-validation-errors [mformField]="address" label="Address"></app-validation-errors>
          </div>
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="address_line2">Address Line 2</label>
            <input maxlength="200" type="text" class="form-control" name="address_line2" id="address_line2"
              placeholder="Address Line 2" [(ngModel)]="vendor.address_line2">
          </div>
          <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="country">Country</label>
            <ng-select [items]="countryList" required #country="ngModel" [typeahead]="countryListTypeahead"
              name="country" bindLabel="name" (change)="getStateByCountry()" [(ngModel)]="vendor.country"
              placeholder="Select Country">
              <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div [ngOptionHighlight]="search">
                  {{ item.name }}
                </div>
              </ng-template>
            </ng-select>
            <app-validation-errors [mformField]="country" label="Country"></app-validation-errors>
          </div>

          <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="state">Province/State</label>
            <select type="text" class="form-control" name="state" #state="ngModel" required id="state"
              placeholder="State" [(ngModel)]="vendor.state">
              <option *ngFor="let item of stateList| keyvalue" [ngValue]="item.value">{{item.value.name}} </option>
            </select>
            <app-validation-errors [mformField]="state" label="State"></app-validation-errors>
          </div>

          <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="city">City</label>
            <input type="text" class="form-control" #city="ngModel" required name="city" id="city" placeholder="City"
              [(ngModel)]="vendor.city">
            <app-validation-errors [mformField]="city" label="City"></app-validation-errors>
          </div>
          <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="postal_code">Zip/Postal Code</label>
            <input type="text" class="form-control" #postal_code="ngModel" required name="postal_code" id="postal_code"
              placeholder="Zip/Postal Code" [(ngModel)]="vendor.postal_code" number maxlength="6">
            <app-validation-errors [mformField]="postal_code" label="Postal code"></app-validation-errors>
          </div>
          <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="currency">Currency</label>
            <ng-select [items]="currency_list" required #currency="ngModel" name="currency" bindLabel="name"
              [(ngModel)]="vendor.currency" placeholder="Select Currency">
              <!-- <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div [ngOptionHighlight]="search">
                  {{ item.name }}
                </div>
              </ng-template> -->
            </ng-select>
            <app-validation-errors [mformField]="currency" label="Currency"></app-validation-errors>
          </div>
          <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="payment_term">Payment Term</label>
            <select class="form-control" required #payment_term="ngModel" id="payment_term" name="payment_term"
              [(ngModel)]="vendor.payment_term">
              <option value="0" selected="selected">Immediate</option>
              <option value="2" selected="selected">Due in 2 days</option>
              <option value="3">Due in 3 days</option>
              <option value="4">Due in 4 days</option>
              <option value="5">Due in 5 days</option>
              <option value="10">Due in 10 days</option>
              <option value="15">Due in 15 days</option>
              <option value="25">Due in 25 days</option>
              <option value="30">Due in 30 days</option>
              <option value="40">Due in 40 days</option>
              <option value="50">Due in 50 days</option>
              <option value="60">Due in 60 days</option>
              <option value="70">Due in 70 days</option>
              <option value="80">Due in 80 days</option>
              <option value="90">Due in 90 days</option>
            </select>
            <app-validation-errors [mformField]="payment_term" label="Payment Term"></app-validation-errors>
          </div>
          <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="vendor_category">Vendor Category</label>
            <ng-select [items]="vendor_categories" required #vendor_category="ngModel" name="vendor_category" bindLabel="name"
              [(ngModel)]="vendor.vendor_category" placeholder="Select Vendor Category">
            </ng-select>
            <app-validation-errors [mformField]="vendor_category" label="Vendor Category"></app-validation-errors>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" [ngClass]="navTab == 'bank_details' ? 'show active':''" *ngIf="navTab=='bank_details'"
        id="bank_details" role="tabpanel">
        <div class="form-row">
          <div class="card mt-2">

            <div class="form-row m-2" *ngFor="let bankObj of vendor.bank; let i = index">
              <div class="form-group  col-md-2">
                <label for="">Bank Name</label>
                <input type="text" class="form-control" placeholder="Bank Name" #bank_name="ngModel" id="bank_name{{i}}"
                  name="bankObj.name{{i}}" [(ngModel)]="bankObj.name">
                <app-validation-errors [mformField]="bank_name" label="Bank Name">
                </app-validation-errors>
              </div>
              <div class="form-group col-md-2">
                <label for="">Branch</label>
                <input type="text" class="form-control" placeholder="Branch Name" id="branch{{i}}"
                  name="bankObj.branch{{i}}" [(ngModel)]="bankObj.branch">
              </div>
              <div class="form-group col-md-3">
                <label for="">Account Number</label>
                <input type="text" class="form-control" placeholder="Account Number" id="account_number{{i}}"
                  name="bankObj.account_number{{i}}" [(ngModel)]="bankObj.account_number">
              </div>
              <div class="form-group col-md-3">
                <label for="">IFSC Code</label>
                <input type="text" class="form-control" placeholder="IFSC Code" id="ifsc_code{{i}}"
                  name="bankObj.ifsc_code{{i}}" [(ngModel)]="bankObj.ifsc_code">
              </div>
              <div class="form-group col-md-2">
                <button class="btn btn-primary mr-2 mt-4" type="button" (click)="addBankRow()">
                  <i class="fa fa-plus"></i>
                </button>
                <button *ngIf="i>0" class="btn btn-danger mt-4" type="button" (click)="deleteBankRow(i)">
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" [ngClass]="navTab == 'statutory' ? 'show active':''" *ngIf="navTab=='statutory'"
        id="statutory" role="tabpanel">
        <div class="form-row">

          <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <label for="pan">PAN</label>
            <input type="text" maxlength="10" name="pan" id="pan" class="form-control" [(ngModel)]="vendor.pan">
          </div>

          <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <label for="gst">GSTN</label>
            <input maxlength="15" type="text" name="gst" class="form-control" [(ngModel)]="vendor.gst_number">
          </div>
          <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <label for="gst_effective_date">GSTN Effective Date</label>
            <div class="input-group">
              <input class="form-control" name="gst_effective_date" #gst_effective_date="ngModel"
                placeholder="Select a date" angular-mydatepicker #dp_gst_effective_date="angular-mydatepicker"
                [(ngModel)]="vendor.gst_effective_date" [options]="gstEffectiveDateOptions" />
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp_gst_effective_date.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp_gst_effective_date.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>
            </div>
            <app-validation-errors [mformField]="gst_effective_date" label="GST Effective Date">
            </app-validation-errors>
          </div>
        </div>

        <div class="form-row" [ngClass]="vendor.is_tds_applicable==false? 'mb-3': ''">
          <div class="form-group col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 custom-switch pl-5">
            <input type="checkbox" value="true" [(ngModel)]="vendor.is_tds_applicable" name="is_tds_applicable"
              class="custom-control-input" id="is_tds_applicable">
            <label class="custom-control-label" for="is_tds_applicable">TDS Applicable ?</label>
          </div>

          <div *ngIf="vendor.is_tds_applicable"
            class="form-group col-12 custom-switch col-sm-3 col-md-3 col-lg-3 col-xl-3">
            <input type="checkbox" value="true" [(ngModel)]="vendor.is_multiple_tds" name="is_multiple_tds"
              class="custom-control-input" id="is_multiple_tds">
            <label class="custom-control-label" for="is_multiple_tds">Multiple TDS</label>
          </div>

          <div *ngIf="vendor.is_tds_applicable" class="form-group col-12 col-md-6 col-lg-6 col-xl-6">
            <label for="tds">TDS</label>
            <ng-select class="hsn_select" [items]="TDSList" [multiple]="vendor.is_multiple_tds"
              [typeahead]="TDSListTypeahead" name="tds" bindLabel="name" [(ngModel)]="vendor.tds"
              placeholder="Select TDS">
              <ng-template ng-label-tmp let-item="item" let-index="index" let-search="searchTerm">
                <p class="text-wrap">{{ item.nature_of_payment}}</p>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div [ngOptionHighlight]="search">
                  <p class="text-wrap"> {{ item.nature_of_payment}} - <b> {{item.section}}</b></p>
                  <ng-container>
                    <small *ngFor="let value of item.basic_cut_off"> Greaterthan {{ value.greater_than }} for
                      {{value.period}}</small>
                  </ng-container>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>

        <!-- <div class="form-row">
          <div class="form-group col-12 col-sm-6 col-md-6 col-xl-6">
            <label for="tan">TAN</label>
            <input type="text" name="tan" class="form-control" [(ngModel)]="vendor.tan">
          </div>
          <div class="form-group  col-12 col-sm-6 col-md-6 col-xl-6">
            <label for="tan_effective_date">TAN Effective Date</label>
            <div class="input-group">
              <input class="form-control" name="tan_effective_date" #tan_effective_date="ngModel"
                placeholder="Select a date" angular-mydatepicker #dp_tan_effective_date="angular-mydatepicker"
                [(ngModel)]="vendor.tan_effective_date" [options]="tanEffectiveDateOptions" />
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp_tan_effective_date.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp_tan_effective_date.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>
            </div>
            <app-validation-errors [mformField]="tan_effective_date" label="Tan Effective Date">
            </app-validation-errors>
          </div>
        </div> -->

        <div class="form-group col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 custom-switch pl-5 pb-5">
          <input type="checkbox" value="true" [(ngModel)]="vendor.is_composite_company" name="is_composite_company"
            class="custom-control-input" id="is_composite_company">
          <label class="custom-control-label" for="is_composite_company">Composite vendor</label>
        </div>
        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 custom-switch pl-5">
          <input type="checkbox" value="true" [(ngModel)]="vendor.is_sez" name="is_sez" class="custom-control-input"
            id="is_sez">
          <label class="custom-control-label" for="is_sez">SEZ unit</label>
        </div>
      </div>

      <div class="tab-pane fade" [ngClass]="navTab == 'contacts' ? 'show active':''" *ngIf="navTab=='contacts'"
        id="contacts" role="tabpanel">
        <!-- <h4>Provide Contact Details </h4> -->

        <div class="form-row " *ngFor="let contactsObj of vendor.contacts; let i = index">
          <div class="form-group required col-12 col-sm-6 col-md-6 col-xl-3">
            <label for="">Contact Name</label>
            <input type="text" maxlength="100" class="form-control" placeholder="Contact Name" #contact_name="ngModel"
              required id="example{{i}}" name="example{{i}}" [(ngModel)]="contactsObj.contact_name">
            <app-validation-errors [mformField]="contact_name" label="Contact Name"></app-validation-errors>
          </div>
          <div class="form-group required col-12 col-sm-6 col-md-6 col-xl-2">
            <label for="">Phone </label>
            <input type="text" class="form-control" maxlength="13" required #phone_number="ngModel"
              placeholder="Phone Number" id="contactsObj.phone_number{{i}}" name="contactsObj.phone_number{{i}}"
              [(ngModel)]="contactsObj.phone_number" number>
            <app-validation-errors [mformField]="phone_number" label="Phone Number">
            </app-validation-errors>
          </div>
          <div class="form-group required col-12 col-sm-6 col-md-6 col-xl-3">
            <label for="">Email</label>
            <input type="text" class="form-control" required #email="ngModel" placeholder="Email"
              id="contactsObj.email{{i}}" name="contactsObj.email{{i}}" [(ngModel)]="contactsObj.email" email>
            <app-validation-errors [mformField]="email" label="Email"></app-validation-errors>
          </div>
          <div class="form-group required col-12 col-sm-6 col-md-6 col-xl-2">
            <label for="">Designation</label>
            <input type="text" maxlength="100" class="form-control" required #designation="ngModel"
              placeholder="Designation" id="designation{{i}}" name="contactsObj.designation{{i}}"
              [(ngModel)]="contactsObj.designation">
            <app-validation-errors [mformField]="designation" label="Designation"></app-validation-errors>
          </div>
          <div class="form-group col-md-2">
            <button class="btn btn-primary mr-2 mt-4" type="button" (click)="addContactRow()">
              <i class="fa fa-plus"></i>
            </button>
            <button *ngIf="i!=0" class="btn btn-danger mt-4" type="button" (click)="deleteContactRow(i)">
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" [ngClass]="navTab == 'contact_address' ? 'show active':''"
        *ngIf="navTab=='contact_address'" id="contact_address" role="tabpanel">
        <div class="col-12">
          <app-address-template [addressConfig]="addressListConfig"></app-address-template>
        </div>
      </div>


      <button type="submit" class="btn btn-primary" [disabled]="!saveVendor.valid || loading==true">Save
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
      </button>
    </form>
  </div>
</div>