<ngb-alert (close)="closed=true" *ngIf="company_details.is_gst == false && !closed">
  This company non GST company
</ngb-alert>
<ngb-alert (close)="closed=true" *ngIf="company_details.is_composite_company == true && !closed">
  Composite Company
</ngb-alert>
<div class="alert alert-primary text-center" *ngIf="!is_gst_applicable" role="alert">
  <p>LUT is enabled for your company and Customer is different country, So Tax may not be applicable</p>
</div>
<div class="card">
  <div class="card-header d-flex align-items-center">
    <h3 class="table-title"> {{header}} </h3>
  </div>
  <div class="card-body">
    <div class="row clearfix">
      <form class="form-horizontal" (ngSubmit)="addUpdateInvoice()" novalidate #saveInvoice="ngForm"
        enctype="multipart/form-data">
        <div class="form-row ">


          <div class="form-group required col-8 col-sm-8 col-md-8 col-xl-5">
            <label for="customer">Customer</label>
			
            <ng-select *ngIf="!is_debit_note" [items]="customer_lists" [typeahead]="customerListTypeahead"
              #customer="ngModel" (change)="onChangeCustomer();getSalesOrderByCustomer()" required name="customer"
              bindLabel="customer_name" [(ngModel)]="invoice.customer" placeholder="Select Customer">
              <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div [ngOptionHighlight]="search">
                  {{ item.customer_name }}
                </div>
              </ng-template>
            </ng-select>

            <app-validation-errors [mformField]="customer" label="Customer"></app-validation-errors>
            <div *ngIf="is_debit_note">
              {{invoice?.customer?.customer_name}}
            </div>
          </div>

          <div *ngIf="invoice?.customer?.currency?.name || invoice?.currency?.name"
            class="col-1 col-lg-1 col-lg-1 col-md-1">
            <label class="invisible">Currency</label>
            <span class="badge badge-primary">
              {{is_debit_note? invoice.currency.name: invoice.customer.currency.name}}
            </span>
          </div>
          <div *ngIf="!is_debit_note && !is_debit_note_add" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="credit_notes">Sales Order Number</label>
            <ng-select [addTag]="true" [items]="salesOrderList" [typeahead]="salesOrderListTypeahead" #product="ngModel"
              name="sales_order" bindValue="sales_order_number" bindLabel="sales_order_number"
              [(ngModel)]="invoice.sales_order_number" (change)="redirectToSalesInvoiceOrder()"
              placeholder="Select Sales Order">
              <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div [ngOptionHighlight]="search">
                  {{ item.sales_order_number }}
                </div>
              </ng-template>
            </ng-select>
          </div>

          <div *ngIf="invoice.customer && !is_debit_note && !is_debit_note_add"
            class="form-group col-12 col-sm-6 col-md-6 col-xl-6">
            <label for="billing_address">Billing Address
              <div class="d-inline-block" ngbDropdown #billingAddressDropdown>
                <!-- <a href="javascript:void(0)" id="billing_address_dropdown" ngbDropdownToggle>Change</a> -->
                <div ngbDropdownMenu aria-labelledby="billing_address_dropdown">
                  <app-address-template (updateAddress)="updateAddress($event, billingAddressDropdown)"
                    [addressConfig]="billingAddressListTmplConfig"></app-address-template>
                </div>
              </div>
            </label>
            <app-address-template [addressConfig]="billingAddressTemplateConfig"></app-address-template>
          </div>

          <div *ngIf="invoice.customer && !is_debit_note && !is_debit_note_add"
            class="form-group col-12 col-sm-6 col-md-6 col-xl-6">
            <label for="shipping_address">Shipping Address
              <div class="d-inline-block" ngbDropdown #shippingAddressDropdownEvent>
                <a (click)="shippingAddressDropdownEvent.open()" href="javascript:void(0)"
                  id="shipping_address_dropdown" ngbDropdownToggle>Change</a>
                <div ngbDropdownMenu aria-labelledby="shipping_address_dropdown">
                  <app-address-template (updateAddress)="updateAddress($event, shippingAddressDropdownEvent)"
                    [addressConfig]="shippingAddressListTmplConfig"></app-address-template>
                </div>
              </div>
            </label>
            <app-address-template [addressConfig]="shippingAddressTemplateConfig"></app-address-template>
          </div>
          <div class="form-group  required col-12 col-sm-6 col-md-3 col-xl-3" *ngIf="voucher_type_lists?.length >1 ">
            <label class="" for="voucher_type">Voucher Type</label>
            <ng-select *ngIf="!is_debit_note" [items]="voucher_type_lists" #voucher_type="ngModel" required
              name="voucher_type" bindLabel="voucher_type" [(ngModel)]="invoice.voucher_type"
              placeholder="Select Voucher Type">
              <!-- <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div [ngOptionHighlight]="search">
                  {{ item.voucher_type }}
                </div>
              </ng-template> -->
            </ng-select>
          </div>
          <!-- <div class="form-group  required col-12 col-sm-6 col-md-3 col-xl-3" *ngIf="voucher_type_lists?.length >1 ">
            <label class="" for="voucher_type">Voucher Type</label>
            <ng-select *ngIf="!is_debit_note" [items]="voucher_type_lists" (change)="updateVoucher(voucher_type.value)"
              #voucher_type="ngModel" required name="voucher_type" bindLabel="voucher_type"
              [(ngModel)]="invoice.voucher_type" placeholder="Select Voucher Type">
              <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div [ngOptionHighlight]="search">
                  {{ item.voucher_type }}
                </div>
              </ng-template>
            </ng-select>
          </div> -->
          <!-- <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3" *ngIf="!is_debit_note_add">
            <label for="invoice_number">{{labelName}} Number</label>
            <div *ngIf="is_debit_note">{{invoice?.invoice_number}}</div>
            <div *ngIf="is_debit_note==false">
              <input class="form-control" name="invoice_number" #invoice_number="ngModel" placeholder="Invoice Number"
                [(ngModel)]="invoice.invoice_number" disabled />
              <app-validation-errors [mformField]="invoice_number" label="Invoice Number">
              </app-validation-errors>
            </div>

          </div> -->
          <div *ngIf="is_debit_note==true || is_debit_note_add"
            class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="credit_number">Credit Note Number</label>
            <input class="form-control" required name="credit_number" #credit_number="ngModel"
              placeholder="Credit Number" [(ngModel)]="invoice.debit_number" />
            <app-validation-errors [mformField]="credit_number" label="Credit Number">
            </app-validation-errors>

          </div>



          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="invoice_date">{{labelName}} Date</label>
            <div class="input-group" *ngIf="!is_debit_note">
              <input class="form-control" name="invoice_date" placeholder="Select a date" angular-mydatepicker
                (dateChanged)="onDateChanged($event)" #dp_invoice="angular-mydatepicker"
                (click)="dp_invoice.toggleCalendar()" [(ngModel)]="invoice.invoice_date"
                [options]="invoiceDateOptions" />
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" *ngIf="invoice.invoice_date"
                  (click)="dp_invoice.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp_invoice.toggleCalendar()"
                  (change)="updateDueDate()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>
            </div>
            <div *ngIf="is_debit_note">
              {{invoice.invoice_date|date}}
            </div>
          </div>
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3" *ngIf="!is_debit_note_add">
            <label for="due_date">Due Date</label>
            <div class="input-group" *ngIf="!is_debit_note">
              <input class="form-control" name="due_date" placeholder="Select a date" angular-mydatepicker
                #dp_duedate="angular-mydatepicker" (click)="dp_duedate.toggleCalendar()" [(ngModel)]="invoice.due_date"
                [options]="dueDateOptions" />
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" *ngIf="invoice.due_date"
                  (click)="dp_duedate.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp_duedate.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>
            </div>
            <div *ngIf="is_debit_note">
              {{invoice.due_date|date}}
            </div>
          </div>
          <!-- <div *ngIf="addForm" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="invoice_image">Upload Invoice</label>
            <input ngfSelect type="file" name="invoice_image" class="form-control" [(files)]="files" />
          </div> -->


          <div *ngIf="is_sales_invoice==true" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="sales_order_date">Sales Order Date</label>
            <div class="input-group" *ngIf="!is_debit_note && !is_sales_invoice">
              <input class="form-control" name="sales_order_date" placeholder="Select a date" angular-mydatepicker
                #dp_salesOrderdate="angular-mydatepicker" (click)="dp_salesOrderdate.toggleCalendar()"
                [(ngModel)]="invoice.sales_order_date" [options]="salesOrderdateOptions" />
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" *ngIf="invoice.sales_order_date"
                  (click)="dp_salesOrderdate.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp_salesOrderdate.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>
            </div>
            <div *ngIf="is_debit_note || is_sales_invoice">
              {{invoice.sales_order_date|date}}
            </div>
          </div>



          <div *ngIf="company_details?.sales_column_settings?.delivery_note_display"
            class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="delivery_note">Delivery Note</label>
            <textarea class="form-control" *ngIf="!is_debit_note" name="delivery_note" id="delivery_note"
              placeholder="Delivery Note" [(ngModel)]="invoice.delivery_note"></textarea>
            <div *ngIf="is_debit_note">
              {{invoice.delivery_note}}
            </div>
          </div>
          <div *ngIf="company_details?.sales_column_settings?.suppliers_ref_display"
            class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="suppliers_ref">Suppliers Ref</label>
            <textarea class="form-control" *ngIf="is_debit_note" name="suppliers_ref" id="suppliers_ref"
              placeholder="Suppliers Ref" [(ngModel)]="invoice.suppliers_ref"></textarea>
            <div *ngIf="is_debit_note">
              {{invoice.suppliers_ref}}
            </div>
          </div>
          <div *ngIf="company_details?.sales_column_settings?.others_ref_display"
            class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="others_ref">Others Ref</label>
            <textarea class="form-control" *ngIf="is_debit_note" name="others_ref" id="others_ref"
              placeholder="Others Ref" [(ngModel)]="invoice.others_ref"></textarea>
            <div *ngIf="is_debit_note">
              {{invoice.others_ref}}
            </div>
          </div>
          <div *ngIf="company_details?.sales_column_settings?.despatched_through_display"
            class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="despatched_through">Despatched Through</label>
            <input type="text" class="form-control" *ngIf="is_debit_note" name="despatched_through"
              id="despatched_through" placeholder="Despatched Through" [(ngModel)]="invoice.despatched_through">
            <div *ngIf="is_debit_note">
              {{invoice.despatched_through}}
            </div>
          </div>
          <div *ngIf="company_details?.sales_column_settings?.destination_display"
            class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="destination">Destination</label>
            <input type="text" class="form-control" *ngIf="is_debit_note" name="destination" id="destination"
              placeholder="Destination" [(ngModel)]="invoice.destination">
            <div *ngIf="is_debit_note">
              {{invoice.destination}}
            </div>
          </div>
          <div *ngIf="company_details?.sales_column_settings?.motor_vehicle_no_display"
            class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="motor_vehicle_no">Motor Vehicle No</label>
            <input type="text" class="form-control" *ngIf="is_debit_note" name="motor_vehicle_no" id="motor_vehicle_no"
              placeholder="Motor Vehicle No" [(ngModel)]="invoice.motor_vehicle_no">
            <div *ngIf="is_debit_note">
              {{invoice.motor_vehicle_no}}
            </div>
          </div>
          <div *ngIf="company_details?.sales_column_settings?.eway_bill_no_display"
            class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="eway_bill_no">Eway Bill No</label>
            <input type="text" class="form-control" *ngIf="is_debit_note" name="eway_bill_no" id="eway_bill_no"
              placeholder="Eway Bill No" [(ngModel)]="invoice.eway_bill_no">
            <!-- <div *ngIf="is_debit_note">
              {{invoice.eway_bill_no}}
            </div> -->
          </div>
          <div *ngIf="company_details?.sales_column_settings?.eway_bill_date_display"
            class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="eway_bill_date">Eway Bill Date</label>
            <div class="input-group" *ngIf="is_debit_note">
              <input class="form-control" name="eway_bill_date" placeholder="Select a date" angular-mydatepicker
                #dp_eway_bill_date="angular-mydatepicker" [(ngModel)]="invoice.eway_bill_date"
                [options]="ewayBillDateOptions" />
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" *ngIf="invoice.eway_bill_date"
                  (click)="dp_eway_bill_date.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp_eway_bill_date.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>

            </div>


            <!-- <div *ngIf="is_debit_note">
              {{invoice.eway_bill_no"}}
            </div> -->
          </div>
          <div class="form-group col-12 required col-sm-6 col-md-3 col-xl-3" *ngIf="is_debit_note">
            <label for="credit_date">Credit Note Date</label>
            <div class="input-group">
              <input class="form-control" required name="credit_date" placeholder="Select a date" angular-mydatepicker
                #dp_credit_date="angular-mydatepicker" [(ngModel)]="invoice.credit_date" [options]="DebitDateOptions" />
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" *ngIf="invoice.credit_date"
                  (click)="dp_credit_date.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="dp_credit_date.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>
            </div>
          </div>
          <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3" *ngIf="is_debit_note">
            <label for="debit_notes">Notes</label>
            <textarea class="form-control" name="debit_notes" id="debit_notes" placeholder="credit Notes"
              [(ngModel)]="invoice.debit_notes"></textarea>
          </div> -->


          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3" *ngIf="is_debit_note">
            <label for="credit_notes">Ledgers:</label>
            <div>
              {{invoice.ledger['name']}}
            </div>
          </div>
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 required" *ngIf="!is_debit_note && user_id !=8">
            <label for="credit_notes">{{labelName}} Ledger</label>
            <app-ledgers (onSelectLedger)="updateLedger($event)" [ledgerAccountsHeads]="invoiceAccountConfig">
            </app-ledgers>
          </div>
          <!-- <div class="form-group col-12 custom-switch col-sm-6 col-md-3 col-xl-3 pl-5"
            *ngIf="is_debit_note_add==false&&is_gst_applicable==false">
            <input type="checkbox" value="true" [(ngModel)]="invoice.is_without_gst" (change)="onGstChange();"
              name="is_without_gst" class="custom-control-input" id="is_without_gst">
            <label class=" custom-control-label" for="is_without_gst">Without GST ?</label>
          </div> -->

          <div class="card">
            <div class="card-body form-row p-0 pb-3">
              <!-- col-12 table table-stripped table-hover table-bordered -->

              <table class="col-12 table table-stripped table-hover table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 4%;"><b> S.No </b></th>
                    <th style="width: 40%;"><b> {{company_details?.sales_column_settings?.items}}</b> </th>
                    <!-- <th>{{company_details?.sales_column_settings?.description}}</b> </th> -->
                    <th style="width: 8%;"><b>{{company_details?.sales_column_settings?.units}}</b> </th>
                    <th style="width: 10%;"><b>{{company_details?.sales_column_settings?.price}}</b> </th>
                    <!-- <th>Tax</b> </th> -->
                    <th style="width: 8%;" *ngIf="invoice.is_productwise_discount && invoice.discount_in_percentage">
                      <b>Discount-(%)
                      </b>
                    </th>
                    <th style="width: 8%;"
                      *ngIf="invoice.is_productwise_discount && invoice.discount_in_percentage==false">
                      <b>Discount-(flat)
                      </b>
                    </th>
                    <th style="width: 8%;" *ngIf="is_composite_company==false && invoice.is_without_gst==false">
                      <b>Tax</b>
                    </th>

                    <th style="width: 8%;"><b>Amount</b> </th>

                    <th style="width: 12%"><b>Action</b> </th>
                  </tr>

                </thead>
                <tbody *ngFor="let itemsObj of invoice.invoice_items; let i = index">
                  <tr *ngIf="is_debit_note">
                    <td>
                      {{i+1}}
                    </td>
                    <td>

                      <div>
                        {{itemsObj?.product?.name}}
                      </div>
                      <app-validation-errors [mformField]="product" label="Product">
                      </app-validation-errors>

                      <div>
                        {{itemsObj.description}}
                      </div>
                    </td>

                    <td *ngIf="is_debit_note">
                      <input [disabled]="itemsObj?.product?.type == 2" type="number" [min]="1"
                        [max]="itemsObj.max_units" class="form-control" #units="ngModel" required name="units{{ i }}"
                        id="units{{ i }}" placeholder="Units" [(ngModel)]="itemsObj.units"
                        (change)="calculatePrice(itemsObj);getProductQty(itemsObj.product,itemsObj)">
                      <!-- <input  *ngIf= "product_type == 2" disabled type="text" class="form-control"
                         name="itemsObj.units{{i}}" > -->
                      <app-validation-errors [mformField]="units" label="Units">
                      </app-validation-errors>

                    </td>
                    <td>
                      <div *ngIf="is_debit_note">
                        <input type="number" [disabled]="itemsObj?.product?.type == 1" [min]="1" class="form-control"
                          #price="ngModel" required name="itemsObj.price{{i}}" placeholder="Price"
                          (change)="calculatePrice(itemsObj);" [(ngModel)]="itemsObj.price">
                        <app-validation-errors [mformField]="price" label="Price">
                        </app-validation-errors>
                      </div>
                    </td>

                    <td *ngIf="invoice.is_productwise_discount && invoice.discount_in_percentage">
                      <span>
                        {{itemsObj.discount_in_percentage}}
                      </span>
                    </td>

                    <td *ngIf="invoice.is_productwise_discount && invoice.discount_in_percentage==false">

                      <span>
                        {{itemsObj.discount_in_amount}}
                      </span>
                    </td>

                    <td *ngIf="is_composite_company==false">
                      {{itemsObj.tax_amount}}

                      <span *ngIf="itemsObj.tax!=undefined"
                        title="sgst:{{itemsObj.sgst}} cgst:{{itemsObj.cgst}} igst:{{itemsObj.igst}}">
                        ({{itemsObj.tax}}%)
                      </span>
                    </td>
                    <td>
                      {{(itemsObj.item_price + itemsObj.tax_amount)|number:'1.2'}}
                    </td>
                    <td>
                      <button type="button" class="btn btn-danger" (click)="deleteItem(i)">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </button>

                    </td>
                  </tr>

                </tbody>

                <tbody *ngFor="let itemsObj of invoice.invoice_items; let i = index">
                  <tr *ngIf="itemsObj.is_invoiced !=2 && itemsObj.is_invoiced !=3 && is_debit_note==false">
                    <td>
                      {{i+1}}
                    </td>
                    <td>
                      <ng-select *ngIf="!is_debit_note" [items]="product_lists" [typeahead]="productListTypeahead"
                        #product="ngModel" required name="itemsObj.product{{i}}" bindLabel="name"
                        [(ngModel)]="itemsObj.product"
                        (change)="getPriceFromHSN(itemsObj.product,itemsObj);getProductQty(itemsObj.product,itemsObj)"
                        placeholder="Select product">
                        <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index"
                          let-search="searchTerm">
                          <div [ngOptionHighlight]="search">
                            {{ item.name }}
                          </div>
                        </ng-template>
                      </ng-select>
                      <div *ngIf="is_debit_note">
                        {{itemsObj?.product?.name}}
                      </div>
                      <app-validation-errors [mformField]="product" label="Product">
                      </app-validation-errors>

                      <textarea type="text" *ngIf="!is_debit_note" class="form-control mt-1" name="description{{i}}"
                        placeholder="Description" [(ngModel)]="itemsObj.description"></textarea>
                      <div *ngIf="is_debit_note">
                        {{itemsObj.description}}
                      </div>
                    </td>
                    <!-- <td>
                      <input type="text" *ngIf="!is_debit_note" class="form-control" name="description{{i}}"
                        placeholder="Description" [(ngModel)]="itemsObj.description">
                      <div *ngIf="is_debit_note">
                        {{itemsObj.description}}
                      </div>
                    </td> -->
                    <td *ngIf="is_debit_note ">
                      <input [disabled]="itemsObj?.product?.type == 2" type="number" [min]="1"
                        [max]="itemsObj.max_units" class="form-control" #units="ngModel" required name="units{{ i }}"
                        id="units{{ i }}" placeholder="Units"
                        (change)="getProductQty(itemsObj.product,itemsObj);calculatePrice(itemsObj)"
                        [(ngModel)]="itemsObj.units">
                      <!-- <input  *ngIf= "product_type == 2" disabled type="text" class="form-control"
                         name="itemsObj.units{{i}}" > -->
                      <app-validation-errors [mformField]="units" label="Units">
                      </app-validation-errors>
                      <!-- <span *ngIf="is_debit_note">
                        {{itemsObj.units}}
                      </span> -->
                    </td>
                    <td *ngIf="!is_debit_note">
                      <input [disabled]="itemsObj?.product?.type == 2" type="number" [min]="1" class="form-control"
                        #units="ngModel" required name="units{{ i }}" id="units{{ i }}" placeholder="Units"
                        (change)="getProductQty(itemsObj.product,itemsObj);calculatePrice(itemsObj)"
                        [(ngModel)]="itemsObj.units">
                      <!-- <input  *ngIf= "product_type == 2" disabled type="text"  class="form-control"
                        name="itemsObj.units{{i}}" value="-"> -->
                      <app-validation-errors [mformField]="units" label="Units">
                      </app-validation-errors>
                      <!-- <span *ngIf="is_debit_note">
                        {{itemsObj.units}}
                      </span> -->
                    </td>
                    <td>
                      <div *ngIf="!is_debit_note">
                        <input type="number" [min]="1" class="form-control" #price="ngModel" required
                          name="itemsObj.price{{i}}" placeholder="Price" (change)="calculatePrice(itemsObj);"
                          [(ngModel)]="itemsObj.price">
                        <app-validation-errors [mformField]="price" label="Price">
                        </app-validation-errors>
                      </div>
                      <span *ngIf="is_debit_note">
                        {{itemsObj.price}}
                      </span>
                    </td>

                    <td *ngIf="invoice.is_productwise_discount && invoice.discount_in_percentage">
                      <input type="number" *ngIf="!is_debit_note" class="form-control"
                        name="itemsObj.discount_in_percentage{{i}}" placeholder="Discount in percentage"
                        (change)="calculatePrice(itemsObj);" [(ngModel)]="
                        itemsObj.discount_in_percentage" title="Discount in percentage">
                      <span *ngIf="is_debit_note">
                        {{itemsObj.discount_in_percentage}}
                      </span>
                    </td>

                    <td *ngIf="invoice.is_productwise_discount && invoice.discount_in_percentage==false">
                      <input type="number" class="form-control" *ngIf="!is_debit_note"
                        name="itemsObj.discount_in_amount{{i}}" placeholder="Discount in Amount"
                        (change)="calculatePrice(itemsObj);" [(ngModel)]="
                        itemsObj.discount_in_amount" title="Discount in Amount">
                      <span *ngIf="is_debit_note">
                        {{itemsObj.discount_in_amount}}
                      </span>
                    </td>

                    <td *ngIf="is_composite_company==false && invoice.is_without_gst==false">
                      {{itemsObj.tax_amount}}

                      <span *ngIf="itemsObj.tax!=undefined"
                        title="sgst:{{itemsObj.sgst}} cgst:{{itemsObj.cgst}} igst:{{itemsObj.igst}}">
                        ({{itemsObj.tax}}%)
                      </span>
                    </td>
                    <td>
                      {{(itemsObj.item_price + itemsObj.tax_amount)|number:'1.2'}}
                    </td>
                    <td>
                      <button type="button" class="btn btn-danger" (click)="deleteItem(i)">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </button>
                      <button type="button" *ngIf="!is_debit_note" class="btn btn-primary mr-2 ml-2"
                        (click)="addNewItem()"><i class="fa fa-plus"></i></button>
                    </td>
                  </tr>

                </tbody>
              </table>
              <!-- Balancing Items Table -->

              <div class="float-right" style="display: inline-block !important;" *ngIf=" invoice.is_discount==true && invoice.discount_in_percentage==true &&
                invoice.is_productwise_discount==false">
                <label for="overall_discount_in_percentage">Discount In Percentage:</label>
                <input type="number" [min]="1" [max]="100" [(ngModel)]="overall_discount_in_percentage"
                  (change)="calculateDisount()" class="form-control" name="overall_discount_in_percentage">
              </div>

              <div class="col-12">
                <table class="float-right col-8 table table-hover table-stripped table-bordered">
                  <thead class=" thead-light">
                    <tr>
                      <!-- <th>S.NO</b> </th> -->
                      <th style="width: 45%;"><b>Account Head</b> </th>
                      <th><b>Value</b> </th>
                      <!-- <th>
                        Percentage</b> </th> -->
                      <th style="width: 20%;"><b>Action</b> </th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let balancingObj of invoice.balancing_items let balancing_index = index">
                    <!-- <td>
                                    {{balancing_index+1}}
                                  </td> -->
                    <td *ngIf="!is_debit_note">
                      <ng-select [items]="account_head_lists" [typeahead]="accountHeadListTypeahead"
                        #account_head="ngModel" name="balancingObj.account_head{{balancing_index}}"
                        bindLabel="account_head" [(ngModel)]="balancingObj.account_head"
                        placeholder="Select Account Head" (change)="getTotalAmount()">
                        <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index"
                          let-search="searchTerm">
                          <div [ngOptionHighlight]="search">
                            {{ item?.flattened_data?.name }}
                          </div>
                        </ng-template>
                      </ng-select>
                      <app-validation-errors [mformField]="account_head" label="Account Head">
                      </app-validation-errors>
                    </td>
                    <td *ngIf="is_debit_note"> {{balancingObj.account_head?.flattened_data?.name}}</td>
                    <td *ngIf="balancingObj.account_head?.flattened_data?.name != 'Discount'">
                      <!-- [min]="1" number-->
                      <input type="number" *ngIf="!is_debit_note" name="amount{{balancing_index}}"
                        #balancing_amount="ngModel" class="form-control" [(ngModel)]="balancingObj.amount"
                        placeholder="Enter Amount" (change)="getTotalAmount();">
                      <app-validation-errors [mformField]="balancing_amount" label="Amount">
                      </app-validation-errors>
                      <span *ngIf="is_debit_note">{{balancingObj.amount}}</span>
                    </td>

                    <td
                      *ngIf="balancingObj.account_head?.flattened_data?.name == 'Discount' && invoice.discount_in_percentage!=true">
                      <input *ngIf="!is_debit_note" type="number" name="amount{{balancing_index}}"
                        #balancing_amount="ngModel" class="form-control" [(ngModel)]="balancingObj.amount"
                        placeholder="Enter Amount" (change)="getTotalAmount();balancingObj.percentage=0;">
                      <app-validation-errors [mformField]="balancing_amount" label="Amount">
                      </app-validation-errors>
                      <span *ngIf="is_debit_note">{{balancingObj.amount}}</span>
                    </td>

                    <td>
                      <button type="button" class="btn btn-danger" (click)="deleteBalancingItem(balancing_index)">
                        <i class="fa fa-trash"></i>
                      </button>
                      <button type="button" class="btn btn-primary ml-2" *ngIf="!is_debit_note"
                        (click)="addNewBalancingItem()">
                        <i class="fa fa-plus"></i>
                      </button>
                    </td>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- Totals Table -->
            <div class="row justify-content-between">
              <div class="form-group col-sm-4 col-md-4 col-xl-6">

                <div class="pb-4 form-group col-12 custom-switch col-sm-6 col-md-3 col-xl-4 pl-5"
                  *ngIf="!is_debit_note">
                  <input type="checkbox" value="true" [(ngModel)]="invoice.is_discount" name="is_discount"
                    class="custom-control-input" (change)="getCoaData();unSetProductWiseDiscount();" id="is_discount">
                  <label class="custom-control-label" for="is_discount">Discount ?</label>
                </div>
                <div class="pb-4 form-group col-12 custom-switch col-sm-6 col-md-3 col-xl-6 pl-5"
                  *ngIf="invoice.is_discount==true && !is_debit_note">
                  <input type="checkbox" value="true" [(ngModel)]="invoice.is_productwise_discount"
                    name="is_productwise_discount" (change)="unSetProductWiseDiscount();getCoaData();"
                    class="custom-control-input" id="is_productwise_discount">
                  <label class="custom-control-label" for="is_productwise_discount">Product-Wise Discount</label>
                </div>
                <div class="pb-4 form-group col-12 custom-switch col-sm-6 col-md-3 col-xl-2"
                  *ngIf="invoice.is_discount==true && !is_debit_note">
                  <input type="checkbox" value="true" [(ngModel)]="invoice.discount_in_percentage"
                    name="discount_in_percentage" (change)="unSetProductWiseDiscount();" class="custom-control-input"
                    id="discount_in_percentage">
                  <label class="custom-control-label" for="discount_in_percentage">Discount In %</label>
                </div>
                <div class="pb-3 form-group col-12 custom-switch pl-5" *ngIf="is_debit_note==false">
                  <input type="checkbox" (click)="changePos()" value="true" [(ngModel)]="is_pos" name="is_pos"
                    class="custom-control-input pl-5" id="is_pos">
                  <label class="custom-control-label" for="is_pos">POS</label>
                </div>


                <label for="credit_notes">Notes</label>
                <textarea class="form-control notes" name="notes" id="notes" placeholder="Notes" maxlength="250"
                  [(ngModel)]="invoice.notes"></textarea>
              </div>
              <table class="float-right table col-4">
                <tbody>
                  <tr>
                    <th>
                      <b>Actual Total </b>
                    </th>
                    <td class="money">
                      <b>{{invoice.actual_total_amount| currency: currency}}</b>
                    </td>
                  </tr>
                  <tr *ngIf="invoice.is_discount==true">
                    <th><b>Total Discount </b></th>
                    <td class="money"><b>{{invoice.discount| currency: currency}}</b></td>
                  </tr>
                  <tr *ngIf="is_composite_company==false">
                    <th><b>Sub Total </b></th>
                    <td class="money"><b>{{invoice.sub_total_amount| currency: currency}}</b></td>
                  </tr>

                  <tr *ngIf="is_same_state && is_composite_company==false">
                    <th><b> CGST </b></th>
                    <td class=" money"><b>{{invoice.cgst| currency: currency}}</b></td>
                  </tr>

                  <tr *ngIf="is_same_state && is_composite_company==false">
                    <th><b> SGST </b></th>
                    <td class="money"><b>{{invoice.sgst| currency: currency}}</b></td>
                  </tr>
                  <tr *ngIf="is_same_state==false && is_composite_company==false">
                    <th><b> IGST </b></th>
                    <td class="money"><b>{{invoice.igst| currency: currency}}</b></td>
                  </tr>
                  <tr *ngIf="is_composite_company==false">
                    <th> <b> Total Tax </b></th>
                    <td class="money"> <b>{{invoice.total_tax_amount| currency: currency}}</b></td>
                  </tr>
                  <tr *ngIf="invoice.round_of!=0 && invoice.round_of!=undefined">
                    <th><b>Rounding Off ({{invoice.round_of < 0 && invoice.round_of!=0 ?'-':'+'}}) </b>
                    </th>
                    <td class="money"> <b>{{invoice.round_of}}</b></td>
                  </tr>
                  <tr>
                    <th><b>Total </b></th>
                    <td class="money"><b>{{(invoice.total_amount) |currency: currency}}</b></td>
                  </tr>

                  <!-- <tr *ngIf="is_round_available">
                    <th><b>Total Rounded Value </b></th>
                    <td class="money"> <b>{{this.invoice.round_value}}</b></td>
                  </tr> -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <button *ngIf="is_edit_form==false && is_debit_note==false" type="submit" class="btn btn-primary"
          [disabled]="(!saveInvoice.valid || loading==true)">{{is_pos == true ? 'Save & Record Payment' : 'Save
          Invoice'}}
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
        </button>

        <button *ngIf="is_debit_note==true" type="submit" class="btn btn-primary"
          [disabled]="!saveInvoice.valid || loading==true">Save
          Credit-Note
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
        </button>
        <button *ngIf="is_edit_form==true" type="submit" class="btn btn-primary"
          [disabled]="!saveInvoice.valid || loading==true">Save
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
        </button>
      </form>
    </div>
  </div>
</div>
