import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import swal from "sweetalert2";
import { AuthenticationService } from '../../_services/authentication.service';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  userId: string;
  loading:boolean;
  passwordLabel:any;
  user_id: any;
  user_muid: any;
  user_password :string;
  user_new_password :string;
  user_confirm_password :string;
  user_name: any;
  

  constructor(    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private userservice: UserService,
    private router: Router



    ) { }

  ngOnInit() {
    this.user_muid = this.authenticationService.getUserId();
    this.user_name =   this.authenticationService.getUserName();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.userId = params.get("unique_id");
  });
  if(this.userId == this.user_muid){
    this.passwordLabel = "Change";
  }else{
    this.passwordLabel = "Reset";

  }
}
  changePassword(){
    let user_data={};
    user_data['new_password'] = this.user_new_password;
    user_data['confirm_password'] = this.user_confirm_password;
    user_data['user_muid'] = this.userId;

    user_data['auth_user_password'] = this.user_password;
    user_data['auth_user_name'] = this.user_name;
    user_data['auth_user_muid'] = this.user_muid;
    
    this.userservice.changePassword(user_data,this.userId).subscribe(result => {
        if(result['ok']==1){
          swal("Success", "Password Updated Successfully", "success");
        }else{
          swal("Error", "Change Password Encountered With Failure", "error");
        }
    });
    if(this.userId != this.user_muid){
    this.router.navigate(["/user/list"]);
    }else{
      this.router.navigate(["/bill/list"]);
    }
  }
}
