export class Country {
    name: string;
    iso_code_2: string;
    iso_code_3: string;
    _id: string;
}

export class State {
    zone_id: string;
    country_id: number
    name: string
    Gst_code: number
    code: string
    status: string
}