import { Component, OnInit, ChangeDetectorRef, Input, ChangeDetectionStrategy, OnDestroy, ViewChild } from '@angular/core';
import { ReportsService } from '../../_services/reports.service';
import { ApiService } from '../../_services/api.service';
import { Router, ActivatedRoute, ParamMap, ChildrenOutletContexts } from '@angular/router';
import { AuthenticationService } from '../../_services/authentication.service';
import { startOfMonth, endOfMonth } from "date-fns";
import { ConstantService } from "src/app/_config/constants";
import { filter, last } from 'rxjs/operators';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { toBase64String } from '@angular/compiler/src/output/source_map';
import { el } from 'date-fns/locale';
import { MastersService } from '../../_services/masters.service';
import { element } from 'protractor';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { async } from 'rxjs/internal/scheduler/async';
import { CompanyService } from '../../_services/company.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  @ViewChild('runningTotal', { static: true }) runningTotal;
  ledgerPadding = 0;
  tableList: any = { data: [], count: 0 };
  dynamicFilterData: any = {};
  sort = { tx_date: 1 };
  pagination: any = { offset: 0, pageSize: 25,currentPage:1 };
  columns: any = [];
  filters: any = [];
  defaultSort: any = { tx_date: 1 };
  id: string;
  tableColumns: any = [];
  groupColumns: any = [];
  apiURL: [string, string];
  postSummary: any;
  reportType: any = 1;
  openingBalance: any;
  closingBalance: any;
  customized_query: any;
  preSummary: any;
  model: any;
  defaultFilters: any;
  rowColumns: string[];
  summaryColumns: any;
  company_id: any;
  branch_id: any;
  currency: any;
  pressSearch: boolean = true;
  sideBySide: boolean = false;
  report_ids: string[];
  multiTable: any = [];
  total_credit: number;
  total_debit: number;
  sumMultiTable: any = [];
  reportDetails: any = [];
  amount: number;
  reportcolumns: any = [];
  reportcolumn: any = [];
  treeData: any = [];
  parentName: any;
  isShowTableHeading: number = 0;
  reportName: any;
  is_opening: boolean = false;
  is_transaction: boolean = false;
  reportHeader: any;
  grossprofit: any = [];
  profitTable = [];
  multiReportId: string;
  dataExists: boolean = false;
  finalTable: any[];
  seqArray: any = [];
  isLoading: any = true;
  order: number = 0;
  timeZone = "Asia/Calcutta";
  pdf_format: string = 'portrait';
  net_profit: number;
  currentFinancialYear: any;
  companyCategory: number;   // 2 Service
  constructor(private reportsservice: ReportsService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private apiservice: ApiService,
    private reportsService: ReportsService,
    private authenticationService: AuthenticationService,
    private mastersService: MastersService,
  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    const companyDetails = this.authenticationService.getCompanyDetails();
    this.currency = companyDetails[
      "base_currency"
    ];
    this.companyCategory = companyDetails.company_category;
    this.currentFinancialYear = companyDetails['fi_year'];
    // this.id = this.route.snapshot.paramMap.get('id');
    // this.getReportCustomizationById();

    this.route.paramMap.subscribe(params => {
      this.id = params.get("id");
      this.multiReportId = params.get("id");
      this.reset();
      this.getReportCustomizationById(this.id);
      this.is_opening = false;
      this.is_transaction = false;
      this.getCoa();
      // this.getFilterTypeaheadData('');
    });
    // this.getDisplayColumns();
    this.getLocaleTimeZone();
  }


  getType(data) {
    return typeof data;
  }

  pageChange($data: any) {
    this.isLoading=1;
    
    console.log(this.pagination);
    this.pagination = $data;
    this.pagination.currentPage=$data.action.currentPage;
    console.log('this.pagination');
    console.log(this.pagination);
    
    this.getReportData();
  }
  applySort(sorting: any) {
    this.sort = sorting;
    this.getReportData();
  }

  // getDisplayColumns() {
  //   this.columns = {};
  //   this.tableColumns.forEach((column) => {
  //     if (column.show !== undefined || column.show !== false) {
  //       this.columns[column.prop] = 1;
  //     }
  //   });
  // }

  search(filterData: any) {
    this.pressSearch = false;
    this.filters = filterData;
    this.dataExists = false;
    console.log("this.filters", this.filters);

    // Api call with filter condition and set the data to this.tableList
    if (this.sideBySide === true) {
      this.reportDetails = [];
      this.multiTable = [];
      this.finalTable = [];
      this.sumMultiTable = [];
      this.isLoading = this.report_ids.length;
      console.log("this.report_ids", this.report_ids);

      // this.report_ids.forEach(async id => {
      //   await this.getReportCustomizationById(id);
      // });
      this.orderlly(this.report_ids);

    } else {
      this.isLoading = 1;
      this.getReportData();
    }

  }

  async orderlly(id) {
    let data: any = {};
    data = await this.getReportCustomizationById(id[this.order]);
    if (data && this.order < id.length - 1) {
      this.order++
      this.orderlly(id);
    } else {
      this.order = 0;
    }
  }


  async getReportCustomizationById(id) {
    return await this.reportsService
      .getCustomizationDetails(id)
      .then(async (result) => {
        console.log("cid", id);

        this.id = id;
        this.tableColumns =
          result["response"]["data"]["columns"];
        this.tableColumns.forEach((column) => {

          if (column.dataType == 'date' && column.filter == true && column.filterDateRanges) {
            column.filterDateRanges.forEach((data) => {
              if (data.isDefault == true) {
                data.isDefault = true
              }
            });
            column.filterDateRanges.push({
              "label": "Current Financial Year",
              "start": new Date(this.currentFinancialYear.start_year, 3, 1),
              "end": new Date(this.currentFinancialYear.end_year, 2, 31),
              "isDefault": true
            })
          }
        })
        this.reportType =
          (result["response"]["data"]["report_type"] != undefined)
            ? result["response"]["data"]["report_type"]
            : 1;
        this.reportName =
          result["response"]["data"]["report_name"];
        if (this.reportType == 2) {
          this.groupColumns = result["response"]["data"]["group_columns"];
          this.rowColumns = result["response"]["data"]["row_columns"];
          this.summaryColumns = result["response"]["data"]["summary_columns"];
        };
        if (result["response"]["data"]["pdf_format"]) this.pdf_format = result["response"]["data"]["pdf_format"];
        console.log("this.pdf_format", this.pdf_format);

        if (this.reportType == "side_by_side") {
          this.sideBySide = true;
          this.report_ids = result["response"]["data"]["report_ids"];
          this.reportHeader = result["response"]["data"]["report_name"];
        }

        // this.defaultFilters = result["response"]["data"]["filters"];
        // this.filters = this.defaultFilters;
        if (result["response"]["data"]["preSummary"] != undefined) {
          this.preSummary = result["response"]["data"]["preSummary"];
        }
        if (result["response"]["data"]["model"] != undefined) {
          this.model = result["response"]["data"]["model"];
        }

        if (result["response"]["data"]["postSummary"] != undefined) {
          this.postSummary = result["response"]["data"]["postSummary"];
        }
        if (result["response"]["data"]["customized_query"] != undefined) {
          this.customized_query =
            result["response"]["data"]["customized_query"];
        }
        //set default sort
        this.sort = result["response"]["data"]["default_sort"] || this.defaultSort;
        this.apiURL = result["response"]["data"]["apiurl"];

        if (this.sideBySide == true) {
          this.reportDetails.push(this.reportName);
          result["response"]["data"]["columns"].forEach((doc, i) => {
            if (doc.show == true) {
              this.reportcolumn.push(doc.name);
            }
          });
          this.reportcolumns.push(this.reportcolumn);
          let data = await this.getReportData();
          return data;
        }
        // this.getReportData();
        // this.initiateFilterTypeaheadData();

      });
  }
  initiateFilterTypeaheadData() {
    this.tableColumns.forEach((filter) => {
      if (
        (filter.filterType == "typeSelect" ||
          filter.filterType == "typeMultiSelect") &&
        filter.filter == true
      )
        this.getFilterTypeaheadData({ filter: filter });
    });
  }

  getFilterTypeaheadData(data: any = {}) {

    // let replaceParams = {};
    // replaceParams[
    //   "~m~company_id"
    // ] = this.authenticationService.getDefaultCompanyId();
    // replaceParams[
    //   "~m~group_id"
    // ] = this.authenticationService.getDefaultCompanyId();
    // let currentFilter = data.filter;
    // let filterCondition: any = {};
    // if (currentFilter.filterAPI != undefined) {
    //   Object.keys(currentFilter.filterAPI.params).forEach((paramKey, key) => {
    //     if (currentFilter.filterAPI.params[paramKey].startsWith("~m~")) {
    //       console.log("paramKey=>>", currentFilter.filterAPI.params[paramKey]);
    //       filterCondition[paramKey] =
    //         replaceParams[currentFilter.filterAPI.params[paramKey]];
    //     }
    //     if (paramKey.startsWith("~m~")) {
    //       filterCondition[currentFilter.filterAPI.params[paramKey]] =
    //         data.searchString;
    //     }
    //   });
    // }
    // this.reportsService
    //   .getFilterTypeaheadData(currentFilter.filterAPI, filterCondition)
    //   .subscribe((result) => {
    //     this.dynamicFilterData = {};
    //     this.dynamicFilterData["data"] = result["response"];
    //     this.dynamicFilterData["prop"] = currentFilter.prop;
    //   });

    const filterDetails = this.tableColumns.filter((value) => {
      return value.prop === data.filter;
    })[0];

    // console.log(filterDetails.filterApi);
    // console.log('filterDetails.filters', filterDetails.filters[0]);
    let apiDetails = filterDetails.filterApi;
    apiDetails.filters = JSON.parse(JSON.stringify(apiDetails.filters).replace('~~m~company_id', this.company_id));
    if (apiDetails.type == 'post') {

      this.apiservice.post(ApiService.geturl(apiDetails.url[0], apiDetails.url[1]), apiDetails.filters).
        subscribe((response) => {

          filterDetails.filterData = response['response'] ? response['response'] : response;
          this.dynamicFilterData = { column: data.filter, data: filterDetails.filterData };
        });
    }
    if (apiDetails.type == 'get') {

      this.apiservice.get(ApiService.geturl(apiDetails.url[0], apiDetails.url[1]), apiDetails.filters).
        subscribe((response) => {
          filterDetails.filterData = response['response'];
          this.dynamicFilterData = { column: data.filter, data: filterDetails.filterData };
        });
    }
  }

  async getReportData(exportType = '') {
    // let replaceParams = {};
    // replaceParams[
    //   "~m~company_id"
    // ] = this.authenticationService.getDefaultCompanyId();

    // this.filters.forEach((filter, key) => {
    //   if (typeof filter.value == "string") {
    //     if (filter.value.startsWith("~m~")) {
    //       this.filters[key]["value"] = replaceParams[filter.value];
    //     }
    //   }
    //   if (filter.type != "daterange") {
    //     if (Array.isArray(filter.value) && filter.type == "between") {
    //       //Condition only for dates
    //       this.filters[key]["value"][0] = this.stringToDate(filter.value[0]);
    //       this.filters[key]["value"][1] = this.stringToDate(filter.value[1]);
    //     }
    //   } else {
    //     for (var i in this.filters) {
    //       if (this.filters[i]["type"] == "between") {
    //         this.filters.splice(i, 1);
    //         break;
    //       }
    //     }
    //   }
    // });



    if (this.apiURL)
      return this.reportsService
        .getData(
          this.id,
          this.apiURL,
          this.filters,
          this.customized_query,
          this.tableColumns,
          this.preSummary,
          this.postSummary,
          this.model,
          this.sort,
          this.pagination,
          exportType,
          this.timeZone
        )
        .then(async (result) => {
          
          this.isLoading = this.isLoading - 1;
          if (this.isLoading == 0) {
            this.isLoading = false;
          }
          if (result['count'] > 0) {
            this.dataExists = true;
          }
          if (this.sideBySide == true) {
            let display_names = ['total'];
            for (let i = 0; i < result['data'].length; i++) {
              result['data'][i]['parent_name'].splice([result['data'][i]['parent_name'].length - 1], 1);
              result['data'][i]['parent_id'].splice([result['data'][i]['parent_id'].length - 1], 1);
            }

            // console.log("result['data']", JSON.stringify(result['data']));
            console.log("reportName", this.reportName);
            if (this.reportName == 'Assets' && this.companyCategory != 2) {
              let stockData: any = await this.getStockOpenCloseValue('5f647601f5dbc70cffe15768', this.filters);
              stockData = stockData.data ? stockData.data : [{ price: 0 }, { price: 0 }];

              let Closing_Stock = {
                "_id": {
                  "name": "Closing Stock",
                  "parent_name": [
                    "Current Assets",
                    "Assets"
                  ],
                  "parent_id": [
                    1017,
                    1000
                  ],
                  "party_id": 1
                },
                "name": "Closing Stock",
                "parent_name": [
                  "Current Assets"
                ],
                "parent_id": [
                  1017
                ],
                "total": stockData[1]['price']
              }
              result['data'].push(Closing_Stock);
            }
            let profit_loss = {
              "_id": {
                "name": "Profit & Loss",
                "parent_name": [
                  "Profit & Loss"
                ],
                "parent_id": [
                  9999,
                ],
                "party_id": 1
              },
              seq_no: 8998,
              "name": "Profit & Loss",
              "parent_name": [
                "Profit & Loss"
              ],
              "parent_id": [
                9999
              ],
              "total": this.net_profit
            }
            console.log(this.net_profit, this.reportName);

            if (this.net_profit > 0 && this.reportName == 'Assets') {
              result['data'].push(profit_loss);
            } else if (this.reportName == 'Liability' && this.net_profit < 0) {
              result['data'].push(profit_loss);
            }


            this.getTreeFormat(result['data'], display_names);
            this.multiTable.push(this.treeData);

            this.treeData = [];
            // this.multiTable.push(result['data']);
            // this.total_credit = 0;
            // this.total_debit = 0;
            // result["data"].forEach(doc => {
            //   this.total_credit = this.total_credit + doc.total_credit;
            //   this.total_debit = this.total_debit + doc.total_debit;
            // });
            let is_balance = this.reportDetails.filter(name => name == 'Liability');
            let is_p$l = this.reportDetails.filter(name => name == 'Expense');
            if (is_balance.length == 1) {
              if (this.multiTable.length == 6) {
                this.reportDetails = ['Liability', 'Assets'];
                this.multiTable = this.multiTable.slice(4);
                this.checkTotalEqual(this.multiTable);

              }
            };

            if (is_p$l.length == 1) {
              if (this.multiTable.length == 4) {
                this.calculateNetprofit();
              }
            }

            // this.sumMultiTable.push([{ credit: this.total_credit, debit: this.total_debit }]);

            // if (this.sumMultiTable.length > 1) {

            //   this.amount = this.sumMultiTable[0][0]['credit'] - this.sumMultiTable[1][0]['debit'];
            //   console.log('this.sumMultiTable===>', this.sumMultiTable);


            // }

            // ["Assets"["CA": ["TR": [{ name: "IEC FEB" }]]]]
          } else if (this.reportType == "tree") {
            // this.getCoa();
            // let treeresult = [];
            let display_name: any = ['ob_debit', 'ob_credit', 'total_debit', 'total_credit', 'cb_debit', 'cb_credit'];
            // let stockData: any = await this.getStockOpenCloseValue('5f647601f5dbc70cffe15768', this.filters);
            // stockData = stockData.data ? stockData.data : [{ price: 0 }, { price: 0 }];
            // let stock: any = {
            //   "_id": {
            //     "name": "Opening Stock",
            //     "parent_name": [
            //       "Assets",
            //     ],
            //     "parent_id": [
            //       1000
            //     ],
            //     "party_id": 2031
            //   },
            //   "ob_credit": 0,
            //   "ob_debit": stockData[0].price,
            //   "name": "Opening Stock",
            //   "parent_name": [
            //     "Assets"
            //   ],
            //   "parent_id": [
            //     1000
            //   ],
            //   "type": 2
            // }


            // result['ob'].push(stock);
            // stock.cb_debit = stockData[1].price;
            // stock.cb_credit = 0;
            // result['cb'].push(stock);
            result['ob'].forEach(doc => {
              this.createTree(doc, 'name', 'parent_name', 'parent_id', display_name, true);
            });
            result['data'].forEach(doc => {
              this.createTree(doc, 'name', 'parent_name', 'parent_id', display_name, true);
            });
            result['cb'].forEach(doc => {
              this.createTree(doc, 'name', 'parent_name', 'parent_id', display_name, true);
            });

            // this.addSequence(this.treeData);
            // this.changeOrder();
            this.treeData = this.getTotal(this.treeData, display_name);
            this.checkTotalEqualNew();
            this.treeData = { name: '', childrens: this.treeData };
            console.log("treeData", this.treeData);
          }
          if (exportType == '') {
            this.tableList = { data: [] };
            this.tableList = result;

            // if (result["opening_balance"].length == 1) {
            //   result["opening_balance"].forEach(data => {
            //     this.tableList = result['data'].unshift({ credit: data.credit, debit: data.debit })
            //   })
            // };
            // if (result["closing_balance"].length == 1) {
            //   result["closing_balance"].forEach(data => {
            //     this.tableList = result['data'].push({ credit: data.credit, debit: data.debit })
            //   })
            // };


            // if (result["opening_balance"] != undefined) {
            //   this.openingBalance = result["opening_balance"][0];
            //   this.closingBalance = result["closing_balance"][0];
            // }
          } else if (exportType == 'pdf' || exportType == 'excel') {
            this.isLoading = false;
            window.open(ConstantService.IMG_BASE_URL + result['filename']);

          }
          return result;
        });
  }

  generatePdf() {
    if (this.sideBySide === true || this.reportType == 'tree') {
      this.generatePdfFromHtml();
    } else {
      this.getReportData('pdf');
    }
  }
  generatePdfFromHtml() {
    // console.log('html', document.getElementById('sideBySideReport'));
    let templateRef = this.sideBySide == true ? 'sideBySideReport' : 'tree';
    this.reportsService.generatePdfFromHtml(this.company_id, document.getElementById(templateRef).innerHTML, this.pdf_format).subscribe(result => {
      window.open(ConstantService.IMG_BASE_URL + result['filename']);
    });
  }

  createTree(document, node_name, node_parent_names, node_parent_ids, display_name, is_parent_reverse = false) {
    let amountName = [];
    Object.keys(document).forEach(key => {
      if (isNaN(document[key]) == false) {
        amountName.push(key)
      }
    });
    // let display =display_name.filter(displayName=> amountName.includes(displayName)==false);

    if (is_parent_reverse) {
      document[node_parent_names].reverse();
      document[node_parent_ids].reverse();
    }
    let currentTempTree = this.treeData;

    let i = 0;
    document[node_parent_names].forEach((parent_name, index) => {
      i++
      let parent_id = document[node_parent_ids][index];
      let seq = this.seqArray.filter(data => data.id == parent_id);
      let keyIndex = this.isArrayObjectHasKey(currentTempTree, parent_name, 'name');

      if (keyIndex == -1) {
        keyIndex = currentTempTree.length;
        let rowData: any = { display_name: [] };
        rowData.name = parent_name;
        rowData.id = parent_id;
        rowData.seq_no = seq[0] ? seq[0]['seq_no'] : 0;
        rowData.childrens = [];
        display_name.forEach(name => {
          rowData[name] = 0;
        })
        rowData.node = false;
        rowData.padding = index * 30;
        currentTempTree.push(rowData);
      }
      if (index == i - 1) {
        amountName.forEach(name => {
          currentTempTree[keyIndex][name] += document[name];
        })
        // currentTempTree[keyIndex][display_name[0]] +=document['debit'];
        // currentTempTree[keyIndex][display_name[1]] +=document['credit'];
      }
      if (index == document[node_parent_names].length - 1) {
        let rowData: any = { display_name: [] };
        amountName.forEach(name => {
          rowData[name] = document[name];
        })
        let ledgerSeq = this.seqArray.filter(data => data.id == document['_id']['party_id']);
        rowData['id'] = document['_id']['party_id'];
        rowData['seq_no'] = ledgerSeq.length ? ledgerSeq[0]['seq_no'] : document['_id']['party_id'];
        // rowData[display_name[0]] = document['debit'];
        // rowData[display_name[1]] = document['credit'];
        rowData.padding = (index + 1) * 30;
        currentTempTree[keyIndex]['node'] = true;
        let childIndex = this.isArrayObjectHasKey(currentTempTree[keyIndex]['childrens'], document[node_name], 'name');
        if (childIndex == -1) {
          rowData.name = document[node_name];
          if(typeof currentTempTree[keyIndex]['childrens']=='undefined'){
            currentTempTree[keyIndex]['childrens']=[];
          }
          currentTempTree[keyIndex]['childrens'].push(rowData);
        } else {
          Object.assign(currentTempTree[keyIndex]['childrens'][childIndex], rowData);
        };
      }

      currentTempTree = currentTempTree[keyIndex].childrens;

    });
    // console.log(currentTempTree);

  }
  getObjectKeys(dataObject) {
    return Object.keys(dataObject);
  }
  isArrayObjectHasKey(dataArray, value, key): number {
    // console.log("dataArray", JSON.stringify(dataArray));
    if (!Array.isArray(dataArray)) {
      dataArray = [];
    }
    let result = -1;
    dataArray.forEach((object, index) => {
      if (object[key] == value) {
        result = index;
      }
    });
    return result;
  }
  stringToDate(dateString) {
    switch (dateString) {
      case "startOfMonth":
        dateString = startOfMonth(new Date());
        break;
      case "endOfMonth":
        dateString = endOfMonth(new Date());
        break;
    }
    return dateString;
  }

  inlineTemplateColumns(columns: any) {
    columns.forEach((element, index) => {
      if (element["dataType"] == "template") {
        // const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.getComponentForCardType(this.data.component));
        // const viewContainerRef = this.container;
        columns[index]["template"] = element["template"].replace('"', "`");
      }
    });
    return columns;
  }

  //   callAPI(data) {

  //     console.log(data.filter, data.searchString.term);
  //     const filterDetails = this.tableColumns.filter((value) => {
  //       return value.prop === data.filter;
  //     })[0];
  //     console.log(filterDetails.filterApi);

  //     this.http.get(filterDetails.filterApi.replace('$data',
  // data.searchString.term)).subscribe((response) => {
  //       filterDetails.filterData = response['drinks'];
  //       console.log(response);
  //       this.dynamicFilterData = { column: data.filter, data: response['drinks'] };
  //     });
  //   }

  reset() {
    this.tableColumns = [];
    this.tableList = { data: [] };
    this.pressSearch = true;
    this.multiTable = [];
    this.finalTable = [];
    this.sideBySide = false;
    this.treeData = [];
    this.profitTable = [];
    this.reportDetails = [];
    this.reportHeader = '';
    this.grossprofit = [];
    this.seqArray = [];
  }

  // ngOnDestroy(): void {
  //   //Called once, before the instance is destroyed.
  //   //Add 'implements OnDestroy' to the class.
  //   this.tableColumns = [];
  //   this.tableList = {};
  //   this.pressSearch = true;
  // }
  getTreeFormat(data, display_names) {
    data.forEach(doc => {
      this.createTree(doc, 'name', 'parent_name', 'parent_id', display_names, true);
    });
    this.treeData = this.getTotal(this.treeData, display_names);
    return this.treeData = { name: '', childrens: this.treeData };
  }


  getTotal(data, display_names) {
    let rowData: any = { display_names: [] };
    rowData.name = 'Grand Total';
    rowData.grand_total = true;
    rowData.seq_no = 10000;
    display_names.forEach(name => {
      rowData[name] = 0;
    })
    data.forEach(amount => {
      display_names.forEach(name => {
        rowData[name] += amount[name];
      })
    });
    this.grossprofit.push(rowData);
    if (this.reportHeader == "Profit & Loss") {
      return data;
    } else {
      return this.treeData = data.concat(rowData);

    }


  }


  async calculateNetprofit() {
    let stockData: any = await this.getStockOpenCloseValue('5f647601f5dbc70cffe15768', this.filters);
    stockData = stockData.data ? stockData.data : [{ price: 0 }, { price: 0 }];
    this.profitTable = [];

    let profit = (this.companyCategory != 2 ? (this.grossprofit[1]['total'] + stockData[1]['price']) : this.grossprofit[1]['total']) - this.grossprofit[0]['total'];
    if (this.companyCategory != 2) {
      this.multiTable[0]['childrens'].push({ name: 'Opening Stock', seq_no: 0, total: stockData[0]['price'], grand_total: true });
      this.multiTable[1]['childrens'].push({ name: 'Closing Stock', seq_no: 8999, total: stockData[1]['price'], grand_total: true });
    }
console.log('this.multiTable');
console.log(this.multiTable);
let subTotal:any={"left":0,"right":0};
this.multiTable[0]['childrens'].forEach((total)=>{
subTotal["right"]+=total.total;
});
this.multiTable[1]['childrens'].forEach((total)=>{
subTotal["left"]+=total.total;
});

    this.multiTable[1]['childrens'].push({ name: 'Gross Profit b/f', seq_no: 9001, total: profit, grand_total: true });
	this.multiTable[1]['childrens'].push({ name: 'Sub Total', seq_no: 9000, total: subTotal["left"], grand_total: true });
	
    this.multiTable[0]['childrens'].push({ name: 'Gross Profit c/o', seq_no: 9000, total: profit, grand_total: true });
	this.multiTable[0]['childrens'].push({ name: 'Sub Total', seq_no: 9001, total: (subTotal["right"]+profit), grand_total: true });
	console.log("TEST",this.grossprofit,profit);
    let total = this.grossprofit[3]['total'] + profit;
    this.net_profit = total - this.grossprofit[2]['total'];
    let net_profit_ptve = this.net_profit > 0 ? this.net_profit : 0;
    let net_profit_ntve = this.net_profit < 0 ? (this.net_profit*-1) : 0;

    let profit1 = []
    profit1.push({ name: "Net Profit", amount: net_profit_ptve });
    profit1.push({ name: "Total", amount: (total+net_profit_ntve+net_profit_ptve) });
    let profit2 = []
    profit2.push({ name: "Net Loss", amount: (net_profit_ntve) });
    profit2.push({ name: "Total", amount: (total+net_profit_ntve+net_profit_ptve)});
    this.profitTable.push({ profit: profit1 });
    this.profitTable.push({ profit: profit2 });

    // concat reports
    let data = [];
    if (this.multiTable[2]['childrens'].length > 0) {
      this.multiTable[2]['childrens'][0]['seq_no'] = 10000;
    }
    if (this.multiTable[3]['childrens'].length > 0) {
      this.multiTable[3]['childrens'][0]['seq_no'] = 10000;
    }
    let side1 = this.multiTable[0]['childrens'].concat(this.multiTable[2]['childrens']);
    let side2 = this.multiTable[1]['childrens'].concat(this.multiTable[3]['childrens']);
    data.push({ name: '', childrens: side1 });
    data.push({ name: '', childrens: side2 });
    this.finalTable = [];
    this.finalTable = data;
    this.clearExistdata();
  }

  async getStockOpenCloseValue(id: string, filters = {}) {
    return await this.reportsService.getOpenClosing(id, filters);
  }

  async checkTotalEqual(data) {
    // let stockData: any = await this.getStockOpenCloseValue('5f647601f5dbc70cffe15768', this.filters);
    // stockData = stockData.data ? stockData.data : [{ price: 0 }, { price: 0 }];
    // this.multiTable[1]['childrens'][0]['childrens'].push({ name: "Closing Stock", node: true, padding: 30, seq_no: 1, total: stockData[1]['price'] });
    // console.log("this.multiTable[1]['childrens']", JSON.stringify(this.multiTable[1]['childrens']));

    // this.multiTable[1]['childrens'][0]['total'] = this.multiTable[1]['childrens'][0]['total'] + stockData[1]['price'];
    let is_equal = this.grossprofit[0]['total'] - this.grossprofit[1]['total'] > 0 ? this.grossprofit[0]['total'] - this.grossprofit[1]['total'] : this.grossprofit[1]['total'] - this.grossprofit[0]['total'];
    if (is_equal != 0) {
      let lastIndex = this.multiTable[0]['childrens'].length - 1;
      let lastIndex1 = this.multiTable[1]['childrens'].length - 1;

      if (this.grossprofit[0]['total'] > this.grossprofit[1]['total']) {
        this.multiTable[1]['childrens'][lastIndex1]['total'] = this.grossprofit[1]['total'] + is_equal;
        this.multiTable[1]['childrens'].splice(lastIndex1, 0, { name: 'Profit & Loss', seq_no: 9000, total: is_equal, padding: 0 });
      } else {
        this.multiTable[0]['childrens'][lastIndex]['total'] = this.grossprofit[0]['total'] + is_equal;
        this.multiTable[0]['childrens'].splice(lastIndex, 0, { name: 'Profit & Loss', seq_no: 9000, total: is_equal, padding: 0 });
      }
    }
    this.finalTable = [];
    this.finalTable = this.multiTable;
    // this.checkDataExists();
    this.clearExistdata();

  }

  clearExistdata() {
    this.grossprofit = [];
    this.report_ids = [];
    this.getReportCustomizationById(this.multiReportId);
  }

  checkTotalEqualNew() {
    let lastIndex = this.treeData.length - 1;
    let op_equal = this.treeData[lastIndex]['ob_credit'] - this.treeData[lastIndex]['ob_debit'] >= 0 ? this.treeData[lastIndex]['ob_credit'] - this.treeData[lastIndex]['ob_debit'] : this.treeData[lastIndex]['ob_debit'] - this.treeData[lastIndex]['ob_credit']
    let cb_equal = this.treeData[lastIndex]['cb_credit'] - this.treeData[lastIndex]['cb_debit'] >= 0 ? this.treeData[lastIndex]['cb_credit'] - this.treeData[lastIndex]['cb_debit'] : this.treeData[lastIndex]['cb_debit'] - this.treeData[lastIndex]['cb_credit']
    let trans_equal = this.treeData[lastIndex]['total_credit'] - this.treeData[lastIndex]['total_debit'] >= 0 ? this.treeData[lastIndex]['total_credit'] - this.treeData[lastIndex]['total_debit'] : this.treeData[lastIndex]['total_debit'] - this.treeData[lastIndex]['total_credit']


    let difference = {};
    if ((op_equal || cb_equal || trans_equal) > 0) {
      difference['name'] = "Difference in opening balances";
      difference['seq_no'] = 9000;

      if (this.treeData[lastIndex]['ob_credit'] > this.treeData[lastIndex]['ob_debit']) {
        difference['ob_debit'] = op_equal ;
        this.treeData[lastIndex]['ob_debit'] = this.treeData[lastIndex]['ob_debit'] + op_equal;
      } else {
        difference['ob_credit'] = op_equal ;
        this.treeData[lastIndex]['ob_credit'] = this.treeData[lastIndex]['ob_credit'] + op_equal;
      }
      //Closing Balance difference
      if (this.treeData[lastIndex]['cb_credit'] > this.treeData[lastIndex]['cb_debit']) {
        difference['cb_debit'] = cb_equal ;
        this.treeData[lastIndex]['cb_debit'] = this.treeData[lastIndex]['cb_debit'] + cb_equal;
      } else {
        difference['cb_credit'] = cb_equal ;
        this.treeData[lastIndex]['cb_credit'] = this.treeData[lastIndex]['cb_credit'] + cb_equal;
      }

      if (this.treeData[lastIndex]['total_credit'] > this.treeData[lastIndex]['total_debit']) {
        difference['total_debit'] = trans_equal ;
        this.treeData[lastIndex]['total_debit'] = this.treeData[lastIndex]['total_debit'] + trans_equal;
      } else {
        difference['total_credit'] = trans_equal;
        this.treeData[lastIndex]['total_credit'] = this.treeData[lastIndex]['total_credit'] + trans_equal;
      }
    }
    this.treeData.splice(lastIndex, 0, difference);


  }

  // changeOrder(){
  //   this.treeData[0]['id']=1000;
  //   this.treeData[1]['id']=2000;
  // }
  getCoa() {
    this.mastersService.getCoa(this.company_id).subscribe((result) => {
      if (result['response']['chart_of_accounts'].length > 0) {
        this.findSequence(result['response']['chart_of_accounts'])
      }

    })
  }

  findSequence(chart_of_accounts) {
    let sort = this.sortCOA(chart_of_accounts);
    sort.forEach((element, i) => {
      element.seq_no = i + 1;
      this.seqArray.push({ id: element.id, seq_no: element.seq_no });
      if (element.child) {
        if (element.child.length > 0) {
          this.findSequence(element.child);
        }

      }
    });



  };

  sortCOA(coaNodes) {
    function sortingLogic(coaNode1, coaNode2) {
      if (coaNode1.is_ledger == false && coaNode2.is_ledger == true) return 1;
      if (coaNode1.is_ledger == true && coaNode2.is_ledger == false) return -1;

      return 0;
    }

    return coaNodes.sort(sortingLogic);
  }

  // addSequence(treeData){
  //   treeData.forEach(element => {
  //   let seq = this.seqArray.filter(data => data.id == element.id);
  //   if (seq.length>0) {
  //     element.seq_no = seq[0]['seq_no']
  //   }
  //   if(element.childrens){
  //     if (element.childrens.length>0) {
  //       this.addSequence(element.childrens);
  //     }
  //   }
  // });
  // }
  getLocaleTimeZone() {
    let options: any = { calendar: 'chinese', numberingSystem: 'arab' };
    var dateFormat = new Intl.DateTimeFormat('default', options);
    var usedOptions = dateFormat.resolvedOptions();
    this.timeZone = usedOptions.timeZone;
    console.log("timezone", usedOptions.timeZone);


  }

}
