
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root', })
export class TestcollectionService {

    constructor(private http: ApiService) { }

    addTestcollection(testcollection_data: any) {
        return this.http.post(ApiService.geturl('testcollection', 'add'),
            { data: testcollection_data });
    }
    editTestcollection(testcollection_data: any, id: string) {

        return this.http.post(ApiService.geturl('testcollection', 'edit'),
            { data: testcollection_data, testcollection_id: id });
    }
    deleteTestcollection(id: string) {
        return this.http.post(ApiService.geturl('testcollection', 'delete'),
            { id: id });
    }

    viewTestcollection(id: string) {
        return this.http.post(ApiService.geturl('testcollection', 'view'),
            { testcollection_id: id });
    }

    getTestcollections(filters: any = {}, sort_by: any = {}, columns: any = {}, page: any = {}) {
        return this.http.post(ApiService.geturl('testcollection', 'viewall'),
            { filters: filters, sort_by: sort_by, columns: columns, page: page });
    }

    uploadFiles(files, data) {
        return this.http.uploadFile(ApiService.geturl('testcollection', 'upload'), files, data);

    }
}