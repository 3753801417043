export class Costgroup {
    company_id: string;
    under_cc_category?: UnderCostCenterCategory;
    parent_id?: string;
    costcategory_name?: string;
    cost_center?: CostCenter[];

}
export class UnderCostCenterCategory {
    costcategory_name: string;
    _id: string;
}
export class CostCenter {
    costcenter_name?: string;
}
