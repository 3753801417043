import { Component, OnInit, ViewChild } from '@angular/core';
import { VendorService } from '../../_services/vendor.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';
import { MastersService } from '../../_services/masters.service';
import { ConstantService } from "src/app/_config/constants";

@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.scss']
})
export class VendorsComponent implements OnInit {
  filters: any = {};
  page: any = { offset: 0, pageSize: 10 };
  sort: any = { "vendor_name": 1 };
  dt_default_sort = [{ prop: 'vendor_name', dir: 'asc' }];
  limit = 10;
  count: any;
  vendors: any = [];
  columns: any = {};
  loadingIndicator = true;
  reorderable = true;
  vendorTableListColumns: any = [];
  // @ViewChild('vendorTable', { static: true }) table: any;
  company_id: string;
  branch_id: string;
  modalReference: any;
  file: any = {};
  files: any=[];
  liability_data: any;
  accounts_payable: any;
  flattened_data: any;
  assets_data: any;
  chart_of_accounts: any = {};
  constructor(
    private vendorService: VendorService,
    private authenticationService: AuthenticationService,
    public route: ActivatedRoute,
    private ngbModal: NgbModal,
    public router:Router,
    private mastersService: MastersService
  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.vendorTableListColumns = [
      {
        prop: 'vendor_name',
        name: 'Vendor Name',
        sortable: 'vendor_name',
        flexGrow: 2,
        show: true,
        is_detailed_row: false
      },
      {
        prop: 'gst_number',
        name: 'GST Number',
        sortable: 'gst_number',
        flexGrow: 2,
        show: true,
        is_detailed_row: false
      },
      // {
      //   prop: 'mobile',
      //   name: 'Mobile',
      //   sortable: 'mobile',
      //   flexGrow: 2,
      //   show: true,
      //   is_detailed_row: false
      // },
      {
        prop: 'email',
        name: 'Email',
        sortable: 'email',
        flexGrow: 2,
        show: true,
        is_detailed_row: false
      },
      {
        prop: 'payables',
        name: 'Payables',
        sortable: 'payables',
        flexGrow: 2,
        show: true,
        is_detailed_row: false
      },
      {
        name: 'Action',
        type: 'action',
        prop: '_id',
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false
      }
    ];
    this.vendorTableListColumns.forEach((column) => {
      this.columns[column.prop] = 1;
    });
    if (this.route.snapshot.queryParams['q'] != 'undefined')
      this.filters['vendor_name'] = this.route.snapshot.queryParams['q'];
    this.getPaginatedVendors(this.page);
    this.getCoa()
  }

  onSort(event) {
    this.loadingIndicator = true;
    // console.log('event', event);
    this.sort = {};
    let sort_details = event.sorts[0];
    this.sort[sort_details.prop] = sort_details.dir == 'asc' ? 1 : -1;
    this.getPaginatedVendors(this.page);
  }

  clearVendorFilters() {
    this.filters = {};
    this.getPaginatedVendors({ offset: 0, pageSize: 10 });
  }


  getPaginatedVendors(pageInfo: any = {}) {
    if (pageInfo !== null) {
      this.page = pageInfo;
    }
    this.filters['company_id'] = this.company_id;
    this.sort['vendor_name'] = 1;
    this.vendorService.getVendors(this.filters, this.columns, this.sort, this.page).subscribe((result => {
      this.loadingIndicator = false;
      this.vendors = result['response']['data'];
      this.count = result['response']['count'];

    }));
  }
  open(content) {
    this.modalReference = this.ngbModal.open(content, { size: "lg" });
  }
  getDate() {
    return new Date()
  };
  uploadFile() {
    this.files=[];
    this.files.push(this.file);
    if (this.files.length > 0) {
      let data: any = {
        company_id: this.company_id,
        branch_id: this.branch_id,
      };

      this.vendorService.uploadFiles(this.files, data).subscribe((event: any) => {
        if (event['total'] >0) {
          if (event.ids.length>0) {
            event.ids.forEach((id, i)=> {
              this.updateCoa(event['vendor_name'][i], id);
            });
          }
          this.modalReference.close("importvendor");
          let error_string= '<p>'+event['success']+'/'+ event['total'] + ' Vendor(s) Added Successfully ('+event['failure'] + ' failed)</p>';
          Object.keys(event['errors']).forEach(number=>{
            error_string+='<table width="100%" style="text-align:left;font-size:14px"><tr><td>Vendor_'+number+'</td><td>'+event['errors'][number]+'</td></tr></table>'
          })
          swal("Success", error_string, "success");
          this.router.navigate(["/party/list"]);
          this.files = [];
          this.file = {};
        }
      },
        error => swal('Error Uploading Files: ', error.message));
    } else {
      swal("Error", "No File Found To Upload", "error");
    }

  }

  getCoa() {
    this.mastersService.getCoa(this.company_id).subscribe((result) => {
      this.chart_of_accounts = result["response"]["chart_of_accounts"];
      this.flattened_data = result["response"]["flattened_data"];
      this.chart_of_accounts.forEach((element) => {
        /**id-2 => Liabilities */
        if (element.id == ConstantService.COA.LIABILITIES) {
          element["child"].forEach((value) => {
            if (value.id == ConstantService.COA.CURRENT_LIABILITIES) {
              value["child"].forEach((value2) => {
                /**id-2011 => Trade Payable */
                if (value2.id == 2011) {
                  this.accounts_payable = value2;
                  console.log(
                    "this.accounts_payable====>",
                    this.accounts_payable
                  );
                }
              });
            }
          });
          this.liability_data = element;
        }
        /**id-1 => Assets */
        if (element.id == ConstantService.COA.ASSETS) {
          // element['child'].forEach(value => {
          //   /**id-11 => Credit Notes */
          //   if (value.id == 1011) {
          //     this.credit_notes = value;
          //   }
          // });
          this.assets_data = element;
        }
      });
      // console.log('credit_notes', this.credit_notes)
    });
  }

  updateCoa(vendor_name, vendor_id) {
    if (this.liability_data["last_sequence"] + 1 < 3000) {
      let condition: any = {};
      condition["company_id"] = this.company_id;
      let account_payable_data: any = {};
      let credit_note_data: any = {};

      account_payable_data["name"] = vendor_name;
      account_payable_data["ref_id"] = vendor_id;
      account_payable_data["id"] = this.liability_data["last_sequence"] + 1;
      this.liability_data["last_sequence"] = account_payable_data["id"];
      account_payable_data["is_editable"] = true;
      account_payable_data["is_ledger"] = true;
      account_payable_data["child"] = [];
      // if (this.assets_data['last_sequence'] + 1 < 2000) {
      //   credit_note_data['name'] = vendor_name;
      //   credit_note_data['ref_id'] = vendor_id;
      //   credit_note_data['id'] = this.assets_data['last_sequence'] + 1;
      //   this.assets_data['last_sequence'] = credit_note_data['id'];
      //   credit_note_data['is_editable'] = true;
      //   credit_note_data['is_ledger'] = true;
      //   credit_note_data['child'] = [];
      //   // Pushing vendor data in account payable and credit note
      //   this.credit_notes.child.push(credit_note_data);
      // }
      this.accounts_payable.child.push(account_payable_data);
      this.flattened_data.forEach((flatObj) => {
        //Accounts Payable flatten obj
        if (
          flatObj.id == 2011 &&
          this.liability_data["last_sequence"] + 1 < 3000
        ) {
          flatObj["is_ledger"] = false;
          let parent_name: any = [];
          let parent_id: any = [];
          parent_name.push(flatObj.name);
          parent_id.push(flatObj.id);
          flatObj.parent_name.forEach((parent_names) => {
            parent_name.push(parent_names);
          });
          flatObj.parent_id.forEach((parent_ids) => {
            parent_id.push(parent_ids);
          });
          this.flattened_data.push({
            name: vendor_name,
            id: this.liability_data["last_sequence"],
            parent_name: parent_name,
            parent_id: parent_id,
            ref_id: vendor_id,
            is_editable: true,
            is_ledger: true,
          });
        }
        //Credit Notes flatten obj
        if (
          flatObj.id == 1011 &&
          this.assets_data["last_sequence"] + 1 < 2000
        ) {
          flatObj["is_ledger"] = false;
          let parent_name: any = [];
          let parent_id: any = [];
          parent_name.push(flatObj.name);
          parent_id.push(flatObj.id);
          flatObj.parent_name.forEach((parent_names) => {
            parent_name.push(parent_names);
          });
          flatObj.parent_id.forEach((parent_ids) => {
            parent_id.push(parent_ids);
          });
          this.flattened_data.push({
            name: vendor_name,
            id: this.assets_data["last_sequence"],
            parent_name: parent_name,
            parent_id: parent_id,
            ref_id: vendor_id,
            is_editable: true,
            is_ledger: true,
          });
        }
      });

      // updating coa with new vendor data
      this.mastersService
        .updateCoa(condition, {
          chart_of_accounts: this.chart_of_accounts,
          flattened_data: this.flattened_data,
        })
        .subscribe((result) => { });
      // updating vendor with coa data
      this.vendorService
        .editVendor(
          {
            coa_data: account_payable_data,
            credit_note_data: credit_note_data,
          },
          vendor_id
        )
        .subscribe((result) => { });
    }
  }
}
