<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header d-flex align-items-center">
          <h3 class="table-title"><i class="fa fa-user"></i>Stock Journal </h3>
        </div>
        <form novalidate #addJournalForm="ngForm" (ngSubmit)="addStockJournal()">
          <div class="card-body row">
            <div class="form-group col-12 col-sm-6 col-md-4 col-xl-4  required ">
              <label class="col-form-label ">Journal Date</label>
              <div class="">
                <div class="input-group">
                  <input angular-mydatepicker #journal_date="angular-mydatepicker" placeholder="Journal Date" required
                    (click)="journal_date_dp.toggleCalendar()" #journal_date_dp="angular-mydatepicker"
                    class="form-control" name="journal_date" [(ngModel)]="journal.journal_date"
                    [options]="journal_dateDpOptions" (dateChanged)="onjournal_dateDateChanged($event)">
                  <!-- clear date button -->
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="journal.journal_date"
                      (click)="journal_date_dp.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>

                  <!-- toggle calendar button -->
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="journal_date_dp.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>
                <app-validation-errors [mformField]="journal_date" label="Journal Date">
                </app-validation-errors>
              </div>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-4 col-xl-4 ">
              <label class="col-form-label ">Journal Number</label>
              <div class="">
                {{journal.journal_no}}
              </div>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-4 col-xl-4 ">
              <label class="col-form-label ">Description</label>
              <div class="">
                <textarea type="textarea" class="form-control" name="memo" #memo="ngModel" id="memo"
                  [(ngModel)]="journal.memo" placeholder=""></textarea>
                <app-validation-errors [mformField]="memo" label="Memo">
                </app-validation-errors>
              </div>
            </div>
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>product</th>
                  <th>In Qty</th>
                  <th>Out Qty</th>
                  <th>Item Value</th>
                  <th>Total Value</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let journal_items of journal.journal_items; let i=index;">
                  <td>
                    <ng-select [items]="productList" [typeahead]="productListTypeahead" name="account{{i}}" required
                      #account="ngModel" [(ngModel)]="journal_items.product" id="account{{i}}" bindLabel="name">
                      <ng-template ng-label-tmp let-item="item">
                        <div [ngOptionHighlight]="search">
                          {{item?.name}}
                        </div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <div [ngOptionHighlight]="search">
                          {{item.name}}
                        </div>
                      </ng-template>
                      <!-- <ng-template ng-footer-tmp>
                        <button type="button" class="btn btn-primary"><i class="fa fa-plus"></i>Add Account
                          Head</button>
                      </ng-template> -->
                    </ng-select>

                    <app-validation-errors [mformField]="account" label="Account Head">
                    </app-validation-errors>
                  </td>
                  <!-- (change)="journal_items.credit=0;" -->
                  <!-- (change)="changeCredit(journal_items)" -->
                  <td><input type="number" [min]="0" class="form-control" name="in_qty{{i}}" #in_qty="ngModel"
                      [(ngModel)]="journal_items.in_qty" placeholder="" (change)="journal_items.out_qty=0;"
                      (keyup)="calculate_totalvalue(journal_items)">
                    <app-validation-errors [mformField]="in_qty" label="In Qty">
                    </app-validation-errors>
                  </td>
                  <td><input type="number" [min]="0" class="form-control" name="out_qty{{i}}" #out_qty="ngModel"
                      [(ngModel)]="journal_items.out_qty" placeholder="" (change)="journal_items.in_qty=0;"
                      (keyup)="calculate_totalvalue(journal_items)">
                    <app-validation-errors [mformField]="out_qty" label="Out Qty">
                    </app-validation-errors>
                  </td>
                  <td><input type="number" [min]="0" class="form-control" name="item_value{{i}}" #item_value="ngModel"
                      (keyup)="calculate_totalvalue(journal_items)" [(ngModel)]="journal_items.item_value"
                      placeholder="">
                    <app-validation-errors [mformField]="item_value" label="Item Value">
                    </app-validation-errors>
                  </td>
                  <td><input type="number" disabled [min]="0" class="form-control" name="total_value{{i}}"
                      [(ngModel)]="journal_items.total_value" placeholder="">

                  </td>

                  <td>
                    <button type="button" class="btn btn-primary" (click)="addNewItem(i)"><i
                        class="fa fa-plus"></i></button>
                    <button *ngIf="i>0" type="button" class="btn btn-danger" (click)="deleteItem(i)"><i
                        class="fa fa-trash"></i></button></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card-footer">
            <button type="submit" [disabled]="!addJournalForm.valid || loading==true" class="btn btn-primary">Save
              <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
            </button>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>
