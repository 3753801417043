import { Injectable } from "@angular/core";
import { environment } from "@src/environments/environment";
import { Invoice } from "../_models/invoice.model";
import { ApiService } from "../_services/api.service";
import { AuthenticationService } from "./authentication.service";

@Injectable({
  providedIn: "root",
})
export class InvoiceService {
  branch_id: string;
  constructor(
    private http: ApiService,
    private authenticationService: AuthenticationService
  ) {
    this.branch_id = this.authenticationService.getDefaultBranchId();
  }
  UpdateBillFile(files, muid) {
    return this.http.uploadFile(
      ApiService.geturl("invoice", "upload"),
      files,
      muid
    );
  }
  addPayInvoice(invoice_data: any) {
    if (this.branch_id != "") {
      invoice_data["branch_id"] = this.branch_id;
    }
    return this.http.post(
      ApiService.geturl("invoice", "add_pay"),
      invoice_data
    );
  }
  addInvoice(invoice_data: any) {
    if (this.branch_id != "") {
      invoice_data["branch_id"] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("invoice", "add"), invoice_data);
  }
  editInvoice(invoice_data: Invoice, id: string) {
    return this.http.put(ApiService.geturl("invoice", "edit"), invoice_data, {
      id: id,
    });
  }
  creditNote(invoice: any) {
    return this.http.post(ApiService.geturl("creditnote", "add"), invoice);
  }
  updateCreditNote(invoice: any, id: string) {
    return this.http.post(ApiService.geturl("creditnote", "edit"), { invoice, id: id });
  }
  deleteInvoice(id: string) {
    return this.http.delete(ApiService.geturl("invoice", "delete_draft"), {
      id: id,
    });
  }
  sendInvoice(row_data) {
    return this.http.post(ApiService.geturl("invoice", "sendInvoice"), {
      invoice_data: row_data,
    });
  }

  // viewInvoice(id: string, condition: {}) {
  //   if (this.branch_id != "") {
  //     condition["branch_id"] = this.branch_id;
  //   }
  //   return this.http.get(ApiService.geturl("invoice", "view"), {
  //     invoice_id: id,
  //     condition: condition,
  //   });
  // }

  viewInvoice(id: string) {
    return this.http.get(ApiService.geturl("invoice", "view"), { id: id });
  }

  getInvoices(
    filters: any = {},
    columns?: any,
    sort: any = {},
    page: any = {}
  ) {
    if (this.branch_id != "") {
      filters["branch_id"] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("invoice", "viewall"), {
      filters: filters,
      columns: columns,
      sort: sort,
      page: page,
    });
  }
  getSelectedInvoices(invoice_ids, columns) {
    return this.http.post(ApiService.geturl("invoice", "getAllInvoices"), {
      filters: invoice_ids["_id"],
      columns: columns,
    });
  }
  addCreditNote(invoice_data: any) {
    return this.http.post(ApiService.geturl("creditnote", "add"), invoice_data);
  }
  getAdvanceAmount(filter) {
    return this.http.post(ApiService.geturl("invoice", "getAdvanceAmount"), {
      filters: filter,
    });
  }

  UpdateInvoiceFile(files, muid) {
    return this.http.uploadFile(
      ApiService.geturl("invoice", "upload"),
      files,
      muid
    );
  }
  updateBillForAdvance(data) {
    return this.http.post(
      ApiService.geturl("bill", "updatetAdvanceAmountInBill"),
      {
        data: data,
      }
    );
  }
  updateBillForDebit(data) {
    return this.http.post(
      ApiService.geturl("creditnote", "updatetAmountInCreditBill"),
      {
        data: data,
      }
    );
  }
  getCreditAmount(filter) {
    return this.http.post(
      ApiService.geturl("debitnote", "getUsersDebitAmount"),
      {
        filters: filter,
      }
    );
  }
  getInvoiceReports(
    filters: any = {},
    columns?: any,
    sort: any = {},
    page: any = {}
  ) {
    return this.http.post(ApiService.geturl("invoice", "viewall"), {
      filters: filters,
      columns: columns,
      sort: sort,
      page: page,
    });
  }
  UpdateInvoiceStatus(data, condition) {
    return this.http.post(ApiService.geturl("invoice", "update_invoice_filestatus"), {
      data: data,
      condition: condition
    });
  }
  exportInvoice(muid: string, exportType: string) {
    return this.http.post(ApiService.geturl("invoice", "export"), {
      muid, exportType
    });
  }

  editEwayorDelivery(invoice_data: Invoice, id: string) {
    return this.http.put(ApiService.geturl("invoice", "e_way"), invoice_data, {
      id: id,
    });
  }

  updateStock(){
    return this.http.post(ApiService.geturl('invoice', 'updateExistsStock'),{});
  }
}
