import { Country, State } from "./country.model";
import { Currency } from "./currency.model";
import { ContactAddress } from "./contact_address.model";
import { Contacts } from "./contacts.model";
import { Tds } from "./tds";
export class Vendor {
  vendor_name: string;
  email: string;
  first_name: string;
  last_name: string;
  account_number: number;
  phone: string;
  fax: string;
  mobile: string;
  toll_free: string;
  website: string;
  country: Country = new Country();
  state: State = new State();
  address_line1: string;
  address_line2: string;
  city: string;
  postal_code: string;
  currency: Currency = new Currency();
  payment_term: string;
  gst_number: String;
  company_id: string;
  branch_id: string;
  is_sez: Boolean;
  bank: any;
  gst_effective_date: any;
  gst: string;
  tan: string;
  tan_effective_date: any;
  is_tds_applicable: boolean = false;
  is_multiple_tds: boolean = false;
  // tds: Tds[] | Tds = new Tds();
  tds: any;
  // tds: any;
  // cin: string;
  // cin_effective_date: any;
  // pf: string;
  // pf_effective_date: any;
  // esi: string;
  // esi_effective_date: any;
  // lut: string;
  // lut_effective_date: any;
  pan: string;
  contact_address: ContactAddress[] = [];
  contacts: Contacts[] = [];
  _id: string;
  annual_total_amount: number;
  month_total_amount: number[];
  vendor_category: VendorCategory = new VendorCategory();
  is_composite_company: boolean;
}

export class VendorCategory {
  name: string;
  id: number;
}
