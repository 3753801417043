import { Injectable } from "@angular/core";
import { User } from "../_models/user";
// import { AuthenticationService } from "./authentication.service";
import { ConstantService } from "../_config/constants";
import { ApiService } from "../_services/api.service";

export interface ITokenUser {
  username: string;
  displayName?: string;
  picture?: string;
}

@Injectable()
export class UserService {
  user: ITokenUser;
  expiration: Date;
  constructor(
    private http: ApiService,
    // private auth: AuthenticationService,
    public ConstantService: ConstantService
  ) { }
  OnInit() {
    // this.user = this.auth.getPayload();
  }
  getUsers() {
    return this.http.post(ApiService.geturl("user", "getusers"), {});
  }
  viewall(filters: any, columns?: any, sort: any = {}, page: any = {}) {
    return this.http.post(ApiService.geturl("user", "viewall"), {
      filters: filters,
      columns: columns,
      sort: sort,
      page: page,
    });
  }
  getUsersList(role_id) {
    return this.http.post(ApiService.geturl("user", "viewall"), {
      filters: { user_role_id: role_id },
    });
  }
  viewUser(id: string) {
    return this.http.get(ApiService.geturl("user", "view"), { id: id });
  }
  refreshToken() {
    return this.http.post(ApiService.geturl('user', 'refresh_token'), {});
  }
  // updateUser(userData: User, user_id: any) {
  //   console.log(userData);
  //   return this.http.post(ApiService.geturl("user", "edit"), {
  //     userData: userData,
  //     user_id: user_id,
  //   });
  // }
  updateUser(userData: any, id: string) {
    return this.http.put(ApiService.geturl("user", "edit"), userData, {
      id: id,
    });
  }
  updateCurrentCompany(userData: any, id: string) {
    return this.http.put(ApiService.geturl("user", "edit_details"),{ userData, 
      id,
    });
  }
  suspendUser(id: string) {
    return this.http.post(ApiService.geturl("user", "suspenduser"), {
      user_id: id,
    });
  }
  activateUser(id: string) {
    return this.http.post(ApiService.geturl("user", "activate_user"), {
      user_id: id,
    });
  }

  addUser(userData: User) {
    return this.http.post(ApiService.geturl("user", "create"), userData);
  }

  deleteUser(id: string) {
    return this.http.delete(ApiService.geturl("user", "delete"), { id: id });
  }

  changePassword(data: any, id: string) {
    return this.http.post(ApiService.geturl("user", "change_password"), {
      data,
      user_id: id,
    });
  }
  forgotPassword(username: string) {
    return this.http.post(ApiService.geturl("user", "forgot_password"), {
      username
    });
  }
  resetPassword(unique_id: any, data) {
    return this.http.post(ApiService.geturl("user", "reset_password"), {
      unique_id: unique_id,
      data: data
    });
  }

  getClientUsers(client_id: string) {
    return this.http.post(ApiService.geturl("user", "getclientusers"), {
      client_id,
    });
  }
  updateClientId(client_id: string, muid: string) {
    return this.http.post(ApiService.geturl("user", "update_client_id"), {
      client_id,
      muid,
    });
  }
  getUserRoles() {
    return this.http.post(ApiService.geturl("user", "getuserroles"), {});
  }
  getUserRolesList(condition: any = {}, page: any = {}) {
    return this.http.post(ApiService.geturl("user", "user_roles"), {
      condition,
      page,
    });
  }

  addRole(role_name: string) {
    return this.http.post(ApiService.geturl("role", "add"), { role_name });
  }
}
