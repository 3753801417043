import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { AuthenticationService } from "./authentication.service";

@Injectable({ providedIn: "root" })
export class ExpenseService {
  branch_id: string;
  constructor(
    private http: ApiService,
    private authenticationService: AuthenticationService
  ) {
    this.branch_id = this.authenticationService.getDefaultBranchId();
  }

  addExpense(expense_data: any) {
    return this.http.post(ApiService.geturl("expense", "add"), expense_data);
  }
  editExpense(expense_data: any, id: string) {
    return this.http.put(ApiService.geturl("expense", "edit"),expense_data, {
      id: id,
    });
  }
  // deleteExpense(id: string) {
  //   return this.http.post(ApiService.geturl("expense", "delete"), { id: id });
  // }
  deleteExpense(id: string) {
    return this.http.delete(ApiService.geturl("expense", "delete"), { id: id });
  }

  // viewExpense(id: string) {
  //   return this.http.post(ApiService.geturl("expense", "view"), {
  //     expense_id: id,
  //   });
  // }

  viewExpense(id: string) {
    return this.http.get(ApiService.geturl("expense", "view"), {
      id: id,
    });
  }

  viewBillExpense(id: string) {

    return this.http.get(ApiService.geturl("expense", "bill_view"), { id: id, });
  }

  getExpenses(
    filters: any = {},
    columns?: any,
    sort: any = {},
    page: any = {}
  ) {
    return this.http.post(ApiService.geturl("expense", "viewall"), {
      filters: filters,
      columns: columns,
      sort: sort,
      page: page,
    });
  }
  getExpenseInvoice(
    filters: any = {},
    columns?: any,
    sort: any = {},
    page: any = {}
  ) {
    return this.http.post(ApiService.geturl("expense", "getExpenseInvoice"), {
      filters: filters,
      columns: columns,
      sort: sort,
      page: page,
    });
  }
  getSelectedExpense(bill_ids, columns) {
    return this.http.post(ApiService.geturl("expense", "getAllExpense"), {
      filters: bill_ids["_id"],
      columns: columns,
    });
  }
  fileRcm(row_data) {
    return this.http.post(ApiService.geturl("expense", "updateRcm"), {
      rcm_data: row_data,
    });
  }
  addExpensePayment(expense_id, company_id) {
    return this.http.post(
      ApiService.geturl("payment", "addExpensePayment"), {
      data: expense_id,
      company_id: company_id
    }
    );
  }
  confirmExpense(expense_data, company_id) {
    return this.http.post(ApiService.geturl("expense", "confirm_expense"), {
      data: expense_data,
      company_id: company_id
    });
  }
}
