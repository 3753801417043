import { Component, OnInit, TemplateRef, ViewChild, EventEmitter, ChangeDetectorRef, Input } from "@angular/core";
import swal from "sweetalert2";
import { PurchaseorderService } from "../../_services/purchaseorder.service";
import { AuthenticationService } from "../../_services/authentication.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { NgbModalConfig, NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
// import { ngxDaterangepicker } frWom "@megamtech-opensource/ngx-datepicker";
import { MastersService } from "../../_services/masters.service";
import { PaymentSettings } from "../../_models/payment-settings.model";
import { ConstantService } from "../../_config/constants";
import { VendorService } from "../../_services/vendor.service";
import {
  distinctUntilChanged,
  debounceTime,
  switchMap,
  filter,
} from "rxjs/operators";
import { startOfDay, endOfDay, startOfMonth, endOfMonth, sub } from "date-fns";
import { InvoiceViewComponent } from "@src/app/components/invoice/invoice-view/invoice-view.component";
@Component({
  selector: "app-purchaseorder",
  templateUrl: "./purchaseorder.component.html",
  styleUrls: ["./purchaseorder.component.scss"],
  providers: [NgbModalConfig, NgbModal]
})
export class PurchaseorderComponent implements OnInit {
  filters: any = {};
  purchaseorder: any = {};
  purchaseorderList: any = [];
  purchaseorderListColumns: any = [];
  purchase_status_list: any = [
    // { name: "PO", id: 1 },
    { name: "Unbilled", id: 1 },
    { name: "Billed", id: 2 },
    // { name: "Partially Paid", id: 4 },
    // { name: "Paid", id: 5 }
  ];
  count: number;
  purchaseorderDateOptions: IAngularMyDpOptions = {
    dateRange: true,
    disableUntil: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat
  };
  // purchaseorderDateOptions: ngxDaterangepicker = {
  //   dateRange: true,
  //   dateFormat: ConstantService.DateFormat
  // };
  selected = { startDate: startOfDay(new Date()), endDate: endOfDay(new Date()) };
  loadingIndicator = false;
  reorderable = true;
  columns: any = {};
  page: any = { offset: 0, pageSize: 10 };
  // limit = 1;
  // @ViewChild("actionTmpl", { static: true }) actionTmpl: TemplateRef<any>;
  company_id: string;
  branch_id: string;
  dueDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat
  };
  model: IMyDateModel = null;

  //selected = [];
  selected_items = [];

  rows = [];
  dispCheckBox: boolean = true;
  // @ViewChild("candidateTable", { static: false }) table: any;

  selectedOrder: any;
  payType: string = "";
  selecteditem_array: any;
  item_selected: any;
  headList: any;
  balance_amt: number = 0;
  sum_of_bal_qty: number = 0;
  transaction_head: string;
  tx_date: IMyDateModel;
  currency: string;
  public payment = new PaymentSettings();
  invoice_array: any = [];
  pay_head: string;
  check_status: boolean;
  sort: any = { purchase_order_number: -1 };
  dt_default_sort = [{ prop: "purchase_order_number", dir: "asc" }];
  page_icon: any;
  vendor_lists: any = [];
  vendorListTypeahead = new EventEmitter<string>();
  poNumberList: any[];
  poNumberListTypeahead = new EventEmitter<string>();
  //transaction_head: string;
  // payment_note: string;
  // total_payment_amount: number;
  // transaction_due_date: Date;
  // balance_amount: number = 0;
  // item_selected: any;
  //
  //
  //
  // sum_of_bal_qty: number;

  // payment: any = {
  //   vendor_name: String,
  //   vendor_id: String,
  //   account_head: Object,
  //   company_id: String,
  //   tx_date: Date,
  //   payment_note: String,
  //   currency: String,
  //   currency_object: Object,
  //   pay_amount: Number,
  //   data_array: []
  // };
  // data_array: any;
  // data: any;
  ranges: any = [
    { label: 'Today', start: startOfDay(new Date()), end: endOfDay(new Date()) },
    {
      label: 'Yesterday', start: startOfDay(sub(new Date, { 'days': 1 })),
      end: endOfDay(sub(new Date, { 'days': 1 }))
    },
    { label: 'Last 7 Days', start: startOfDay(sub(new Date, { 'days': 7 })), end: endOfDay(new Date()) },
    { label: 'Last 30 Days', start: startOfDay(sub(new Date, { 'days': 30 })), end: endOfDay(new Date()) },
    { label: 'This Month', start: startOfMonth(new Date), end: endOfDay(new Date()) },
    {
      label: 'Last Month', start: startOfMonth(sub(new Date, { months: 1 })),
      end: endOfMonth(sub(new Date, { months: 1 }))
    },
    {
      label: 'Last 3 Month', start: startOfMonth(sub(new Date, { months: 3 })),
      end: endOfMonth(sub(new Date, { months: 1 }))
    }
  ]
  closeResult = '';
  id: any;
  companyDetails: any;
  selectFiYear: any;

  constructor(
    private router: Router,

    private purchaseorderservice: PurchaseorderService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private masterService: MastersService,
    public route: ActivatedRoute,
    private vendorService: VendorService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // this.filters.purchase_status = this.purchase_status_list[0];
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.companyDetails = this.authenticationService.getCompanyDetails();
    this.currency = this.companyDetails.base_currency;

    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];
    this.filters.po_date = {
      isRange: true,
      singleDate: null,
      dateRange: {
        beginDate: { year: this.selectFiYear.start_year, month: 4, day: 1 },
        beginJsDate: new Date(this.selectFiYear.start_year, 3, 1),
        endDate: { year: this.selectFiYear.end_year, month: 3, day: 31 },
        endJsDate: new Date(this.selectFiYear.end_year, 2, 31),
      }
    };
    this.getVendors("").subscribe((result) => {
      this.vendor_lists = result["response"]["data"];
    });
    this.getVendorsSearch();
    this.getPONumberList("").subscribe((result) => {
      this.poNumberList = result["response"]["data"];
    });
    this.getPONumberListSearch();
    this.purchaseorderListColumns = [
      {
        prop: "purchase_order_number",
        name: "PO Number",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false
      },
      {
        prop: "vendor",
        name: "Vendor",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "object",
        field_name: "vendor_name"
      },
      {
        prop: "purchase_order_date",
        name: "PO Date",
        sortable: "purchase_order_date",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "date"
      },
      // {
      //   prop: "due_date",
      //   name: "Due Date",
      //   sortable: "false",
      //   flexGrow: 1,
      //   show: true,
      //   is_detailed_row: false,
      //   type: "date"
      // },
      // {
      //   prop: "balance_amount",
      //   name: "Balance",
      //   sortable: "balance_amount",
      //   flexGrow: 1,
      //   show: true,
      //   is_detailed_row: false,
      //   type: "currency",
      //   css_class: "align-right"
      // },
      {
        prop: "total_amount",
        name: "Total Amount",
        sortable: "total_amount",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "currency",
        css_class: "align-right"
      },

      {
        prop: "purchase_status_name",
        name: "Status",
        sortable: "purchase_order_status",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "status"
      },
      {
        name: "Action",
        type: "action",
        prop: "_id",
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false
      },
    ];
    this.purchaseorderListColumns.forEach(column => {
      this.columns[column.prop] = 1;
    });
    this.page_icon = this.route.snapshot.data.menu.icon;
    if (this.route.snapshot.queryParams["q"] != "undefined")
      this.filters["vendor"] = this.route.snapshot.queryParams["q"];

    // if (this.route.snapshot.queryParams['q'] != 'undefined')
    //   this.filters['purchase_order_number'] = this.route.snapshot.queryParams['q'];
    // this.getCoaHead();
    this.getPurchaseordersList(this.page);
    this.tx_date = { isRange: false, singleDate: { jsDate: new Date() } };
  }

  open(content, id) {
    this.id = id;
    this.modalService.open(content, { size: "lg" });
  }

  getVendors(searchString) {
    this.vendor_lists = [];
    let condition: any = {};
    let columns: any = {};
    if (searchString != "") {
      condition["vendor_name"] = searchString;
    }
    condition["company_id"] = this.company_id;
    columns["vendor_name"] = 1;
    columns["first_name"] = 1;
    columns["last_name"] = 1;
    columns["currency"] = 1;
    columns["address_line1"] = 1;
    columns["address_line2"] = 1;
    columns["valid_upto"] = 1;
    columns["country"] = 1;
    columns["state"] = 1;
    columns["phone"] = 1;
    columns["coa_data"] = 1;
    columns["credit_note_data"] = 1;
    columns["is_sez"] = 1;
    return this.vendorService.getVendors(condition, columns, {}, { limit: 10 });
  }
  getVendorsSearch() {
    this.vendorListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getVendors(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.vendor_lists = result["response"]["data"];
        },
        (err) => {
          console.log(err);
          this.vendor_lists = [];
        }
      );
  }

  getPONumberList(searchString: string) {
    let condition: any = {};
    condition["company_id"] = this.company_id;
    // if (searchString != "") {
    condition["po_number_search_string"] = searchString;
    // }
    return this.purchaseorderservice.getPurchaseorders(
      condition,
      { purchase_order_number: 1 },
      {},
      { offset: 0, limit: 10 }
    );
  }

  getPONumberListSearch() {
    this.poNumberListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getPONumberList(searchString))
      )
      .subscribe(
        (result) => {
          this.poNumberList = [];
          this.cd.markForCheck();
          this.poNumberList = result["response"]["data"];
        },
        (err) => {
          console.log(err);
          this.poNumberList = [];
        }
      );
  }

  onSort(event) {
    this.loadingIndicator = true;
    // console.log("event", event);
    this.sort = {};
    let sort_details = event.sorts[0];
    this.sort[sort_details.prop] = sort_details.dir == "asc" ? 1 : -1;
    this.getPurchaseordersList(this.page);
  }

  getPurchaseordersList(page_info: any) {
    if (page_info != null) {
      this.page = page_info;
    }
    this.filters["company_id"] = this.company_id;
    this.purchaseorderservice
      .getPurchaseorders(this.filters, this.columns, this.sort, this.page)
      .subscribe(result => {
        this.loadingIndicator = false;
        // this.purchaseorderListColumns = [
        //   { name: "Action", cellTemplate: this.actionTmpl, prop: "muid" }
        // ];
        this.purchaseorderList = result["response"]["data"];
        this.page.count = result["response"]["count"];
      });
  }
  clearPurchaseorderFilters() {
    // this.purchaseorder = {};
    this.filters = {};
    this.getPurchaseordersList({ offset: 0, pageSize: 10 });
  }
  deletePurchaseorder(muid) {
    swal({
      title: "Are you sure want to delete?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result) {
        this.purchaseorderservice
          .deletePurchaseorder(muid)
          .subscribe(result => {
            if (result["status_code"] === 1) {
              swal("Deleted!", "Purchaseorder has been deleted.", "success");
              this.getPurchaseordersList({});
            }
          });
      }
    });
  }

  onSelectOrders({ selected }) {
    console.log(selected);
    this.selecteditem_array = [];
    this.selected_items = [];

    selected.forEach(element => {
      this.selected_items.push(element.vendor._id);
    });
    this.item_selected = this.selected_items[0];
    if (this.selected_items.length > 1) {
      this.payType = "multiple";
    } else {
      this.payType = "single";
    }
    this.selecteditem_array = selected;
  }

  payAmountModal(payAmount) {
    this.invoice_array = [];
    this.invoice_array.amount = 0;
    this.balance_amt = 0;
    this.invoice_array.debit = 0;
    if (this.selected_items.length == 1 || this.selected_items.length > 1) {
      this.payment.company_id = this.selecteditem_array[0].company_id;
      this.payment.vendor_name = this.selecteditem_array[0].vendor.vendor_name;
      this.payment.vendor_id = this.selecteditem_array[0].vendor;
      if (this.payType == "single") {
        this.modalService.open(payAmount, { size: "lg" });
      } else if (this.payType == "multiple") {
        let passed = this.selected_items.every(this.isEqualVendor);
        if (passed) {
          this.modalService.open(payAmount, { size: "lg" });
        } else {
          swal("Error!", "Please select same vendor.", "error");
        }
      } else {
        swal("Error!", "Please select an order.", "error");
      }

      // console.log("=======customer", this.payment);

      this.selecteditem_array.forEach(element => {
        this.payment.currency = element.currency;
        this.balance_amt = this.balance_amt + parseInt(element.balance_amount);
        this.payment.total_payment_amount = this.balance_amt;
        this.invoice_array.push({
          purchase_order_number: element.purchase_order_number,
          purchase_id: element._id,
          purchase_order_date: element.purchase_order_date,
          debit: element.balance_amount,
          amount: element.balance_amount,
          credit: 0
        });
      });
    } else {
      swal("Error!", "Please select an order.", "error");
    }
  }
  isEqualVendor(element, index, array) {
    console.log(array);
    return element == array[0];
  }
  payPurchaseOrder() {
    this.sum_of_bal_qty = this.invoice_array.reduce(function (r, a) {
      return r + a["debit"];
    }, 0);

    if (this.invoice_array.length > 1) {
      if (this.sum_of_bal_qty == this.payment.total_payment_amount) {
        this.check_status = true;
      } else {
        this.check_status = false;
      }
    } else {
      this.check_status = true;
    }
    if (this.check_status) {
      this.payment.account_head = this.transaction_head;
      this.payment.payment_array = this.invoice_array;
      this.payment.tx_date = this.tx_date["singleDate"]["jsDate"];
      this.payment.tx_type = 1;
      this.payment.company_id = this.company_id;
      this.payment.currency = this.currency;
      this.payment.party_id = [
        {
          vendor_id: this.payment.vendor_id["_id"]
        }
      ];

      this.purchaseorderservice
        .createPayment(this.payment)
        .subscribe(result => {
          if (result["statusCode"] == 200) {
            swal("Success!", "Payment Successful.", "success");
            this.router.navigateByUrl("/purchase-order/list");
          } else {
            swal("Error!", "Payment Unsuccessful.", "error");
          }
        });
    } else {
      swal("Error!", "The amount doesnt tally during split up.", "error");
    }
  }
  // getCoaHead() {
  //   this.masterService
  //     .getCoaHead(this.company_id, "Revenue")
  //     .subscribe(result => {
  //       this.headList = result["response"];
  //       // this.payment.transaction_method = result['response']['chart_of_accounts']['child']['revenue'];
  //     });
  // }
  getPaymentHead() {
    if (this.payment.payment_mode == 1) {
      this.pay_head = "Bank";
    } else if (this.payment.payment_mode == 2) {
      this.pay_head = "Cash";
    }

    this.headList = [
      {
        flattened_data: {
          name: "Account Payable",
          id: "gjgj7779"
        }
      }
    ];

    // this.masterService
    //   .getCoaHead(this.company_id, this.pay_head)
    //   .subscribe(result => {
    //     this.headList = result["response"];
    //   });
  }
}
