import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import swal from "sweetalert2";


import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";

import { AccountsHeadTemplate } from "src/app/_models/accounts-head-template.model";
import { Journal } from "../../../_models/journal";

import { ConstantService } from "src/app/_config/constants";
import { CompanyService } from "../../../_services/company.service";
import { MastersService } from "../../../_services/masters.service";
import { JournalService } from "../../../_services/journal.service";
import { AuthenticationService } from "../../../_services/authentication.service";
import { SequenceService } from "../../../_services/sequence.service";
import { PaymenttransactionsService } from "../../../_services/paymenttransactions.service";
import { differenceInDays } from "date-fns";
// import { startOfMonth, endOfMonth } from "date-fns";
@Component({
  selector: "app-journal-add",
  templateUrl: "./journal-add.component.html",
  styleUrls: ["./journal-add.component.scss"],
})
export class JournalAddComponent implements OnInit {
  id: string;
  is_edit_form: boolean = false;
  journal: Journal = new Journal();
  coa: any = {};
  accountHeadListTypeahead = new EventEmitter<string>();
  company_id: string;
  branch_id: string;
  head: any = {};
  journal_dateDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };
  JournalorderDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    disableSince: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };
  sum_of_debit: number;
  sum_of_credit: number;
  company_details: any;
  sequence_id: any;
  sequence_no: any;
  journalAccountLedgerConfig: AccountsHeadTemplate = new AccountsHeadTemplate();
  currency: any;
  openingBalanceForm = false;
  financialStartYear: Date;
  totals: { 'assets': number, 'liabilities': number, 'debit': number, 'credit': number } = { 'assets': 0, 'liabilities': 0, 'debit': 0, 'credit': 0 };
  isOpeningBalanceFormEditable = true;
  selectFiYear: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private journalservice: JournalService,
    private mastersService: MastersService,
    private authenticationService: AuthenticationService,
    private sequenceService: SequenceService,
    private companyService: CompanyService,
    private cd: ChangeDetectorRef,
    private paymenttransactionsService: PaymenttransactionsService,
  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];

    if (this.router.url.match('opening_balance')) {
      this.openingBalanceForm = true;
      let company_details = this.authenticationService.getCompanyDetails();
      this.financialStartYear = new Date(company_details.fi_year.start_year, ConstantService.FY_START_MONTH, ConstantService.FY_START_DATE);
      this.journalAccountLedgerConfig.accountsHeads = [1000, 2000];
      this.journalAccountLedgerConfig.filter_type = 'in';

    }
    if (this.router.url.search('journal') >= 0) {
      this.openingBalanceForm = false;
      this.journalAccountLedgerConfig.accountsHeads = [1030, 1031];
      this.journalAccountLedgerConfig.filter_type = 'nin';
      this.getCompanyDetails();
      this.addNewItem(0);
    }
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get("id");
      if (this.id != null) {
        this.getJournal();
      }
      var result = this.router.url.match("/edit/");
      if (result != null) {
        if (result[0] === "/edit/" && this.id != null) {
          this.is_edit_form = true;
        }
      } else {
        this.getNextSequence();
        this.journal.journal_date = {
          isRange: false,
          singleDate: {
            jsDate: new Date(),
          },
        };
      }
    });
    this.journal.journal_date={
      isRange: false,
      singleDate: {
        jsDate: differenceInDays(new Date(this.selectFiYear.end_year, 2, 31), new Date()) > 0 && differenceInDays(new Date(), new Date(this.selectFiYear.start_year, 3, 1)) > 0 ? new Date() : new Date(this.selectFiYear.end_year, 2, 31),
      },
    };


    // this.addNewItem(0);
    this.currency = this.authenticationService.getCompanyDetails()[
      "base_currency"
    ];
    this.getCOA();
  }

  //   this.getJournalsList();

  onjournal_dateDateChanged(event: IMyDateModel): void {
    // date selected
  }
  addJournal() {
    this.journal.company_id = this.company_id;
    //Journal transaction type id starts from 31
    this.journal.tx_type_id = 31;

    if (this.totals.credit == this.totals.debit) {
      this.journal.journal_date = this.journal.journal_date["singleDate"][
        "jsDate"
      ].toISOString();
      this.journal.amount = parseFloat(this.totals.credit.toString());
      this.journalservice.addJournal(this.journal).subscribe((result) => {
        if (result["status_code"] === 1) {
          this.updateSequence();
          swal("Added journal sucessfully!", "journal added.", "success");
          this.router.navigateByUrl("/journal/list");
        } else {
          this.showErrors(result[" response "][" errors "]);
        }
      });
    } else {
      swal("Warning!", "Credit And Debit Amount Doesn't Tally", "warning");
    }
  }
  saveJournal() {
    this.openingBalanceForm ? this.saveOpeningBalance() : this.addJournal();
  }

  addOpeningBalance() {

    this.journal.journal_items.forEach((element, i) => {
      if (element.account_head.flattened_data) {
        let flattenedDataIndex = this.coa.flattened_data.map((flattenedData) => {
          return flattenedData._id;
        }).indexOf(element.account_head.flattened_data._id);
        this.coa.flattened_data[flattenedDataIndex]['credit_opening_amt'] = element.credit;
        this.coa.flattened_data[flattenedDataIndex]['debit_opening_amt'] = element.debit;
        this.coa.flattened_data[flattenedDataIndex]['sequence'] = i;
      }
    });
    this.journalservice.addOpeningBalance(this.coa.flattened_data, this.company_id, this.financialStartYear).subscribe((response) => {
      if (response['status_code'] == 1) {
        swal('Success', response['message'], 'success');
        this.router.navigateByUrl("/coa/edit");
      } else {
        swal('Error', response['errors'][0], 'error');
      }

    });

    // this.mastersService.updateCoa({ company_id: this.company_id }, {
    //   flattened_data: this.coa.flattened_data,
    //   chart_of_accounts: this.coa.chart_of_accounts,
    // }).subscribe(result => {
    //   if (result['response']["ok"] == 1) {
    //     swal("Opening Balance Created Successfully");
    //   }
    // });

  }
  saveOpeningBalance() {
    if (parseFloat(this.totals.credit.toFixed(2)) != parseFloat(this.totals.debit.toFixed(2))) {
      swal({
        title: "Credit And Debit Amount Doesn't Tally",
        text: "Are you sure want to continue?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result) {
          this.addOpeningBalance();
        }
      });


    } else {
      this.addOpeningBalance();
    }

    // this.paymenttransactionsService.createOpeningBalancesTransactions(this.journal.journal_items, this.company_id, this.sum_of_debit, this.sum_of_credit).subscribe(result => {
    //   if (result['status_code'] == 1) {
    //     this.addOpeningBalance();
    //   }
    // });
  }
  getJournal() {
    this.journalservice.viewJournal(this.id).subscribe((result) => {
      this.journal = result[" response "];
    });
  }

  addUpdateJournal() {
    if (this.is_edit_form === true) {
      this.editJournal();
    } else {
      this.addJournal();
    }
  }

  editJournal() {
    this.journalservice
      .editJournal(this.journal, this.id)
      .subscribe((result) => {
        if (result[" response "] == 1) {
          swal(" Success ", " Journal Updated Successfully ");
          this.router.navigateByUrl("/journal/list");
        } else {
          this.showErrors(result[" response "][" errors "]);
        }
      });
  }

  sumOfCreditDebit() {
    this.totals = { 'assets': 0, 'liabilities': 0, 'debit': 0, 'credit': 0 };
    this.journal.journal_items.forEach((coa_head) => {
      if (this.openingBalanceForm) {
        if (coa_head.account_head.flattened_data) {

          if (coa_head.account_head.flattened_data.parent_id.indexOf(ConstantService.COA.ASSETS) > 0) {

            this.totals.assets += coa_head.credit_opening_amt > 0 ? coa_head.credit_opening_amt : 0;
            this.totals.assets += coa_head.debit_opening_amt > 0 ? coa_head.debit_opening_amt : 0;

          }
          if (coa_head.account_head.flattened_data.parent_id.indexOf(ConstantService.COA.LIABILITIES) > 0) {
            this.totals.liabilities += coa_head.credit_opening_amt > 0 ? coa_head.credit_opening_amt : 0;
            this.totals.liabilities += coa_head.debit_opening_amt > 0 ? coa_head.debit_opening_amt : 0;

          }
        }
      }

      this.totals.debit += coa_head.debit;
      this.totals.credit += coa_head.credit;
    });
    this.totals.debit = typeof this.totals.debit == 'string' ? parseFloat(this.totals.debit) : this.totals.debit;
    this.totals.credit = typeof this.totals.credit == 'string' ? parseFloat(this.totals.credit) : this.totals.credit;
  }

  showErrors(errors) {
    let error_string: string;
    error_string = " ";
    errors.forEach((item) => {
      error_string += " < br / > " + item;
    });
    swal({
      title: "Error",
      html: error_string,
      type: "error",
    }).then(function () { });
  }

  addNewItem(index) {
    let journalObj: any = {
      account_head: [],
      debit: "",
      credit: "",
      description: "",
    }
    let selectedAccountHeads = this.journal.journal_items.filter((ele) => ele.account_head !== '');
    if (selectedAccountHeads.length <= index + 1) {
      if (this.openingBalanceForm) {
        journalObj['tx_date'] = this.financialStartYear;
        journalObj['accountsHeads'] = this.journalAccountLedgerConfig;
      }
      this.journal.journal_items.push(journalObj);
    }
  }
  deleteItem(index) {
    if (this.openingBalanceForm) {
      let flattendDataIndex = this.coa.flattened_data.map((flattenedData) => {
        return flattenedData._id;
      }).indexOf(this.journal.journal_items[index]._id);
      if (flattendDataIndex != -1) {
        this.coa.flattened_data[flattendDataIndex]['credit_opening_amt'] = 0;
        this.coa.flattened_data[flattendDataIndex]['debit_opening_amt'] = 0;
      }
    }
    this.journal.journal_items.splice(index, 1);
  }
  getCOA() {
    return this.mastersService.getCoa(this.company_id).subscribe(result => {
      this.coa = result["response"];
      if (this.openingBalanceForm) {

        this.getOpeningBalance();
      }
    });
  }

  changeDebit(journal_items) {
    if (journal_items.credit != "") {
      journal_items.debit = 0;
    }
  }

  changeCredit(journal_items) {
    if (journal_items.debit != "") {
      journal_items.credit = 0;
    }
  }

  getCompanyDetails() {
    this.companyService.viewCompany(this.company_id).subscribe((result) => {
      this.company_details = result["response"];
      this.journal_dateDpOptions.dateFormat = this.company_details.date_format;
      let book_beginning_date = new Date(this.company_details.book_beginning);
      book_beginning_date.setDate(book_beginning_date.getDate() - 1);
      let curren_tx_dateOptions = this.getCopyOfOptions();
      curren_tx_dateOptions.disableUntil = {
        year: book_beginning_date.getFullYear(),
        month: book_beginning_date.getMonth() + 1,
        day: book_beginning_date.getDate(),
      };
      curren_tx_dateOptions.disableUntil = { year: this.selectFiYear.start_year, month: 3, day: 31 };
      curren_tx_dateOptions.disableSince = { year: this.selectFiYear.end_year, month: 4, day: 1 };
      this.journal_dateDpOptions = curren_tx_dateOptions;
    });
  }
  getCopyOfOptions(): IAngularMyDpOptions {
    return JSON.parse(JSON.stringify(this.journal_dateDpOptions));
  }

  getNextSequence() {
    let contra_sequence: any = {};
    contra_sequence["seq_name"] = "journal";
    contra_sequence["company_id"] = this.company_id;
    this.sequenceService.viewSequence(contra_sequence).subscribe((result) => {
      let data = result["response"];
      if (data != "" && data != null) {
        this.journal.journal_no =
          data["prefix"] + data["seq_no"] + data["suffix"];
      }
      this.sequence_id = data["_id"];
      this.sequence_no = data["seq_no"];
    });
  }
  updateSequence() {
    let sequence_data: any = {};
    sequence_data["seq_no"] = parseInt(this.sequence_no) + 1;
    sequence_data["company_id"] = this.company_id;
    this.sequenceService
      .editSequence(sequence_data, this.sequence_id)
      .subscribe((result) => { });
  }

  getVoucherType(voucher) {
    this.journal.voucher_name = "";
    this.journal.voucher_object = {};

    this.journal.voucher_name = voucher.name;
    this.journal.voucher_object = voucher;
  }

  updateLedger(ledger: any, index) {
    this.journal.journal_items[index].account_head = ledger;
    this.addNewItem(index);
  }

  getOpeningBalance() {
    this.journal.journal_items = [];
    let index = 0;
    this.coa.flattened_data.forEach((coa_head) => {
      if (coa_head.debit_opening_amt > 0 || coa_head.credit_opening_amt > 0) {
        coa_head.accountsHeads = { is_set: true, 'accountsHeads': [coa_head.id, ConstantService.COA.ASSETS, ConstantService.COA.LIABILITIES], 'filter_type': 'in' };
        coa_head.credit = coa_head.credit_opening_amt > 0 ? coa_head.credit_opening_amt : 0;
        coa_head.debit = coa_head.debit_opening_amt > 0 ? coa_head.debit_opening_amt : 0;
        this.journal.journal_items.push(coa_head);

        // this.updateLedger({
        let ledger = {
          flattened_data: {
            name: coa_head.name, "parent_name": coa_head.parent_name,
            "parent_id": coa_head.parent_id,
            "_id": coa_head._id,
            "id": coa_head.id,
            "ref_id": coa_head.ref_id,
            "is_ledger": coa_head.is_ledger
          }
        }

        // }, index);

        this.journal.journal_items[index].account_head = ledger;

        index++;
      }

    });

    this.journal.journal_items.sort((a, b) => a.sequence - b.sequence);
    this.sumOfCreditDebit();
    if (this.totals.debit.toFixed(2) == this.totals.credit.toFixed(2) && this.totals.debit > 0 && this.totals.credit > 0) {
      this.isOpeningBalanceFormEditable = false;
    }
    else {
      if (this.journal.journal_items.length == 0) {
        this.addNewItem(0);
      }
    }

  }


}
