import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import swal from "sweetalert2";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { AuthenticationService } from "../../_services/authentication.service";
import { UtilsService } from "../../_services/utils.service";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { ConstantService } from "../../_config/constants";
import { Fixedassests } from "../../_models/fixed_assests.model";
import { MastersService } from "../../_services/masters.service";
import { FixedAssestsService } from "../../_services/fixed-assests.service";
// import { AuthenticationService } from "../../_services/authentication.service";
@Component({
  selector: "app-fixed_assests-add",
  templateUrl: "./fixed_assests-add.component.html",
  styleUrls: ["./fixed_assests-add.component.scss"],
})
export class FixedassestsAddComponent implements OnInit {
  loading : boolean = false;
  fixedassests: Fixedassests = new Fixedassests();
  header: string;
  is_edit_form: boolean = false;
  ledger_lists: Object;
  ledgerListTypeahead = new EventEmitter<string>();
  company_id: any;
  branch_id: string;
  all_account_heads: any;
  id: string;
  constructor(
    private mastersService: MastersService,
    private authenticationService: AuthenticationService,
    private fixedAssestsService: FixedAssestsService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) {}
  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    // let in_condition: any = [];
    // this.getLedgers("", in_condition).subscribe((result) => {
    //   this.ledger_lists = result["response"]["data"];
    // });

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get("id");
      if (this.id != null) {
        this.getFixedassests();
      }
      var result = this.router.url.match("/edit/");
      if (result != null) {
        if (result[0] === "/edit/" && this.id != null) {
          this.is_edit_form = true;
          this.header = "Edit";
        }
      } else {
        this.header = "Add";
      }
    });
    // this.getLedgersSearch();
    // this.getCoaData();
  }

  getFixedassests() {
    let condition: any = {};
    condition["company_id"] = this.company_id;
    this.fixedAssestsService
      .viewFixedassests(this.id, condition)
      .subscribe((result) => {
        this.fixedassests = result["response"];
        
      });
  }

  getCoaData() {
    let in_condition: any = [];

    in_condition = [
      ConstantService.coa_heads.Loans_Liabilities,
      ConstantService.coa_heads.Secured_Loan,
      ConstantService.coa_heads.Unsecured_Loan,
      // ConstantService.coa_heads.Discount,
      // ConstantService.coa_heads.Assets,
      // ConstantService.coa_heads.Liabilities,
      // ConstantService.coa_heads.Equity,
      // ConstantService.coa_heads.Revenue,
      // ConstantService.coa_heads.Expenses,
      // ConstantService.coa_heads.Bank,
      // ConstantService.coa_heads.Cash,
      // ConstantService.coa_heads.Taxes_Payable,
      // ConstantService.coa_heads.Taxes_Receivable,
      // ConstantService.coa_heads.Accounts_Receivable,
      // ConstantService.coa_heads.Accounts_Payable,
      // ConstantService.coa_heads.Transport_Charges,
      // ConstantService.coa_heads.Other_Income,
      // ConstantService.coa_heads.INCOME_FROM_SERVICES,
      // ConstantService.coa_heads.INCOME_FROM_SALE_OF_GOODS,
      // ConstantService.coa_heads.Materials_Purchases,
      // ConstantService.coa_heads.Purchase_Accounts,
      // ConstantService.coa_heads.Opening_Inventories,
      // ConstantService.coa_heads.Direct_Expenses,
      // ConstantService.coa_heads.INDIRECT_EXPENSES,
      // ConstantService.coa_heads.Loans_Liabilities,
      // ConstantService.coa_heads.Secured_Loan,
      // ConstantService.coa_heads.Unsecured_Loan,
      // ConstantService.coa_heads.Direct_Incomes,
      // ConstantService.coa_heads.Rent,
      // ConstantService.coa_heads.Repairs_Maintenance,
      // ConstantService.coa_heads.Insurance,
      // ConstantService.coa_heads.Research_Development_Expenses,
      // ConstantService.coa_heads.Finance_Charges_Banking_Charges,
    ];
    // in_condition = [ConstantService.coa_heads.PURCHASE_DIRECT_EXPENSES];

    // in_condition = [ConstantService.coa_heads.Purchase_Accounts];
    console.log("in_condition||||||", in_condition);
    this.getLedgers("", in_condition).subscribe((result) => {
      this.ledger_lists = result["response"];
      this.all_account_heads = result["response"];
      // this.addRemoveDiscountHead();
    });
  }
  getLedgers(searchString, in_condition) {
    // if (searchString == null) {
    //   searchString = "";
    //   in_condition = [ConstantService.coa_heads.PURCHASE_DIRECT_EXPENSES];
    // }
    this.ledger_lists = [];
    // let condition: any = {};
    // let columns: any = {};
    console.log("in_condition=====", in_condition);
    return this.mastersService.getCoaHead(this.company_id, searchString, {
      in: in_condition,
      is_parent: false,
    });
  }
  getLedgersSearch() {
    this.ledgerListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getLedgers(searchString, ""))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.ledger_lists = result["response"]["data"];
        },
        (err) => {
          console.log(err);
          this.ledger_lists = [];
        }
      );
  }
  saveFixedAssests() {
    if (this.is_edit_form === true) {
      this.editFixedAssests();
    } else {
      this.addFixedAssests();
    }
  }
  addFixedAssests() {
    this.loading = true;
    if (this.company_id != "") {
      this.fixedassests.company_id = this.company_id;
      this.fixedAssestsService
        .addFixedAssests(this.fixedassests)
        .subscribe((result) => {
          if (result["status_code"] === 1) {
            this.loading = false;
            swal(
              "Added fixedassests sucessfully!",
              "Fixed Assets added.",
              "success"
            );
            this.router.navigateByUrl("/fixed_assets/viewall");
          } else {
            this.showErrors(result["response"]);
          }
        });
    }
  }

  showErrors(errors) {
    let error_string: string;
    error_string = " ";
    errors.forEach((item) => {
      error_string += " < br / > " + item;
    });
    swal({
      title: "Error",
      html: error_string,
      type: "error",
    }).then(function () {});
  }

  editFixedAssests() {
    this.loading = true;
    console.log("YYYYY");
    this.fixedAssestsService
      .editFixedassests(this.fixedassests, this.id)
      .subscribe((result) => {
        if (result["response"]["status_code"] == 1) {
          this.loading = false;
          swal("Success!", "FixedAssets Updated Successfully", "success");
          this.router.navigateByUrl("/fixed_assets/viewall");
        } else {
          this.showErrors(result["response"]["errors"]);
        }
      });
  }
}
