<div class="col-12 mb-4">
  <!-- <a class="btn btn-primary float-right" routerLink="/support-ticket">Add ticket</a> -->
  <!-- <button (click)="syncTickets()" class="btn btn-info float-right mr-2"><i class="fe fe-refresh-cw"></i></button> -->
  <!-- <app-report-filters (getFilterData)="search($event)" [filters]="tableColumns" [name]="'Support Tickets'">
  </app-report-filters> -->
</div>
<!-- 
<div class="col-12">
    
</div> -->

<div class="mb-5">
  <datalytics-tabular-report  [data]="paymenttransactionsList" [pageSize]="10" [columns]="tableColumns"
    (pageChange)="pageChange($event)" (sortChange)="applySort($event)"></datalytics-tabular-report>
</div>
