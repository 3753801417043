import { Contact } from './company.contact.model';
import { Warehouse } from './warehouse.model';
import { Country } from './country.model';

export class Branch {
    name: string;
    address: string;
    gst: string;
    is_gst_filing: Boolean = false;
    gst_effective_date: any;
    state: string;
    city: string;
    country: Country = new Country();
    is_warehouse: Boolean = false;
    contacts: Contact[] = [];
}
