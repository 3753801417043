import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";

@Injectable({ providedIn: "root" })
export class VoucherTypeService {
  constructor(private http: ApiService) {}

  addVoucherType(voucherType_data: any) {
    return this.http.post(
      ApiService.geturl("voucher_type", "add"),
      voucherType_data
    );
  }
  editVoucherType(voucherType_data: any, id: string) {
    return this.http.post(ApiService.geturl("voucher_type", "edit"), {
      data: voucherType_data,
      voucherType_id: id,
    });
  }
  deleteVoucherType(id: string) {
    return this.http.post(ApiService.geturl("voucher_type", "delete"), {
      _id: id,
    });
  }

  viewVoucherType(id: string, voucherCategoryCode) {
    return this.http.post(ApiService.geturl("voucher_type", "view"), {
      voucherType_id: id,
      voucherCategoryCode,
    });
  }

  getVoucherType(
    filters: any = {},
    sort_by: any = {},
    columns: any = {},
    page: any = {}
  ) {
    return this.http.post(ApiService.geturl("voucher_type", "viewall"), {
      filters: filters,
      sort_by: sort_by,
      columns: columns,
      page: page,
    });
  }
}
