<form novalidate #searchProductForm="ngForm" (ngSubmit)="getPaginatedProduct({ offset: 0, pageSize: 10 })">
  <div class="card">
    <div class="card-body">
      <div class="btn-group mr-3 mb-2 float-right">
        <button type="button" class="btn btn-primary" rel="tooltip" routerLink="/product/add/item">Add
          Product
        </button>
        <button type="button" class="btn btn-primary  ml-2" (click)="open(importProduct)"> <i class="fa fa-upload"></i>
          Import
          product</button>
        <div class="btn-group" ngbDropdown container="body" aria-label="Button group with nested dropdown"
          *ngIf="company_category.type==3" role="group">
          <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button rel="tooltip" class="dropdown-item" ngbDropdownItem routerLink="/product/add/composite_item">
              <i class="icon icon-add"></i>Add Composite Product
            </button>
          </div>
        </div>
      </div>
      <h3 class="table-title"><i class="fa fa-cube"></i> Products</h3>
      <div class="card-body row">
        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
          <label>Product</label>
          <input type="text" name="name" class="form-control" placeholder="Product Name" [(ngModel)]="filters.name">
        </div>
        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
          <label>SKU</label>
          <input type="text" name="sku" class="form-control" placeholder="Sku" [(ngModel)]="filters.sku">
        </div>
        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
          <label>HSN Code</label>
          <input type="text" name="code" class="form-control" placeholder="HSN Code" [(ngModel)]="filters.code">
        </div>
        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
          <label for="company_category">Goods/Services</label>
          <ng-select [items]="company_categories" #company_category="ngModel" name="company_category" bindLabel="name"
            [(ngModel)]="filters.company_category" placeholder="Select Goods/Services">
          </ng-select>
        </div>

      </div>

      <div>
        <button type="submit" class="btn btn-primary mr-2"> <i class="fa fa-search"></i> Search</button>
        <button type="button" (click)="clearProductFilters()" class="btn btn-danger">
          <i class="fa fa-close"></i> Clear
        </button>

      </div>
    </div>
  </div>

  <ng-template #importProduct let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Upload file</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div style="cursor: pointer;" ngfDrop selectable="1" [(validDrag)]="validComboDrag" [(file)]="file"
          [(lastInvalids)]="lastInvalids" class="well my-drop-zone"
          accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          [class.invalid-drag]="validComboDrag===false" [class.valid-drag]="validComboDrag"
          (filesChange)="lastFileAt=getDate()">
          Drop/select <b>CSV</b> here...
          <!-- {{dataSource.name}} -->
        </div>
        <div style="margin-bottom: 40px" class="mt-2">
          <table class="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Size</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>{{ file.name }}</strong>
                </td>
                <td nowrap>
                  {{ file.type }}
                </td>
                <td nowrap>
                  {{ file.size/1024/1024 | number:'.2' }} MB
                </td>
                <td nowrap>
                  <button type="button" class="btn btn-danger btn-xs" (click)="file={}">
                    <span class="fa fa-trash"></span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-primary" [disabled]="" (click)="uploadFile()">Save</button>
    </div>
  </ng-template>

  <!-- <ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Upload File</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <label>File Upload </label>
      <div ngfDrop multiple="1" selectable="1" [(validDrag)]="validComboDrag" [(files)]="files"
        [(lastInvalids)]="lastInvalids" class="well my-drop-zone" accept="image/*,application/pdf"
        [class.invalid-drag]="validComboDrag===false" [class.valid-drag]="validComboDrag"
        (filesChange)="lastFileAt=getDate()">
        Drop/select <strong>Image/Pdf</strong> here... (PNG, JPEG, PDF)
      </div>
      <div class="bg-warning" *ngIf="lastInvalids?.length" style="margin-bottom: 40px">
        <h3 style="color:red;">Last {{ lastInvalids.length }} Invalid Selected Files</h3>
  
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Error</th>
              <th>Type</th>
              <th>Size</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of lastInvalids;let i=index">
              <td>
                
                <strong>{{ item.file.name }}</strong>
              </td>
              <td nowrap>
                {{ item.type }}
              </td>
              <td nowrap>
                {{ item.file.type }}
              </td>
              <td nowrap>
                {{ item.file.size/1024/1024 | number:'.2' }} MB
              </td>
              <td nowrap>
                <button type="button" class="btn btn-danger btn-xs" (click)="lastInvalids.splice(i,1)">
                  <span class="glyphicon glyphicon-trash"></span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="margin-bottom: 40px" class="mt-2">
        <h5>{{ files?.length }} Queued Files</h5>
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Size</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of files;let i=index">
              <td>
                
                <strong>{{ item.name }}</strong>
              </td>
              <td nowrap>
                {{ item.type }}
              </td>
              <td nowrap>
                {{ item.size/1024/1024 | number:'.2' }} MB
              </td>
              <td nowrap>
                <button type="button" class="btn btn-danger btn-xs" (click)="files.splice(i,1)">
                  <span class="fa fa-trash"></span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div> 
    </div>
    <div class="modal-footer">
      <button type="button" [disabled]="files.length == 0" class="btn btn-outline-dark"
        (click)="uploadFile()">Save</button>
    </div>
  </ng-template> -->
</form>

<div class="card p-sm-0">
  <div class="card-body">
    <ngx-datatable [rows]="productList" [loadingIndicator]="loadingIndicator" [externalPaging]="true"
      [columnMode]="'force'" [count]="count" [limit]="page.pageSize" [offset]="page.offset"
      (page)='getPaginatedProduct($event)' [externalSorting]=true (sort)="onSort($event)" [footerHeight]="50"
      [rowHeight]="'auto'" class="material striped" [sorts]="this.dt_default_sort">


      <ngx-datatable-column *ngFor="let col of productListColumns|filter:'is_detailed_row':false;"
        [flexGrow]="col.flexGrow" [name]="col.name" [sortable]="col.sortable" [prop]="col.prop">
        <ng-template ngx-datatable-header-template let-sort="sortFn">
          <span (click)="sort()">{{ col.name }}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div [ngSwitch]="col.type">

            <!-- <span *ngSwitchCase="'date'">
                      {{ row[col.prop]| date }}
                  </span> -->

            <span *ngSwitchCase="'action'">
              <div class="button-row">
                <button class="btn btn-primary mr-2"
                  *ngxPermissionsOnly="['Elixir - Admin', 'Elixir - Manager', 'Client - Admin', 'Client - Manager']"
                  routerLink="/product/edit/{{row._id}}" mat-mini-fab color="primary" title="Edit">
                  <i class="fa fa-edit"></i>
                </button>

                <button class="btn btn-danger"
                  *ngxPermissionsOnly="['Elixir - Admin', 'Elixir - Manager', 'Client - Admin', 'Client - Manager']"
                  (click)="showDeleteProductConfirmDialog(row._id)" mat-mini-fab color="warn" title="Delete">
                  <i class="fa fa-trash"></i>
                </button>
              </div>

            </span>
            <span *ngSwitchCase="'goodsservice'">
              {{row[col.prop] == 1 ? 'Goods' : 'Service'}}
            </span>
            <span *ngSwitchDefault>
              {{ row[col.prop] }}
            </span>
          </div>

        </ng-template>
      </ngx-datatable-column>

    </ngx-datatable>
  </div>
</div>