import { Injectable } from "@angular/core";
import { ApiService } from "../_services/api.service";

@Injectable()
export class TransactionService {
    constructor(private http: ApiService) { }

    getProductsQuantity(
        filter: any = {},

    ) {
        return this.http.post(ApiService.geturl("transaction", "quantity"), {
            filter: filter,

        });
    }
}