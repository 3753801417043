import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import swal from "sweetalert2";
import { PurchaseorderService } from "../../../_services/purchaseorder.service";
import { ProductService } from "../../../_services/product.service";
import { VendorService } from "../../../_services/vendor.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { Purchaseorder } from "../../../_models/purchaseorder";
import { PurchaseOrderItem } from "../../../_models/purchaseorder-items";
import { MastersService } from "../../../_services/masters.service";
import { CompanyService } from "../../../_services/company.service";
import { AuthenticationService } from "../../../_services/authentication.service";
import { SequenceService } from "../../../_services/sequence.service";
import { HsnService } from "../../../_services/hsn.service";
import { UtilsService } from "../../../_services/utils.service";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { ConstantService } from "../../../_config/constants";
import { differenceInDays } from "date-fns";
@Component({
  selector: "app-purchaseorder-add",
  templateUrl: "./purchaseorder-add.component.html",
  styleUrls: ["./purchaseorder-add.component.scss"],
})
export class PurchaseorderAddComponent implements OnInit {
  loading = false;
  due_date: any;
  vendor: any;
  purchaseorder_date: any;
  dueDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };
  header: string;
  purchaseorderDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    disableSince: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };
  myDateInit: boolean = true;
  model: IMyDateModel = null;
  id: string;
  is_edit_form: boolean = false;
  purchaseorder: Purchaseorder = new Purchaseorder();
  company_id: string;
  branch_id: string;
  currency_list: any;
  company_details: any;
  purchaseOrderItem: PurchaseOrderItem = new PurchaseOrderItem();
  vendor_lists: Object;
  vendorListTypeahead = new EventEmitter<string>();
  sequence_no: any;
  sequence_id: any;
  product_lists: Object;
  productListTypeahead = new EventEmitter<string>();

  account_head_list: any = [{ name: "Sundry Debitors", type: 1 }];
  total_amount: any;
  is_credit_note: boolean = false;
  is_same_state: boolean = false;
  currency: string;
  is_debit_note: boolean = false;
  purchaseorder_number: any;
  selectFiYear: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private purchaseorderservice: PurchaseorderService,
    private mastersService: MastersService,
    private companyService: CompanyService,
    private sequenceService: SequenceService,
    private authenticationService: AuthenticationService,
    private productService: ProductService,
    private hsnService: HsnService,
    private vendorService: VendorService,
    private utilsService: UtilsService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.purchaseorder.vendor = null;
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.currency = this.authenticationService.getCompanyDetails()[
      "base_currency"
    ];
    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];
    this.getCompanyDetails();
    this.getVendors("").subscribe((result) => {
      this.vendor_lists = result["response"]["data"];
    });
    this.getVendorsSearch();
    this.addNewItem();
    this.getProducts("").subscribe((result) => {
      this.product_lists = result;
    });
    this.getProductSearch();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get("id");
      if (this.id != null) {
        this.getPurchaseorder();
      }

      var result = this.router.url.match("/edit/");
      if (result != null) {
        if (result[0] === "/edit/" && this.id != null) {
          this.is_edit_form = true;
          this.header = "Edit";
        }
      } else {
        this.header = "";
        let today = differenceInDays(new Date(this.selectFiYear.end_year, 2, 31), new Date()) > 0 && differenceInDays(new Date(), new Date(this.selectFiYear.start_year, 3, 1)) > 0 ? new Date() : new Date(this.selectFiYear.end_year, 2, 31);
        this.purchaseorder.purchase_order_date = {
          isRange: false,
          singleDate: {
            jsDate: today,
          },
        };
        this.purchaseorder.due_date = {
          isRange: false,
          singleDate: {
            jsDate: today,
          },
        };
      }
    });
  }
  setCurrency(currency) {
    this.purchaseorder.currency = currency;
  }

  //   this.getPurchaseordersList();

  addPurchaseorder() {
    this.loading = true;
    if (this.company_id != "") {
      this.purchaseorder.company_id = this.company_id;
      this.purchaseorderservice
        .addPurchaseorder(this.purchaseorder)
        .subscribe((result) => {

          // console.log('result', this.purchaseorder);
          // console.log('result', result); return false;
          if (result["status_code"] === 1) {
            this.loading = false;
            this.updateSequence();
            swal(
              "Added purchaseorder sucessfully!",
              "purchaseorder added.",
              "success"
            );
            this.router.navigateByUrl("/purchase-order/list");
          } else {
            this.showErrors(result["response"]);
          }
        });
    }
  }

  getPurchaseorder() {
    let condition: any = {};
    condition["company_id"] = this.company_id;
    this.purchaseorderservice
      .viewPurchaseorder(this.id, condition)
      .subscribe((result) => {
        this.purchaseorder = result["response"];
        if (this.is_edit_form) {
          this.purchaseorder.purchase_order_date = {
            isRange: false,
            singleDate: {
              jsDate: new Date(this.purchaseorder.purchase_order_date),
            },
          };
          this.purchaseorder.due_date = {
            isRange: false,
            singleDate: {
              jsDate: new Date(this.purchaseorder.due_date),
            },
          };
        }
        this.purchaseorder.purchase_order_items.forEach((element) => {
          this.getAmount(element);
        });
        this.getTotalAmount();
      });
  }

  addNewItem() {
    this.purchaseorder.purchase_order_items.push({
      product: null,
      description: "",
      units: 1,
      price: 0,
      amount: 0,
      actual_amount: 0,
      tax_amount: 0,
      total_amount: 0,
      discount: 0,
      is_billed: 1,
    });
  }

  deleteItem(index) {
    if (index != 0) {
      this.purchaseorder.purchase_order_items.splice(index);
    }
  }

  getProducts(searchString) {
    this.product_lists = [];
    let columns = {
      name: 1,
      purchase: 1,
      type: 1,
      sku: 1,
      unit: 1,
      code: 1,
      hsn_muid: 1,
      group_id: 1,
      group_name: 1,
    };
    let condition: any = {};
    if (searchString != "") {
      condition["name"] = searchString;
    }
    condition["company_id"] = this.company_id;
    condition["is_purchase"] = true;
    return this.productService.getAllProducts(condition, "all", columns);
  }

  getProductSearch() {
    this.productListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getProducts(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.product_lists = result;
        },
        (err) => {
          console.log(err);
          this.product_lists = [];
        }
      );
  }

  getCompanyDetails() {
    this.companyService.viewCompany(this.company_id).subscribe((result) => {
      console.log("=======sadsad=======", this.id);

      this.company_details = result["response"];

      console.log("this.company_details", this.company_details);

      if (this.id == "" || this.id == null) {
        this.getNextSequence();
      }
      this.purchaseorderDateOptions.dateFormat = this.company_details.date_format;
      this.dueDateOptions.dateFormat = this.company_details.date_format;
      let book_beginning_date = new Date(this.company_details.book_beginning);
      book_beginning_date.setDate(book_beginning_date.getDate() - 1);
      let currentDueDateOptions = this.getCopyOfOptions();
      currentDueDateOptions.disableUntil = {
        year: book_beginning_date.getFullYear(),
        month: book_beginning_date.getMonth() + 1,
        day: book_beginning_date.getDate(),
      };
      this.dueDateOptions = currentDueDateOptions;
      let currentPurchaseOrderDtOptions = this.getCopyOfOptionsDue();
      // currentPurchaseOrderDtOptions.disableUntil = {
      //   year: book_beginning_date.getFullYear(),
      //   month: book_beginning_date.getMonth() + 1,
      //   day: book_beginning_date.getDate(),
      // };
      currentPurchaseOrderDtOptions.disableUntil = { year: this.selectFiYear.start_year, month: 3, day: 31 };
      currentPurchaseOrderDtOptions.disableSince = { year: this.selectFiYear.end_year, month: 4, day: 1 };
      this.purchaseorderDateOptions = currentPurchaseOrderDtOptions;
      this.dueDateOptions = currentDueDateOptions;
      this.purchaseorder.purchase_order_date = this.purchaseorderDateOptions;
      this.purchaseorder.purchase_order_date = {
        isRange: false,
        singleDate: {
          jsDate: new Date(),
        },
      };
      this.purchaseorder.due_date = this.dueDateOptions;
      this.purchaseorder.due_date = {
        isRange: false,
        singleDate: {
          jsDate: new Date(),
        },
      };
    });
  }

  getCopyOfOptions(): IAngularMyDpOptions {
    return JSON.parse(JSON.stringify(this.purchaseorderDateOptions));
  }
  getCopyOfOptionsDue(): IAngularMyDpOptions {
    return JSON.parse(JSON.stringify(this.dueDateOptions));
  }

  addUpdatePurchaseorder() {
    if (this.is_edit_form === true) {
      this.editPurchaseorder();
    } else {
      this.addPurchaseorder();
    }
  }

  editPurchaseorder() {
    this.loading = true;
    this.purchaseorderservice
      .editPurchaseorder(this.purchaseorder, this.id)
      .subscribe((result) => {
        if (result["response"]["status_code"] == 1) {
          this.loading = false;
          swal("Success!", "Purchaseorder Updated Successfully", "success");
          this.router.navigateByUrl("/purchase-order/list");
        } else {
          this.showErrors(result["response"]["errors"]);
        }
      });
  }

  showErrors(errors) {
    let error_string: string;
    error_string = " ";
    errors.forEach((item) => {
      error_string += " < br / > " + item;
    });
    swal({
      title: "Error",
      html: error_string,
      type: "error",
    }).then(function () { });
  }

  getVendors(searchString) {
    this.vendor_lists = [];
    let condition: any = {};
    let columns: any = {};
    if (searchString != "") {
      condition["vendor_name"] = searchString;
    }
    condition["company_id"] = this.company_id;
    columns["vendor_name"] = 1;
    columns["first_name"] = 1;
    columns["last_name"] = 1;
    columns["currency"] = 1;
    columns["address_line1"] = 1;
    columns["address_line2"] = 1;
    columns["payment_term"] = 1;
    columns["valid_upto"] = 1;
    columns["country"] = 1;
    columns["state"] = 1;
    columns["phone"] = 1;
    columns["gst_number"] = 1;
    columns["coa_data"] = 1;
    columns["tds"] = 1;
    columns["month_total_amount"] = 1;
    columns["annual_total_amount"] = 1;
    columns["pan"] = 1;
    return this.vendorService.getVendors(condition, columns, {}, { limit: 10 });
  }
  getVendorsSearch() {
    console.log("sadasdasd");
    this.vendorListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getVendors(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.vendor_lists = result["response"]["data"];
        },
        (err) => {
          console.log(err);
          this.vendor_lists = [];
        }
      );
  }

  getAmount(itemsObj) {
    itemsObj.amount = 0;
    // console.log('itemsobj------>', itemsObj)
    let actual_amount: any;
    let tax_amount: any;
    let discount: any;
    actual_amount = itemsObj.units * itemsObj.price;
    itemsObj.actual_amount = actual_amount;
    if (itemsObj.tax > 0) {
      tax_amount = (actual_amount / 100) * itemsObj.tax;
      itemsObj.amount = actual_amount + tax_amount;
    } else {
      itemsObj.amount = actual_amount;
    }
    itemsObj.amount = parseFloat(itemsObj.amount.toFixed(2));
    itemsObj.tax_amount = parseFloat(itemsObj.tax_amount.toFixed(2));
    itemsObj.actual_amount = parseFloat(itemsObj.actual_amount.toFixed(2));
    this.getTotalAmount();
    // discount = (actual_amount / 100) * itemsObj.discount;
    // if (itemsObj.discount == undefined || itemsObj.discount == "") {

    // console.log('itemsObj.amount', itemsObj.amount, this.total_amount)
    // }
    // else {
    // console.log('inside else', itemsObj)
    // itemsObj.amount = actual_amount - discount + tax_amount;
    // }
    // console.log('itemsObj.amount', itemsObj, this.total_amount)
  }

  getTotalAmount() {
    this.purchaseorder.total_amount = 0;
    this.purchaseorder.balance_amount = 0;
    this.purchaseorder.total_tax_percentage = 0;
    this.purchaseorder.total_tax_amount = 0;
    this.purchaseorder.purchase_order_items.forEach((element) => {
      this.purchaseorder.total_amount += element["amount"];
      this.purchaseorder.balance_amount += element["amount"];
      this.purchaseorder.total_tax_amount += element["tax_amount"];
      this.purchaseorder.total_tax_percentage += element["tax"];
      this.purchaseorder.actual_total_amount =
        this.purchaseorder.total_amount - this.purchaseorder.total_tax_amount;
      if (this.purchaseorder.vendor) {
        if (
          this.purchaseorder.vendor.state["zone_id"] ==
          this.company_details.state["zone_id"]
        ) {
          this.is_same_state = true;
          this.purchaseorder.cgst = this.purchaseorder.total_tax_amount / 2;
          this.purchaseorder.sgst = this.purchaseorder.total_tax_amount / 2;
        } else {
          this.is_same_state = false;
          this.purchaseorder.igst = this.purchaseorder.total_tax_amount;
        }
      }
      // console.log('amount in loop', element['amount']);
      // console.log('this.total_amount in loop', this.total_amount);
    });

    this.purchaseorder.total_tax_amount = parseFloat(
      this.purchaseorder.total_tax_amount.toFixed(2)
    );
    this.purchaseorder.actual_total_amount = parseFloat(
      this.purchaseorder.actual_total_amount.toFixed(2)
    );
    this.purchaseorder.cgst = parseFloat(this.purchaseorder.cgst.toFixed(2));
    this.purchaseorder.sgst = parseFloat(this.purchaseorder.sgst.toFixed(2));
    this.purchaseorder.igst = parseFloat(this.purchaseorder.igst.toFixed(2));
    this.purchaseorder.total_amount = parseFloat(
      this.purchaseorder.total_amount.toFixed(2)
    );
  }

  getTaxAmount(itemsObj) {
    itemsObj.tax_amount = 0;
    let actual_amount: any;
    let tax_amount: any;
    actual_amount = itemsObj.units * itemsObj.price;
    if (this.company_details.is_gst_filing == true) {

      if (itemsObj.tax > 0) {

        tax_amount = (actual_amount / 100) * itemsObj.tax;
        itemsObj.tax_amount = tax_amount;
      }
    }
    this.getAmount(itemsObj);
  }

  getPriceFromHSN(product_data, itemsObj) {
    // console.log('product_data', product_data);
    if (product_data != null) {
      let condition: any = {};
      let sort: any = {};
      sort["effects_from"] = "desc";
      condition["_id"] = product_data["hsn_muid"];
      condition["effects_from_is_active"] = true;
      // console.log('condition', condition)
      console.log("condition", product_data);
      let data = {};

      this.hsnService.getHSNByCondition(condition, sort).subscribe((result) => {
        if (result["response"][0] != undefined) {
          result["response"][0]["effects_from"].filter((e) => {
            if (e.is_active) {
              data = e;
              itemsObj["tax"] = e.igst;
              itemsObj["sgst"] = e.sgst;
              itemsObj["cgst"] = e.cgst;
              itemsObj["igst"] = e.igst;
              this.getTaxAmount(itemsObj);
            }
          });
        } else {
          itemsObj["tax"] = 0;
        }
        // console.log('data', itemsObj);
      });
    } else {
      itemsObj["tax"] = 0;
    }
  }

  getNextSequence() {
    let sales_sequence: any = {};
    sales_sequence["seq_name"] = "purchaseorder";
    sales_sequence["company_id"] = this.company_id;
    this.sequenceService.viewSequence(sales_sequence).subscribe((result) => {
      //console.log("=====resultseq_no", result);
      if (result["response"] != "" && result["response"] != null) {
        if (result["response"]["seq_no"]) {
          this.purchaseorder_number = result["response"]["seq_no"]
            .toString()
            .padStart(this.company_details["purchase_column_settings"]["ordernumber_length"], 0);
          this.purchaseorder.purchase_order_number =
            this.company_details["purchase_column_settings"][
            "purchase_order_prefix"
            ] +
            this.purchaseorder_number +
            this.company_details["purchase_column_settings"][
            "purchase_order_suffix"
            ];
          this.sequence_no = result["response"]["seq_no"];
          this.sequence_id = result["response"]["_id"];
        } else {
          swal(
            "Update Settings!",
            "Update Purchase Order Settings In Company And Then Add PO",
            "warning"
          );
          this.router.navigateByUrl("/company/edit/" + this.company_id);
        }
      } else {
        console.log("2------------");
        swal(
          "Update Settings!",
          "Update Purchase Order Settings In Company And Then Add PO",
          "warning"
        );
        this.router.navigateByUrl("/company/edit/" + this.company_id);
      }
    });
  }

  updateSequence() {
    let sequence_data: any = {};
    let sequence_muid: any = {};
    sequence_data["seq_no"] = parseInt(this.sequence_no) + 1;
    sequence_muid["muid"] = this.sequence_id;
    sequence_data["company_id"] = this.company_id;
    this.sequenceService
      .editSequence(sequence_data, sequence_muid["muid"])
      .subscribe((result) => { });
  }
}
