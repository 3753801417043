<div class="card">
  <div class="card-header d-flex align-items-center">
    <h3 class="table-title">Sales Order View</h3>
  </div>
  <div class="card-body">
    <div>
      <form class="form-horizontal" novalidate #saveSalesOrder="ngForm">
        <div class="form-row view">
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="customer">Customer</label>
            <!-- <ng-select *ngIf="!is_debit_note" [items]="customer_lists" [typeahead]="customerListTypeahead"
#customer="ngModel"
(change)="setBillShipAddress();setCurrency(sales_order.customer.currency);getTotalAmount();" required
name="customer" bindLabel="customer_name" [(ngModel)]="sales_order.customer"
placeholder="Select Customer">
<ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
<div [ngOptionHighlight]="search">
{{ item.customer_name }}
</div>
</ng-template>
</ng-select> -->
            <!-- <app-validation-errors [mformField]="customer" label="Customer"></app-validation-errors> -->
            <div>
              {{sales_order?.customer?.customer_name}}
            </div>
          </div>

          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="currency">Currency</label>
            <!-- <select *ngIf="!is_debit_note" class="form-control" name="currency" id="currency"
[(ngModel)]="sales_order.currency">
<option *ngFor="let currency of sales_order?.customer?.currency" [ngValue]="currency">{{currency.name}}
</option>
</select> -->
            <div>
              {{sales_order.currency.name}}
            </div>
            <!-- <div>
              {{sales_order?.customer?.currency?.name}}
            </div> -->
          </div>

          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="sales_order_number">Sales Order Number</label>
            <div>{{sales_order.sales_order_number}}</div>
          </div>
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="sales_order_date">Sales Order Date</label>
            <!-- <div class="input-group" *ngIf="!is_debit_note">
<input class="form-control" name="sales_order_date" placeholder="Select a date" angular-mydatepicker
#dp_salesorder="angular-mydatepicker" (click)="dp_salesorder.toggleCalendar()"
[(ngModel)]="sales_order.sales_order_date" [options]="salesorderDateOptions" />
<div class="input-group-append">
<button type="button" class="btn btn-secondary" *ngIf="sales_order.sales_order_date"
(click)="dp_salesorder.clearDate()">
<i class="fa fa-close"></i>
</button>
</div>
<div class="input-group-append">
<button type="button" class="btn btn-secondary" (click)="dp_salesorder.toggleCalendar()">
<i class="fa fa-calendar-o"></i>
</button>
</div>
</div> -->
            <div>
              {{sales_order.sales_order_date|date}}
            </div>
          </div>
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="due_date">Due Date</label>
            <!-- <div class="input-group" *ngIf="!is_debit_note">
<input class="form-control" name="due_date" placeholder="Select a date" angular-mydatepicker
#dp_duedate="angular-mydatepicker" (click)="dp_duedate.toggleCalendar()"
[(ngModel)]="sales_order.due_date" [options]="dueDateOptions" />
<div class="input-group-append">
<button type="button" class="btn btn-secondary" *ngIf="sales_order.due_date"
(click)="dp_duedate.clearDate()">
<i class="fa fa-close"></i>
</button>
</div>
<div class="input-group-append">
<button type="button" class="btn btn-secondary" (click)="dp_duedate.toggleCalendar()">
<i class="fa fa-calendar-o"></i>
</button>
</div>
</div> -->
            <div>
              {{sales_order.due_date|date}}
            </div>
          </div>
          <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
<label for="billing_address">Billing Address</label> -->
          <!-- <textarea class="form-control" *ngIf="!is_debit_note" name="billing_address" id="billing_address"
placeholder="Billing Address" [(ngModel)]="sales_order.billing_address"></textarea> -->
          <!-- <div>
{{sales_order.billing_address}}
</div>
</div> -->
          <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
<label for="shipping_address">Shipping Address</label> -->
          <!-- <textarea class="form-control" *ngIf="!is_debit_note" name="shipping_address" id="shipping_address"
placeholder="Shipping Address" [(ngModel)]="sales_order.shipping_address"></textarea> -->
          <!-- <div>
{{sales_order.shipping_address}}
</div>
</div> -->
          <!-- <div *ngIf="company_details?.sales_column_settings?.delivery_note_display"
class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
<label for="delivery_note">Delivery Note</label>
<textarea class="form-control" *ngIf="!is_debit_note" name="delivery_note" id="delivery_note"
placeholder="Delivery Note" [(ngModel)]="sales_order.delivery_note"></textarea>
<div *ngIf="is_debit_note">
{{sales_order.delivery_note}}
</div>
</div> -->
          <!-- <div *ngIf="company_details?.sales_column_settings?.suppliers_ref_display"
class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
<label for="suppliers_ref">Suppliers Ref</label>
<textarea class="form-control" *ngIf="is_debit_note" name="suppliers_ref" id="suppliers_ref"
placeholder="Suppliers Ref" [(ngModel)]="sales_order.suppliers_ref"></textarea>
<div *ngIf="is_debit_note">
{{sales_order.suppliers_ref}}
</div>
</div> -->
          <!-- <div *ngIf="company_details?.sales_column_settings?.others_ref_display"
class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
<label for="others_ref">Others Ref</label>
<textarea class="form-control" *ngIf="is_debit_note" name="others_ref" id="others_ref"
placeholder="Others Ref" [(ngModel)]="sales_order.others_ref"></textarea>
<div *ngIf="is_debit_note">
{{sales_order.others_ref}}
</div>
</div> -->
          <!-- <div *ngIf="company_details?.sales_column_settings?.despatched_through_display"
class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
<label for="despatched_through">Despatched Through</label>
<input type="text" class="form-control" *ngIf="is_debit_note" name="despatched_through"
id="despatched_through" placeholder="Despatched Through" [(ngModel)]="sales_order.despatched_through">
<div *ngIf="is_debit_note">
{{sales_order.despatched_through}}
</div>
</div> -->
          <!-- <div *ngIf="company_details?.sales_column_settings?.destination_display"
class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
<label for="destination">Destination</label>
<input type="text" class="form-control" *ngIf="is_debit_note" name="destination" id="destination"
placeholder="Destination" [(ngModel)]="sales_order.destination">
<div *ngIf="is_debit_note">
{{sales_order.destination}}
</div>
</div> -->
          <!-- <div *ngIf="company_details?.sales_column_settings?.motor_vehicle_no_display"
class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
<label for="motor_vehicle_no">Motor Vehicle No</label>
<input type="text" class="form-control" *ngIf="is_debit_note" name="motor_vehicle_no" id="motor_vehicle_no"
placeholder="Motor Vehicle No" [(ngModel)]="sales_order.motor_vehicle_no">
<div *ngIf="is_debit_note">
{{sales_order.motor_vehicle_no}}
</div>
</div> -->
          <!-- <div *ngIf="company_details?.sales_column_settings?.eway_bill_no_display"
class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
<label for="eway_bill_no">Eway Bill No</label>
<input type="text" class="form-control" *ngIf="is_debit_note" name="eway_bill_no" id="eway_bill_no"
placeholder="Eway Bill No" [(ngModel)]="sales_order.eway_bill_no"> -->
          <!-- <div *ngIf="is_debit_note">
{{sales_order.eway_bill_no}}
</div> -->
          <!-- </div> -->
          <!-- <div *ngIf="company_details?.sales_column_settings?.eway_bill_date_display"
class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
<label for="eway_bill_date">Eway Bill Date</label>
<div class="input-group" *ngIf="is_debit_note">
<input class="form-control" name="eway_bill_date" placeholder="Select a date" angular-mydatepicker
#dp_eway_bill_date="angular-mydatepicker" [(ngModel)]="sales_order.eway_bill_date"
[options]="ewayBillDateOptions" />
<div class="input-group-append">
<button type="button" class="btn btn-secondary" *ngIf="sales_order.eway_bill_date"
(click)="dp_eway_bill_date.clearDate()">
<i class="fa fa-close"></i>
</button>
</div>
<div class="input-group-append">
<button type="button" class="btn btn-secondary" (click)="dp_eway_bill_date.toggleCalendar()">
<i class="fa fa-calendar-o"></i>
</button>
</div>

</div> -->

          <!-- <div *ngIf="is_debit_note">
{{sales_order.eway_bill_no"}}
</div> -->
          <!-- </div> -->
          <!-- <div class="form-group col-12 required col-sm-6 col-md-3 col-xl-3" *ngIf="is_debit_note">
<label for="debit_date">Debit Date</label>
<div class="input-group">
<input class="form-control" name="debit_date" placeholder="Select a date" angular-mydatepicker
#dp_debit_date="angular-mydatepicker" [(ngModel)]="sales_order.debit_date"
[options]="DebitDateOptions" />
<div class="input-group-append">
<button type="button" class="btn btn-secondary" *ngIf="sales_order.debit_date"
(click)="dp_debit_date.clearDate()">
<i class="fa fa-close"></i>
</button>
</div>
<div class="input-group-append">
<button type="button" class="btn btn-secondary" (click)="dp_debit_date.toggleCalendar()">
<i class="fa fa-calendar-o"></i>
</button>
</div>
</div>
</div> -->
          <!-- <div class="form-group col-12 required col-sm-6 col-md-3 col-xl-3" *ngIf="is_debit_note">
<label for="debit_notes">Debit Notes</label>
<textarea class="form-control" name="debit_notes" id="debit_notes" placeholder="Debit Notes"
[(ngModel)]="sales_order.debit_notes"></textarea>
</div> -->
          <div class="card">
            <div class="card-body form-row p-0 pb-3">
              <table class="col-12 table table-hover table-vcenter table_custom text-nowrap spacing5 text-nowrap mb-0">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>{{company_details.sales_column_settings.items}}</th>
                    <th>{{company_details.sales_column_settings.description}}</th>
                    <th>{{company_details.sales_column_settings.units}}</th>
                    <th>{{company_details.sales_column_settings.price}}</th>
                    <th *ngIf="sales_order.total_tax_amount>0">Tax</th>
                    <th *ngIf="sales_order.is_discount==true && sales_order.is_productwise_discount == true">
                      Discount</th>

                    <th *ngIf="sales_order.total_tax_amount>0">Tax Amount</th>
                    <th>{{company_details.sales_column_settings.amount}}</th>
                    <!-- <th>Action</th> -->
                  </tr>

                </thead>
                <tbody *ngFor="let itemsObj of sales_order.sales_order_items; let i = index">
                  <tr>
                    <td>
                      {{i+1}}
                    </td>
                    <td>
                      <!-- validateProduct(); -->
                      <!-- <ng-select *ngIf="!is_debit_note" [items]="product_lists" [typeahead]="productListTypeahead"
#product="ngModel" required name="itemsObj.product{{i}}" bindLabel="name"
[(ngModel)]="itemsObj.product"
(change)="getPriceFromHSN(itemsObj.product,itemsObj);getAmount(itemsObj);getProductQty(itemsObj.product,itemsObj);"
placeholder="Select product">
<ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index"
let-search="searchTerm">
<div [ngOptionHighlight]="search">
{{ item.name }}
</div>
</ng-template>
</ng-select> -->
                      <div>
                        {{itemsObj.product.name}}
                      </div>
                      <!-- <app-validation-errors [mformField]="product" label="Product"></app-validation-errors> -->

                    </td>
                    <td>
                      <!-- <input type="text" *ngIf="!is_debit_note" class="form-control" name="description{{i}}"
placeholder="Description" [(ngModel)]="itemsObj.description"> -->
                      <div>
                        {{itemsObj.description}}
                      </div>
                    </td>
                    <td>
                      <!-- <input type="number" [min]="1" class="form-control" #units="ngModel" required
name="itemsObj.units{{i}}" placeholder="Units"
(change)="getTaxAmount(itemsObj);getAmount(itemsObj);getProductQty(itemsObj.product,itemsObj)"
[(ngModel)]="itemsObj.units"> -->
                      <!-- <app-validation-errors [mformField]="units" label="Units"></app-validation-errors> -->
                      <div>
                        {{itemsObj.units}}
                      </div>
                    </td>
                    <td>
                      <!-- <input type="number" *ngIf="!is_debit_note" class="form-control" #price="ngModel" required
name="itemsObj.price{{i}}" placeholder="Price"
(change)="getTaxAmount(itemsObj);getAmount(itemsObj);getTotalAmount();"
[(ngModel)]="itemsObj.price"> -->
                      <!-- <app-validation-errors [mformField]="price" label="Price"></app-validation-errors> -->
                      <div>
                        {{itemsObj.price | currency: currency}}
                      </div>
                    </td>

                    <td *ngIf="sales_order.total_tax_amount>0"
                      title="sgst:{{itemsObj.sgst}} cgst:{{itemsObj.cgst}} igst:{{itemsObj.igst}}">
                      {{itemsObj.tax}}%
                      <!-- <input type="number" class="form-control" name="itemsObj.tax{{i}}" placeholder="Tax in percentage"
[(ngModel)]="itemsObj.tax" title="Tax in percentage"> -->
                    </td>
                    <!-- <td *ngIf="itemsObj.tax==undefined">
                      0%
                    </td> -->
                    <!-- <td *ngIf="company_details?.sales_column_settings?.discount_display && !is_debit_note">
                      <input type="number" class="form-control" name="itemsObj.discount{{i}}"
                        placeholder="Discount in percentage" (change)="getTaxAmount(itemsObj);getAmount(itemsObj);"
                        [(ngModel)]=" itemsObj.discount" title="Discount in percentage">
                      <span *ngIf="is_debit_note">
                        {{itemsObj.discount}}
                      </span>
                    </td> -->
                    <td *ngIf="sales_order.is_productwise_discount && sales_order.discount_in_percentage==false">
                      {{itemsObj.discount_in_amount | currency: currency}}
                    </td>
                    <td *ngIf="sales_order.discount_in_percentage">
                      {{itemsObj.discount_in_percentage}}%
                    </td>

                    <td *ngIf="sales_order.total_tax_amount>0">
                      {{itemsObj.tax_amount | currency: currency}}
                    </td>
                    <td>
                      {{itemsObj.amount | currency: currency}}
                    </td>

                    <!-- <td>
<button type="button" class="btn btn-danger" (click)="deleteItem(i)">
<i class="fa fa-trash" aria-hidden="true"></i>
</button>
</td> -->
                  </tr>

                </tbody>
              </table>
              <!-- <div>
<button type="button" *ngIf="!is_debit_note" class="btn btn-primary mr-2 ml-2"
(click)="addNewItem()">Add
New Item</button>

</div> -->
            </div>
            <!-- <div>
              <span class="mr-2 float-right">
                <b>Actual Total Amount: {{sales_order.actual_total_amount}}</b>
              </span>
              <br>
              <span class="mr-2 float-right">
                <b> CGST Amount: {{sales_order.cgst}}</b>
              </span>
              <br>
              <span class="mr-2 float-right">
                <b> SGST Amount: {{sales_order.sgst}}</b>
              </span>
              <br>
              <span class="mr-2 float-right">
                <b> IGST Amount: {{sales_order.igst}}</b>
              </span>
              <br>
              <span class="mr-2 float-right">
                <b> Total Tax Amount: {{sales_order.total_tax_amount}}</b>
              </span>
              <br>
              <span class="mr-2 float-right">
                <b>Total Amount: {{sales_order.total_amount}}</b>
              </span>
            </div> -->
            <div class="row justify-content-between">
              <div class="col-4"></div>
              <table class="float-right table col-4 final-table mt-4">
                <tbody>
                  <tr>
                    <th><b>Actual Total Amount</b></th>
                    <td>
                      <b>{{sales_order.actual_total_amount | currency: currency}}</b>
                    </td>
                  </tr>
                  <tr *ngIf="sales_order.is_discount==true">
                    <th><b>TOTAL DISCOUNT AMOUNT</b></th>
                    <td>
                      <b>{{sales_order.discount | currency: currency}}</b>
                    </td>
                  </tr>
                  <tr>
                    <th><b>SUB TOTAL AMOUNT</b></th>
                    <td>
                      <b>{{sales_order.sub_total_amount | currency: currency}}</b>
                    </td>
                  </tr>
                  <tr *ngIf="sales_order.cgst>0">
                    <th><b>CGST Amount</b></th>
                    <td>
                      <b>{{sales_order.cgst | currency: currency}}</b>
                    </td>
                  </tr>
                  <tr *ngIf="sales_order.sgst>0">
                    <th><b>SGST Amount</b></th>
                    <td>
                      <b>{{sales_order.sgst | currency: currency}}</b>
                    </td>
                  </tr>
                  <tr *ngIf="sales_order.igst>0">
                    <th><b>IGST Amount</b></th>
                    <td>
                      <b>{{sales_order.igst | currency: currency}}</b>
                    </td>
                  </tr>
                  <tr *ngIf="sales_order.total_tax_amount>0">
                    <th><b>Total Tax Amount</b></th>
                    <td>
                      <b>{{sales_order.total_tax_amount | currency: currency}}</b>
                    </td>
                  </tr>
                  <tr>
                    <th><b>Total Amount</b></th>
                    <td>
                      <b>{{sales_order.total_amount | currency: currency}}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- <button type="submit" class="btn btn-primary" [disabled]="!saveSalesOrder.valid">Save Sales Order</button> -->
      </form>
    </div>
  </div>
</div>