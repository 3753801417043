import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomerService } from '../../_services/customer.service';
import { AuthenticationService } from '../../_services/authentication.service';
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';
import { MastersService } from '../../_services/masters.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {
  filters: any = {};
  page: any = { offset: 0, pageSize: 10 };
  sort: any = { "customer_name": 1 };
  dt_default_sort = [{ prop: 'customer_name', dir: 'asc' }];
  limit = 10;
  customers: any = [];
  count: number;
  columns: any = {};
  loadingIndicator = false;
  reorderable = true;
  customerTableListColumns: any = [];
  // @ViewChild('customerTable', { static: true }) table: any;
  company_id: string;
  branch_id: string;
  modalReference: any;
  file: any = {};
  files: any=[];
  chart_of_accounts: any = {};
  flattened_data: any;
  accounts_receivable: any;
  assets_data: any;
  liability_data: any;
  constructor(
    private customerService: CustomerService,
    private authenticationService: AuthenticationService,
    public route: ActivatedRoute,
    private ngbModal: NgbModal,
    public router:Router,
    private mastersService: MastersService,
  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.customerTableListColumns = [
      {
        prop: 'customer_name',
        name: 'Customer Name',
        sortable: 'customer_name',
        flexGrow: 2,
        show: true,
        is_detailed_row: false
      },
      // {
      //   prop: 'first_name',
      //   name: 'First Name',
      //   sortable: 'first_name',
      //   flexGrow: 2,
      //   show: true,
      //   is_detailed_row: false
      // },
      //   {
      //   prop: 'currency.name',
      //   name: 'Currency',
      //   sortable: 'currency',
      //   flexGrow: 2,
      //   show: true,
      //   is_detailed_row: false
      // },
      {
        prop: 'gst_number',
        name: 'GST Number',
        sortable: 'gst_number',
        flexGrow: 2,
        show: true,
        is_detailed_row: false
      },
      {
        prop: 'mobile',
        name: 'Mobile',
        sortable: 'false',
        flexGrow: 2,
        show: true,
        is_detailed_row: false
      },
      {
        prop: 'email',
        name: 'Email',
        sortable: 'email',
        flexGrow: 2,
        show: true,
        is_detailed_row: false
      },
      {
        prop: 'receivables',
        name: 'Receivables',
        sortable: 'receivables',
        flexGrow: 2,
        show: true,
        is_detailed_row: false
      },
      {
        name: 'Action',
        type: 'action',
        prop: '_id',
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false
      }
    ];
    this.customerTableListColumns.forEach((column) => {
      this.columns[column.prop] = 1;
    });
    if (this.route.snapshot.queryParams['q'] != 'undefined')
      this.filters['customer_name'] = this.route.snapshot.queryParams['q'];
    this.getPaginatedCustomers(this.page);
    this.getCoa();
  }

  onSort(event) {
    this.loadingIndicator = true;
    // console.log('event', event);
    this.sort = {};
    let sort_details = event.sorts[0];
    this.sort[sort_details.prop] = sort_details.dir == 'asc' ? 1 : -1;
    this.getPaginatedCustomers({ offset: 0, pageSize: 10 });
  }

  clearCustomerFilters() {
    this.filters = {};
    this.getPaginatedCustomers({ offset: 0, pageSize: 10 });
  }

  getPaginatedCustomers(pageInfo: any = {}) {

    if (pageInfo !== null) {
      this.page = pageInfo;
    }
    this.filters['company_id'] = this.company_id;
    // this.sort['customer_name'] = 1;
    this.customerService.getCustomers(this.filters, this.columns, this.sort, this.page).subscribe(result => {
      // console.log('result',result);
      this.loadingIndicator = false;
      this.customers = result['response']['data'];
      this.count = result['response']['count'];
    });
  }

  open(content) {
    this.modalReference = this.ngbModal.open(content, { size: "lg" });
  }
  getDate() {
    return new Date()
  };
  uploadFile() {
    this.files=[];
    this.files.push(this.file);
    if (this.files.length > 0) {
      let data: any = {
        company_id: this.company_id,
        branch_id: this.branch_id,
      };

      this.customerService.uploadFiles(this.files, data).subscribe((event: any) => {
        console.log('result==>', event);
        if (event['total'] >0) {
          this.modalReference.close("importProduct");
          if (event.ids.length>0) {
            event.ids.forEach((id, i)=> {
              this.updateCoa(event['customer_name'][i], id);
            });
          }
          let error_string= '<p>'+event['success']+'/'+ event['total'] + ' Customer(s) Added Successfully ('+event['failure'] + ' failed)</p>';
          Object.keys(event['errors']).forEach(number=>{
            error_string+='<table width="100%" style="text-align:left;font-size:14px"><tr><td>Customer_'+number+'</td><td>'+event['errors'][number]+'</td></tr></table>'
          })
          swal("Success", error_string, "success");         
          this.router.navigate(["/party/list"]);
          this.files = [];
          this.file = {};
        }
        // }
      },
        error => swal('Error Uploading Files: ', error.message));
    } else {
      swal("Error", "No File Found To Upload", "error");
    }

  }


  getCoa() {
    this.mastersService.getCoa(this.company_id).subscribe(result => {
      this.chart_of_accounts = result['response']['chart_of_accounts'];
      this.flattened_data = result['response']['flattened_data'];
      this.chart_of_accounts.forEach(element => {
        if (element.id == 1000) {
          /**id-1000 => Assets */
          element['child'].forEach(value => {
            /**id-1017 => Current Assets */
            if (value.id == 1017) {
              value['child'].forEach(value2 => {
                /**id-1033 => Trade Receivable */
                if (value2.id == 1033) {
                  value2['is_ledger'] = false;
                  this.accounts_receivable = value2;
                }
              });

            }
          });
          this.assets_data = element;
        }
        if (element.id == 2000) {
          /**id-2000 => Liability */
          // element['child'].forEach(value => {
          /**id-2006 => Debit Notes */
          // if (value.id == 2006) {
          //   value['is_ledger'] = false;
          //   this.debit_notes = value;
          // }
          // });
          this.liability_data = element;
        }
      });
    })
  }


  //TODO move the update COA functionality to back end
  updateCoa(customer_name, customer_id) {
    if (this.assets_data['last_sequence'] + 1 < 2000) {
      let condition: any = {}
      condition['company_id'] = this.company_id;
      let account_receivable_data: any = {};
      let debit_notes_data: any = {};

      account_receivable_data['name'] = customer_name;
      account_receivable_data['ref_id'] = customer_id;
      account_receivable_data['id'] = this.assets_data['last_sequence'] + 1;
      this.assets_data['last_sequence'] = account_receivable_data['id'];
      account_receivable_data['is_editable'] = true;
      account_receivable_data['is_ledger'] = true;
      account_receivable_data['child'] = [];
      // if (this.liability_data['last_sequence'] + 1 < 3000) {
      //   debit_notes_data['name'] = customer_name;
      //   debit_notes_data['ref_id'] = customer_id;
      //   debit_notes_data['id'] = this.liability_data['last_sequence'] + 1;
      //   this.liability_data['last_sequence'] = debit_notes_data['id'];
      //   debit_notes_data['is_editable'] = true;
      //   debit_notes_data['is_ledger'] = true;
      //   debit_notes_data['child'] = [];
      //   //Pushing customer info in account receivable and debit notes head
      //   this.debit_notes.child.push(debit_notes_data);
      // }
      this.accounts_receivable.child.push(account_receivable_data);

      this.flattened_data.forEach(flatObj => {
        //Accounts Receivable flatten obj
        if (flatObj.id == 1033 && this.assets_data['last_sequence'] + 1 < 2000) {
          flatObj['is_ledger'] = false;
          let parent_name: any = [];
          let parent_id: any = [];
          parent_name.push(flatObj.name);
          parent_id.push(flatObj.id);
          flatObj.parent_name.forEach(parent_names => {
            parent_name.push(parent_names);
          });
          flatObj.parent_id.forEach(parent_ids => {
            parent_id.push(parent_ids);
          });
          this.flattened_data.push({
            name: customer_name,
            id: this.assets_data['last_sequence'],
            parent_name: parent_name,
            parent_id: parent_id,
            ref_id: customer_id,
            is_editable: true,
            is_ledger: true
          });
        }
        //Debit Notes flatten obj
        if (flatObj.id == 2006 && this.liability_data['last_sequence'] + 1 < 3000) {
          flatObj['is_ledger'] = false;
          let parent_name: any = [];
          let parent_id: any = [];
          parent_name.push(flatObj.name);
          parent_id.push(flatObj.id);
          flatObj.parent_name.forEach(parent_names => {
            parent_name.push(parent_names);
          });
          flatObj.parent_id.forEach(parent_ids => {
            parent_id.push(parent_ids);
          });
          this.flattened_data.push({
            name: customer_name,
            id: this.liability_data['last_sequence'],
            parent_name: parent_name,
            parent_id: parent_id,
            ref_id: customer_id,
            is_editable: true,
            is_ledger: true
          });
        }


      });
      // updating COA
      this.mastersService.updateCoa(condition, { chart_of_accounts: this.chart_of_accounts, flattened_data: this.flattened_data }).subscribe(result => { });
      // updating customer with the coa data of account receivable data
      this.customerService.editCustomer({ coa_data: account_receivable_data, debit_note_data: debit_notes_data }, customer_id).subscribe(result => { });
    }
  }
}
