import { Component, OnInit, Output, OnChanges, ChangeDetectorRef, ViewChild, TemplateRef, Input, EventEmitter } from '@angular/core';
import { AddressTemplate, AddressTemplateEmitConfig, ActionType } from '../../_models/address_template.model';
import { ContactAddress } from '../../_models/contact_address.model';
import { CustomerService } from '../../_services/customer.service';
import { VendorService } from '../../_services/vendor.service';
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { CountryService } from '../../_services/country.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import swal from "sweetalert2";
@Component({
  selector: 'app-address-template',
  templateUrl: './address-template.component.html',
  styleUrls: ['./address-template.component.scss']
})
export class AddressTemplateComponent implements OnChanges, OnInit {
  @ViewChild('actionTmpl', { static: true }) actionTmpl: TemplateRef<any>;
  @Input() public addressConfig: AddressTemplate;
  contactAddressList: ContactAddress[] = []
  contactAddress: ContactAddress = new ContactAddress();
  countryList: any = [];
  stateList: any = [];
  countryListTypeahead = new EventEmitter<string>();
  loadingIndicator = false;
  @Output() public updateAddress = new EventEmitter<AddressTemplateEmitConfig>();
  public addAddressModelRef: any;
  reorderable: boolean;
  result: any;
  addressListTableColumns: ({
    prop: string;
    name: string;
    sortable: string;
    show?: boolean;
    flexGrow?: number;
    type?: string;
    is_detailed_row?: boolean;
    cellTemplate?: TemplateRef<any>;
  })[];


  constructor(
    private vendorService: VendorService,
    private customerService: CustomerService,
    private cd: ChangeDetectorRef,
    // public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private countryService: CountryService
  ) { }


  ngOnInit() {
    this.addressListTableColumns = [
      {
        prop: 'first_name',
        name: 'First Name',
        sortable: 'first_name',
        flexGrow: 2,
        show: true,
        type: 'full_name',
        is_detailed_row: false
      },
      {
        prop: 'last_name',
        name: 'Last Name',
        sortable: 'last_name',
        flexGrow: 2,
        show: true,
        is_detailed_row: false
      },
      {
        prop: 'city',
        name: 'City',
        sortable: 'city',
        flexGrow: 2,
        show: true,
        is_detailed_row: false
      },
      {
        prop: 'state.name',
        name: 'State',
        sortable: 'state',
        flexGrow: 2,
        show: true,
        is_detailed_row: false
      },
      {
        prop: 'country.name',
        name: 'Country',
        sortable: 'country',
        flexGrow: 2,
        show: true,
        is_detailed_row: false
      },
      {
        prop: 'postal_code',
        name: 'Postal',
        sortable: 'postal_code',
        flexGrow: 2,
        show: true,
        is_detailed_row: false
      },
      {
        prop: '_id',
        name: 'Action',
        sortable: '',
        flexGrow: 2,
        show: true,
        cellTemplate: this.actionTmpl,
        is_detailed_row: false
      }
    ]
    if (this.addressConfig !== undefined) {
      if (this.addressConfig.action_type == 'add' || this.addressConfig.action_type == 'edit') {
        this.initializeAddressTemplate(this.addressConfig.action_type);
      }
    }
  }
  ngOnChanges() {
    if (this.addressConfig !== undefined) {
      console.log('=============>>>>>', this.addressConfig);
      this.initializeAddressTemplate(this.addressConfig.action_type);
    } else {
      this.addressConfig = new AddressTemplate();
      // this.getCountryData("").subscribe((result: any = []) => {
      //   this.countryList = result;
      // });
      // this.getCountrySearch();
    }

  }


  initializeAddressTemplate(action_type: ActionType) {
    switch (action_type) {
      case 'add':
        this.getCountryData("").subscribe((result: any = []) => {
          this.countryList = result;
        });
        this.getCountrySearch();
        break;

      case 'edit':
        this.getCountryData("").subscribe((result: any = []) => {
          this.countryList = result;
          this.getStateByCountry();
        });
        this.getCountrySearch();
        break;

      case 'view':
        break;

      case 'dropdown_viewall':
        this.getAddressList();
        break;

      case 'table_viewall':
        this.initiateAddressListTableColumns();
        this.getAddressList();
        break;
    }
  }


  getAddressList = () => {
    if (this.addressConfig.user_role == 'vendor') {
      this.vendorService.viewVendor(this.addressConfig.user_muid).subscribe(result => {
        this.contactAddressList = result['response']['contact_address'];
      });
    } else if (this.addressConfig.user_role == 'customer') {
      this.customerService.viewCustomer({ muid: this.addressConfig.user_muid }).subscribe(result => {
        this.contactAddressList = result['response']['contact_address'];
      });
    }
  }



  getCountryData(searchString) {
    this.countryList = [];
    let filters = {};
    filters["name"] = searchString;
    return this.countryService.getCountryData(filters, 10);
  }
  getCountrySearch() {
    this.countryListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getCountryData(searchString))
      )
      .subscribe(
        (result: any = []) => {
          this.cd.markForCheck();
          this.countryList = result;
        },
        (err) => {
          console.log(err);
          this.countryList = [];
        }
      );
  }
  getStateByCountry() {
    this.countryService
      .getStateByCountry({ muid: this.addressConfig.contact_address.country._id })
      .subscribe((result) => {
        this.stateList = result[0]["zones"];
      });
  }


  saveAddress() {
    if (this.addressConfig.user_role == 'customer') {
      this.customerService.saveCustomerAddress(this.addressConfig.user_muid, this.addressConfig.contact_address).subscribe(result => {
        this.modalService.dismissAll();
        swal('Success', 'Customer Address Saved Successfully');
      });
    } else if (this.addressConfig.user_role == 'vendor') {
      this.vendorService.saveVendorAddress(this.addressConfig.user_muid, this.addressConfig.contact_address).subscribe(result => {
        this.modalService.dismissAll();
        swal('Success', 'Vendor Address Saved Successfully');
      });
    }
    this.addressConfig.callbackFunction(this.result);
  }

  showUpdateAddressDialog(is_edit_form: boolean, existingAddress?: ContactAddress) {
    console.log(existingAddress);
    this.addAddressModelRef = this.modalService.open(AddressTemplateComponent, { size: 'lg' });
    this.addAddressModelRef.componentInstance.addressConfig = new AddressTemplate();
    this.addAddressModelRef.componentInstance.addressConfig.action_type = is_edit_form == false ? 'add' : 'edit';
    this.addAddressModelRef.componentInstance.addressConfig.user_role = this.addressConfig.user_role;
    this.addAddressModelRef.componentInstance.addressConfig.user_muid = this.addressConfig.user_muid;
    this.addAddressModelRef.componentInstance.addressConfig.contact_address = existingAddress ? existingAddress : this.addressConfig.contact_address;
    this.addAddressModelRef.componentInstance.addressConfig.callbackFunction = this.getAddressList;
  }

  //TODO emit all response to parent for now completed only for selected address
  emitSelectedAddressToParentComponent(address: ContactAddress) {
    let emitObject = new AddressTemplateEmitConfig()
    // this.addressConfig.address = address;
    emitObject.contact_address = address;
    emitObject.emit_action_type = 'selected_address';
    emitObject.address_type = this.addressConfig.address_type;
    emitObject.user_muid = this.addressConfig.user_muid;
    emitObject.user_role = this.addressConfig.user_role;
    this.updateAddress.emit(emitObject);
  }

  initiateAddressListTableColumns() {
    this.addressListTableColumns = [
      {
        prop: 'first_name',
        name: 'First Name',
        sortable: 'first_name',
        show: true,
        type: 'full_name',
        is_detailed_row: false
      },
      {
        prop: 'last_name',
        name: 'Last Name',
        sortable: 'last_name',
        show: true,
        is_detailed_row: false
      },
      {
        prop: 'city',
        name: 'City',
        sortable: 'city',
        show: true,
        is_detailed_row: false
      },
      {
        prop: 'state.name',
        name: 'State',
        sortable: 'state',
        show: true,
        is_detailed_row: false
      },
      {
        prop: 'country.name',
        name: 'Country',
        sortable: 'country',
        show: true,
        is_detailed_row: false
      },
      {
        prop: 'postal_code',
        name: 'Postal',
        sortable: 'postal_code',
        show: true,
        is_detailed_row: false
      },
      {
        prop: '_id',
        name: 'Action',
        sortable: '',
        show: true,
        cellTemplate: this.actionTmpl,
        is_detailed_row: false
      }
    ];

  }

}
