<div class="row address_view" *ngIf="addressConfig?.action_type=='view'">
  <div class="col-6">
    <p>
      {{addressConfig?.contact_address?.first_name}}
      {{addressConfig?.contact_address?.last_name}}
    </p>
    <p>{{addressConfig?.contact_address?.address_line1}}</p>
    <p>{{addressConfig?.contact_address?.address_line2}}</p>
    <p>{{addressConfig?.contact_address?.city}} {{addressConfig?.contact_address?.state.name}}</p>
    <p>{{addressConfig?.contact_address?.country?.name}} {{addressConfig?.contact_address?.postal_code}}</p>
  </div>
  <div class="col-6">
    <i (click)="showUpdateAddressDialog(true, addressConfig.contact_address)" class="fe fe-edit"></i>
  </div>
</div>

<ng-container *ngIf="addressConfig?.action_type=='dropdown_viewall'">
  <ul class="list-group" *ngFor=" let address of contactAddressList; let i=index">
    <li (click)="emitSelectedAddressToParentComponent(address)" class="list-group-item list-group-item-primary">
      {{address?.first_name}} {{address?.last_name}}
      {{address?.address_line1}}
      {{address?.address_line2}}
      {{address?.city}}
      {{address?.state?.name}}
      {{address?.country?.name}} - {{address?.postal_code}}

    </li>
    <li *ngIf="i==(contactAddressList.length-1)" class="list-group-item">
      <a (click)="showUpdateAddressDialog(false)" href="javascript:void(0)">Add New Address</a>
    </li>
  </ul>

</ng-container>




<div *ngIf="addressConfig?.action_type=='add' || addressConfig?.action_type=='edit'">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add New Address</h4>
    <button type="button" class="close" aria-label="Close" (click)="modalService.dismissAll()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-row">
      <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
        <label for="first_name">First Name</label>
        <input type="text" class="form-control" #first_name="ngModel" required name="first_name" id="first_name"
          placeholder="First Name" [(ngModel)]="addressConfig.contact_address.first_name">
        <app-validation-errors [mformField]="first_name" label="First Name"></app-validation-errors>
      </div>
      <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
        <label for="last_name">Last Name</label>
        <input type="text" class="form-control" #last_name="ngModel" required name="last_name" id="last_name"
          placeholder="Last Name" [(ngModel)]="addressConfig.contact_address.last_name">
        <app-validation-errors [mformField]="last_name" label="Address"></app-validation-errors>
      </div>
      <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
        <label for="address_line1">Address Line 1</label>
        <input maxlength="200" type="text" class="form-control" #address1="ngModel" required name="address_line1"
          id="address_line1" placeholder="Address Line 1" [(ngModel)]="addressConfig.contact_address.address_line1">
        <app-validation-errors [mformField]="address1" label="Address"></app-validation-errors>
      </div>
      <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
        <label for="address_line2">Address Line 2</label>
        <input maxlength="200" type="text" class="form-control" name="address_line2" id="address_line2"
          placeholder="Address Line 2" [(ngModel)]="addressConfig.contact_address.address_line2">
      </div>
      <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
        <label for="country">Country</label>
        <ng-select [items]="countryList" required #country="ngModel" [typeahead]="countryListTypeahead" name="country"
          bindLabel="name" (change)="getStateByCountry()" [(ngModel)]="addressConfig.contact_address.country"
          placeholder="Select Country">
          <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div [ngOptionHighlight]="search">
              {{ item.name }}
            </div>
          </ng-template>
        </ng-select>
        <app-validation-errors [mformField]="country" label="Country"></app-validation-errors>
      </div>

      <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
        <label for="state">Province/State</label>
        <select type="text" class="form-control" name="state" #state="ngModel" required id="state" placeholder="State"
          [(ngModel)]="addressConfig.contact_address.state">
          <option [selected]="item.value.zone_id==addressConfig.contact_address.state.zone_id"
            *ngFor="let item of stateList| keyvalue" [ngValue]="item.value">{{item.value.name}} </option>
        </select>
        <app-validation-errors [mformField]="state" label="State"></app-validation-errors>
      </div>
      <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
        <label for="city">City</label>
        <input type="text" class="form-control" #city="ngModel" required name="city" id="city" placeholder="City"
          [(ngModel)]="addressConfig.contact_address.city">
        <app-validation-errors [mformField]="city" label="City"></app-validation-errors>
      </div>
      <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
        <label for="postal_code">Zip/Postal Code</label>
        <input type="text" class="form-control" #postal_code="ngModel" required name="postal_code" id="postal_code"
          placeholder="Zip/Postal Code" [(ngModel)]="addressConfig.contact_address.postal_code" number maxlength="6">
        <app-validation-errors [mformField]="postal_code" label="Postal code"></app-validation-errors>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modalService.dismissAll()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="saveAddress()">Save</button>
  </div>
</div>

<div *ngIf="addressConfig?.action_type=='table_viewall'">
  <button class="btn btn-primary" type="button" (click)="showUpdateAddressDialog(false)"><i class="fe fe-edit"></i> Add
    New Address</button>
    <ngx-datatable class="material striped" [rows]="contactAddressList" [loadingIndicator]="loadingIndicator"
      [columns]="addressListTableColumns" [externalPaging]="true" [count]="10" [limit]="10" [offset]="0"
      [columnMode]="'force'" rowHeight="auto" [reorderable]="reorderable">
    </ngx-datatable>
</div>




<ng-template #actionTmpl let-row="row" let-value="value">
  <button class="btn btn-primary" type="button" (click)="showUpdateAddressDialog(true, row)"><i class="fe fe-edit"></i>
    Edit</button>
</ng-template>