import { Component, OnInit } from "@angular/core";
import swal from "sweetalert2";
import { GstService } from "../../_services/gst.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";

import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { ConstantService } from "../../_config/constants";
import { AuthenticationService } from "../../_services/authentication.service";
import { endOfDay, endOfMonth, getYear, startOfDay, startOfMonth } from "date-fns";

@Component({
  selector: "app-gst-add",
  templateUrl: "./gst-add.component.html",
  styleUrls: ["./gst-add.component.scss"]
})
export class GstAddComponent implements OnInit {
  // gstDateOptions: IAngularMyDpOptions = {
  //   dateRange: true,
  //   dateFormat: ConstantService.DateFormat,
  // };
  // gstDateOptions = {
  //   dateRange: startOfMonth(new Date())
  // };
  filters: any = {};
  loading: boolean = false;
  id: string;
  is_edit_form: boolean = false;
  gst: any = {};
  fromDateOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: ConstantService.DateFormat
  };
  toDateOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: ConstantService.DateFormat
  };

  fromdate: any;
  todate: any;
  company_id: any;
  // selected: any={};  
  ranges: any = [
    // { label: 'Today', start: startOfDay(new Date()), end: endOfDay(new Date()) },
    { label: 'April', start: startOfMonth(new Date(getYear(new Date()), 3) ),
     end: endOfMonth(new Date(getYear(new Date()), 3) ) },
    { label: 'May', start: startOfMonth(new Date(getYear(new Date()), 4)),
     end: endOfMonth(new Date(getYear(new Date()), 4) ) },
    { label: 'June', start: startOfMonth(new Date(getYear(new Date()), 5)),
     end: endOfMonth(new Date(getYear(new Date()), 5))},
    { label: 'July', start: startOfMonth(new Date(getYear(new Date()), 6)),
     end: endOfMonth(new Date(getYear(new Date()), 6))},
    { label: 'August', start: startOfMonth(new Date(getYear(new Date()), 7)),
     end: endOfMonth(new Date(getYear(new Date()), 7))},
    { label: 'September', start: startOfMonth(new Date(getYear(new Date()), 8)),
     end: endOfMonth(new Date(getYear(new Date()), 8))},
    { label: 'October', start: startOfMonth(new Date(getYear(new Date()), 9)),
     end: endOfMonth(new Date(getYear(new Date()), 9))},
    { label: 'November', start: startOfMonth(new Date(getYear(new Date()), 10)),
     end: endOfMonth(new Date(getYear(new Date()), 10))},
    { label: 'December', start: startOfMonth(new Date(getYear(new Date()), 11)),
     end: endOfMonth(new Date(getYear(new Date()), 11))},
    { label: 'January', start: startOfMonth(new Date(getYear(new Date()), 12)),
     end: endOfMonth(new Date(getYear(new Date()), 12))},
    { label: 'February', start: startOfMonth(new Date(getYear(new Date()), 13)),
     end: endOfMonth(new Date(getYear(new Date()), 13))},
    { label: 'March', start: startOfMonth(new Date(getYear(new Date()), 14)),
     end: endOfMonth(new Date(getYear(new Date()), 14))},
  
]
// gst_date : any = {startDate: startOfDay(new Date()), endDate: endOfDay(new Date())};


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private gstservice: GstService,
    private authenticationService: AuthenticationService,

  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.filters.gst_date = {startDate: startOfMonth(new Date()), endDate: endOfMonth(new Date())};
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get("id");
      if (this.id != null) {
        this.getGst();
      }

      var result = this.router.url.match("/edit/");
      if (result != null) {
        if (result[0] === "/edit/" && this.id != null) {
          this.is_edit_form = true;
        }
      }
    });
  }

  //   this.getGstsList();

  onfrom_dateDateChanged(event: IMyDateModel): void {
    // date selected
  }
  onto_dateDateChanged(event: IMyDateModel): void {
    // date selected
  }
  addGst() {
    this.loading = true;
    this.gstservice.addGst(this.gst).subscribe(result => {
      if (result["status_code"] === 1) {
        this.loading = false;
        swal("Added gst sucessfully!", "gst added.", "success");
        this.router.navigateByUrl("/gst/list");
      } else {
        this.showErrors(result[" response "][" errors "]);
      }
    });
  }
  getGst() {
    let condition ={};
    condition['_id'] =this.id;
    condition['company_id'] =this.company_id;
    
    this.gstservice.getGsts(condition).subscribe(result => {
      this.gst = result[" response "];
    });
  }

  addUpdateGst() {
    if (this.is_edit_form === true) {
      this.editGst();
    } else {
      this.addGst();
    }
  }

  editGst() {
    this.loading = true;
    this.gstservice.editGst(this.gst, this.id).subscribe(result => {
      if (result[" response "] == 1) {
        this.loading = false;
        swal(" Success ", " Gst Updated Successfully ");
        this.router.navigateByUrl("/gst/list");
      } else {
        this.showErrors(result[" response "][" errors "]);
      }
    });
  }

  showErrors(errors) {
    let error_string: string;
    error_string = " ";
    errors.forEach(item => {
      error_string += " < br / > " + item;
    });
    swal({
      title: "Error",
      html: error_string,
      type: "error"
    }).then(function () { });
  }
  generateGSTR1() {
    this.loading = true;
    let filters = {};
    let columns = {};
    let isRegenerate = false;
    filters["gst"] = { from: this.filters['gst_date']['startDate'], to:this.filters['gst_date']['endDate'] };
    filters['company_id'] = this.company_id;

    // columns['gst'] =1;
    // columns['book_beginning'] =1;
    // columns['gst_effective_date'] =1;
    // columns['tds_effective_date'] =1;
    // columns['company'] =1;
    // columns['pan'] =1;
    this.gstservice.generateGSTR1(filters).subscribe(result => {
      if (result["status_code"] == 1) {
        this.loading = false;
        swal("Success!", "Gst Generated Successfully.", "success");
        this.router.navigateByUrl("/gst/list");
      } else {
        swal("Error!", result['response']['message'], "error");
        this.loading = false;
      }
    });
  }
  generateGSTR2() {
    console.log(this.fromdate);
    console.log(this.todate);
  }
}
