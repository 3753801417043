import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '../_models/user';
import { ApiService } from './api.service';
import { UserService } from './user.service';

import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { ConstantService } from '../_config/constants';
import swal from 'sweetalert2';

@Injectable()
export class AuthenticationService {
  public token: string;
  private user: User;
  private company_details: any = {};
  constructor(
    private http: ApiService,
    private router: Router,
    private localstorage: LocalStorageService,
    private ConstantService: ConstantService,
    // private userService: UserService,
    private injector: Injector,
    private jwtHelper: JwtHelperService
  ) {
    // set token if saved in local storage
  }

  login(
    username: string,
    password: string,
  ): Observable<boolean> {
    return this.http
      .post(ApiService.geturl('user', 'login'), {
        username: username,
        password: password
      })
      .pipe(
        map((response: Response) => {
          if (response['token']) {
            this.removeToken();

            this.setToken(response['token']);
            if (this.isAuthenticated() === true) {
              this.getUserDetails();
              this.setDefaultClientId(this.user['default_client_id']);
              if (this.user['default_client_id'] !== null) {
                this.setDefaultClientId(this.user['default_client_id']);
              }
              return true;
            }
          }
          // swal('Error', response['response']['error'], 'error');
          return false;
        })
      );
  }

  setToken(token: string) {
    localStorage.setItem(ConstantService.TOKEN_NAME, token);
  }
  removeToken() {
    localStorage.removeItem(ConstantService.TOKEN_NAME);
  }
  isAuthenticated() {
    // check is local storage or session storage
    let raw_token = this.getToken();
    if (raw_token === null) {
      return false;
    }
    if (this.jwtHelper.isTokenExpired(raw_token)) {
      // && (typeof this.router.url !== 'undefined')
      // (typeof this.router.url !== 'undefined') && 
      if (this.router.url !== null) {
        if (this.router.url.match('/login') === null) {
          this.logout();
        }
      }
      return false;
    } else {
      return true;
    }
  }

  getUserDetails() {
    const userDetails = this.getPayload();
    if (typeof userDetails !== 'undefined') {
      this.user = userDetails;
    } else {
      return false;
    }
  }

  // getUserData() {
  //   this.userService.viewUser(this.getUserId()).subscribe(result => {
  //     return result['response'];
  //   })
  // }

  getUserId() {
    if (typeof this.user === 'undefined') {
      this.getUserDetails();
    }
    return this.getPayload().muid;
  }
  getTokenExpirationTime() {
    
    return this.getPayload().exp;
  }

  getCompanyIds() {
    if (typeof this.user === 'undefined') {
      this.getUserDetails();
    }
    return this.getPayload().company_ids;
  }
  getUserFullName() {
    if (typeof this.user === 'undefined') {
      this.getUserDetails();
    }
    return this.getPayload().fullname;
  }
  getUserName() {
    if (typeof this.user === 'undefined') {
      this.getUserDetails();
    }
    return this.getPayload().username;
  }
  getNameWithEmail() {
    if (typeof this.user === 'undefined') {
      this.getUserDetails();
    }
    return this.user;
  }

  getPayload() {
    // tslint:disable-next-line:prefer-const
    let raw_token = this.jwtHelper.decodeToken(
      localStorage.getItem(ConstantService.TOKEN_NAME)
    );
    if (raw_token == null) {
      return false;
    } else {
      return raw_token;
    }
  }

  getUserRole() {
    let getPayload = this.getPayload();

    return getPayload.role_id;
  }

  refreshToken() {
    const userService =  this.injector.get(UserService);
    userService.refreshToken().subscribe(result => {
      this.setToken(result['response']['token']);
      this.getUserDetails();
    });
  }


  getUserRoleName() {

    let getPayload = this.getPayload();
    return getPayload.role_name;
  }

  getUserFirstName() {
    let getPayload = this.getPayload();
    return getPayload.firstname;
  }

  getTeamName() {
    // if (typeof this.user == 'undefined') {
    //     this.getUserDetails();
    // }
    // tslint:disable-next-line:prefer-const
    let getPayload = this.getPayload();

    return getPayload.team_role_name;
  }
  isClientRole() {
    if (this.getPayload().user_role_id === 10) {
      return true;
    } else {
      return false;
    }
  }

  getDefaultClientId() {
    return localStorage.getItem('default_client_id');
  }
  setDefaultClientId(default_client_id: string) {
    localStorage.setItem('default_client_id', default_client_id);
  }
  setDefaultCompanyId(company_data, branch_data) {
    //let default_company_id = btoa(company_data['_id']);
    console.log('company_data/////', company_data);
    let company_details = {};

    company_details['company_id'] = company_data['_id'];
    company_details['company_name'] = company_data['company'];
    if (company_data['company_type']) {
      company_details['company_type'] = company_data['company_type']['type'];
    }
    company_details['gst_mapping'] = company_data['gst_mapping']['type'];
    company_details['company_category'] = company_data['company_category']['type'];
    company_details['base_currency'] = company_data['currency']['name'];
    company_details['branch_name'] = branch_data['name'];
    company_details['branch_id'] = branch_data['_id'];
    company_details['is_head_office'] = branch_data['is_head_office'];
    company_details['financial_year'] = company_data['financial_year'];
    this.setDefaultCompany(company_details);

  }
  setDefaultCompany(company_details) {
    localStorage.setItem('comp_data', btoa(JSON.stringify(company_details)));
    this.company_details = company_details;
  }
  getCompanyDetails() {
    let comp_data = localStorage.getItem('comp_data');

    return comp_data != null ? JSON.parse(atob(comp_data)) : '';
  }
  getEncryptedCompanyDetails() {
    return localStorage.getItem('comp_data');
  }
  getSelectedCompanyId() {
    let company_details = this.getCompanyDetails();
    return typeof company_details['company_id'] != 'undefined' ? company_details['company_id'] : '';
  }
  getSelectedCompanyName() {
    let company_details = this.getCompanyDetails();

    return typeof company_details['company_name'] != 'undefined' ? company_details['company_name'] : '';
  }

  getDefaultCompanyId() {
    let company_details = this.getCompanyDetails();
    return typeof company_details['company_id'] != 'undefined' ? company_details['company_id'] : '';
  }

  getDefaultBranchId() {
    let company_details = this.getCompanyDetails();
    return typeof company_details['branch_id'] != 'undefined' ? company_details['branch_id'] : '';
  }
  getCompanyCategory() {
    let company_details = this.getCompanyDetails();
    return typeof company_details['company_category'] != 'undefined' ? company_details['company_category'] : '';
  }
  getDefaultBranchName() {
    let company_details = this.getCompanyDetails();
    return typeof company_details['branch_name'] != 'undefined' ? company_details['branch_name'] : '';
  }
  getHeadOffice() {
    let company_details = this.getCompanyDetails();
    return typeof company_details['is_head_office'] != 'undefined' ? company_details['is_head_office'] : false;
  }
  getDefaultCompanyType() {
    let company_details = this.getCompanyDetails();
    return typeof company_details['company_type'] != 'undefined' ? company_details['company_type'] : '';
  }
  getDefaultGSTMapping() {
    let company_details = this.getCompanyDetails();
    return typeof company_details['gst_mapping'] != 'undefined' ? company_details['gst_mapping'] : '';
  }
  isLoggedIn() {
    return this.isAuthenticated();
  }
  getToken() {
    return localStorage.getItem(ConstantService.TOKEN_NAME);
  }
  getAuthHeaders() {
    return { Authorization: 'Bearer ' + this.getToken() };
  }
  logout(): void {
    // clear token remove user from local storage to log user out
    localStorage.removeItem('comp_data');
    localStorage.removeItem('default_client_id');
    this.user = null;
    this.removeToken();
    if (this.router.url && this.router.url !== '/') {
      localStorage.setItem('redirect_path', this.router.url);
    }
    this.router.navigateByUrl('/login').then(() => {
      window.location.reload();
    });
  }
  removeLocalStorageItem(key: LocalStorageKeyTypes) {
    localStorage.removeItem(key);
  }

  getLocalStorageItem(key: LocalStorageKeyTypes) {
    return localStorage.getItem(key);
  }

  clearCompanyData() {
    localStorage.removeItem('comp_data');
  }
}

export type LocalStorageKeyTypes = 'comp_data' | 'redirect_path' | 'facilty_name';
