<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <form novalidate #searchDebitBillForm="ngForm" (ngSubmit)="getDebitBillsList({ offset: 0, limit: 10 })">
        <div class="card">
          <div class="card-body">

            <div class=" mr-3 mb-2 float-right">
              <a *ngIf="this.companyDetails.fi_year.status!='Finalized'" class="btn btn-primary float-right mb-4" routerLink="/invoice/creditnote/add"><i
                  class="fa fa-plus"></i>
                Add Credit Note </a>
            </div>
            <h3 class="table-title">Credit Note List</h3>

            <div class="card-body row">
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="customer">Customer </label>
                <ng-select (clear)="debit_billList=[];" [items]="customerList" [typeahead]="customerListTypeahead"
                  name="customer" bindLabel="customer_name" bindValue="_id" [(ngModel)]="filters.customer_muid"
                  placeholder="Select Customer">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.customer_name }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="credit_note_number">Credit Note No</label>
                <ng-select (clear)="debit_billList=[]" [multiple]="true" [items]="creditNoteNumberList" [typeahead]="creditNoteNumberListTypeahead"
                  name="credit_number" bindLabel="credit_number" bindValue="credit_number"
                  [(ngModel)]="filters.credit_number" placeholder="Credit Note Number">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.credit_number }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="bill_status">Credit Note Status</label>
                <ng-select [items]="bill_status_list" name="bill_status" bindLabel="name"
                  [(ngModel)]="filters.bill_status" placeholder="Select Status" bindValue="id">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.name }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="creditnote_date">Credit Note Date</label>
                <div class="input-group">
                  <input class="form-control" name="credit_note_date" angular-mydatepicker
                    #dp_creditnote="angular-mydatepicker" placeholder="Select a date"
                    (click)="dp_creditnote.toggleCalendar()" [(ngModel)]="filters.co_date"
                    [options]="creditnoteDateOptions"/>

                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="filters.co_date"
                      (click)="dp_creditnote.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>

                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>

              </div>

            </div>

            <div>
              <button type="submit" class="btn btn-primary mr-2"><i class="fa fa-search"></i> Search</button>
              <button type="button" (click)="clearCreditNoteFilters();" class="btn btn-danger"><i
                  class="fa fa-close"></i>
                Clear
              </button>

            </div>
          </div>
          <div class="card-body row">
          </div>
          <!-- <div class="card-footer">
            <button type="submit" class="btn btn-primary mr-2"><i class="fa fa-search"></i> Search</button>
            <button (click)="clearDebitBillFilters();" class="btn btn-danger">
              <i class="fa fa-close"></i> Clear
            </button>

          </div> -->
        </div>
      </form>
      <!-- <div class="p-sm-2">
        <button class="btn btn-primary float-right" routerLink="/debit_bill/add">
          <i class="icon icon-add"></i>Add DebitBill
        </button>
      </div> -->

      <div class="card p-sm-0">
        <div class="card-body">

          <ngx-datatable [rows]="debit_billList" [loadingIndicator]="loadingIndicator" [externalPaging]="true"
            [columnMode]="'force'" [limit]="page.limit" [count]="page.count" [externalSorting]="true"
            (sort)="onSort($event)" [sorts]="this.dt_default_sort" [offset]="page.offset"
            (page)="getDebitBillsList($event)" [reorderable]="reorderable" [headerHeight]="50" [footerHeight]="50"
            [rowHeight]="'auto'" class="material striped" [summaryRow]="'true'" [summaryPosition]="'bottom'"
            [summaryHeight]="'auto'">
            <ngx-datatable-column *ngFor="let col of debitbillListColumns" [flexGrow]=" col.flexGrow" [name]="col.name"
              [sortable]="col.sortable" [cellClass]="col.css_class" [prop]="col.prop">
              <ng-template ngx-datatable-header-template let-sort="sortFn">
                <span (click)="sort()">{{ col.name }}</span>
              </ng-template>
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <div [ngSwitch]="col.type">
                  <span *ngSwitchCase="'currency'"> {{row[col.prop]|currency:currency}}</span>
                  <span *ngSwitchCase="'percentage'">{{row[col.prop]}}%</span>
                  <span *ngSwitchCase="'action'">
                    <div class="btn-group mr-3" *ngIf="row.invoice_status_name =='Draft'">
                      <a class=" btn btn-primary" name="send{{ i }}" type="button" (click)="confirmDebitNote(row)"
                        rel="tooltip">Confirm
                        <!-- <i class="fa fa-spinner fa-spin" *ngIf="loading"></i> -->
                      </a>
                      <!-- <div class="button-row" >
                    <button class="btn btn-success mr-2" routerLink="/credit-bill/view/{{ row._id}}" mat-mini-fab
                      color="primary" title="View">
                      <i class="fa fa-eye"></i>
                    </button>
                  </div> -->

                      <div class="btn-group mr-3" *ngIf="row.invoice_status_name=='Draft'">
                        <!-- <button class="btn btn-primary" name="pay{{i}}" (click)="payAmountModal(payAmount)" type="button"
                      rel="tooltip">Pay</button> -->
                        <!-- <a class="btn btn-primary" routerLink="/credit_bill/edit/{{row._id}}" rel="tooltip">Edit</a> -->
                        <div class="btn-group" ngbDropdown placement="bottom-right" container="body" role="group">
                          <a class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></a>
                          <div class="dropdown-menu" ngbDropdownMenu>
                            <!-- <a class="dropdown-item" routerLink="/bill/invoice/{{row._id}}" type="button"
                                      rel="tooltip">Create Bill</a> -->
                            <a class="dropdown-item" (click)="open(creditBillViewContent, row._id)" mat-mini-fab
                              color="primary" title="View">View</a>
                            <a class="dropdown-item " (click)="deleteDebitBill(value)"
                              *ngIf="row.invoice_status_name =='Draft'">
                              <span class="text-red">
                                <i class="icon-trash-can3"></i> Delete
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="btn-group mr-3" *ngIf="row.invoice_status_name =='opened'">
                      <a class="btn btn-primary" (click)="open(creditBillViewContent, row._id)">
                        View
                      </a>

                    </div>
                    <div class="btn-group mr-3" *ngIf="row.invoice_status_name =='closed'">
                      <a class="btn btn-primary" (click)="open(creditBillViewContent, row._id)">
                        View
                      </a>

                    </div>

                  </span>
                  <span *ngSwitchCase="'object'"> {{ row[col.prop]? row[col.prop][col.field_name]: '' }}</span>
                  <span *ngSwitchCase="'date'"> {{ row[col.prop]|date }}</span>

                  <span *ngSwitchCase="'status'"> <span [ngClass]="{'badge-warning': row.invoice_status==1,
                  'badge-success': row.invoice_status==3,'badge-default': row.invoice_status==5}" class="badge">
                      {{ row[col.prop]}}</span></span>
                  <span *ngSwitchDefault>
                    {{ row[col.prop] }}
                  </span>
                </div>

              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>

  </div>
</div>

<ng-template #actionTmpl let-value="value">

  <div class="btn-group mr-3">
    <!-- <button class="btn btn-primary" routerLink="/debit_bill/edit/{{value}}" type="button" rel="tooltip">Edit</button> -->
    <div class="btn-group" ngbDropdown placement="bottom-right" role="group">
      <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
      <div class="dropdown-menu" ngbDropdownMenu>
        <!-- <button class="dropdown-item" routerLink="/debit_bill/edit/{{value}}" type="button" rel="tooltip">Edit</button> -->
        <button class="dropdown-item " (click)="deleteDebitBill(value)">
          <span class="text-red">
            <i class="icon-trash-can3"></i> Delete
          </span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #creditBillViewContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Credit Note View</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
       <app-invoice-view [id]="id" [moduleName]="'credit_note'"></app-invoice-view>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
