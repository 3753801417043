<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header d-flex align-items-center">
          <h3 class="table-title"><i class="fa fa-user"></i> {{openingBalanceForm==false? 'Journal': 'Add Opening Balance'}} </h3>
        </div>
        <form novalidate #addJournalForm="ngForm" (ngSubmit)="saveJournal()">
          <div class="card-body row">
            <ng-container *ngIf="!openingBalanceForm">
              <div class="form-group col-12 col-sm-6 col-md-4 col-xl-4  required ">
                <label class="col-form-label ">Journal Date</label>
                <div class="">
                  <div class="input-group">
                    <input angular-mydatepicker #journal_date="angular-mydatepicker" placeholder="Journal Date" required
                      (click)="journal_date_dp.toggleCalendar()" #journal_date_dp="angular-mydatepicker"
                      class="form-control" name="journal_date" [(ngModel)]="journal.journal_date"
                      [options]="journal_dateDpOptions" (dateChanged)="onjournal_dateDateChanged($event)">
                    <!-- clear date button -->
                    <div class="input-group-append">
                      <button type="button" class="btn btn-secondary" *ngIf="journal.journal_date"
                        (click)="journal_date_dp.clearDate()">
                        <i class="fa fa-close"></i>
                      </button>
                    </div>

                    <!-- toggle calendar button -->
                    <div class="input-group-append">
                      <button type="button" class="btn btn-secondary" (click)="journal_date_dp.toggleCalendar()">
                        <i class="fa fa-calendar-o"></i>
                      </button>
                    </div>
                  </div>
                  <app-validation-errors [mformField]="journal_date" label="Journal Date">
                  </app-validation-errors>
                </div>
              </div>
              <div class="form-group required col-12 col-sm-6 col-md-4 col-xl-4 ">
                <label class="col-form-label ">Journal Number</label>
                <div class="ml-3">
                  <!-- <input type="number" required class="form-control" name="journal_no" #journal_no="ngModel"
                  id="journal_no" [(ngModel)]="journal.journal_no" placeholder="">
                <app-validation-errors [mformField]="journal_no" label="Journal Number">
                </app-validation-errors> -->
                  {{journal.journal_no}}
                </div>
              </div>

              <!-- <div class="form-group  required col-12 col-sm-6 col-md-3 col-xl-3">
                <label class="" for="voucher_type">Voucher Type</label>
                <app-voucher-type (onSelectVoucher)="getVoucherType($event)" [voucherCategoryCode]=6></app-voucher-type>
              </div> -->

              <div class="form-group col-12 col-sm-6 col-md-4 col-xl-4 ">
                <label class="col-form-label ">Memo</label>
                <div class="">
                  <textarea type="textarea" class="form-control" name="memo" #memo="ngModel" id="memo"
                    [(ngModel)]="journal.memo" placeholder=""></textarea>
                  <app-validation-errors [mformField]="memo" label="Memo">
                  </app-validation-errors>
                </div>
              </div>
            </ng-container>

            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th style="width: 30%;">Account Head</th>
                  <th>Debit</th>
                  <th>Credit</th>
                  <th *ngIf="!openingBalanceForm">description</th>
                  <!-- <th>Action</th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let journal_items of journal.journal_items; let i=index;">
                  <td>
                    <ng-container *ngIf="isOpeningBalanceFormEditable==true">
                      <app-ledgers (onSelectLedger)="updateLedger($event, i)"
                        [ledgerAccountsHeads]="openingBalanceForm==true?journal_items.accountsHeads:journalAccountLedgerConfig">
                      </app-ledgers>
                    </ng-container>
                    <ng-container *ngIf="!isOpeningBalanceFormEditable">
                      <div style="font-weight: bold; m-0">
                        {{ journal_items.account_head.flattened_data.name }}
                      </div>
                      <div class="text-muted font-italic" style="font-size:12px"
                        [innerHTML]="journal_items.account_head.flattened_data.parent_name|join:'fa fa-caret-right m-2':true">
                      </div>

                    </ng-container>
                  </td>
                  <!-- (change)="journal_items.credit=0;" -->
                  <!-- (change)="changeCredit(journal_items)" -->
                  <td>
                    <ng-container *ngIf="isOpeningBalanceFormEditable">
                      <input type="number" [min]="0" class="form-control" name="debit{{i}}" #debit="ngModel"
                        id="debit{{i}}" [(ngModel)]="journal_items.debit" placeholder=""
                        (change)="journal_items.credit=0;sumOfCreditDebit();">
                      <app-validation-errors [mformField]="debit" label="Debit">
                      </app-validation-errors>
                    </ng-container>
                    <ng-container *ngIf="!isOpeningBalanceFormEditable">
                      {{journal_items.debit|currency:currency}}
                    </ng-container>
                  </td>
                  <!-- (change)="journal_items.debit=0;" -->
                  <!-- (change)="changeDebit(journal_items);" -->
                  <td>
                    <ng-container *ngIf="isOpeningBalanceFormEditable">
                      <div class="input-group">
                        <input type="number" (blur)="(openingBalanceForm)?addNewItem(i):void(0)" [min]="0"
                          class="form-control" name="credit{{i}}" #credit="ngModel" id="credit{{i}}"
                          [(ngModel)]="journal_items.credit" placeholder=""
                          (change)="journal_items.debit=0;sumOfCreditDebit();">

                        <app-validation-errors [mformField]="credit" label="Credit">
                        </app-validation-errors>

                        <button *ngIf="openingBalanceForm" [disabled]="i==0 && journal.journal_items.length==1"
                          type="button" tabindex="" class="btn btn-danger ml-2" (click)="deleteItem(i)"><i
                            class="fa fa-trash"></i></button>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!isOpeningBalanceFormEditable">
                      {{journal_items.credit|currency:currency}}
                    </ng-container>
                  </td>
                  <td *ngIf="!openingBalanceForm">
                    <div class="input-group">
                      <textarea type="textarea" class="form-control mr-2" name="description{{i}}"
                        (focus)="addNewItem(i)" #description="ngModel" id="description{{i}}"
                        [(ngModel)]="journal_items.description" placeholder=""></textarea>
                      <app-validation-errors [mformField]="description" label="description">
                      </app-validation-errors>
                      <button [disabled]="i==0 && journal.journal_items.length==1" type="button" tabindex=""
                        class="btn btn-danger" (click)="deleteItem(i)"><i class="fa fa-trash"></i></button>
                    </div>
                  </td>
                  <!-- <td>
                    <button type="button" class="btn btn-primary" (click)="addNewItem(i)"><i
                        class="fa fa-plus"></i></button>
                    <button *ngIf="i>0" type="button" class="btn btn-danger" (click)="deleteItem(i)"><i
                        class="fa fa-trash"></i></button></td> -->
                </tr>
                <tr>
                  <td style="font-weight: bold;">
                    <!-- <div *ngIf="openingBalanceForm">
                      Assets {{totals.assets|currency:currency}}

                    </div>
                    <div *ngIf="openingBalanceForm">
                      Liabilites : {{totals.liabilities|currency:currency}}

                    </div> -->

                  </td>
                  <td class="font-weight-bold">{{totals.debit|currency:currency}}</td>
                  <td class="font-weight-bold">{{totals.credit|currency:currency}}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card-footer">
            <button type="submit" *ngIf="isOpeningBalanceFormEditable" [disabled]="!addJournalForm.valid"
              class="btn btn-primary">Save</button>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>