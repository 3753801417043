<!-- <app-test-temp [testTemp]="'columns'+ tableColumns"></app-test-temp> -->

<b>{{ report_heading }} </b>
<hr />


<div class="col-12 pull-right">
  <!-- <div class=""> -->
  <button class="btn btn-primary mr-2" (click)="getReportData('pdf')">Export as PDF</button>
  <button class="btn btn-primary" (click)="getReportData('excel')">Export as Excel</button>
  <!-- </div> -->
</div>
<div *ngIf="tableColumns.length > 0">
  <!-- <div class="col-12 mb-4" *ngIf="reportType !== 2"> -->
  <!-- <a class="btn btn-primary float-right" routerLink="/support-ticket">Add ticket</a> -->
  <!-- <button type="button" (click)="exportData()" class="btn btn-info float-right mr-2">
    Export
  </button> -->
  <!-- <app-report-filters (getFilterData)="search($event)" [filters]="tableColumns" [name]="'Support Tickets'">
  </app-report-filters> -->

  <datalytics-report-filters (getFilterTypeaheadData)="getFilterTypeaheadData($event)" (getFilterData)="search($event)"
    [dynamicFilterData]="dynamicFilterData" [filters]="tableColumns" [displayInline]="true" [name]="'Tabular Report'">
  </datalytics-report-filters>
  <!-- </div> -->
  <div class="card">
    <div class="card-body">
      <datalytics-tabular-report [defaultSort]="defaultSort" [data]="cashbookList" [pageSize]="pagination.pageSize"
        [columns]="tableColumns" (pageChange)="pageChange($event)" (sortChange)="applySort($event)"
        searchOnLoad="false">
      </datalytics-tabular-report>
    </div>
  </div>
  <!-- <div class="mb-5" *ngIf="openingBalance != undefined || openingBalance != null">
    <table class="table table-bordered">
      <tr>
        <th>Particulars</th>
        <th>Debit</th>
        <th>Credit</th>
      </tr>
      <tr>
        <td>Opening Balance</td>
        <td>
          {{
            openingBalance.opening_balance > 0
              ? openingBalance.opening_balance
              : 0
          }}
        </td>
        <td>
          {{
            openingBalance.opening_balance < 0
              ? openingBalance.opening_balance
              : 0
          }}
        </td>
      </tr>
    </table>
  </div> -->
  <div class="mb-5" *ngIf="reportType !== 2">
    <!-- <app-tabular-report [data]="cashbookList" [pageSize]="10" [columns]="tableColumns" (pageChange)="pageChange($event)"
    (sortChange)="applySort($event)"></app-tabular-report> -->


  </div>
  <div class="mb-5" *ngIf="reportType == 2">
    <!-- <app-tabular-report [data]="cashbookList" [pageSize]="10" [columns]="tableColumns" (pageChange)="pageChange($event)"
    (sortChange)="applySort($event)"></app-tabular-report> -->
    <!-- <datalytics-pivot-table [columns]="tableColumns" [keepColumns]="rowColumns" [groupColumns]="groupColumns"
      [summaryColumns]="summaryColumns" [data]="cashbookList">
    </datalytics-pivot-table> -->

    <!-- <datalytics-tabular-report [defaultSort]="defaultSort" [data]="cashbookList" [pageSize]="pagination.pageSize"
      [columns]="tableColumns" [groupColumns]="groupColumns" (pageChange)="pageChange($event)"
      (sortChange)="applySort($event)">
    </datalytics-tabular-report> -->
  </div>
  <!-- <div class="mb-5" *ngIf="closingBalance != undefined || closingBalance != null">
      <table class="table table-bordered">
        <tr>
          <th>Particulars</th>
          <th>Debit</th>
          <th>Credit</th>
        </tr>
        <tr>
          <td>Closing Balance</td>
          <td>
            {{
            closingBalance.closing_balance < 0 ? closingBalance.closing_balance : 0 }} </td>
          <td>
            {{
            closingBalance.closing_balance > 0
            ? closingBalance.closing_balance
            : 0
            }}
          </td>
        </tr>
      </table>
    </div> -->
</div>