import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { NgxPermissionsService } from 'ngx-permissions';

export enum menuTypes {
  routing = 1,
  static = 2,
  external = 3,
}
export interface Menu {
  is_open: any;
  id: number;
  name: string;
  icon: string;
  type: menuTypes;
  childrens?: number[];
  role_id: number[];
  path: string;
  show?: boolean;
}

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  menus: Menu[] = [];
  is_show: boolean;
  userRoleMenuPermissions: any = {
    1: [6, 21, 37, 39, 70, 101, 102, 7, 29, 34, 42, 35, 71, 63, 9, 11, 43, 48, 60, 66, 56, 57, 12, 46, 49, 300, 301,
      302, 303, 310, 314, 304, 305, 306, 307, 308, 309, 311, 312, 313, 315, 318, 319, 320, 321, 322, 323, 324, 325, 326, 33,
      31, 44, 8, 28, 62, 99, 505, 76, 77, 801, 802, 27],
    8: [21, 29, 71, 63, 47, 505],
    2: [6, 21, 37, 39, 70, 101, 102, 7, 29, 34, 42, 35, 71, 63, 9, 11, 43, 48, 60, 66, 56, 57, 12, 46, 49, 300, 301,
      302, 303, 310, 314, 304, 305, 306, 307, 308, 309, 311, 312, 313, 315, 318, 319, 320, 321, 322, 323, 324, 325, 326,
      33, 31, 44, 8, 28, 62, 99, 505, 76, 77, 27],
    3: [6, 21, 37, 39, 70, 101, 102, 7, 29, 34, 42, 35, 71, 63, 9, 11, 43, 48, 60, 66, 56, 57, 12, 46, 49, 300, 301,
      302, 303, 310, 314, 304, 305, 306, 307, 308, 309, 311, 312, 313, 315, 318, 319, 320, 321, 322, 323, 324, 325, 326,
      33, 31, 44, 8, 28, 62, 99, 505, 76, 77, 27],
    6: [6, 21, 37, 39, 70, 101, 102, 7, 29, 34, 42, 35, 71, 63, 9, 11, 43, 48, 60, 66, 56, 57, 12, 46, 49, 300, 301,
      302, 303, 310, 314, 304, 305, 306, 307, 308, 309, 311, 312, 313, 315, 318, 319, 320, 321, 322, 323, 324, 325, 326,
      33, 31, 44, 8, 28, 62, 99],
    9: [6, 21, 37, 39, 70, 101, 102, 7, 29, 34, 42, 35, 71, 63, 9, 11, 43, 48, 60, 66, 56, 57, 12, 46, 49, 300, 301,
      302, 303, 310, 314, 304, 305, 306, 307, 308, 309, 311, 312, 313, 315, 318, 319, 320, 321, 322, 323, 324, 325, 326,
      33, 31, 44, 8, 28, 62, 99],
    10: [6, 21, 37, 39, 70, 101, 102, 7, 29, 34, 42, 35, 71, 63, 9, 11, 43, 48, 60, 66, 56, 57, 12, 46, 49, 300, 301,
      302, 303, 310, 314, 304, 305, 306, 307, 308, 309, 311, 312, 313, 315, 318, 319, 320, 321, 322, 323, 324, 325, 326,
      33, 31, 44, 8, 28, 62, 99],
    11: [6, 21, 37, 39, 70, 101, 102, 7, 29, 34, 42, 35, 71, 63, 9, 11, 43, 48, 60, 66, 56, 57, 12, 46, 49, 300, 301,
      302, 303, 310, 314, 304, 305, 306, 307, 308, 309, 311, 312, 313, 315, 318, 319, 320, 321, 322, 323, 324, 325, 326,
      33, 31, 44, 8, 28, 62, 99],
    12: [6, 21, 37, 39, 70, 101, 102, 7, 29, 34, 42, 35, 71, 63, 9, 11, 43, 48, 60, 66, 56, 57, 12, 46, 49, 300, 301,
      302, 303, 310, 314, 304, 305, 306, 307, 308, 309, 311, 312, 313, 315, 318, 319, 320, 321, 322, 323, 324, 325, 326,
      33, 31, 44, 8, 28, 62, 99],
  };

  constructor(
    private router: Router,
    public authenticationService: AuthenticationService,
    public permissionsService: NgxPermissionsService
  ) { }

  ngOnInit() {
    const user_roles = ["ADMIN", "MANAGER", "ACCOUNTANT", "USER", "MINI-ERP"]
    this.getMenus();
  }
  getDefaultCompanyId() {
    return this.authenticationService.getSelectedCompanyId();
  }
  isAuthenticated() {
    return this.authenticationService.isAuthenticated();
  }
  getMenus() {
    let is_admin_menu = this.getDefaultCompanyId() !== "" ? false : true;

    // tslint:disable-next-line: max-line-length
    if (
      this.authenticationService.getUserRole() != 8 &&
      is_admin_menu == false
    ) {
      //Update if new menu is added .
      const menu: any = [
        {
          id: 402,
          path: "dashboard",
          name: "Dashboard",
          icon: "fa fa-dashboard",
          role_id: [1, 2, 3, 6, 7, 8, 9, 10, 11, 12],
          type: 1,
          is_open: false,
          is_child: false,
          childrens: [],
        },
        {
          id: 100,
          path: "purchase-order/list",
          name: "Purchase",
          icon: "fa fa-money",
          role_id: [1, 2, 3, 6, 8, 9, 10, 11, 12],
          type: 2,
          is_open: false,
          is_child: false,
          childrens: [6, 21, 37, 42, 70, 101, 102],
        },
        {
          id: 6,
          path: "purchase-order/list",
          name: "Purchase Orders",
          icon: "fa fa-shopping-cart",
          role_id: [1, 2, 8],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 101,
          path: "receipt/vendor/list",
          name: "Purchase Advance",
          icon: "fa fa-file-text",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 39,
          path: "credit-bill/list",
          name: "Credit Note",
          icon: "fa fa-shopping-cart",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 70,
          path: "bill_expense/list",
          name: "Expense Invoice",
          icon: "fa fa-shopping-cart",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 21,
          path: "bill/list",
          name: "Purchase Invoice",
          icon: "fa fa-file",
          role_id: [1, 2, 8],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 47,
          path: "bill/view/:id",
          name: "Bill View",
          icon: "fa fa-file",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 77,
          path: "bill/image/:id",
          name: "Invoice Entry",
          icon: "fa fa-file",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 76,
          path: "bill_expense/image/:id",
          name: "Expense Invoice",
          icon: "fa fa-file",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 38,
          path: "bill/list/:type/:id/:advance_id",
          name: "PurchaseAdvanceMapping",
          icon: "fa fa-file",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 22,
          path: "bill/add",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: false,
          childrens: [],
        },
        {
          id: 200,
          path: "sales-order/list",
          name: "Sales",
          icon: "fa fa-file-text",
          role_id: [1, 2, 3, 6, 8, 9, 10, 11, 12],
          type: 2,
          is_open: false,
          is_child: false,
          childrens: [7, 29, 34, 39, 35],
        },
        {
          id: 7,
          path: "sales-order/list",
          name: "Sales Orders",
          icon: "fa fa-file-text",
          role_id: [1, 2, 8],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 42,
          path: "debit-bill/list",
          name: "Debit Note",
          icon: "fa fa-shopping-cart",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 29,
          path: "invoice/list",
          name: "Sales Invoice",
          icon: "fa fa-file-text",
          role_id: [1, 2, 8],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 41,
          path: "invoice/list/:type/:id/:advance_id",
          name: "Advance",
          icon: "fa fa-file-text",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 34,
          path: "receipt/customer/list",
          name: "Sales Advance",
          icon: "fa fa-address-book",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        // {
        //   id: 56,
        //   path: "invoice/list/receive",
        //   name: "Receivables",
        //   icon: "fa  fa-sticky-note-o",
        //   role_id: [1, 2],
        //   type: 1,
        //   is_open: false,
        //   is_child: true,
        //   childrens: [],
        // },
        // {
        //   id: 57,
        //   path: "bill/list/payment",
        //   name: "Payables",
        //   icon: "fa  fa-sticky-note-o",
        //   role_id: [1, 2],
        //   type: 1,
        //   is_open: false,
        //   is_child: true,
        //   childrens: [],
        // },
        {
          id: 64,
          path: "receipts/add",
          name: "Receipts Booking",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 71,
          path: "receipt/list",
          name: "Receipt",
          icon: "fa fa-user",
          role_id: [1, 2, 8],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 634,
          path: "expense/add",
          name: "Payment",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 63,
          path: "expense/list",
          name: "Payment",
          icon: "fa fa-user",
          role_id: [1, 2, 8],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 400,
          path: "paymenttransactions/list",
          name: "Transactions",
          icon: "fa fa-money",
          role_id: [1, 2, 3, 6, 8, 9, 10, 11, 12],
          type: 2,
          is_open: false,
          is_child: false,
          childrens: [71, 63, 9, 11, 43, 48, 60, 66, 56, 57],
        },
        // {
        //   id: 68,
        //   path: "receipts/:type",
        //   name: "Receipts Payment",
        //   icon: "fa fa-user",
        //   role_id: [1, 2],
        //   type: 1,
        //   is_open: false,
        //   is_child: true,
        //   childrens: [],
        // },


        {
          id: 103,
          path: "receipts/:type/:id",
          name: "Receipts Payment",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 12,
          path: "paymenttransactions/list",
          name: "Ledger",
          icon: "fa fa-money",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 9,
          path: "contra/list",
          name: "Contra",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 11,
          path: "journal/list",
          name: "Journal",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 666,
          path: "journal/add",
          name: "Journal",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 60,
          path: "stockjournal/list",
          name: "Stock Journal",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 667,
          path: "stockjournal/add",
          name: "Stock Journal",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 600,
          path: "receipts/direct_receipt",
          name: "Reports",
          icon: "fa fa-list",
          role_id: [1, 2, 3, 6, 9, 10, 11, 12],
          type: 2,
          is_open: false,
          is_child: false,
          childrens: [
            700,
            701,
            702,
            706,
            703,
            704,
            705,
            

          ],
        },
        {
          id: 90,
          path: "receipts/direct_receipt",
          name: "Payment",
          icon: "fa fa-money",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 46,
          path: "stocktransactions/list",
          name: "Stock Transactions",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 700,
          path: "report/cashbook",
          name: "Accounts Books",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 2,
          is_open: false,
          is_child: true,
          childrens: [300, 301, 302, 310, 314],
        },
        // {
        //   id: 701,
        //   path: "report/sales_ledger",
        //   name: "Ledgers",
        //   icon: "fa fa-user",
        //   role_id: [1, 2],
        //   type: 2,
        //   is_open: false,
        //   is_child: true,
        //   childrens: [305, 306, 307, 308],
        // },
        {
          id: 702,
          path: "report/sales_register",
          name: "Registers",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 2,
          is_open: false,
          is_child: true,
          childrens: [303, 304, 312, 313, 318, 319, 315],
        },
        {
          id: 703,
          path: "report/stock_summary",
          name: "Inventory Reports",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 2,
          is_open: false,
          is_child: true,
          childrens: [320, 321, 322],
        },
        {
          id: 704,
          path: "report/bills_payable",
          name: "Outstandings Reports",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 2,
          is_open: false,
          is_child: true,
          childrens: [323, 324],
        },
        {
          id: 705,
          path: "report/hsn_report",
          name: "Other Reports",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 2,
          is_open: false,
          is_child: true,
          childrens: [325, 326, 399, 398, 403],
        },
        {
          id: 300,
          path: "report/cashbook/5f63778103ecb31ca0d53f43",
          name: "Cash Book",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 301,
          path: "report/bankbook/5f644a85f5dbc70cffe14ea2",
          name: "Bank Book",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 302,
          path: "report/vendor_ledgeraccount/5f6303aa936c6e4436de729c",
          name: "Vendor Ledger",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 314,
          path: "report/party/60489096ef399515d0b2eaf2",
          name: "Customer Ledger",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 310,
          path: "report/ledger/6048906eef399515d0b2eaf1",
          name: "Ledger",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },

        {
          id: 303,
          path: "report/sales_register/5f6454a3f5dbc70cffe15080",
          name: "Sales Register",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 304,
          path: "report/purchase_register/5f645b50f5dbc70cffe151e9",
          name: "Purchase Register",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 305,
          path: "report/sales_ledger/5f645d73f5dbc70cffe1525b",
          name: "Sales Ledger",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 306,
          path: "report/purchase_ledger/5f64653ff5dbc70cffe15440",
          name: "Purchase Ledger",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 307,
          path: "report/receipt/5f647420f5dbc70cffe156e6",
          name: "Receipt Report",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 308,
          path: "report/payment/5f64758af5dbc70cffe15746",
          name: "Payment Report",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 320,
          path: "report/stock_summary/5f76c6371971b629b3bee3a0",
          name: "Stock Summary",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 321,
          path: "report/stock_item_report/5f647601f5dbc70cffe15768",
          name: "Stock Item Report",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 322,
          path: "report/stock_ageing_analysis/5f647654f5dbc70cffe15782",
          name: "Stock Ageing Analysis",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 312,
          path: "report/credit_note/5f6476b6f5dbc70cffe157a4",
          name: "Credit Note Report",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 313,
          path: "report/debit_note/5f647703f5dbc70cffe157b7",
          name: "Debit Note Report",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 318,
          path: "report/sales_order_register/5f647766f5dbc70cffe157e2",
          name: "SO Register",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 319,
          path: "report/purchase_order_register/5f9abb0e876e7fb2231cdba6",
          name: "PO Register",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 323,
          path: "report/bills_payable/5fb76936a5018ae74b2bb6dd",
          name: "Bills Payable",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 324,
          path: "report/bills_receivable/5fb7adc5a5018ae74b2bcee0",
          name: "Bills Receivable",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 315,
          path: "report/journal_register/5f6477b8f5dbc70cffe157fb",
          name: "Journal Register",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 501,
          path: "masters/hsn/list",
          name: "Masters",
          icon: "fa fa-gear",
          role_id: [1, 2, 3, 9, 10, 11],
          type: 2,
          is_open: false,
          is_child: false,
          childrens: [33, 31, 44, 8],
        },
        {
          id: 403,
          path: "report/balance_sheet/60f95946e4154a51b527e31a",
          name: "Balance Sheet",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 399,
          path: "report/profit_loss/609d1e2fac12ab32a9830d35",
          name: "Profit & Loss",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 398,
          path: "report/trial_balance/609e3b12ac12ab32a9830d36",
          name: "Trial Balance",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 31,
          path: "product/list",
          name: "Products",
          icon: "fa fa-cube",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        // {
        //   id: 44,
        //   path: "product/group/list",
        //   name: "Products Group",
        //   icon: "fa fa-cube",
        //   role_id: [1, 2],
        //   type: 1,
        //   is_open: false,
        //   is_child: true,
        //   childrens: [],
        // },
        {
          id: 33,
          path: "party/list",
          name: "Party",
          icon: "fa fa-address-book",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 8,
          path: "costgroup/list",
          name: "Cost Center",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 27,
          path: "gst/add",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: false,
          childrens: [],
        },
        {
          id: 401,
          path: "fixed_assets/viewall",
          name: "Fixed Assets",
          icon: "fa fa-user",
          role_id: [1, 9, 10],
          type: 1,
          is_open: false,
          is_child: false,
          childrens: [],
        },
        {
          id: 51,
          path: "user/list",
          name: "Users",
          icon: "fa fa-user",
          role_id: [1, 9, 10],
          type: 1,
          is_open: false,
          is_child: false,
          childrens: [],
        },
        {
          id: 326,
          path: "report/hsn_report/5fc4e47973e3ea61c7f0ff54",
          name: "Hsn",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        // {
        //   id: 325,
        //   path: "report/ageing_report/5fbb4557a5018ae74b2c6e99",
        //   name: "Ageing",
        //   icon: "fa fa-user",
        //   role_id: [1, 2],
        //   type: 1,
        //   is_open: false,
        //   is_child: true,
        //   childrens: [],
        // },
        {
          id: 505,
          path: "fileupload/list",
          name: "Fileupload",
          icon: "fa fa-user",
          role_id: [1, 2, 8],
          type: 1,
          is_open: false,
          is_child: false,
          childrens: [],
        },
        {
          id: 801,
          path: "gst/list",
          name: "Gst",
          icon: "fa fa-user",
          role_id: [1, 2, 9, 10],
          type: 1,
          is_open: false,
          is_child: false,
          childrens: [],
        },
        {
          id: 802,
          path: "gst/:id",
          role_id: [1],
          type: 1,
          is_open: false,
          is_child: false,
          childrens: []

        },
        {
          id: 706,
          path: "report/detailed_reports",
          name: "Detailed Reports",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 2,
          is_open: false,
          is_child: true,
          childrens: [901, 902, 903, 904],
        },
        {
          id: 901,
          path: "report/sales_details/611a1a77bf2a77d42753c355",
          name: "Sales Details",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 2,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 902,
          path: "report/purchase_details/611b95c739480d93e8607d5e",
          name: "Purchase Details",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 2,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 903,
          path: "report/credit_details/611ba08739480d93e8607d5f",
          name: "Credit Note Details",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 2,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 904,
          path: "report/debit_details/611ba15b39480d93e8607d60",
          name: "Debit Note Details",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 2,
          is_open: false,
          is_child: true,
          childrens: [],
        },
      ];
      // const menu = this.router.config
      //   .filter(
      //     (routes) =>
      //       routes.data &&
      //       routes.data.menu &&
      //       routes.data.menu.show != false &&
      //       routes.data.menu.is_common_master == is_admin_menu
      //   )
      //   .map((route) => {
      //     return {
      //       id: route.data.menu.id,
      //       path: route.path,
      //       name: route.data.menu.name,
      //       icon: route.data.menu.icon,
      //       role_id: route.data.permissions,
      //       type: route.data.menu.type ? route.data.menu.type : 1,
      //       is_open: false,
      //       is_child: route.data.menu.is_child
      //         ? route.data.menu.is_child
      //         : false,
      //       childrens: route.data.menu.childrens
      //         ? route.data.menu.childrens
      //         : [],
      //     };
      //   });
      return (this.menus = menu);
    } else if (this.authenticationService.getUserRole() != 8 && is_admin_menu) {
      const menu = [
        {
          id: 99,
          path: "company/add",
          name: "Company Add",
          icon: "fa fa-money",
          role_id: [1, 2],
          type: 2,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 500,
          path: "masters/hsn/list",
          name: "Masters",
          icon: "fa fa-gear",
          role_id: [1, 2, 9, 10],
          type: 2,
          is_open: false,
          is_child: false,
          childrens: [28, 37, 62, 99, 201],
        },
        {
          id: 28,
          path: "masters/hsn/list",
          name: "GST",
          icon: "fa fa-file",
          role_id: [1, 2, 9, 10],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 36,
          path: "tax/list",
          name: "Tax",
          icon: "fa fa-user",
          role_id: [1, 2, 9, 10],
          type: 1,
          is_open: false,
          is_child: false,
          childrens: [],
        },
        {
          id: 37,
          path: "coa/add",
          name: "Chart Of Accounts",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 62,
          path: "masters/unit/list",
          name: "Units",
          icon: "fa fa-file",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 201,
          path: "masters/voucher_type/list",
          name: "Voucher Type",
          icon: "fa fa-file",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        },
        {
          id: 50,
          path: "user/list",
          name: "Users",
          icon: "fa fa-user",
          role_id: [1, 2, 9, 10],
          type: 1,
          is_open: false,
          is_child: false,
          childrens: [],
        },
        {
          id: 505,
          path: "fileupload/list",
          name: "Fileupload",
          icon: "fa fa-user",
          role_id: [1, 2],
          type: 1,
          is_open: false,
          is_child: false,
          childrens: [],
        }
      ];
      return (this.menus = menu);
    } else if (this.authenticationService.getUserRole() == 8) {
      const menu = [

        {
          id: 21,
          path: "bill/list",
          name: "Purchase Invoice",
          icon: "fa fa-file",
          role_id: [8],
          type: 1,
          is_open: false,
          is_child: false,
          childrens: [],
        },
        {
          id: 29,
          path: "invoice/list",
          name: "Sales Invoice",
          icon: "fa fa-file-text",
          role_id: [8],
          type: 1,
          is_open: false,
          is_child: false,
          childrens: [],
        },
        {
          id: 71,
          path: "receipt/list",
          name: "Receipt",
          icon: "fa fa-user",
          role_id: [8],
          type: 1,
          is_open: false,
          is_child: false,
          childrens: [],
        },
        {
          id: 63,
          path: "expense/list",
          name: "Payment",
          icon: "fa fa-user",
          role_id: [8],
          type: 1,
          is_open: false,
          is_child: false,
          childrens: [],
        },
        {
          id: 505,
          path: "fileupload/list",
          name: "Fileupload",
          icon: "fa fa-user",
          role_id: [1, 2, 8],
          type: 1,
          is_open: false,
          is_child: false,
          childrens: [],
        }

      ];

      return (this.menus = menu);
    } else if (this.authenticationService.getUserRole() == 2) {
      const menu = [
        {
          id: 100,
          path: "purchase-order/list",
          name: "Purchase",
          icon: "fa fa-money",
          role_id: [1, 2, 8],
          type: 2,
          is_open: false,
          is_child: false,
          childrens: [6, 21, 37, 39, 70, 101, 102],
        },
        {
          id: 6,
          path: "purchase-order/list",
          name: "Purchase Orders",
          icon: "fa fa-shopping-cart",
          role_id: [1, 2, 8],
          type: 1,
          is_open: false,
          is_child: true,
          childrens: [],
        }
      ];
      return (this.menus = menu);
    }
  }
  getMenuById(menu_id) {
    return this.menus.filter((data) => data.id == menu_id);
  }
  getMenuURLById(menu_id) {
    let menu = this.menus.filter((data) => data.id == menu_id);
    if (menu.length > 0) {
      return menu[0].path;
    }
  }
  getMenuChildrensNameById(menu_id) {
    let menu = this.menus.filter((data) => data.id == menu_id);
    if (menu.length > 0) {
      return menu[0].childrens;
    }
  }
  getMenuICONById(menu_id) {
    let menu = this.menus.filter((data) => data.id == menu_id);
    if (menu.length > 0) {
      return menu[0].icon;
    }
  }
  getMenuNameById(menu_id) {
    let menu = this.menus.filter((data) => data.id == menu_id);
    if (menu.length > 0) {
      return menu[0].name;
    } else {
      return false;
    }
  }
  getMenuIsOpen(menu_id) {
    let menu = this.menus.filter((data) => data.id == menu_id);
    if (menu.length > 0) {
      return menu[0].is_open;
    }
  }
  getMenuType(menu_id) {
    let menu = this.menus.filter((data) => data.id == menu_id);
    if (menu.length > 0) {
      return menu[0].type;
    }
  }
  toggleMenuIsOpen(child_menu_id) {
    let menuIndex = this.menus.findIndex((data) => data.id == child_menu_id);
    if (menuIndex > 0) {
      this.menus[menuIndex].is_open = this.menus[menuIndex].is_open == true ? false : true;
      return this.menus[menuIndex].is_open;
    }
  }
}
