export class SupportTicket {
  title: string;
  description: string;
  labels: string;
  description_html: any;
  label: string;
  discussions: Discussions[] = []; 
}

export class Discussions {
  discussion: string; 
  updated_by: string;
  updated_at: Date;
  created_at: Date;
  discussion_id: string; 
}
