import { Component, OnInit, EventEmitter } from "@angular/core";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { MastersService } from "src/app/_services/masters.service";
import { Receipts } from "../../../_models/receipts";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { VendorService } from "../../../_services/vendor.service";
import { ReceiptsService } from "src/app/_services/receipts.service";
import { CompanyService } from "src/app/_services/company.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import swal from "sweetalert2";
import { ConstantService } from "../../../_config/constants";
import { BillService } from "src/app/_services/bill.service";
import { differenceInDays } from "date-fns";

@Component({
  selector: "app-receipts-vendor-add",
  templateUrl: "./receipts-vendor-add.component.html",
  styleUrls: ["./receipts-vendor-add.component.scss"],
})
export class ReceiptsVendorAddComponent implements OnInit {
  loading: boolean =false;
  receipts: Receipts = new Receipts();
  vendor: any = {};
  vendor_lists: Object;
  billsList: any;
  vendorListTypeahead = new EventEmitter<string>();
  payment_methodList: any = [
    { name: "Cash", id: 1031 },
    { name: "Bank", id: 1030 },
    //   { name: "Check", id: 3 }
  ];
  payment_dateDpOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };
  purchaseAdvanceDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    disableSince: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };
  payment_accountList: any;
  company_id: any;
  company_details: any;
  page: any = { offset: 0, pageSize: 10 };
  filters: any = {};
  sort: any = {};
  columns: any = {};
  count: any;
  selectFiYear: any;
  constructor(
    private vendorService: VendorService,
    private authenticationService: AuthenticationService,
    private receiptService: ReceiptsService,
    private companyService: CompanyService,
    private router: Router,
    private billService: BillService,

    private countryservice: MastersService
  ) {}

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.receipts.payment_method = this.payment_methodList;
    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];

    this.getVendors("").subscribe((result) => {
      this.vendor_lists = result["response"]["data"];
    });
    this.getCompanyDetails();
    this.receipts.payment_date={
      isRange: false,
      singleDate: {
        jsDate: differenceInDays(new Date(this.selectFiYear.end_year, 2, 31), new Date()) > 0 && differenceInDays(new Date(), new Date(this.selectFiYear.start_year, 3, 1)) > 0 ? new Date() : new Date(this.selectFiYear.end_year, 2, 31),
      },
    };
  }

  getBills(pageInfo: any = {}) {
    if (pageInfo !== null) {
      this.page = pageInfo;
    }
    console.log("this.receipts.vendor", this.receipts.vendor);
    this.filters["company_id"] = this.company_id;
    this.filters["vendor_muid"] = this.vendor._id;
    return this.billService
      .getBills(this.filters, this.columns, this.sort, this.page)
      .subscribe((result) => {
        this.billsList = result["response"]["data"];
        this.count = result["response"]["count"];
      });
  }

  getPaymentInChange() {
    this.getPaymentCoaHead("").subscribe((result) => {
      this.payment_accountList = result["response"];
    });
  }
  getPaymentCoaHead(searchString) {
    this.payment_accountList = [];
    return this.countryservice.getCoaHead(this.company_id, searchString, {
      in: [this.receipts.payment_method["name"]],
    });
  }
  onpayment_dateDateChanged(event: IMyDateModel): void {
    // date selected
  }
  addVendorReceipts() {
    this.loading =true
    this.receipts.type = "vendor";
    this.receipts["party"] = {
      id: this.vendor["_id"],
      name: this.vendor["vendor_name"],
      coa_id: this.vendor["coa_data"]["id"],
    };
    this.receipts.company_id = this.company_id;
    // console.log('this.receipts====>>>>>', this.receipts); return false;
    this.receipts['tx_type_id'] = 71;
    this.receiptService.addVendorReceipts(this.receipts).subscribe((result) => {
      if (result["ok"] == 1) {
        this.loading = false;
        swal("Success", "Advance added Successfully.", "success");
        this.router.navigateByUrl("/receipt/vendor/list");
      } else {
        this.showErrors(result[" message "]);
      }
    });
  }
  book_pay() {
    this.receipts.type = "vendor";
    this.receipts["party"] = {
      id: this.vendor["_id"],
      name: this.vendor["vendor_name"],
      coa_id: this.vendor["coa_data"]["id"],
    };
    this.receipts.company_id = this.company_id;
    this.receiptService.book_vendor_pay(this.receipts).subscribe((result) => {
      if (result["response"]["status_code"] == 1) {
        swal(
          "Success",
          "Advance added and payment done successfully ",
          "success"
        );
        this.router.navigateByUrl("/receipt/vendor/list");
      } else {
        swal("Error", "Advance not added successfully ", "error");
      }
    });
  }
  getVendors(searchString) {
    let condition: any = {};
    let columns: any = {};
    if (searchString != "") {
      condition["vendor_name"] = searchString;
    }
    condition["company_id"] = this.company_id;
    columns["vendor_name"] = 1;
    columns["first_name"] = 1;
    columns["last_name"] = 1;
    columns["currency"] = 1;
    columns["address_line1"] = 1;
    columns["address_line2"] = 1;
    columns["valid_upto"] = 1;
    columns["country"] = 1;
    columns["state"] = 1;
    columns["phone"] = 1;
    columns["gst_number"] = 1;
    columns["coa_data"] = 1;
    return this.vendorService.getVendors(condition, columns, {}, { limit: 10 });
  }
  showErrors(errors) {
    let error_string: string;
    error_string = " ";
    errors.forEach((item) => {
      error_string += " < br / > " + item;
    });
    swal({
      title: "Error",
      html: error_string,
      type: "error",
    }).then(function () {});
  }

  getCompanyDetails() {
    this.companyService.viewCompany(this.company_id).subscribe((result) => {
      this.company_details = result["response"];
      this.payment_dateDpOptions.dateFormat = this.company_details.date_format;
      let book_beginning_date = new Date(this.company_details.book_beginning);
      book_beginning_date.setDate(book_beginning_date.getDate() - 1);
      let curren_tx_dateOptions = this.getCopyOfOptions();
      curren_tx_dateOptions.disableUntil = {
        year: book_beginning_date.getFullYear(),
        month: book_beginning_date.getMonth() + 1,
        day: book_beginning_date.getDate(),
      };
      curren_tx_dateOptions.disableUntil = {year:this.selectFiYear.start_year, month:3, day:31};
      curren_tx_dateOptions.disableSince = {year:this.selectFiYear.end_year, month:4, day:1};
      this.payment_dateDpOptions = curren_tx_dateOptions;
    });
  }
  getCopyOfOptions(): IAngularMyDpOptions {
    return JSON.parse(JSON.stringify(this.payment_dateDpOptions));
  }
  updateLedger(ledger: any) {
    this.receipts.ledger.name = ledger.flattened_data.name;
    this.receipts.ledger.id = ledger.flattened_data.id;
    this.receipts.ledger.ref_id = ledger.flattened_data["_id"];
    this.receipts.ledger.parent_id = ledger.flattened_data.parent_id;
    this.receipts.ledger.parent_name = ledger.flattened_data.parent_name;
  }
}
