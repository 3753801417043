import { Component, OnInit, TemplateRef, ViewChild, Input, SimpleChanges, OnChanges } from "@angular/core";
import { Location } from "@angular/common";
// import { InvoiceService } from 'app/_services/invoice.service;'
import { InvoiceService } from "../../../_services/invoice.service";
import { ApiService } from "../../../_services/api.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { AuthenticationService } from "../../../_services/authentication.service";
import swal from "sweetalert2";
import { CompanyService } from "../../../_services/company.service";
import { Company } from "../../../_models/company.model";
import { SalesorderService } from "../../../_services/salesorder.service";
import { DebitBillService } from "../../../_services/debit-bill.service";
import { CreditBillService } from "../../../_services/credit_bill.service";
import { PurchaseorderService } from "../../../_services/purchaseorder.service";
import { BillService } from "../../../_services/bill.service";
import { ExpenseService } from "../../../_services/expense.service";


@Component({
  selector: "app-invoice-view",
  templateUrl: "./invoice-view.component.html",
  styleUrls: ["./invoice-view.component.scss"]
})
export class InvoiceViewComponent implements OnInit, OnChanges {
  @Input() selectedrow_data: any;
  invoice: any = {};
  viewDetails: any = {};
  @Input() id: string;
  @Input() moduleName: string;
  company_id: string;
  rem_amount: number;
  path: any;
  branch_id: string;
  type: string;
  company_details: Company = new Company();
  currency: string;
  header: string;
  labelName: string;
  viewReplaceKey: any = {}
  partyCurrency: string;
  party: string;
  orderLabel: string;
  billLabel: string;
  constructor(
    private invoiceService: InvoiceService,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private apiService: ApiService,
    private companyService: CompanyService,
    private salesorderService: SalesorderService,
    private debitBillService: DebitBillService,
    private creditBillService: CreditBillService,
    private purchaseorderService: PurchaseorderService,
    private billService: BillService,
    private expenseService: ExpenseService,
    private location: Location

  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.company_id = this.authenticationService.getDefaultCompanyId();
    if(changes.id) {
      this.id = changes.id.currentValue;
      this.type = this.moduleName;
      this.initiateViewPage();
    }
  }
  ngOnInit() {
    // this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.partyCurrency = this.authenticationService.getCompanyDetails()[
      "base_currency"
    ];
    this.viewCompany()
    this.route.paramMap.subscribe(params => {
      // this.id = params.get("id");
      // this.type = params.get("type")
      // this.initiateViewPage();
    });
  }
  goBack() {
    this.location.back();
  }
  initiateViewPage() {
    switch (this.type) {
      case "salse_invoice":
        this.header = "Sales Invoice";
        this.orderLabel = "Sales Order";
        this.billLabel = "Sales Invoice";
        this.party = "Customer";
        this.viewSalesInvoice();
        break;
      case "sales_order":
        this.header = "Sales Order";
        this.orderLabel = "Sales Order";
        this.billLabel = "Sales Invoice";
        this.party = "Customer";
        this.viewSalesOrder();
        break;
      case "credit_note":
        this.header = "Credit Note";
        this.orderLabel = "Credit Note";
        this.billLabel = "Sales Invoice";
        this.party = "Customer";
        this.viewCreditNote();
        break;
      case "purchase_order":
        this.header = "Purchase Order";
        this.orderLabel = "Purchase Order";
        this.billLabel = "Purchase Invoice";
        this.party = "Vendor";
        this.viewPurchaseOrder();
        break;
      case "purchase_invoice":
        this.party = "Vendor";
        this.header = "Purchase Invoice";
        this.orderLabel = "Purchase Order";
        this.billLabel = "Purchase Invoice";
        this.viewPurchaseInvoice();
        break;
      case "debit_note":
        this.party = "Vendor";
        this.header = "Debit Note";
        this.orderLabel = "Debit Note";
        this.billLabel = "Purchase Invoice";
        this.viewDebitNote();
        break;
      case "expense_invoice":
        this.party = "Vendor";
        this.header = "Expense Invoice";
        this.orderLabel = "Expense";
        this.billLabel = "Expense Invoice";
        this.viewExpenseInvoice();
        break;
    }
  }
  viewSalesInvoice() {
    this.invoiceService
      .viewInvoice(this.id)
      .subscribe(result => {
        this.viewDetails = {};
        this.viewDetails.party = result['response']['customer']['customer_name'];
        this.viewDetails.ledger = result['response']['ledger']['name'];
        this.viewDetails.billNumber = result['response']['invoice_number'];
        this.viewDetails.billDate = result['response']['invoice_date'];
        this.viewDetails.dueDate = result['response']['due_date'];
        this.viewDetails.orderNumber = result['response']['sales_order_number'];
        this.viewDetails.orderDate = result['response']['sales_order_date'];
        this.viewDetails.items = result['response']['invoice_items'];
        this.viewDetails.balancingItems = result['response']['balancing_items'];
        this.viewDetails.actualTotalAmount = result['response']['actual_total_amount'];
        this.viewDetails.subTotalAmount = result['response']['sub_total_amount'];
        this.viewDetails.discount = result['response']['discount'];
        this.viewDetails.isDiscount = result['response']['is_discount'];
        this.viewDetails.isProductwiseDiscount = result['response']['is_productwise_discount'];
        this.viewDetails.discountInPercentage = result['response']['discount_in_percentage'];
        this.viewDetails.cgst = result['response']['cgst'];
        this.viewDetails.sgst = result['response']['sgst'];
        this.viewDetails.igst = result['response']['igst'];
        this.viewDetails.totalTaxAmount = result['response']['total_tax_amount'];
        this.viewDetails.totalAmount = result['response']['total_amount'];
        this.viewDetails.billingAddress = result['response']['billing_address'];
        this.viewDetails.shippingAddress = result['response']['shipping_address'];
        this.viewDetails.deliveryNote = result['response']['delivery_note'];
        this.viewDetails.vehicle_number = result['response']['vehicle_number'];
        this.viewDetails.eway_bill_no = result['response']['eway_bill_no'];
        this.viewDetails.delivery_challan_no = result['response']['delivery_challan_no'];
        this.viewDetails.currency = result['response']['customer']['currency']['name'];
        if (this.viewDetails.image) {
          this.path = this.apiService.getImageUrl(this.viewDetails.image.path);
        }

      });
  }
  viewSalesOrder() {
    let condition: any = {};
    condition['company_id'] = this.company_id;
    this.salesorderService
      .viewSalesorder(this.id, condition)
      .subscribe((result) => {
        this.viewDetails = {};
        this.viewDetails.party = result['response']['customer']['customer_name'];
        this.viewDetails.billNumber = result['response']['bill_number'];
        this.viewDetails.billDate = result['response']['billing_date'];
        this.viewDetails.orderNumber = result['response']['sales_order_number'];
        this.viewDetails.orderDate = result['response']['sales_order_date'];
        this.viewDetails.items = result['response']['sales_order_items'];
        this.viewDetails.actualTotalAmount = result['response']['actual_total_amount'];
        this.viewDetails.subTotalAmount = result['response']['actual_total_amount'] - result['response']['discount'];
        this.viewDetails.discount = result['response']['discount'];
        this.viewDetails.isDiscount = result['response']['is_discount'];
        this.viewDetails.isProductwiseDiscount = result['response']['is_productwise_discount'];
        this.viewDetails.discountInPercentage = result['response']['discount_in_percentage'];
        this.viewDetails.cgst = result['response']['cgst'];
        this.viewDetails.sgst = result['response']['sgst'];
        this.viewDetails.igst = result['response']['igst'];
        this.viewDetails.totalTaxAmount = result['response']['total_tax_amount'];
        this.viewDetails.totalAmount = result['response']['total_amount'];
        this.viewDetails.billingAddress = result['response']['billing_address'];
        this.viewDetails.shippingAddress = result['response']['shipping_address'];
        this.viewDetails.deliveryNote = result['response']['delivery_note'];
        this.viewDetails.currency = result['response']['customer']['currency']['name'];

      });
  }
  viewCreditNote() {
    let condition: any = {};
    condition['comapny_id'] = this.company_id;
    this.creditBillService
      .viewBill(this.id, condition)
      .subscribe(result => {
        this.viewDetails = {};
        this.viewDetails.party = result['customer']['customer_name'];
        this.viewDetails.ledger = result['ledger']['name'];
        this.viewDetails.orderNumber = result['credit_number'];
        this.viewDetails.orderDate = result['credit_date'];
        this.viewDetails.billNumber = result['bill_number'];
        this.viewDetails.billDate = result['sales_order_date'];
        this.viewDetails.items = result['invoice_items'];
        this.viewDetails.actualTotalAmount = result['actual_total_amount'];
        this.viewDetails.subTotalAmount = result['actual_total_amount'];
        this.viewDetails.cgst = result['cgst'];
        this.viewDetails.sgst = result['sgst'];
        this.viewDetails.igst = result['igst'];
        this.viewDetails.totalTaxAmount = result['total_tax_amount'];
        this.viewDetails.totalAmount = result['total_amount'];
        this.viewDetails.billingAddress = result['billing_address'];
        this.viewDetails.shippingAddress = result['shipping_address'];
        this.viewDetails.deliveryNote = result['delivery_note'];
        this.viewDetails.currency = result['customer']['currency']['name'];

      });
  }
  viewPurchaseOrder() {
    let condition: any = {};
    condition['company_id'] = this.company_id;
    this.purchaseorderService
      .viewPurchaseorder(this.id, condition)
      .subscribe(result => {
        this.viewDetails = {};
        this.viewDetails.party = result['response']['vendor']['vendor_name'];
        this.viewDetails.orderNumber = result['response']['purchase_order_number'];
        this.viewDetails.orderDate = result['response']['purchase_order_date'];
        this.viewDetails.items = result['response']['purchase_order_items'];
        this.viewDetails.actualTotalAmount = result['response']['actual_total_amount'];
        this.viewDetails.subTotalAmount = result['response']['actual_total_amount'];
        this.viewDetails.cgst = result['response']['cgst'];
        this.viewDetails.sgst = result['response']['sgst'];
        this.viewDetails.igst = result['response']['igst'];
        this.viewDetails.totalTaxAmount = result['response']['total_tax_amount'];
        this.viewDetails.totalAmount = result['response']['total_amount'];
        this.viewDetails.billingAddress = result['response']['billing_address'];
        this.viewDetails.shippingAddress = result['response']['shipping_address'];
        this.viewDetails.deliveryNote = result['response']['delivery_note'];
        this.viewDetails.currency = result['response']['vendor']['currency']['name'];
      });
  }
  viewPurchaseInvoice() {
    this.billService
      .viewBill(this.id)
      .subscribe(result => {
        this.viewDetails = {};
        this.viewDetails.party = result['response']['vendor']['vendor_name'];
        this.viewDetails.ledger = result['response']['ledger']['name'];
        this.viewDetails.billNumber = result['response']['bill_number'];
        this.viewDetails.billDate = result['response']['billing_date'];
        this.viewDetails.dueDate = result['response']['due_date'];
        this.viewDetails.orderNumber = result['response']['purchase_order_number'];
        this.viewDetails.orderDate = result['response']['purchase_order_date'];
        this.viewDetails.items = result['response']['bill_items'];
        this.viewDetails.balancingItems = result['response']['balancing_items'];
        this.viewDetails.actualTotalAmount = result['response']['actual_total_amount'];
        this.viewDetails.subTotalAmount = result['response']['sub_total_amount'];
        this.viewDetails.discount = result['response']['discount'];
        this.viewDetails.cgst = result['response']['cgst'];
        this.viewDetails.sgst = result['response']['sgst'];
        this.viewDetails.igst = result['response']['igst'];
        this.viewDetails.totalTaxAmount = result['response']['total_tax_amount'];
        this.viewDetails.totalAmount = result['response']['total_amount'];
        this.viewDetails.billingAddress = result['response']['billing_address'];
        this.viewDetails.shippingAddress = result['response']['shipping_address'];
        this.viewDetails.deliveryNote = result['response']['delivery_note'];
        this.viewDetails.currency = result['response']['vendor']['currency']['name'];
        if (this.viewDetails.image) {
          this.path = this.apiService.getImageUrl(this.viewDetails.image.path);
        }
      });
  }
  viewDebitNote() {
    let condition: any = {};
    condition['comapny_id'] = this.company_id;
    this.debitBillService
      .viewDebitBill(this.id, condition)
      .subscribe(result => {
        this.viewDetails = {};
        this.viewDetails.party = result['vendor']['vendor_name'];
        this.viewDetails.ledger = result['ledger']['name'];
        this.viewDetails.billNumber = result['bill_number'];
        this.viewDetails.billDate = result['billing_date'];
        this.viewDetails.orderNumber = result['debit_number'];
        this.viewDetails.orderDate = result['debit_date'];
        this.viewDetails.items = result['bill_items'];
        this.viewDetails.actualTotalAmount = result['actual_total_amount'];
        this.viewDetails.subTotalAmount = result['actual_total_amount'];
        this.viewDetails.cgst = result['cgst'];
        this.viewDetails.sgst = result['sgst'];
        this.viewDetails.igst = result['igst'];
        this.viewDetails.totalTaxAmount = result['total_tax_amount'];
        this.viewDetails.totalAmount = result['total_amount'];
        this.viewDetails.billingAddress = result['billing_address'];
        this.viewDetails.shippingAddress = result['shipping_address'];
        this.viewDetails.deliveryNote = result['delivery_note'];
        this.viewDetails.currency = result['vendor']['currency']['name'];
      });
  }
  viewExpenseInvoice() {
    this.expenseService
      .viewBillExpense(this.id)
      .subscribe((result) => {
        this.viewDetails = {};
        this.viewDetails.party = result['expense_object']['vendor']['vendor_name'];
        this.viewDetails.ledger = result['expense_object']['ledger']['name'];
        this.viewDetails.billNumber = result['expense_object']['bill_number'];
        this.viewDetails.billDate = result['expense_object']['billing_date']['singleDate']['jsDate'];
        this.viewDetails.dueDate = result['expense_object']['due_date']['singleDate']['jsDate'];
        this.viewDetails.items = result['expense_object']['bill_items'];
        this.viewDetails.balancingItems = result['expense_object']['balancing_items'];
        this.viewDetails.actualTotalAmount = result['expense_object']['actual_total_amount'];
        this.viewDetails.subTotalAmount = result['expense_object']['sub_total_amount'];
        this.viewDetails.isProductwiseDiscount = result['is_productwise_discount'];
        this.viewDetails.discountInPercentage = result['discount_in_percentage'];
        this.viewDetails.cgst = result['expense_object']['cgst'];
        this.viewDetails.sgst = result['expense_object']['sgst'];
        this.viewDetails.igst = result['expense_object']['igst'];
        this.viewDetails.totalTaxAmount = result['expense_object']['total_tax_amount'];
        this.viewDetails.totalAmount = result['expense_object']['total_amount'];
        this.viewDetails.billingAddress = result['billing_address'];
        this.viewDetails.shippingAddress = result['shipping_address'];
        this.viewDetails.deliveryNote = result['delivery_note'];
        this.viewDetails.currency = result['expense_object']['vendor']['currency']['name'];

      });
  }
  viewCompany() {
    this.companyService.viewCompany(this.company_id).subscribe(result => {
      this.company_details = result['response']
    })
  }

}
