import { FilterPipe } from './../_pipes/filter.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { from } from 'rxjs';
import { DashboardWidgetComponent } from './dashboard-widget/dashboard-widget.component';
import { DashboardComponent } from './../components/dashboard/dashboard.component';
import { ChartsComponent } from './charts/charts.component';
import { ReportComponent } from './report/report.component';
import { TabularReportComponent } from './tabular-report/tabular-report.component';
import { ReportsAddComponent } from './reports-add/reports-add.component';
import { PivotTableComponent } from './pivot-table/pivot-table.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardTemplateBaseComponent } from './dashboard-widget/dashboard-widget.component';
import { AddCustomizationComponent } from './add-customization/add-customization.component';
// import { ReportFiltersComponent } from './report-filters/report-filters.component';
import { DatafilterPipe } from '../_pipes/datafilter.pipe';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ReportCustomizationComponent } from './report-customization/report-customization.component';
import { ReportCustomizationAddComponent } from './report-customization/report-customization-add/report-customization-add.component';
import { DatalyticsAngularModule } from '@megamtech-labs/datalytics';
import { SortPipe } from '../_pipes/sort.pipe';


@NgModule({
  declarations: [
    ReportComponent,
    ReportsAddComponent,
    TabularReportComponent,
    PivotTableComponent,
    ChartsComponent,
    DashboardWidgetComponent,
    DashboardComponent,
    CardTemplateBaseComponent,
    AddCustomizationComponent,
    // ReportFiltersComponent,
    DatafilterPipe,
    ReportCustomizationComponent,
    ReportCustomizationAddComponent,
    SortPipe
  ],
  imports: [
    CommonModule, NgbModule, DatalyticsAngularModule, FormsModule, NgSelectModule,
    AngularMyDatePickerModule, NgxDatatableModule
  ],
  exports: [
    ReportComponent,
    ReportsAddComponent,
    TabularReportComponent,
    PivotTableComponent,
    ChartsComponent,
    DashboardWidgetComponent,
    DashboardComponent,
    CardTemplateBaseComponent,
    AddCustomizationComponent,
    SortPipe
    // ReportFiltersComponent,
    // Column
  ],
  providers: [
    FilterPipe,
  ]
})
export class ReportsModule { }
