import { Component, OnInit, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { ReceiptsService } from "../../_services/receipts.service";
import { AuthenticationService } from "../../_services/authentication.service";
import swal from "sweetalert2";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MastersService } from "../../_services/masters.service";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { ConstantService } from "src/app/_config/constants";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
@Component({
  selector: "app-receipts-vendor",
  templateUrl: "./receipts-vendor.component.html",
  styleUrls: ["./receipts-vendor.component.scss"],
})
export class ReceiptsVendorComponent implements OnInit {
  filters: any = {};
  page: any = { offset: 0, pageSize: 10 };
  sort: any = { vendor_name: 1 };
  dt_default_sort = [{ prop: "vendor_name", dir: "asc" }];
  limit = 10;
  count: any;
  vendors: any = [];
  columns: any = {};
  loadingIndicator = true;
  reorderable = true;
  vendorTableListColumns: any = [];
  currency: string;
  // @ViewChild('vendorTable', { static: true }) table: any;
  company_id: string;
  id: string;
  modalReference: any;
  payeeList: any = [];
  payeeListTypeahead = new EventEmitter<string>();
  paymentDateOptions: IAngularMyDpOptions = {
    dateRange: true,
    dateFormat: ConstantService.DateFormat
  };
  purchase_status_list: any = [
    { name: "UnPaid", id: 1 },
    { name: "Draft", id: 6 },
  ];

  companyDetails: any;
  selectFiYear: any;
  constructor(
    private receiptService: ReceiptsService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private payeeService: MastersService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.companyDetails = this.authenticationService.getCompanyDetails();
    this.currency = this.companyDetails.base_currency;

    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];
    this.filters.payment_date = {
      isRange: true,
      singleDate: null,
      dateRange: {
        beginDate: { year: this.selectFiYear.start_year, month: 4, day: 1 },
        beginJsDate: new Date(this.selectFiYear.start_year, 3, 1),
        endDate: { year: this.selectFiYear.end_year, month: 3, day: 31 },
        endJsDate: new Date(this.selectFiYear.end_year, 2, 31),
      }
    };

    this.getPayee("").subscribe((result) => {
      this.payeeList = result["response"];
    });
    this.getPayeesearch();
    this.vendorTableListColumns = [
      {
        prop: "party.name",
        name: "Vendor Name",
        sortable: "party.name",
        flexGrow: 2,
        show: true,
        is_detailed_row: false,
        type: "object",
      },
      {
        prop: "advance_amount",
        name: "Advance Amount",
        sortable: "advance_amount",
        flexGrow: 2,
        show: true,
        is_detailed_row: false,
        type: "currency",
        css_class: "align-right",
      },
      {
        prop: "advance_status_name",
        name: "Advance Status",
        sortable: "advance_status_name",
        flexGrow: 2,
        type:"status",
        show: true,
        is_detailed_row: false,
      },
      {
        prop: "payment_date",
        name: "Payment Date",
        sortable: "payment_date",
        flexGrow: 2,
        type:"date",
        show: true,
        is_detailed_row: false,
      },
      {
        prop: "description",
        name: "Description",
        sortable: "description",
        flexGrow: 2,
        show: true,
        is_detailed_row: false,
      },
      {
        name: "Action",
        type: "action",
        prop: "_id",
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false,
      },
    ];
    this.getPaginatedVendors(this.page);
  }
  getPaginatedVendors(pageInfo: any = {}) {
    this.filters["$or"] =[];
    if (pageInfo !== null) {
      this.page = pageInfo;
    }
    this.filters["company_id"] = this.company_id;
    this.filters["$or"] = Array(
      {"advance_status": {$eq:1}},
      {"advance_status": {$eq:2}},
      {"advance_status": {$eq:3}},
      {"advance_status": {$eq:6}}
    );
    // this.sort["vendor_name"] = 1;
    this.receiptService
      .getVendorsAdvance(this.filters, this.columns, this.sort, this.page)
      .subscribe((result) => {
        this.loadingIndicator = false;
        this.vendors = result["response"]["data"];
        this.count = result["response"]["count"];
      console.log('this.vendors====>>>><<<<', this.vendors);
      });
  }
  getPayee(searchString) {
    this.payeeList = [];
    return this.payeeService.getCoaHead(this.company_id, searchString, {
      in: [
        //ConstantService.coa_heads.Accounts_Receivable,
        ConstantService.coa_heads.Accounts_Payable,
      ],
    });
  }

  getPayeesearch() {
    this.payeeListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getPayee(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.payeeList = result["response"];
        },
        (err) => {
          console.log(err);
          this.payeeList = [];
        }
      );
  }

  open(content, id) {
    this.id = id;
    this.modalReference = this.modalService.open(content, { size: "lg" });
  }

  clearPurchaseAdvance() {
    this.filters = {};
    this.getPaginatedVendors({ offset: 0, pageSize: 12 });
  }
  onSort(event) {
    this.loadingIndicator = true;
    console.log("event", event);
    this.sort = {};
    let sort_details = event.sorts[0];
    this.sort[sort_details.prop] = sort_details.dir == "asc" ? 1 : -1;
    this.getPaginatedVendors(this.page);
  }
  deleteVendorAdvance(row_data) {
    //cancel status for the advance and remove the account transaction .
    this.receiptService.cancelAdvance(row_data).subscribe((result) => {
      this.loadingIndicator = false;
      if (result["ok"]) {
        swal("Success", "Advance has been cancelled successfully", "success");
      } else {
        swal("Error", "Cant able to cancel the advance", "error");
      }
    });
    this.getPaginatedVendors(this.page);
  }
  confirmAdvanceStatus(row_data){
    swal({
      title: "Approve the Purchase Advance ?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Approve it!",
    }).then((result) => {
      if (result) {
        this.receiptService.confirmPurchaseAdvance(row_data,this.company_id).subscribe((result) => {
          if (result["ok"] === 1) {
            swal("Success!", "Purchase Advance has been approved.", "success");
            this.getPaginatedVendors({});
          }
        });
      }
    });

}
}
