import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "tx_type",
  pure: false,
})
export class TxTypePipe implements PipeTransform {
  transform(tx_type_id: number): string {
    let transaction_types: any = [
      { 1: "Bill" },
      { 11: "INVOICE" },
      { 21: "PAYMENT" },
      { 31: "JOURNAL" },
      { 41: "CONTRA" },
      { 51: "EXPENSE" },
      { 61: "SALES ADVANCE" },
      { 71: "PURCHASE ADVANCE" },
      { 81: "CREDIT NOTE" },
      { 91: "DEBIT NOTE" },
      { 101: "STOCK JOURNAL" },
      { 111: "RECEIPTS" },
    ];
    let data = "";
    if (tx_type_id) {
      data = transaction_types.filter((item) => item[tx_type_id]);

      return data[0][tx_type_id];
    } else {
      return "";
    }
  }
}

@Pipe({
  name: "ref_link",
  pure: false,
})
export class CreateLinkPipe implements PipeTransform {
  public transform(
    tx_ref_data: any,
    filterKey: string,
    filterValue: string
  ): any {
    // console.log('tx_ref_data', tx_ref_data)
    let link: any = [];
    let id_heads: any = ["bill_id", "invoice_id"];
    tx_ref_data.forEach((element) => {
      let keys = Object.keys(element);
      keys.forEach((data) => {
        if (id_heads.includes(data)) {
          switch (true) {
            case data == "bill_id":
              link.push({
                url: "/bill/view/" + element[data],
                name: "bill_id",
              });
              break;
          }
        }
      });
    });
    // console.log('test======', link)

    return link;
  }
}

@Pipe({
  name: "to_by",
  pure: false,
})
export class ToByPipe implements PipeTransform {
  transform(row: any = {}): string {
    let data = "";
    if (row.credit) {
      data = "To";
    } else {
      data = "By";
    }
    return data;
  }
}

@Pipe({
  name: "voucherType",
  pure: false,
})
export class VoucherTypePipe implements PipeTransform {
  transform(row: any): string {
    let data = "";
    if (row.credit) {
      data = "Cash Receipt";
    } else {
      data = "Cash Payment";
    }
    return data;
  }
}
