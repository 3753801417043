<div class="row">
    <h2>{{reportHeader ? reportHeader:reportName?.default_text ? reportName.default_text : reportName}}</h2>
</div>
<div *ngIf="tableColumns?.length>0">

    <datalytics-report-filters (getFilterTypeaheadData)="getFilterTypeaheadData($event)"
        (getFilterData)="search($event)" [dynamicFilterData]="dynamicFilterData" [filters]="tableColumns"
        [searchOnLoad]="false" [displayInline]="true" [name]="'Tabular Report'">
    </datalytics-report-filters>
    <div class="mt-2 mb-2" *ngIf="!pressSearch  && dataExists">
        <div>
            <button type="button" class="btn btn-primary mr-2" (click)="generatePdf()">Export pdf</button>
            <button type="button" class="btn btn-primary" (click)="getReportData('excel')">Export Excel</button>
        </div>

    </div>


    <div *ngIf="pressSearch" class="alert alert-primary text-center" role="alert">

        Please click 'search' to get data!
    </div>
    <div *ngIf="!pressSearch && isLoading" class="alert alert-primary text-center" role="alert">

        Loading data please wait...!
    </div>

    <!-- <div *ngIf="tableList.count == 0 && !pressSearch" class="alert alert-warning text-center" role="alert">
        No data found for this search data.
    </div> -->
    <div *ngIf="!pressSearch && tableList?.data?.length>0 && sideBySide==false && dataExists && reportType !='tree'  && !isLoading && reportType!=2"
        class="card">
        <div class="card-body">
            <datalytics-tabular-report [defaultSort]="defaultSort" [data]="tableList" [pageSize]="pagination.pageSize"
                [columns]="tableColumns" [currentPage]="pagination.currentPage" (pageChange)="pageChange($event)" (sortChange)="applySort($event)"
                searchOnLoad="false">
            </datalytics-tabular-report>
            <ng-template #runningTotal let-data let-row>
            </ng-template>
        </div>
    </div>

    <!-- <div *ngIf="(reportType=='tree' || sideBySide) && !dataExists && !pressSearch" class="alert alert-danger text-center" role="alert">
        No Records found
    </div> -->
    <div *ngIf="!dataExists && !pressSearch && !isLoading" class="alert alert-danger text-center" role="alert">
        No Records found
    </div>
    <div id="sideBySideReport" *ngIf="sideBySide && dataExists && !isLoading" class="container bgc-white pb-5"
        style="background-color:#fff">
        <div class="row" *ngIf="finalTable.length>0">
            <table width="100%">
                <td width="50%" class="col-6 mt-3" *ngFor="let multidata of finalTable; let i = index"
                    [ngClass]="{'left':(i%2==0), 'right':(i%2!=0)}" style="vertical-align: top;">
                    <h3 class="text-center">{{reportDetails[i]}}</h3>
                    <div class="pdfTable">
                        <table width="100%">
                            <tr *ngIf="i!=2 && i!=3">
                                <th width="50%" class="text-center">Particular</th>
                                <th width="50%" class="text-center" colspan="2">Amount</th>
                            </tr>
                            <ng-template [ngTemplateOutlet]="tree" [ngTemplateOutletContext]="{ $implicit: multidata }">
                            </ng-template>
                        </table>

                    </div>
                    <!-- <table width=100%>
                    <tr *ngIf="reportcolumns.length>0">
                        <th *ngFor="let columName of reportcolumns[i]">{{columName}}</th>
                    </tr>
                    <tr>
                        <th>Particular</th>
                        <th>Credit</th>
                        <th>Debit</th>
                    </tr>
                    <tr *ngFor="let data of multidata">
                        <td>{{data._id}}</td>
                        <td class="text-right">{{data.total_credit | currency:'INR'}}</td>
                        <td class="text-right">{{data.total_debit | currency:'INR'}}</td>
                    </tr>
                </table> -->
                </td>
            </table>
        </div>
        <!-- <div class="row" *ngIf="sumMultiTable.length>0">
            <div class="col-6" *ngFor="let sumTotal of sumMultiTable">
                <table width=100%>
                    <tr *ngFor="let data of sumTotal">
                        <td>Total</td>
                        <td class="text-right">{{data.credit | currency:'INR'}}</td>
                        <td class="text-right">{{data.debit | currency:'INR'}}</td>
                    </tr>
                </table>

            </div>
        </div>-->
        <div class="row" *ngIf="profitTable.length==2 && reportDetails.includes('Expense')">
            <table width="100%">
                <td width="50%" class="col-6" *ngFor="let profits of profitTable; let i=index"
                    [ngClass]="{'left':(i%2==0), 'right':(i%2!=0)}">
                    <div class="pdfTable">
                        <table width="100%" class="font-weight-bold">
                            <tr *ngFor="let profit of profits.profit">
                                <td>{{profit.name}}</td>
                                <td class="text-right">{{profit.amount |currency:'INR'}}</td>
                            </tr>
                        </table>
                    </div>
                </td>
            </table>
        </div>
    </div>

</div>
<div *ngIf="reportType=='tree' && treeData?.childrens && dataExists  && !isLoading" class="mb-5 card">
    <div class="row">
        <div class="col-2 p-2 pl-3">
            <div class="form-group form-check">
                <input type="checkbox" name="is_opening" class="form-check-input" [(ngModel)]="is_opening"
                    id="exampleCheck1">
                <label class="form-check-label" for="exampleCheck1">Show Opening</label>
            </div>
        </div>
        <div class="col-2 p-2">
            <div class="form-group form-check">
                <input type="checkbox" class="form-check-input" [(ngModel)]="is_transaction" name="is_transaction"
                    id="exampleCheck2">
                <label class="form-check-label" for="exampleCheck2">Show Transaction</label>
            </div>
        </div>
    </div>
    <div class="card-body pdfTable" id="tree">
        <table width="100%">
            <tr>
                <th width="40%" class="text-center" rowspan="2">Particulars</th>
                <ng-container *ngIf="is_opening">
                    <th width="20%" class="text-center" colspan="2">Opening Balance</th>
                </ng-container>
                <ng-container *ngIf="is_transaction">
                    <th width="20%" class="text-center" colspan="2">Transaction</th>
                </ng-container>

                <th width="20%" class="text-center" colspan="2">Closing Balance</th>

            </tr>
            <tr>

                <ng-container *ngIf="is_opening">
                    <th class="text-center">Debit</th>
                    <th class="text-center">Credit</th>
                </ng-container>

                <ng-container *ngIf="is_transaction">
                    <th class="text-center">Debit</th>
                    <th class="text-center">Credit</th>
                </ng-container>
                <th class="text-center">Debit</th>
                <th class="text-center">Credit</th>
            </tr>

            <ng-template [ngTemplateOutlet]="tree" [ngTemplateOutletContext]="{ $implicit: treeData }">
            </ng-template>
        </table>
    </div>
</div>


<ng-template #tree let-node>
    <!-- <table width="100%"> -->
    <tr *ngIf="node.name !=''">
        <td [ngStyle]="{'padding-left': node.padding+'px' }"
            [ngClass]="{'grouptotal':node.padding==0|| node.grand_total}">{{node.name}}</td>
        <ng-container *ngIf="!sideBySide">
            <ng-container *ngIf="is_opening">
                <td class="text-right" style="word-wrap: break-word"
                    [ngClass]="{'grouptotal': node.padding==0 || node.grand_total}">{{(node.ob_debit)|currency:'INR'}}
                </td>
                <td class="text-right" style="word-wrap: break-word"
                    [ngClass]="{'grouptotal': node.padding==0 || node.grand_total}">{{(node.ob_credit)|currency:'INR'}}
                </td>
            </ng-container>

            <ng-container *ngIf="is_transaction">
                <td class="text-right" style="word-wrap: break-word"
                    [ngClass]="{'grouptotal': node.padding==0 || node.grand_total}">
                    {{(node.total_debit)|currency:'INR'}}</td>
                <td class="text-right" style="word-wrap: break-word"
                    [ngClass]="{'grouptotal': node.padding==0 || node.grand_total}">
                    {{(node.total_credit)|currency:'INR'}}</td>
            </ng-container>
            <td class="text-right" style="word-wrap: break-word"
                [ngClass]="{'grouptotal': node.padding==0 || node.grand_total}">{{(node.cb_debit)|currency:'INR'}}</td>
            <td class="text-right" style="word-wrap: break-word"
                [ngClass]="{'grouptotal': node.padding==0 || node.grand_total}">{{(node.cb_credit)|currency:'INR'}}</td>
        </ng-container>
        <ng-container *ngIf="sideBySide">
            <td style="word-wrap: break-word" class="pr0"
                [ngClass]="{'grouptotal': node.padding==0 || node.grand_total}">{{( (node.padding==0 ||
                node.grand_total) ? '': node.total)|currency:'INR'}}</td>
            <td style="word-wrap: break-word" class="pr0"
                [ngClass]="{'grouptotal': node.padding==0 || node.grand_total}">{{( (node.padding==0 ||
                node.grand_total) ? node.total:'' )|currency:'INR'}}</td>

        </ng-container>
    </tr>
    <!-- </table> -->
    <ng-container *ngIf="node.childrens?.length>0">
        <ng-template ngFor [ngForOf]="node.childrens|sort: 'asc':'seq_no'" [ngForTemplate]="tree">
            <!-- <ng-template ngFor [ngForOf]="node.childrens" [ngForTemplate]="tree"> -->

            <!-- NOTE: The "$implicit" property of the ngFor context is what will
                                              be made available to the template ref's implicit let-node binding. -->

        </ng-template>
    </ng-container>

</ng-template>

<div *ngIf="reportType==2" class="card">

    <datalytics-pivot-table class="width" [keepColumns]="rowColumns" [groupColumns]="groupColumns"
        [summaryColumns]="summaryColumns" [data]="tableList.data"
        [settings]="{rowTotal:true,columnTotal:true,rowTotalText:'Total',columnTotalText:'Total',rowTotalPosition:'end'}">
    </datalytics-pivot-table>
</div>
