import {
  Component,
  OnInit,
  Input,
  OnChanges,
  EventEmitter,
  Output,
  TemplateRef,
  ViewChild,
  SimpleChanges,
} from "@angular/core";
import swal from "sweetalert2";
import { ReportsService } from "../../_services/reports.service";
import { DatePipe } from "@angular/common";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { Column } from "@app/reports/column";
class DateOnlyPipe extends DatePipe {
  public transform(value): any {
    return super.transform(value, "dd/MMM/yy hh:mm");
  }
}
@Component({
  selector: "app-tabular-report",
  templateUrl: "./tabular-report.component.html",
  styleUrls: ["./tabular-report.component.css"],
})
export class TabularReportComponent implements OnInit, OnChanges {
  // @Input() customization_obj: any = {};
  // @Input() report_data: any;
  // @Input() page: any;
  // @Output() getNextPage = new EventEmitter<string>();
  @ViewChild("actionTmpl", { static: true }) actionTmpl: TemplateRef<any>;
  // tabularListsColumns = [];
  // tabularLists: any;
  // customizationList: any = [];
  // customizationObj: any = {};
  // encoded_data: any = {};
  // filters: any = {
  //   customization_id: null
  // }
  @Input() actionTemplate: TemplateRef<any>;
  @Input() pageSize: any = 10;
  @Input() columns: Column[];
  @Input() serverSideSort: any;
  @Input() offset: any = 0;
  @Output() pageChange = new EventEmitter();
  @Output() sortChange = new EventEmitter();
  // tslint:disable-next-line:variable-name
  _data = [];
  // tslint:disable-next-line:variable-name
  _count: any;
  pagination: any = {};
  _totals: [{ name: string; value: number }];
  constructor(
    private reportsservice: ReportsService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.pagination = { pageSize: this.pageSize, offset: this.offset };
  }

  @Input()
  get data() {
    console.log("!!!!!!!!!11111");
    return this.data;
  }

  set data(value: { data: any[]; count: number; totals?: any }) {
    this._data = value.data;
    console.log("!!!!!!!!!2222222", this._data);
    this._count = value.count;
    this._totals = value.totals ? value.totals : [];
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      console.log("data changed", changes.data);
      this._setData(changes.data.currentValue || []);
    }
  }
  private _setData(data: { data: any[]; count: number }) {
    this._data = data.data;
    this._count = data.count;
  }
  onPageChangeEvent($data) {
    this.pageChange.emit($data);
  }
  onSortChangeEvent($data) {
    console.log($data);
    let sorts = {};
    $data.sorts.forEach((sortColumn) => {
      sorts[sortColumn.prop] = sortColumn.dir === "asc" ? 1 : -1;
    });
    // this.sort[sort_details.prop] = sort_details.dir == "asc" ? 1 : -1;
    this.sortChange.emit(sorts);
  }
  ngOnInit() {
    // this.pagination = { pageSize: 10, limit: 10, offset: 0 };
    // this.applyColumnCustomization();
    //   this.columns.forEach(element => {
    //     if (element.prop == 'action' && this.actionTemplate != undefined) {
    //       element.cellTemplate = this.actionTemplate;
    //     }
    //   });
    // }
    // getCellClass({ row, column, value }) {
    //   return column;
    // }
    // applyColumnCustomization() {
    //   let column_array = Object.keys(this.customization_obj['columns']);
    //   var temp = [];
    //   column_array.forEach((column_name, key) => {
    //     // if (this.customization_obj['columns'][column_name]['type'] != 'date') {
    //     // }
    //     if (this.customization_obj['columns'][column_name]['type'] == 'date') {
    //       temp.push({ name: this.customization_obj['columns'][column_name]['displayname'], prop: column_name, pipe: new DateOnlyPipe("en-US") })
    //     } else if (typeof this.customization_obj['columns'][column_name]['link'] === 'object') {
    //       temp.push({ name: this.customization_obj['columns'][column_name]['displayname'], cellTemplate: this.actionTmpl, prop: column_name })
    //     } else {
    //       temp.push({ name: this.customization_obj['columns'][column_name]['displayname'], prop: column_name })
    //     }
    //     // console.log(typeof this.customization_obj['columns'][column_name]['filter']['link'] === 'object');
    //     // if () {
    //     //   // { name: "",  prop: "_id" }
    //     //   // @ViewChild(column_name) column_name: TemplateRef<any>;
    //     //   console.log('Testing123==>>', column_name)
    //     // }
    //   });
    //   this.tabularListsColumns = temp;
    // }
    // getPaginatedData(page_info: any = {}) {
    //   this.getNextPage.next(page_info);
    // }
  }
}
