<div class="auth">
  <div class="auth_left">
    <div class="card">
      <div class="text-center mb-5">
        <a class="header-brand" href="javascript:void(0);"><i class="fe fe-command brand-logo"></i></a>
      </div>
      <div class="card-body">
        <div class="card-title">Forgot password</div>
        <p class="text-muted">Enter your user name your password will be reset and emailed to you.</p>
        <div class="form-group">
          <label class="form-label required" for="username">User Name</label>
          <input type="text" required class="form-control" id="username" [(ngModel)]="username" placeholder="Username">
        </div>
        <div class="form-footer">
          <button type="button" (click)="forgotPassword()" class=" btn btn-primary btn-block">Send me new
            password</button>
        </div>
      </div>
      <div class="text-center text-muted">
        Forget it, <a routerLink="/login">Send me Back</a> to the Sign in screen.
      </div>
    </div>
  </div>
  <div class="auth_right">
    <div class="carousel slide" data-ride="carousel" data-interval="3000">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="assets/images/slider1.svg" class="img-fluid" alt="forgot password page" />
          <div class="px-4 mt-4">
            <h4>Accounting ERP</h4>
            <p>Accounting Made Easy</p>
          </div>
        </div>
        <div class="carousel-item">
          <img src="assets/images/slider2.svg" class="img-fluid" alt="forgot password page" />
          <div class="px-4 mt-4">
            <h4>Accounting ERP</h4>
            <p>There are many variations of passages of Lorem Ipsum available.</p>
          </div>
        </div>
        <div class="carousel-item">
          <img src="assets/images/slider3.svg" class="img-fluid" alt="forgot password page" />
          <div class="px-4 mt-4">
            <h4>Accounting ERP</h4>
            <p>Conducting interviews and filtering candidates for open positions</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>