<!-- <div class="container-fluid"> -->
<!-- <div class="row"> -->
<!-- <div class="col-lg-12"> -->
<ngb-alert (close)="closed=true" *ngIf="company_details.is_sez !='' && company_details.is_sez == true && !closed">
  This company is in "SEZ" so taxes not applicable
</ngb-alert>
<ngb-alert (close)="closed=true" *ngIf="bill.vendor?.is_composite_company == true && !closed">
  Composite vendor selected
</ngb-alert>
<div class="card">
  <div class="card-header d-flex align-items-center col-12">
    <h3 class="table-title col-3">{{ header }}</h3>
    <h6 *ngIf="is_bill_invoice || is_credit_note" class="ml-3 col-5 pt-2">
      PO-Number / PO-Date
      <span class="badge badge-primary ml-2 mb-2">
        {{ bill.purchase_order_number }} /
        {{ bill.purchase_order_date | date }}
      </span>
    </h6>
    <!-- <div *ngIf="!is_bill_invoice && is_credit_note==false" class="col-5"></div> -->

  </div>
  <form novalidate #addBillForm="ngForm" (ngSubmit)="addUpdateBill()" enctype="multipart/form-data">
    <div class="card-body row">
      <div class="form-group required col-8 col-sm-8 col-md-8 col-xl-5">
        <label for="vendor">Vendor</label>
        <ng-select *ngIf="is_credit_note==false" [items]="vendor_lists" [typeahead]="vendorListTypeahead"
          #vendor="ngModel" required name="vendor" (change)="onChangeVendor();" bindLabel="vendor_name"
          [(ngModel)]="bill.vendor" placeholder="Select Vendor">
          <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div [ngOptionHighlight]="search">
              {{ item.vendor_name }} - ({{ item.currency?.name }})
            </div>
          </ng-template>
        </ng-select>
        <div *ngIf="is_credit_note">
          {{ bill?.vendor?.vendor_name }}
        </div>

        <app-validation-errors [mformField]="vendor" label="Vendor"></app-validation-errors>
      </div>

      <div *ngIf="bill?.vendor?.currency?.name || bill?.currency?.name" class="col-1 col-lg-1 col-lg-1 col-md-1">
        <label class="invisible">Currency</label>
        <span class="badge badge-primary">
          {{is_debit_note? bill.currency.name: bill.vendor.currency.name}}
        </span>
      </div>
      <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 required"
        *ngIf="is_credit_note==false && is_expense==false">
        <label for="credit_notes">Purchase Ledger</label>
        <app-ledgers (onSelectLedger)="updateLedger($event)" [ledgerAccountsHeads]="billAddAccountConfig">
        </app-ledgers>
      </div> -->
      <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 required" *ngIf="is_credit_note ==false && user_id !=8">
        <label for="credit_notes">{{labelName}} Ledger</label>
        <app-ledgers (onSelectLedger)="updateLedger($event)" [ledgerAccountsHeads]="billAddAccountConfig">
        </app-ledgers>
      </div>

      <div class="form-group  required col-12 col-sm-6 col-md-3 col-xl-3" *ngIf="voucher_type_lists?.length >1 ">
        <label class="" for="voucher_type">Voucher Type</label>
        <ng-select *ngIf="!is_debit_note" [items]="voucher_type_lists" #voucher_type="ngModel" required
          name="voucher_type" bindLabel="voucher_type" [(ngModel)]="bill.voucher_type"
          placeholder="Select Voucher Type">
          <!-- <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
           <div [ngOptionHighlight]="search">
             {{ item.voucher_type }}
           </div>
         </ng-template> -->
        </ng-select>
        <!-- <app-voucher-type [defaultVoucher]="voucherListConfig" (onSelectVoucher)="getVoucherType($event)" [voucherCategoryCode]=2></app-voucher-type> -->
        <!-- <ng-select name="voucher_type" #voucher_type="ngModel" [loading]="loading" [items]="voucherTypeList"
          [typeahead]="voucherTypeTypeahead" bindLabel="name" [(ngModel)]="bill.voucher_type"
          placeholder="Select Voucher Type">

          <ng-template ng-label-tmp ng-option-tmp let-item="item" bindLabel="card_number" let-search="searchTerm">
            <div [ngOptionHighlight]="search">

                {{item.name}}

            </div>
          </ng-template>
          <ng-template ng-footer-tmp>

            <button class="btn btn-lg btn-outline-primary" (click)="open(content)">Add</button>


          </ng-template>
        </ng-select> -->

        <!-- <app-validation-errors [mformField]="group" label="group">
        </app-validation-errors> -->

      </div>

      <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 required" *ngIf="!is_credit_note_add">
        <label for="bill_number">{{labelName}} Number</label>
        <div *ngIf="is_credit_note">
          {{ bill?.bill_number }}
        </div>
        <div *ngIf="is_credit_note == false || is_credit_note == false">
          <input type="text" class="form-control" #bill_number="ngModel" name="bill_number"
            placeholder="{{ bill_label_name }} Number" [(ngModel)]="bill.bill_number" />
        </div>
      </div>

      <div *ngIf="is_credit_note==true || is_credit_note_add"
        class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
        <label for="debit_number">Debit Note Number</label>
        <input class="form-control" required name="credit_number" #debit_number="ngModel" placeholder="Debit Number"
          [(ngModel)]="bill.credit_number" />
        <app-validation-errors [mformField]="debit_number" label="Debit Number">
        </app-validation-errors>

      </div>

      <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3" *ngIf="!is_credit_note_add">
        <label for="billing_date">{{labelName}} Date</label>
        <div class="input-group" *ngIf="is_credit_note==false">
          <input class="form-control" #bill_date="ngModel" name="bill_date" angular-mydatepicker
            #dp_bill="angular-mydatepicker" required placeholder="Select a date" (dateChanged)="setDueDate($event)"
            (click)="dp_bill.toggleCalendar()" [(ngModel)]="bill.billing_date" [options]="billDateOptions" />

          <div class="input-group-append">
            <button type="button" class="btn btn-secondary" *ngIf="bill.billing_date" (click)="dp_bill.clearDate()">
              <i class="fa fa-close"></i>
            </button>
          </div>
          <div class="input-group-append">
            <button type="button" class="btn btn-secondary" (click)="dp_bill.toggleCalendar()">
              <i class="fa fa-calendar-o"></i>
            </button>
          </div>
        </div>
        <div *ngIf="is_credit_note" class="">
          {{ bill.billing_date | date }}
        </div>
        <app-validation-errors [mformField]="bill_date" label="bill Date">
        </app-validation-errors>
      </div>
      <div class="form-group col-12 required col-sm-6 col-md-3 col-xl-3" *ngIf="!is_credit_note_add">
        <label for="due_date">Due Date</label>
        <div class="input-group" *ngIf="is_credit_note==false">
          <input class="form-control" #due_date="ngModel" name="due_date" placeholder="Select a date"
            angular-mydatepicker #dp_duedate="angular-mydatepicker" [(ngModel)]="bill.due_date"
            [options]="dueDateOptions" (click)="dp_duedate.toggleCalendar()" />
          <div class="input-group-append">
            <button type="button" class="btn btn-secondary" *ngIf="bill.due_date" (click)="dp_duedate.clearDate()">
              <i class="fa fa-close"></i>
            </button>
          </div>
          <div class="input-group-append">
            <button type="button" class="btn btn-secondary" (click)="dp_duedate.toggleCalendar()">
              <i class="fa fa-calendar-o"></i>
            </button>
          </div>
        </div>
        <div *ngIf="is_credit_note">
          {{ bill.due_date | date }}
        </div>
        <app-validation-errors [mformField]="due_date" label="Due Date">
        </app-validation-errors>
      </div>


      <div class="form-group col-12 custom-switch col-sm-6 col-md-3 col-xl-3 pl-5" *ngIf="is_credit_note==false">
        <input type="checkbox" value="true" [(ngModel)]="bill.is_without_gst" name="is_without_gst"
          class="custom-control-input" id="is_without_gst" (change)="onCheckboxChange();">
        <label class=" custom-control-label" for="is_without_gst">Without GST ?</label>
      </div>


      <!-- <div *ngIf="addForm && is_expense==false" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
        <label>Select File To Upload</label>
        <input ngfSelect type="file" class="form-control" [(files)]="files" />
      </div> -->


      <div *ngIf="is_bill_invoice==false && is_credit_note==false && is_expense==false && is_credit_note_add ==false"
        class=" form-group col-12 col-sm-6 col-md-3 col-xl-3">
        <label for="purchase_order_date">PO Date</label>
        <div class="input-group">
          <input class="form-control" #purchase_order_date="ngModel" name="purchase_order_date" angular-mydatepicker
            #dp_purchase_order_date="angular-mydatepicker" placeholder="Select a date"
            (click)="dp_purchase_order_date.toggleCalendar()" [(ngModel)]="bill.purchase_order_date"
            [options]="billPurchaseDateOptions" />

          <div class="input-group-append">
            <button type="button" class="btn btn-secondary" *ngIf="bill.purchase_order_date"
              (click)="dp_purchase_order_date.clearDate()">
              <i class="fa fa-close"></i>
            </button>
          </div>
          <div class="input-group-append">
            <button type="button" class="btn btn-secondary" (click)="dp_purchase_order_date.toggleCalendar()">
              <i class="fa fa-calendar-o"></i>
            </button>
          </div>
        </div>

        <app-validation-errors [mformField]="purchase_order_date" label="Purchase Order Date">
        </app-validation-errors>
      </div>


      <div *ngIf="is_credit_note==false && is_expense==false && is_credit_note_add==false"
        class="form-group col-12 col-sm-6 col-md-3 col-xl-3 ">
        <label for="purchase_order_number">PO Number</label>
        <ng-select *ngIf="is_credit_note==false" (change)="redirectUrl('bill')" bindValue="purchase_order_number"
          [items]="purchaseorder_lists" [typeahead]="purchaseOrderListTypeahead" #purchase_order_number="ngModel"
          name="purchase_order_number" bindLabel="purchase_order_number" [(ngModel)]="bill.purchase_order_number"
          [addTag]="true" placeholder="Select Purchase Order">
          <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div [ngOptionHighlight]="search">
              {{ item.purchase_order_number }}
            </div>
          </ng-template>
        </ng-select>

        <!-- <input type="text" class="form-control" #purchase_order_number="ngModel" name="purchase_order_number"
                  placeholder="Purchase Order Number" [(ngModel)]="bill.purchase_order_number" /> -->
        <app-validation-errors [mformField]="purchase_order_number" label="Purchase Order Number">
        </app-validation-errors>
      </div>


      <div *ngIf="bill.vendor?.tds?.length>1" class="form-group col-12 col-md-6 col-lg-6 col-xl-6">
        <label for="tds">TDS</label>
        <!-- [multiple]="false" -->
        <ng-select (change)="calculateTDSAmount()" class="hsn_select" [items]="bill.vendor.tds" name="tds"
          bindLabel="name" [(ngModel)]="tds" placeholder="Select TDS">
          <ng-template ng-label-tmp let-item="item" let-index="index" let-search="searchTerm">
            <p class="text-wrap">{{ item.nature_of_payment}}</p>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div [ngOptionHighlight]="search">
              <p class="text-wrap"> {{ item.nature_of_payment}} - <b> {{item.section}}</b></p>
              <ng-container>
                <small *ngFor="let value of item.basic_cut_off"> Greaterthan {{ value.greater_than }} for
                  {{value.period}}</small>
              </ng-container>
            </div>
          </ng-template>
        </ng-select>
      </div>

      <div class="form-group col-12 required col-sm-6 col-md-3 col-xl-3" *ngIf="is_credit_note || is_credit_note_add">
        <label for="debit_date">Debit Note Date</label>
        <div class="input-group">
          <input class="form-control" required #debit_date="ngModel" name="debit_date" placeholder="Select a date"
            angular-mydatepicker #dp_creditdate="angular-mydatepicker" [(ngModel)]="bill.debit_date"
            [options]="billDateOptions" (click)="dp_creditdate.toggleCalendar()" />
          <div class="input-group-append">
            <button type="button" class="btn btn-secondary" *ngIf="bill.debit_date"
              (click)="dp_creditdate.clearDate()">
              <i class="fa fa-close"></i>
            </button>
          </div>
          <div class="input-group-append">
            <button type="button" class="btn btn-secondary" (click)="dp_creditdate.toggleCalendar()">
              <i class="fa fa-calendar-o"></i>
            </button>
          </div>
        </div>
        <!-- <div *ngIf="is_credit_note">
                {{bill.credit_date}}
              </div> -->
        <app-validation-errors [mformField]="debit_date" label="Debit Date">
        </app-validation-errors>
      </div>

      <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3" *ngIf="is_credit_note">
        <label for="credit_notes">Account:</label>
        <div>
          {{ bill.ledger.name }}
        </div>
      </div>

      <!-- <div class="row col-12 mb-3">
              <div class="form-group col-12 custom-switch col-sm-6 col-md-3 col-xl-3 pl-5" *ngIf="is_credit_note==false">
                <input type="checkbox" value="true" [(ngModel)]="bill.is_discount" name="is_discount"
                  class="custom-control-input" (change)="getCoaData();unSetProductWiseDiscount();getTotalAmount();"
                  id="is_discount">
                <label class="custom-control-label" for="is_discount">Discount ?</label>
              </div>
              <div class="form-group col-12 custom-switch col-sm-6 col-md-3 col-xl-3 pl-5"
                *ngIf="bill.is_discount==true && is_credit_note==false">
                <input type="checkbox" value="true" [(ngModel)]="bill.is_productwise_discount"
                  name="is_productwise_discount" (change)="unSetProductWiseDiscount();getCoaData();getTotalAmount();"
                  class="custom-control-input" id="is_productwise_discount">
                <label class="custom-control-label" for="is_productwise_discount">Product-Wise
                  Discount</label>
              </div>
              <div class="form-group col-12 custom-switch col-sm-6 col-md-3 col-xl-3"
                *ngIf="bill.is_discount==true && is_credit_note==false">
                <input type="checkbox" value="true" [(ngModel)]="bill.discount_in_percentage"
                  name="discount_in_percentage" (change)="unSetProductWiseDiscount();getTotalAmount();"
                  class="custom-control-input" id="discount_in_percentage">
                <label class="custom-control-label" for="discount_in_percentage">Discount In %</label>
              </div>
            </div> -->

      <div class="card">
        <div class="card-body form-row p-0 pb-3">
          <table class="col-12 table table-stripped table-hover table-bordered">
            <thead class="thead-light">
              <tr>
                <th style="width: 4%;">S.No</th>
                <th style="width: 40%;" class="required">{{company_details?.purchase_column_settings?.items}}
                </th>
                <!-- <th>{{company_details?.purchase_column_settings?.description}}</th> -->
                <th style="width: 10%;" *ngIf="!is_expenses == true">
                  {{company_details?.purchase_column_settings?.units}}
                </th>
                <th style="width: 10%;">{{company_details?.purchase_column_settings?.price}}
                </th>
                <th style="width: 8%;" *ngIf="bill.is_without_gst==false">Tax</th>

                <th style="width: 8%;">Amount</th>
                <th style="width: 12%;">Action</th>
              </tr>
            </thead>
            <tbody *ngFor="let itemsObj of bill.bill_items; let i = index">
              <tr *ngIf="is_credit_note">
                <td>
                  {{ i + 1 }}

                </td>
                <td>
                  <div *ngIf="is_credit_note">
                    {{ itemsObj?.product?.name }}
                  </div>
                </td>
                <!-- <td>
                        <input type="text" *ngIf="is_credit_note==false" class="form-control" name="description{{i}}"
                          id="description{{i}}" placeholder="Description" [(ngModel)]="itemsObj.description">
                        <div *ngIf="is_credit_note">
                          {{ itemsObj.description }}
                        </div>
                      </td> -->


                <td *ngIf="is_credit_note">
                  <input [disabled]="itemsObj?.product?.type == 2" type="number" [min]="1" [max]="itemsObj.max_units"
                    class="form-control" #units="ngModel" required name="units{{ i }}" id="units{{ i }}"
                    placeholder="Units" [(ngModel)]="itemsObj.units" (change)="getTaxAmount(itemsObj)" />
                  <app-validation-errors [mformField]="units" label="Units">
                  </app-validation-errors>
                  <!-- <div *ngIf="is_credit_note">{{ itemsObj.units }}</div> -->
                </td>

                <td>
                  <div *ngIf="is_credit_note">
                    <input type="number" [disabled]="itemsObj?.product?.type == 1" [min]="1" class="form-control" #price="ngModel" required name="price{{ i }}"
                      id="price{{ i }}" placeholder="Price" [(ngModel)]="itemsObj.price"
                      (change)="getTaxAmount(itemsObj);" />
                    <app-validation-errors [mformField]="price" label="Price">
                    </app-validation-errors>
                  </div>
                </td>
                <!-- <td *ngIf="itemsObj.tax==undefined">
                        0%
                        <input type="number" class="form-control" name="tax{{i}}" id="tax"
                          placeholder="Tax in percentage" [(ngModel)]="itemsObj.tax" title="Tax in percentage">
                      </td> -->
                <!-- <td *ngIf="itemsObj.tax!=undefined"
                        title="sgst:{{itemsObj.sgst}} cgst:{{itemsObj.cgst}} igst:{{itemsObj.igst}}">
                        {{itemsObj.tax}}%
                      </td> -->
                <td *ngIf="bill.is_without_gst==false">
                  {{itemsObj.tax_amount| currency: currency}}
                  <span *ngIf="itemsObj.tax!=undefined && bill.is_without_gst==false"
                    title="sgst:{{itemsObj.sgst}} cgst:{{itemsObj.cgst}} igst:{{itemsObj.igst}}">
                    ({{itemsObj.tax}}%)
                  </span>

                </td>
                <td *ngIf="
                          bill.is_productwise_discount &&
                          bill.discount_in_percentage
                        ">

                  <span *ngIf="is_credit_note">
                    {{ itemsObj.discount_in_percentage }}
                  </span>
                </td>
                <td *ngIf="
                          bill.is_productwise_discount &&
                          bill.discount_in_percentage == false
                        ">

                  <span *ngIf="is_credit_note">
                    {{ itemsObj.discount_in_amount }}
                  </span>
                </td>

                <td>
                  {{ itemsObj.amount | currency: currency }}
                </td>

                <td>
                  <button type="button" class="btn btn-danger" (click)="deleteItem(i); getTotalAmount()">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>

                </td>
              </tr>
              <tr *ngIf="itemsObj.is_billed !=3 &&
                        itemsObj.is_billed != 2 &&is_credit_note ==false ">
                <td>
                  {{ i + 1 }}

                </td>
                <td>
                  <ng-select *ngIf="is_credit_note==false" [items]="product_lists" [typeahead]="productListTypeahead"
                    #product="ngModel" required name="items{{ i }}" bindLabel="name"
                    (change)="getPriceFromHSN(itemsObj.product, itemsObj, i)" [(ngModel)]="itemsObj.product"
                    placeholder="Select Items">
                    <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <div [ngOptionHighlight]="search">
                        {{ item.name }}
                      </div>
                    </ng-template>
                  </ng-select>
                  <div *ngIf="is_credit_note">
                    {{ itemsObj?.product?.name }}
                  </div>
                  <app-validation-errors [mformField]="product" label="Product">
                  </app-validation-errors>
                  <textarea type="text" *ngIf="is_credit_note==false" class="form-control mt-1" name="description{{i}}"
                    id="description{{i}}" placeholder="Description" [(ngModel)]="itemsObj.description"></textarea>
                </td>
                <!-- <td>
                        <input type="text" *ngIf="is_credit_note==false" class="form-control" name="description{{i}}"
                          id="description{{i}}" placeholder="Description" [(ngModel)]="itemsObj.description">
                        <div *ngIf="is_credit_note">
                          {{ itemsObj.description }}
                        </div>
                      </td> -->


                <td *ngIf="is_credit_note && !is_expenses == true">
                  <input [disabled]="itemsObj?.product?.type == 2" type="number" [min]="1" [max]="itemsObj.max_units"
                    class="form-control" #units="ngModel" required name="units{{ i }}" id="units{{ i }}"
                    placeholder="Units" [(ngModel)]="itemsObj.units" (change)="getTaxAmount(itemsObj)" />
                  <app-validation-errors [mformField]="units" label="Units">
                  </app-validation-errors>
                  <!-- <div *ngIf="is_credit_note">{{ itemsObj.units }}</div> -->
                </td>
                <td *ngIf="is_credit_note==false && !is_expenses == true">
                  <input [disabled]="itemsObj?.product?.type == 2" type="number" [min]="1" class="form-control"
                    #units="ngModel" required name="units{{ i }}" id="units{{ i }}" placeholder="Units"
                    [(ngModel)]="itemsObj.units" (change)="getTaxAmount(itemsObj)" />
                  <app-validation-errors [mformField]="units" label="Units" digits>
                  </app-validation-errors>
                </td>

                <td>
                  <div *ngIf="is_credit_note==false">
                    <input type="number" [min]="1" class="form-control" #price="ngModel" required name="price{{ i }}"
                      id="price{{ i }}" placeholder="Price" [(ngModel)]="itemsObj.price"
                      (change)="getTaxAmount(itemsObj);" />
                    <app-validation-errors [mformField]="price" label="Price">
                    </app-validation-errors>
                  </div>
                  <div *ngIf="is_credit_note">{{ itemsObj.price }}</div>
                </td>
                <!-- <td *ngIf="itemsObj.tax==undefined">
                        0%
                        <input type="number" class="form-control" name="tax{{i}}" id="tax"
                          placeholder="Tax in percentage" [(ngModel)]="itemsObj.tax" title="Tax in percentage">
                      </td> -->
                <!-- <td *ngIf="itemsObj.tax!=undefined"
                        title="sgst:{{itemsObj.sgst}} cgst:{{itemsObj.cgst}} igst:{{itemsObj.igst}}">
                        {{itemsObj.tax}}%
                      </td> -->
                <td *ngIf="bill.is_without_gst==false">
                  {{itemsObj.tax_amount| currency: currency}}
                  <span *ngIf="itemsObj.tax!=undefined && bill.is_without_gst==false"
                    title="sgst:{{itemsObj.sgst}} cgst:{{itemsObj.cgst}} igst:{{itemsObj.igst}}">
                    ({{itemsObj.tax}}%)
                  </span>

                </td>
                <td *ngIf="
                          bill.is_productwise_discount &&
                          bill.discount_in_percentage
                        ">
                  <input *ngIf="is_credit_note==false" type="number" class="form-control"
                    name="itemsObj.discount_in_percentage{{ i }}" placeholder="Discount in percentage"
                    (change)="getAmount(itemsObj)" [(ngModel)]="itemsObj.discount_in_percentage"
                    title="Discount in percentage" />
                  <span *ngIf="is_credit_note">
                    {{ itemsObj.discount_in_percentage }}
                  </span>
                </td>
                <td *ngIf="
                          bill.is_productwise_discount &&
                          bill.discount_in_percentage == false
                        ">
                  <input type="number" class="form-control" *ngIf="is_credit_note==false"
                    name="itemsObj.discount_in_amount{{ i }}" placeholder="Discount in Amount"
                    (change)="getAmount(itemsObj)" [(ngModel)]="itemsObj.discount_in_amount"
                    title="Discount in Amount" />
                  <span *ngIf="is_credit_note">
                    {{ itemsObj.discount_in_amount }}
                  </span>
                </td>

                <td>
                  {{ itemsObj.amount | currency: currency }}
                </td>

                <td>
                  <button type="button" class="btn btn-danger" (click)="deleteItem(i); getTotalAmount()">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                  <button type="button" *ngIf="is_credit_note==false" class="btn btn-primary mr-2 ml-1"
                    (click)="!addNewItem()">
                    <i class="fa fa-plus"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Balancing Items Table -->
          <div class="col-12">
            <table class="float-right col-6 table table-bordered table-stripped table-condensed totals_table">
              <thead class=" thead-light">
                <tr>
                  <!-- <th>S.NO</th> -->
                  <th style="width: 45%;">Account Head</th>
                  <th>Value</th>
                  <th style="width: 25%;">Action</th>
                </tr>
              </thead>
              <tbody *ngFor="
                        let balancingObj of bill.balancing_items;
                        let balancing_index = index
                      ">
                <!-- <td>
                                    {{balancing_index+1}}
                                  </td> -->
                <td *ngIf="is_credit_note==false">
                  <ng-select [items]="account_head_lists" [typeahead]="accountHeadListTypeahead" #account_head="ngModel"
                    name="balancingObj.account_head{{ balancing_index }}" (change)="validateAccountHead()"
                    bindLabel="account_head" [(ngModel)]="balancingObj.account_head"
                    placeholder="Select Account Head">
                    <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <div [ngOptionHighlight]="search">
                        {{ item?.flattened_data?.name }}
                      </div>
                    </ng-template>
                  </ng-select>
                  <app-validation-errors [mformField]="account_head" label="Account Head">
                  </app-validation-errors>
                </td>
                <td *ngIf="is_credit_note">
                  {{ balancingObj?.account_head?.flattened_data?.name }}
                </td>
                <td *ngIf="
                          balancingObj.account_head?.flattened_data?.name !=
                          'Discount'
                        ">
                  <!-- [min]="1" number-->
                  <input type="number" *ngIf="is_credit_note==false" name="amount{{ balancing_index }}"
                    #balancing_amount="ngModel" class="form-control" [(ngModel)]="balancingObj.amount"
                    placeholder="Enter Amount" (change)="getTotalAmount()" />
                  <app-validation-errors [mformField]="balancing_amount" label="Amount">
                  </app-validation-errors>
                  <span *ngIf="is_credit_note">
                    {{ balancingObj.amount }}
                  </span>
                </td>

                <td *ngIf="
                          balancingObj.account_head?.flattened_data?.name ==
                            'Discount' && bill.discount_in_percentage != true
                        ">
                  <input type="number" name="amount{{ balancing_index }}" *ngIf="is_credit_note==false"
                    #balancing_amount="ngModel" class="form-control" [(ngModel)]="balancingObj.amount"
                    placeholder="Enter Amount" (change)="
                            getTotalAmount(); balancingObj.percentage = 0
                          " />
                  <app-validation-errors [mformField]="balancing_amount" label="Amount">
                  </app-validation-errors>
                  <span *ngIf="is_credit_note">
                    {{ balancingObj.amount }}
                  </span>
                </td>

                <td *ngIf="
                          balancingObj.account_head?.flattened_data?.name ==
                            'Discount' && bill.discount_in_percentage == true
                        ">
                  <input type="number" [max]="100" [min]="0" name="percentage{{ balancing_index }}"
                    *ngIf="is_credit_note==false" #balancing_percentage="ngModel" class="form-control"
                    [(ngModel)]="balancingObj.percentage" placeholder="Enter Percentage"
                    (change)="getTotalAmount(); balancingObj.amount = 0" />
                  <app-validation-errors [mformField]="balancing_percentage" label="Percentage">
                  </app-validation-errors>
                  <span *ngIf="is_credit_note">
                    {{ balancingObj.percentage }}
                  </span>
                </td>
                <td>
                  <button type="button" class="btn btn-danger" (click)="
                            deleteBalancingItem(balancing_index);
                            validateAccountHead()
                          ">
                    <i class="fa fa-trash"></i>
                  </button>
                  <button type="button" class="btn btn-primary ml-1" *ngIf="is_credit_note==false"
                    (click)="addNewBalancingItem()">
                    <i class="fa fa-plus"></i>
                  </button>
                </td>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-6" *ngIf="imageFiles !=undefined && imageFiles !=''">
          <table class="table" *ngIf="imageFiles['files'].length>0">
            <thead>
              <tr>
                <th scope="col"><b>Image</b></th>
                <th scope="col"><b>Action</b></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let fileupload of imageFiles['files']">
                <td>
                  <a target="_blank" href="{{image_path}}/{{fileupload.file_path}}">
                    <img *ngIf="fileupload.file_type!='application/pdf'" style="width: 50px;"
                      src="{{image_path}}/{{fileupload.file_path}}" />
                    <i *ngIf="fileupload.file_type=='application/pdf'" class="fa fa-file-pdf-o fa-2x"></i>
                  </a>

                </td>
                <td><a target="_blank" href="{{image_path}}/{{fileupload.file_path}}"> <button
                      class="btn btn-success mr-2" type="button"><i class="fa fa-eye"></i></button></a></td>
              </tr>
            </tbody>
          </table>

        </div>
        <div class="row justify-content-between">
          <div class="form-group col-sm-4 col-md-4 col-xl-4">
            <label for="credit_notes">Notes</label>
            <textarea class="form-control notes" name="credit_notes" id="credit_notes" placeholder="Notes"
              [(ngModel)]="bill.credit_notes"></textarea>
          </div>
          <table class="float-right table col-4">
            <tbody>
              <tr>
                <th><b>Actual Total </b></th>
                <td class="money">
                  <b>{{
                    bill.actual_total_amount | currency: currency
                    }}</b>
                </td>
              </tr>
              <tr *ngIf="is_same_state &&  bill.is_without_gst==false">
                <th><b>CGST </b></th>
                <td class="money">
                  <b>{{ bill.cgst | currency: currency }}</b>
                </td>
              </tr>
              <tr *ngIf="is_same_state  &&  bill.is_without_gst==false">
                <th><b>SGST </b></th>
                <td class="money">
                  <b>{{ bill.sgst | currency: currency }}</b>
                </td>
              </tr>
              <tr *ngIf="is_same_state == false  &&  bill.is_without_gst==false">
                <th><b>IGST </b></th>
                <td class="money">
                  <b>{{ bill.igst | currency: currency }}</b>
                </td>
              </tr>
              <!-- <tr>
                                            <th><b>Freight</b></th>
                                            <td class="money"><b>{{bill.freight| currency: currency}}</b></td>
                                        </tr>
                                        <tr>
                                            <th><b>Transport Charges</b></th>
                                            <td class="money"><b>{{bill.transport_charges| currency: currency}}</b></td>
                                        </tr> -->
              <tr *ngIf="bill.is_without_gst==false">
                <th><b>Sub Total </b></th>
                <td class="money">
                  <b>{{ bill.sub_total_amount | currency: currency }}</b>
                </td>
              </tr>
              <tr *ngIf="bill.is_discount == true">
                <th><b>Total Discount </b></th>
                <td class="money">
                  <b>- {{ bill.discount | currency: currency }}</b>
                </td>
              </tr>
              <tr *ngIf="bill.tds?.tds_deducted_amount > 0">
                <th>
                  <b>
                    TDS ({{bill.tds.tds_percentage}} % of {{this.bill.actual_total_amount}})
                  </b>
                  <i style="font-size: 1rem;" class="fe fe-info" [ngbPopover]="bill.tds.nature_of_payment"> </i>
                </th>
                <td class="money">
                  <b>

                    {{bill.tds.tds_deducted_amount | currency: currency}}
                  </b>
                </td>
              </tr>
              <tr *ngIf="bill.round_of!=0 && bill.round_of!=undefined">
                <th><b>Rounding Off ({{bill.round_of < 0 && bill.round_of!=0 ?'-':'+'}}) </b>
                </th>
                <td class="money"> <b>{{bill.round_of}}</b></td>
              </tr>
              <tr>
                <th><b>Total</b></th>
                <td class="money">
                  <b>{{ bill.total_amount | currency: currency }}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button *ngIf="is_edit_form == true && is_credit_note == false && is_expense ==false" type="submit"
        [disabled]="(!addBillForm.valid)" class="btn btn-primary">
        Save
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
      </button>
      <button *ngIf="is_edit_form == false && is_credit_note == false && is_expense ==false" type="submit"
        [disabled]="(!addBillForm.valid )" class="btn btn-primary">
        Save
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
      </button>
      <button *ngIf="is_credit_note == true" type="submit" [disabled]="(!addBillForm.valid || !bill.ledger.name)"
        class="btn btn-primary">
        Save Debit-Note
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
      </button>
      <button *ngIf="is_expense == true" type="submit" [disabled]="(!addBillForm.valid || !bill.ledger.name)"
        class="btn btn-primary">
        Save Expense
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
      </button>
    </div>
  </form>
</div>
<!-- </div> -->
<!-- </div> -->
<!-- </div> -->
<!-- <ng-template #content let-modal>
  <app-voucher-type-add [voucherCategoryCode]= 2 ></app-voucher-type-add>
</ng-template> -->
