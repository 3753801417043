<div class="container-fluid">
        <div class="row">
                <div class="col-lg-12">
                        <div class="card">
                                <div class="card-header d-flex align-items-center">
                                        <h3 class="table-title">Cost Center Add</h3>
                                </div>
                                <form novalidate #addCostgroupForm="ngForm" (ngSubmit)="addCostgroup()">
                                        <div class="card-body row">
                                                <div class="form-group col-12 col-sm-6 col-md-4 col-xl-4 ">
                                                        <label class="col-form-label ">Under Cost Center
                                                                Category</label>
                                                        <div class="">
                                                                <ng-select [items]="underCostCenterList"
                                                                        [typeahead]="underCostCenterListTypeahead"
                                                                        name="under_cc_category" bindLabel="name"
                                                                        [(ngModel)]="costgroup.under_cc_category">
                                                                        <ng-template ng-label-tmp let-item="item">
                                                                                {{item.costcategory_name}}
                                                                        </ng-template>
                                                                        <ng-template ng-option-tmp let-item="item"
                                                                                let-index="index"
                                                                                let-search="searchTerm">
                                                                                {{item.costcategory_name}}
                                                                        </ng-template>
                                                                </ng-select>
                                                                <!-- <app-validation-errors [mformField]="under_cc_category"
                                                                        label="Under Cost Center Category">
                                                                </app-validation-errors> -->
                                                                <!-- <input class="form-control" name="costcatagory_name"
                                                                        #costcatagory_name="ngModel"
                                                                        [(ngModel)]="costgroup.under_cc_category"
                                                                        placeholder="Cost Catagory Name"> -->
                                                                <!-- <app-validation-errors [mformField]="costcatagory_name"
                                                                        label="Cost Catagory Name">
                                                                </app-validation-errors> -->
                                                        </div>
                                                </div>
                                                <div class="form-group col-12 col-sm-6 col-md-4 col-xl-4  required ">
                                                        <label class="col-form-label ">Cost Group
                                                                Name</label>
                                                        <div class="">
                                                                <input class="form-control" name="costcategory_name"
                                                                        #costcategory_name="ngModel"
                                                                        [(ngModel)]="costgroup.costcategory_name"
                                                                        placeholder="Cost Category Name">
                                                                <app-validation-errors [mformField]="costcategory_name"
                                                                        label="Cost Category Name">
                                                                </app-validation-errors>
                                                        </div>
                                                </div>
                                                <table class="table table-striped table-bordered">
                                                        <thead>
                                                                <tr>
                                                                        <th>Cost Center Name</th>
                                                                        <th>Action</th>
                                                                </tr>
                                                        </thead>
                                                        <tbody>
                                                                <tr
                                                                        *ngFor="let costgroup_items of costgroupitemsArray; let i=index;">
                                                                        <td>
                                                                                <input type="text" class="form-control"
                                                                                        name="costcenter_name{{i}}"
                                                                                        #costcenter_name="ngModel"
                                                                                        id="costcenter_name{{i}}"
                                                                                        [(ngModel)]="costgroup_items.costcenter_name"
                                                                                        placeholder="">
                                                                                <app-validation-errors
                                                                                        [mformField]="costcenter_name"
                                                                                        label="Cost Center Name">
                                                                                </app-validation-errors>
                                                                        </td>
                                                                        <td><button type="button"
                                                                                        class="btn btn-primary"
                                                                                        (click)="addNewItem(i)"><i
                                                                                                class="fa fa-plus"></i></button><button
                                                                                        *ngIf="costgroupitemsArray.length >1"
                                                                                        type="button"
                                                                                        class="btn btn-danger ml-2"
                                                                                        (click)="deleteItem(i)"><i
                                                                                                class="fa fa-trash"></i></button>
                                                                        </td>
                                                                </tr>
                                                        </tbody>
                                                </table>
                                        </div>
                                        <div class="card-footer">
                                                <button type="submit" [disabled]="!addCostgroupForm.valid || loading==true"
                                                        class="btn btn-primary">Save
                                                        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                                </button>
                                        </div>
                                </form>
                        </div>
                </div>
        </div>
</div>