import { Currency } from "./currency.model";
import { SalesOrderItem } from "./salesorder-items";
export class Salesorder {
  grand_total_amount: number = 0;
  sub_total_amount: number = 0;
  is_discount: boolean = false;
  is_productwise_discount: boolean = false;
  discount: number = 0;
  // sales_items: any = [];
  discount_in_percentage: boolean = false;
  balancing_items: any = [];
  customer: any;
  currency: Currency = new Currency();
  // desc: any;
  sales_order_number: string;
  credit_notes: any;
  sales_order_date: any;
  due_date: any;
  sales_order_items: any = [];
  // tax: string;
  company_id: string;
  branch_id: string;
  shipping_address: string;
  billing_address: string;
  total_amount: number = 0;
  balance_amount: number = 0;
  total_tax_percentage: number = 0;
  total_tax_amount: number = 0;
  actual_total_amount: number = 0;
  cgst: number = 0;
  sgst: number = 0;
  igst: number = 0;
  is_invoiced: number;
  delivery_note: string;
  suppliers_ref: string;
  overall_discount_in_percentage: any;
}
