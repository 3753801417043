import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { Vendor } from "../../../_models/vendor.model";
import { VendorService } from "../../../_services/vendor.service";
import swal from "sweetalert2";
import { CountryService } from "../../../_services/country.service";
import { MastersService } from "../../../_services/masters.service";
import { TdsService } from "../../../_services/tds.service";
import { AuthenticationService } from "../../../_services/authentication.service";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { Bank } from "src/app/_models/bank.model";
import { ConstantService } from "src/app/_config/constants";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { AddressTemplateComponent } from '../../../common/address-template/address-template.component';
import { NgbModal, NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddressTemplate } from '../../../_models/address_template.model';
import { ContactAddress } from '../../../_models/contact_address.model';
import { Tds } from '../../../_models/tds';

@Component({
  selector: "app-vendors-add",
  templateUrl: "./vendors-add.component.html",
  styleUrls: ["./vendors-add.component.scss"],
})
export class VendorsAddComponent implements OnInit {
  loading: boolean = false;
  public vendor: Vendor = new Vendor();
  bank_details: Bank = new Bank();
  navTab = "vendor";
  addressListConfig: AddressTemplate = new AddressTemplate();
  result: any;
  countryList: any = [];
  vendor_categories: any = [
    { name: "Individual / Proprietorship", id: 1 },
    { name: "Partnership Firm", id: 2 },
    { name: "Private Limited", id: 3 },
    { name: "Limited Liability", id: 4 },
    { name: "HUF(Hindu Undivided Family)", id: 5 },
    { name: "AOP (Association Of Person)", id: 6 },
    { name: "Trust", id: 7 },
    { name: "Public Limited", id: 8 },
  ];
  stateList: any = [];
  // country_list: any;
  currency_list: any;
  type: string;
  vendor_id: any = {};
  countryListTypeahead = new EventEmitter<string>();
  TDSListTypeahead = new EventEmitter<string>();
  is_edit_form: boolean = false;

  account_head: [
    {
      liability: [];
    }
  ];
  company_id: string;
  branch_id: string;
  chart_of_accounts: any = {};
  accounts_payable: any;
  credit_notes: any;
  liability_data: any;
  assets_data: any;
  flattened_data: any;

  gstEffectiveDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  branchGstEffectiveDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  tanEffectiveDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  cinEffectiveDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  dinEffectiveDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  pfEffectiveDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  esiEffectiveDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  lutEffectiveDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  contactAddressList: ContactAddress[] = [];
  TDSList: Tds[] = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private vendor_service: VendorService,
    private mastersService: MastersService,
    private authenticationService: AuthenticationService,
    private countryService: CountryService,
    // public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private tdsService: TdsService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.vendor.vendor_category = null;
    this.vendor.country = null;
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.vendor.payment_term = "60";
    this.vendor.bank = [];
    this.addBankRow();
    this.addContactRow();

    // this.vendor.country = null;
    this.getCountryData("").subscribe((result) => {
      this.countryList = result;
      this.countryList.push({
        country_id: 1,
        name: "India",
        _id: "56dd4f381ffda7ee447019d3"
      });
      this.countryList.filter((e) => {
        if (e.name == "India") {
          this.vendor.country = e;
        }
      });
      this.stateList.push({

        zone_id: 1503,
        country_id: 99,
        name: "Tamil Nadu",
        Gst_code: 33,
        code: "TN",
        status: 1

      });
      this.stateList.filter((e) => {
        if (e.name == "Tamil Nadu") {
          this.vendor.state = e;
        }
      });
      this.getStateByCountry();
    });
    this.getCountrySearch();

    this.getTDSList('').subscribe((result: { data: Tds[], count: number }) => {
      this.TDSList = result['data'];
    });
    this.getTDSSearch();
    this.vendor.currency = null;
    this.route.paramMap.subscribe((params) => {
      this.vendor_id = params.get("id");
      let result = this.router.url.match("/edit/");
      if (result != null) {
        if (result[0] === "/edit/" && this.vendor_id != null) {
          this.getVendor();
          this.is_edit_form = true;
          this.initializeAddressTableList();
        }
      }
    });
    this.getCoa();
    // this.getCountries();
    this.getCurrencies();
  }

  initializeAddressTableList() {
    this.addressListConfig.action_type = 'table_viewall';
    this.addressListConfig.user_muid = this.vendor_id;
    this.addressListConfig.user_role = 'vendor';
  }

  addUpdateVendor() {
    if (this.is_edit_form === true) {
      this.editVendor();
    } else {
      this.addVendor();
    }
  }

  addVendor() {
    this.loading = true;
    console.log(this.vendor.first_name);
    let addressDetails: any = {};
    addressDetails.first_name = this.vendor.first_name;
    addressDetails.last_name = this.vendor.last_name;
    addressDetails.address_line1 = this.vendor.address_line1;
    addressDetails.address_line2 = this.vendor.address_line2;
    addressDetails.city = this.vendor.city;
    addressDetails.state = this.vendor.state;
    addressDetails.country = this.vendor.country;
    this.vendor.contact_address.push(addressDetails);
    this.vendor.company_id = this.company_id;
    if (this.vendor.company_id != "") {
      this.result = this.vendor_service
        .createVendor(this.vendor)
        .subscribe((result) => {
          if (result["error"].length>0) {
            swal("Error", result["error"], "error");
          } else {
            if (result["muid"] != "") {
              this.loading = false;
              this.updateCoa(this.vendor.vendor_name, result["muid"]);
              this.router.navigate(["party/list"]);
              swal(
                "Success",
                "Vendor has been created successfully",
                "success"
              );
            }
          }
        });
    }
  }

  getVendor() {
    this.vendor_service.viewVendor(this.vendor_id).subscribe((result) => {
      this.vendor = result["response"];
      this.vendor.gst_effective_date = {
        isRange: false,
        singleDate: {
          jsDate: new Date(this.vendor.gst_effective_date),
        },
      };
      if (this.vendor.tds.length == 1) {
        this.vendor.tds = this.vendor.tds[0];
      }
      this.getStateByCountry();
    });
  }

  editVendor() {
    this.loading = true;
    this.vendor_service
      .editVendor(this.vendor, this.vendor_id)
      .subscribe((result) => {
        if (result["response"]["status_code"] == 1) {
          this.loading = false;
          swal("Success", "Vendor has been updated successfully", "success");
          this.router.navigate(["party/list"]);
        } else if (result["response"]["status_code"] == 0) {
          swal("Error", "Some error occured while updating vendor", "error");
        }
      });
  }

  getCurrencies() {
    this.mastersService.getCurrencies().subscribe((result) => {
      this.currency_list = result["response"];
      this.currency_list.filter((e) => {
        if (e.name == "INR") {
          this.vendor.currency = e;
        }
      });
    });
  }

  getCountryData(searchString) {
    this.countryList = [];
    let filters = {};
    filters["name"] = searchString;
    return this.countryService.getCountryData(filters, 10);
  }
  getCountrySearch() {
    this.countryListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getCountryData(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.countryList = result;
        },
        (err) => {
          console.log(err);
          this.countryList = [];
        }
      );
  }
  getTDSSearch() {
    this.TDSListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getTDSList(searchString))
      ).subscribe((result: { data: Tds[], count: number }) => {
        this.cd.markForCheck();
        this.TDSList = result['data'];
      },
        (err) => {
          console.log(err);
          this.TDSList = [];
        }
      );
  }
  getStateByCountry() {
    this.countryService
      .getStateByCountry({ muid: this.vendor.country._id })
      .subscribe((result) => {
        this.stateList = result[0]["zones"];
        if (this.vendor.state) {
          this.vendor.state = this.stateList[this.vendor.state["zone_id"]];
        }
      });
  }

  getCoa() {
    this.mastersService.getCoa(this.company_id).subscribe((result) => {
      this.chart_of_accounts = result["response"]["chart_of_accounts"];
      this.flattened_data = result["response"]["flattened_data"];
      this.chart_of_accounts.forEach((element) => {
        /**id-2 => Liabilities */
        if (element.id == ConstantService.COA.LIABILITIES) {
          element["child"].forEach((value) => {
            if (value.id == ConstantService.COA.CURRENT_LIABILITIES) {
              value["child"].forEach((value2) => {
                /**id-2011 => Trade Payable */
                if (value2.id == 2011) {
                  this.accounts_payable = value2;
                  console.log(
                    "this.accounts_payable====>",
                    this.accounts_payable
                  );
                }
              });
            }
          });
          this.liability_data = element;
        }
        /**id-1 => Assets */
        if (element.id == ConstantService.COA.ASSETS) {
          // element['child'].forEach(value => {
          //   /**id-11 => Credit Notes */
          //   if (value.id == 1011) {
          //     this.credit_notes = value;
          //   }
          // });
          this.assets_data = element;
        }
      });
      // console.log('credit_notes', this.credit_notes)
    });
  }

  updateCoa(vendor_name, vendor_id) {
    if (this.liability_data["last_sequence"] + 1 < 3000) {
      let condition: any = {};
      condition["company_id"] = this.company_id;
      let account_payable_data: any = {};
      let credit_note_data: any = {};

      account_payable_data["name"] = vendor_name;
      account_payable_data["ref_id"] = vendor_id;
      account_payable_data["id"] = this.liability_data["last_sequence"] + 1;
      this.liability_data["last_sequence"] = account_payable_data["id"];
      account_payable_data["is_editable"] = true;
      account_payable_data["is_ledger"] = true;
      account_payable_data["child"] = [];
      // if (this.assets_data['last_sequence'] + 1 < 2000) {
      //   credit_note_data['name'] = vendor_name;
      //   credit_note_data['ref_id'] = vendor_id;
      //   credit_note_data['id'] = this.assets_data['last_sequence'] + 1;
      //   this.assets_data['last_sequence'] = credit_note_data['id'];
      //   credit_note_data['is_editable'] = true;
      //   credit_note_data['is_ledger'] = true;
      //   credit_note_data['child'] = [];
      //   // Pushing vendor data in account payable and credit note
      //   this.credit_notes.child.push(credit_note_data);
      // }
      this.accounts_payable.child.push(account_payable_data);
      this.flattened_data.forEach((flatObj) => {
        //Accounts Payable flatten obj
        if (
          flatObj.id == 2011 &&
          this.liability_data["last_sequence"] + 1 < 3000
        ) {
          flatObj["is_ledger"] = false;
          let parent_name: any = [];
          let parent_id: any = [];
          parent_name.push(flatObj.name);
          parent_id.push(flatObj.id);
          flatObj.parent_name.forEach((parent_names) => {
            parent_name.push(parent_names);
          });
          flatObj.parent_id.forEach((parent_ids) => {
            parent_id.push(parent_ids);
          });
          this.flattened_data.push({
            name: vendor_name,
            id: this.liability_data["last_sequence"],
            parent_name: parent_name,
            parent_id: parent_id,
            ref_id: vendor_id,
            is_editable: true,
            is_ledger: true,
          });
        }
        //Credit Notes flatten obj
        if (
          flatObj.id == 1011 &&
          this.assets_data["last_sequence"] + 1 < 2000
        ) {
          flatObj["is_ledger"] = false;
          let parent_name: any = [];
          let parent_id: any = [];
          parent_name.push(flatObj.name);
          parent_id.push(flatObj.id);
          flatObj.parent_name.forEach((parent_names) => {
            parent_name.push(parent_names);
          });
          flatObj.parent_id.forEach((parent_ids) => {
            parent_id.push(parent_ids);
          });
          this.flattened_data.push({
            name: vendor_name,
            id: this.assets_data["last_sequence"],
            parent_name: parent_name,
            parent_id: parent_id,
            ref_id: vendor_id,
            is_editable: true,
            is_ledger: true,
          });
        }
      });

      // updating coa with new vendor data
      this.mastersService
        .updateCoa(condition, {
          chart_of_accounts: this.chart_of_accounts,
          flattened_data: this.flattened_data,
        })
        .subscribe((result) => { });
      // updating vendor with coa data
      this.vendor_service
        .editVendor(
          {
            coa_data: account_payable_data,
            credit_note_data: credit_note_data,
          },
          vendor_id
        )
        .subscribe((result) => { });
    }
  }

  addBankRow() {
    this.vendor.bank.push({
      name: "",
      branch: "",
      ifsc_code: "",
      account_number: "",
    });
  }
  addContactRow() {
    this.vendor.contacts.push({
      contact_name: "",
      phone_number: "",
      email: "",
      designation: "",

    });
  }
  deleteContactRow(index) {
    if (index != 0) {
      this.vendor.contacts.splice(index, 1);
    }
  }
  deleteBankRow(index) {
    this.vendor.bank.splice(index, true);
  }

  showUpdateAddressDialog(is_edit_form = false) {
    const addAddressModelRef = this.modalService.open(AddressTemplateComponent, { size: 'lg' });
    addAddressModelRef.componentInstance.addressConfig = new AddressTemplate();

    addAddressModelRef.componentInstance.addressConfig.user_role = 'vendor';
    addAddressModelRef.componentInstance.addressConfig.user_muid = this.vendor_id;

    addAddressModelRef.componentInstance.addressConfig.callbackFunction = this.getAddressList;
    if (is_edit_form == true) {
      addAddressModelRef.componentInstance.addressConfig.action_type = 'edit';
    } else {
      // addAddressModelRef.componentInstance.addressConfig.contact_address = contactAddress;
      addAddressModelRef.componentInstance.addressConfig.action_type = 'add';
    }
  }

  getAddressList = () => {
    this.vendor_service.viewVendor({ muid: this.vendor_id }).subscribe(result => {
      this.contactAddressList = result['response']['contacts'];
    });
  }

  getTDSList(search_string: string) {
    return this.tdsService.getTdsList({ search_string: search_string }, {}, {}, {})
    // .subscribe((result: { data: Tds[], count: number }) => {
    //   this.TDSList = result['data'];
    // });
  }
}
