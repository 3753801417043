import { PurchaseOrderItem } from './purchaseorder-items';
import { Currency } from '../_models/currency.model';
export class Purchaseorder {
    vendor: any = {};
    desc: string;
    notes: string;
    purchase_order_number: string;
    purchase_order_items: any = [];
    company_id: string;
    branch_id: string;
    purchase_order_date: any;
    due_date: any;
    currency: Currency = new Currency();
    total_amount: number = 0;
    actual_amount: number = 0;
    balance_amount: number;
    total_tax_amount: number = 0;
    total_tax_percentage: number = 0;
    actual_total_amount: number = 0;
    credit_date: any;
    credit_notes: any;
    cgst: number = 0;
    sgst: number = 0;
    igst: number = 0;
}
