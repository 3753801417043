<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <form novalidate #searchCostgroupForm="ngForm" (ngSubmit)="getCostgroupsList({ offset: 0, pageSize: 2 })">
                <div class="card">
                    <div class="card-body">
                        <div class="btn-group mr-3 mb-2 float-right">
                            <button class="btn btn-primary float-right" routerLink="/costgroup/add">
                                <i class="icon icon-add"></i>Add Cost Center
                            </button>
                    </div>
                    <h3 class="table-title"><i class="fa fa-user"></i> Cost Center</h3>

                    <div class="card-body row">
                        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 ">
                            <label class="col-form-label ">Parent Category</label>
                            <div class="">
                              <input type="text" class="form-control" name="under_cc_category" #under_cc_category="ngModel" id="under_cc_category"
                                [(ngModel)]="filters.under_cc_category" placeholder="">
                            </div>
                          </div>

                          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 ">
                            <label class="col-form-label ">Cost Category</label>
                            <div class="">
                              <input type="text" class="form-control" name="costcategory_name" #costcategory_name="ngModel" id="costcategory_name"
                                [(ngModel)]="filters.costcategory_name" placeholder="">
                            </div>
                          </div>

                          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 ">
                            <label class="col-form-label ">Costcenter Name</label>
                            <div class="">
                              <input type="text" class="form-control" name="costcenter_name" #costcenter_name="ngModel" id="costcenter_name"
                                [(ngModel)]="filters.costcenter_name" placeholder="">
                            </div>
                          </div>

                    </div>
                    <div>
                        <button type="submit" class="btn btn-primary mr-2"><i class="fa fa-search"></i> Search</button>
                        <button type="button" (click)="clearCostgroupFilters();" class="btn btn-danger"><i class="fa fa-close"></i>
                            Clear 
                        </button>

                    </div>
                    </div>
                </div>
            </form>
            

            <div class="p-sm-0">
                <div class="card">
                    <div class="card-body">

                        <ngx-datatable [rows]="costgroupList" [loadingIndicator]="loadingIndicator"
                            [externalPaging]="true" [columnMode]="'force'" [limit]="page.limit" [count]="page.count"
                            [offset]="page.offset" (page)="getCostgroupsList($event)" [externalSorting] = true (sort)="onSort($event)" 
                             [footerHeight]="50" [rowHeight]="'auto'" class="material striped" [sorts]="this.dt_default_sort"
                           >

                            <ngx-datatable-column *ngFor="let col of costgroupListColumns" [flexGrow]="col.flexGrow"
                                [name]="col.name" [prop] = "col.prop" [sortable] = "col.sortable">
                                <ng-template ngx-datatable-header-template>
                                    {{ col.name }}
                                </ng-template>
                                <ng-template #actionTmpl let-row="row" ngx-datatable-cell-template>
                                    <div [ngSwitch]="col.type">
                                        <span *ngSwitchCase="'under_cc_category'"> {{row?.under_cc_category?.costcategory_name}}</span>
                               

                                        <span *ngSwitchCase="'action'">
                                            <div class="button-row">
                                                <a class="btn btn-primary mr-2" *ngxPermissionsOnly="['Elixir - Admin', 'Elixir - Manager', 'Client - Admin', 'Client - Manager', 'Elixir - Accountant']" routerLink="/costgroup/edit/{{row._id}}"
                                                    mat-mini-fab color="primary" title="Edit">
                                                    <i class="fa fa-edit"></i>
                                            </a>

                                                <a class="btn btn-danger" *ngxPermissionsOnly="['Elixir - Admin', 'Elixir - Manager', 'Client - Admin', 'Client - Manager']" (click)="deleteCostgroup(row._id)"
                                                    mat-mini-fab color="warn" title="Delete">
                                                    <i class="fa fa-trash"></i>
                                        </a>
                                            </div>

                                        </span>
                         <span *ngSwitchCase="'object'"> {{ row[col.prop][col.field_name] }}</span>
                        <span *ngSwitchCase="'date'"> {{ row[col.prop]|date }}</span>
                        <span *ngSwitchCase="'status'"> <span
                            class="badge badge-success">{{ row[col.prop]}}</span></span>
                                        <span *ngSwitchDefault>
                                            {{ row[col.prop] }}
                                        </span>
                                    </div>

                                </ng-template>
                            </ngx-datatable-column>

                            <!-- <ngx-datatable-footer>
                                <ng-template ngx-datatable-footer-template let-rowCount="rowCount"
                                    let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage"
                                    let-offset="offset">

                                    <div class="page-count">{{ (curPage * pageSize)-pageSize+1 }} -
                                        {{ curPage * pageSize }}
                                        of
                                        {{ rowCount }}
                                    </div>
                                    <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                        [pagerRightArrowIcon]="'datatable-icon-right'"
                                        [pagerPreviousIcon]="'datatable-icon-prev'"
                                        [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize"
                                        [count]="rowCount" [hidden]="!((rowCount / pageSize) > 1)"
                                        (change)="table.onFooterPage($event)">
                                    </datatable-pager>
                                </ng-template>

                            </ngx-datatable-footer> -->
                        </ngx-datatable>
                    </div>
                </div>
            </div>

            <!-- <ng-template #actionTmpl let-value="value">

<div class="btn-group mr-3">
<button class="btn btn-primary" routerLink="/costgroup/edit/{{value}}" type="button" rel="tooltip">Edit</button>
<div class="btn-group" ngbDropdown placement="bottom-right" role="group">
    <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
    <div class="dropdown-menu" ngbDropdownMenu>
        <button class="dropdown-item" routerLink="/costgroup/edit/{{value}}" type="button" rel="tooltip">Edit</button>
        <button class="dropdown-item " (click)="deleteCostgroup(value)">
            <span class="text-red">
                <i class="icon-trash-can3"></i> Delete
            </span>
        </button>
    </div>
</div>
</div>



</ng-template> -->