import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";

import { AuthenticationService } from "src/app/_services/authentication.service";
import { ProductService } from "../../../_services/product.service";

import swal from "sweetalert2";
import { Product } from "../../../_models/product.model";
import { CompanyService } from "src/app/_services/company.service";
import { HsnService } from "src/app/_services/hsn.service";
import { MastersService } from "src/app/_services/masters.service";
import { NgSelectModule } from "@ng-select/ng-select";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { typeofExpr } from "@angular/compiler/src/output/output_ast";

@Component({
  selector: "app-product-add",
  templateUrl: "./product-add.component.html",
  styleUrls: ["./product-add.component.scss"],
})
export class ProductAddComponent implements OnInit {
  public product = new Product();
  page: any = { offset: 0, pageSize: 2 };
  sort: {};
  filter: any = {};
  bomArray: any = [];
  hsndescArray: any = [];
  hsncodeArray: any = [];
  inventoryList: any = [];
  inventoryListTypeahead = new EventEmitter<string>();
  hsnListTypeahead = new EventEmitter<string>();
  productArray: any = [];
  groupList: any = [];
  bomAttributes: any = {};
  type: string;
  selectedFile: File;
  unitArray: any = [];
  gst_mapping: number;
  company_id: string;
  token_data: any;
  loading: boolean = false;
  branch_id: string;
  company_details: any;
  is_service: boolean;
  is_sale: boolean;
  is_bom: boolean = false;
  is_edit: number;
  product_id: string;
  is_disable: boolean = false;
 productType = {
  GOODS: 1,
  SERVICE: 2
};
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    private companyService: CompanyService,
    private hsnService: HsnService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private mstersService: MastersService //  private config: NgSelectConfig
  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();

    this.branch_id = this.authenticationService.getDefaultBranchId();
    let company_category = this.authenticationService.getCompanyCategory();
    console.log('company_category', company_category);
    if (company_category == 2) {
      //Service=>Service
      this.is_service = true;
      this.product.type = this.productType.SERVICE;

    } else if (company_category == 4 || company_category == 5) {
      // Trade => Goods
      this.is_service = false;
      this.product.type = this.productType.GOODS;
    } else {
      this.product.type = 1;
      this.is_service = false;
    }
    this.token_data = this.authenticationService.getPayload();

    this.getHsncodeSearch();
    this.getHsnCode("").subscribe((result) => {
      this.hsncodeArray = result;
    });
    this.getProducts();

    this.getProductGroup(this.company_id);

    this.route.paramMap.subscribe((params) => {
      this.type = params.get("type");
      if (this.type == "composite_item") {
      } else if (this.type != "item") {
        this.is_edit = 1;
        this.is_disable = true;
        this.product_id = this.type;
        console.log(this.type, "sfdsfsdfsdf");
        this.getProductData(this.type);
      } else if (this.type == "item") {
        console.log("Add product====");
      }
    });
    // this.getCoaHead("").subscribe((result) => {
    //   this.inventoryList = result["response"];
    // });

    // this.getCoaHeadSearch();

    this.gst_mapping = parseInt(
      this.authenticationService.getDefaultGSTMapping()
    );

    this.getUnits();
   
    this.addNewItems();
  }

  addProduct() {
    this.loading = true;
    if (this.company_id) {
      this.product.company_id = this.company_id;
      if (this.branch_id != "") {
        this.product.branch_id = this.branch_id;
      }
      if (this.bomArray) { 
        this.product.bom = this.bomArray;
      }
      this.product.type = this.is_service == true ? this.productType.SERVICE : this.productType.GOODS;
      if (this.is_edit == 1) {
        this.product.old_group_id = this.product['group_id'];
        this.product.group_id = this.product['group']['_id'];
        this.product.group_name = this.product['group']['group_name'];
  
        this.product.hsn_muid =  this.product['code']['_id'];
        this.product.code =  this.product['code']['code'];

        this.productService
          .editProduct(this.product, this.product_id)
          .subscribe((result) => {
            if (result["statusCode"] == 200) {
              this.loading = false;
              swal(
                "Success",
                "Product has been Updated successfully",
                "success"
              );
              this.router.navigate(["/product/list"]);
            }
          });
          
      } else {
        this.productService.addProduct(this.product).subscribe((result) => {
          if(result['error']) {
            swal('Error', result['error'], 'error');
          } else {
          if (result["muid"] != "") {
            this.loading = false;
            console.log('result', result);
            swal("Success", "Product has been added successfully", "success");
            this.router.navigate(["/product/list"]);
          }
        }
        });
      }
    } else {
      swal("Error", "Select the company", "error");
    }
  }

  getProducts() {
    if (this.company_id) {
      let columns = {
        name: 1,
        code: 1,
        sku: 1,
        type: 1,
        "sales.selling_price": 1,
      };
      let condition: any = {};
      condition["company_id"] = this.company_id;
      if (this.branch_id != "") {
        condition["branch_id"] = this.branch_id;
      }
      this.productService
        .getAllProducts(condition, 1, columns)
        .subscribe((result) => {
          console.log("====productArray", result);
          this.productArray = result;
        });
    } else {
      swal("Error", "Select the company", "error");
    }
  }

  getCoaHead(searchString) {
    this.inventoryList = [];
    return this.mstersService.getCoaHead(this.company_id, searchString, {});
  }

  getCoaHeadSearch() {
    this.inventoryListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getCoaHead(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.inventoryList = result["response"];
        },
        (err) => {
          console.log(err);
          this.inventoryList = [];
        }
      );
  }

  addNewItems() {
    this.bomArray.push({
      product: "",
      selling_price: "",
      quantity: "",
    });
  }
  deleteBomItem(indexValue) {
    this.bomArray.splice(indexValue, 1);
  }
  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
    console.log(this.selectedFile);
  }

  getDescription() {
    this.hsnService.getDescription(this.product.code).subscribe((result) => {
      console.log(result);
      this.hsndescArray = result;
    });
  }
  getHsnCode(searchString) {
    let condition: any = {};
    let columns: any = {};
    this.hsncodeArray = [];
    if (searchString != "") {
      condition["search_string"] = searchString;
    }

    condition["type"] = this.product.type;
    condition["effects_from_is_active"] = 1;
    columns["code"] = 1;
    columns["description"] = 1;
    columns["group_code "] = 1;
    columns["country_code"] = 1;
    columns["effects_from"] = 1;

    console.log("condition==========>", condition);
    return this.hsnService.getHsnCode(condition, columns, {}, { limit: 10 });
  }

  getHsncodeSearch() {
    this.hsnListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getHsnCode(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.hsncodeArray = result;
        },
        (err) => {
          console.log(err);
          this.hsncodeArray = [];
        }
      );
  }

  getUnits() {
    this.mstersService.getUnits().subscribe((result) => {
      this.unitArray = result["response"]["data"];
    });
  }

  addNewGroup = (group) => {
    this.productService
      .addGroupSet(group, this.company_id)
      .subscribe((data) => {
        console.log("=============grouplist", data);
        this.loading = false;
        if (data) {
          this.getProductGroup(this.company_id);
        }
      });

    // if (this.product) {
    // return new Promise(resolve => {
    //   this.loading = true;
    //   this.productService
    //     .addGroupSet({ group: group }, this.product)
    //     .subscribe(data => {
    //       this.loading = false;
    //       resolve(data);
    //     });
    // });
    // } else {
    //   swal("Success", "You must select the industry to add the skill");
    // }
  };
  getProductGroup(companyid) {
    this.filter = {
      company_id: companyid,
    };
    let columns = {
      group_name: 1,
      _id: 1,
      product: 1,
    };
    this.productService
      .getProductGroup(this.filter, columns)
      .subscribe((result) => {
        this.groupList = result["response"];
      });
  }
  hsn_code(data) {
    let condition: any = {};
    let columns: any = {};
    this.hsncodeArray = [];

    condition["type"] = data;

    columns["code"] = 1;
    columns["description"] = 1;
    columns["group_code "] = 1;
    columns["country_code"] = 1;
    columns["effects_from"] = 1;

    this.hsnService
      .getHsnCode(condition, columns, {}, { limit: 50 })
      .subscribe((result) => {
        this.hsncodeArray = result;
      });
  }
  getProductData(product_id) {
    console.log("edit===========");
    let filter: any = {};
    filter["_id"] = product_id;
    filter["is_edit"] = 1;
    filter["company_id"] = this.company_id;
    if (this.branch_id) {
      filter["branch_id"] = this.branch_id;
    }
    this.productService.getAllProducts(filter, "", "").subscribe((result) => {
      this.product = result[0];
      this.is_service = this.product.type==1? false: true; 
      // this.is_service = result[0].type;
      this.hsn_code(this.is_service);

      let hsn_data = this.hsncodeArray.find(
        (hsn) => hsn._id == this.product.hsn_muid
      );
      let group_data = this.groupList.find(
        (group) => group._id == this.product.group_id
      );
      if (hsn_data == undefined) {
        this.hsnService
          .getHSNByCondition({ _id: result[0].hsn_muid })
          .subscribe((hsn_result) => {
            this.hsndescArray.push(hsn_result["response"][0]);
            this.product.code = hsn_result["response"][0];
          });
      } else {
        this.product.code = hsn_data;
      }
      if (group_data == undefined) {
        this.productService
          .getProductGroup({ _id: result[0].group_id }, {})
          .subscribe((group_result) => {
            this.groupList.push(group_result["data"][0]);
            this.product.group = group_result["data"][0];
          });
      } else {
        this.product.group = group_data;
      }

      if (this.product.bom[0].product != "") {
        this.bomArray = [];
        this.is_bom = true;
        result[0].bom.forEach((element) => {
          this.bomArray.push(element);
        });

        console.log(this.bomArray);
      }
    });
     this.productService
          .getProductStock(this.company_id, this.product_id)
          .subscribe((result) => {
           console.log('result', result);
          });
  }
  onChangeCompanyCategory() {
    this.product.type = this.is_service == true ? this.productType.SERVICE : this.productType.GOODS; 
    this.product.code = null; 
    this.getHsnCode('').subscribe(result => {
      this.hsncodeArray =result;
    });
  }
}
