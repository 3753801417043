import { Country } from "./country.model";
import { Currency } from "./currency.model";
import { SalesColumnSettings } from "./sales-column-settings.model";
import { PurchaseColumnSettings } from "./purchase-column-settings.model";
import { RcmColumnSettings } from "./rcm-column-settings.model";
import { Contact } from "./company.contact.model";
import { WarehouseContact } from "./company.warehouse-contact.model";
import { Bank } from "./bank.model";
import { Branch } from "./branch.model";
import { Warehouse } from "./warehouse.model";
export class Company {
  company: string;
  email: string;
  din: string;
  din_effective_date: any;
  cin: string;
  cin_effective_date: any;
  gst: string;
  gst_effective_date: any;
  pan: string;
  tan: string;
  tds: string;
  tds_effective_date: any;
  pf: string;
  pf_effective_date: any;
  esi: string;
  esi_effective_date: any;
  lut: string;
  lut_effective_date: any;
  is_gst_filing: boolean = false;
  is_tds_filing: boolean = false;
  is_cin_filing: boolean = false;
  is_pf_filing: boolean = false;
  is_esi_filing: boolean = false;
  is_sez: boolean = false;
  is_lut: boolean = false;
  address1: string;
  address2: string;
  city: string;
  is_inventory_needed: boolean = false;
  is_composite_company: boolean = false;
  is_branch: boolean = false;
  state: object;
  country: Country = new Country();
  postal_code: string;
  phone: string;
  logo: string;
  contacts: any = [Contact];
  company_type: any = {};
  company_category: any = {};
  date_format: string;
  fiscal_year_end: string;
  // fiscal_daterange: any = {};
  gst_mapping: {};
  stock_sort: string;
  incorporation_date: any;
  book_beginning: any;
  is_warehouse: boolean = false;
  warehouse: any = [Warehouse];
  bank: any = [Bank];
  branch: any = [Branch];
  member_details: any = [MemberDetails];
  currency: Currency = new Currency();
  sales_column_settings: SalesColumnSettings = new SalesColumnSettings();
  // sales_column_settings: any = {};
  purchase_column_settings: PurchaseColumnSettings = new PurchaseColumnSettings();
  rcm_column_settings: RcmColumnSettings = new RcmColumnSettings();
  // purchase_column_settings: any = {};
  // contra_settings: any = {};
  nature_of_activity: String;
  authorized_person: String;
  //12a / 80g register number and date is used as below
  registered_number: String;
  registered_date: any;
  financial_year: any;
}

export class MemberDetails {
  name: string;
  father_name: string;
  pan: string;
  din: string;
  dob: any;
  aadhar: number;
  mobile: number;
  address: string;
  email: string;
}
