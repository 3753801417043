import { Component, OnInit, OnChanges } from "@angular/core";
// import {Column} from '../reports'
import { SupportTicketService } from "../../_services/support-ticket.service";
import { SupportTicket } from "../../_models/support-ticket.model";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { PaymenttransactionsService } from "src/app/_services/paymenttransactions.service";
import { DatePipe } from "@angular/common";
import { TxTypePipe } from "src/app/_pipes/tx_type.pipe";
import { MastersService } from "src/app/_services/masters.service";
import { element } from "protractor";
import { filter } from "rxjs/operators";

import { resolve } from "url";
class DateOnlyPipe extends DatePipe {
  public transform(value): any {
    return super.transform(value, "MM/dd/y");
  }
}
// class TransactionOnlyPipe extends TxTypePipe {
//   public transform(value): any {
//     return super.transform(value);
//   }
// }

@Component({
  selector: "app-paymenttransactions",
  templateUrl: "./paymenttransactions.component.html",
  styleUrls: ["./paymenttransactions.component.scss"],
})
export class PaymenttransactionsComponent implements OnInit, OnChanges {
  filters: any = [];
  tableColumns: any;
  supportTicketList: any = [];
  pagination: any = { offset: 0, pageSize: 10 };
  sort: any;
  columns: {};
  company_id: string;
  branch_id: any;
  currency: string;
  page: any;
  loadingIndicator: boolean;
  paymenttransactionsList: any = [];
  page_count: any;
  account_head_lists: any[];
  coa_head: any;
  coa_ledger: any;
  constructor(
    private supportTicketService: SupportTicketService,
    private paymenttransactionsservice: PaymenttransactionsService,
    private authenticationService: AuthenticationService,
    private mastersService: MastersService
  ) {
    this.tableColumns = [
      {
        prop: "account_head.name",
        name: "Account Head",
        //sortable: "title",
        dataType: "number",
        filterType: "typeSelect",
        filterData: this.coa_ledger,
        filter: true,
      },
      {
        prop: "tx_date",
        name: "TX Date",
        sortable: "label",
        dataType: "date",
        filterType: "dateRange",
        pipe: new DateOnlyPipe("en-US"),
        //  // filterData: this.getLabels(),
        //   filterLabel: "name",
        //   filterId: "id",
        filter: true,
      },
      {
        prop: "tx_type_id",
        name: "Tx Type",
        dataType: "number",
        filterType: "typeSelect",
        filterData: this.getTransactionTypes(),
        //pipe: new TransactionOnlyPipe(),
        filterLabel: "name",
        filterId: "id",
        filter: true,
      },
      {
        prop: "tx_ref_id",
        name: "Tx Ref #",
        dataType: "number",
        filterType: "typeSelect",
        filterData: this.getTransactionTypes(),
        //pipe: new TransactionOnlyPipe(),
        filterLabel: "name",
        filterId: "id",
        filter: true,
      },
      {
        prop: "debit",
        name: "Debit",
        dataType: "number",
        filterType: "number",
        filterLabel: "name",

        filter: true,
      },
      {
        prop: "credit",
        name: "Credit",
        dataType: "number",
        filterType: "number",
        filterLabel: "name",
        filter: true,
        align: "right",
      },
    ];
    this.getDisplayColumns();
    this.sort = { updated_at: -1 };
  }

  ngOnInit() {
    //  this.getSupportTickets();
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.currency = this.authenticationService.getCompanyDetails()[
      "base_currency"
    ];
    this.getCoaHead();

    this.getPaymenttransactionssList();
  }
  ngOnChanges() {
    this.getCoaHead();
  }
  getTransactionTypes() {
    return [
      { id: 1, name: "Bill" },
      { id: 11, name: "INVOICE" },
      { id: 21, name: "PAYMENT" },
      { id: 31, name: "JOURNAL" },
      { id: 41, name: "CONTRA" },
      { id: 51, name: "EXPENSE" },
      { id: 61, name: "SALES ADVANCE" },
      { id: 71, name: "PURCHASE ADVANCE" },
      { id: 81, name: "CREDIT NOTE" },
      { id: 91, name: "DEBIT NOTE" },
      { id: 101, name: "STOCK JOURNAL" },
      { id: 111, name: "RECEIPTS" },
    ];
  }
  getPaymenttransactionssList() {
    this.filters.push({
      column: "company_id",
      type: "in",
      value: this.company_id,
    });
    this.paymenttransactionsservice
      .getPaymenttransactionss(
        this.filters,
        this.columns,
        this.sort,
        this.pagination
      )
      .subscribe((result) => {
        this.loadingIndicator = false;
        console.log("--------result===", result);

        this.paymenttransactionsList = result;
      });
  }
  // getLabels() {
  //   return [
  //     { name: "Bugs", id: "bug" },
  //     { name: "Performance", id: "performance" },
  //   ];
  // }
  // getSupportTickets() {
  //   this.supportTicketService
  //     .getPaginatedData(this.filters, this.pagination, this.columns, this.sort)
  //     .subscribe((result: any) => {
  //       this.supportTicketList = result.response;
  //     });
  // }
  getDisplayColumns() {
    this.columns = {};
    this.tableColumns.forEach((column) => {
      if (column.show !== undefined || column.show !== false) {
        this.columns[column.prop] = 1;
      }
    });
  }
  pageChange($data: any) {
    this.pagination = $data;
    this.getPaymenttransactionssList();
  }
  applySort(sorting: any) {
    this.sort = sorting;
    this.getPaymenttransactionssList();
  }
  search(filterData: any) {
    filterData.push({
      column: "company_id",
      type: "contains",
      value: this.company_id,
    });
    this.filters = filterData;

    this.getPaymenttransactionssList();
  }

  // syncTickets() {
  //   this.supportTicketService.syncTickets().subscribe((result) => {
  //     console.log("sync result====>", result);
  //   });
  // }

  async getCoaHead() {
    // this.account_head_lists = [];
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.coa_ledger = [];
    return new Promise((resolve, reject) => {
      this.mastersService
        .getCoaHead(this.company_id, "", {
          is_parent: false,
          is_ledger: true,
        })
        .toPromise()
        .then((res) => {
          this.account_head_lists = res["response"];
          this.account_head_lists.forEach((element, index) => {
            this.coa_head = {};
            if (element["flattened_data"]["name"] != "") {
              this.coa_head = {
                name: element["flattened_data"]["name"],
                id: element["flattened_data"]["id"],
              };
              this.coa_ledger.push(this.coa_head);
            }
          });
          return this.coa_ledger;
          resolve();
        });
    });
  }
}
