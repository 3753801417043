import { Component, EventEmitter, OnInit, TemplateRef, ViewChild, ChangeDetectorRef } from "@angular/core";
import swal from "sweetalert2";
import { AuthenticationService } from "../../_services/authentication.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { from } from "rxjs";
import { DebitBillService } from "../../_services/debit-bill.service";
import { debounceTime, switchMap, distinctUntilChanged } from "rxjs/operators";
import { VendorService } from "../../_services/vendor.service";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { ConstantService } from "../../_config/constants";

@Component({
  selector: "app-debit_bill",
  templateUrl: "./debit-bill.component.html",
  styleUrls: ["./debit-bill.component.scss"],
})
export class DebitBillComponent implements OnInit {
  filters: any = {};
  credit_bill: any = {};
  credit_billList: any = [];
  creditbillListColumns: any = [];
  count: number;
  loadingIndicator = false;
  reorderable = true;
  columns: any = {};
  sort: any = { debit_date: -1 };
  dt_default_sort = [{ prop: "debit_date", dir: -1 }];
  page: any = { offset: 0, limit: 10 };
  page_icon: any;
  company_id: string;
  branch_id: string;
  vendorListTypeahead = new EventEmitter<string>();
  vendor_lists: any = [];
  id: string;
  modalReference: any;
  debitNoteNumberListTypeahead = new EventEmitter<string>();
  debitNoteNumberList: any[];
  bill_status_list : any = [
    { name: "Opened", id: 1},
    { name: "Closed", id: 3},
    { name: "Cancelled", id: 4},
    { name: "Draft", id: 5}
  ];
  debitnoteDateOptions: IAngularMyDpOptions = {
    dateRange: true,
    dateFormat: ConstantService.DateFormat
  };
  companyDetails: any;
  currency: any;
  selectFiYear: any;
  // @ViewChild("actionTmpl", { static: true }) actionTmpl: TemplateRef<any>;
  constructor(
    private credit_billservice: DebitBillService,
    private authenticationService: AuthenticationService,
    private vendorService: VendorService,
    private cd: ChangeDetectorRef,
    public route: ActivatedRoute,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.companyDetails = this.authenticationService.getCompanyDetails();
    this.currency = this.companyDetails.base_currency;

    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];
    this.filters.do_date = {
      isRange: true,
      singleDate: null,
      dateRange: {
        beginDate: { year: this.selectFiYear.start_year, month: 4, day: 1 },
        beginJsDate: new Date(this.selectFiYear.start_year, 3, 1),
        endDate: { year: this.selectFiYear.end_year, month: 3, day: 31 },
        endJsDate: new Date(this.selectFiYear.end_year, 2, 31),
      }
    };
    this.getVendors("").subscribe((result) => {
      this.vendor_lists = result["response"]["data"];
    });
    this.getVendorsSearch();
    this.getDebitNoteNumberList("").subscribe((result) => {
      this.debitNoteNumberList = result["response"]["data"];
    });
    this.getDebitNoteNumberListSearch();
    this.creditbillListColumns = [
      {
        prop: "vendor",
        name: "Vendor Name",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "object",
        field_name: "vendor_name",
      },
      {
        prop: "debit_date",
        name: "Debit Note Date",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "date",
        // field_name: "vendor_name"
      },
      {
        prop: "debit_number",
        name: "Debit Note Number",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        // type: "object",
        // field_name: "bill_items"
      },
      {
        prop: "balance_amount",
        name: "Amount",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
      },
      {
        prop: "bill_status_name",
        name: "Status",
        sortable: "bill_status_name",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "status",
      },
      {
        name: "Action",
        type: "action",
        prop: "_id",
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false
      }
    ];
    this.creditbillListColumns.forEach((column) => {
      this.columns[column.prop] = 1;
    });
    this.columns.bill_number = 1;
    this.columns.bill_status = 1;

    this.page_icon = this.route.snapshot.data.menu.icon;
    if (this.route.snapshot.queryParams["q"] != "undefined")
      this.filters["vendor.name"] = this.route.snapshot.queryParams["q"];
    this.getCreditBillsList(this.page);


  }

  open(content, id) {
    this.id = id;
    this.modalReference = this.modalService.open(content, { size: "lg" });
  }

  onSort(event) {
    this.loadingIndicator = true;
    // console.log("event", event);
    this.sort = {};
    let sort_details = event.sorts[0];
    this.sort[sort_details.prop] = sort_details.dir == "asc" ? 1 : -1;
    this.getCreditBillsList(this.page);
  }

  getCreditBillsList(page_info: any) {
    if (page_info != null) {
      this.page = page_info;
    }
    this.filters["company_id"] = this.company_id;

    this.credit_billservice
      .getDebitBills(this.filters, this.columns, this.sort, this.page)
      .subscribe((result) => {
        this.loadingIndicator = false;
        // this.credit_billListColumns = [
        //   { name: "Action", cellTemplate: this.actionTmpl, prop: "muid" }
        // ];
        this.credit_billList = result["response"]["data"];
        this.page.count = result["response"]["count"];
      });
  }
  clearDebitNoteFilters() {
    // this.purchaseorder = {};
    this.filters = {};
    this.getCreditBillsList({ offset: 0, pageSize: 10 });
  }
  getDebitNoteNumberList(searchString: string) {
    let condition: any = {};
    condition["company_id"] = this.company_id;
    // if (searchString != "") {
    condition["debitnote_number_search_string"] = searchString;
    // }
    return this.credit_billservice.getDebitBills(
      condition,
      { debit_number: 1 },
      {},
      { offset: 0, limit: 10 }
    );
  }

  getDebitNoteNumberListSearch() {
    this.debitNoteNumberListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getDebitNoteNumberList(searchString))
      )
      .subscribe(
        (result) => {
          this.debitNoteNumberList = [];
          this.cd.markForCheck();
          this.debitNoteNumberList = result["response"]["data"];
        },
        (err) => {
          console.log(err);
          this.debitNoteNumberList = [];
        }
      );
  }
  clearCreditBillFilters() {
    this.filters = {};
    this.getCreditBillsList({});
  }
  deleteCreditBill(muid) {
    swal({
      title: "Are you sure want to delete?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result) {
        this.credit_billservice.deleteDebitBill(muid).subscribe((result) => {
          if (result["ok"] === 1) {
            swal("Deleted!", "DebitBill has been deleted.", "success");
            this.getCreditBillsList({});
          }
        });
      }
    });
  }
  confirmCreditNote(row_data) {
    swal({
      title: "Approve the Debit note ?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Approve it!",
    }).then((result) => {
      if (result) {
        this.credit_billservice.confirmDebitNote(row_data).subscribe((result) => {
          if (result["ok"] === 1) {
            swal("Success!", "DebitNote has been approved.", "success");
            this.getCreditBillsList({});
          }
        });
      }
    });

  }
  getVendors(searchString) {
    this.vendor_lists = [];
    let condition: any = {};
    let columns: any = {};
    if (searchString != "") {
      condition["vendor_name"] = searchString;
    }
    condition["company_id"] = this.company_id;
    columns["vendor_name"] = 1;
    columns["first_name"] = 1;
    columns["last_name"] = 1;
    columns["currency"] = 1;
    columns["address_line1"] = 1;
    columns["address_line2"] = 1;
    columns["valid_upto"] = 1;
    columns["country"] = 1;
    columns["state"] = 1;
    columns["phone"] = 1;
    columns["coa_data"] = 1;
    columns["credit_note_data"] = 1;
    columns["is_sez"] = 1;
    return this.vendorService.getVendors(condition, columns, {}, { limit: 10 });
  }

  getVendorsSearch() {
    this.vendorListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getVendors(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.vendor_lists = result["response"]["data"];
        },
        (err) => {
          console.log(err);
          this.vendor_lists = [];
        }
      );
  }
}
