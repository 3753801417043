import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-party',
  templateUrl: './party.component.html',
  styleUrls: ['./party.component.scss']
})
export class PartyComponent implements OnInit {
  is_customer_vendor: Boolean = false;
  navTab = 'customer';
  constructor() { }

  ngOnInit() {
  }

}
