import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../_services/user.service';
import { ConstantService } from '../../_config/constants';
import { AuthenticationService } from '../../_services/authentication.service';
import { HomeComponent } from '../../components/home/home.component';
import swal from 'sweetalert2';

@Component({
  providers: [HomeComponent],
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user: any = { username: '', password: '', email: '' };
  error: string;
  loading: boolean;
  company_id: any;
  selected_company: any = {};
  selected_branch: any = {};
  image_path: string;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private homeComponent: HomeComponent,
    private userservice: UserService

  ) { }

  ngOnInit() {
    this.getElixirLogo();
    this.company_id = this.authenticationService.getDefaultCompanyId();
    if (this.authenticationService.isLoggedIn() === true) {
      this.router.navigateByUrl('home');
    }
  }


  login() {
    this.loading = true;
    this.authenticationService
      .login(this.user.username, this.user.password)
      .subscribe(result => {
        if (result === true) {
          if (this.authenticationService.getUserRole() == 8) {

            this.authenticationService.setDefaultCompanyId(
              this.selected_company,
              this.selected_branch
            );
            this.company_id = this.authenticationService.getCompanyIds();
            this.router.navigateByUrl('/home')

          } else {
            this.router.navigateByUrl('/home')
          }
        } else {
          this.error = 'Username or password is incorrect';
          this.loading = false;
        }
      });
  }

  forgotPassword() {
    this.userservice.forgotPassword(this.user.email).subscribe(result => {
      if (result['status_code'] == 1) {
        swal('Success!', result['response'], 'success');
      } else {
        swal('Error!', result['response'], 'error');
      }
    });
  }
  getElixirLogo() {
    this.image_path = ConstantService.IMG_BASE_URL + "/uploads/elixir.png";
  }
}
