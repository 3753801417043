import { Injectable } from '@angular/core';
import { ApiService } from '../_services/api.service';
@Injectable({
  providedIn: 'root'
})
export class SupportTicketService {

  constructor(private http: ApiService) { }
  create(data: any) {
    data.title += ' ' + data.application_user;
    return this.http.post(ApiService.geturl('support_ticket', 'create'), data);
  }
  getPaginatedData(filters: any, pagination: any, columns?: any, sort?: any) {
    return this.http.post(ApiService.geturl('support_ticket', 'viewall'), {filters, columns, pagination, sort});
  }
  syncTickets() {
    return this.http.post(ApiService.geturl('support_ticket', 'sync_tickets'), {});
  }
  viewSupportTicket(support_ticket_muid: string) {
    return this.http.post(ApiService.geturl('support_ticket', 'view'), {support_ticket_muid: support_ticket_muid});
  }
  update(ticket: any, ticket_muid: any) {
    return this.http.post(ApiService.geturl('support_ticket', 'update'), {ticket, ticket_muid});
  }

}
