import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import swal from "sweetalert2";
import { ExpenseService } from "../../../_services/expense.service";

import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { CustomerService } from "../../../_services/customer.service";
import { VendorService } from "../../../_services/vendor.service";
import { Expense } from "../../../_models/expense";
// import { CustomersService } from '../../../app/_services/customers.service';
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { MastersService } from "../../../_services/masters.service";
import { CompanyService } from "../../../_services/company.service";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { ConstantService } from "src/app/_config/constants";
import { PaymentModalComponent } from "../../payment-modal/payment-modal.component";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BillService } from "src/app/_services/bill.service";
import { HsnService } from "src/app/_services/hsn.service";
import { CostgroupService } from "src/app/_services/costgroup.service";
import { differenceInDays } from "date-fns";


@Component({
  selector: "app-expense-add",
  templateUrl: "./expense-add.component.html",
  // styleUrls: ['./expense-add.component.scss']
})
export class ExpenseAddComponent implements OnInit {
  loading: boolean = false;
  id: string;
  filters: any = {};
  payment_accountList: any = [];
  payment_methodList: any = [
    { name: "Cash", id: 1031 },
    { name: "Bank", id: 1030 },
    // { name: "Check", id: 3 }
  ];
  is_edit_form = false;
  is_payee = false;
  sort: any = {};
  // dt_default_sort = [{ prop: 'costcategory_name', dir: 'asc' }, { prop: 'under_cc_category', dir: 'asc' }];
  columns: any = {};
  page: any = { offset: 0, limit: 2 };
  expense: any = new Expense();
  expenseList: any = [];
  payeeList: any = [];
  payeeListTypeahead = new EventEmitter<string>();
  bookingDateDPOptions: IAngularMyDpOptions = {
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };

  expenseitemsArray: any = [];
  company_id: string;
  paymentMethodListTypeahead = new EventEmitter<string>();
  accountHeadListTypeahead = new EventEmitter<string>();
  expenseListTypeahead = new EventEmitter<string>();
  company_details: any;

  is_book_and_pay: boolean;
  expenseType: string;
  selected_items: any[];
  selected_items_billid: any[];
  selected_type: any[];
  payType: string;
  passed: boolean;
  passed_type: boolean;
  selecteditem_array: any[];
  item_selected: any;
  is_same_state: boolean;
  cost_group_items: any;
  sum_of_costgroup: number = 0;
  user_id: any;
  paymentMethod: string;
  selectFiYear: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private expenseservice: ExpenseService,
    private billService: BillService,
    private customerservice: CustomerService,
    private countryservice: MastersService,
    private authenticationService: AuthenticationService,
    private companyService: CompanyService,
    private cd: ChangeDetectorRef,
    private vendorservice: VendorService,
    private hsnService: HsnService,
    private modalService: NgbModal,
    private costgroupService: CostgroupService
  ) { }

  ngOnInit() {
    this.expense.payee = null;
    this.user_id = this.authenticationService.getUserRole();
    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];

    this.route.paramMap.subscribe((params) => {
      this.expenseType = params.get("type");
      // this.expense.is_booking_payment = 2;
    });

    // this.expense.payment_method = this.payment_methodList[0];
    this.company_id = this.authenticationService.getDefaultCompanyId();
    // this.getPaymentCoaHead("").subscribe((result) => {
    //   this.payment_accountList = result["response"];
    // });
    this.getPaymentCoaHeadSearch();

    this.getExpenseCoaHead("").subscribe((result) => {
      this.expenseList = result["response"];
    });
    this.getExpenseCoaHeadSearch();

    // this.getPayee("").subscribe((result) => {
    //   this.payeeList = result["response"];
    // });
    // this.getPayeesearch();

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get("id");
      if (this.id != null) {
        this.getExpense();
      }

      let result = this.router.url.match("/edit/");
      if (result != null) {
        if (result[0] === "/edit/" && this.id != null) {
          this.is_edit_form = true;
          this.getExpense();
        }
      } else {
        this.expense.payment_date = {
          isRange: false,
          singleDate: {
            jsDate: new Date(),
          },
        };
      }
    });
    this.addNewItem(0);
    this.expense.booking_date={
      isRange: false,
      singleDate: {
        jsDate: differenceInDays(new Date(this.selectFiYear.end_year, 2, 31), new Date()) > 0 && differenceInDays(new Date(), new Date(this.selectFiYear.start_year, 3, 1)) > 0 ? new Date() : new Date(this.selectFiYear.end_year, 2, 31),
      },
    };
    this.getCompanyDetails();
  }

  addExpense() {
    this.loading = true;
    // if (this.is_payee) {

    // } else {
    this.expense.is_direct_payment = true;
    // }
    this.expense.booking_date = this.expense.booking_date["singleDate"][
      "jsDate"
    ].toISOString();

    this.expense.company_id = this.company_id;
    this.expense["tx_type"] = 51;

    let total_expense_amount = 0;
    let total_tax_amount = 0;
    let balance_amount = 0;
    this.expense.expense_items.forEach((element) => {
      total_expense_amount = total_expense_amount + element["total_amount"];
      total_tax_amount = total_tax_amount + element["tax_amount"];
    });
    this.expense.total_expense_amount = total_expense_amount;
    this.expense.total_tax_amount = total_tax_amount;
    this.expense.balance_amount = total_expense_amount;
    this.expense.is_booking_payment = 1;
    if (this.is_same_state) {
      this.expense.cgst = parseFloat((total_tax_amount / 2).toFixed(2));
      this.expense.sgst = parseFloat((total_tax_amount / 2).toFixed(2));
      this.expense.igst = 0;
    } else {
      this.expense.igst = parseFloat(total_tax_amount.toFixed(2));
      this.expense.cgst = 0;
      this.expense.sgst = 0;
    }
    if (this.is_payee) {
      this.expense.pay_type = 2;
    } else {
      this.expense.pay_type = 1;
    }
    // this.expense.booking_date = {
    //   isRange: false,
    //   singleDate: {
    //     jsDate: new Date(),
    //   },
    // };
    this.expense['expense_status'] = 5;
    this.expenseservice.addExpense(this.expense).subscribe((result) => {
      if (result['response']["status_code"] == 1) {
        delete this.expense['expense_status'];
        this.loading = false;
        swal("Expense  added sucessfully!", "expense added.", "success");

        this.router.navigateByUrl("/expense/list");
      }
    });
  }
  getExpense() {
    // let condition ={};
    // condition['_id'] = this.id;
    // condition['company_id']= this.company_id;


    this.expenseservice.viewExpense(this.id).subscribe((result) => {
      this.expense = result;
      // this.expense.booking_date = this.expense.expense_object.booking_date;
      this.expense.voucher_number = this.expense.expense_object.voucher_number;
      this.expense.ref_id = this.expense.expense_object.ref_id;
      this.expense.booking_date = {
        isRange: false,
        singleDate: {
          jsDate: new Date(this.expense.expense_object.payment_date),
        },
      };
    });
  }

  getExpenseCoaHead(searchString) {
    this.expenseList = [];
    return this.countryservice.getCoaHead(this.company_id, searchString, {
      not_in: [
        ConstantService.coa_heads.Closing_Inventories,
        ConstantService.coa_heads.Premilinary_Expenses
      ],
    });
  }

  getExpenseCoaHeadSearch() {
    this.expenseListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getExpenseCoaHead(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.expenseList = result["response"];
        },
        (err) => {
          console.log(err);
          this.expenseList = [];
        }
      );
  }

  getPaymentCoaHead(searchString) {
    this.payment_accountList = [];
    return this.countryservice.getCoaHead(this.company_id, searchString, {
      in: [this.expense.payment_method["name"]],
    });
  }

  getPaymentInChange() {
    if (this.expense.payment_method["name"] == "Bank") {
      this.paymentMethod = "Bank";
    } else {
      this.paymentMethod = "Cash";
    }

    this.getPaymentCoaHead("").subscribe((result) => {
      this.payment_accountList = result["response"];
    });
  }

  getPaymentCoaHeadSearch() {
    this.accountHeadListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getPaymentCoaHead(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.payment_accountList = result["response"];
        },
        (err) => {
          console.log(err);
          this.payment_accountList = [];
        }
      );
  }

  // getPayee(searchString) {
  //   this.payeeList = [];
  //   return this.countryservice.getCoaHead(this.company_id, searchString, {
  //     in: [
  //       //ConstantService.coa_heads.Accounts_Receivable,
  //       ConstantService.coa_heads.Accounts_Payable,
  //     ],
  //   });
  // }

  // getPayeesearch() {
  //   this.payeeListTypeahead
  //     .pipe(
  //       debounceTime(300),
  //       distinctUntilChanged(),
  //       switchMap((searchString) => this.getPayee(searchString))
  //     )
  //     .subscribe(
  //       (result) => {
  //         this.cd.markForCheck();
  //         this.payeeList = result["response"];
  //       },
  //       (err) => {
  //         console.log(err);
  //         this.payeeList = [];
  //       }
  //     );
  // }

  addUpdateExpense() {
    if (this.is_edit_form === true) {
      this.editExpense();
    } else {
      this.addExpense();
    }
  }

  editExpense() {
    this.loading = true;
    this.expense.booking_date = this.expense.booking_date["singleDate"][
      "jsDate"
    ];
    // this.expense.booking_date = this.expense.booking_date['singleDate']['jsDate'];
    this.expense.is_direct_payment = true;
    this.expense.company_id = this.company_id;
    this.expense["tx_type"] = 51;

    let total_expense_amount = 0;
    let total_tax_amount = 0;
    let balance_amount = 0;
    this.expense.expense_items.forEach((element) => {
      total_expense_amount = total_expense_amount + element["total_amount"];
      total_tax_amount = total_tax_amount + element["tax_amount"];
    });
    this.expense.total_expense_amount = total_expense_amount;
    this.expense.total_tax_amount = total_tax_amount;
    this.expense.balance_amount = total_expense_amount;
    this.expense.is_booking_payment = 1;
    if (this.is_same_state) {
      this.expense.cgst = parseFloat((total_tax_amount / 2).toFixed(2));
      this.expense.sgst = parseFloat((total_tax_amount / 2).toFixed(2));
      this.expense.igst = 0;
    } else {
      this.expense.igst = parseFloat(total_tax_amount.toFixed(2));
      this.expense.cgst = 0;
      this.expense.sgst = 0;
    }
    this.expense['expense_status'] = 5;
    this.expense['expense_status_name'] = "Draft";

    this.expenseservice
      .editExpense(this.expense, this.id)
      .subscribe((result) => {
        if (result["response"]["ok"] == 1) {
          this.loading = false;
          swal(" Success ", " Expense Updated Successfully ");
          this.router.navigateByUrl("/expense/list");
        } else {
          this.showErrors(result[" response "][" errors "]);
        }
      });
  }

  showErrors(errors) {
    let error_string: string;
    error_string = " ";
    errors.forEach((item) => {
      error_string += " < br / > " + item;
    });
    swal({
      title: "Error",
      html: error_string,
      type: "error",
    }).then(function () { });
  }

  addNewItem(index) {
    this.expense.expense_items.push({
      category: null,
      description: "",
      amount: "",
      tax: "",
      total_tax_amount: "",
    });
  }
  deleteItem(index) {
    this.expense.expense_items.splice(index, 1);
  }

  getCompanyDetails() {
    this.companyService.viewCompany(this.company_id).subscribe((result) => {
      this.company_details = result["response"];
      this.bookingDateDPOptions.dateFormat = this.company_details.date_format;
      let book_beginning_date = new Date(this.company_details.book_beginning);
      book_beginning_date.setDate(book_beginning_date.getDate() - 1);
      let curren_tx_dateOptions = this.getCopyOfOptions();
      curren_tx_dateOptions.disableUntil = {
        year: book_beginning_date.getFullYear(),
        month: book_beginning_date.getMonth() + 1,
        day: book_beginning_date.getDate(),
      };
      curren_tx_dateOptions.disableUntil = {year:this.selectFiYear.start_year, month:3, day:31};
      curren_tx_dateOptions.disableSince = {year:this.selectFiYear.end_year, month:4, day:1};
      this.bookingDateDPOptions = curren_tx_dateOptions;
    });
  }
  getCopyOfOptions(): IAngularMyDpOptions {
    return JSON.parse(JSON.stringify(this.bookingDateDPOptions));
  }

  getHsnData(index) {
    if (
      this.expense.expense_items[index]["category"]["flattened_data"][
      "hsn_code"
      ] != undefined ||
      this.expense.expense_items[index]["category"]["flattened_data"][
      "hsn_code"
      ] != null
    ) {
      let condition = {};
      condition["_id"] = this.expense.expense_items[index]["category"][
        "flattened_data"
      ]["hsn_code"];
      condition["effects_from.is_active"] = true;
      this.hsnService.getHSNByCondition(condition, {}).subscribe((result) => {
        result["response"][0]["effects_from"].forEach((element) => {
          if (element.is_active == true) {
            this.expense["expense_items"][index]["tax"] = element.igst;
            if (this.is_same_state) {
              this.expense["expense_items"][index]["cgst_tax"] =
                element.igst / 2;
              this.expense["expense_items"][index]["sgst_tax"] =
                element.igst / 2;
            }

            if (!this.is_same_state) {
              this.expense["expense_items"][index]["igst_tax"] = element.igst;
            }

            this.calculateTotalAmount(
              this.expense["expense_items"][index]["amount"],
              index
            );
          }
        });
      });
    }
  }

  calculateTotalAmount(event, index) {
    this.expense.expense_items[index]["tax_amount"] = 0;
    this.expense.expense_items[index]["total_amount"] = 0;
    if (!this.is_payee) {
      if (
        this.expense["expense_items"][index]["tax"] == "" ||
        this.expense["expense_items"][index]["tax"] == null
      ) {
        this.expense.expense_items[index]["total_amount"] = parseFloat(event);
        this.expense.expense_items[index]["tax_amount"] = 0;
      } else {
        this.expense.expense_items[index]["tax_amount"] =
          (event / 100) * parseInt(this.expense["expense_items"][index]["tax"]);

        this.expense.expense_items[index]["total_amount"] =
          this.expense.expense_items[index]["tax_amount"] + event;
        this.calculateGst(index, event);
      }
    } else {
      this.expense.expense_items[index]["total_amount"] = event;
    }
  }
  clearAmount() {
    this.expense.expense_items.forEach((element, index) => {
      this.expense.expense_items[index]["total_amount"] = "";
      this.expense.expense_items[index]["tax_amount"] = "";
      this.expense.expense_items[index]["tax"] = "";
      this.expense.expense_items[index]["amount"] = "";
      this.expense.expense_items[index]["category"] = "";
      this.expense.expense_items[index]["isgst_tax"] = "";
      this.expense.expense_items[index]["csgst_tax"] = "";
      this.expense.expense_items[index]["ssgst_tax"] = "";
    });
    if (!this.is_payee) {
      this.expense.payee = null;
    }
  }
  calculateGst(index, event) {
    if (this.is_same_state) {
      this.expense.expense_items[index]["cgst_amount"] =
        (event / 100) *
        parseInt(this.expense["expense_items"][index]["cgst_tax"]);
      this.expense.expense_items[index]["sgst_amount"] =
        (event / 100) *
        parseInt(this.expense["expense_items"][index]["sgst_tax"]);
    } else {
      this.expense.expense_items[index]["igst_amount"] =
        (event / 100) *
        parseInt(this.expense["expense_items"][index]["igst_tax"]);
    }
  }
  checkTaxBasedOnState() {
    let condition = {};
    this.companyService.viewCompany(this.company_id).subscribe((result) => {
      this.company_details = result["response"];
    });
    this.clearAmount();
    condition["_id"] = this.expense.payee["flattened_data"]["ref_id"];
    this.vendorservice.getVendorData(condition, {}, {}).subscribe((result) => {
      if (
        result["response"][0]["state"]["zone_id"] ==
        this.company_details.state["zone_id"]
      ) {
        this.is_same_state = true;
      } else {
        this.is_same_state = false;
      }
      //  this.calculateGst();
    });
  }

  addPayment(booking_muid: string) {
    const modalRef = this.modalService.open(PaymentModalComponent, {
      size: "lg",
    });
    modalRef.componentInstance.selectedarray_data.bill_ids = [booking_muid];
    modalRef.componentInstance.selectedarray_data.payment_from = "Expense";
    modalRef.componentInstance.selectedarray_data.payment_type = "single";
    modalRef.componentInstance.selectedarray_data.ref_id = booking_muid;
    if (!this.is_payee) {
      modalRef.componentInstance.selectedarray_data.is_direct_payment = true;
      modalRef.componentInstance.selectedarray_data.is_voucher_number = true;
    }
  }
  getCostGroup(expense_items) {
    if (expense_items["category"]["flattened_data"]["cost_center_id"] != null) {
      this.costgroupService
        .viewCostgroup(
          expense_items["category"]["flattened_data"]["cost_center_id"]
        )
        .subscribe((result) => {
          expense_items.cost_group_name =
            result["response"]["costcategory_name"];
          expense_items.cost_group_items = result["response"]["cost_center"];
        });
    }
  }
  changeExpenseAmount(i, j, event, expense_item) {
    this.sum_of_costgroup = 0;

    expense_item.cost_group_items.forEach((element) => {
      if (element.cost_group_amount) {
        this.sum_of_costgroup += parseInt(element.cost_group_amount);
      }
    });

    this.expense.expense_items[i].amount = this.sum_of_costgroup;
    this.calculateTotalAmount(this.sum_of_costgroup, i);
  }

  getVoucherType(voucher) {
    this.expense.voucher_name = "";
    this.expense.voucher_object = {};

    this.expense.voucher_name = voucher.name;
    this.expense.voucher_object = voucher;
  }
}
