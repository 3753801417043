<div class="card">
  <div class="card-header d-flex align-items-center">
    <h3 class="table-title">FixedAssets {{header}} </h3>

  </div>

  <form novalidate #addFixedAssestsForm="ngForm" (ngSubmit)="saveFixedAssests()">
    <div class="card-body row">
      <!-- <div class="form-group required col-8 col-sm-8 col-md-8 col-xl-8">
        <label for="ledger">Ledger</label>
        <ng-select [items]="ledger_lists" [typeahead]="ledgerListTypeahead" #ledger="ngModel" required name="ledger"
          bindLabel="flattened_data.name" [(ngModel)]="fixedassests.ledger" placeholder="Select Ledger">
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div [ngOptionHighlight]="search">
              {{item.flattened_data?.parent_name}} <i
                *ngIf="item.flattened_data?.parent_name!=''" class="fa fa-caret-right"></i>
              {{ item.flattened_data?.name }}
            </div>
          </ng-template>
        </ng-select>


        <app-validation-errors [mformField]="ledger" label="Ledger"></app-validation-errors>
      </div> -->

      <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
        <label>Group Name</label>
        <div>
          <input type="text" name="group_name" required #group_name="ngModel" class="form-control"
            placeholder="Group Name" [(ngModel)]="fixedassests.group_name">
          <app-validation-errors [mformField]="group_name" label="Group Name"></app-validation-errors>
        </div>
      </div>

      <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
        <label>Description</label>
        <div>
          <input type="text" name="description" required #description="ngModel" class="form-control"
            placeholder="Description" [(ngModel)]="fixedassests.description">
          <app-validation-errors [mformField]="description" label="Description"></app-validation-errors>
        </div>
      </div>

      <div class="form-group required col-md-3">
        <label for="useful_life">Useful Life</label>
        <input type="text" class="form-control" required #useful_life="ngModel" placeholder="Useful Life"
          id="useful_life" name="useful_life" [(ngModel)]="fixedassests.useful_life" number>
        <app-validation-errors [mformField]="useful_life" label="Useful Life">
        </app-validation-errors>
      </div>

      <div class="form-group required col-md-3">
        <label for="income_tax_rate">Income Tax Rate</label>
        <input type="text" class="form-control" required #income_tax_rate="ngModel" placeholder="Income Tax Rate"
          id="income_tax_rate" name="income_tax_rate" [(ngModel)]="fixedassests.income_tax_rate" number>
        <app-validation-errors [mformField]="income_tax_rate" label="Income Tax Rate">
        </app-validation-errors>
      </div>


    </div>
    <div class="card-footer">
      <button type="submit" [disabled]="!addFixedAssestsForm.valid || loading==true" class="btn btn-primary">Save
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
      </button>

    </div>
  </form>
  <!-- </div> -->
  <!-- </div> -->
</div>
