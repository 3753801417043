<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">

      <form novalidate #searchExpenseForm="ngForm" (ngSubmit)="getExpensesList({ offset: 0, pageSize: 12 })">
        <div class="card">
          <div class="card-body">
            <div class="btn-group mr-3 mb-2 float-right">
              <!-- <button type="button" class="btn btn-primary mr-2">
                Pay
              </button> -->
              <button *ngIf="this.companyDetails.fi_year.status!='Finalized'" type="button" class="btn btn-primary" rel="tooltip" routerLink="/receipts/book_pay/1">
                Add Receipts
              </button>

            </div>
            <h3 class="table-title"><i class="fa fa-user"></i> Receipts </h3>

            <div class="card-body row">
              <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label>Receipts Amount</label>
                <input type="text" name="total_expense_amount" class="form-control" placeholder="Amount"
                  [(ngModel)]="total_expense_amount">
              </div>
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label class="col-form-label">Customer</label>

                <ng-select [items]="customerList" [typeahead]="customerListTypeahead" name="payee" #payee="ngModel"
                  [(ngModel)]="filters.payee" id="payee" bindLabel="name">
                  <ng-template ng-label-tmp let-item="item">
                    <div [ngOptionHighlight]="search">
                      {{ item?.flattened_data?.name }}
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item?.flattened_data?.name }}
                    </div>
                  </ng-template>
                </ng-select>
                <app-validation-errors [mformField]="payee" label="Payee">
                </app-validation-errors>
                </div> -->
              <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label class="col-form-label" for="ref_id">Ref Id</label>
                <input type="text" class="form-control" name="ref_id" placeholder="Reference ID"
                  [(ngModel)]="filters.ref_id" />
              </div>
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label class="col-form-label" for="voucher_number">Voucher Number</label>
                <input type="text" class="form-control" name="voucher_number" placeholder="Voucher Number"
                  [(ngModel)]="filters.voucher_number" />
              </div> -->
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 required">
                <label class="col-form-label">Payment Date</label>
                <div class="">
                  <div class="input-group">
                    <input angular-mydatepicker placeholder="Payment Date" required
                      (click)="payment_date_dp.toggleCalendar()" #payment_date_dp="angular-mydatepicker"
                      class="form-control" name="payment_date" [(ngModel)]="filters.payment_date"
                      [options]="payment_dateDpOptions" />
                    <div class="input-group-append">
                      <button type="button" class="btn btn-secondary" *ngIf="filters.payment_date"
                        (click)="payment_date_dp.clearDate()">
                        <i class="fa fa-close"></i>
                      </button>
                    </div>
                    <!-- toggle calendar button -->
                    <div class="input-group-append">
                      <button type="button" class="btn btn-secondary" (click)="payment_date_dp.toggleCalendar()">
                        <i class="fa fa-calendar-o"></i>
                      </button>
                    </div>
                  </div>
                  <app-validation-errors [mformField]="payment_date_dp" label="Payment Date">
                  </app-validation-errors>
                </div>
              </div>
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label class="">Payment Type</label>
                <div class="">
                  <ng-select [items]="payment_accountList" [typeahead]="accountHeadListTypeahead" name="payment_account"
                    id="payment_account" required #payment_account="ngModel" bindLabel="name"
                    [(ngModel)]="filters.payment_account">
                    <ng-template ng-label-tmp let-item="item">
                      <div [ngOptionHighlight]="search">
                        {{item?.flattened_data?.name}}
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <div [ngOptionHighlight]="search">
                        {{item?.flattened_data?.name}}
                      </div>
                    </ng-template>

                  </ng-select>
                  <!-- <app-validation-errors [mformField]="payment_account" label="Payment Account">
                  </app-validation-errors> -->
                </div>
              </div>
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label class="">Receipts Head</label>
                <div class="">
                  <ng-select [items]="receiptList" [typeahead]="receiptListTypeahead" name="category" required
                    #receipt="ngModel" [(ngModel)]="filters.category" id="category" bindLabel="name">
                    <ng-template ng-label-tmp let-item="item">
                      <div [ngOptionHighlight]="search">
                        {{item?.flattened_data?.name}}
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <div [ngOptionHighlight]="search">
                        {{item?.flattened_data?.name}}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label class="">Voucher#</label>
                <div class="">
                  <ng-select [items]="voucherNoList" [typeahead]="voucherNoTypeahead" name="voucher_number" required
                    #receipt="ngModel" [(ngModel)]="filters.voucher_number" id="voucher_number" bindLabel="name"
                    bindValue="voucher_number">
                    <ng-template ng-label-tmp let-item="item">
                      <div [ngOptionHighlight]="search">
                        {{item.voucher_number}}
                      </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <div [ngOptionHighlight]="search">
                        {{item?.voucher_number}}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label>Payee Name</label>
                <input type="text" name="payee" class="form-control" placeholder="Payee Name" [(ngModel)]="filters.payee">
              </div> -->
            </div>
            <div>
              <button type="submit" class="btn btn-primary mr-2"> <i class="fa fa-search"></i> Search</button>
              <button type="button" class="btn btn-danger"
                (click)="filters={};getExpensesList({ offset: 0, pageSize: 12 })"><i class="fa fa-close"></i>
                Clear
              </button>

            </div>
          </div>
        </div>
      </form>
    </div>

  </div>
</div>

<div class="card p-sm-0">
  <div class="card-body">
    <ngx-datatable [rows]="expenseList" [loadingIndicator]="loadingIndicator" [externalPaging]="true"
      [columnMode]="'force'" [limit]="page.limit"  [count]="page.count" [offset]="page.offset" (page)="getExpensesList($event)"
      [externalSorting]="true" (sort)="onSort($event)" [reorderable]="reorderable" [footerHeight]="50"
      [rowHeight]="'auto'" (select)="onSelectOrders($event)" class="material striped" [sorts]="this.dt_default_sort"
      [sortType]="'multi'">

      <!-- <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false" [draggable]="false"
        [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="dispCheckBox">
      </ngx-datatable-column> -->

      <ngx-datatable-column *ngFor="let col of expenseListColumns" [flexGrow]="col.flexGrow" [name]="col.name"
        [sortable]="col.sortable" [cellClass]="col.css_class" [prop]="col.prop">
        <ng-template ngx-datatable-header-template let-sort="sortFn">
          <span (click)="sort()">{{ col.name }}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div [ngSwitch]="col.type">
            <span *ngSwitchCase="'percentage'">{{row[col.prop]}}%</span>
            <span *ngSwitchCase="'object'"> {{ row[col.prop][col.field_name]}}</span>
            <span *ngSwitchCase="'date'"> {{ row[col.prop]|date:dateFormat }}</span>
            <span *ngSwitchCase="'action'">
              <div class="btn-group mr-3" *ngIf="row.receipt_status_name =='Draft'">
                <a *ngIf="user_id !=8" class="btn btn-primary" name="send{{ i }}" type="button" (click)="confirmReceipt(row)"
                  rel="tooltip">Confirm</a>
                  <a *ngIf="user_id ==8" class="btn btn-primary" (click)="open(receiptViewContent, row._id)" rel="tooltip">View</a>

                <div class="btn-group" ngbDropdown placement="bottom-right" container="body" role="group">
                  <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
                  <div class="dropdown-menu" ngbDropdownMenu>
                    <button *ngIf="user_id !=8"  class="dropdown-item" (click)="open(receiptViewContent, row._id)" type="button" rel="tooltip">
                      View
                    </button>
                    <button class="dropdown-item" routerLink="/receipts/edit/{{row._id}}" type="button" rel="tooltip">
                      Edit
                    </button>
                    <button *ngIf="user_id !=8"  class="dropdown-item" (click)="deleteReceipt(row._id)" type="button" rel="tooltip">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              <div class="btn-group mr-3" *ngIf="row.receipt_status_name !='Draft'">
                <a class="btn btn-primary" (click)="open(receiptViewContent, row._id)" rel="tooltip">View</a>

              </div>
            </span>
            <span *ngSwitchCase="'status'">
              <span *ngIf="row[col.prop]=='Draft'" class="badge badge-danger">{{row[col.prop]}}</span>

              <span *ngIf="row[col.prop]!='Draft'" class=" badge badge-success">{{ row[col.prop]}}</span>
            </span>
            <span *ngSwitchDefault>
              {{ row[col.prop] }}
            </span>
          </div>

        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>

<ng-template #receiptViewContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Receipt View</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
       <app-expense-view [id]="id" [moduleName]="'receipt'"></app-expense-view>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
