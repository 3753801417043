export class Journal {
  tx_type_id?: any;
  company_id?: any;
  branch_id?: any;
  journal_date: any;
  journal_no?: any;
  memo?: string;
  journal_items: any = [];
  in_qty: string;
  out_qty: string;
  product: string;
  voucher_name: string;
  voucher_object: {};
  amount:number;
}
