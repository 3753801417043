import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
import { GstService } from "../../_services/gst.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { AuthenticationService } from "../../_services/authentication.service";
@Component({
  selector: "app-gst-view",
  templateUrl: "./gst-view.component.html",
  styleUrls: ["./gst-view.component.scss"]
})
export class GstViewComponent implements OnInit {
  gst: any = [];
  gst_id: any;
  company_id: any;
  constructor(
    private router:Router,
    private gstService:GstService,
    private authenticationService: AuthenticationService,
    private route:ActivatedRoute) { }
  ngOnInit() {
    // this.route.par
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.gst_id = params.get("id");
      this.getGst();
    });
  }

  getGst(){
    let condition ={};
    condition['_id'] = this.gst_id;
    condition['company_id'] = this.company_id;
    condition['is_view'] = true;

    this.gstService.getGsts(condition).subscribe(result => {
      this.gst = result;
      console.log("=======this.GST===>>>",this.gst);
    }); 
  }

  goBack() {
    //this.location.back();
  }
}
