<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header d-flex align-items-center">
          <h3 class="table-title"><i class="fa fa-gear"></i> HSN/SAC </h3>
        </div>
        <form novalidate #addHsnForm="ngForm" (ngSubmit)="addHsnSubmit()">
          <div class="card-body row">
            <div class="form-group col-12 col-sm-6 col-md-2 col-xl-2 ">
              <label class="col-form-label" for="type">Type</label>
              <ng-select id="type" #hsn_type="ngModel" [disabled]="is_edit_form==true" required placeholder="Type"
                name="type" [(ngModel)]="type">
                <ng-option value="1">Hsn</ng-option>
                <ng-option value="2">Sac</ng-option>
              </ng-select>
              <app-validation-errors [mformField]="hsn_type" label="Type">
              </app-validation-errors>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 ">
              <label class="col-form-label ">Group Code</label>
              <input type="number" minlength="4" class="form-control" name="group_code" #hsn_group_code="ngModel"
                [(ngModel)]="hsn.group_code" placeholder="Hsn Group Code">
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label class="col-form-label ">Code</label>
              <input [readonly]="is_edit_form==true" type="text" required class="form-control" name="code"
                #hsn_code="ngModel" [(ngModel)]="hsn.code" placeholder="Hsn Code">
              <app-validation-errors [mformField]="hsn_code" label="Code">
              </app-validation-errors>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label class="col-form-label ">Description</label>
              <input [readonly]="is_edit_form==true" type="text" class="form-control" required name="description"
                #description="ngModel" [(ngModel)]="hsn.description" placeholder="Description">
              <app-validation-errors [mformField]="description" label="Description">
              </app-validation-errors>
            </div>

            <div *ngIf="is_edit_form!=true" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label class="col-form-label ">Effective From</label>

              <div class="input-group ">
                <!-- input box -->
                <input class="form-control" (click)="dp.toggleCalendar()" name="from_date{{i}}"
                  placeholder="Effects From" angular-mydatepicker #dp="angular-mydatepicker" [(ngModel)]="hsn.from_date"
                  [options]="myOptions" />
                <!-- clear date button -->
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" (click)="dp.clearDate()">
                    <i class="fa fa-close"></i>
                  </button>
                </div>

                <!-- toggle calendar button -->
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" (click)="dp.toggleCalendar()">
                    <i class="fa fa-calendar-o"></i>
                  </button>
                </div>
              </div>
            </div>

            <div *ngIf="is_edit_form!=true" class="form-group col-12 col-sm-6 col-md-3 col-xl-3 ">
              <label class="col-form-label ">GST Percent</label>
              <input type="number" required class="form-control" name="gst_percent" #gst_percent="ngModel"
                [(ngModel)]="hsn.gst_percent" placeholder="GST Percent">

              <app-validation-errors [mformField]="gst_percent" label="GST Percent">
              </app-validation-errors>
            </div>

            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 ">
              <label class="col-form-label ">Chapter</label>
              <input type="number" class="form-control" name="chapter" [(ngModel)]="hsn.chapter" placeholder="Chapter">
            </div>

            <div class="form-group col-INF ">
              <!-- <label class="col-form-label ">Country Code</label> -->
              <select [attr.readonly]="is_edit_form==true" class="form-control" style="display: none;"
                #country_code="ngModel" name="country_code" [(ngModel)]="hsn.country_code">
                <option *ngFor="let country of country_list" [ngValue]="country">{{country.name}}</option>
              </select>

              <!-- <app-validation-errors 
                            [mformField] = "country_code" 
                            label = "Country Code" >
                        </app-validation-errors> -->
            </div>


            <div class="col-12 mb-2 " *ngIf="hsn_paramType!='add'">
              <button [disabled]="isDisabled" type="button" class="btn btn-primary" (click)="addItems()">Add</button>
            </div>

            <div class="col-12">
              <table class="table table-striped table-bordered" *ngIf="hsn_paramType!='add'">
                <thead>
                  <tr>
                    <th>Effective From</th>
                    <th>GST</th>
                    <th *ngIf="hsn_paramType!='add'">Action</th>
                  </tr>
                </thead>
                <tbody>

                  <tr *ngFor="let hsn_items of hsnArray; let i=index;">
                    <td>
                      <span *ngIf="i<hsnArray_length">
                        {{hsn_items.from_date| date: 'mediumDate'}}
                      </span>
                      <div class="input-group" *ngIf="i>=hsnArray_length">
                        <!-- input box -->

                        <input class="form-control" (click)="dp.toggleCalendar()" name="from_date{{i}}"
                          placeholder="Effects From" angular-mydatepicker #dp="angular-mydatepicker"
                          [(ngModel)]="hsn_items.from_date" [options]="myOptions" />
                        <!-- clear date button -->
                        <div class="input-group-append">
                          <button type="button" class="btn btn-secondary" (click)="dp.clearDate()">
                            <i class="fa fa-close"></i>
                          </button>
                        </div>

                        <!-- toggle calendar button -->
                        <div class="input-group-append">
                          <button type="button" class="btn btn-secondary" (click)="dp.toggleCalendar()">
                            <i class="fa fa-calendar-o"></i>
                          </button>
                        </div>

                      </div>
                      <app-validation-errors [mformField]="dp" label="From Date">
                      </app-validation-errors>

                    </td>

                    <td>
                      <span *ngIf="i<hsnArray_length"> {{hsn_items.igst}}</span>
                      <input *ngIf="i>=hsnArray_length" type="number" #igst="ngModel" class="form-control"
                        name="hsn_items.igst{{i}}" required id="igst" [(ngModel)]="hsn_items.igst" placeholder="GST">
                      <app-validation-errors [mformField]="igst" label="GST">
                      </app-validation-errors>
                    </td>

                    <td>
                      <span *ngIf="i<hsnArray_length"> <button type="button" class="btn btn-primary action-btn"
                          disabled>Delete</button> </span>
                      <button *ngIf="i>=hsnArray_length" type="button" class="btn btn-primary action-btn"
                        (click)="deleteItem(i)">Delete</button></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" [disabled]="!addHsnForm.valid || loading==true" class="btn btn-primary">Save 
              <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
