
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root', })
export class CostgroupService {

    constructor(private http: ApiService) { }

    addCostgroup(costgroup_data: any = {}) {
        return this.http.post(ApiService.geturl('costgroup', 'add'), costgroup_data);
    }
    editCostgroup(costgroup_data: any, id: string) {

        return this.http.post(ApiService.geturl('costgroup', 'edit'),
            { data: costgroup_data, costgroup_id: id });
    }
    deleteCostgroup(id: string) {
        return this.http.post(ApiService.geturl('costgroup', 'delete'),
            { id: id });
    }

    viewCostgroup(id: string) {
        return this.http.post(ApiService.geturl('costgroup', 'view'),
            { costgroup_id: id });
    }

    getCostgroups(filters: any = {}, columns: any = {}, sort: any = {}, page: any = {}) {
        return this.http.post(ApiService.geturl('costgroup', 'viewall'),
            { filters: filters, columns: columns, sort: sort, page: page });
    }
}