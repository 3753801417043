<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <form class="form-horizontal" (ngSubmit)="getPaginatedVendors({ offset: 0, pageSize: 10 })" novalidate
        #filterVendor="ngForm">
        <div class="card">
          <div class="card-body">
            <div class=" mr-3 mb-2 float-right">
              <button type="button" class="btn btn-primary float-right mb-4" routerLink="/vendor/add">
                <i class="icon icon-add"></i>Add Vendor
              </button>
              <button type="button" class="btn btn-primary  mr-2" (click)="open(importVendor)"> <i class="fa fa-upload"></i>
                Import
                Vendor</button>

            </div>
            <h3 class="table-title"><i class="fa fa-address-card"></i> Vendor List</h3>

            <div class="card-body row">
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label>Vendor Name</label>
                <input type="text" name="vendor_name" class="form-control" placeholder="Vendor Name"
                  [(ngModel)]="filters.vendor_name">
              </div>
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="gst_number">GST Number</label>
                <input type="text" class="form-control" name="gst_number" id="gst_number" placeholder="GST Number"
                  [(ngModel)]="filters.gst_number">
              </div>
              <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="phone">Phone Number</label>
                <input type="text" class="form-control" name="phone" id="phone" placeholder="Phone"
                  [(ngModel)]="filters.phone">
              </div> -->
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="email">Email</label>
                <input type="text" class="form-control" name="email" id="email" placeholder="Email"
                  [(ngModel)]="filters.email">
              </div>
              <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="payables">Payables</label>
                <input type="text" class="form-control" name="payables" id="payables" placeholder="Payables"
                  [(ngModel)]="filters.payables">
              </div> -->
              <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="status">Status</label>
                <input type="text" class="form-control" name="status" id="status" placeholder="Status"
                  [(ngModel)]="filters.status">
              </div> -->

            </div>
            <div>
              <button class="btn btn-primary mr-2" type="submit"><i class="fa fa-search"></i> Search</button>
              <button class="btn btn-danger" (click)="clearVendorFilters()" type="button"><i class="fa fa-close"></i>
                Clear</button>
            </div>
          </div>
        </div>

      </form>

    </div>
  </div>
  <ng-template #importVendor let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Upload file</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div style="cursor: pointer;" ngfDrop selectable="1" [(validDrag)]="validComboDrag" [(file)]="file"
          [(lastInvalids)]="lastInvalids" class="well my-drop-zone"
          accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          [class.invalid-drag]="validComboDrag===false" [class.valid-drag]="validComboDrag"
          (filesChange)="lastFileAt=getDate()">
          Drop/select <b>CSV</b> here...
          <!-- {{dataSource.name}} -->
        </div>
        <div style="margin-bottom: 40px" class="mt-2">
          <table class="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Size</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>{{ file.name }}</strong>
                </td>
                <td nowrap>
                  {{ file.type }}
                </td>
                <td nowrap>
                  {{ file.size/1024/1024 | number:'.2' }} MB
                </td>
                <td nowrap>
                  <button type="button" class="btn btn-danger btn-xs" (click)="file={}">
                    <span class="fa fa-trash"></span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-primary" [disabled]="" (click)="uploadFile()">Save</button>
    </div>
  </ng-template>
</div>

<div class="card p-sm-0">
  <ngx-datatable [rows]="vendors" [loadingIndicator]="loadingIndicator" [externalPaging]="true" [columnMode]="'force'"
    [count]="count" [limit]="page.limit" [offset]="page.offset" (page)='getPaginatedVendors($event)'
    [externalSorting]=true (sort)="onSort($event)" footerHeight="50" [rowHeight]="'auto'" class="material striped"
    [sorts]="this.dt_default_sort">

    <ngx-datatable-column *ngFor="let col of vendorTableListColumns" [flexGrow]="col.flexGrow" [name]="col.name"
      [sortable]="col.sortable" [prop]="col.prop">
      <ng-template ngx-datatable-header-template let-sort="sortFn">
        <span (click)="sort()">{{ col.name }}</span>
      </ng-template>
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <div [ngSwitch]="col.type">


          <span *ngSwitchCase="'action'">
            <div class="button-row">
              <button class="btn btn-primary mr-1" *ngxPermissionsOnly="['Admin','Elixir - Admin', 'Elixir - Manager', 'Client - Admin', 'Client - Manager']" routerLink="/vendor/edit/{{row._id}}" mat-mini-fab color="primary"
                title="Edit">
                <i class="fa fa-edit"></i>
              </button>

              <button class="btn btn-danger" *ngxPermissionsOnly="['Admin','Elixir - Admin', 'Elixir - Manager', 'Client - Admin', 'Client - Manager']" (click)="deleteVendor(row._id)" mat-mini-fab color="warn" title="Delete">
                <i class="fa fa-trash"></i>
              </button>
            </div>

          </span>
          <span *ngSwitchDefault>
            {{ row[col.prop] }}
          </span>
        </div>

      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>

</div>