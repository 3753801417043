import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { AuthenticationService } from "../../_services/authentication.service";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import swal from "sweetalert2";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { Router, ActivatedRoute, RoutesRecognized } from "@angular/router";
import { CompanyService } from "../../_services/company.service";
import { UserService } from "../../_services/user.service";
import {FinancialYearsComponent} from '../financial-years/financial-years.component';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  financialYearsConfig: FinancialYearsComponent = new FinancialYearsComponent(this.router, this.authenticationService);
  is_branch: boolean = false;
  selected_company: any = {};
  selected_branch: any = {};
  company_id: string;
  financial_details: any = [];
  current_selected_company: any = {};
  user_id: string;
  companies_list: any = [];
  branch_lists: any = [];
  financial_year_list: any = [];
  companiesListTypeahead = new EventEmitter<string>();
  setCompanyFormDialogOption: NgbModalOptions = {
    keyboard: false,
    backdrop: "static"
  };

  @ViewChild("setDefaultCompanyForm", { static: true })
  setDefaultCompanyForm: TemplateRef<any>;
  user_details: any = {};
  user_role: any;
  financialYears: any;
  company_details: any;
  financial_year: any;
  id: any;
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private router: Router,
    private companyService: CompanyService
  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    // this.financial_details = this.authenticationService.getFinancialDetails();
    this.user_id = this.authenticationService.getUserId();
    this.user_role = this.authenticationService.getUserRole();

    this.getUserInfo();

    this.selected_company = null;
    this.selected_branch = null;

    this.getCompaniesSearch();

  }

  filterCompany() {
    let company_data: any = [];
    if (this.user_role > 1) {
      this.user_details['company_ids'].forEach(company_id => {
        this.companies_list.forEach(companyObj => {
          if (company_id == companyObj['_id']) {
            company_data.push(companyObj);
          }
        });
      });
      this.companies_list = company_data;
    }
  }

  setBranch() {
  this.branch_lists = [];
    if(this.selected_company !=undefined){
    if (this.selected_company.branch.length > 1) {
      this.is_branch = true;
      if(this.user_role > 1) {
        if(this.user_details.branch_ids.length > 0){
          this.user_details.branch_ids.forEach(branchId => {
            this.selected_company.branch.forEach(branchObj => {
              if (branchId == branchObj['_id']) {
                this.branch_lists.push(branchObj);
              }
            });
          });
        }else{
            this.selected_company.branch.forEach(branchObj => {
                this.branch_lists.push(branchObj);
            });
        }
    } else {
      this.selected_company.branch.forEach(branchObj => {
          this.branch_lists.push(branchObj);
      });
    }
      if ([6, 11, 12].indexOf(this.user_role) >=0) {
      //Dashboard
      this.router.navigateByUrl('/home');
      }

    } else if (this.selected_company.branch.length > 1 && this.user_role <= 1) {

      this.is_branch = true;
      this.branch_lists = this.selected_company.branch;
    } else if (this.selected_company.branch.length = 1) {

      this.is_branch = false;
      this.selected_branch = this.selected_company.branch[0];
      this.updateDefaultCompany();
    }
   
      this.financialYearsConfig = this.selected_company.financial_year;

   

  }
  }

  getUserInfo() {
    this.userService.viewUser(this.user_id).subscribe(result => {
      this.user_details = result['response'];
      this.getCompanyData();
    })

  }

  updateDefaultCompany() {
    this.current_selected_company = this.selected_company._id;
    this.id = this.user_details._id;
    this.userService.updateCurrentCompany({current_selected_company: this.current_selected_company}, this.id).subscribe((result)=>{

    });
    let pathString = '/dashboard';

    this.authenticationService.setDefaultCompanyId(
      this.selected_company,
      this.selected_branch
    );
    if(this.user_role == 8) {
    this.financial_year_list = this.selected_company.financial_year;
    this.financialYears = this.financial_year_list[this.financial_year_list.length - 1];
    this.company_details = this.authenticationService.getCompanyDetails();

    this.company_details.fi_year = this.financialYears;
    this.financial_details = this.authenticationService.setDefaultCompany(this.company_details);
    console.log('this.financialYearsConfig=====', this.financialYears);
    this.router.navigateByUrl(pathString).then(() => {
      location.reload();
    });
  
  }
    this.company_id = this.authenticationService.getDefaultCompanyId();
    
    if(this.selected_company.financial_year.length ==1){
      this.financial_year_list = this.selected_company.financial_year;
      this.financialYears = this.financial_year_list[0];
      this.company_details = this.authenticationService.getCompanyDetails();
  
      this.company_details.fi_year = this.financialYears;

      this.financial_details = this.authenticationService.setDefaultCompany(this.company_details);
    this.router.navigateByUrl(pathString).then(() => {
      location.reload();
    });
    }
  }

  getCompanies(searchString) {
    this.companies_list = [];
    let condition: any = {};
    if (searchString != "") {
      condition["company"] = searchString;
    }
    return this.companyService.getCompanies(condition);
  }

  getCompanyData() {
    
    this.getCompanies("").subscribe(result => {
      this.companies_list = result['response'];
      if(this.authenticationService.getCompanyIds().length > 1){
        this.filterCompany();
      }else{
        this.selected_company = this.companies_list.filter(company => company._id == this.authenticationService.getCompanyIds()[0])[0];
      
        this.setBranch();
        if (this.company_id) {
          if (this.companies_list) {
            this.selected_company = this.companies_list.filter(
              company_data => company_data["_id"] === this.company_id
            )[0];
            this.setBranch();
          }
        }
      }
      // this.setBranch();
      // if (this.company_id) {
      //   if (this.companies_list) {
      //     this.selected_company = this.companies_list.filter(
      //       company_data => company_data["_id"] === this.company_id
      //     )[0];
      //     this.setBranch();
      //   }
      // }
      //this.filterCompany();
    });
  }

  getCompaniesSearch() {
    this.companiesListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(searchString => this.getCompanies(searchString))
      )
      .subscribe(
        result => {
          this.cd.markForCheck();
          this.companies_list = result['response'];
        },
        err => {
          console.log(err);
          this.companies_list = [];
        }
      );
  }

  isCompanySelected() {
    if (this.companies_list.length > 0) {
      // swal('Add Company!', 'Please Add The Company First or select the company', 'warning');
    } else if (this.selected_company == undefined) {
      this.router.navigate(["company/add"]);

      // const customerModalRef = this.modalService.open(this.setDefaultCompanyForm, this.setCompanyFormDialogOption);
    }
  }
}
