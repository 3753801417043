import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
// import { UsersService } from 'app/_services/users.service;'
import { UserService } from "../../../_services/user.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { AuthenticationService } from "../../../_services/authentication.service";
import { CompanyService } from "../../../_services/company.service";
@Component({
  selector: "app-users",
  templateUrl: "./users-view.component.html",
  styleUrls: ["./users-view.component.scss"],
})
export class UsersViewComponent implements OnInit {
  company_id: any;
  user: any = {};
  id: any;
  navTab = "profile";
  companies_list: any;
  company_branch_details: any = []
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private companyService: CompanyService,
    private location: Location
  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    // this.viewInvoice();
    this.route.paramMap.subscribe((params) => {
      this.id = params.get("id");
      if (this.id != "") {
        this.viewUser();
      }
    });
  }

  viewUser() {
    this.userService.viewUser(this.id).subscribe((result) => {
      this.user = result["response"];
      this.getCompanies();

    });
  }


  getCompanies() {
    this.companyService.getCompanies().subscribe(result => {
      this.companies_list = result["response"];
      this.filterCompany();

    });
  }

  filterCompany() {
    let company_data: any = [];
    let branch_data: any = [];
    this.user['company_ids'].forEach(company_id => {
      this.companies_list.forEach(companyObj => {
        if (company_id == companyObj['_id']) {
          company_data.push(companyObj)
          this.company_branch_details.push([{ company_name: companyObj.company }])
          branch_data = [];
          companyObj.branch.forEach((branchObj, key2) => {
            if (this.user['branch_ids'].includes(branchObj._id) && key2 > 1) {
              branch_data.push({ branch_name: branchObj.name })
            }
          });
          this.company_branch_details[this.company_branch_details.length - 1].push(branch_data)
        }
      });
    });
    this.companies_list = company_data;
    // console.log('this.companies_list', this.companies_list)
    // console.log('this.merged_company_data', this.company_branch_details)
  }

  goBack() {
    this.location.back();
  }
}
