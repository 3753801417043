import { filter } from 'rxjs/operators';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
// import {Column} from '../reports'
import { SupportTicketService } from '../../_services/support-ticket.service';
import { SupportTicket } from '../../_models/support-ticket.model';
@Component({
  selector: 'app-support-ticket-list',
  templateUrl: './support-ticket-list.component.html',
  styleUrls: ['./support-ticket-list.component.scss']
})
export class SupportTicketListComponent implements OnInit {
  tableColumns: any;
  supportTicketList: any = [];
  pagination: any = { offset: 0, pageSize: 10 };
  sort: any;
  filters: any;
  columns: {};
  @ViewChild('actionTemplate',{static:true}) actionTemplate:TemplateRef<any>;
  constructor(private supportTicketService: SupportTicketService) {

    this.tableColumns = [
      {
        prop: 'ticket_id',
        name: '#',
        sortable: 'ticket_id',
        dataType: 'number',
        filterType: 'number',
        filter: true
      },
      {
        prop: 'title',
        name: 'Title',
        sortable: 'title',
        dataType: 'text',
        filterType: 'text',
        filter: true
      },
      {
        prop: 'labels',
        name: 'Type',
        sortable: 'label',
        dataType: 'text',
        filterType: 'typeMultiSelect',
        filterData: this.getLabels(),
        filterLabel: 'name',
        filterId: 'id',
        filter: true
      },
      {
        prop: 'status',
        name: 'Status',
        dataType: 'text',
        filterType: 'typeSelect',
        filterData: this.getStatuses(),
        filterLabel: 'name',
        filterId: 'id',
        filter: true
      },
      {
        prop: 'updated_at',
        name: 'Updated Date',
        dataType: 'date',
        filterType: 'dateRange',
        filter: true
      },
      // {
      //   prop: 'created_by',
      //   name: 'Created By',
      //   dataType: 'text',
      //   filterType: 'typeSelect',
      //   filter: true
      // },
      {
        prop:'action',
        cellTemplate: this.actionTemplate,
        name: 'Action',
        // dataType: 'text',
        // filterType: 'typeSelect',
        // filter: true
      }
    ];
    this.getDisplayColumns();
    this.sort = { updated_at: -1 };
  }

  ngOnInit() {
    this.getSupportTickets();
  }
  getStatuses() {
    return [{ name: 'Bugs', id: 'bugs' }, { name: 'Performance', id: 'performance' }];
  }
  getLabels() {
    return [{ name: 'Bugs', id: 'bug' }, { name: 'Performance', id: 'performance' }];
  }
  getSupportTickets() {
    this.supportTicketService.getPaginatedData(this.filters
      , this.pagination, this.columns, this.sort).subscribe((result: any) => {
        this.supportTicketList = result.response;
      });
  }
  getDisplayColumns() {
    this.columns = {};
    this.tableColumns.forEach((column) => {
      if (column.show !== undefined || column.show !== false) {
        this.columns[column.prop] = 1;
      }
    });

  }
  pageChange($data: any) {
    this.pagination = $data;
    this.getSupportTickets();
  }
  applySort(sorting: any) {
    this.sort = sorting;
    this.getSupportTickets();
  }
  search(filterData: any) {
    console.log(filterData);
    this.filters = filterData;
    this.getSupportTickets();

  }

  syncTickets()  {
    this.supportTicketService.syncTickets().subscribe(result=>{
      console.log('sync result====>', result);
    });
  }
}
