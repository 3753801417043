import {
  Component,
  OnInit,
  ChangeDetectorRef,
  EventEmitter
} from "@angular/core";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Invoice, Ledger } from "../../../_models/invoice.model";
import { Salesorder } from "../../../_models/salesorder";
import { SalesorderService } from "../../../_services/salesorder.service";
import { InvoiceService } from "../../../_services/invoice.service";
import { MastersService } from "../../../_services/masters.service";
import { CustomerService } from "../../../_services/customer.service";
import { CompanyService } from "../../../_services/company.service";
import { ProductService } from "../../../_services/product.service";
import { AuthenticationService } from "../../../_services/authentication.service";
import { SequenceService } from "../../../_services/sequence.service";
import { HsnService } from "../../../_services/hsn.service";
import swal from "sweetalert2";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { TransactionService } from "../../../_services/transaction.service";
import { ConstantService } from "src/app/_config/constants";
import { ContactAddress } from "../../../_models/contact_address.model";
import {
  AddressTemplate,
  AddressTemplateEmitConfig,
} from "../../../_models/address_template.model";
import { AccountsHeadTemplate } from "../../../_models/accounts-head-template.model";
import { AddressTemplateComponent } from "src/app/common/address-template/address-template.component";
import { PaymentModalComponent } from "../../payment-modal/payment-modal.component";
import { addDays, differenceInDays } from "date-fns";
import { exit } from "process";
@Component({
  selector: "app-invoice-add",
  templateUrl: "./invoice-add.component.html",
  styleUrls: ["./invoice-add.component.scss"],
})
export class InvoiceAddComponent implements
  OnInit {
  loading: boolean = false;
  // selected_sales_order: Salesorder;
  salesOrderList: Salesorder[] = [];
  customer: any;
  header: string;
  dueDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };
  invoiceAccountConfig: AccountsHeadTemplate = new AccountsHeadTemplate();
  invoiceDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };
  salesOrderdateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };
  ewayBillDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    disableSince: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };
  myDateInit = true;
  model: IMyDateModel = null;
  currency_list: any;
  company_id: any;
  id: string;
  is_edit_form = false;
  is_same_state = false;
  public invoice: Invoice = new Invoice();
  customer_lists: any;
  voucher_type_lists: any;
  voucherTypeListTypeahead = new EventEmitter<string>();
  customerListTypeahead = new EventEmitter<string>();
  product_lists: any;
  productListTypeahead = new EventEmitter<string>();
  accountHeadListTypeahead = new EventEmitter<string>();
  salesOrderListTypeahead = new EventEmitter<string>();
  company_details: any = {};
  sequence_no: any;
  sequence_id: any;
  currency: any;
  is_sales_invoice = false;
  currentInvoice: any;
  invoice_dates_array: any = [
    "invoice_date",
    "sales_order_date",
    "due_date",
    "credit_date",
  ];
  account_head_lists: any;
  is_gst_applicable = true;
  branch_id: string;
  files: File[] = [];
  addForm = false;
  invoiced_array: any = [];
  original_salesorderitems: any = [];
  is_pos = false;
  // shippingAddress: ContactAddress = new ContactAddress();
  shippingAddressTemplateConfig: AddressTemplate = new AddressTemplate();
  billingAddressTemplateConfig: AddressTemplate = new AddressTemplate();
  billingAddressListTmplConfig: AddressTemplate = new AddressTemplate();
  shippingAddressListTmplConfig: AddressTemplate = new AddressTemplate();
  contactAddressList: ContactAddress[] = [];
  // billingAddress: ContactAddress = new ContactAddress();
  selecteditem_array: any;
  item_selected: any;
  selected_items_billid = [];
  payType = "";
  product_type: any;
  invoicenumber: any;
  is_composite_company = false;
  closed = false;
  overall_discount_in_percentage: number = 0;
  is_round_available: boolean = false;
  decimal_value: number = 0;
  round_value: number;
  salesordernumberlength: any;
  invoiceprefix: any;
  invoicesuffix: any;
  labelName: string;
  invoice_label_name: string;
  is_debit_note_add: boolean = false;
  is_debit_note = false;
  editForm: string = "";
  currentUrl: any;
  user_id: any;
  result1: any;
  result2: any;
  selectFiYear: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private invoiceService: InvoiceService,
    private salesorderService: SalesorderService,
    private mastersService: MastersService,
    private customerService: CustomerService,
    private companyService: CompanyService,
    private productService: ProductService,
    private sequenceService: SequenceService,
    private hsnService: HsnService,
    private authenticationService: AuthenticationService,
    private transactionService: TransactionService,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    // this.invoice.total_amount = 0;
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.user_id = this.authenticationService.getUserRole();

    this.currency = this.authenticationService.getCompanyDetails()[
      "base_currency"
    ];
    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];
    // this.invoice.voucher_type = this.company_details.sales_column_settings.voucher.voucher_type;
    const today = new Date();
    this.getCompanyDetails();
    // this.invoice.is_discount = false;
    // this.invoice.is_productwise_discount = false;
    // this.invoice.discount_in_percentage = false;

    // this.getSalesOrderByCustomer("").subscribe((result: Salesorder) => {
    //   this.salesOrderList = result["response"]["data"];
    // });
    this.getSalesOrderListSearch();
    this.route.paramMap.subscribe((params) => {
      this.id = params.get("id");
      let today = differenceInDays(new Date(this.selectFiYear.end_year, 2, 31), new Date()) > 0 && differenceInDays(new Date(), new Date(this.selectFiYear.start_year, 3, 1)) > 0 ? new Date() : new Date(this.selectFiYear.end_year, 2, 31);
      this.result1 = this.router.url.match("/edit");
      this.result2 = this.router.url.match("/debit-note/");
      this.currentUrl = this.router.url.match("/sales-invoice/");
      var result4 = this.router.url.match("/credit-bill/edit/");
      var result5 = this.router.url.match("/creditnote/add");

      if (this.result1 != null || this.result2 != null || this.currentUrl != null) {
        this.invoice_label_name = "Invoice";
      }
      // let result = this.router.url.match("/edit/")
      this.addNewBalancingItem();
      // this.getCoaHead('').subscribe(result => {
      //   this.account_head_lists = result['response'];
      //   this.addRemoveDiscountHead();
      // });

      if (this.result1 != null) {
        // this.viewInvoice();

        if (this.result1[0] === "/edit" && this.id != null) {
          this.is_edit_form = true;
          this.header = "Invoice Edit";
          this.labelName = "Sales Invoice";
          this.editForm = "invoice";
        }
      } else if (this.result2 != null) {

        if (this.result2[0] === "/debit-note/" && this.id != null) {
          this.is_debit_note = true;
          // this.viewInvoice();
          this.header = "Credit Note";
          // this.is_debit_note = true;
          // this.getNextSequence();
          this.labelName = "Sales Invoice";
        }
      } else if (result4 != null) {
        if (result4[0] === "/credit-bill/edit/" && this.id != null) {
          // this.is_edit_form = true;
          this.is_debit_note_add = true;

          // this.getDebitNote();
          this.header = "Credit Note Edit";
          this.labelName = "Credit Note";

        }
      } else if (result5 != null) {
        if (result5[0] === "/creditnote/add") {


          // this.is_edit_form = true;
          this.is_debit_note_add = true;
          this.invoice.invoice_date = {
            isRange: false,
            singleDate: {
              jsDate: today,
            },
          }
          // this.getDebitNote();
          // this.bill.balancing_items = [];
          this.header = "Credit Note";
          this.labelName = "Credit Note";

        }
      } else if (this.currentUrl != null) {
        this.addForm = true;
        if (this.currentUrl[0] === "/sales-invoice/" && this.id != null) {
          this.is_sales_invoice = true;
          // this.is_debit_note = true;
          // this.viewSalesOrder();
          this.header = "Invoice Entry";
          this.labelName = "Sales Invoice";
        }
      } else {
        this.header = "Invoice Entry";
        this.labelName = "Sales Invoice";
        this.addForm = true;

        this.invoice_dates_array.forEach((element) => {
          if (element != "credit_date" && element != "due_date") {
            this.invoice[element] = {
              isRange: false,
              singleDate: {
                jsDate: today,
              },
            };
          }
        });
        // this.getNextSequence();

      }

    });

    this.getCustomers("").subscribe((result) => {
      this.customer_lists = result;
    });
    this.getCustomersSearch();
    this.getCurrencies();
    this.getProducts("").subscribe((result) => {
      this.product_lists = result;
    });
    this.getProductSearch();
    // TODO fix array push issue
    // this.invoice.invoice_items = [];
    this.addNewItem();

    this.getCoaData();
    this.getCoaHeadSearch();
    this.getCoaHead("", "").subscribe((result) => {
      this.account_head_lists = result['response'];
    });
    this.setInvoiceAccountHeaderConfig();

  }

  onDateChanged(event: IMyDateModel): void {
    this.invoice.due_date = {
      isRange: false,
      singleDate: {
        jsDate: addDays(new Date(event['singleDate']['jsDate']), this.invoice.customer.payment_term),
      },
    }
  }

  getCurrencies() {
    this.mastersService.getCurrencies().subscribe((result) => {
      this.currency_list = result["response"];
    });
  }

  addUpdateInvoice() {
    this.invoice.is_without_gst = !this.is_gst_applicable;
    if (this.is_edit_form === true) {
      this.editInvoice();
    } else if (this.is_debit_note === true) {
      this.addDebitNote();
    } else if (this.is_debit_note_add) {
      if (this.id != null) {
        this.editCreditNote();
      } else {
        this.addCreditNote();
      }
    }
    else {
      this.addInvoice();
    }
  }

  addCreditNote() {
    this.loading = true;
    this.invoice.company_id = this.company_id;
    this.invoice.branch_id = this.branch_id;
    return this.invoiceService.creditNote(this.invoice).subscribe((result) => {
      if (result["response"]["muid"] != "") {
        this.loading = false;
        swal("Added Credit Note sucessfully!", "Credit Note Added.", "success");
        this.router.navigateByUrl("/credit-bill/list");
      } else {
        this.showErrors(result["response"]["errors"]);
      }
    });
  }

  async editBill() {
    this.loading = true;
    await this.invoiceService.editInvoice(this.invoice, this.id).subscribe((result) => {
      if (result["status_code"] == 1) {
        this.loading = false;
        this.router.navigateByUrl("/bill/list");
        swal(" Success !", " Bill Updated Successfully ", "success");
      } else {
        this.showErrors(result["response"]["errors"]);
      }
    });
  }
  editCreditNote() {
    this.loading = true;
    this.invoice.company_id = this.company_id;
    this.invoice.branch_id = this.branch_id;
    this.invoiceService.updateCreditNote(this.invoice, this.id).subscribe((result) => {
      if (result["status_code"] == 1) {
        this.loading = false;
        swal("Credit Note Updated sucessfully!", "Credit Note Updated.", "success");
        this.router.navigateByUrl("/credit-bill/list");
      } else {
        this.showErrors(result["response"]["errors"]);
      }
    });

  }
  showErrors(errors) {
    let error_string: string;
    error_string = " ";
    errors.forEach((item) => {
      error_string += " < br / > " + item;
    });
    swal({
      title: "Error",
      html: error_string,
      type: "error",
    }).then(function () { });
  }

  getCoaHead(searchString, in_condition) {
    this.account_head_lists = [];

    if (in_condition != null) {
      if (
        this.invoice.is_discount == true &&
        this.invoice.is_productwise_discount == false
      ) {
        in_condition = [
          ConstantService.coa_heads.Other_Income,
          ConstantService.coa_heads.Discount,
        ];
      } else if (
        this.invoice.is_discount == true &&
        this.invoice.is_productwise_discount == true
      ) {
        in_condition = [ConstantService.coa_heads.Other_Income];
      } else {
        in_condition = [ConstantService.coa_heads.Other_Income];
      }
    }
    if (searchString != null) {
      searchString = searchString;
    }
    return this.mastersService.getCoaHead(this.company_id, searchString, {
      in: in_condition,
      is_parent: true,
    });
  }

  getCoaData() {
    let in_condition = [];
    if (
      this.invoice.is_discount == true &&
      this.invoice.is_productwise_discount == false
    ) {
      in_condition = [
        ConstantService.coa_heads.Other_Income,
        ConstantService.coa_heads.Discount,
      ];
    } else if (
      this.invoice.is_discount == true &&
      this.invoice.is_productwise_discount == true
    ) {
      in_condition = [ConstantService.coa_heads.Other_Income];
    } else {
      in_condition = [ConstantService.coa_heads.Other_Income];
    }
    this.getCoaHead("", in_condition).subscribe((result) => {
      this.account_head_lists = result["response"];
    });
  }

  addRemoveDiscountHead() {
    let in_condition = [];
    if (
      this.invoice.is_discount == true &&
      this.invoice.is_productwise_discount == false
    ) {
      in_condition = [
        ConstantService.coa_heads.Other_Income,
        ConstantService.coa_heads.Discount,
      ];
    } else if (
      this.invoice.is_discount == true &&
      this.invoice.is_productwise_discount == true
    ) {
      in_condition = [ConstantService.coa_heads.Other_Income];
    } else {
      in_condition = [ConstantService.coa_heads.Other_Income];
    }
    let account_head_data = this.account_head_lists;
    this.account_head_lists = [];
    account_head_data.forEach((element) => {
      in_condition.forEach((head_name) => {
        if (element.flattened_data.name == head_name) {
          this.account_head_lists.push(element);
        }
      });
    });
  }

  getCoaHeadSearch() {
    this.accountHeadListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getCoaHead(searchString, ""))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.account_head_lists = result["response"];
          // this.addRemoveDiscountHead();
        },
        (err) => {
          console.log(err);
          this.account_head_lists = [];
        }
      );
  }

  getProductQty(product, itemsObj) {
    if (this.is_debit_note == false) {
      if (
        product.type != 2 &&
        this.company_details["is_inventory_needed"] == true
      ) {
        const filter = {};
        filter["product"] = product["_id"];
        filter["company_id"] = this.company_id;
        this.transactionService
          .getProductsQuantity(filter)
          .subscribe((result) => {
            let total_qty = 0;
            if (result["response"]["data"][0] != undefined && result["response"]["data"][0] != "") {
              total_qty = result["response"]["data"][0]["total_bal_qty"];
            }
            let current_qty = 0;
            this.invoice.invoice_items.forEach((element) => {
              if (element.product["_id"] == product["_id"]) {
                current_qty += parseInt(element["units"]);
              }
            });

            console.log('current_qty', current_qty);
            console.log('total_qty', total_qty);

            if (current_qty > total_qty) {
              // itemsObj.units = itemsObj.units - (current_qty - total_qty);
              itemsObj.units = 0;
              swal(
                "Quantity Exceeded!",
                itemsObj.product.name + " has only " + total_qty + " quantity",
                "warning"
              );
              this.calculatePrice(itemsObj);
            }
          });
      } else {
        if (itemsObj.product.type == 2) {
          itemsObj.units = 1;
        }
        this.calculatePrice(itemsObj);
      }
    }
  }

  setCurrency(currency) {
    this.invoice.currency = currency;
  }

  addInvoice() {
    this.loading = true;
    if (
      this.overall_discount_in_percentage > 0 &&
      this.invoice.is_discount == true &&
      this.invoice.discount_in_percentage == true
    ) {
      this.invoice.overall_discount_in_percentage = this.overall_discount_in_percentage;
    }
    if (this.is_pos) {
      this.invoice.invoice_items.forEach((itemObj) => {
        this.original_salesorderitems.forEach((itemObject) => {
          itemObject.is_invoiced = 2;
        });
      });
      this.invoice.sales_status = 2;
      this.invoice.sales_status_name = "invoiced";
    } else {
      this.invoiced_array = [];
      // console.log('this.invoice.invoice_items=====>>>', this.invoice.invoice_items); return false;

      this.invoice.invoice_items.forEach((itemObj) => {
        this.original_salesorderitems.forEach((itemObject) => {
          if (itemObj.product.sku == itemObject.product.sku) {
            itemObject.is_invoiced = 2;
          }
        });
      });

      this.original_salesorderitems.forEach((itemsObj) => {
        if (itemsObj.is_invoiced == 3) {
          this.invoiced_array.push(3);
        } else if (itemsObj.is_invoiced == 1) {
          this.invoiced_array.push(3);
        } else {
          this.invoiced_array.push(2);
        }
      });

      let invoice_status = this.invoiced_array.includes(3);
      if (invoice_status) {
        this.invoice.sales_status = 3;
        this.invoice.sales_status_name = "partially-invoiced";
      } else {
        this.invoice.sales_status = 2;
        this.invoice.sales_status_name = "invoiced";
      }
    }

    if (this.company_id != "") {
      this.invoice.company_id = this.company_id;

      if (typeof this.invoice.sales_order_date == "string") {
        this.invoice.sales_order_date = {
          isRange: false,
          singleDate: {
            jsDate: new Date(this.invoice.sales_order_date),
          },
        };
      }
      this.invoice.original_items = this.original_salesorderitems;
      this.invoice.sequence_number = parseInt(this.invoicenumber);

      this.invoice.due_date = this.invoice.due_date['singleDate']['jsDate'];
      this.invoice.sales_order_date = this.invoice.sales_order_date['singleDate']['jsDate'];
      this.invoice.invoice_date = this.invoice.invoice_date['singleDate']['jsDate'];;

      if (this.is_pos) {
        this.invoice.is_pos = this.is_pos;
        this.invoiceService.addInvoice(this.invoice).subscribe((result) => {
          if (result["muid"]) {
            this.loading = false;
            if (this.files.length != 0 && this.files.length != undefined) {
              this.invoiceService
                .UpdateInvoiceFile(this.files, result["muid"])
                .subscribe((result) => { });
            }
            if (this.is_pos == true) {
              const modalRef = this.modalService.open(PaymentModalComponent, {
                size: "lg",
              });
              this.selected_items_billid.push(result["muid"]);
              const dataArray = {
                bill_ids: this.selected_items_billid,
                payment_from: "Sales",
                payment_type: "single",
                payment_by: "vendor",
                hide_advance_div: false,
              };
              modalRef.componentInstance.selectedarray_data = dataArray;
            }
            // swal("Success", "Invoice created successfully", "success");
          } else {
            swal("Error", result["errors"], "error");
          }
        });
      } else {
        this.invoiceService.addInvoice(this.invoice).subscribe((result) => {
          if (result["muid"]) {
            // if (this.files.length != 0 && this.files.length != undefined) {
            //   this.invoiceService
            //     .UpdateInvoiceFile(this.files, result["muid"])
            //     .subscribe((result) => { });
            // }
            // this.openPaymentModal('', '');
            // this.modalService.open(PaymentModalComponent, { size: "lg" });
            this.router.navigate(["invoice/list"]);
            swal("Success", "Invoice created successfully", "success");
          } else {
            swal("Error", result["errors"], "error");
          }
        });
      }
    }
  }
  voucher_type(arg0: string, voucher_type: any) {
    throw new Error("Method not implemented.");
  }
  // openPaymentModal(row_data, pay_method) {
  //   let selected: any = [];

  //   if (row_data) {
  //     selected.push(row_data);
  //     // this.onSelectOrders({ selected });
  //   }

  //   this.selecteditem_array = [];

  //   this.item_selected = this.selected_items[0];

  //   if (pay_method == "bulk") {
  //     if (this.selected_items.length == 1) {
  //       this.payType = "single";
  //       this.passed = true;
  //     } else {
  //       this.payType = "multiple";
  //       this.passed = this.selected_items.every(this.isEqualVendor);
  //     }
  //   } else if (pay_method == "single") {
  //     this.payType = "single";
  //     this.passed = true;
  //   }
  //   if (this.passed) {
  //     const modalRef = this.modalService.open(PaymentModalComponent,{size:'lg'});

  //     let dataArray = {
  //       bill_ids: this.selected_items_billid,
  //       payment_from: "Purchase",
  //       payment_type: this.payType
  //     };

  //     modalRef.componentInstance.selectedarray_data = dataArray;
  //     modalRef.result
  //       .then(result => {
  //         console.log(result);
  //       })
  //       .catch(error => {
  //         console.log(error);
  //       });
  //   } else {
  //     swal("Error!", "Select same vendor / choose an order", "error");
  //   }
  // }
  updateSequence() {
    let sequence_data: any = {};
    let sequence_muid: any = {};
    sequence_data["seq_no"] = parseInt(this.sequence_no) + 1;
    sequence_muid["muid"] = this.sequence_id;
    sequence_data["company_id"] = this.company_id;
    this.sequenceService
      .editSequence(sequence_data, sequence_muid["muid"])
      .subscribe((result) => { });
  }

  addDebitNote() {
    this.loading = true;
    if (this.company_id != "") {
      this.invoice.company_id = this.company_id;
      if (this.branch_id != "") {
        this.invoice.branch_id = this.branch_id;
      }
      // if (typeof this.invoice.sales_order_date == 'string') {
      //   this.invoice.sales_order_date = {
      //     isRange: false, singleDate: {
      //       jsDate: new Date(this.invoice.sales_order_date)
      //     }
      //   };
      // }
      this.invoice['tx_type_id'] = 81;
      this.invoiceService.addCreditNote(this.invoice).subscribe((result) => {
        if (result["response"]["muid"]) {
          this.loading = false;
          swal("Success !", "Credit Note created successfully", "success");
          this.router.navigate(["/credit-bill/list"]);
        } else {
          swal("Error", "Some error occured", "error");
        }
      });
    }
  }

  editInvoice() {
    this.loading = true;
    if (this.company_id != "") {
      this.invoice.company_id = this.company_id;
      if (this.branch_id != "") {
        this.invoice.branch_id = this.branch_id;
      }

      this.invoice.due_date = this.invoice.due_date['singleDate']['jsDate'];
      this.invoice.sales_order_date = this.invoice.sales_order_date['singleDate']['jsDate'];
      this.invoice.invoice_date = this.invoice.invoice_date['singleDate']['jsDate'];;
      this.invoice.invoice_status = 5;
      this.invoice.invoice_status_name = "Draft";
      this.invoiceService
        .editInvoice(this.invoice, this.id)
        .subscribe((result) => {
          if (result["response"]["status_code"] == 1) {
            this.loading = false;
            this.router.navigateByUrl("/invoice/list");
            swal("Success", "Invoice updated successfully", "success");
          } else {
            swal("Error", "Some error occured", "error");
          }
        });
    }
  }

  getCustomers(searchString) {
    this.customer_lists = [];
    const condition: any = {};
    const columns: any = {};
    if (searchString != "") {
      condition["customer_name"] = searchString;
    }
    condition["company_id"] = this.company_id;
    columns["customer_name"] = 1;
    columns["first_name"] = 1;
    columns["last_name"] = 1;
    columns["currency"] = 1;
    columns["address_line1"] = 1;
    columns["billing_address"] = 1;
    columns["shipping_address"] = 1;
    columns["payment_term"] = 1;
	columns["is_sez"] = 1;
    columns["gst_number"] = 1;
    columns["coa_data"] = 1;
    columns["debit_note_data"] = 1;
    columns["country"] = 1;
    columns["state"] = 1;
    columns["phone"] = 1;
    columns["contact_address"] = 1;
    return this.customerService.getCustomersData(condition, columns);
  }
  getCustomersSearch() {
    this.customerListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getCustomers(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.customer_lists = result;
        },
        (err) => {
          console.log(err);
          this.customer_lists = [];
        }
      );
  }

  setBillShipAddress() {
    this.shippingAddressTemplateConfig = new AddressTemplate();
    this.shippingAddressListTmplConfig = new AddressTemplate();
    this.billingAddressListTmplConfig = new AddressTemplate();
    this.billingAddressTemplateConfig = new AddressTemplate();
    if (this.invoice.customer != null) {
      // Address Details
      this.shippingAddressTemplateConfig.contact_address = this.invoice.customer.contact_address[0];
      this.shippingAddressTemplateConfig.user_role = "customer";
      this.shippingAddressTemplateConfig.user_muid = this.invoice.customer._id;
      this.shippingAddressTemplateConfig.action_type = "view";

      this.shippingAddressListTmplConfig.action_type = "dropdown_viewall";
      this.shippingAddressListTmplConfig.user_muid = this.invoice.customer._id;
      this.shippingAddressListTmplConfig.user_role = "customer";
      this.shippingAddressListTmplConfig.address_type = "shipping_address";

      this.billingAddressTemplateConfig.action_type = "view";
      this.billingAddressTemplateConfig.user_role = "customer";
      this.billingAddressTemplateConfig.contact_address = this.invoice.customer.contact_address[0];
      this.billingAddressTemplateConfig.user_muid = this.invoice.customer._id;

      this.billingAddressListTmplConfig.action_type = "dropdown_viewall";
      this.billingAddressListTmplConfig.address_type = "billing_address";
      this.billingAddressListTmplConfig.user_muid = this.invoice.customer._id;
      this.billingAddressListTmplConfig.user_role = "customer";
    } else {
      // this.invoice.billing_address = "";
      // this.invoice.shipping_address = "";
    }
  }
  getAmount(itemsObj) {
    let tax_amount: any = 0;
    itemsObj.amount = 0;
    let actual_amount: any;
    if (this.company_details.is_lut) {
      this.checkForLutSez();
      if (
        this.is_gst_applicable == true &&
        this.company_details.is_composite_company == false
      ) {
        tax_amount = itemsObj.tax_amount;
        itemsObj.amount = itemsObj.item_price + tax_amount;
      } else if (
        this.company_details.is_composite_company == false &&
        this.company_details.is_gst_filing == true
      ) {
        tax_amount = itemsObj.tax_amount;
        itemsObj.amount = itemsObj.item_price + tax_amount;
      } else {
        tax_amount = 0;
        itemsObj.amount = itemsObj.item_price;
      }
    } else if (
      this.company_details.is_composite_company == false &&
      this.company_details.is_gst_filing == true
    ) {
      tax_amount = itemsObj.tax_amount;
      itemsObj.amount = itemsObj.item_price + tax_amount;
    } else {
      itemsObj.amount = itemsObj.item_price;
    }

    itemsObj.amount = parseFloat(itemsObj.amount.toFixed(2));
    itemsObj.tax_amount = parseFloat(itemsObj.tax_amount.toFixed(2));

    this.getTotalAmount();
  }

  applyDiscountforProductItems() {
    if (this.invoice.is_discount == true) {
      this.invoice.invoice_items.forEach((itemsObj) => {
        let actual_amount = 0;
        let tax_amount = 0;
        let total_amount = 0;
        let discount_in_percentage = 0;
        actual_amount = itemsObj.units * itemsObj.price;
        if (this.company_details.is_lut) {
          this.checkForLutSez();
          if (this.is_gst_applicable == true) {
            tax_amount = (actual_amount / 100) * itemsObj.tax;
            itemsObj.amount = actual_amount + tax_amount;
          } else {
            tax_amount = 0;
            itemsObj.amount = actual_amount;
          }
        } else if (itemsObj.tax != undefined) {
          tax_amount = (actual_amount / 100) * itemsObj.tax;
          itemsObj.amount = actual_amount + tax_amount;
        }
        // total_amount = actual_amount+tax_amount;
        if (this.invoice.is_productwise_discount == true) {
          if (
            itemsObj.discount_in_percentage > 0 &&
            this.invoice.discount_in_percentage == true
          ) {
            itemsObj.discount_in_amount = 0;
            discount_in_percentage =
              (actual_amount / 100) * itemsObj.discount_in_percentage;
            itemsObj.discount = discount_in_percentage;
            // this.invoice.discount = itemsObj.discount;
            // itemsObj.amount = total_amount - discount_in_percentage;
          } else if (
            itemsObj.discount_in_amount > 0 &&
            this.invoice.discount_in_percentage == false
          ) {
            itemsObj.discount_in_percentage = 0;
            itemsObj.discount = itemsObj.discount_in_amount;
            // this.invoice.discount = itemsObj.discount;
            // itemsObj.amount = total_amount - itemsObj.discount_in_amount;
          } else {
            itemsObj.discount = 0;
          }
        }
      });
    }
  }
  // applyDiscountforOverallItems

  applyDiscountforOverallItems() {
    this.invoice.freight = 0;
    this.invoice.transport_charges = 0;

    // this.invoice.sub_total_amount = 0;
    this.invoice.balancing_items.forEach((element) => {
      if (element.account_head != null) {
        // if (
        //   element.account_head.flattened_data.name ==
        //   ConstantService.coa_heads.Freight
        // ) {
        //   this.invoice.freight = element.amount;
        // }
        if (
          element.account_head.flattened_data.name ==
          ConstantService.coa_heads.Other_Income
        ) {
          this.invoice.transport_charges = element.amount;
          // this.getTotalAmount();
          // this.invoice.sub_total_amount = this.invoice.sub_total_amount + element.amount;
          // this.invoice.grand_total_amount = this.invoice.sub_total_amount;
        }
        if (
          element.account_head.flattened_data.name ==
          ConstantService.coa_heads.Discount &&
          this.invoice.is_productwise_discount == false
        ) {
          // this.invoice.discount = 0;
          if (this.invoice.discount_in_percentage == false) {
            this.invoice.discount = element.amount;
          } else {
            this.invoice.discount = element.percentage;
          }
          // this.invoice.grand_total_amount = this.invoice.sub_total_amount - this.invoice.discount;
        }
      }
    });
  }

  checkForLutSez() {
    if (this.company_details.is_lut == true) {
	console.log(this.invoice.customer);
	console.log(this.company_details.country);
	console.log(this.invoice.customer.is_sez);
      if (this.invoice.customer && (this.company_details.country._id == this.invoice.customer.country._id)) {
        if (this.invoice.customer.is_sez == true) {
          this.is_gst_applicable = false;
        } else {
          this.is_gst_applicable = true;
        }
      } else {
        this.is_gst_applicable = false;
      }
    } else {
      this.is_gst_applicable = true;
    }
    this.invoice.is_without_gst = !this.is_gst_applicable;
  }

  // updateBalancingItems(ledger:any){
  //   if(ledger ==undefined){
  //     this.getCoaData();
  //   }
  // }

  getTotalAmount() {
    this.invoice.total_amount = 0;
    this.invoice.actual_total_amount = 0;
    this.invoice.balance_amount = 0;
    this.invoice.total_tax_percentage = 0;
    this.invoice.total_tax_amount = 0;
    this.invoice.discount = 0;
    this.invoice.sub_total_amount = 0;
    this.checkForLutSez();

    this.invoice.invoice_items.forEach((element, index) => {

      this.invoice.discount += element["discount"];
      if (element["tax_amount"] == 0) {
        element.tax_amount = (element.item_price / 100) * element.tax;
      }
      if (element["tax_amount"] > 0 && this.is_gst_applicable == true) {
        this.invoice.total_tax_amount += element["tax_amount"];
        this.invoice.total_tax_percentage += element["tax"];
      } else {
        element["tax_amount"] = 0;
        element.amount = element.item_price;
        this.invoice.total_tax_amount = 0;
        this.invoice.total_tax_percentage = 0;
        this.invoice.cgst = 0;
        this.invoice.sgst = 0;
      }
      this.invoice.actual_total_amount += element["actual_amount"];

      // Rounding off value to two decimal points
      this.invoice.total_tax_amount = parseFloat(
        this.invoice.total_tax_amount.toFixed(2)
      );
      if (this.invoice.customer != undefined) {
        if (this.company_details.state &&
          this.invoice.customer.state["zone_id"] == this.company_details.state["zone_id"] && this.invoice.customer.is_sez==false&&
          this.is_gst_applicable == true 
        ) {
          this.invoice.cgst = this.invoice.total_tax_amount / 2;
          this.invoice.sgst = this.invoice.total_tax_amount / 2;
          this.is_same_state = true;

          this.invoice.cgst = parseFloat(this.invoice.cgst.toFixed(2));
          this.invoice.sgst = parseFloat(this.invoice.sgst.toFixed(2));

          this.invoice.igst = 0;

          this.invoice.total_tax_amount = 0;
          this.invoice.total_tax_amount = this.invoice.cgst + this.invoice.sgst;

        } else if (this.is_gst_applicable == true) {
          this.invoice.igst = this.invoice.total_tax_amount;
          this.is_same_state = false;
          this.invoice.igst = parseFloat(this.invoice.igst.toFixed(2));

          this.invoice.total_tax_amount = 0;
          this.invoice.total_tax_amount = this.invoice.igst;

        }
      }
    });
    this.invoice.actual_total_amount = parseFloat(this.invoice.actual_total_amount.toFixed(2));
    this.invoice.sub_total_amount =
      this.invoice.actual_total_amount - this.invoice.discount;



    if (this.invoice.balancing_items != undefined) {
      this.invoice.balancing_items.forEach((item) => {
        this.invoice.sub_total_amount += item.amount;
      });

    }

    this.invoice.total_amount = this.invoice.sub_total_amount + this.invoice.total_tax_amount;
    this.invoice.balance_amount = this.invoice.total_amount;

    // this.invoice.sub_total_amount =
    // this.invoice.actual_total_amount + this.invoice.total_tax_amount;

    this.invoice.round_of = 0;
    this.invoice.round_value = 0;
    this.invoice.invoice_amount_wo_roundof = this.invoice.total_amount;
    this.invoice.round_value = Math.round(this.invoice.total_amount);
    this.is_round_available = true;
    this.invoice.round_of = Number((this.invoice.round_value - this.invoice.total_amount).toFixed(2));

    this.invoice.total_amount = this.invoice.total_amount + Number(this.invoice.round_of);
    // this.invoice.actual_total_amount = parseFloat(this.invoice.actual_total_amount.toFixed(2))
    // if(this.invoice.freight)
    // this.invoice.freight = parseFloat(this.invoice.freight.toFixed(2));
    // this.invoice.transport_charges = parseFloat(
    //   this.invoice.transport_charges.toFixed(2)
    // );
    this.invoice.discount = parseFloat(this.invoice.discount.toFixed(2));
    this.invoice.sub_total_amount = parseFloat(
      this.invoice.sub_total_amount.toFixed(2)
    );
    this.invoice.total_amount = parseFloat(this.invoice.total_amount.toFixed(2));
  }

  unSetProductWiseDiscount() {
    this.invoice.discount = 0;
    this.overall_discount_in_percentage = 0;

    this.invoice.invoice_items.forEach((element) => {
      if (this.invoice.is_discount === false) {
        element.is_discount = false;
        this.invoice.is_productwise_discount = false;
        this.invoice.discount_in_percentage = false;
      }
      element.discount_in_percentage = 0;
      element.discount_in_amount = 0;

      this.calculatePrice(element);
      // element.discount = 0;
      // if (this.invoice.is_productwise_discount == false) {
      //   let actual_amount = element.units * element.price;
      //   let tax_amount = (actual_amount / 100) * element.tax;
      //   if (this.invoice.discount_in_percentage == false) {
      //     element.discount_in_amount = 0;
      //   } else {
      //     element.discount_in_percentage = 0;
      //   }

      //   element.amount = actual_amount + tax_amount;
      // } else {
      //   this.invoice.balancing_items.forEach((balancingObj, key) => {
      //     if (balancingObj.account_head != null) {
      //       if (
      //         balancingObj.account_head.flattened_data.name ==
      //         ConstantService.coa_heads.Discount
      //       ) {
      //         // this.invoice.discount = 0;
      //         if (this.invoice.balancing_items.length > 1) {
      //           this.invoice.balancing_items.splice(key, true);
      //         } else {
      //           this.invoice.balancing_items.splice(key, true);
      //           this.addNewBalancingItem();
      //         }
      //       }
      //     }
      //   });
      // }
    });
  }

  getCompanyDetails() {

    this.companyService.viewCompany(this.company_id).subscribe((result) => {
      this.company_details = result["response"];
      if ((this.result1) || (this.result2 && this.result2[0] === "/debit-note/" && this.id)) {
        this.viewInvoice();
      }
      if (this.currentUrl != null) {
        if (this.currentUrl[0] === "/sales-invoice/" && this.id != null) {
          this.viewSalesOrder();
        }
      }

      this.salesordernumberlength = this.company_details['sales_column_settings']['invoice_ordernumber_length'];

      // this.invoiceprefix = result["response"]["sales_column_settings"]["invoice_order_prefix"];
      // this.invoicesuffix = result["response"]["sales_column_settings"]["invoice_order_suffix"];
      // console.log(this.salesordernumberlength)
      this.voucher_type_lists = this.company_details.sales_column_settings.voucher;

      this.invoice.voucher_type = this.voucher_type_lists[0];
      // if(this.invoice.voucher_type != null && this.invoice.voucher_type != undefined) {
      //   console.log('JJJJJAAAACCCCCKKKKK', this.invoice.voucher_type);
      //   this.updateVoucher(this.invoice.voucher_type);
      // }
      if (this.company_details["sales_column_settings"]["items"] != "") {
        // this.myOptions.dateFormat = this.company_details.date_format;
        this.invoiceDateOptions.dateFormat = this.company_details.date_format;
        this.dueDateOptions.dateFormat = this.company_details.date_format;
        this.salesOrderdateOptions.dateFormat = this.company_details.date_format;
        let book_beginning_date = new Date(this.company_details.book_beginning);
        book_beginning_date.setDate(book_beginning_date.getDate() - 1);
        const currentDueDateOptions = this.getCopyOfOptions();
        currentDueDateOptions.disableUntil = {
          year: book_beginning_date.getFullYear(),
          month: book_beginning_date.getMonth() + 1,
          day: book_beginning_date.getDate(),
        };
        this.dueDateOptions = currentDueDateOptions;
        const currentInvoiceDtOptions = this.getCopyOfOptions();
        // currentInvoiceDtOptions.disableUntil = {
        //   year: book_beginning_date.getFullYear(),
        //   month: book_beginning_date.getMonth() + 1,
        //   day: book_beginning_date.getDate(),
        // };
        currentInvoiceDtOptions.disableUntil = {year:this.selectFiYear.start_year, month:3, day:31};
        currentInvoiceDtOptions.disableSince = {year:this.selectFiYear.end_year, month:4, day:1};
        if (this.is_debit_note == false) {
          this.invoice.invoice_date = {
            isRange: false,
            singleDate: {
              jsDate: new Date(),
            },
          };
        }
        this.invoiceDateOptions = currentInvoiceDtOptions;
        const currentSalesOrderDtOptions = this.getCopyOfOptions();
        currentSalesOrderDtOptions.disableUntil = {
          year: book_beginning_date.getFullYear(),
          month: book_beginning_date.getMonth() + 1,
          day: book_beginning_date.getDate(),
        };
        this.invoiceDateOptions = currentInvoiceDtOptions;
        this.salesOrderdateOptions = currentSalesOrderDtOptions;

        if (this.company_details.is_composite_company != undefined) {
          this.is_composite_company = this.company_details.is_composite_company;
        } else {
          this.is_composite_company = false;
        }
      } else {
        swal(
          "Update Settings!",
          "Update Invoice Settings In Company And Then Add SO",
          "warning"
        );
        this.router.navigateByUrl("/company/edit/" + this.company_id);
      }
    });
  }

  getCopyOfOptions(): IAngularMyDpOptions {
    return JSON.parse(JSON.stringify(this.invoiceDateOptions));
    return JSON.parse(JSON.stringify(this.dueDateOptions));
  }

  deleteItem(index) {
    // this.original_salesorderitems[index]["is_invoiced"] = 3;
    // if (index != 0) {
    if (this.invoice.invoice_items.length > 1) {
      this.invoice.invoice_items.splice(index, true);
      //    this.unSetProductWiseDiscount();
      this.invoice.invoice_items.forEach((element) => {
        this.calculatePrice(element);
      });
    }
  }

  addNewItem() {
    this.invoice.invoice_items.push({
      product: null,
      description: "",
      units: 1,
      price: 0,
      amount: 0,
      tax_amount: 0,
      discount: 0,
      discount_in_percentage: 0,
      discount_in_amount: 0,
    });
  }

  addNewBalancingItem() {
    this.invoice.balancing_items.push({
      account_head: null,
      amount: 0,
      percentage: 0,
    });
  }

  deleteBalancingItem(index) {
    if (index != 0) {
      this.invoice.balancing_items.splice(index, true);
      //   this.unSetProductWiseDiscount();
      this.getTotalAmount();
    }
  }

  getProducts(searchString) {
    this.product_lists = [];
    const columns = {
      name: 1,
      // "sales": 1,
      is_purchase: 1,
      is_sell: 1,
      type: 1,
      sku: 1,
      unit: 1,
      code: 1,
      hsn_muid: 1,
      group_id: 1,
      group_name: 1,
    };
    const condition: any = {};
    if (searchString != "") {
      condition["name"] = searchString;
    }
    condition["company_id"] = this.company_id;
    condition["is_sell"] = true;
    return this.productService.getAllProducts(condition, "all", columns);
  }

  getProductSearch() {
    this.productListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getProducts(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.product_lists = result;
        },
        (err) => {
          console.log(err);
          this.product_lists = [];
        }
      );
  }
  getSalesOrderListSearch() {
    this.salesOrderListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getSalesOrderByCustomer(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.salesOrderList = result["response"]["data"];
        },
        (err) => {
          console.log(err);
          this.salesOrderList = [];
        }
      );
  }

  getTaxAmount(itemsObj) {
    itemsObj.tax_amount = 0;
    if (this.company_details.is_gst_filing == true && this.is_gst_applicable == true) {
      if (itemsObj.tax > 0) {
        let tax_amount: any;

        tax_amount = (itemsObj.item_price / 100) * itemsObj.tax;
        itemsObj.tax_amount = tax_amount;
      }
    }
    this.getAmount(itemsObj);
  }

  getPriceFromHSN(product_data, itemsObj) {
    this.product_type = product_data.type;
    if (product_data != null && this.is_composite_company == false) {
      const condition: any = {};
      const sort: any = {};
      sort["effects_from"] = "desc";
      condition["_id"] = product_data["hsn_muid"];
      condition["effects_from_is_active"] = true;
      let data = {};

      this.hsnService.getHSNByCondition(condition, sort).subscribe((result) => {
        if (result["response"][0] != undefined) {
          result["response"][0]["effects_from"].filter((e) => {
            if (e.is_active) {
              data = e;
              itemsObj["tax"] = e.igst;
              itemsObj["sgst"] = e.sgst;
              itemsObj["cgst"] = e.cgst;
              itemsObj["igst"] = e.igst;
              this.calculatePrice(itemsObj);
            }
          });
        } else {
          itemsObj["tax"] = 0;
        }
      });
    } else {
      // this.calculatePrice(itemsObj);
      itemsObj["tax"] = 0;
    }
  }

  viewInvoice() {
    // const condition: any = {};
    // condition["company_id"] = this.company_id;
    this.invoiceService.viewInvoice(this.id).subscribe((result) => {
      this.currentInvoice = result["response"];
      this.invoice = result["response"];
      this.invoice.voucher_type = this.invoice.voucher_object;
      console.log('this.invoice====111===>>>', this.invoice);
      if (this.is_debit_note == false) {
        this.invoice.invoice_sequence_number = result["response"]["invoice_number"];
        this.invoiceAccountConfig.accountsHeads = [result["response"]['ledger']['id']];
        this.invoiceAccountConfig.is_required = true;
        this.invoiceAccountConfig.is_set = true;

        this.invoice.invoice_items.forEach((element) => {
          this.calculatePrice(element);
        });
        this.invoice_dates_array.forEach((element) => {
          if (this.invoice[element]) {
            this.invoice[element] = {
              isRange: false,
              singleDate: {
                jsDate: new Date(this.invoice[element]),
              },
            };
          }
        });
      } else {
        this.invoice.invoice_number = result["response"]["invoice_number"];
        console.log('this.invoice.invoice_number1', this.invoice.invoice_number);

        this.invoice.invoice_items.forEach((element) => {
          if (element["returned_qty"]) {
            element.units = element.units - element["returned_qty"];
          }
          element["max_units"] = element.units;
          this.calculatePrice(element);
        });
      }
    });
  }

  viewSalesOrder() {
    const condition: any = {};
    this.invoice.is_discount = false;
    this.invoice.is_productwise_discount = false;
    this.invoice.discount_in_percentage = false;

    condition["company_id"] = this.company_id;
    this.salesorderService
      .viewSalesorder(this.id, condition)
      .subscribe((result) => {
        this.invoice.is_discount = result["response"]["is_discount"];
        this.invoice.is_productwise_discount =
          result["response"]["is_productwise_discount"];
        this.invoice.discount_in_percentage =
          result["response"]["discount_in_percentage"];

        result["response"]["sales_order_items"].forEach((val) =>
          this.original_salesorderitems.push(Object.assign({}, val))
        );
        if (
          result["response"]["is_discount"] == true &&
          result["response"]["is_productwise_discount"] == false &&
          result["response"]["discount_in_percentage"] == true
        ) {
          this.overall_discount_in_percentage =
            result["response"]["overall_discount_in_percentage"];
        }
        this.invoice = result["response"];
        this.getNextSequence();

        this.invoice.ledger = new Ledger();
        this.invoice.billing_address =
          result["response"]["customer"]["billing_address"];
        this.invoice.shipping_address =
          result["response"]["customer"]["shipping_address"];
        this.invoice.invoice_items = this.getInvoiceItems(
          result["response"]["sales_order_items"]
        );
        console.log('1111112222', this.invoice.customer.state);
        if (this.invoice) {
          this.invoice.balancing_items = [];
          this.addNewBalancingItem();
          this.invoice.invoice_items.forEach((element) => {
            this.calculatePrice(element);
          });
        }
        if (this.is_sales_invoice == false) {
          this.invoice.sales_order_date = {
            isRange: false,
            singleDate: {
              jsDate: new Date(result["response"]["sales_order_date"]),
            },
          };
        }
        this.invoice.due_date = {
          isRange: false,
          singleDate: {
            jsDate: addDays(new Date(), this.invoice.customer.payment_term),
          },
        };
        this.invoice.invoice_date = {
          isRange: false,
          singleDate: {
            jsDate: new Date(),
          },
        };

        // if (this.invoice) {
        //   console.log('1111112222');
        //   this.invoice.balancing_items = [];
        //   this.addNewBalancingItem();
        //   this.invoice.invoice_items.forEach((element) => {
        //     this.calculatePrice(element);
        //   });
        // }
      });
  }
  changePos() {
    if (this.is_pos) {
      this.is_pos = false;
    } else {
      this.is_pos = true;
    }
  }
  getInvoiceItems(array_items) {
    const invoiceItems = [];
    array_items.filter(function (e) {
      if (e.is_invoiced == 3 || e.is_invoiced == 1) {
        invoiceItems.push(e);
      }
    });

    return invoiceItems;
  }
  updateVoucher(voucher: any) {
    console.log("voucher", voucher);

    this.invoice.voucher_name = "";
    if (this.header == "Invoice Edit") {
      if (voucher != null) {
        this.invoice.voucher_object = voucher;

        this.invoice.invoice_number = this.invoice.sequence_number;
        console.log('this.invoice.invoice_number2', this.invoice.invoice_number);

        this.invoice.invoice_sequence_number = voucher.voucher_type + this.invoice.invoice_number;
      }
    } else {
      if (voucher.voucher_type == "default") {
        this.getNextSequence();
      } else {
        this.invoice.invoice_sequence_number = "";
        this.invoice.voucher_object = {};
        this.invoice.invoice_number = "";
        if (voucher != null) {
          this.invoice.invoice_sequence_number = voucher.voucher_type + this.invoicenumber;
          this.invoice.invoice_number = voucher.voucher_type + this.invoicenumber;
          console.log("this.invoice.invoice_number3", this.invoice.invoice_number);

          this.invoice.voucher_object = voucher;
          this.invoice.voucher_name = voucher.voucher_type;
        }
      }

    }

  }

  updateLedger(ledger: any) {
    if (ledger != null) {
      this.invoice["ledger"]["name"] = ledger.flattened_data.name;
      this.invoice["ledger"]["id"] = ledger.flattened_data.id;
      this.invoice["ledger"]["ref_id"] = ledger.flattened_data["_id"];
      this.invoice["ledger"]["parent_id"] = ledger.flattened_data.parent_id;
      this.invoice["ledger"]["parent_name"] = ledger.flattened_data.parent_name;
    } else {
      this.invoiceAccountConfig.accountsHeads = [4012, 4013];
      if (this.user_id != 8) {
        this.invoiceAccountConfig.is_required = true;
      } else {
        this.invoiceAccountConfig.is_required = false;
      }

    }
  }
  getNextSequence() {
    let invoice_sequence: any = {};
    invoice_sequence["seq_name"] = "invoice";
    invoice_sequence["company_id"] = this.company_id;
    this.sequenceService.viewSequence(invoice_sequence).subscribe((result) => {

      if (result["response"] != "" && result["response"] != null) {
        if (result["response"]["seq_no"]) {
          this.invoicenumber = result["response"]["seq_no"]
            .toString()
            .padStart(
              this.salesordernumberlength,
              0
            );
          console.log("this.invoicenumberseq", this.invoicenumber);

          // let invoice_order_prefix = result["response"]["prefix"];
          // // +
          // let invoice_order_suffix = result["response"]["suffix"];

          // this.invoice.invoice_sequence_number = invoice_order_prefix + this.invoicenumber + invoice_order_suffix;
          this.invoice.invoice_sequence_number = result["response"]["prefix"] + this.invoicenumber + result["response"]["suffix"];

          if (this.invoice.voucher_type != undefined && this.invoice.voucher_type != "") {
            if (this.invoice.voucher_type.voucher_type != undefined && this.invoice.voucher_type.voucher_type != "" && this.invoice.voucher_type.voucher_type != "default") {
              this.updateVoucher(this.invoice.voucher_type);
            } else {
              this.invoice.invoice_number = this.invoice.invoice_sequence_number;
              console.log("invoice.invoice_number4", this.invoice.invoice_number);

            }
          } else {
            this.invoice.invoice_number = this.invoice.invoice_sequence_number;
            console.log("invoice.invoice_number5", this.invoice.invoice_number);

          }


          this.sequence_no = result["response"]["seq_no"];
          this.sequence_id = result["response"]["_id"];
        } else {
          swal(
            "Update Settings!",
            "Update Sales Order Settings In Company And Then Add SO",
            "warning"
          );
          this.router.navigateByUrl("/company/edit/" + this.company_id);
        }
      } else {
        swal(
          "Update Settings!",
          "Update Sales Order Settings In Company And Then Add SO",
          "warning"
        );
        this.router.navigateByUrl("/company/edit/" + this.company_id);
      }
    });
  }

  getSalesOrderByCustomer(searchString: string) {
    const condition: any = {};
    condition["company_id"] = this.company_id;
    if (this.invoice.customer != null) {
      condition["customer_muid"] = this.invoice.customer._id;
      condition["sales_status"] = 1;
    }
    condition["so_number_search_string"] = searchString;
    return this.salesorderService.getSalesorders(
      condition,
      {},
      {},
      { offset: 0, limit: 10 }
    );
  }

  // setInvoiceDetails() {
  //   this.invoice.invoice_items = this.selected_sales_order.sales_order_items;
  // }
  redirectToSalesInvoiceOrder() {
    const selectedSalesOrder = this.salesOrderList.filter(
      (sales_order) =>
        sales_order.sales_order_number == this.invoice.sales_order_number
    )[0];
    if (selectedSalesOrder != null) {
      this.router.navigateByUrl(
        "/invoice/sales-invoice/" + selectedSalesOrder["_id"]
      );
    }
  }

  setInvoiceAccountHeaderConfig() {
    this.invoiceAccountConfig.accountsHeads = [4012, 4013];
    if (this.user_id != 8) {
      this.invoiceAccountConfig.is_required = true;
    } else {
      this.invoiceAccountConfig.is_required = false;
    }

  }

  getAddressList() {
    this.customerService
      .viewCustomer({ muid: this.customer._id })
      .subscribe((result) => {
        this.contactAddressList = result["response"]["contact_address"];
      });
  }

  updateAddress(addressInfo: AddressTemplateEmitConfig, dropdownEvent: any) {
    if (addressInfo.address_type == "billing_address") {
      this.billingAddressTemplateConfig = new AddressTemplate();
      this.billingAddressTemplateConfig.contact_address =
        addressInfo.contact_address;
      this.billingAddressTemplateConfig.action_type = "view";
      this.billingAddressTemplateConfig.user_role = "customer";
      this.billingAddressTemplateConfig.user_muid = addressInfo.user_muid;
    } else if (addressInfo.address_type == "shipping_address") {
      this.shippingAddressTemplateConfig = new AddressTemplate();
      this.shippingAddressTemplateConfig.contact_address =
        addressInfo.contact_address;
      this.shippingAddressTemplateConfig.action_type = "view";
      this.shippingAddressTemplateConfig.user_role = "customer";
      this.shippingAddressTemplateConfig.user_muid = addressInfo.user_muid;
    }
  }

  onChangeCustomer() {

    this.setBillShipAddress();
    this.setCurrency(this.invoice.customer.currency);
    this.getTotalAmount();
    this.getSalesOrderByCustomer("").subscribe((result: Salesorder) => {
      this.salesOrderList = result["response"]["data"];
    });

    if (this.invoice.invoice_date != null) {
      console.log("this.invoice.invoice_date===>", this.invoice.invoice_date);
      this.onDateChanged(this.invoice.invoice_date);
    } else {
      this.invoice.due_date = {
        isRange: false,
        singleDate: {
          jsDate: addDays(new Date(), this.invoice.customer.payment_term),
        },
      };
    }
  }

  calculatePrice(itemsObj) {
    let item_price = 0;
    itemsObj.actual_amount = 0;
    itemsObj.amount = 0;
    itemsObj.discount = 0;

    item_price = itemsObj.units * itemsObj.price;
    itemsObj.actual_amount = itemsObj.units * itemsObj.price;
    if (
      this.invoice.discount_in_percentage ||
      this.invoice.is_productwise_discount
    ) {
      if (this.invoice.discount_in_percentage) {
        itemsObj.item_price =
          item_price - item_price * (itemsObj.discount_in_percentage / 100);
        itemsObj.discount =
          item_price * (itemsObj.discount_in_percentage / 100);
      } else {
        itemsObj.item_price = item_price - itemsObj.discount_in_amount;
        itemsObj.discount = itemsObj.discount_in_amount;
      }
    } else {
      itemsObj.item_price = item_price;
    }
    this.getTaxAmount(itemsObj);
  }
  calculateDisount() {
    this.invoice.invoice_items.forEach((element) => {
      element.discount_in_percentage = this.overall_discount_in_percentage;
      this.calculatePrice(element);
    });
  }

}
