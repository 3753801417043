<button class="btn btn-primary" (click)="getDashboardWidgets()"> <i class="fa fa-refresh"></i></button>
<div class="row clearfixm pt-3">
    <div class="col-6 col-md-4 col-xl-3">
        <div class="card" [ngbPopover]="cashBankBalSplitPopOver" triggers="mouseenter:mouseleave"
            popoverTitle="Cash & Bank">
            <div class="card-body ribbon ">
                <!-- <div class="ribbon-box green">5</div> -->
                <a class="my_sort_cut text-muted">
                    <span style="font-size: 18px;
                                color: #22abfa;">Total Balance</span>
                    <span style="color: #000;
                                font-size: 22px;
                                font-weight: bold;
                                padding-bottom: 10px;">{{widgetLabels['labels_1']['overall_balance']| currency:
                        'INR'}}</span>
                    <!-- <span class="text-small w-15 w-xs-100" style="font-size: 11px;text-align: left;">Cash In
                                Hand {{widgetLabels['labels_1']['cash_balance']| currency: 'INR'}}</span>
                            <span style="font-size: 11px;text-align: left;">Bank Balance
                                {{widgetLabels['labels_1']['bank_balance']| currency: 'INR'}}</span> -->
                </a>
            </div>
        </div>
    </div>
    <div class="col-6 col-md-4 col-xl-3">
        <div class="card" [ngbPopover]="expensePopOverTmpl" popoverTitle="Top 5 Expenses"
            triggers="mouseenter:mouseleave">
            <div class="card-body">
                <a class="my_sort_cut text-muted">
                    <!-- <i class="icon-like"></i> -->
                    <span style="font-size: 18px;
                                color: #dc3545;">Expenses</span>
                    <span style="color: #000;
                                font-size: 22px;
                                font-weight: bold;
                                padding-bottom: 10px;">{{widgetLabels['labels_2']| currency: 'INR'}}</span>
                </a>
            </div>
        </div>
    </div>
    <div class="col-6 col-md-4 col-xl-3">
        <div class="card" triggers="mouseenter:mouseleave" [ngbPopover]="receivablePopOverTmpl"
            popoverTitle="Top 5 Receivables">
            <div class="card-body ribbon">
                <!-- <div class="ribbon-box orange">8</div> -->
                <a class="my_sort_cut text-muted">
                    <!-- <i class="icon-calendar"></i> -->

                    <span style="font-size: 18px;
                            color: #02bdae;">Receivables</span>
                    <span style="color: #000;
                            font-size: 22px;
                            font-weight: bold;
                            padding-bottom: 10px;">{{widgetLabels['labels_3']| currency: 'INR'}}</span>
                </a>
            </div>
        </div>
    </div>
    <div class="col-6 col-md-4 col-xl-3">
        <div class="card" triggers="mouseenter:mouseleave" [ngbPopover]="payablePopOverTmpl"
            popoverTitle="Top 5 Payables">
            <div class="card-body">
                <a class="my_sort_cut text-muted">
                    <!-- <i class="icon-credit-card"></i> -->
                    <span style="font-size: 18px;
                            color: #e75008;">Payables</span>
                    <span style="color: #000;
                            font-size: 22px;
                            font-weight: bold;
                            padding-bottom: 10px;"> {{widgetLabels['labels_4']| currency: 'INR'}}</span>
                </a>
            </div>
        </div>
    </div>

</div>






<div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Invoice Status</h3>
            </div>
            <div class="card-body">
                <div class="progress">
                    <div *ngFor="let report of invoiceProgress['data']"
                        class="progress-bar progress-bar-striped progress-bar-animated"
                        ngbPopover="{{report.amount |currency: 'INR'}}" triggers="mouseenter:mouseleave"
                        popoverTitle="{{report.invoice_status_name}}"
                        [ngClass]="{'bg-warning':report.invoice_status== 2, 'bg-success': report.invoice_status==3, 'bg-danger': report.invoice_status==1}"
                        role="progressbar" [style.width.%]="report.percentage">
                        {{report.invoice_status_name}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="progress-bar bg-warning" role="progressbar" style=" width:95.36541889483065%">
        Warning
    </div>
    <div class="progress-bar bg-danger" role="progressbar" style="width:2%">
        Danger
    </div> -->







<div class="row">


    <div class="col-xl-6 col-lg-12 col-md-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Expenses</h3>
            </div>

            <div class="card-body" *ngIf="expenseReportData.length>0">
                <datalytics-charts [data]="expenseReportData" [chartAxis]="expenseReportChartConfig"
                    [chartOptions]="expenseReportOptions"></datalytics-charts>
            </div>
        </div>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Income</h3>
            </div>

            <div class="card-body" *ngIf="incomeReportData.length>0">
                <datalytics-charts [data]="incomeReportData" [chartAxis]="incomeReportChartConfig"
                    [chartOptions]="incomeReportOptions"></datalytics-charts>
            </div>
        </div>
    </div>
    <div class="col-xl-12 col-lg-12 col-md-12">
        <div class="card">
            <div class="card-header">
                <h3 class="card-title">Income Vs Expenses</h3>
            </div>

            <div class="card-body" *ngIf="incomeVsExpenseData?.length>0">
                <datalytics-charts [data]="incomeVsExpenseData" [chartAxis]="incomeVsExpenseChartConfig"
                    [chartOptions]="incomeVsExpenseChartOptions"></datalytics-charts>
            </div>
        </div>
    </div>
    <!-- <div class="col-6">
        <datalytics-charts [data]="cashAndBankBalanceData" [chartAxis]="cashAndBankBalanceChartConfig"
            [chartOptions]="cashAndBankBalanceChartOptions"></datalytics-charts>
    </div>
    <div class="col-6">
        <datalytics-charts [data]="outstandingReceivablesAndPayables"
            [chartAxis]="tradePayablesAndReceivalesChartConfig"
            [chartOptions]="outstandingReceivablesAndPayablesOptions"></datalytics-charts>
    </div> -->
</div>

<ng-template #cashBankBalSplitPopOver>
    <table class="table">
        <tr>
            <td>
                Bank - <b> {{widgetLabels['labels_1']['bank_balance']| currency: 'INR'}}</b>
            </td>
        </tr>
        <tr>
            <td>
                Cash - <b>{{widgetLabels['labels_1']['cash_balance']| currency: 'INR'}}</b>
            <td>
        </tr>
    </table>
</ng-template>
<ng-template #expensePopOverTmpl>
    <table class="table">
        <tr *ngFor="let expense of expenseReportData.slice(0, 4)">
            <td> {{expense.expense_by}} - <b> {{expense.expense_amount| currency: 'INR'}}</b> </td>
        </tr>
    </table>
</ng-template>

<ng-template #receivablePopOverTmpl>
    <table class="table">
        <tr *ngFor="let receivable of receivableReportData.slice(0, 4)">
            <td> {{receivable.receivable_name}} - <b> {{receivable.receivable| currency: 'INR'}}</b> </td>
        </tr>
    </table>
</ng-template>
<ng-template #payablePopOverTmpl>
    <table class="table">
        <tr *ngFor="let payable of payableReportData.slice(0, 4)">
            <td> {{payable.payable_name}} - <b> {{payable.payable_amount| currency: 'INR'}}</b> </td>
        </tr>
    </table>
</ng-template>