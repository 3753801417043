import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import swal from "sweetalert2";
import { BillService } from "../../../_services/bill.service";
import { PurchaseorderService } from "../../../_services/purchaseorder.service";
import { ProductService } from "../../../_services/product.service";
import { VendorService } from "../../../_services/vendor.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { Bill, Ledger } from "../../../_models/bill.model";
// import { PurchaseOrderItem } from '../../../_models/bill-items';
import { MastersService } from "../../../_services/masters.service";
import { CompanyService } from "../../../_services/company.service";
import { AuthenticationService } from "../../../_services/authentication.service";
import { SequenceService } from "../../../_services/sequence.service";
import { HsnService } from "../../../_services/hsn.service";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { ConstantService } from "src/app/_config/constants";
import add from "date-fns/add";
import { ExpenseService } from "src/app/_services/expense.service";
import { AccountsHeadTemplate } from "../../../_models/accounts-head-template.model";
import { BasicCutOff, Tds, TDSPeriod } from "../../../_models/tds";
import {
  NgbPopoverConfig,
  NgbModal,
  ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import { VoucherTypeService } from "../../../_services/voucher-type.service";
import { DebitBillService } from '../../../_services/debit-bill.service';
import { addDays, differenceInDays } from "date-fns";

@Component({
  selector: "app-bill-add",
  templateUrl: "./bill-add.component.html",
  styleUrls: ["./bill-add.component.scss"],
  providers: [NgbPopoverConfig],
})
export class BillAddComponent implements OnInit {
  loading = false;
  due_date: any;
  vendor: any;
  bill_date: any;
  dueDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };
  billDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  billPurchaseDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    disableSince: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };
  myDateInit: boolean = true;
  model: IMyDateModel = null;
  id: string;
  is_edit_form: boolean = false;
  is_same_state: boolean = true;
  is_expense_debitnote: boolean = false;
  bill: Bill = new Bill();
  company_id: string;
  currency_list: any;
  selectFiYear:any;
  company_details: any = {};
  // purchaseOrderItem: PurchaseOrderItem = new PurchaseOrderItem();
  vendor_lists: Object;
  purchaseorder_lists: any = [];
  vendorListTypeahead = new EventEmitter<string>();
  purchaseOrderListTypeahead = new EventEmitter<string>();
  sequence_no: any;
  sequence_id: any;
  product_lists: any;
  productListTypeahead = new EventEmitter<string>();
  accountHeadListTypeahead = new EventEmitter<string>();
  account_head_lists: any;
  bill_dates_array: any = ["billing_date", "purchase_order_date", "due_date", "debit_date"];
  account_head_list: any = [{ name: "Sundry Debitors", type: 1 }];
  total_amount: any;
  is_credit_note: boolean = false;
  is_credit_note_add: boolean = false;
  is_bill_invoice: boolean = false;
  header: string;
  branch_id: string;
  files: File[] = [];
  all_account_heads: any;
  addForm: boolean = false;
  currency: string;
  original_purchaseorderitems: any = [];
  bill_array: any;
  bill_label_name: string;
  is_debit_note: boolean = false;
  is_expense: boolean = false;
  is_expenses: boolean = false;
  billAddAccountConfig: AccountsHeadTemplate = new AccountsHeadTemplate();
  tds: Tds = new Tds();
  closed = false;
  is_igst: boolean;
  voucherTypeList: any = [];
  closeResult: string;
  voucherTypeTypeahead = new EventEmitter<string>();
  voucher_type_lists: any;
  imageFiles: any;
  image_path: string;
  labelName: string;
  editForm: string = "";
  user_id: any;
  CompanyDetails: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private billservice: BillService,
    private purchaseorderService: PurchaseorderService,
    private mastersService: MastersService,
    private companyService: CompanyService,
    private sequenceService: SequenceService,
    private authenticationService: AuthenticationService,
    private productService: ProductService,
    private hsnService: HsnService,
    private vendorService: VendorService,
    private cd: ChangeDetectorRef,
    private expenseservice: ExpenseService,
    private billService: BillService,
    public config: NgbPopoverConfig,
    public voucherTypeService: VoucherTypeService,
    private modalService: NgbModal,
    private creditBillService: DebitBillService,
  ) {
    config.triggers = "hover";
  }

  ngOnInit() {
    this.bill.vendor = null;
    this.bill.is_without_gst = false;
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.CompanyDetails = this.authenticationService.getCompanyDetails();
    this.currency = this.company_details.base_currency;

    this.currency = this.authenticationService.getCompanyDetails()[
      "base_currency"
    ];
    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];
    this.getCompanyDetails();
    this.getVendors("").subscribe((result) => {
      this.vendor_lists = result["response"]["data"];
    });
    this.getVendorsSearch();
    // this.getpurchase_Order("").subscribe((result) => {
    //   this.purchaseorder_lists = result["response"]["data"];
    // });
    // this.getPurchaseOrderSearch();
    this.addNewItem();

    // this.getNextSequence();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get("id");
      let today = differenceInDays(new Date(this.selectFiYear.end_year, 2, 31), new Date()) > 0 && differenceInDays(new Date(), new Date(this.selectFiYear.start_year, 3, 1)) > 0 ? new Date() : new Date(this.selectFiYear.end_year, 2, 31);
      var result1 = this.router.url.match("/bill/edit/");
      var result2 = this.router.url.match("credit-note");
      var result3 = this.router.url.match("/purchase_order/");
      var result4 = this.router.url.match("/expense/1");
      var result7 = this.router.url.match("/bill_expense/image/");

      var result5 = this.router.url.match("/image/");
      var result6 = this.router.url.match("/bill_expense/edit/");
      var result7 = this.router.url.match("/debit-bill/edit/");
      var result8 = this.router.url.match("/debitnote/add");
      var result9 = this.router.url.match("/expense-debitnote/");

      if (result1 != null || result2 != null || result3 != null) {
        this.bill_label_name = "Bill";
      }

      if (result1 != null) {
        if (result1[0] === "/bill/edit/" && this.id != null) {
          this.is_edit_form = true;
          this.getBill();
          this.editForm = "bill";

          this.header = "Invoice Edit";
          this.labelName = "Purchase Invoice";

        }
      } else if (result2 != null) {
        if (result2[0] === "credit-note") {
          if (this.id != null) {
            this.getBill();
            this.is_credit_note = true;
          }

          this.header = "Debit Note";
          this.labelName = "Purchase Invoice";

        }
      } else if (result7 != null) {
        if (result7[0] === "/debit-bill/edit/" && this.id != null) {
          // this.is_edit_form = true;
          this.is_credit_note_add = true;

          this.getDebitNote();
          this.header = "Debit Note Edit";
          this.labelName = "Debit Note";

        }
      } else if (result8 != null) {
        if (result8[0] === "/debitnote/add") {
          // this.is_edit_form = true;
          this.is_credit_note_add = true;

          // this.getDebitNote();
          // this.bill.balancing_items = [];
          this.header = "Add Debit Note";
          this.labelName = "Debit Note";
          this.bill.debit_date={
            isRange: false,
            singleDate: {
              jsDate: today,
            },
          }

        }
      } else if (result3 != null) {
        this.addForm = true;
        if (result3[0] === "/purchase_order/" && this.id != null) {
          this.getPurchaseorder();
          this.is_bill_invoice = true;
          this.header = "Invoice Entry";
          this.labelName = "Purchase Invoice";

          // this.bill.billing_date = {
          //   isRange: false,
          //   singleDate: {
          //     jsDate: today
          //   }
          // };
        }
      } else if (result4 != null) {
        this.bill_label_name = "Expense";

        if (this.id != '1') {
          this.getImageFromFileupload(this.id);
        }
        this.addForm = true;
        if (result4[0] === "/expense/1" && this.id != null) {
          this.is_expense = true;
          // this.getPurchaseorder();
          //this.is_bill_invoice = true;
          this.header = "Expense Invoice";
          this.labelName = "Expense";

          this.bill.billing_date = {
            isRange: false,
            singleDate: {
              jsDate: today
            }
          };
        }
      }
      else if (result7 != null) {
        this.is_expense = true;

        this.getImageFromFileupload(this.id);

        this.addForm = true;
        this.bill_label_name = "Expense";
        this.header = "Expense Invoice";
        this.labelName = "Expense Invoice";

        this.bill_dates_array.forEach((date_name) => {
          this.bill[date_name] = {
            isRange: false,
            singleDate: {
              jsDate: today,
            },
          };
        });
        this.bill.balancing_items = [];
      }

      else if (result5 != null) {
        this.getImageFromFileupload(this.id);

        this.addForm = true;
        this.bill_label_name = "Bill";
        this.header = "Invoice Entry";
        this.labelName = "Purchase Invoice";

        this.bill_dates_array.forEach((date_name) => {
          this.bill[date_name] = {
            isRange: false,
            singleDate: {
              jsDate: today,
            },
          };
        });
        this.bill.balancing_items = [];
      }
      else if (result6 != null) {
        this.header = "Expense Invoice Edit";
        this.labelName = "Expense";
        this.is_edit_form = true;
        this.editForm = "expense";

        this.getExpense(this.id);
      }
      else if (result9 != null && result9[0] == "/expense-debitnote/") {
        this.header = "Expense DebitNote";
        this.labelName = "Expense";
        this.is_credit_note = true;
        this.is_expense_debitnote = true;
        this.getExpense(this.id);
      }
      // else if (result7 != null) {
      //   this.header = "Add Debit Note";
      //   this.labelName = "Debit";
      //   this.is_credit_note = true;
      //   // this.is_edit_form = true;
      //   // this.editForm = "expense";

      //   // this.getExpense(this.id);
      // }
      else {
        this.addForm = true;
        this.bill_label_name = "Bill";
        this.header = "Invoice Entry";
        this.labelName = "Purchase Invoice";

        this.bill_dates_array.forEach((date_name) => {
          if (date_name != "debit_date" && date_name != "due_date") {
            this.bill[date_name] = {
              isRange: false,
              singleDate: {
                jsDate: today,
              },
            };
          }
        });
        this.bill.balancing_items = [];
      }
      this.getProducts("").subscribe((result) => {
        this.product_lists = result;
      });
      this.getProductSearch();

    });
    this.getCoaData();
    this.getCoaHeadSearch();
    this.getCoaHead("","").subscribe((result) => {
      this.account_head_lists = result['response'];

    });
    this.addNewBalancingItem();
    this.setBillAccountHeaderConfig();
    // this.getVoucherType();

    this.getVoucherTypeSearch();
  }


  getDebitNote() {
    // let total_amount = 0;
    let condition: any = {};
    condition['comapny_id'] = this.company_id;
    this.creditBillService
      .viewDebitBill(this.id, condition)
      .subscribe((result: Bill) => {
        this.bill = result;
        this.getTotalAmount();

        this.bill.debit_date = result["debit_date"]
        this.bill.credit_number = result["debit_number"]

        let currentAccountHeads: any = [];
        currentAccountHeads.push(result["ledger"]["id"]);
        this.billAddAccountConfig.accountsHeads = currentAccountHeads;

        this.billAddAccountConfig.is_required = true;
        this.billAddAccountConfig.field_name = result["ledger"]["name"];
        this.billAddAccountConfig.label_name = result["ledger"]["name"];
        this.billAddAccountConfig.is_set = true;

        this.bill.debit_date = {
          isRange: false,
          singleDate: {
            jsDate: new Date(this.bill.debit_date),
          },
        };

        // this.getImageFromFileupload(this.bill['image_id']);

        // this.bill.sub_total_amount = result["response"]["sub_total_amount"];

        // let currentAccountHeads: any = [];
        // currentAccountHeads.push(result["response"]["ledger"]["id"]);
        // this.billAddAccountConfig.accountsHeads = currentAccountHeads;

        // this.billAddAccountConfig.is_required = true;
        // this.billAddAccountConfig.field_name = result["response"]["ledger"]["name"];
        // this.billAddAccountConfig.label_name = result["response"]["ledger"]["name"];
        // this.billAddAccountConfig.is_set = true;

        // if (this.is_edit_form && this.is_credit_note == false) {
        //   if (
        //     this.bill.vendor.state["zone_id"] ==
        //     this.company_details.state["zone_id"]
        //   ) {
        //     this.is_same_state = true;
        //   }
        //   this.bill_dates_array.forEach((date_name) => {
        //     if (this.bill[date_name]) {
        //       this.bill[date_name] = {
        //         isRange: false,
        //         singleDate: {
        //           jsDate: new Date(this.bill[date_name]),
        //         },
        //       };
        //     }
        //   });
        // }



      });
  }

  //   this.getBillsList();
  getVoucherType_Search(search_string) {
    this.voucherTypeList = [];
    let condition: any = {};
    let columns: any = {};

    condition["category_code"] = 2;
    return this.voucherTypeService.getVoucherType(
      condition,
      columns,
      {},
      { limit: 10 }
    );
  }

  open(modelName) {
    this.modalService.open(modelName, { size: "lg" }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  getVoucherType(voucher) {
    this.bill.voucher_name = "";
    this.bill.voucher_object = {};

    this.bill.voucher_name = voucher.name;
    this.bill.voucher_object = voucher;
  }
  getVoucherTypeSearch() {
    this.voucherTypeTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term) => this.getVoucherType_Search(term))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.voucherTypeList = result["data"];
        },
        (err) => {
          console.log(err);
          this.voucherTypeList = [];
        }
      );
  }
  addBill() {
    this.loading = true;
    // this.sumMonthAnnualVendorAmmount();
    let current_month = new Date(
      this.bill.billing_date["singleDate"]["jsDate"]
    ).getMonth();
    this.bill.vendor.annual_total_amount += this.bill.actual_total_amount;
    this.bill.vendor.month_total_amount[
      current_month
    ] += this.bill.actual_total_amount;
    this.bill_array = [];

    if (this.company_id != "") {
      this.bill.balance_amount = this.bill.total_amount;
      this.bill.company_id = this.company_id;

      // if (typeof this.bill.purchase_order_date == "string") {
      //   this.bill.purchase_order_date = {
      //     isRange: false,
      //     singleDate: {
      //       jsDate: new Date(this.bill.purchase_order_date)
      //     }
      //   };
      // }

      this.bill.bill_items.forEach((itemObj) => {
        this.original_purchaseorderitems.forEach((itemObject) => {
          if (itemObj.product._id == itemObject.product._id) {
            itemObject.is_billed = 2;
          }
        });
      });

      this.original_purchaseorderitems.forEach((itemsObj) => {
        if (itemsObj.is_billed == 3) {
          this.bill_array.push(3);
        } else if (itemsObj.is_billed == 1) {
          this.bill_array.push(3);
        } else {
          this.bill_array.push(2);
        }
      });

      let bill_status = this.bill_array.includes(3);
      if (bill_status) {
        this.bill.purchase_status = 3;
        this.bill.purchase_status_name = "partially-billed";
      } else {
        this.bill.purchase_status = 2;
        this.bill.purchase_status_name = "billed";
      }
      this.bill.original_items = this.original_purchaseorderitems;
      this.bill.add = this.bill.balancing_items;

      if (this.is_expense == true) {
        this.bill.is_booking_payment = 1;
        this.bill.tx_type = 51;
        this.bill.is_igst = this.is_igst;
        this.bill.tx_type_id = 51;

        this.expenseservice.addExpense(this.bill).subscribe((result) => {
          if (result['response']["_id"]) {
            this.loading = false;
            swal(
              "Expense added sucessfully!",
              "Expense added.",
              "success"
            );
            this.router.navigateByUrl("/bill_expense/list");
            // this.bill.billing_date = this.bill.billing_date["singleDate"][
            //   "jsDate"
            // ].toISOString();
            // this.bill["ref_id"] = result["_id"];

            // this.billService
            //   .createDoubleEntryTransaction(this.bill)
            //   .subscribe((result_payment) => {
            //     if (result_payment["statusCode"] == 200) {
            //       this.loading =false;
            //       swal(
            //         "Expense added sucessfully!",
            //         "Expense added.",
            //         "success"
            //       );
            //       this.router.navigateByUrl("/bill_expense/list");
            //     }
            //     //
            //   });
          }
        });
      } else {
        if (this.bill.purchase_order_date != undefined) {
          this.bill.purchase_order_date = this.bill.purchase_order_date['singleDate']['jsDate'];
        }
        this.bill.billing_date = this.bill.billing_date['singleDate']['jsDate'];
        this.bill.due_date = this.bill.due_date['singleDate']['jsDate'];

        this.billservice.addBill(this.bill).subscribe((result) => {
          if (result["n"] == 1) {
            swal("Added bill sucessfully!", "bill added.", "success");

            if (this.files.length != 0) {
              this.billservice
                .UpdateBillFile(this.files, result["response"]["muid"])
                .subscribe((result) => { });
            }

            this.router.navigateByUrl("/bill/list");
          } else {
            swal("Error !", result["response"], "error");
          }
        });
      }
    }
  }

  getCoaData() {
    let in_condition: any = [];
    if (
      this.bill.is_discount == true &&
      this.bill.is_productwise_discount == false
    ) {
      in_condition = [
        ConstantService.coa_heads.PURCHASE_DIRECT_EXPENSES,
        ConstantService.coa_heads.Discount,
        ConstantService.coa_heads.Fixed_Assets_Tangible
      ];
    } else if (
      this.bill.is_discount == true &&
      this.bill.is_productwise_discount == true
    ) {
      in_condition = [ConstantService.coa_heads.PURCHASE_DIRECT_EXPENSES,ConstantService.coa_heads.Fixed_Assets_Tangible];
    } else if (this.is_expense == true) {
      in_condition = [ConstantService.coa_heads.Purchase_Accounts,ConstantService.coa_heads.Fixed_Assets_Tangible];
    } else {
      in_condition = [ConstantService.coa_heads.Purchase_Accounts,ConstantService.coa_heads.Fixed_Assets_Tangible];
    }
    this.getCoaHead("", in_condition).subscribe((result) => {
      this.account_head_lists = result["response"];
      this.all_account_heads = result["response"];
      // this.addRemoveDiscountHead();
    });
  }

  addRemoveDiscountHead() {
    let in_condition = [];
    if (
      this.bill.is_discount == true &&
      this.bill.is_productwise_discount == false
    ) {
      in_condition = [ConstantService.coa_heads.PURCHASE_DIRECT_EXPENSES];
    } else if (
      this.bill.is_discount == true &&
      this.bill.is_productwise_discount == true
    ) {
      in_condition = [ConstantService.coa_heads.PURCHASE_DIRECT_EXPENSES];
    } else {
      in_condition = [ConstantService.coa_heads.PURCHASE_DIRECT_EXPENSES];
    }
    let account_head_data = this.account_head_lists;
    this.account_head_lists = [];
    account_head_data.forEach((element) => {
      in_condition.forEach((head_name) => {
        if (element.flattened_data.name == head_name) {
          this.account_head_lists.push(element);
        }
      });
    });
    this.validateAccountHead();
  }

  getCoaHead(searchString, in_condition) {
    if(in_condition !=null && this.is_expense == false){
        in_condition = [ConstantService.coa_heads.PURCHASE_DIRECT_EXPENSES,ConstantService.coa_heads.Fixed_Assets_Tangible ];
      } else if (this.is_expense == true) {
        in_condition = [ConstantService.coa_heads.Purchase_Accounts,ConstantService.coa_heads.Fixed_Assets_Tangible];
      }else if (in_condition == "" ||in_condition == null) {
        in_condition = [ConstantService.coa_heads.PURCHASE_DIRECT_EXPENSES,ConstantService.coa_heads.Fixed_Assets_Tangible];
      }
    if (searchString != null) {
      searchString = searchString;
    }else if(searchString ==null){
      searchString ="";
    }
    this.account_head_lists = [];
    return this.mastersService.getCoaHead(this.company_id, searchString, {
      // in: [ConstantService.coa_heads.Assets]
      in: in_condition,
      is_parent: true,
    });
  }

  getCoaHeadSearch() {
    this.accountHeadListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getCoaHead(searchString, ""))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.account_head_lists = result["response"];
          // this.addRemoveDiscountHead();
        },
        (err) => {
          console.log(err);
          this.account_head_lists = [];
        }
      );
  }

  getBill() {
    // let condition: any = {};
    // condition["company_id"] = this.company_id;
    this.billservice.viewBill(this.id).subscribe((result) => {
      this.bill = result["response"];
      this.getImageFromFileupload(this.bill['image_id']);

      this.bill.sub_total_amount = result["response"]["sub_total_amount"];

      let currentAccountHeads: any = [];
      currentAccountHeads.push(result["response"]["ledger"]["id"]);
      this.billAddAccountConfig.accountsHeads = currentAccountHeads;

      this.billAddAccountConfig.is_required = true;
      this.billAddAccountConfig.field_name = result["response"]["ledger"]["name"];
      this.billAddAccountConfig.label_name = result["response"]["ledger"]["name"];
      this.billAddAccountConfig.is_set = true;
      this.bill.currency = result['response']['vendor']['currency'];

      // let currentLedger : any = [];
      // currentLedger.push(result["response"]["voucher_object"]["code"]);

      if (this.is_edit_form && this.is_credit_note == false) {
        if (
          this.bill.vendor.state["zone_id"] ==
          this.company_details.state["zone_id"]
        ) {
          this.is_same_state = true;
        }
        this.bill_dates_array.forEach((date_name) => {
          if (this.bill[date_name]) {
            this.bill[date_name] = {
              isRange: false,
              singleDate: {
                jsDate: new Date(this.bill[date_name]),
              },
            };
          }
        });
      }
      if (this.is_credit_note == true) {
        this.bill.bill_items.forEach((element) => {
          delete element.is_billed;
          if (element["returned_qty"] > 0) {
            element["units"] = element["units"] - element["returned_qty"];
          }
          element["max_units"] = element["units"];
          this.getAmount(element);
        });
        this.getTotalAmount();
      }
    });

  }

  addNewItem() {
    this.bill.bill_items.push({
      product: null,
      description: "",
      units: 1,
      price: 0,
      amount: 0,
      actual_amount: 0,
      tax_amount: 0,
      total_amount: 0,
      discount: 0,
      discount_in_percentage: 0,
      discount_in_amount: 0,
    });
  }

  deleteItem(index) {
    if (this.original_purchaseorderitems[index] != undefined) {
      this.original_purchaseorderitems[index]["is_billed"] = 3;
    }
    if (this.bill.bill_items.length > 1) {
      this.bill.bill_items.splice(index, true);
      this.unSetProductWiseDiscount();
      this.getTotalAmount();
    }
  }

  addNewBalancingItem() {
    // if (this.bill.balancing_items == undefined) {
    //   this.bill.balancing_items = [];
    // }
    this.bill.balancing_items.push({
      account_head: null,
      amount: 0,
      percentage: 0,
    });
  }

  validateAccountHead() {
    // this.bill.balancing_items.forEach(itemsObj => {
    //   console.log('accounthead', itemsObj['account_head'])
    //   if (itemsObj['account_head'] != null) {
    //     this.account_head_lists = this.all_account_heads.filter((data) => data.flattened_data._id != itemsObj['account_head']['flattened_data']['_id']);
    //   } else if (itemsObj['account_head'] == null) {
    //     this.account_head_lists = this.all_account_heads;
    //   }
    // });

  }

  deleteBalancingItem(index) {
    if (index != 0) {
      this.bill.balancing_items.splice(index, true);
      this.unSetProductWiseDiscount();
      this.getTotalAmount();
    }
  }

  getProducts(searchString) {
    this.product_lists = [];
    let columns = {
      name: 1,
      purchase: 1,
      type: 1,
      sku: 1,
      unit: 1,
      code: 1,
      hsn_muid: 1,
      group_id: 1,
      group_name: 1
    };
    let condition: any = {};
    if (searchString != "") {
      condition["name"] = searchString;
    }
    condition["company_id"] = this.company_id;
    if (this.header == "Expense Invoice") {
      condition["is_expense"] = true;
    }
    if (this.header == "Invoice Entry") {
      condition["is_purchase"] = true;
    }
    return this.productService.getAllProducts(condition, "all", columns);
  }

  getProductSearch() {
    this.productListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getProducts(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.product_lists = result;
        },
        (err) => {
          console.log(err);
          this.product_lists = [];
        }
      );
  }

  getCompanyDetails() {
    this.companyService.viewCompany(this.company_id).subscribe((result) => {
      this.company_details = result["response"];
      this.voucher_type_lists = this.company_details.purchase_column_settings.voucher;
      this.bill.voucher_type = this.voucher_type_lists[0];
      this.billDateOptions.dateFormat = this.company_details.date_format;
      this.dueDateOptions.dateFormat = this.company_details.date_format;
      this.billPurchaseDateOptions.dateFormat = this.company_details.date_format;
      let book_beginning_date = new Date(this.company_details.book_beginning);
      book_beginning_date.setDate(book_beginning_date.getDate() - 1);
      let currentDueDateOptions = this.getCopyOfOptions();
      currentDueDateOptions.disableUntil = {
        year: book_beginning_date.getFullYear(),
        month: book_beginning_date.getMonth() + 1,
        day: book_beginning_date.getDate(),
      };
      this.dueDateOptions = currentDueDateOptions;
      let currentBillingDtOptions = this.getCopyOfOptions();
      // currentBillingDtOptions.disableUntil = {
      //   year: book_beginning_date.getFullYear(),
      //   month: book_beginning_date.getMonth() + 1,
      //   day: book_beginning_date.getDate(),
      // };
      currentBillingDtOptions.disableUntil = {year:this.selectFiYear.start_year, month:3, day:31};
      currentBillingDtOptions.disableSince = {year:this.selectFiYear.end_year, month:4, day:1};
      this.billDateOptions = currentBillingDtOptions;

      let currentBillPurchaseDtOptions = this.getCopyOfOptions();
      currentBillPurchaseDtOptions.disableUntil = {
        year: book_beginning_date.getFullYear(),
        month: book_beginning_date.getMonth() + 1,
        day: book_beginning_date.getDate(),
      };
      this.billPurchaseDateOptions = currentBillPurchaseDtOptions;
    });
  }
  getCopyOfOptions(): IAngularMyDpOptions {
    return JSON.parse(JSON.stringify(this.billDateOptions));
    return JSON.parse(JSON.stringify(this.dueDateOptions));
  }

  addUpdateBill() {
    if (this.is_edit_form === true) {
      if (this.editForm == "bill") {
        this.editBill();
      } else if (this.editForm == "expense") {
        this.editExpense();
      }
    } else if (this.is_credit_note == true) {
      if (this.id != null) {
        this.addCreditNote();
      }

    } else if (this.is_credit_note_add && this.is_expense_debitnote) {
      this.addCreditNote();

    }
    else if (this.is_credit_note_add) {
      if (this.id != null) {
        this.editDebitNote();
      } else {
        this.addCreditNote();
      }

    } else {
      this.addBill();
    }
  }

  addCreditNote() {
    this.loading = true;
    this.bill.company_id = this.company_id;
    this.bill.branch_id = this.branch_id;
    delete this.bill.due_date;
    return this.billservice.debitNote(this.bill).subscribe((result) => {
      if (result["response"]["muid"] != "") {
        this.loading = false;
        swal("Added Debit Note sucessfully!", "Debit Note Added.", "success");
        this.router.navigateByUrl("/debit-bill/list");
      } else {
        this.showErrors(result["response"]["errors"]);
      }
    });
  }

  async editBill() {
    this.loading = true;
    this.bill['billing_date'] = this.bill['billing_date']['singleDate']['jsDate'];
	if(this.bill['purchase_order_date'])
    this.bill['purchase_order_date'] = this.bill['purchase_order_date']['singleDate']['jsDate'];
    this.bill['due_date'] = this.bill['due_date']['singleDate']['jsDate'];

    await this.billservice.editBill(this.bill, this.id).subscribe((result) => {
      if (result["status_code"] == 1) {
        this.loading = false;
        this.router.navigateByUrl("/bill/list");
        swal(" Success !", " Bill Updated Successfully ", "success");
      } else {
        this.showErrors(result["response"]["errors"]);
      }
    });
  }
  editDebitNote() {
    this.loading = true;
    this.bill.company_id = this.company_id;
    this.bill.branch_id = this.branch_id;
    this.billservice.updateDebitNote(this.bill, this.id).subscribe((result) => {
      if (result["status_code"] == 1) {
        this.loading = false;
        swal("Debit Note Updated sucessfully!", "Debit Note Updated.", "success");
        this.router.navigateByUrl("/debit-bill/list");
      } else {
        this.showErrors(result["response"]["errors"]);
      }
    });

  }

  async editExpense() {
    this.bill.is_booking_payment = 1;
    this.bill.tx_type = 51;
    this.bill.is_igst = this.is_igst;
    this.bill.tx_type_id = 51;

    this.loading = true;
    await this.billservice.editExpense(this.bill, this.id).subscribe((result) => {
      if (result["response"]["status_code"] == 1) {
        this.loading = false;
        this.router.navigateByUrl("/bill_expense/list");
        swal(" Success !", " Expense Updated Successfully ", "success");
      } else {
        this.showErrors(result["response"]["errors"]);
      }
    });
  }

  showErrors(errors) {
    let error_string: string;
    error_string = " ";
    errors.forEach((item) => {
      error_string += " < br / > " + item;
    });
    swal({
      title: "Error",
      html: error_string,
      type: "error",
    }).then(function () { });
  }

  getVendors(searchString) {
    this.vendor_lists = [];
    let condition: any = {};
    let columns: any = {};
    if (searchString != "") {
      condition["vendor_name"] = searchString;
    }
    condition["company_id"] = this.company_id;
    columns["vendor_name"] = 1;
    columns["payment_term"] = 1;
    columns["first_name"] = 1;
    columns["last_name"] = 1;
    columns["currency"] = 1;
    columns["address_line1"] = 1;
    columns["address_line2"] = 1;
    columns["valid_upto"] = 1;
    columns["country"] = 1;
    columns["state"] = 1;
    columns["phone"] = 1;
    columns["coa_data"] = 1;
    columns["credit_note_data"] = 1;
    columns["is_sez"] = 1;
    columns["is_composite_company"] = 1;
    columns["tds"] = 1;
    columns["month_total_amount"] = 1;
    columns["annual_total_amount"] = 1;
    columns["pan"] = 1;
    columns["vendor_category"] = 1;
    return this.vendorService.getVendors(condition, columns, {}, { limit: 10 });
  }
  getVendorsSearch() {
    this.vendorListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getVendors(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.vendor_lists = result["response"]["data"];
        },
        (err) => {
          console.log(err);
          this.vendor_lists = [];
        }
      );
  }

  getpurchase_Order(searchString) {
    this.purchaseorder_lists = [];
    let condition: any = {};
    let columns: any = {};
    if (searchString != "") {
      condition["purchase_order_number"] = searchString;
    }

    condition["company_id"] = this.company_id;
    if (this.bill.vendor != null) {
      condition["vendor"] = {};
      condition["vendor"]["_id"] = this.bill.vendor["_id"];
    }
    condition["purchase_status"] = 1; //unbilled

    return this.purchaseorderService
      .getPurchaseorders(condition, columns, {}, { limit: 10 })
      .subscribe((result) => {
        this.purchaseorder_lists = result["response"]["data"];
      });
  }
  getpo_Orders() {
    this.purchaseorder_lists = [];
    let condition: any = {};
    let columns: any = {};

    condition["company_id"] = this.company_id;
    if (this.bill.vendor != null) {
      condition["vendor"] = {};
      condition["vendor"]["_id"] = this.bill.vendor["_id"];
    }
    condition["purchase_status"] = 1; //unbilled

    return this.purchaseorderService
      .getPurchaseorders(condition, columns, {}, { limit: 10 })
      .subscribe((result) => {
        this.purchaseorder_lists = result["response"]["data"];
      });
  }
  getPurchaseOrderSearch() {
    this.purchaseOrderListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(async (searchString) => this.getpurchase_Order(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.purchaseorder_lists = result["response"]["data"];
        },
        (err) => {
          console.log(err);
          this.purchaseorder_lists = [];
        }
      );
  }

  getAmount(itemsObj) {
    let actual_amount: any;
    let tax_amount: any = 0;

    actual_amount = itemsObj.units * itemsObj.price;
    itemsObj.actual_amount = actual_amount;
    if (this.bill.is_without_gst == false) {
      if (itemsObj.tax > 0 || itemsObj.tax != undefined) {
        tax_amount = (actual_amount / 100) * itemsObj.tax;
        itemsObj.amount = actual_amount + tax_amount;
      } else {
        itemsObj.tax = 0;
        itemsObj.amount = actual_amount;
      }
    } else {
      itemsObj.tax = 0;
      itemsObj.amount = actual_amount;
    }

    itemsObj.amount = parseFloat(itemsObj.amount.toFixed(2));
    itemsObj.tax_amount = parseFloat(itemsObj.tax_amount);

    itemsObj.actual_amount = parseFloat(itemsObj.actual_amount.toFixed(2));
    this.getTotalAmount();
  }

  applyDiscountforProductItems() {
    if (this.bill.is_discount == true) {
      this.bill.bill_items.forEach((itemsObj) => {
        let actual_amount = 0;
        let tax_amount = 0;
        let total_amount = 0;
        let discount_in_percentage = 0;
        actual_amount = itemsObj.units * itemsObj.price;
        // if (this.company_details.is_lut) {
        //   this.checkForLut();
        //   if (this.is_gst_applicable == true) {
        //     tax_amount = ((actual_amount / 100) * itemsObj.tax);
        //     itemsObj.amount = actual_amount + tax_amount;
        //   } else {
        //     tax_amount = 0;
        //     itemsObj.amount = actual_amount;
        //   }
        // } else if (itemsObj.tax != undefined) {
        //   tax_amount = ((actual_amount / 100) * itemsObj.tax);
        //   itemsObj.amount = actual_amount + tax_amount;
        // }
        if (itemsObj.tax != undefined) {
          tax_amount = (actual_amount / 100) * itemsObj.tax;
          itemsObj.amount = actual_amount + tax_amount;
        } else {
          itemsObj.tax = 0;
          itemsObj.amount = actual_amount;
        }
        total_amount = actual_amount + tax_amount;
        if (this.bill.is_productwise_discount == true) {
          if (
            itemsObj.discount_in_percentage > 0 &&
            this.bill.discount_in_percentage == true
          ) {
            itemsObj.discount_in_amount = 0;
            discount_in_percentage =
              (total_amount / 100) * itemsObj.discount_in_percentage;
            itemsObj.discount = discount_in_percentage;
            if (this.is_credit_note == true) {
              itemsObj.discount = discount_in_percentage / itemsObj.units;
            }
            // this.bill.discount = itemsObj.discount;
            // itemsObj.amount = total_amount - discount_in_percentage;
          } else if (
            itemsObj.discount_in_amount > 0 &&
            this.bill.discount_in_percentage == false
          ) {
            itemsObj.discount_in_percentage = 0;
            itemsObj.discount = itemsObj.discount_in_amount;
            if (this.is_credit_note == true) {
              itemsObj.discount = itemsObj.discount_in_amount / itemsObj.units;
            }
            // this.invoice.discount = itemsObj.discount;
            // itemsObj.amount = total_amount - itemsObj.discount_in_amount;
          }
        }
      });
    }
  }

  applyDiscountforOverallItems() {
    this.bill.freight = 0;
    // this.bill.transport_charges = 0;

    // this.invoice.sub_total_amount = 0;
    this.bill.balancing_items.forEach((element) => {
      if (element.account_head != null) {
        // if (element.account_head.flattened_data.name == ConstantService.coa_heads.Freight) {
        //   this.bill.freight = element.amount;
        //   // this.invoice.sub_total_amount = this.invoice.sub_total_amount + this.invoice.freight;
        //   // this.invoice.grand_total_amount = this.invoice.sub_total_amount;
        //   // this.getTotalAmount();

        // }

        // if (element.account_head.flattened_data.name == ConstantService.coa_heads.Other_Income) {
        //   this.bill.transport_charges = element.amount;
        //   // this.getTotalAmount();
        //   // this.invoice.sub_total_amount = this.invoice.sub_total_amount + element.amount;
        //   // this.invoice.grand_total_amount = this.invoice.sub_total_amount;
        // }
        if (
          element.account_head.flattened_data.name ==
          ConstantService.coa_heads.Discount &&
          this.bill.is_productwise_discount == false
        ) {
          // this.invoice.discount = 0;
          if (this.bill.discount_in_percentage == false) {
            this.bill.discount = element.amount;
            if (this.is_credit_note == true) {
              let sum_of_units: number = this.bill.bill_items.reduce(
                (sum, current) => sum + current.units,
                0
              );
              this.bill.discount = element.amount / sum_of_units;
            }
          } else {
            this.bill.discount = element.percentage;
          }
          // this.invoice.grand_total_amount = this.invoice.sub_total_amount - this.invoice.discount;
        }
      }
    });
  }

  //Copied function from Invoice

  getTotalAmount() {
    this.bill.total_amount = 0;
    this.bill.actual_total_amount = 0;
    this.bill.balance_amount = 0;
    this.bill.total_tax_percentage = 0;
    this.bill.total_tax_amount = 0;
    this.bill.discount = 0;
    this.bill.sub_total_amount = 0;
    this.bill.grand_total_amount = 0;
    // this.bill.freight = 0;
    // this.bill.transport_charges = 0;
    // this.bill.discount = 0;
    this.applyDiscountforProductItems();
    this.applyDiscountforOverallItems();
    this.bill.bill_items.forEach((element) => {
      //Rounding off value to two decimal points

      this.bill.total_amount += element["amount"];
      this.bill.actual_total_amount += element["actual_amount"];
      this.bill.balance_amount += element["amount"];

      if (element["tax_amount"] > 0) {
        this.bill.total_tax_amount += element["tax_amount"];
      }

      this.bill.total_tax_amount = parseFloat(
        this.bill.total_tax_amount.toFixed(2)
      );

      if (this.bill.vendor) {
        if (
          this.bill.vendor.state["zone_id"] ==
          this.company_details.state["zone_id"]
        ) {
          this.bill.cgst = this.bill.total_tax_amount / 2;
          this.bill.sgst = this.bill.total_tax_amount / 2;
          this.is_same_state = true;
          this.is_igst = false;

          this.bill.cgst = parseFloat(this.bill.cgst.toFixed(2));
          this.bill.sgst = parseFloat(this.bill.sgst.toFixed(2));
          this.bill.igst = 0;


          this.bill.total_tax_amount = 0;
          this.bill.total_tax_amount = this.bill.cgst + this.bill.sgst;
        } else {


          this.bill.igst = this.bill.total_tax_amount;
          this.is_same_state = false;
          this.is_igst = true;
          this.bill.igst = parseFloat(this.bill.igst.toFixed(2));

          this.bill.total_tax_amount = 0;
          this.bill.total_tax_amount = this.bill.igst;
        }
      }
      this.bill.sub_total_amount =
        this.bill.actual_total_amount + this.bill.total_tax_amount;
      if (this.bill.is_discount == true) {
        if (this.bill.is_productwise_discount == true) {
          // console.log('productwise discount', element['discount'])

          // this.bill.grand_total_amount = this.bill.sub_total_amount - this.bill.discount;
          this.bill.total_amount =
            this.bill.sub_total_amount - this.bill.discount;
        } else if (this.bill.is_productwise_discount == false) {
          this.applyDiscountforOverallItems();
          if (this.bill.discount_in_percentage == true) {
            this.bill.discount =
              (this.bill.sub_total_amount / 100) * this.bill.discount;
          }
          this.bill.total_amount =
            this.bill.sub_total_amount - this.bill.discount;
        } else {
          this.bill.total_amount = this.bill.sub_total_amount;
        }
      } else {
        this.bill.total_amount = this.bill.sub_total_amount;
      }

    });
    this.bill.balancing_items.forEach((item) => {
      this.bill.sub_total_amount += item.amount;
    });
    // this.bill.sub_total_amount = this.bill.sub_total_amount + this.bill.freight
    // this.bill.sub_total_amount = this.bill.sub_total_amount + this.bill.transport_charges
    this.bill.total_amount = this.bill.sub_total_amount - this.bill.discount;
    //For Credit Note
    if (this.is_credit_note == true) {
      this.bill.discount = 0;
      // let temp_amount = this.bill.freight + this.bill.transport_charges;
      let temp_amount;
      if (this.bill.balancing_items[0].amount != 0) {
        this.bill.balancing_items.forEach((item) => {
          temp_amount += item.amount;
        });
        this.bill.sub_total_amount = this.bill.sub_total_amount - temp_amount;
        this.bill.total_amount = this.bill.sub_total_amount;
      }
    }
    this.bill.balance_amount = this.bill.total_amount;

    if (this.company_details["is_sez"] == true) {
      this.bill.sub_total_amount = this.bill.actual_total_amount;
      this.bill.total_amount =
        this.bill.total_amount - this.bill.total_tax_amount;
      this.bill.balance_amount = this.bill.total_amount;
      this.bill.total_tax_amount = 0;
      this.bill.cgst = 0;
      this.bill.sgst = 0;
      this.bill.igst = 0;
    }

    this.bill.round_of = 0;
    this.bill.round_value = 0;
    this.bill.bill_amount_wo_roundof = (this.bill.total_amount).toFixed(2);
    this.bill.round_value = Math.round(this.bill.total_amount);
    this.bill.round_of = Number((this.bill.round_value - this.bill.total_amount).toFixed(2));
    this.bill.total_amount = this.bill.total_amount + Number(this.bill.round_of);

    this.bill.actual_total_amount = parseFloat((this.bill.actual_total_amount).toFixed(2));
    // this.bill.freight = parseFloat(this.bill.freight.toFixed(2))
    // this.bill.transport_charges = parseFloat(this.bill.transport_charges.toFixed(2))
    this.bill.discount = parseFloat(this.bill.discount.toFixed(2));
    // this.sumMonthAnnualVendorAmmount();
    this.calculateTDSAmount();
  }

  unSetProductWiseDiscount() {
    this.bill.discount = 0;
    this.bill.bill_items.forEach((element) => {
      element.discount = 0;
      if (this.bill.is_productwise_discount == false) {
        let actual_amount = element.units * element.price;
        let tax_amount = (actual_amount / 100) * element.tax;
        if (this.bill.discount_in_percentage == false) {
          element.discount_in_amount = 0;
        } else {
          element.discount_in_percentage = 0;
        }

        element.amount = actual_amount + tax_amount;
      } else {
        this.bill.balancing_items.forEach((balancingObj, key) => {
          if (balancingObj.account_head != null) {
            if (
              balancingObj.account_head.flattened_data.name ==
              ConstantService.coa_heads.Discount
            ) {
              if (this.bill.balancing_items.length > 1) {
                this.bill.balancing_items.splice(key, true);
              } else {
                this.bill.balancing_items.splice(key, true);
                this.addNewBalancingItem();
              }
            }
          }
        });
      }
    });
  }

  // OLD working function of bill
  // getTotalAmount() {
  //   this.bill.total_amount = 0;
  //   this.bill.balance_amount = 0;
  //   this.bill.total_tax_percentage = 0;
  //   this.bill.total_tax_amount = 0;
  //   this.bill.bill_items.forEach(element => {
  //     this.bill.total_amount += element["amount"];
  //     this.bill.balance_amount += element["amount"];
  //     this.bill.total_tax_amount += element["tax_amount"];
  //     this.bill.total_tax_percentage += element["tax"];
  //     this.bill.actual_total_amount =
  //       this.bill.total_amount - this.bill.total_tax_amount;
  //     // console.log('this.bill.vendor', this.bill.vendor);
  //     // console.log('this.company_details.state', this.company_details.state);
  //     //Uncommand While Commit
  //     if (
  //       this.bill.vendor.state["zone_id"] ==
  //       this.company_details.state["zone_id"]
  //     ) {
  //       this.bill.cgst = this.bill.total_tax_amount / 2;
  //       this.bill.sgst = this.bill.total_tax_amount / 2;
  //       this.is_same_state = true;
  //     } else {
  //       this.bill.igst = this.bill.total_tax_amount;
  //       this.is_same_state = false;
  //     }
  //     console.log("amount in loop", element["amount"]);
  //     console.log("this.total_amount in loop", this.total_amount);
  //   });
  // }

  calculatePrice(itemsObj) {
    let item_price: number = 0;
    itemsObj.actual_amount = 0;
    itemsObj.amount = 0;
    itemsObj.item_total = 0;

    itemsObj.item_total = itemsObj.units * itemsObj.price;
    // if (this.bill.discount_in_percentage || this.bill.is_productwise_discount) {
    //   if (this.bill.discount_in_percentage) {
    //     itemsObj.item_total =
    //       itemsObj.item_total -
    //       itemsObj.item_total * (itemsObj.discount_in_percentage / 100);
    //   } else {
    //     itemsObj.item_total = itemsObj.item_total - itemsObj.discount_in_amount;
    //   }
    // }
    if (this.bill.is_without_gst == false) {
      itemsObj["tax"] = itemsObj["igst"];
      this.getTaxAmount(itemsObj);
    } else {
      itemsObj["tax"] = 0;
      itemsObj.tax_amount = 0;
      this.getAmount(itemsObj);
    }
  }

  getTaxAmount(itemsObj) {
    itemsObj.tax_amount = 0;
    if (this.company_details.is_gst_filing == true) {
      if (itemsObj.tax > 0) {
        let actual_amount: any;
        let tax_amount: any;
        actual_amount = itemsObj.units * itemsObj.price;
        tax_amount = (actual_amount / 100) * itemsObj.tax;
        itemsObj.tax_amount = tax_amount;
      }
    } else {
      itemsObj.tax_amount = 0;
      itemsObj.tax = 0;
    }
    this.getAmount(itemsObj);
  }

  validateUnits(itemsObj) {
    if (this.is_credit_note == true) {
      this.bill.bill_items.forEach((element) => {
        if (itemsObj.product._id == element.product._id) {
          if (element.units > itemsObj.max_units) {
            itemsObj.units = itemsObj.max_units;
            swal(
              "Quantity Should Not Be Exceeded",
              "Please Enter Values Lesser Than " + itemsObj.max_units,
              "warning"
            );
          }
        }
      });
    }
  }

  getPriceFromHSN(product_data, itemsObj, index) {
    if (product_data != null) {
      let condition: any = {};
      let sort: any = {};
      sort["effects_from"] = "desc";
      condition["_id"] = product_data["hsn_muid"];
      condition["effects_from_is_active"] = true;
      let data = {};

      this.hsnService.getHSNByCondition(condition, sort).subscribe((result) => {
        if (result["response"][0] != undefined) {
          result["response"][0]["effects_from"].forEach((e) => {
            if (e.is_active) {
              itemsObj["tax"] = 0;
              itemsObj["sgst"] = e.sgst;
              itemsObj["cgst"] = e.cgst;
              itemsObj["igst"] = e.igst;
              this.bill.bill_items[index]["tax"] = itemsObj["igst"];
              itemsObj["tax"] = this.bill.bill_items[index]["tax"];
              this.getTaxAmount(itemsObj);
            }
          });
        } else {
          itemsObj["tax"] = 0;
        }
      });
    } else {
      itemsObj["tax"] = 0;
    }
  }

  updateSequence() {
    let sequence_data: any = {};
    let sequence_muid: any = {};
    sequence_data["seq_no"] = parseInt(this.sequence_no) + 1;
    sequence_muid["muid"] = this.sequence_id;
    sequence_data["company_id"] = this.company_id;
    this.sequenceService
      .editSequence(sequence_data, sequence_muid["muid"])
      .subscribe((result) => { });
  }

  getPurchaseorder() {
    let condition: any = {};
    condition["company_id"] = this.company_id;

    this.purchaseorderService
      .viewPurchaseorder(this.id, condition)
      .subscribe((result) => {
        this.bill.vendor = result["response"].vendor;

        if (result["response"].vendor.tds.length == 1) {
          this.tds = this.bill.vendor.tds[0];
        }
        result["response"]["purchase_order_items"].forEach((val) =>
          this.original_purchaseorderitems.push(Object.assign({}, val))
        );

        this.bill = result["response"];
        if (typeof result["response"]["is_without_gst"] == "undefined") {
          this.bill.is_without_gst = false;
        }
        this.bill.ledger = new Ledger();
        this.bill.due_date = {
          isRange: false,
          singleDate: {
            jsDate: addDays(new Date(), this.bill.vendor.payment_term),
          },
        };
        this.bill.billing_date = {
          isRange: false,
          singleDate: {
            jsDate: new Date(),
          },
        };

        this.bill.bill_items = this.getBillItems(
          result["response"]["purchase_order_items"]
        );
        this.bill.credit_notes = result['response']['notes'];
        this.bill.purchaseorder_id = result["response"]["_id"];
        if (this.bill) {
          this.bill.balancing_items = [];

          this.addNewBalancingItem();
          this.bill.bill_items.forEach((element) => {
            this.calculatePrice(element);
            //   this.getAmount(element);
          });
          //this.getTotalAmount();
          // this.bill.is_productwise_discount = false;
          // this.bill.is_discount = false;
          // this.bill.discount_in_percentage = false;
        }
      });
  }
  getDate() {
    return new Date();
  }

  getBillItems(array_items) {
    let billItems = [];
    array_items.filter(function (e) {
      if (e.is_billed == 3 || e.is_billed == 1) {
        billItems.push(e);
      }
    });

    return billItems;
  }

  updateLedger(ledger: any) {
    if(ledger !=null){
      this.bill.ledger.name = ledger.flattened_data.name;
      this.bill.ledger.id = ledger.flattened_data.id;
      this.bill.ledger.ref_id = ledger.flattened_data["_id"];
      this.bill.ledger.parent_id = ledger.flattened_data.parent_id;
      this.bill.ledger.parent_name = ledger.flattened_data.parent_name;
      }else{
        this.billAddAccountConfig.accountsHeads = [5040,
          5041,
          5042,
          5043,
          5046,
          5047,
          5026,
          5022,
          5039];
        if(this.user_id !=8){
          this.billAddAccountConfig.is_required = true;
        }else{
          this.billAddAccountConfig.is_required = false;
        }

      }
  }

  getNextSequence() {
    let bill_sequence: any = {};
    bill_sequence["seq_name"] = "bill";
    bill_sequence["company_id"] = this.company_id;
    this.sequenceService.viewSequence(bill_sequence).subscribe((result) => {
      if (result["response"] != "" && result["response"] != null) {
        if (result["response"]["seq_no"]) {
          this.bill.bill_sequencenumber =
            this.company_details["purchase_column_settings"][
            "bill_order_prefix"
            ] +
            result["response"]["seq_no"] +
            this.company_details["purchase_column_settings"][
            "bill_order_suffix"
            ];

          this.sequence_no = result["response"]["seq_no"];
          this.sequence_id = result["response"]["_id"];
        } else {
          swal(
            "Update Settings!",
            "Update Purchase Order Settings In Company And Then Add SO",
            "warning"
          );
          this.router.navigateByUrl("/company/edit/" + this.company_id);
        }
      } else {
        swal(
          "Update Settings!",
          "Update Sales Order Settings In Company And Then Add SO",
          "warning"
        );
        this.router.navigateByUrl("/company/edit/" + this.company_id);
      }
    });
  }

  redirectUrl(type) {
    let po = this.purchaseorder_lists.filter(
      (purchase_order) =>
        purchase_order.purchase_order_number == this.bill.purchase_order_number
    )[0];
    if (po != null) {
      this.router.navigateByUrl("/bill/purchase_order/" + po["_id"]);
    }
  }
  setDueDate(event: IMyDateModel) {
    if (this.bill.vendor != undefined || this.bill.vendor != null) {
      let payment_terms = this.bill.vendor.payment_term;
      this.onchangeDueDate(event, payment_terms);
    }
  }

  setDueDateAndCurrency(bill_data) {
    // this.bill.currency = this.currency;
    if (this.bill.billing_date != undefined || this.bill.billing_date != null) {
      this.onchangeDueDate(
        this.bill.billing_date,
        this.bill.vendor.payment_term
      );
    }
  }

  onchangeDueDate(date_val, payment_term) {
    let dataObj = add(new Date(date_val.singleDate.jsDate), {
      days: payment_term,
    });
    this.bill.due_date = {
      isRange: false,
      singleDate: {
        jsDate: new Date(dataObj),
      },
    };
  }
  setBillAccountHeaderConfig() {
    let currentAccountHeads: any = [];
    if (this.is_credit_note == false && this.is_expense == false) {
      currentAccountHeads = [5038, 5039,1019,1020];
    } else if (this.is_expense) {
      currentAccountHeads = [
	  1019,1020,
        5040,
        5041,
        5042,
        5043,
        5046,
        5047,
        5026,
        5022,
        5039,
      ];
    }
    this.billAddAccountConfig.accountsHeads = currentAccountHeads;
    this.billAddAccountConfig.is_required = true;
    this.billAddAccountConfig.field_name = "bill_accounts";
    this.billAddAccountConfig.label_name = "Bill Accounts";
    // this.billAddAccountConfig.fixedAssets =
  }

  calculateTDSAmount() {
    if (Object.keys(this.tds).length > 0) {
      let is_tds_applicable: boolean;
      this.tds.basic_cut_off.forEach((basicCutOff) => {
        let is_tds_applicable_for_current_slab = this.checkTDSApplicableBySlab(
          basicCutOff.period,
          basicCutOff.greater_than
        );
        if (is_tds_applicable_for_current_slab == true) {
          is_tds_applicable = true;
        }
      });

      if (is_tds_applicable == true) {
        if (this.checkPanForVendor() == false) {
          this.bill.tds.nature_of_payment = this.tds.nature_of_payment;
          this.bill.tds.section = this.tds.sec;
          this.bill.tds.tds_percentage = this.tds.no_pan_or_adhar;
          this.deductTDSToCurrentBill(this.bill.tds.tds_percentage);
        } else {
          if (
            this.bill.vendor.vendor_category.id == 1 ||
            this.bill.vendor.vendor_category.id == 5
          ) {
            this.bill.tds.nature_of_payment = this.tds.nature_of_payment;
            this.bill.tds.section = this.tds.sec;
            this.bill.tds.tds_percentage = this.tds.individual_and_huf;
            this.deductTDSToCurrentBill(this.bill.tds.tds_percentage);
          } else {
            this.bill.tds.nature_of_payment = this.tds.nature_of_payment;
            this.bill.tds.section = this.tds.sec;
            this.bill.tds.tds_percentage = this.tds.other_than_individual_or_huf;
            this.deductTDSToCurrentBill(this.bill.tds.tds_percentage);
          }
        }
      }
    }
  }
  checkPanForVendor(): boolean {
    if (!this.bill.vendor.pan) {
      return false;
    } else {
      return true;
    }
  }

  checkTDSApplicableBySlab(period: TDSPeriod, cutOffValue: number): boolean {
    let isTDSApplcable = false;
    switch (period) {
      case "single_bill":
      case "all":
        if (this.bill.actual_total_amount > cutOffValue) {
          isTDSApplcable = true;
        }
        break;
      case "per_annum":
        if (
          this.bill.vendor.annual_total_amount + this.bill.total_amount >
          cutOffValue
        ) {
          isTDSApplcable = true;
        }
        break;
      case "per_month":
        let monthNumber = new Date(this.bill.billing_date).getMonth();
        if (
          this.bill.vendor.month_total_amount[monthNumber] +
          this.bill.total_amount >
          cutOffValue
        ) {
          isTDSApplcable = true;
        }
        break;
    }
    return isTDSApplcable;
  }

  deductTDSToCurrentBill(tdsPercentage: number) {
    this.bill.tds.tds_deducted_amount =
      (tdsPercentage / 100) * this.bill.actual_total_amount;
    this.bill.total_amount =
      this.bill.total_amount - this.bill.tds.tds_deducted_amount;
  }

  onChangeVendor() {
    if (this.bill.vendor.tds.length == 1) {
      this.tds = this.bill.vendor.tds[0];
    }
    this.bill.vendor.currency = this.bill.vendor['currency'];
    this.calculateTDSAmount();
    this.sumMonthAnnualVendorAmmount();
    this.getTotalAmount();
    this.getpo_Orders();
    // this.setDueDateAndCurrency(this.bill.vendor);
    if (this.bill.vendor.is_composite_company == true) {
      this.bill.is_without_gst = true;
    }
    if(this.bill.billing_date !=null){
      this.onDateChanged(this.bill.billing_date);
    }else{
      this.bill.due_date = {
        isRange: false,
        singleDate: {
          jsDate: addDays(new Date(), this.bill.vendor.payment_term),
        },
      };
    }
    this.bill.due_date = {
      isRange: false,
      singleDate: {
        jsDate: addDays(new Date(), this.bill.vendor.payment_term),
      },
    };
  }

  sumMonthAnnualVendorAmmount() {
    if (this.bill.total_amount > 0) {
      this.bill.vendor.annual_total_amount =
        this.bill.vendor.annual_total_amount + this.bill.total_amount;
      let monthNumber = new Date(this.bill.billing_date).getMonth();
      this.bill.vendor.month_total_amount[monthNumber] =
        this.bill.vendor.month_total_amount[monthNumber] +
        this.bill.total_amount;
    }
  }

  onCheckboxChange() {
    this.bill.bill_items.forEach((element) => {
      this.calculatePrice(element);
    });
    // if (this.bill.is_without_gst) {
    //   this.bill.is_without_gst = false;
    //   this.bill.bill_items.forEach((element) => {
    //     this.calculatePrice(element);
    //   });
    // } else {
    //   this.bill.is_without_gst = true;
    //   this.bill.bill_items.forEach((element) => {
    //     this.calculatePrice(element);
    //   });
    // }
  }
  getImageFromFileupload(image_muid) {
    let condition = {};
    condition['_id'] = image_muid;
    condition['company_id'] = this.company_id;

    this.billService.getImageFromFileUpload(condition).subscribe(result => {
      this.imageFiles = result['data'];
      if (result['data'] != null) {
        this.bill.file_uploadimage_id = result['data']['_id'];
        this.image_path = ConstantService.IMG_BASE_URL;
      }

    })
  }
  getExpense(expenseId) {
    let condition = {};
    condition['company_id'] = this.company_id;
    condition['_id'] = expenseId;

    this.expenseservice.getExpenses(condition, {}).subscribe(result => {
      this.bill = result['data'][0]['expense_object'];

      if (this.is_expense_debitnote) {
        this.bill['billing_date'] = this.bill['billing_date']['singleDate']['jsDate'];
        this.bill['due_date'] = this.bill['due_date']['singleDate']['jsDate'];
      }
      else {

        this.bill['billing_date'] = {
          isRange: false,
          singleDate: {
            jsDate: new Date(result['data'][0]['billing_date']),
          },
        };
        this.bill['due_date'] = {
          isRange: false,
          singleDate: {
            jsDate: new Date(result['data'][0]['due_date']),
          },
        };
      }

      let currentAccountHeads: any = [];
      currentAccountHeads.push(result['data'][0]['expense_object']["ledger"]["id"]);
      this.billAddAccountConfig.accountsHeads = currentAccountHeads;

      this.billAddAccountConfig.is_required = true;
      this.billAddAccountConfig.field_name = result['data'][0]['expense_object']["ledger"]["name"];
      this.billAddAccountConfig.label_name = result['data'][0]['expense_object']["ledger"]["name"];
      this.billAddAccountConfig.is_set = true;



      this.bill.bill_items.forEach((element) => {
        this.getAmount(element);
      });
      this.getTotalAmount();

    })

  }
  onDateChanged(event: IMyDateModel): void {
    this.bill.due_date = {
      isRange: false,
      singleDate: {
        jsDate: addDays(new Date(event['singleDate']['jsDate']), this.bill.vendor.payment_term),
      },
    }
  }
}
