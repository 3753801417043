import { Injectable } from "@angular/core";
import { ApiService } from "../_services/api.service";
import { AuthenticationService } from "./authentication.service";
import {ContactAddress} from '../_models/contact_address.model';
@Injectable({
  providedIn: "root"
})
export class CustomerService {
  branch_id: string;
  constructor(private http: ApiService,
    private authenticationService: AuthenticationService) {
    this.branch_id = this.authenticationService.getDefaultBranchId();
  }

  addCustomer(customer_data: any) {
    if (this.branch_id != '') {
      customer_data['branch_id'] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("customer", "add"), customer_data);
  }
  editCustomer(customer_data: any, id: string) {
    return this.http.post(ApiService.geturl("customer", "edit"), {
      data: customer_data,
      customer_id: id
    });
  }
  deleteCustomer(id: string) {
    return this.http.post(ApiService.geturl("customer", "delete"), { id: id });
  }

  viewCustomer(customer_id: any) {
    return this.http.post(ApiService.geturl("customer", "view"), customer_id);
  }

  getCustomers(
    filters: any = {},
    columns: any = {},
    sort_by: any = {},
    page: any = {}
  ) {
    if (this.branch_id != '') {
      filters['branch_id'] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("customer", "viewall"), {
      filters: filters,
      columns: columns,
      sort_by: sort_by,
      page: page
    });
  }
  getCustomersData(filters: any = {}, columns: any = {}) {
    if (this.branch_id != '') {
      filters['branch_id'] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("customer", "data"), {
      filters: filters,
      columns: columns
    });
  }

  saveCustomerAddress(customer_muid: string, contact_address: ContactAddress) {
    return this.http.post(ApiService.geturl("customer", "add_address"), {
      customer_muid: customer_muid,
      contact_address: contact_address
    });
  }
  uploadFiles(files, data) {
    console.log("data", data);
    
    return this.http.uploadFile(ApiService.geturl('customer', 'upload'),
      files,
      data
    );

  }
}
