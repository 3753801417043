export class Expense {
  payee: any = {};
  payment_account: string;
  payment_date: any;
  payment_method: Object;
  category?: string;
  description?: string;
  amount?: any;
  tax?: any;
  expense_items: any = [];
  company_id: string;
  total_tax_amount: number;
  balance_amount: number;
  total_expense_amount: number;
  party_id: {};
  is_booking_patyment: number;
  form_mode: number;
  is_booking_payment: number;
  ref_id: any;
  tx_date: any;
  voucher_number: any;
  booking_date: any;
  cgst: number = 0;
  sgst: number = 0;
  igst: number = 0;
  total_amount: any;
  actual_total_amount: any;
  pay_type: number;
  is_direct_payment: boolean;
  voucher_name: string;
  voucher_object: {};
}
