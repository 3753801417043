import { Component, OnInit, ChangeDetectorRef, EventEmitter } from "@angular/core";
import { UserService } from "../../../_services/user.service";
import { CompanyService } from "../../../_services/company.service";
import { AuthenticationService } from "../../../_services/authentication.service";
import { User } from "../../../_models/user";
import swal from "sweetalert2";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { CountryService } from "../../../_services/country.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { ConstantService } from "../../../_config/constants";
import { element } from "protractor";
import { filter } from "rxjs/operators";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
@Component({
  selector: "app-user-add",
  templateUrl: "./user-add.component.html",
  styleUrls: ["./user-add.component.scss"]
})
export class UserAddComponent implements OnInit {
  loading: boolean = false;
  user: User = new User();
  user_id: string;
  unique_id: string;
  company_id: string;
  companies_list: any = [];
  username_list: any = [];
  user_roles: any = [
    { name: "Elixir - Admin", role_id: 1 },
    { name: "Elixir - Manager", role_id: 2 },
    { name: "Elixir - Accountant", role_id: 3 },
    { name: "Elixir - User", role_id: 6 },
    { name: "Elixir - External User", role_id: 7 },
    { name: "Mini ERP", role_id: 8 },
    { name: "Client - Admin", role_id: 9 },
    { name: "Client - Manager", role_id: 10 },
    { name: "Client - Accountant", role_id: 11 },
    { name: "Client - User", role_id: 12 }
  ];
  genders: any = [
    { name: "Male", id: 1 },
    { name: "Female", id: 2 },
    { name: "Other", id: 3 }
  ];
  dobDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat
  };
  country_list: any = [];
  userNameListTypeahead = new EventEmitter<string>();
  stateList: any = [];
  is_branch: boolean = false;
  is_edit_form: boolean = false;
  reset_password_form: boolean = false;
  title: string;
  company_details: any = [];
  filters: any = {};
  page: any = { offset: 0, pageSize: 10 };
  reporting_to: any = [];
  sort: any;
  columns: any;
  companyids: any = [];
  constructor(
    private userservice: UserService,
    private authenticationService: AuthenticationService,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private countryService: CountryService,
    private router: Router
  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.user_id = params.get("id");
      this.unique_id = params.get("unique_id");
      var result = this.router.url.match("/edit");
      var reset_password = this.router.url.match("/reset_password/");
      if (result != null) {
        if (result[0] === "/edit" && this.user_id != null) {
          this.is_edit_form = true;
          this.title = "Edit";
          this.viewUser();
        }
      } else {
        this.title = "Add";
      }
      if (reset_password != null) {
        if (reset_password[0] === "/reset_password/" && this.unique_id != null) {
          this.reset_password_form = true;
          this.title = 'Reset Password';

        }
      } else {
        this.getCountryData("");
        this.getCompanies();
        this.getUsersList("").subscribe((result) => {
          this.username_list = result["response"]["data"];
        });
        this.getUsersListSearch();
      }
    });

  }

  addUpdateUser() {
    if (this.is_edit_form == false && this.reset_password_form == false) {
      this.addUser();
    } else if (this.is_edit_form == true && this.reset_password_form == false) {
      this.updateUser();
    } else {
      this.resetPassword();
    }
  }

  addUser() {
    this.loading = true;
    // this.user.username = this.user.email;
    if (Array.isArray(this.user.company_details)) {
      this.user.company_ids = this.user.company_details;
    } else {
      this.user.company_ids = [];
      this.user.company_ids.push(this.user.company_details);
    }


    this.userservice.addUser(this.user).subscribe(result => {
      if (result["status_code"] == 1) {
        this.loading = false;
        swal("Added!", "User Has Been Added Successfully", "success");
        this.router.navigateByUrl("/user/list");
      } else {
        swal("Failure!", result["response"], "warning");
      }
    });
  }

  getCompanies() {
    let condition: any = {};
    if (this.company_id != "") {
      condition["company_id"] = this.company_id;
    }
    this.companyService.getCompanies(condition).subscribe(result => {
      this.companies_list = result["response"];
      this.companies_list.forEach(element => {
        this.companyids.push(element._id);

      });
    });
    console.log('this.companies_list===>>>', this.companies_list);
  }

  getUsersList(searchString) {
    this.username_list = [];
    if (searchString != "") {
      this.filters["firstname"] = searchString;
    }
    return this.userservice
      .viewall(this.filters, this.columns, this.sort, this.page);

  }

  getUsersListSearch() {
    this.userNameListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getUsersList(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.username_list = result["response"]["data"];
        },
        (err) => {
          console.log(err);
          this.username_list = [];
        }
      );
  }

  getReportList(data) {
    this.userservice
      .viewall(data.role_id)
      .subscribe((result) => {
        this.username_list = result["response"]["data"];
      });
  }

  getCountryData(searchString) {
    this.country_list = [];
    let filters = {};
    filters["name"] = searchString;
    return this.countryService.getCountryData(filters, 0).subscribe(result => {
      this.country_list = result;
    });
  }

  setBranch() {
    this.user.branch_ids = [];
    this.user.company_ids.forEach(selectedcompanies => {
      this.companies_list.forEach(companiesList => {
        if (companiesList["_id"] == selectedcompanies) {
          if (companiesList.branch.length > 1) {
            companiesList.branch.forEach(element => {
              this.user.branch_ids.push(element["_id"]);
            });
          }
        }
      });
    });
  }

  getStateByCountry() {
    let country_id = this.user.country["_id"];
    this.countryService
      .getStateByCountry({ muid: country_id })
      .subscribe(result => {
        this.stateList = result[0]["zones"];
        if (this.user.state) {
          this.user.state = this.stateList[this.user.state['zone_id']];
        }
      });
  }
  companyId() {
    if (this.user.company_ids.length == 1) {
      this.user.company_ids = [];
      this.user.company_ids.push(this.user.company_details);
    } else {
      this.user.company_ids = [];
      this.user.company_ids = this.user.company_details;
    }
  }
  updateUser() {

    this.userservice.updateUser(this.user, this.user_id).subscribe(result => {
      if (result["status_code"] == 1) {
        swal("Updated!", "User Information Updated Successfully", "success");
        this.router.navigateByUrl("/user/list");
      } else {
        swal("Failure!", result["response"], "warning");
      }
    });
  }

  viewUser() {
    console.log('00000999999');
    this.userservice
      .viewUser(this.user_id)
      .subscribe(result => {
        this.user = result["response"];
        if (result["response"]["company_ids"].length == 1) {
          this.user.company_details = result["response"]["company_ids"][0];
        } else {
          this.user.company_details = result["response"]["company_ids"];
        }
        // this.user.company_details = '603c917cf5f57b5f92cd9b9f';
        if (this.user.dob) {
          this.user.dob = {
            isRange: false,
            singleDate: {
              jsDate: new Date(this.user.dob)
            }
          };
        }
        // this.getStateByCountry();
      });
  }

  resetPassword() {
    console.log('user', this.user)
    let user_data: any = {};
    user_data['new_password'] = this.user.new_password;
    user_data['confirm_password'] = this.user.confirm_password;
    this.userservice.resetPassword(this.unique_id, user_data).subscribe(result => {
      if (result['status_code'] == 1) {
        swal('Success!', 'Your Password Has Been Updated Successfully', 'success');
        this.router.navigateByUrl("/login");
      } else {
        swal('Warning', result['response'], 'error');
        this.user.new_password = '';
        this.user.confirm_password = '';
      }
    });
  }
}
