<div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header d-flex align-items-center">
            <h3 class="table-title"><i class="fa fa-gear"></i> Units </h3>
          </div>
          <form novalidate #adUnitsForm="ngForm" (ngSubmit)="addUnits()">
            <div class="card-body row">
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 ">
                <label class="col-form-label ">Name</label>
                <input type="text" required class="form-control" name="unit_name"
                  #unit_name_valid="ngModel" [(ngModel)]="unit_name" placeholder="Unit Name">
  
                <app-validation-errors [mformField]="unit_name_valid" label="Unit name">
                </app-validation-errors>
              </div>
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label class="col-form-label ">Type</label>
                <input type="text" required class="form-control" name="unit_type" #unit_type_valid="ngModel" [(ngModel)]="unit_type"
                  placeholder="Unit Type">
                <app-validation-errors [mformField]="unit_type_valid" label="Unit type">
                </app-validation-errors>
              </div>
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label class="col-form-label ">Code</label>
                <input type="text" class="form-control" required name="unit_code" #unit_code_valid="ngModel"
                  [(ngModel)]="unit_code" placeholder="Unit code">
                <app-validation-errors [mformField]="unit_code_valid" label="Unit Code">
                </app-validation-errors>
              </div>
             
              
            </div>
            <div class="card-footer">
              <button type="submit" [disabled]="!adUnitsForm.valid || loading==true" class="btn btn-primary">Save 
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>