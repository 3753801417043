import { Component, OnInit, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { ReceiptsService } from "../../_services/receipts.service";
import { AuthenticationService } from "../../_services/authentication.service";
import swal from "sweetalert2";
import { NgbModalConfig, NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { Customer } from "../../_models/customer";
import { CustomerService } from "../../_services/customer.service";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { ConstantService } from "src/app/_config/constants";

@Component({
  selector: "app-receipts-customer",
  templateUrl: "./receipts-customer.component.html",
  styleUrls: ["./receipts-customer.component.scss"],
})
export class ReceiptsCustomerComponent implements OnInit {
  filters: any = {};
  page: any = { offset: 0, pageSize: 10 };
  sort: any = { payment_date: -1 };
  dt_default_sort = [{ prop: "payment_date", dir: "asc" }];
  limit = 50;
  count: any;
  customers: any = [];
  customerList: Customer[] = [];
  customerListTypeahead = new EventEmitter<string>();
  columns: any = {};
  loadingIndicator = true;
  reorderable = true;
  customerTableListColumns: any = [];
  // @ViewChild('vendorTable', { static: true }) table: any;
  company_id: string;
  currency: string;
  id: any;
  paymentDateOptions: IAngularMyDpOptions = {
    dateRange: true,
    dateFormat: ConstantService.DateFormat
  };
  advance_status_list: any = [
    { name: "UnPaid", id: 1 },
    { name: "Draft", id: 6 },
  ]
  companyDetails: any;
  selectFiYear: any;

  constructor(
    private receiptService: ReceiptsService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private customerService: CustomerService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.companyDetails = this.authenticationService.getCompanyDetails();
    this.currency = this.companyDetails.base_currency;


    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];
    this.filters.payment_date = {
      isRange: true,
      singleDate: null,
      dateRange: {
        beginDate: { year: this.selectFiYear.start_year, month: 4, day: 1 },
        beginJsDate: new Date(this.selectFiYear.start_year, 3, 1),
        endDate: { year: this.selectFiYear.end_year, month: 3, day: 31 },
        endJsDate: new Date(this.selectFiYear.end_year, 2, 31),
      }
    };

    this.currency = this.authenticationService.getCompanyDetails()[
      "base_currency"
    ];
    this.getCustomers("").subscribe((result: Customer[]) => {
      this.customerList = result;
    });
    this.getCustomersSearch();
    this.customerTableListColumns = [
      {
        prop: "party.name",
        name: "Customer Name",
        sortable: "party.name",
        flexGrow: 2,
        show: true,
        is_detailed_row: false,
        type: "object",
      },
      {
        prop: "advance_amount",
        name: "Advance Amount",
        sortable: "advance_amount",
        flexGrow: 2,
        show: true,
        is_detailed_row: false,
        type: "currency",
        css_class: "align-right",
      },
      {
        prop: "payment_date",
        name: "Payment Date",
        sortable: "payment_date",
        flexGrow: 2,
        type:"date",
        show: true,
        is_detailed_row: false,
      },
      {
        prop: "advance_status_name",
        name: "Advance Status",
        sortable: "advance_status_name",
        flexGrow: 2,
        type:"status",
        show: true,
        is_detailed_row: false,
      },
      {
        prop: "description",
        name: "Description",
        sortable: "description",
        flexGrow: 2,
        show: true,
        is_detailed_row: false,
      },
      {
        name: "Action",
        type: "action",
        prop: "_id",
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false,
      },
    ];
    this.getPaginatedCustomers(this.page);
  }
  getCustomers(searchString) {
    this.customerList = [];
    let condition: any = {};
    let columns: any = {};
    if (searchString != "") {
      condition["customer_name"] = searchString;
    }
    condition["company_id"] = this.company_id;
    columns["customer_name"] = 1;
    columns["first_name"] = 1;
    columns["last_name"] = 1;
    columns["currency"] = 1;
    columns["address_line1"] = 1;
    columns["billing_address"] = 1;
    columns["shipping_address"] = 1;
    columns["payment_term"] = 1;
    columns["gst_number"] = 1;
    columns["coa_data"] = 1;
    columns["debit_note_data"] = 1;
    columns["country"] = 1;
    columns["state"] = 1;
    columns["phone"] = 1;
    return this.customerService.getCustomersData(condition, columns);
  }

  getCustomersSearch() {
    this.customerListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getCustomers(searchString))
      )
      .subscribe(
        (result: Customer[]) => {
          this.cd.markForCheck();
          this.customerList = result;
        },
        (err) => {
          console.log(err);
          this.customerList = [];
        }
      );
  }
  clearSalesAdvance() {
    this.filters = {};
    this.getPaginatedCustomers({ offset: 0, pageSize: 12 });
  }

  getPaginatedCustomers(pageInfo: any = {}) {
    this.filters["$or"] =[];
    if (pageInfo !== null) {
      this.page = pageInfo;
    }
    this.filters["company_id"] = this.company_id;
    this.filters["$or"] = Array(
      {"advance_status": {$eq:1}},
      {"advance_status": {$eq:2}},
      {"advance_status": {$eq:3}},
      {"advance_status": {$eq:6}}
    );
    //this.sort["vendor_name"] = 1;
    this.receiptService
      .getCustomersAdvance(this.filters, this.columns, this.sort, this.page)
      .subscribe((result) => {
        this.loadingIndicator = false;
        this.customers = result["response"]["data"];
        this.count = result["response"]["count"];
      });
  }

  open(content, id) {
    this.id = id;
    this.modalService.open(content, {size: "lg"});
  }

  onSort(event) {
    this.loadingIndicator = true;
    console.log("event", event);
    this.sort = {};
    let sort_details = event.sorts[0];
    this.sort[sort_details.prop] = sort_details.dir == "asc" ? 1 : -1;
    this.getPaginatedCustomers(this.page);
  }
  confirmAdvanceStatus(row_data){
    swal({
      title: "Approve the Sales Advance ?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Approve it!",
    }).then((result) => {
      if (result) {
        this.receiptService.confirmPurchaseAdvance(row_data,this.company_id).subscribe((result) => {
          if (result["ok"] === 1) {
            swal("Success!", "Sales Advance has been approved.", "success");
            this.getPaginatedCustomers({});
          }
        });
      }
    });

}
  deleteCustomerAdvance(row_data) {
    //cancel status for the advance and remove the account transaction .
    this.receiptService.cancelAdvance(row_data).subscribe((result) => {
      this.loadingIndicator = false;
      if (result["ok"]) {
        swal("Success", "Advance has been cancelled successfully", "success");
      } else {
        swal("Error", "Cant able to cancel the advance", "error");
      }
    });
    this.getPaginatedCustomers(this.page);
  }
}
