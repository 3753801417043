<div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header d-flex align-items-center">
            <h3  class="table-title">{{passwordLabel}} Password</h3>
          </div>
          <form class="form-horizontal" (ngSubmit)="changePassword()" novalidate #saveUser="ngForm">
              <div  *ngIf="this.userId == this.user_muid"
                class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="password">Password</label>
                <input type="password" class="form-control" #password="ngModel" required placeholder="Password"
                  id="user_password" name="user_password" [(ngModel)]="user_password">
                <app-validation-errors [mformField]="password" label="Password"></app-validation-errors>
              </div>
  
              <div  class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="new_password">New Password</label>
                <input type="password" class="form-control" #new_password="ngModel" required placeholder="New Password"
                  id="user_password" name="user_new_password" [(ngModel)]="user_new_password">
                <app-validation-errors [mformField]="new_password" label="New Password"></app-validation-errors>
              </div>
  
              <div  class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="confirm_password">Confirm Password</label>
                <input type="password" class="form-control" #confirm_password="ngModel" required
                  placeholder="Confirm Password" id="user_confirm_password" name="user_confirm_password"
                  [(ngModel)]="user_confirm_password" [equal]="user_new_password">
                <app-validation-errors [mformField]="confirm_password" label="Confirm Password"></app-validation-errors>
              </div>
            <button type="submit" class="btn btn-primary">Save 
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- TODO Users List -->