import { Sales } from "./sales.model";
import { Purchase } from "./purchase.model";
import { BOM } from "./bom.model";
//import { Items } from './items.model';

export class Product {
  type: any;
  name: String;
  group: String;
  // sku: String;
  unit: Object;
  image: any;
  code: String;
  hsn_desc: String;
  hsn_muid: String;
  group_id: any;
  group_name: any;

  is_purchase: boolean=true;
  is_sell: boolean=true;
  is_expense: boolean=true;

  sales: Sales = new Sales();
  purchase: Purchase = new Purchase();
  bom: [BOM];

  is_track: boolean;
  product_stock: number;
  reorder_point: number;
  stock_rate_perunit: number;
  company_id: string;
  branch_id: string;
  old_group_id: any;
}
