import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MastersService } from '../../../_services/masters.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
@Component({
  selector: 'app-ledgers',
  templateUrl: './ledgers.component.html',
  styleUrls: ['./ledgers.component.scss']
})
export class LedgersComponent implements OnInit, OnChanges {
  @Input() ledgerAccountsHeads: any = {};
  ledgerName: any = {};
  ledgersList: any = [];
  filters: any = { regex: '', in: [] };
  ledgersListTypeHead = new EventEmitter<string>();
  company_id: string;
  currentLedgersAccountsHeads: any = [];
  @Output() public onSelectLedger = new EventEmitter<String>();

  constructor(
    private mastersService: MastersService,
    private authenticationService: AuthenticationService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    if (this.ledgerAccountsHeads != null && this.ledgerAccountsHeads != undefined) {
      if(this.ledgerAccountsHeads.is_required !=undefined && this.ledgerAccountsHeads.is_required !=null){
        this.ledgerAccountsHeads.is_required  = this.ledgerAccountsHeads.is_required ;
      }

      this.currentLedgersAccountsHeads = this.ledgerAccountsHeads.accountsHeads;
      if (this.ledgerAccountsHeads.is_set != undefined && this.ledgerAccountsHeads.is_set == true) {
        this.ledgerName = this.ledgersList.filter(
          items => items.flattened_data.id == this.ledgerAccountsHeads.accountsHeads[0])[0];
        this.currentLedgersAccountsHeads = this.ledgerAccountsHeads.accountsHeads;
      }
    }

  }

  ngOnChanges() {
    this.ledgerName = null;
    // this.ledgerAccountsHeads.field_name='accounts_head';
    if (this.ledgerAccountsHeads != undefined) {
      this.currentLedgersAccountsHeads = this.ledgerAccountsHeads.accountsHeads;
      this.company_id = this.authenticationService.getDefaultCompanyId();
      this.getLedgersList('').subscribe(result => {
        this.ledgersList = result['response'];
        if (this.ledgerAccountsHeads.is_set != undefined && this.ledgerAccountsHeads.is_set == true) {
          this.ledgerName = this.ledgersList.filter(
            items => items.flattened_data.id == this.ledgerAccountsHeads.accountsHeads[0])[0];
          this.currentLedgersAccountsHeads = this.ledgerAccountsHeads.accountsHeads;
        }
      });
      this.getLedgersSearch();

    }

  }
  getLedgersSearch() {
    this.ledgersListTypeHead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(searchString => this.getLedgersList(searchString))
      )
      .subscribe(
        result => {
          this.cd.markForCheck();
          this.ledgersList = result['response'];
        },
        err => {
          console.log(err);
          this.ledgersList = [];
        }
      );
  }

  getLedgersList(searchString: string) {
    this.ledgersList = [];
    let condition: any = {};
    condition[this.ledgerAccountsHeads.filter_type] = this.currentLedgersAccountsHeads;
    condition.is_parent = true;
    return this.mastersService.getCoaHead(this.company_id, searchString, condition);
  }

  updateLedger() {
    this.onSelectLedger.emit(this.ledgerName);
  }
  clearLedger() {
    this.onSelectLedger.emit(null);
  }

}
