export class Tds {
    nature_of_payment: string;
    sec: string;
    basic_cut_off: BasicCutOff[];
    individual_and_huf?: number;
    other_than_individual_or_huf?: number;
    no_pan_or_adhar?: number;

}
export class BasicCutOff {
    period: TDSPeriod;
    greater_than: number;
};

export type TDSPeriod = 'single_bill' | 'per_annum' | 'per_month'| 'all';