import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
// import { PurchaseorderService } from 'app/_services/purchaseorder.service;'
import { PurchaseorderService } from "../../../_services/purchaseorder.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { AuthenticationService } from "../../../_services/authentication.service";
import { CompanyService } from "../../../_services/company.service";
import { Company } from "../../../_models/company.model";

@Component({
  selector: "app-purchaseorder",
  templateUrl: "./purchaseorder-view.component.html",
  styleUrls: ["./purchaseorder-view.component.scss"]
})
export class PurchaseorderViewComponent implements OnInit {
  filters: any = {};
  purchaseorder: any = {};
  purchase_orderList: any = [];
  purchase_orderListColumns: any = [];
  page: any = { offset: 0, limit: 10 };
  purchase_order_id: string;
  // bill: any;
  company_id: string;
  branch_id: string;
  currency: string;
  company_details: Company = new Company();
  is_same_state: boolean = false;
  constructor(
    private purchaseorderService: PurchaseorderService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private companyService: CompanyService,

  ) { }
  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.currency = this.authenticationService.getCompanyDetails()[
      "base_currency"
    ];
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.route.paramMap.subscribe(params => {
      this.purchase_order_id = params.get("id");
      if (this.purchase_order_id != "") {
        this.viewBills();
      }
    });
    this.viewCompany();
  }

  viewBills() {
    let condition: any = {};
    condition['company_id'] = this.company_id;
    this.purchaseorderService
      .viewPurchaseorder(this.purchase_order_id, condition)
      .subscribe(result => {
        this.purchaseorder = result["response"];
        //     this.sales_order.due_date = {
        //         isRange: false, singleDate: {
        //             jsDate: new Date(this.sales_order.due_date)
        //         }
        //     };
        //     this.sales_order.sales_order_date = {
        //         isRange: false, singleDate: {
        //             jsDate: new Date(this.sales_order.sales_order_date)
        //         }
        //     };
      });
  }
  viewCompany() {
    this.companyService.viewCompany(this.company_id).subscribe(result => {
      this.company_details = result['response']
    })
  }
  // goBack() {
  //     this.location.back();
  // }
}
