<div class="container-fluid">
  <div class="row view">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header d-flex align-items-center">
          <h3 class="table-title">{{header}} View</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 ">
              <!-- <div class=""> -->
              <label class="col-form-label" for="vendor">{{label}}</label>
              <!-- <ng-select [items]="vendor_lists" [typeahead]="vendorListTypeahead" #vendorModel="ngModel" 
                name="vendor" bindLabel="vendor_name" [(ngModel)]="vendor" placeholder="Select vendor">
                <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div [ngOptionHighlight]="search">
                    {{ item.vendor_name }}
                  </div>
                </ng-template>
              </ng-select> -->
              <!-- <app-validation-errors [mformField]="vendorModel" label="vendor"></app-validation-errors> -->

              <!-- </div> -->
              <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3"> -->
              <div>
                {{receiptList[0]?.party?.name}}
              </div>
              <!-- </div> -->
            </div>
            <div class="form-group  col-12 col-sm-6 col-md-3 col-xl-3">
              <label class="col-form-label ">Payment Method</label>
              <div>
                {{receiptList[0]?.payment_method.name}}
              </div>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 ">
              <label class="col-form-label">Payment Account</label>
              <div>
                {{receiptList[0]?.payment_account?.flattened_data?.name}}
              </div>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 ">
              <label class="col-form-label ">Payment Date</label>
              <div>
                {{receiptList[0]?.payment_date | date}}
              </div>
            </div>

            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 ">
              <label class="col-form-label ">Advance Amount</label>
              <div>
                {{receiptList[0]?.advance_amount}}
              </div>
            </div>

            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label class="col-form-label ">Description</label>
              <div>
                {{receiptList[0]?.description}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- TODO Add vendor in purchase order if there is no data in vendor -->
<!-- <div>
<app-vendors-add></app-vendors-add>
</div> -->