import { Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef, EventEmitter } from "@angular/core";
import swal from "sweetalert2";
import { StocktransactionsService } from "../../_services/stocktransactions.service";
import { AuthenticationService } from "../../_services/authentication.service";
import { ProductService } from "../../_services/product.service";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
@Component({
  selector: "app-stocktransactions",
  templateUrl: "./stocktransactions.component.html",
  styleUrls: ["./stocktransactions.component.scss"]
})
export class StocktransactionsComponent implements OnInit {
  filters: any = {};
  stocktransactions: any = {};
  loadingIndicator = true;
  stocktransactionsList: any = [];
  stocktransactionsListColumns: any = [];
  page: any = { offset: 0, pageSize: 10 };
  reorderable = true;
  company_id: string;
  branch_id: string;
  columns: any = {};
  sort: any = {};
  product_lists: any;
  productListTypeahead = new EventEmitter<string>();
  currency: string;
  //  @ViewChild('actionTmpl',{static:true}) actionTmpl: TemplateRef<any>;
  constructor(
    private stocktransactionsservice: StocktransactionsService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    public route: ActivatedRoute,
    private productService: ProductService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.currency = this.authenticationService.getCompanyDetails()[
      "base_currency"
    ];
    this.stocktransactionsListColumns = [
      {
        prop: "product_info",
        name: "Product",
        sortable: true,
        flexGrow: 1,
        show: true,
        field_name: 'name',
        type: 'object',
        is_detailed_row: false
      },
      {
        prop: "tx_date",
        name: "TX Date",
        sortable: "false",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "date"
      },
      {
        prop: "tx_type_id",
        name: "TX Type",
        sortable: "false",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "tx_type"
      },
      // {
      //   prop: "tx_ref_id",
      //   name: "TX Ref",
      //   sortable: "false",
      //   flexGrow: 1,
      //   show: true,
      //   is_detailed_row: false,
      //   type: "tx_ref_id"
      // },
      {
        prop: "in_qty",
        name: "In Qty",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false
      },
      {
        prop: "out_qty",
        name: "Out Qty ",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false
        // type: "object",
        // field_name: "vendor_name"
      },
      {
        prop: "bal_qty",
        name: "Balance Qty",
        sortable: "bal_qty",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        // type: "date"
      },

      {
        prop: "item_price",
        name: "Value",
        sortable: "item_price",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "currency",
        css_class: "align-right"
      },
      // {
      //   name: "Action",
      //   type: "action",
      //   prop: "_id",
      //   flexGrow: 1,
      //   is_detailed_row: false,
      //   sortable: false
      // }
    ];
    this.stocktransactionsListColumns.forEach(column => {
      this.columns[column.prop] = 1;
    });
    this.getStocktransactionssList(this.page);
    this.getProducts("").subscribe(result => {
      this.product_lists = result;
    });
    this.getProductSearch();
  }

  getStocktransactionssList(page_info: any) {
    if (page_info != null) {
      this.page = page_info;
    }
    if (this.company_id != '') {
      this.filters['company_id'] = this.company_id;
    }


    this.stocktransactionsservice
      .getStocktransactionss(this.filters, this.columns, this.sort, this.page)
      .subscribe(result => {
        this.loadingIndicator = false;
        this.stocktransactionsList = result["response"]["data"];
        this.page.count = result["response"]["count"];
      });
  }
  clearStocktransactionsFilters() {
    this.filters = {};
    this.getStocktransactionssList({});
  }
  deleteStocktransactions(muid) {
    swal({
      title: "Are you sure want to delete?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result) {
        this.stocktransactionsservice
          .deleteStocktransactions(muid)
          .subscribe(result => {
            if (result["status_code"] === 1) {
              swal(
                "Deleted!",
                "Stocktransactions has been deleted.",
                "success"
              );
              this.getStocktransactionssList({});
            }
          });
      }
    });
  }


  getProducts(searchString) {
    this.product_lists = [];
    let columns = {
      name: 1,
      purchase: 1,
      type: 1,
      sku: 1,
      unit: 1,
      hsn_code: 1
    };
    let condition: any = {};
    if (searchString != "") {
      condition["name"] = searchString;
    }
    condition["company_id"] = this.company_id;
    condition["is_purchase"] = true;
    return this.productService.getAllProducts(condition, "all", columns);
  }

  getProductSearch() {
    this.productListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(searchString => this.getProducts(searchString))
      )
      .subscribe(
        result => {
          this.cd.markForCheck();
          this.product_lists = result;
        },
        err => {
          console.log(err);
          this.product_lists = [];
        }
      );
  }
}
