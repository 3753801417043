import {Country, State} from './country.model';
export class ContactAddress {
    first_name: string;
    last_name: string;
    address_line1: string;
    address_line2: string;
    city: string;
    state: State = new State();
    country: Country = new Country();
    postal_code: string;
    _id?: string;
}


