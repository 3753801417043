import { Component, OnInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { AuthenticationService } from "../../_services/authentication.service";
import swal from "sweetalert2";
import {MastersService} from '../../_services/masters.service';
import { distinctUntilChanged, debounceTime, switchMap, filter } from "rxjs/operators";
@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.scss']
})
export class UnitsComponent implements OnInit {

  unitsList: any = [];
  typeList: any = [];
  unitsListColumns: any = [];
  loadingIndicator = false;
  page: any = { offset: 0, pageSize: 10 };
  dt_default_sort = [{ prop: "updated_at", dir: "desc" }];
  filters: any = {};
  // company_id: string;
  columns: any = {};
  sort: any = { updated_at: -1 };
  nameList: any = [];
  name_lists: any = [];
  type_lists: any = [];
  code_lists: any = [];
  nameListTypeahead = new EventEmitter<string>();
  typeListTypeahead = new EventEmitter<string>();
  codeListTypeahead = new EventEmitter<string>();

  constructor(
    private authenticationService: AuthenticationService,
    private mastersService: MastersService,
    private cd : ChangeDetectorRef
  ) { }

  ngOnInit() {
    // this.company_id = this.authenticationService.getDefaultCompanyId();
   
    this.unitsListColumns = [
      {
        prop: "name",
        name: "Name",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false
      },
      {
        prop: "type",
        name: "Type",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false
      },
      {
        prop: "code",
        name: "Code",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false
      },
      {
        name: "Action",
        type: "action",
        prop: "_id",
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false
      },
    ];
    this.getUnits("").subscribe(result => {
      this.name_lists = result['response']['data'];
    });
    this.getUnitsSearch();

    this.getUnitsType("").subscribe(result => {
      this.type_lists = result['response']['data'];
    });
    this.getUnitsTypeSearch();

    this.getUnitsCode("").subscribe(result => {
      this.code_lists = result['response']['data'];
    });
    this.getUnitsCodeSearch();
    this.getUnitsList(this.page);
    this.unitsListColumns.forEach(column => {
      this.columns[column.prop] = 1;
    });
  }

  getUnitsList(page_info: any) {
    if (page_info != null) {
      this.page = page_info;
    }
    // this.filters["company_id"] = this.company_id;
    this.mastersService
      .getUnits(this.filters, this.columns, this.sort, this.page)
      .subscribe(result => {
        this.loadingIndicator = false;
        // this.purchaseorderListColumns = [
        //   { name: "Action", cellTemplate: this.actionTmpl, prop: "muid" }
        // ];
        this.unitsList = result["response"]["data"];

        this.page.count = result["response"]["count"];
      });
  }
  getUnitsCode(searchString) {
    this.code_lists = [];
    let condition: any = {};
    condition['units_code_search_string'] = searchString;
    let columns: any = {};
    if (searchString != "") {
      condition["code"] = searchString;
    }
    columns["code"] = 1;
    return this.mastersService.getUnits(condition, columns, {}, { limit: 10 });
  }
  getUnitsCodeSearch() {
    this.codeListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getUnitsCode(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.code_lists = result["response"]["data"];
        },
        (err) => {
          console.log(err);
          this.code_lists = [];
        }
      );
  }
  getUnitsType(searchString) {
    this.type_lists = [];
    let condition: any = {};
    condition['units_type_search_string'] = searchString;
    let columns: any = {};
    if (searchString != "") {
      condition["type"] = searchString;
    }
    columns["type"] = 1;
    return this.mastersService.getUnits(condition, columns, {}, { limit: 10 });
  }
  getUnitsTypeSearch() {
    this.typeListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getUnitsType(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.type_lists = result["response"]["data"];
        },
        (err) => {
          console.log(err);
          this.type_lists = [];
        }
      );
  }
  getUnits(searchString) {
    this.name_lists = [];
    let condition: any = {};
    condition['units_search_string'] = searchString;
    let columns: any = {};
    if (searchString != "") {
      condition["name"] = searchString;
    }
    columns["name"] = 1;
    return this.mastersService.getUnits(condition, columns, {}, { limit: 10 });
  }
  getUnitsSearch() {
    this.nameListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getUnits(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.name_lists = result["response"]["data"];
        },
        (err) => {
          console.log(err);
          this.name_lists = [];
        }
      );
  }
  clearUnitsFilters() {
    this.filters = {};
    this.getUnitsList({ offset: 0, pageSize: 10 });
  }
  deleteUnits(muid) {
    swal({
      title: "Are you sure want to delete?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result) {
        this.mastersService
          .deleteUnits(muid)
          .subscribe(result => {
            if (result["status_code"] === 1) {
              swal("Deleted!", "Purchaseorder has been deleted.", "success");
              this.getUnitsList({});
            }
          });
      }
    });
  }
  onSort(event) {
    this.loadingIndicator = true;
    // console.log("event", event);
    this.sort = {};
    let sort_details = event.sorts[0];
    this.sort[sort_details.prop] = sort_details.dir == "desc" ? 1 : -1;
    this.unitsList(this.page);
  }

}
