<div class="section-body">
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          Copyrighted to <a> Elixir</a>
          © 2020 Developed By <a href="https://www.megamtech.com"> Megam Technologies</a>
        </div>
        <div class="col-md-6 col-sm-12 text-md-right">
          <ul class="list-inline mb-0">
            <!-- <li class="list-inline-item"><a href="javascript:void(0)">Documentation</a></li>
            <li class="list-inline-item"><a href="javascript:void(0)">FAQ</a></li> -->
          </ul>
        </div>
      </div>
    </div>
  </footer>
</div>