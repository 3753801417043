export class Contra {
  tx_date: any;
  contra_id: string;
  company_id: string;
  branch_id: string;
  contra_items: any = [];
  memo: string;
  voucher_name: any;
  voucher_object: any;
}
