
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root', })
export class TdsService {

    constructor(private http: ApiService) { }

    addTds(tds_data: any) {
        return this.http.post(ApiService.geturl('tds', 'add'),
            { data: tds_data });
    }
    editTds(tds_data: any, id: string) {

        return this.http.post(ApiService.geturl('tds', 'edit'),
            { data: tds_data, tds_id: id });
    }
    deleteTds(id: string) {
        return this.http.post(ApiService.geturl('tds', 'delete'),
            { id: id });
    }

    viewTds(id: string) {
        return this.http.post(ApiService.geturl('tds', 'view'),
            { tds_id: id });
    }

    getTdsList(filters: any = {}, sort_by: any = {}, columns: any = {}, page: any = {}) {
        return this.http.post(ApiService.geturl('tds', 'viewall'),
            { filters: filters, sort_by: sort_by, columns: columns, page: page });
    }
}