import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Location } from "@angular/common";
// import { SalesorderService } from 'app/_services/salesorder.service;'
import { SalesorderService } from "../../../_services/salesorder.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { AuthenticationService } from "../../../_services/authentication.service";
import { Company } from "../../../_models/company.model";
import { CompanyService } from "../../../_services/company.service";
@Component({
  selector: "app-sales-order-view",
  templateUrl: "./sales-order-view.component.html",
  styleUrls: ["./sales-order-view.component.scss"],
})
export class SalesorderViewComponent implements OnInit {
  filters: any = {};
  page: any = { offset: 0, limit: 10 };
  sales_order_id: string;
  sales_order: any = {};
  company_id: any;
  company_details: Company = new Company();
  is_debit_note: boolean = false;
  branch_id: any;
  currency: string;
  constructor(
    private salesorderService: SalesorderService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    public companyService: CompanyService
  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.currency = this.authenticationService.getCompanyDetails()['base_currency']
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.route.paramMap.subscribe((params) => {
      this.sales_order_id = params.get("id");
      if (this.sales_order_id != "") {
        this.viewSalesOrder();
      }
    });
    this.viewCompany()
  }

  viewSalesOrder() {
    let condition: any = {};
    condition['company_id'] = this.company_id;

    this.salesorderService
      .viewSalesorder(this.sales_order_id, condition)
      .subscribe((result) => {
        this.sales_order = result["response"];
        // this.sales_order.due_date = {
        // isRange: false, singleDate: {
        // jsDate: new Date(this.sales_order.due_date)
        // }
        // };
        // this.sales_order.sales_order_date = {
        // isRange: false, singleDate: {
        // jsDate: new Date(this.sales_order.sales_order_date)
        // }
        // };
        this.sales_order.sub_total_amount =
          this.sales_order.actual_total_amount - this.sales_order.discount;
      });
  }

  goBack() {
    // this.location.back();
  }
  viewCompany() {
    this.companyService.viewCompany(this.company_id).subscribe(result => {
      this.company_details = result['response']
    })
  }
}
