<div *ngIf="voucherCategoryCode == 0" class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <form novalidate #searchVoucherTypeForm="ngForm" (ngSubmit)="getVoucherTypeList()">
                <div class="card">
                    <div class="card-header d-flex align-items-center">


                        <h3 class="table-title">Voucher-Type List</h3>


                    </div>
                    <div class="card-body row">
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary mr-2">Search</button>
                        <button (click)="clearVoucherTypeFilters();" class="btn btn-warning">
                            Clear Search
                        </button>

                    </div>
                </div>
            </form>
            <div class="p-sm-2">
                <button class="btn btn-primary float-right" routerLink="/masters/voucher_type/add">
                    <i class="icon icon-add"></i>Add Voucher-Type
                </button>
            </div>

            <div class="p-sm-5">

                <ngx-datatable [rows]="voucherTypeList" [loadingIndicator]="loadingIndicator" [externalPaging]="true"
                    [columnMode]="'force'" [limit]="page.limit" [count]="page.count" [offset]="page.offset"
                    (page)="getVoucherTypeList($event)" [reorderable]="reorderable" [headerHeight]="50"
                    [footerHeight]="50" [rowHeight]="'auto'" class="material striped">


                    <ngx-datatable-column *ngFor="let col of voucherTypeListColumns" [flexGrow]=" col.flexGrow"
                        [name]="col.name" [sortable]="col.sortable" [cellClass]="col.css_class" [prop]="col.prop">
                        <ng-template ngx-datatable-header-template let-sort="sortFn">
                            <span (click)="sort()">{{ col.name }}</span>
                        </ng-template>
                        <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                            <div [ngSwitch]="col.type">
                                <!-- <span *ngSwitchCase="'currency'"> {{row[col.prop]|currency:currency}}</span> -->
                                <!-- <span *ngSwitchCase="'percentage'">{{row[col.prop]}}%</span> -->
                                <span *ngSwitchCase="'action'">
                                    <div class="btn-group mr-3">
                                        <a class="btn btn-primary" routerLink="/masters/voucher_type/edit/{{value}}"
                                            type="button" rel="tooltip">Edit</a>
                                        <div class="btn-group" ngbDropdown placement="bottom-right" container="body"
                                            role="group">
                                            <a class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></a>
                                            <div class="dropdown-menu" ngbDropdownMenu>
                                                <!-- <button class="dropdown-item" routerLink="/voucher-type/edit/{{value}}" type="button" rel="tooltip">Edit</button> -->
                                                <a class="dropdown-item " (click)="deleteVoucherType(value)">
                                                    <span class="text-red">
                                                        <i class="icon-trash-can3"></i> Delete
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                                <span *ngSwitchDefault>
                                    {{ row[col.prop] }}
                                </span>
                            </div>

                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </div>

        </div>
    </div>
</div>
<div *ngIf="voucherCategoryCode > 0">
    <ng-select name="voucher_type" #voucher_type="ngModel" (change)="getVoucherType()" [items]="voucherTypeList" bindLabel="name"
        [(ngModel)]="voucherType.voucher_type" placeholder="Select Voucher Type">

        <ng-template ng-label-tmp ng-option-tmp let-item="item" bindLabel="card_number" let-search="searchTerm">
            <div [ngOptionHighlight]="search">

                {{item.name}}

            </div>
        </ng-template>
        <!-- <ng-template ng-footer-tmp>
            <div>
                <button type="button" class="btn btn-lg btn-outline-primary" (click)="open(content)">Add</button>
            </div>

        </ng-template> -->
    </ng-select>

    <app-validation-errors [mformField]="voucher_type" label="Voucher Type">
    </app-validation-errors>
    <ng-template #content let-modal>
        <app-voucher-type-add [voucherCategoryCode]="voucherCategoryCode"></app-voucher-type-add>
    </ng-template>
</div>
