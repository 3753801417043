<form (ngSubmit)="saveTicket()" #supportTicketForm="ngForm" novalidate>

    <div class="row">
        <div class="form-group col-8" *ngIf="is_edit_form == false">
            <label for="title" class=" col-form-label"><b>Title:</b></label>
            <div class="">
                <input name="title"  #title="ngModel" [(ngModel)]="ticket.title" type="text"
                    class="form-control" placeholder="Title" id="title">
            </div>
        </div>
        <!-- </div> -->
        <div class="form-group col-12" *ngIf="is_edit_form">
            <h3 class="separator mb-3">
                #{{ticket?.ticket_id}} -
                {{ticket?.title}} <span [ngClass]="ticket.status==0? 'badge-danger': 'badge-success'"
                    class="badge badge-pill mr-1 mb-1">{{ticket.status_name}}</span>
                <span
                    class="badge badge-info badge-pill ml-1 mb-1">{{labelsList| filter: 'value': ticket.label: 'label'}}</span>
            </h3>
        </div>

        <div class="form-group col-4" *ngIf="is_edit_form==false">
            <label for="label" class=" col-form-label"><b>Label:</b></label>
            <div class="">
                <ng-select #label="ngModel" name="label" id="label" [(ngModel)]="ticket.label" [items]="labelsList"
                    bindValue="value" bindName="label">

                </ng-select>
                <!-- <select [disabled]="is_edit_form" #label="ngModel" class="form-control" name="label" id="label"
                    [(ngModel)]="ticket.label">
                    <option value="bug">Bug</option>
                    <option value="feature_request">Feature Request</option>
                    <option value="suggestion">Suggestion</option>
                    <option value="usability">Usablity</option>
                    <option value="performance">Performance</option>
                </select> -->
            </div>
        </div>
    </div>
    <ng-container *ngIf="ticket.discussions?.length>0 && is_edit_form==true">
        <h6 class="col-12">Comments :</h6>
        <div class="row app-row">
            <div class="col-12" *ngFor="let discussion of ticket.discussions">
                <div class="card d-flex mb-3">
                    <div class="d-flex flex-grow-1 min-width-zero">
                        <div
                            class="align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center card-body">
                            <a id="toggler1" href="javascript:;"
                                class="list-item-heading mb-0 truncate w-40 w-xs-100 mb-1 mt-1"><i
                                    class="heading-icon simple-icon-check"></i>
                                <span class="align-middle d-inline-block mr-2">
                                    {{discussion.author?.username == authservice.getUserName()?  'You' : discussion.author?.name }} 
                                </span>
                                <span *ngIf="discussion.author?.username != 'support_team'" class="badge badge-pill mr-1 mb-1 badge-success"> Support Team</span>
                            </a>
                            <p class="mb-1 text-muted text-small w-15 w-xs-100"
                                
                                >
                                <!-- tooltip="{{discussion.updated_at | date: 'medium'}}" -->
                                {{distanceInWordsAgo(discussion.updated_at)}}</p>
                        </div>
                    </div>


                    <div class="card-body pt-1">
                        <p class="mb-0">{{discussion.discussion}}</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- <ul class="col-12">
            <li  class="list-group-item col-12">
                <div>
                    <b>{{discussion.author?.name == 'Support'?  'You' : discussion.author?.name }}</b> :
                    {{discussion.discussion}} ({{distanceInWordsAgo(discussion.updated_at)}})
            </div>
        </li>

        </ul> -->
    </ng-container>

    <div class="form-group row mb-4 col-12">
        <!-- <label for="description" class="col-sm-2 col-form-label"><b>Description:</b></label> -->
        <div class="col-sm-12">
            <quill-editor [modules]="editorOptions" theme="snow" [disabled]="is_edit_form" [required]="true"
                [(ngModel)]="ticket.description_html" [placeholder]="'Please detail your issue or question'"
                id="description" name="description" [styles]="{height: '900px !important'}">
            </quill-editor>
            <!-- (onContentChanged)="onChangeContent($event)" <textarea name="description" #description="ngModel" [(ngModel)]="ticket.description" id="description"
            class="form-control" placeholder="Please detail your issue or question" style="height: 120px;"></textarea> -->
        </div>
    </div>

    <!-- <h6>Comments</h6>
    <ul class="list-group">
        <li *ngFor="let discussion of ticket.discussions" class="list-group-item">{{discussion.discussion}}</li>
    </ul> -->



    <div class="card-footer">
        <button type="button" class="btn btn-default" routerLink="/support-ticket-list" data-dismiss="modal"><i class="fa fa-times"></i> Discard</button>
        <button type="submit" class="btn btn-primary pull-right"><i class="fa fa-pencil"></i>
            {{is_edit_form==false? 'Create': 'Save'}}</button>
    </div>
    <!-- </div> -->
</form>


<!-- <input type="text" (keyup)="()" [(ngModel)]="markdownText"/> -->
<!-- <showdown [value]="markdownText"></showdown> -->