import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { AuthenticationService } from "./authentication.service";

@Injectable({ providedIn: "root" })
export class PurchaseorderService {
  branch_id: string;
  constructor(
    private http: ApiService,
    private authenticationService: AuthenticationService
  ) {
    this.branch_id = this.authenticationService.getDefaultBranchId();
  }

  addPurchaseorder(purchaseorder_data: any) {
    if (this.branch_id != "") {
      purchaseorder_data["branch_id"] = this.branch_id;
    }
    return this.http.post(
      ApiService.geturl("purchaseorder", "add"),
      purchaseorder_data
    );
  }
  editPurchaseorder(purchaseorder_data: any, id: string) {
    return this.http.post(ApiService.geturl("purchaseorder", "edit"), {
      data: purchaseorder_data,
      purchaseorder_id: id,
    });
  }
  deletePurchaseorder(id: string) {
    return this.http.post(ApiService.geturl("purchaseorder", "delete"), {
      id: id,
    });
  }

  viewPurchaseorder(id: string, condition: {}) {
    if (this.branch_id != "") {
      condition["branch_id"] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("purchaseorder", "view"), {
      purchaseorder_id: id,
      condition: condition,
    });
  }

  getPurchaseorders(
    filters: any = {},
    columns?: any,
    sort: any = {},
    page: any = {}
  ) {
    if (this.branch_id != "") {
      filters["branch_id"] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("purchaseorder", "viewall"), {
      filters: filters,
      columns: columns,
      sort: sort,
      page: page,
    });
  }
  createPayment(data) {
    return this.http.post(ApiService.geturl("payment", "add"), data);
  }
}
