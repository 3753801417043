<!-- <ng-template #formerrors let-formfield='error_details' let-field="fieldname"> -->
<!-- ---{{fieldDetails.errors|json}}--- -->

<div *ngIf="fieldDetails?.dirty || fieldDetails?.touched">
  <label class="error" *ngIf="fieldDetails.errors?.required"> {{fieldLabel}} is mandatory.</label>
  <label class="error" *ngIf="fieldDetails.errors?.number"> {{fieldLabel}} should be number.</label>

  <label class="error" *ngIf="fieldDetails.errors?.equal">{{fieldLabel}} Doesn't Match</label>
  <label class="error" *ngIf="fieldDetails.errors?.notEqual">{{fieldLabel}} error message</label>
  <!-- <label class="error" *ngIf="fieldDetails.errors?.equalTo">{{fieldLabel}} equalTo error</label> -->
  <label class="error" *ngIf="fieldDetails.errors?.equalTo">{{fieldLabel}} Doesn't Match</label>
  <label class="error" *ngIf="fieldDetails.errors?.min"> {{fieldLabel}} value should be greater than
    {{fieldDetails.errors.requiredValue}}</label>
  <label class="error" *ngIf="fieldDetails.errors?.max"> {{fieldLabel}} value should be lesser than
    {{fieldDetails.errors.requiredValue}}</label>
  <label class="error" *ngIf="fieldDetails.errors?.email">{{fieldLabel}} is not a valid email </label>
  <label class="error" *ngIf="fieldDetails.errors?.url">{{fieldLabel}} is not valid URL</label>
  <label class="error" *ngIf="fieldDetails.errors?.digits">{{fieldLabel}} is not a digit </label>
  <label class="error" *ngIf="fieldDetails.errors?.range">{{fieldLabel}} should be between
    {{fieldDetails.errors.requiredValue[0]}}
    and {{fieldDetails.errors.requiredValue[1]}}</label>
  <label class="error" *ngIf="fieldDetails.errors?.date">{{fieldLabel}} error message</label>
  <label class="error" *ngIf="fieldDetails.errors?.minDate">{{fieldLabel}} error message</label>
  <label class="error" *ngIf="fieldDetails.errors?.maxDate">{{fieldLabel}} error message</label>
  <label class="error" *ngIf="fieldDetails.errors?.dateISO">{{fieldLabel}}error message</label>
  <label class="error" *ngIf="fieldDetails.errors?.phone">{{fieldLabel}} error message</label>
  <label class="error" *ngIf="fieldDetails.errors?.json">{{fieldLabel}} error message</label>
  <label class="error" *ngIf="fieldDetails.errors?.pattern">{{fieldLabel}} pattern mismatch, Should be in
    {{fieldDetails.errors.pattern.requiredPattern}} </label>

</div>