<div class="auth">
  <form (ngSubmit)="login()" #signInForm="ngForm" novalidate>
    <div class="auth_left">
      <div class="card">
        <div class="text-center mb-2">
          <a class="header-brand" href="javascript:void(0);"><img src="{{image_path}}" style="width: 250px;"></a>
        </div>
        <div class="card-body">
          <div class="card-title">Login to your account</div>
          <div class="form-group">
            <input type="email" required class="form-control" #username="ngModel" [(ngModel)]="user.username"
              name="username" id="username" aria-describedby="usernameHelp" placeholder="Enter Username">
            <app-validation-errors [mformField]="username" label="Username">
            </app-validation-errors>
          </div>
          <div class="form-group">
            <label class="form-label">Password<a routerLink="/forgot_password" class="float-right small">I forgot
                password</a></label>
            <input type="password" required class="form-control mb-2" #password="ngModel" id="password"
              [(ngModel)]="user.password" name="password" placeholder="Password">
            <app-validation-errors [mformField]="password" label="Password">
            </app-validation-errors>
            <ngb-alert *ngIf="error" [dismissible]="false" class="alert alert-danger" type="alert.type">{{error}}
            </ngb-alert>
            <!-- <ngb-alert type="custom">User Does Not Match</ngb-alert> -->
          </div>
          <div class="form-group">
            <label class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" />
              <span class="custom-control-label">Remember me</span>
            </label>
          </div>
          <div class="form-footer">

            <button [disabled]="signInForm.invalid" class="btn btn-primary btn-block">Sign in</button>
          </div>
        </div>
        <div class="text-center text-muted">
          Don't have account yet? <a href="javascript:void(0);">Sign up</a>
        </div>
      </div>
    </div>
  </form>

  <div class="auth_right">
    <img src="assets/img/slider2.svg" class="img-fluid" style="height:100%;width: 100%;" alt="login page" />


  </div>
</div>

<!--<script src="../assets/bundles/lib.vendor.bundle.js"></script>
<script src="../assets/js/core.js"></script>-->