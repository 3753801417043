<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <!-- <form novalidate #searchJournalForm="ngForm" (ngSubmit)="getJournalsList(null)">
                <div class="card">
                    <div class="card-header d-flex align-items-center">


                        <h3 class="table-title">Journal List</h3>


                    </div>
                    <div class="card-body row">
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary">Search</button>
                        <button (click)="clearJournalFilters();" class="btn btn-warning">
                            Clear Search
                        </button>

                    </div>
                </div>
            </form> -->
            <div class="p-sm-2">
                <button *ngIf="this.companyDetails.fi_year.status!='Finalized'" class="btn btn-primary float-right mb-2" routerLink="/stockjournal/add">
                    <i class="icon icon-add"></i>Add Stock Journal
                </button>
            </div>

            <div class="card p-sm-0">
                <div class="card-body">

                <ngx-datatable [rows]="journalList" #myTable [loadingIndicator]="loadingIndicator" [externalPaging]="true"
                  [columnMode]="'force'" [limit]="page.pageSize" [count]="page.count" [offset]="page.offset"
                  (page)="getJournalsList($event)" [externalSorting]="true" (sort)="onSort($event)" [footerHeight]="50"
                  [rowHeight]="'auto'" class="material striped" [sorts]="this.dt_default_sort" [summaryRow]="'true'"
                  [summaryPosition]="'bottom'" [summaryHeight]="'auto'">

                  <ngx-datatable-column [width]="50" [resizeable]="false" [sortable]="false" [draggable]="false"
                    [canAutoResize]="false">
                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
                      <a href="javascript:void(0)" [class.datatable-icon-right]="!expanded"
                        [class.datatable-icon-down]="expanded" title="Expand/Collapse Row" (click)="toggleExpandRow(row)">
                      </a>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-row-detail [rowHeight]="'auto'" #myDetailRow (toggle)="onDetailToggle($event)">
                    <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
                      <div style="padding-left:35px;">
                        <table class="col-12 table table-hover table-vcenter table_custom text-nowrap spacing5 text-nowrap">
                          <thead>
                            <tr>
                              <th><strong>Product Head</strong></th>
                              <th><strong>In Qty</strong></th>
                              <th><strong>Out Qty</strong></th>
                              <th><strong>Item Value</strong></th>
                              <th><strong>Total Value</strong></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let journal_items of row.journal_items">
                              <td>{{journal_items.product[0]?.name}}</td>
                              <td>{{journal_items.in_qty}}</td>
                              <td>{{journal_items.out_qty}}</td>
                              <td>{{journal_items.item_value}}</td>
                              <td>{{journal_items.total_value}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </ng-template>
                  </ngx-datatable-row-detail>

                  <ngx-datatable-column *ngFor="let col of journalListColumns" [flexGrow]=" col.flexGrow" [name]="col.name"
                    [sortable]="col.sortable" [cellClass]="col.css_class" [prop]="col.prop">

                    <ng-template ngx-datatable-header-template let-sort="sortFn">
                      <span (click)="sort()">{{ col.name }}</span>
                    </ng-template>
                    <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                      <div [ngSwitch]="col.type">
                        <span *ngSwitchCase="'currency'"> {{row[col.prop]|currency:currency}}</span>
                        <span *ngSwitchCase="'percentage'">{{row[col.prop]}}%</span>
                        <span *ngSwitchCase="'action'">

                          <span *ngSwitchCase="'action'">
                            <div class="button-row">
                              <!-- <button class="btn btn-primary mr-1" *ngxPermissionsOnly="['Admin','Elixir - Admin', 'Elixir - Manager', 'Client - Admin', 'Client - Manager']" routerLink="/journal/edit/{{row._id}}" mat-mini-fab color="primary"
                                title="Edit">
                                <i class="fa fa-edit"></i>
                              </button> -->

                              <button class="btn btn-danger" *ngxPermissionsOnly="['Admin','Elixir - Admin', 'Elixir - Manager', 'Client - Admin', 'Client - Manager']" (click)="deleteJournal(row._id)" mat-mini-fab color="warn" title="Delete">
                                <i class="fa fa-trash"></i>
                              </button>
                            </div>

                          </span>


                        </span>
                        <span *ngSwitchCase="'object'"> {{ row[col.prop][col.field_name] }}</span>
                        <!-- <span *ngSwitchCase="'custom'"> {{ row[col.field_name] }}</span> -->
                        <span *ngSwitchCase="'custom'"> {{ row[col.field_name] }}</span>
                        <span *ngSwitchCase="'date'"> {{ row[col.prop]|date }}</span>

                        <span *ngSwitchCase="'status'"> <span class="badge badge-success">{{ row[col.prop]}}</span></span>
                        <span *ngSwitchDefault>
                          {{ row[col.prop] }}
                        </span>
                      </div>

                    </ng-template>
                  </ngx-datatable-column>

                </ngx-datatable>
              </div>
              </div>


            <!-- <div class="p-sm-5">

                <ngx-datatable [rows]="journalList" [columns]="journalListColumns" [loadingIndicator]="loadingIndicator"
                    [externalPaging]="true" [columnMode]="'force'" [limit]="page.limit" [count]="page.count"
                    [offset]="page.offset" (page)="getJournalsList($event)" [reorderable]="reorderable"
                     [footerHeight]="50" [rowHeight]="'auto'" class="material striped"
                    [summaryRow]="'true'" [summaryPosition]="'bottom'" [summaryHeight]="'auto'"></ngx-datatable>
            </div> -->
        </div>

    </div>
</div>

<!-- <ng-template #actionTmpl let-value="value">

    <div class="btn-group mr-3">
        <button class="btn btn-primary" routerLink="/journal/edit/{{value}}" type="button" rel="tooltip">Edit</button>
        <div class="btn-group" ngbDropdown placement="bottom-right" role="group">
            <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
            <div class="dropdown-menu" ngbDropdownMenu>
                <button class="dropdown-item" routerLink="/journal/edit/{{value}}" type="button"
                    rel="tooltip">Edit</button>
                <button class="dropdown-item " (click)="deleteJournal(value)">
                    <span class="text-red">
                        <i class="icon-trash-can3"></i> Delete
                    </span>
                </button>
            </div>
        </div>
    </div>



</ng-template> -->
