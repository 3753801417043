import { ReportsModule } from '../app/reports/reports.module';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, SecurityContext, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { JwtModule } from '@auth0/angular-jwt';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { CustomFormsModule } from 'ng2-validation';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ngfModule, ngf } from 'angular-file';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ColorPickerModule } from 'ngx-color-picker';

// Services
import { AuthGuard } from './_guards/auth.guard';
import { AuthenticationService } from './_services/authentication.service';
import { ApiService } from './_services/api.service';
import { UserService } from './_services/user.service';
import { ConstantService } from './_config/constants';
import { CompanyService } from './_services/company.service';
import { ProductService } from './_services/product.service';
import { CustomerService } from './_services/customer.service';
import { SalesorderService } from './_services/salesorder.service';
import { JournalService } from './_services/journal.service';
import { PaymenttransactionsService } from './_services/paymenttransactions.service';
import { TransactionService } from './_services/transaction.service';

import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { FooterComponent } from './layout/footer/footer.component';
import { LoginComponent } from './users/login/login.component';
import { CompanyAddComponent } from './components/company/company-add/company-add.component';
import { UserAddComponent } from './components/users/user-add/user-add.component';
import { PageTopbarComponent } from './layout/page-topbar/page-topbar.component';
import { ForgotPasswordComponent } from './users/forgot-password/forgot-password.component';
import { UnauthorizedComponent } from './users/unauthorized/unauthorized.component';
import { ValidationErrorsComponent } from './common/validation-errors/validation-errors.component';
// import { HttpInterceptorHandler } from './common/http.interceptors';
import { VendorsComponent } from './components/vendors/vendors.component';
import { VendorsAddComponent } from './components/vendors/vendors-add/vendors-add.component';
import { ProductAddComponent } from './components/product/product-add/product-add.component';
import { CustomerComponent } from './components/customer/customer.component';
import { CustomerAddComponent } from './components/customer/customer-add/customer-add.component';
// import { SalesOrderComponent } from "./components/salradhika@suquino.comes-order/sales-order.component";
import { SalesOrderComponent } from './components/sales-order/sales-order.component';
import { SalesOrderAddComponent } from './components/sales-order/sales-order-add/sales-order-add.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ProductComponent } from './components/product/product.component';
import { TestcollectionComponent } from './components/testcollection/testcollection.component';
import { TestcollectionAddComponent } from './components/testcollection/testcollection-add/testcollection-add.component';
import { HsnAddComponent } from './components/hsn/hsn-add/hsn-add.component';
import { HsnService } from './_services/hsn.service';
import { SequenceService } from './_services/sequence.service';
import { PurchaseorderService } from './_services/purchaseorder.service';
import { PurchaseorderAddComponent } from './components/purchaseorder/purchaseorder-add/purchaseorder-add.component';
import { PurchaseorderComponent } from './components/purchaseorder/purchaseorder.component';
import { HsnComponent } from './components/hsn/hsn.component';
import { PartyComponent } from './components/party/party.component';
import { DatalyticsAngularModule } from '@megamtech-labs/datalytics';
// import { DatalyticsAngularModule } from '../../../../datalytics_new/src/datalytics-angular/lib/datalytics-angular.module';

// import {DatalyticsAngularModule} from 'datalytics-angular/dist';



// import { TreeModule } from 'ng2-tree';

// Pipes
import { FilterPipe } from './_pipes/filter.pipe';
import { JoinPipe } from './_pipes/mjoin.pipe';
import {
  TxTypePipe,
  CreateLinkPipe,
  ToByPipe,
  VoucherTypePipe,
} from './_pipes/tx_type.pipe';
import { JournalAddComponent } from './components/journal/journal-add/journal-add.component';
import { JournalComponent } from './components/journal/journal.component';
import { CostgroupComponent } from './masters/costgroup/costgroup.component';
import { CostgroupAddComponent } from './masters/costgroup/costgroup-add/costgroup-add.component';
import { CostgroupService } from './_services/costgroup.service';
import { PaymenttransactionsComponent } from './components/paymenttransactions/paymenttransactions.component';
import { ContraComponent } from './contra/contra.component';
import { ContraViewComponent } from './contra/contra-view/contra-view.component';
import { ContraAddComponent } from './contra/contra-add/contra-add.component';
import { ContraService } from './_services/contra.service';
import { ExpenseComponent } from './components/expense/expense.component';
import { ExpenseAddComponent } from './components/expense/expense-add/expense-add.component';
import { ExpenseService } from './_services/expense.service';
import { BillComponent } from './components/bill/bill.component';
import { BillAddComponent } from './components/bill/bill-add/bill-add.component';
import { GstComponent } from './gst/gst.component';
import { GstViewComponent } from './gst/gst-view/gst-view.component';
import { GstAddComponent } from './gst/gst-add/gst-add.component';
import { GstService } from './_services/gst.service';
import { PaymentModalComponent } from './components/payment-modal/payment-modal.component';
import { MapModalComponent } from './components/map-modal/map-modal.component';
import { HomeComponent } from './components/home/home.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { InvoiceAddComponent } from './components/invoice/invoice-add/invoice-add.component';
import { CoaComponent } from './components/coa/coa.component';
import { ReceiptsComponent } from './components/receipts/receipts.component';
import { ReceiptsVendorComponent } from './components/receipts-vendor/receipts-vendor.component';
import { ReceiptsCustomerComponent } from './components/receipts-customer/receipts-customer.component';
import { ReceiptsCustomerAddComponent } from './components/receipts-customer/receipts-customer-add/receipts-customer-add.component';
import { ReceiptsVendorAddComponent } from './components/receipts-vendor/receipts-vendor-add/receipts-vendor-add.component';
import { ReceiptsAddComponent } from './components/receipts/receipts-add/receipts-add.component';
import { StockJournalAddComponent } from './components/stock-journal/stock-journal-add/stock-journal-add.component';
import { ProductGroupComponent } from './components/product/product-group/product-group.component';
import { ProductGroupAddComponent } from './components/product/product-group-add/product-group-add.component';
// import { StockJournalComponent } from './com{onents/stock-journal/stock-journal.component';
// import { CreditBillComponent } from './components/credit-bill/credit-bill.component';

import { ExpenseViewComponent } from './components/expense/expense-view/expense-view.component';
import { CreditBillComponent } from './components/credit-bill/credit-bill.component';
import { CreditBillService } from '../app/_services/credit_bill.service';
import { DebitBillComponent } from './components/debit-bill/debit-bill.component';
import { DebitBillService } from '../app/_services/debit-bill.service';
import { BillViewComponent } from './components/bill/bill-view/bill-view.component';
import { InvoiceViewComponent } from './components/invoice/invoice-view/invoice-view.component';
import { PurchaseorderViewComponent } from './components/purchaseorder/purchaseorder-view/purchaseorder-view.component';
import { SalesorderViewComponent } from './components/sales-order/sales-order-view/sales-order-view.component';
// import { StocktransactionsViewComponent } from './components/stocktransactions/stocktransactions-view/stocktransactions-view.component';
import { StocktransactionsComponent } from './components/stocktransactions/stocktransactions.component';
import { StockJournalComponent } from './components/stock-journal/stock-journal.component';
import { UsersComponent } from './components/users/users.component';
import { UsersViewComponent } from './components/users/users-view/users-view.component';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { UnitsComponent } from './components/units/units.component';
import { UnitsAddComponent } from './components/units/units-add/units-add.component';
import { ExpensePaymentComponent } from './components/expense-payment/expense-payment.component';
import { ReceiptPaymentComponent } from './components/receipt-payment/receipt-payment.component';
import { LedgersComponent } from './components/coa/ledgers/ledgers.component';
import { AddressTemplateComponent } from './common/address-template/address-template.component';
import { SupportTicketComponent } from './users/support-ticket/support-ticket.component';
import { QuillModule } from 'ngx-quill';
import { MarkdownModule } from 'ngx-markdown';
import { DatafilterPipe } from './_pipes/datafilter.pipe';
import { SupportTicketListComponent } from './users/support-ticket-list/support-ticket-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTreeModule } from '@angular/material/tree';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { ReverseStringPipe } from './reverse-string.pipe';
import { BillLedgerComponent } from './components/bill-ledger/bill-ledger.component';
import { BillRegisterComponent } from './components/bill-register/bill-register.component';
import { SalesRegisterComponent } from './components/sales-register/sales-register.component';
import { CashbookComponent } from './components/account-books/cashbook/cashbook.component';
import { BankbookComponent } from './components/account-books/bankbook/bankbook.component';
import { CreditBillViewComponent } from './components/credit-bill/credit-bill-view/credit-bill-view.component';
import { DebitBillViewComponent } from './components/debit-bill/debit-bill-view/debit-bill-view.component';
import { FixedAssetsComponent } from './fixed-assets/fixed-assets.component';
import { FixedassestsAddComponent } from './fixed-assets/fixed-assets-add/fixed_assests-add.component';
import { StrreplacePipe } from './_pipes/strreplace.pipe';
import { VoucherTypeComponent } from './voucher-type/voucher-type.component';
import { VoucherTypeViewComponent } from './voucher-type/voucher-type-view/voucher-type-view.component';
import { VoucherTypeAddComponent } from './voucher-type/voucher-type-add/voucher-type-add.component';
import { FinancialYearsComponent } from './components/financial-years/financial-years.component';
import { FileuploadComponent } from './components/fileupload/fileupload.component';
import { ReceiptvendorViewComponent } from './components/receipts-vendor/receipts-vendor-view/receipts-vendor-view.component';
import { ngxDaterangepicker } from '@megamtech-opensource/ngx-datepicker';
import { Router } from "@angular/router";
// import * as Sentry from "@sentry/angular";
import { ChangePasswordComponent } from './components/change-password/change-password.component';
// import { NgxDaterangepicker } from 'ngx-daterangepicker-material';
export function jwtTokenGetter() {
  return localStorage.getItem(ConstantService.TOKEN_NAME);
}

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    LoginComponent,
    CompanyAddComponent,
    UserAddComponent,
    PageTopbarComponent,
    ForgotPasswordComponent,
    UnauthorizedComponent,
    ValidationErrorsComponent,
    VendorsComponent,
    VendorsAddComponent,
    ProductAddComponent,
    CustomerComponent,
    CustomerAddComponent,
    SalesOrderComponent,
    SalesOrderAddComponent,
    ProductComponent,
    HsnAddComponent,
    PurchaseorderAddComponent,
    PurchaseorderComponent,
    TestcollectionAddComponent,
    TestcollectionComponent,
    HsnComponent,
    PartyComponent,
    FilterPipe,
    TxTypePipe,
    ToByPipe,
    JoinPipe,
    VoucherTypePipe,
    CreateLinkPipe,
    JournalAddComponent,
    JournalComponent,
    CostgroupComponent,
    CostgroupAddComponent,
    PaymenttransactionsComponent,
    ContraComponent,
    ContraViewComponent,
    ContraAddComponent,
    ExpenseComponent,
    ExpenseAddComponent,
    BillComponent,
    BillAddComponent,
    GstComponent,
    GstViewComponent,
    GstAddComponent,
    PaymentModalComponent,
    MapModalComponent,
    HomeComponent,
    InvoiceComponent,
    InvoiceAddComponent,
    CoaComponent,
    ReceiptsComponent,
    ReceiptsVendorComponent,
    ReceiptsCustomerComponent,
    ReceiptsCustomerAddComponent,
    ReceiptsVendorAddComponent,
    CreditBillComponent,
    DebitBillComponent,
    BillViewComponent,
    InvoiceViewComponent,
    PurchaseorderViewComponent,
    SalesorderViewComponent,
    // CreditBillComponent
    ExpenseViewComponent,
    StockJournalAddComponent,
    ProductGroupComponent,
    ProductGroupAddComponent,
    ReceiptsAddComponent,
    StockJournalComponent,
    // StocktransactionsViewComponent,
    StocktransactionsComponent,
    UsersComponent,
    UsersViewComponent,
    UnitsComponent,
    UnitsAddComponent,
    ExpensePaymentComponent,
    ReceiptPaymentComponent,
    LedgersComponent,
    AddressTemplateComponent,
    SupportTicketComponent,
    // DatafilterPipe,
    SupportTicketListComponent,
    ReverseStringPipe,
    BillLedgerComponent,
    BillRegisterComponent,
    SalesRegisterComponent,
    CashbookComponent,
    BankbookComponent,
    CreditBillViewComponent,
    DebitBillViewComponent,
    FixedAssetsComponent,
    FixedassestsAddComponent,
    StrreplacePipe,
    VoucherTypeComponent,
    VoucherTypeViewComponent,
    VoucherTypeAddComponent,
    FinancialYearsComponent,
    FileuploadComponent,
    ReceiptvendorViewComponent,
    ChangePasswordComponent
  ],
  imports: [
    // ngxDaterangepicker,
    QuillModule.forRoot(),
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    CustomFormsModule,
    NgSelectModule,
    NgxDatatableModule,
    ngfModule,
    ReportsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        whitelistedDomains: ConstantService.whiteListedURLS,
        skipWhenExpired: true,
      },
    }),
    NgxPermissionsModule.forRoot(),
    NgxPermissionsModule.forChild(),
    NgxWebstorageModule.forRoot(),
    AngularMyDatePickerModule,
    AngularEditorModule,
    NgbModule,
    MarkdownModule.forRoot(),
    BrowserAnimationsModule,
    MatTreeModule,
    MatIconModule,
    DatalyticsAngularModule,
    ColorPickerModule,
    ngxDaterangepicker.forRoot()
  ],
  entryComponents: [
    PaymentModalComponent,
    MapModalComponent,
    AddressTemplateComponent,
  ],
  // exports: [DatafilterPipe],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpInterceptorHandler,
    //   multi: true,
    // },
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: false,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => { },
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
    AuthGuard,
    AuthenticationService,
    ApiService,
    ConstantService,
    UserService,
    ProductService,
    CustomerService,
    SalesorderService,
    SequenceService,
    PurchaseorderService,
    HsnService,
    JournalService,
    CostgroupService,
    PaymenttransactionsService,
    ContraService,
    TransactionService,
    ExpenseService,
    GstService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: ( ps: NgxPermissionsService ) => function() {return ps.loadPermissions(data)}},
    //   deps: [NgxPermissionsService],
    //   multi: true
    // }
    // CreditBillService,
    // DebitBillService
  ],
  bootstrap: [LayoutComponent],
})
export class AppModule { }
