import {
  Component,
  OnInit,
  ChangeDetectorRef,
  TemplateRef,
  ViewChild,
  Input,
  AfterViewInit,
} from "@angular/core";
import swal from "sweetalert2";
import { BillService } from "@app/_services/bill.service";
import { AuthenticationService } from "@app/_services/authentication.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import {
  NgbModalConfig,
  NgbModal,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { MastersService } from "@app/_services/masters.service";
import { PaymentSettings } from "@app/_models/payment-settings.model";
import { InvoiceService } from "@app/_services/invoice.service";
import { ExpenseService } from "@app/_services/expense.service";
import { ReceiptsService } from "@app/_services/receipts.service";
import { DebitBillService } from "@app/_services/debit-bill.service";
import { CreditBillService } from "@app/_services/credit_bill.service";

import { ConstantService } from "@app/_config/constants";
import { AccountsHeadTemplate } from "@app/_models/accounts-head-template.model";
import { Customer } from "../../_models/customer";
import { Receipts } from "../../_models/receipts";
@Component({
  selector: "app-payment-modal",
  templateUrl: "./payment-modal.component.html",
  styleUrls: ["./payment-modal.component.scss"],
})
export class PaymentModalComponent implements OnInit {
  loading: boolean = false;
  @Input() selectedarray_data: any = [];
  invoice_array = [];
  sum_of_bal_qty: number = 0;
  check_status: boolean;
  transaction_head: string;
  paymentAccountConfig: AccountsHeadTemplate = new AccountsHeadTemplate();
  public payment = new PaymentSettings();
  pay_head: string;
  headList: any = [];
  dueDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  model: IMyDateModel = null;
  tx_date: IMyDateModel;

  //Common settings
  db_columns: any = {};
  payee: string;
  payee_name: string;
  balance_amt: number = 0;
  payType: any;
  payment_accountList: any = [];
  payment_methodList: any = [
    { name: "Cash", account_head: 1031 },
    { name: "Bank", account_head: 1030 },
    //   { name: "Check", id: 3 }
  ];
  company_id: any;
  payment_array: any = [];
  tx_ref_id: number;
  singlepayment_amount: number;
  payment_expense: { to_be_paid: number };
  ref_id: any;
  voucher_number: string;
  close_pay: boolean = true;

  advance: boolean = false;
  debi_or_credit: boolean = false;
  debit_payment_array: any = [];
  adv_payment_array: any = [];
  is_credit_or_debit: string = "";
  selected_debit_credit_values: any = [];
  advanced_values: any = [];
  adv_amt: number;
  debit_credit_amount: number;
  balance_amount: any;
  field_name: string = "Payment";
  currency: any;
  public sales_advance: Receipts = new Receipts();
  tx_type_id: number;
  // sales_advance: any;
  constructor(
    // private purchaseorderservice: PurchaseorderService,
    private billService: BillService,
    private invoiceService: InvoiceService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private masterService: MastersService,
    private expenseService: ExpenseService,
    private receiptsService: ReceiptsService,

    private debitService: DebitBillService,
    private creditService: CreditBillService,
    public ngbActiveModal: NgbActiveModal,
    private cd: ChangeDetectorRef,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.currency = this.authenticationService.getCompanyDetails()[
      "base_currency"
    ];
    let condition: any = [];
    condition["_id"] = this.selectedarray_data["bill_ids"];

    this.getBills(condition, this.selectedarray_data["payment_from"]);
    this.payType = this.selectedarray_data["payment_type"];

    if (
      this.selectedarray_data["is_booking"] &&
      this.selectedarray_data["is_booking"] == 2
    ) {
      this.close_pay = false;

      setTimeout(() => {
        this.payPurchaseOrder();
      }, 3000);
    }
  }

  getPaymentInChange() {
    this.getPaymentCoaHead("").subscribe((result) => {
      this.payment_accountList = result["response"];
    });
  }
  getPaymentCoaHead(searchString) {
    this.payment_accountList = [];
    return this.masterService.getCoaHead(this.company_id, searchString, {
      in: [this.payment.payment_method["name"]],
    });
  }
  payPurchaseOrder() {
    if (this.balance_amount < 0 && this.payee == 'Sales') {
      this.sales_advance.company_id = this.company_id;
      this.sales_advance["party"] = {
        name: this.payment.party_id[0].vendor_name,
        id: this.payment.party_id[0].vendor_id,
        coa_id: this.payment.party_id[0].vendor_coa_id
      };
      this.sales_advance.payment_date = this.tx_date;
      this.sales_advance.advance_amount = Math.abs(this.balance_amount);
      this.sales_advance.type = "customer";
      this.sales_advance.payment_account = this.payment.payment_account;
      this.sales_advance.payment_method = this.payment.payment_method;
      this.sales_advance.tx_type_id = 61;
      this.receiptsService.addCustomerReceipts(this.sales_advance).subscribe((result) => { });
    }
    this.loading = true;
    if (this.payment_array.length > 1) {
      this.sum_of_bal_qty = this.payment_array.reduce(function (r, a) {
        return r + a["to_be_paid"];
      }, 0);

      if (this.sum_of_bal_qty == this.payment.total_payment_amount) {
        this.check_status = true;
      } else {
        this.check_status = false;
      }
    } else {
      this.sum_of_bal_qty = this.singlepayment_amount;

      this.check_status = true;
      this.payment.balance_amount = this.balance_amount;


      this.payment_array[0]["to_be_paid"] = 0;
      this.payment_array[0]["to_be_paid"] =
        this.payment.paying_amount +
        this.payment.total_adv_amount +
        this.payment.total_debit_credit_amount;

    }

    if (this.check_status) {
      this.payment.company_id = this.company_id;
      this.payment.payment_array = this.payment_array;
      this.payment.tx_date = this.tx_date["singleDate"]["jsDate"];
      this.payment.tx_type = this.tx_ref_id;
      this.payment.total_payment_amount = this.sum_of_bal_qty;
      this.payment.ref_id = this.ref_id;
      this.payment.advance_array = this.advanced_values;
      this.payment.debitcredit_array = this.selected_debit_credit_values;
      this.payment.is_credit_or_debit = this.is_credit_or_debit;
      this.payment.voucher_number = this.voucher_number;

      if (this.selectedarray_data.is_direct_payment) {
        this.payment.order_id = this.payment_array[0]["order_id"];
        this.addExpenseDirectPayment();
      } else {

        this.billService.createPayment(this.payment).subscribe((result) => {
          if (result["statusCode"] == 200) {
            this.loading = false;
            if (this.advance && this.advanced_values.length > 0) {
              this.receiptsService
                .updateAdvance(this.advanced_values, "vendor")
                .subscribe((result) => {
                  console.log("===result", result);
                });
            }
            if (
              this.debi_or_credit &&
              this.selected_debit_credit_values.length > 0
            ) {
              if (this.is_credit_or_debit == "credit") {
                this.creditService
                  .updateCredit(this.selected_debit_credit_values, "credit")
                  .subscribe((result) => {
                    console.log("===result", result);
                  });
              } else if (this.is_credit_or_debit == "debit") {
                this.debitService
                  .updateDebit(this.selected_debit_credit_values, "debit")
                  .subscribe((result) => {
                    console.log("===result", result);
                  });
              }
            }
            this.ngbActiveModal.close({ message: "Payment Successful", status_code: 1, });
            swal("Success!", "Payment Successful.", "success");
          } else {
            this.ngbActiveModal.close({ message: "Payment Unsuccessful", status_code: 0, });
            swal("Error!", "Payment Unsuccessful.", "error");
          }
        });
      }
    } else {
      swal("Error!", "The amount doesnt tally during split up.", "error");
    }
  }

  getBills(bill_ids, payment_from) {
    if (payment_from == "Purchase") {
      this.payee = "Purchase";

      this.db_columns["bill_items"] = 1;
      this.db_columns["vendor"] = 1;
      this.db_columns["currency"] = 1;
      this.db_columns["balance_amount"] = 1;
      this.db_columns["company_id"] = 1;
      this.db_columns["billing_date"] = 1;
      this.db_columns["purchaseorder_id"] = 1;
      this.db_columns["bill_number"] = 1;
      this.db_columns["purchase_order_number"] = 1;
      this.db_columns["purchase_order_date"] = 1;
      this.db_columns["total_tax_amount"] = 1;

      this.billService
        .getSelectedBills(bill_ids, this.db_columns)
        .subscribe((result) => {
          this.payee_name = result[0]["vendor"]["vendor_name"];
          this.balance_amt = this.getBalanceAmount(result, "balance_amt");
          this.payment.total_payment_amount = this.balance_amt;
          this.singlepayment_amount = this.balance_amt;
          this.balance_amount = this.balance_amt;
          this.payment.total_tax = this.getBalanceAmount(result, "tax_amt");
          this.payment_array = this.getPaymentArray(result, payment_from);
          this.payment.paying_amount = 0;

          this.getAdvanceAmount(result[0], "vendor");
          this.getDebitOrCreditAmount(result[0], "debit");
          this.is_credit_or_debit = "debit";
          this.changeAmount();
          this.selectedarray_data["payment_date"] = this.selectedarray_data["payment_date"] ? this.selectedarray_data["payment_date"] : new Date();
          this.tx_date = {
            isRange: false,
            singleDate: {
              jsDate: new Date(this.selectedarray_data["payment_date"]),
            },
          };
        });
    } else if (payment_from == "Expense") {
      this.payee = "Expense Payment";

      this.db_columns["expense_items"] = 1;
      this.db_columns["payee"] = 1;
      this.db_columns["balance_amount"] = 1;
      this.db_columns["total_expense_amount"] = 1;
      this.db_columns["company_id"] = 1;

      this.db_columns["payment_date"] = 1;

      this.expenseService
        .getSelectedExpense(bill_ids, this.db_columns)
        .subscribe((result) => {
          if (result[0]["payee"]) {
            this.payee_name = result[0]["payee"]["name"];
          }
          if (this.selectedarray_data.is_direct_payment == true) {
            this.balance_amt = result[0]["total_expense_amount"];
          } else {
            this.balance_amt = this.getBalanceAmount(result, "balance_amt");
          }

          this.payment.total_payment_amount = this.balance_amt;
          this.singlepayment_amount = this.balance_amt;
          this.balance_amount = this.balance_amt;
          this.payment.paying_amount = 0;

          this.payment.total_tax = this.getBalanceAmount(result, "tax_amt");
          if (this.selectedarray_data.is_direct_payment) {
            this.payment.is_direct_payment = this.selectedarray_data.is_direct_payment;
          }
          this.payment_array = this.getPaymentArray(result, payment_from);

          this.changeAmount();
          this.selectedarray_data["payment_date"] = this.selectedarray_data["payment_date"] ? this.selectedarray_data["payment_date"] : new Date();
          this.tx_date = {
            isRange: false,
            singleDate: {
              jsDate: new Date(this.selectedarray_data["payment_date"]),
            },
          };
          if (this.selectedarray_data["is_booking"] == 2) {
            this.payment.payment_method = this.selectedarray_data[
              "payment_method"
            ];
            this.payment.payment_account = this.selectedarray_data[
              "payment_account"
            ];
            this.payment.ref_id = this.selectedarray_data["ref_id"];

          }
        });
    } else if (payment_from == "Receipt") {
      this.payee = "Receipt";

      this.db_columns["receipt_items"] = 1;
      this.db_columns["payee"] = 1;
      this.db_columns["balance_amount"] = 1;
      this.db_columns["company_id"] = 1;
      this.db_columns["payment_date"] = 1;

      this.receiptsService
        .getSelectedReceipt(bill_ids, this.db_columns)
        .subscribe((result) => {
          this.payee_name = result[0]["payee"]["name"];
          this.balance_amt = this.getBalanceAmount(result, "balance_amt");
          this.payment.total_payment_amount = this.balance_amt;
          this.singlepayment_amount = this.balance_amt;
          this.balance_amount = this.balance_amt;
          this.payment.paying_amount = 0;


          this.payment.total_tax = this.getBalanceAmount(result, "tax_amt");
          this.payment_array = this.getPaymentArray(result, payment_from);
          this.selectedarray_data["payment_date"] = this.selectedarray_data["payment_date"] ? this.selectedarray_data["payment_date"] : new Date();
          this.tx_date = {
            isRange: false,
            singleDate: {
              jsDate: new Date(this.selectedarray_data["payment_date"]),
            },
          };
          if (this.selectedarray_data["is_booking"] == 2) {
            this.payment.payment_method = this.selectedarray_data[
              "payment_method"
            ];
            this.payment.payment_account = this.selectedarray_data[
              "payment_account"
            ];
            this.payment.ref_id = this.selectedarray_data["ref_id"];

          }
        });
    } else if (payment_from == "Sales") {
      this.payee = "Sales";
      this.field_name = "Receipt";

      this.db_columns["invoice_items"] = 1;
      this.db_columns["customer"] = 1;
      this.db_columns["currency"] = 1;
      this.db_columns["balance_amount"] = 1;
      this.db_columns["company_id"] = 1;
      this.db_columns["invoice_date"] = 1;
      this.db_columns["sales_order_id"] = 1;
      this.db_columns["invoice_number"] = 1;
      this.db_columns["sales_order_number"] = 1;
      this.db_columns["sales_order_date"] = 1;
      this.db_columns["rounded_balance"] = 1;
      this.db_columns["round_value"] = 1;
      this.db_columns["round_of"] = 1;

      this.invoiceService
        .getSelectedInvoices(bill_ids, this.db_columns)
        .subscribe((result) => {
          this.payee_name = result[0]["customer"]["customer_name"];
          this.balance_amt = this.getBalanceAmount(result, "balance_amt");
          this.singlepayment_amount = this.balance_amt;
          this.payment.total_payment_amount = this.balance_amt;
          this.balance_amount = this.balance_amt;
          this.payment.paying_amount = 0;

          this.payment.total_tax = this.getBalanceAmount(result, "tax_amt");
          this.payment_array = this.getPaymentArray(result, payment_from);
          this.getAdvanceAmount(result[0], "customer");
          this.getDebitOrCreditAmount(result[0], "credit");
          this.is_credit_or_debit = "credit";
          this.changeAmount();
          this.selectedarray_data["payment_date"] = this.selectedarray_data["payment_date"] ? this.selectedarray_data["payment_date"] : new Date();
          this.tx_date = {
            isRange: false,
            singleDate: {
              jsDate: new Date(this.selectedarray_data["payment_date"]),
            },
          };
        });
    }
    this.payment.total_payment_amount = this.balance_amt;
  }
  getBalanceAmount(data, amt_type) {
    let amount = 0;
    if (amt_type == "balance_amt") {
      amount = data.reduce((sum, current) => sum + current.balance_amount, 0);
    } else if (amt_type == "tax_amt") {
      amount = data.reduce((sum, current) => sum + current.total_tax_amount, 0);
    }
    return amount;
  }
  getPaymentArray(data, payment_from) {
    this.payment_array = [];
    this.payment.currency = data[0]["currency"];
    if (payment_from == "Purchase") {
      this.tx_ref_id = 1;
      this.payment.party_id = [
        {
          vendor_id: data[0]["vendor"]["_id"],
          vendor_name: data[0]["vendor"]["vendor_name"],
          vendor_coa_id: data[0]["vendor"]["coa_data"]['id'],

        },
      ];
      data.forEach((element) => {
        this.payment_array.push({
          order_number: element.purchase_order_number,
          order_date: element.purchase_order_date,
          order_id: element._id,
          amount: element.balance_amount,
          to_be_paid: element.balance_amount,
          bill_number: element.bill_number,
        });
      });
    } else if (payment_from == "Sales") {
      this.tx_ref_id = 11;
      this.payment.party_id = [
        {
          vendor_id: data[0]["customer"]["_id"],
          vendor_name: data[0]["customer"]["customer_name"],
          vendor_coa_id: data[0]["customer"]["coa_data"]['id'],
        },
      ];
      data.forEach((element) => {
        this.payment_array.push({
          order_number: element.sales_order_number,
          order_date: element.sales_order_date,
          order_id: element._id,
          amount: element.balance_amount,
          to_be_paid: element.balance_amount,
          bill_number: element.invoice_number,
        });
      });
    } else if (payment_from == "Expense") {
      this.tx_ref_id = 51;
      if (!this.selectedarray_data.is_direct_payment) {
        this.payment.party_id = [
          {
            vendor_id: data[0]["payee"]["_id"],
            vendor_name: data[0]["payee"]["flattened_data"]["name"],
            ledger: data[0]["payee"]["flattened_data"],
          },
        ];
      } else {
        this.payment["expense_items"] = data[0]["expense_items"];
      }
      data.forEach((element) => {
        this.payment_array.push({
          //  order_number: element.sales_order_number,
          order_date: element.payment_date,
          order_id: element._id,
          amount: element.balance_amount,
          to_be_paid: element.balance_amount,
          //   bill_number: element.invoice_number
        });
      });
    } else if (payment_from == "Receipt") {
      this.tx_ref_id = 111;
      this.payment.party_id = [
        {
          party_id: data[0]["payee"]["_id"],
          party_name: data[0]["payee"]["flattened_data"]["name"],
          ledger: data[0]["payee"],
        },
      ];
      data.forEach((element) => {
        this.payment_array.push({
          //  order_number: element.sales_order_number,
          order_date: element.payment_date,
          order_id: element._id,
          amount: element.balance_amount,
          to_be_paid: element.balance_amount,
          //   bill_number: element.invoice_number
        });
      });
    }
    return this.payment_array;
  }
  updatePaymentAccountLedger(accountHead: any) {
    this.payment.payment_account = accountHead;
  }

  setPaymentAccountHeaderConfig() {
    if (this.payment.payment_method.account_head) {
      this.paymentAccountConfig = {
        accountsHeads: [],
        defualt_value: null,
        is_required: null,
        field_name: null,
        label_name: null,
        is_set: false,
        filter_type: 'in'
      };
      this.paymentAccountConfig.accountsHeads = [
        this.payment.payment_method.account_head
      ];
      this.paymentAccountConfig.is_required = true;
      this.paymentAccountConfig.field_name = "payment_account";
      this.paymentAccountConfig.label_name = "Payment Account"
      this.payment.payment_method.account_head == 1031
        ? "Cash Accounts"
        : "Bank Accounts";
    }
  }
  addExpenseDirectPayment() {
    this.loading = true;
    this.payment["expense_from"] = "expense";
    this.billService
      .createExpensePayment(this.payment)
      .subscribe((result_payment) => {
        if (result_payment["statusCode"] == 200) {
          this.loading = false;
          this.ngbActiveModal.dismiss();
          this.expenseService
            .editExpense(
              { voucher_number: this.voucher_number },
              this.selectedarray_data.ref_id
            )
            .subscribe((result) => { });
          swal(
            "Expense payment done sucessfully!",
            "expense added.",
            "success"
          );
        } else {
          swal("Can't create payment for expense", "expense error.", "error");
        }
      });
  }
  getAdvanceAmount(data, type) {

    let condition = {};
    condition["$or"] = [];
    condition["company_id"] = this.company_id;
    condition["$or"] = Array(
      { "advance_status": { $eq: 1 } },
      { "advance_status": { $eq: 2 } }
    );

    if (type == "vendor") {
      condition["party_id"] = data["vendor"]["_id"];
      this.receiptsService.getVendorsAdvance(condition).subscribe((result) => {
        this.adv_payment_array = result["response"]["data"];
        // if (result["response"]["data"].length > 0) {
        //   this.advance = true;
        //   this.adv_payment_array.forEach((element, index) => {
        //     element.advance_selected = true;
        //     this.selectedAdvance(element, index, true);
        //   });

        // }

      });
    } else if (type == "customer") {
      condition["party_id"] = data["customer"]["_id"];
      this.receiptsService.getCustomersAdvance(condition).subscribe((result) => {
        this.adv_payment_array = result["response"]["data"];
        // if (result["response"]["data"].length > 0) {
        //   this.advance = true;
        //   this.adv_payment_array.forEach((element, index) => {
        //     element.advance_selected = true;
        //     this.selectedAdvance(element, index, true);
        //   });

        // }
      });
    }

  }

  getDebitOrCreditAmount(data, type) {

    let condition = {};
    condition["company_id"] = this.company_id;

    if (type == "debit") {
      
      condition["vendor_id"] = data["vendor"]["_id"];
      //condition["bill_id"] = data["_id"];
      this.debitService.getDebitNotes(condition).subscribe((result) => {
        this.debit_payment_array = result["response"]["data"];
        if (result["response"]["data"].length > 0) {
          this.debi_or_credit = true;
          this.debit_payment_array.forEach((element, index) => {
            element.checkbox_debit_credit_select = true;
            this.selectedDebitCredit(element, index, true);
          });
        }
      });
    } else if (type == "credit") {
      
      condition["customer_id"] = data["customer"]["_id"];
     // condition["invoice_id"] = data["_id"];
      this.creditService.getCreditNotes(condition).subscribe((result) => {
        this.debit_payment_array = result["response"]["data"];
        if (result["response"]["data"].length > 0) {
          this.debi_or_credit = true;

          this.debit_payment_array.forEach((element, index) => {
            element.checkbox_debit_credit_select = true;
            this.selectedDebitCredit(element, index, true);
          });
        }
      });
    }
  }

  selectedDebitCredit(data, i, val) {
    if (this.debi_or_credit) {
      if (val != 2) {
        if (val) {
          this.selected_debit_credit_values.push(data);
        } else {
          let index = this.selected_debit_credit_values.indexOf(data);
          this.selected_debit_credit_values.splice(index, 1);
        }
      } else {
        let index = this.selected_debit_credit_values.indexOf(data);
        this.selected_debit_credit_values.splice(index, 1, data);
      }
    } else {
      this.selected_debit_credit_values = [];
    }
    this.changeAmount();
  }
  selectedAdvance(data, i, val) {
    if (this.advance) {
      if (val != 2) {
        if (val) {
          this.advanced_values.push(data);
        } else {
          let index = this.advanced_values.indexOf(data);
          this.advanced_values.splice(index, 1);
        }
      } else {
        let index = this.advanced_values.indexOf(data);
        this.advanced_values.splice(index, 1, data);
      }
    } else {
      this.advanced_values = [];
    }
    this.changeAmount();
  }

  changeAmount() {
    this.adv_amt = 0;
    this.payment.total_adv_amount = 0;
    this.debit_credit_amount = 0;
    this.payment.total_debit_credit_amount = 0;
    this.payment.total_payment_amount = this.balance_amt;
    this.singlepayment_amount = this.balance_amt;
    this.payment.is_advance = this.advance;
    this.payment.is_credit_debit = this.debi_or_credit;

    this.balance_amount = this.balance_amt.toFixed(2);
    if (this.advance) {
      this.advanced_values.forEach((element) => {
        this.adv_amt += Number(element.advance_amount);
      });
      if (this.payment.paying_amount == undefined) {
        this.payment.paying_amount = 0;
      }
      this.payment.total_adv_amount = this.adv_amt;
      this.balance_amount =
        this.balance_amount -
        (Number(this.payment.paying_amount) + this.adv_amt);
    }
    if (this.debi_or_credit) {
      this.selected_debit_credit_values.forEach((element) => {
        this.debit_credit_amount += Number(element.balance_amount);
      });

      if (this.payment.paying_amount == undefined) {
        this.payment.paying_amount = 0;
      }
      this.payment.total_debit_credit_amount = this.debit_credit_amount;
      this.balance_amount =
        (this.balance_amount -
          (Number(this.payment.paying_amount) + this.debit_credit_amount)).toFixed(2);
    }

    if (!this.advance && !this.debi_or_credit) {
      this.balance_amount =
        (this.balance_amount - Number(this.payment.paying_amount)).toFixed(2);
    }
  }
  check_adv_status(status) {
    if (status == false) {
      // this.changeAmount();
      this.changeBalanceAmount(this.payment.paying_amount);

      this.adv_payment_array.forEach((element) => {
        element.advance_selected = false;
      });
      this.advanced_values = [];
    }
  }
  check_debit_status(status) {
    if (status == false) {
      // this.changeAmount();
      this.changeBalanceAmount(this.payment.paying_amount);
      this.debit_payment_array.forEach((element) => {
        element.checkbox_debit_credit_select = false;
      });
      this.selected_debit_credit_values = [];
    }
  }
  changeBalanceAmount(value) {
    if (value == null || value == "") {
      this.payment.paying_amount = 0;
    }

    let inputType = 1; //Amount type from the paying_amount text box
    this.changeAmount();
  }
}
