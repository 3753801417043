import { Injectable } from "@angular/core";
import { ApiService } from "../_services/api.service";

@Injectable({
  providedIn: "root",
})
export class ReceiptsService {
  branch_id: string;
  constructor(private http: ApiService) {}

  addReceipt(receipts) {
    return this.http.post(ApiService.geturl("receipts", "add"), receipts);
  }
  createReceiptPayment(receipts) {
    return this.http.post(
      ApiService.geturl("payment", "addReceiptPayment"),
      receipts
    );
  }

  createPaymentReceipt(receipts) {
    return this.http.post(
      ApiService.geturl("payment", "add_receipt_payment"),
      receipts
    );
  }

  getVendorsAdvance(
    filters: any = {},
    columns: any = {},
    sort: any = {},
    page: any = {}
  ) {
    filters["type"] = "vendor";
    
    return this.http.post(ApiService.geturl("advance", "party_viewall"), {
      filters: filters,
      columns: columns,
      sort: sort,
      page: page,
    });
  }
  getCustomersAdvance(
    filters: any = {},
    columns: any = {},
    sort: any = {},
    page: any = {}
  ) {
    filters["type"] = "customer";
    return this.http.post(ApiService.geturl("advance", "party_viewall"), {
      filters: filters,
      columns: columns,
      sort: sort,
      page: page,
    });
  }
  book_pay(data) {
    return this.http.post(ApiService.geturl("invoice", "book_pay"), data);
  }
  book_vendor_pay(data) {
    return this.http.post(ApiService.geturl("bill", "advance_payandmap"), data);
  }
  addCustomerReceipts(advance) {
    return this.http.post(ApiService.geturl("advance", "sales_advance_add"), {
      advance: advance,
    });
  }
  addVendorReceipts(advance) {
    return this.http.post(
      ApiService.geturl("advance", "purchase_advance_add"),
      {
        advance: advance,
      }
    );
  }
  viewPurchaseAdvance(id: string, condition: {}) {
    if (this.branch_id != "") {
      condition["branch_id"] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("advance", "view"), {
      receipt_order_id: id,
      condition: condition,
    });
  }

  getExpenses(
    filters: any = {},
    columns?: any,
    sort: any = {},
    page: any = {}
  ) {
    return this.http.post(ApiService.geturl("receipts", "viewall"), {
      filters: filters,
      columns: columns,
      sort: sort,
      page: page,
    });
  }
  getSelectedReceipt(bill_ids, columns) {
    return this.http.post(
      ApiService.geturl("receipttransaction", "getAllReceipt"),
      {
        filters: bill_ids["_id"],
        columns: columns,
      }
    );
  }
  updateAdvance(advance_ids, type) {
    return this.http.post(ApiService.geturl("advance", "update_advance"), {
      advance_id: advance_ids,
      advance_type: type,
    });
  }
  cancelAdvance(row_data) {
    return this.http.post(ApiService.geturl("advance", "cancel_advance"), {
      data: row_data,
    });
  }
  confirmReceipt(receipt) {
    return this.http.post(ApiService.geturl("receipts", "confirm_receipt"), {
      data: receipt,
    });
  }
  confirmPurchaseAdvance(row_data,company_id) {
    return this.http.post(ApiService.geturl("advance", "confirm_advance"), {
      data: row_data,
      company_id:company_id
    });
  }
  deleteReceipt(receiptId,company_id){
    return this.http.post(ApiService.geturl("receipts", "delete_receipt"), {
      data: receiptId,
      company_id:company_id
    });
  }
  viewreceipt(condition){
    return this.http.post(ApiService.geturl("receipts", "view"), condition);
  }
  editreceipt(receiptData,receipt_id){
    return this.http.post(ApiService.geturl("receipts", "edit"), {
      data: receiptData,
      receiptId : receipt_id
    });
  }
}
