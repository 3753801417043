import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import swal from "sweetalert2";
import { ExpenseService } from "../../_services/expense.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { CustomerService } from "../../_services/customer.service";
import { Expense } from "../../_models/expense";
// import { CustomersService } from '../../../app/_services/customers.service';
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { MastersService } from "../../_services/masters.service";
import { CompanyService } from "../../_services/company.service";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { ConstantService } from "src/app/_config/constants";
import { CompileShallowModuleMetadata } from "@angular/compiler";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-expense-payment",
  templateUrl: "./expense-payment.component.html",
  styleUrls: ["./expense-payment.component.scss"],
})
export class ExpensePaymentComponent implements OnInit {
  id: string;
  filters: any = {};
  payment_accountList: any = [];
  payment_methodList: any = [
    { name: "Cash", id: 1 },
    { name: "Bank", id: 2 },
  ];
  is_edit_form: boolean = false;
  sort: any = { created_at: -1 };
  // dt_default_sort = [{ prop: 'costcategory_name', dir: 'asc' }, { prop: 'under_cc_category', dir: 'asc' }];
  columns: any = {};
  page: any = { offset: 0, limit: 20 };
  expense: Expense = new Expense();
  expenseList: any = [];
  payeeList: any = [];
  payeeListTypeahead = new EventEmitter<string>();
  payment_dateDpOptions: IAngularMyDpOptions = {
    dateRange: true,
    dateFormat: ConstantService.DateFormat,
  };
  expenseitemsArray: any = [];
  company_id: string;
  accountHeadListTypeahead = new EventEmitter<string>();
  expenseListTypeahead = new EventEmitter<string>();
  company_details: any;
  ex_payment_method: any;
  loadingIndicator = false;
  expensePaymentList: any = [];
  expensePaymentListColumns: any = [];
  reorderable = true;
  dt_default_sort = [{ prop: "payee", dir: "asc" }];
  expensePayeeList: { id: number; name: string };
  user_id: any;
  receiptList: any = [];
  voucherNoList: any = [];
  receiptListTypeahead = new EventEmitter<string>();
  voucherNoTypeahead = new EventEmitter<string>();
  modalReference: any;
  companyDetails: any;
  currency: any;
  selectFiYear: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private expenseservice: ExpenseService,
    private customerservice: CustomerService,
    private countryservice: MastersService,
    private authenticationService: AuthenticationService,
    private companyService: CompanyService,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.ex_payment_method = this.payment_methodList[0];
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.user_id = this.authenticationService.getUserRole();
    this.companyDetails = this.authenticationService.getCompanyDetails();
    this.currency = this.companyDetails.base_currency;


    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];
    this.filters.payment_date = {
      isRange: true,
      singleDate: null,
      dateRange: {
        beginDate: { year: this.selectFiYear.start_year, month: 4, day: 1 },
        beginJsDate: new Date(this.selectFiYear.start_year, 3, 1),
        endDate: { year: this.selectFiYear.end_year, month: 3, day: 31 },
        endJsDate: new Date(this.selectFiYear.end_year, 2, 31),
      }
    };

    this.getPaymentCoaHead("").subscribe((result) => {
      this.payment_accountList = result["response"];
    });
    this.expensePayeeList = {
      id: 2,
      name: "Without payee",
    };

    this.getPaymentCoaHeadSearch();

    this.getExpenseCoaHead("").subscribe((result) => {
      this.expenseList = result["response"];
    });
    this.getExpenseCoaHeadSearch();

    this.getPayee("").subscribe((result) => {
      this.payeeList = result["response"];
    });
    this.getPayeesearch();

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get("id");
      if (this.id != null) {
        this.getExpense();
      }

      var result = this.router.url.match("/edit/");
      if (result != null) {
        if (result[0] === "/edit/" && this.id != null) {
          this.is_edit_form = true;
        }
      } else {
        this.expense.payment_date = {
          isRange: false,
          singleDate: {
            jsDate: new Date(),
          },
        };
      }
    });
    this.addNewItem(0);

    this.expensePaymentListColumns = [

      {
        prop: "payment_date",
        name: "Date",
        sortable: "payment_date",
        flexGrow: 1,
        type: "date",
        show: true,
        field_name: "Date",
        is_detailed_row: false,
        css_class: "align-left",
      },
      {
        prop: "leadger_name",
        name: "Expense Head",
        sortable: "leadger_name",
        flexGrow: 1,
        type: "unwindVal",
        show: true,
        is_detailed_row: false,
        css_class: "align-left",
      },
      {
        prop: "voucher_number",
        name: "Voucher#",
        sortable: "voucher_number",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        css_class: "align-left",
      },
      {
        prop: "payment_account",
        name: "Payment Type",
        sortable: "payment_account",
        flexGrow: 1,
        show: true,
        // field_name: "payment_account.flattened_data.name",
        is_detailed_row: false,
        css_class: "align-left",
      },
      // {
      //   prop: "payment_method",
      //   name: "Payment Method",
      //   sortable: "payment_method",
      //   flexGrow: 1,
      //   show: true,
      //   type: "object",
      //   field_name: "name",
      //   is_detailed_row: false,
      //   css_class: "align-right",
      // },

      {
        prop: "amount",
        name: "Amount",
        sortable: "amount",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        css_class: "align-right",
      },
      {
        prop: "expense_status_name",
        name: "Expense Status",
        sortable: "expense_status",
        flexGrow: 1,
        show: true,
        type: "status",
        is_detailed_row: false,
        css_class: "align-center",
      },
      {
        name: "Action",
        type: "action",
        prop: "_id",
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false,
      },
    ];


    // this.expensePaymentListColumns = [
    //   {
    //     prop: "payment_date",
    //     name: "Date",
    //     sortable: "payment_date",
    //     flexGrow: 1,
    //     show: true,
    //     type: "date",
    //     is_detailed_row: false,
    //     css_class: "align-lift",
    //   },
    //   {
    //     prop: "payment_method",
    //     name: "Payment Type",
    //     sortable: "payment_method",
    //     flexGrow: 1,
    //     show: true,
    //     type: "object",
    //     field_name: "name",
    //     is_detailed_row: false,
    //     css_class: "align-lift",
    //   },
    //   {
    //     prop: "expense_object",
    //     name: "Voucher #",
    //     sortable: "voucher_number",
    //     flexGrow: 1,
    //     show: true,
    //     is_detailed_row: false,
    //     type: "object",
    //     field_name: "voucher_number",
    //     css_class: "align-lift",
    //   },
    //   {
    //     prop: "expense_object",
    //     name: "Ref Id",
    //     sortable: "ref_id",
    //     type: "object",
    //     flexGrow: 1,
    //     show: true,
    //     is_detailed_row: false,
    //     field_name: "ref_id",
    //     css_class: "align-lift",
    //   },
    //   {
    //     prop: "total_expense_amount",
    //     name: "Total Amount",
    //     sortable: "Total Amount",
    //     flexGrow: 1,
    //     show: true,
    //     is_detailed_row: false,
    //     css_class: "align-right",
    //   },
    //   {
    //     prop: "expense_status_name",
    //     name: "Payment Status",
    //     sortable: "expense_status_name",
    //     flexGrow: 1,
    //     show: true,
    //     type: "status",
    //     is_detailed_row: false,
    //     css_class: "align-center",
    //   },
    //   {
    //     name: "Action",
    //     type: "action",
    //     prop: "_id",
    //     flexGrow: 1,
    //     is_detailed_row: false,
    //     sortable: false,
    //   },
    // ];
    this.getExpensesPaymentList(this.page);
    this.customerservice
      .getCustomers({}, this.columns, this.sort, this.page)
      .subscribe((result) => {
        this.payeeList = result["response"];
      });
    this.getCompanyDetails();

    this.getIncomeCoaHead("").subscribe((result) => {
      this.receiptList = result["response"];
    });
    this.getIncomeCoaHeadSearch();
    this.getVoucherNoList("").subscribe((result) => {
      this.voucherNoList = result["response"]["data"].filter(element => element["voucher_number"] != undefined);
    });
    this.getVoucherNoListSearch();
  }

  //   this.getExpensesList();

  onpayment_dateDateChanged(event: IMyDateModel): void {
    // date selected
  }

  open(content, id) {
    this.id = id;
    this.modalReference = this.modalService.open(content, { size: "lg" });
  }

  getExpensesPaymentList(page_info: any) {
    this.columns = {
      company_id: 1,
      expense_items: 1,
      expense_object: 1,
      payment_date: "$expense_object.payment_date",
      expense_status: 1,
      expense_status_name: 1,
      total_expense_amount: 1,
      balance_amount: 1,
      booking_date: 1,
      payment_method: 1,
      payment_account: 1,
      _id: 1,
      draft_data: 1
    };
    // this.filters = {};
    this.filters["$or"] = [];
    if (page_info != null) {
      this.page = page_info;
    }
    console.log('this.page====>>>>', this.page);
    let with_payee = this.expensePayeeList;
    // if (this.filters.with_payee == 2) {
    //   this.columns['payee'] = null;
    // }
    // this.columns["expense_type"] = 1;
    this.filters["company_id"] = this.company_id;
    this.filters["$or"] = Array(
      { "payee": { $eq: null } },
      { "payee": { $eq: '' } }
    );

    console.log("====trjmgf==this.filters===>", this.filters);

    this.expenseservice
      .getExpenses(this.filters, this.columns, this.sort, this.page)
      .subscribe((result) => {
        console.log("======result===>", result);
        this.loadingIndicator = false;
        this.expensePaymentList = result["response"]["data"];
        this.page.count = result["response"]["count"];
      });
  }
  onSort(event) {
    this.loadingIndicator = true;
    console.log("event", event);
    this.sort = {};
    let sortIndex = event.sorts.length > 0 ? event.sorts.length - 1 : 0;
    let sort_details = event.sorts[sortIndex];
    this.sort[sort_details.prop] = sort_details.dir == "asc" ? 1 : -1;
    this.getExpensesPaymentList(this.page);
  }
  addExpense() {
    this.expense.payment_date = this.expense.payment_date["singleDate"][
      "jsDate"
    ].toISOString();
    this.expense.company_id = this.company_id;
    // console.log("===this expense", this.expense);

    let total_expense_amount = 0;
    let total_tax_amount = 0;
    let balance_amount = 0;
    this.expense.expense_items.forEach((element) => {
      total_expense_amount = total_expense_amount + element["amount"];
      total_tax_amount = total_tax_amount + element["tax"];
    });

    this.expense.total_expense_amount = total_expense_amount;
    this.expense.total_tax_amount = total_tax_amount;
    this.expense.balance_amount = total_tax_amount;
    this.expense.party_id = { vendor: "202002020" };
    this.expenseservice.addExpense(this.expense).subscribe((result) => {
      if (result["response"]["status_code"] === 1) {
        swal("Added expense sucessfully!", "expense added.", "success");
        this.router.navigateByUrl("/expense/list");
      } else {
        this.showErrors(result[" response "][" errors "]);
      }
    });
  }
  getExpense() {
    this.expenseservice.viewExpense(this.id).subscribe((result) => {
      this.expense = result[" response "];
    });
  }

  getExpenseCoaHead(searchString) {
    this.expenseList = [];
    return this.countryservice.getCoaHead(this.company_id, searchString, {
      in: [ConstantService.coa_heads.Expenses],
    });
  }
  getExpenseCoaHeadSearch() {
    this.expenseListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getExpenseCoaHead(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.expenseList = result["response"];
        },
        (err) => {
          console.log(err);
          this.expenseList = [];
        }
      );
  }

  getPaymentCoaHead(searchString) {
    this.payment_accountList = [];
    return this.countryservice.getCoaHead(this.company_id, searchString, {
      in: ['Cash', 'Bank'],
    });
  }

  getPaymentInChange() {
    console.log("name===========>", this.expense.payment_method);
    this.getPaymentCoaHead("").subscribe((result) => {
      this.payment_accountList = result["response"];
    });
  }

  getPaymentCoaHeadSearch() {
    this.accountHeadListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getPaymentCoaHead(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.payment_accountList = result["response"];
        },
        (err) => {
          console.log(err);
          this.payment_accountList = [];
        }
      );
  }

  getPayee(searchString) {
    this.payeeList = [];
    return this.countryservice.getCoaHead(this.company_id, searchString, {
      in: [
        ConstantService.coa_heads.Accounts_Receivable,
        ConstantService.coa_heads.Accounts_Payable,
      ],
    });
  }

  getPayeesearch() {
    this.payeeListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getPayee(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.payeeList = result["response"];
        },
        (err) => {
          console.log(err);
          this.payeeList = [];
        }
      );
  }

  addUpdateExpense() {
    if (this.is_edit_form === true) {
      this.editExpense();
    } else {
      this.addExpense();
    }
  }

  editExpense() {
    this.expenseservice
      .editExpense(this.expense, this.id)
      .subscribe((result) => {
        if (result["response"]['ok'] == 1) {
          swal(" Success ", " Expense Updated Successfully ");
          this.router.navigateByUrl("/expense/list");
        } else {
          this.showErrors(result[" response "][" errors "]);
        }
      });
  }
  deleteReceipt(row_data) {
    swal({
      title: "Are you sure want to delete?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result) {
        this.expenseservice.deleteExpense(row_data)
          .subscribe((result) => {
            this.loadingIndicator = false;
            if (result['ok']) {
              swal("Success", "Expense Payment has been cancelled successfully", "success")
            } else {
              swal("Error", "Cant able to cancel the advance", "error");
            }
          });
        this.getExpensesPaymentList(this.page);
      }
    });
  }

  showErrors(errors) {
    let error_string: string;
    error_string = " ";
    errors.forEach((item) => {
      error_string += " < br / > " + item;
    });
    swal({
      title: "Error",
      html: error_string,
      type: "error",
    }).then(function () { });
  }

  addNewItem(index) {
    this.expense.expense_items.push({
      category: "",
      description: "",
      amount: "",
      tax: "",
    });
  }
  deleteItem(index) {
    this.expense.expense_items.splice(index, 1);
  }

  getCompanyDetails() {
    this.companyService.viewCompany(this.company_id).subscribe((result) => {
      this.company_details = result["response"];
      this.payment_dateDpOptions.dateFormat = this.company_details.date_format;
      let book_beginning_date = new Date(this.company_details.book_beginning);
      book_beginning_date.setDate(book_beginning_date.getDate() - 1);
      let curren_tx_dateOptions = this.getCopyOfOptions();
      curren_tx_dateOptions.disableUntil = {
        year: book_beginning_date.getFullYear(),
        month: book_beginning_date.getMonth() + 1,
        day: book_beginning_date.getDate(),
      };
      this.payment_dateDpOptions = curren_tx_dateOptions;
    });
  }
  getCopyOfOptions(): IAngularMyDpOptions {
    return JSON.parse(JSON.stringify(this.payment_dateDpOptions));
  }
  addExpenseBookingpayment() {
    this.expense.payment_date = this.expense.payment_date["singleDate"][
      "jsDate"
    ].toISOString();
    this.expense.company_id = this.company_id;
    // console.log("===this expense", this.expense);

    let total_expense_amount = 0;
    let total_tax_amount = 0;
    let balance_amount = 0;
    this.expense.expense_items.forEach((element) => {
      total_expense_amount = total_expense_amount + element["amount"];
      total_tax_amount = total_tax_amount + element["tax"];
    });

    this.expense.total_expense_amount = total_expense_amount;
    this.expense.payment_method = this.ex_payment_method;
    this.expense.total_tax_amount = total_tax_amount;
    this.expense.balance_amount = total_tax_amount;
    this.expense.party_id = this.expense.payee["flattened_data"];
    this.expense.is_booking_patyment = 2;
    this.expenseservice.addExpense(this.expense).subscribe((result) => {
      if (result["response"]["status_code"] === 1) {
        swal("Added expense sucessfully!", "expense added.", "success");
        this.router.navigateByUrl("/expense/list");
      } else {
        this.showErrors(result[" response "][" errors "]);
      }
    });
  }

  confirmExpense(row_data) {
    if(row_data['leadger_name'] !=undefined){
    swal({
      title: "Are you sure want to confirm the payment ?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, confirm it!",
    }).then((result) => {
      if (result) {
        this.expenseservice
          .confirmExpense(row_data["_id"], this.company_id)
          .subscribe((response) => {
            if (response["ok"]) {
              this.expenseservice
                .addExpensePayment(response["data"]["draft_data"]["expense_id"], this.company_id)
                .subscribe((result) => {
                  if (result['statusCode'] == 200) {
                    swal(
                      "Expense  added sucessfully!",
                      "expense added.",
                      "success"
                    );
                    this.getExpensesPaymentList(this.page);
                  }

                });
            }
          });
      }
    });}else{
      swal("Error!", "Select the ledger for approval.", "error");
    }
  }

  getIncomeCoaHeadSearch() {
    this.receiptListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getIncomeCoaHead(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.receiptList = result["response"];
        },
        (err) => {
          console.log(err);
          this.receiptList = [];
        }
      );
  }

  getIncomeCoaHead(searchString) {
    this.receiptList = [];
    return this.countryservice.getCoaHead(this.company_id, searchString, {
      not_in: [
        ConstantService.coa_heads.Closing_Inventories,
        ConstantService.coa_heads.Premilinary_Expenses
      ],
    });
  }
  getVoucherNoList(searchString: string = '') {
    this.voucherNoList = [];
    this.columns["voucher_number"] = 1;
    this.filters["company_id"] = this.company_id;
    if (searchString != "") {
      this.filters['voucher_number'] = searchString;
    }

    // this.loadingIndicator = true;
    return this.expenseservice
      .getExpenses(this.filters, this.columns, this.sort, this.page)
  }
  getVoucherNoListSearch() {
    this.voucherNoTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getVoucherNoList(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.voucherNoList = result["response"]["data"].filter(element => element["voucher_number"] != undefined);;
        },
        (err) => {
          console.log(err);
          this.voucherNoList = [];
        }
      );
  }
}
