import { Router, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { Component, OnInit, EventEmitter, ChangeDetectorRef, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { AuthenticationService } from '../../_services/authentication.service';
import { CompanyService } from '../../_services/company.service';
import swal from 'sweetalert2';
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FinancialYearsComponent } from '../../components/financial-years/financial-years.component';
@Component({
  selector: 'app-page-topbar',
  templateUrl: './page-topbar.component.html',
  styleUrls: ['./page-topbar.component.scss']
})
export class PageTopbarComponent implements OnInit {
  financialYearsConfig: FinancialYearsComponent = new FinancialYearsComponent(this.router, this.authenticationService);

  first_name: string;
  role_name: string;
  companies_list: any;
  companiesListTypeahead = new EventEmitter<string>();
  company_id: string;
  selected_company: boolean=false;
  showDropdown = false;
  searchDropdown = false;
  addDropdown = false;
  current_search_module: { id: any; path: string; name: any; icon: any; role_id: any; isSelected: boolean };
  quickAdd: { path: string; name: any; icon: any; role_id: any; }[];
  quickSearch: { id: any; path: string; name: any; icon: any; role_id: any; isSelected: boolean }[];
  pageTitle: string;
  search_text: string;
  setCompanyFormDialogOption: NgbModalOptions = {
    keyboard: false,
    backdrop: 'static',

  }
  @ViewChild('setDefaultCompanyForm', { static: true }) setDefaultCompanyForm: TemplateRef<any>;
  branch_id: any;
  branch_name: any;
  is_head_office: boolean;
  user_id: any;
  company_details: any;
  constructor(
    private authenticationService: AuthenticationService,
    private companyService: CompanyService,
    private router: Router,
    private cd: ChangeDetectorRef,
    // private canComponentDeactivate: CanComponentDeactivate,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getSearchModules();
    this.getQuickAddMenu();
    this.getPageTitle();
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.branch_name = this.authenticationService.getDefaultBranchName();
    this.is_head_office = this.authenticationService.getHeadOffice();
    this.role_name = this.authenticationService.getUserRoleName();
    this.user_id = this.authenticationService.getUserId();
    this.first_name = this.authenticationService.getUserFirstName();
    this.getCompanies('').subscribe(result => {
      this.companies_list = result;
      let data = this.authenticationService.getDefaultCompanyId();
      // if (data) {
      // if (this.companies_list) {
      // this.selected_company = this.companies_list.filter(company_data => company_data['_id'] === data)[0];
      // console.log('this.selected_company', this.selected_company)
      // }
      // }
      // this.isCompanySelected();
    });
    this.getCompaniesSearch();
    // this.isCompanySelected();
      this.company_details = this.authenticationService.getCompanyDetails();
      if(this.company_details !=null && this.company_details !=undefined){
      this.financialYearsConfig = this.company_details.financial_year;
    }
  }

  logout() {

    this.authenticationService.logout();
    this.authenticationService.removeLocalStorageItem('redirect_path');
  }
  getDefaultCompanyId() {
    return this.authenticationService.getSelectedCompanyId();
  }
  getDefaultCompanyName() {
    if(this.company_id !== ""){
      this.selected_company = true;
    }
    return this.authenticationService.getSelectedCompanyName();
  }
  getCompanies(searchString) {
    this.companies_list = [];
    let condition: any = {};
    if (searchString != "") {
      condition["company"] = searchString;
    }
    // .subscribe(result => {
    // this.companies_list = result;
    // let data = this.authenticationService.getDefaultCompanyId();
    // if (data) {
    //   if (this.companies_list) {
    //     this.selected_company = this.companies_list.filter(company_data => company_data['_id'] === data)[0];
    //   }
    // }
    // this.isCompanySelected();
    return this.companyService.getCompanies(condition);

    // });

  }
  clearCompanyData() {
    this.authenticationService.clearCompanyData();
    this.router.navigateByUrl('/home').then(() => {
      location.reload();
    });
  }

  getCompaniesSearch() {
    this.companiesListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(searchString => this.getCompanies(searchString))
      )
      .subscribe(
        result => {
          this.cd.markForCheck();
          this.companies_list = result;
        },
        err => {
          console.log(err);
          this.companies_list = [];
        }
      );
  }
  getSearchModules() {
    // if (this.company_id = this.authenticationService.getDefaultCompanyId() != '') {
    const menu = this.router.config.filter(routes => routes.data && routes.data.quick_search).map(route => {
      return {
        id: route.data.menu.id,
        path: route.path,
        name: route.data.menu.name,
        icon: route.data.menu.icon,
        role_id: route.data.permissions,
        isSelected: false,

      };

    });

    return this.quickSearch = menu;
    // }
  }
  getPageTitle() {
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        this.pageTitle = data.state.root.firstChild.data.title;
      }
    });
    // console.log('this.activatedRoute.data', this.activatedRoute.snapshot.data);
  }
  selectSearchModule(module_id: number) {

    this.quickSearch.forEach((module, index) => {
      this.quickSearch[index]['isSelected'] = false;
      if (module.id == module_id) {
        this.quickSearch[index]['isSelected'] = true;
        this.current_search_module = module;

      }

    });
  }
  search() {
    if (this.search_text.length > 3) {
      this.router.navigate([this.current_search_module.path], { queryParams: { q: this.search_text } });
      this.searchDropdown = false;
    }
    // this.current_search_module.path;
  }
  getQuickAddMenu() {
    const menu = this.router.config.filter(routes => routes.data && routes.data.quick_add).map(route => {
      return {

        path: route.path,
        name: route.data.quick_add.name,
        icon: route.data.quick_add.icon,
        role_id: route.data.permissions,

      };
    });

    return this.quickAdd = menu;
  }
  updateDefaultCompany() {
    // this.authenticationService.setDefaultCompanyId(this.selected_company);
    window.location.reload();
    this.company_id = this.authenticationService.getDefaultCompanyId();
  }

  // isCompanySelected() {
  //   // this.selected_company == '' || this.selected_company == null || 
  //   if (this.companies_list == undefined || this.companies_list == '') {
  //     // swal('Add Company!', 'Please Add The Company First', 'warning');
  //     this.router.navigate([('company/add')]);
  //   } else if (this.selected_company == undefined) {
  //     const customerModalRef = this.modalService.open(this.setDefaultCompanyForm, this.setCompanyFormDialogOption);
  //   }
  // }
}
