import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { AuthenticationService } from "./authentication.service";
import { id } from "@swimlane/ngx-datatable";

@Injectable({ providedIn: "root" })
export class BillService {
  branch_id: string;
  constructor(
    private http: ApiService,
    private authenticationService: AuthenticationService
  ) {
    this.branch_id = this.authenticationService.getDefaultBranchId();
  }
  UpdateBillFile(files, muid) {
    return this.http.uploadFile(
      ApiService.geturl("bill", "upload"),
      files,
      muid
    );
  }
  createDoubleEntryTransaction(bill) {
    return this.http.post(
      ApiService.geturl("bill", "doubleentryTransaction"),
      bill
    );
  }

  addBill(bill_data: any) {
    if (this.branch_id != "") {
      bill_data.branch_id = this.branch_id;
    }
    return this.http.post(ApiService.geturl("bill", "add"), bill_data);
  }

  editBill(bill_data: any, id: string) {
    return this.http.put(ApiService.geturl("bill", "edit"), bill_data, {
      id: id,
    });
  }
  // editBill(bill_data: any, id: string) {
  //   return this.http.post(ApiService.geturl("bill", "edit"), {
  //     data: bill_data,
  //     bill_id: id,
  //   });
  // }

  deleteBill(id: string) {
    return this.http.delete(ApiService.geturl("bill", "delete_draft"), {
      id: id,
    });
  }

  debitNote(bill: any) {
    return this.http.post(ApiService.geturl("debitnote", "add"), bill);
  }

  updateDebitNote(bill: any, id: string) {
    return this.http.post(ApiService.geturl("debitnote", "update"), { bill, id: id });
  }

  // viewBill(id: string, condition: {}) {
  //   if (this.branch_id != "") {
  //     condition["branch_id"] = this.branch_id;
  //   }
  //   return this.http.post(ApiService.geturl("bill", "view"), {
  //     bill_id: id,
  //     condition: condition,
  //   });
  // }
  viewBill(id: string) {

    return this.http.get(ApiService.geturl("bill", "view"), {
      id: id,
    });
  }

  getBills(filters: any = {}, columns?: any, sort: any = {}, page: any = {}) {
    if (this.branch_id != "") {
      filters["branch_id"] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("bill", "viewall"), {
      filters: filters,
      columns: columns,
      sort: sort,
      page: page,
    });
  }
  createPayment(data) {
    return this.http.post(ApiService.geturl("payment", "add"), data);
  }
  createExpensePayment(data) {
    return this.http.post(
      ApiService.geturl("payment", "addExpensePayment"),
      data
    );
  }
  getSelectedBills(bill_ids, columns) {
    return this.http.post(ApiService.geturl("bill", "getAllBills"), {
      filters: bill_ids["_id"],
      columns: columns,
    });
  }
  getCreditAmount(filter) {
    return this.http.get(
      ApiService.geturl("creditnote", "getUsersDebitAmount"),

      filter,

    );
  }
  getAdvanceAmount(filter) {
    return this.http.post(ApiService.geturl("bill", "getAdvanceAmount"), {
      filters: filter,
    });
  }
  mapBills(paymentArray, company_id, dataObj) {
    return this.http.post(ApiService.geturl("bill", "map_advancepayment"), {
      filters: paymentArray,
      company_id: company_id,
      dataObj: dataObj,
    });
  }
  updateBillForAdvance(data) {
    return this.http.post(
      ApiService.geturl("bill", "updatetAdvanceAmountInBill"),
      {
        data: data,
      }
    );
  }
  updateBillForDebit(data) {
    return this.http.post(
      ApiService.geturl("creditnote", "updatetAmountInCreditBill"),
      {
        data: data,
      }
    );
  }
  getDebitAmount(company_id, bill_id) {
    return this.http.post(ApiService.geturl("creditnote", "getDebitAmount"), {
      company_id: company_id,
      bill_id: bill_id,
    });
  }
  getBillReports(
    filters: any = {},
    columns?: any,
    sort: any = {},
    page: any = {}
  ) {
    return this.http.post(ApiService.geturl("bill", "reportallBills"), {
      filters: filters,
      columns: columns,
      sort: sort,
      page: page,
    });
  }
  sendBill(row_data) {
    return this.http.post(ApiService.geturl("bill", "sendBill"), {
      bill_data: row_data,
    });
  }
  uploadFiles(files, data) {
    // data = JSON.stringify(data);
    return this.http.uploadFile(ApiService.geturl('bill', 'upload'),
      files,
      data
    );

  }
  getFileuploads(
    filters: any = {},
    sort_by: any = {},
    columns: any = {},
    page: any = {}
  ) {
    return this.http.post(ApiService.geturl("fileupload", "viewall"), {
      filters: filters,
      sort: sort_by,
      columns: columns,
      page: page
    });
  }
  getImagePath(path) {
    return this.http.getImageUrl(path);
  }
  getImageFromFileUpload(condition) {
    return this.http.post(ApiService.geturl('fileupload', 'view'), condition
    );
  }
  editExpense(data, expense_id) {
    return this.http.post(ApiService.geturl("expense", "editExpense"), {
      data: data,
      expense_id: expense_id,
    });
  }

  deleteFileUpload(id) {
    return this.http.post(ApiService.geturl("fileupload", "delete"), { id: id })
  }
}
