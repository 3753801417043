<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <form novalidate #searchCreditBillForm="ngForm" (ngSubmit)="getCreditBillsList({ offset: 0, limit: 10 })">
        <div class="card">
          <div class="card-body">
            <div class=" mr-3 mb-2 float-right">
              <a *ngIf="this.companyDetails.fi_year.status!='Finalized'" class="btn btn-primary float-right mb-4" routerLink="/bill/debitnote/add"><i class="fa fa-plus"></i>
                Add
                Debit Note </a>
            </div>
            <h3 class="table-title"> Debit Note List </h3>
            <div class="card-body row">
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="vendor">Vendor</label>
                <ng-select (clear)="credit_billList=[]" [items]="vendor_lists" [typeahead]="vendorListTypeahead"
                  #vendor="ngModel" name="vendor" bindLabel="vendor_name" bindValue="_id"
                  [(ngModel)]="filters.vendor_muid" placeholder="Select Vendor">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.vendor_name }}
                    </div>
                  </ng-template>

                </ng-select>
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="debit_note_number">Debit Note No</label>
                <ng-select (clear)="credit_billList=[]" [multiple]="true" [items]="debitNoteNumberList" [typeahead]="debitNoteNumberListTypeahead"
                  name="debit_number" bindLabel="debit_number" bindValue="debit_number"
                  [(ngModel)]="filters.debit_number" placeholder="Debit Note Number">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.debit_number }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="bill_status">Debit Note Status</label>
                <ng-select [items]="bill_status_list" name="bill_status" bindLabel="name"
                  [(ngModel)]="filters.bill_status" placeholder="Select Status" bindValue="id">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.name }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label>Status</label>
              <input type="text" name="vendor_name" class="form-control" placeholder="Vendor Name"
                [(ngModel)]="filters.vendor" />
            </div> -->
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="debitnote_date">Debit Note Date</label>
              <div class="input-group">
                <input class="form-control" name="debit_note_date" angular-mydatepicker
                  #dp_debitnote="angular-mydatepicker" placeholder="Select a date"
                  (click)="dp_debitnote.toggleCalendar()" [(ngModel)]="filters.do_date"
                  [options]="debitnoteDateOptions"/>

                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" *ngIf="filters.do_date"
                    (click)="dp_debitnote.clearDate()">
                    <i class="fa fa-close"></i>
                  </button>

                </div>
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary">
                    <i class="fa fa-calendar-o"></i>
                  </button>
                </div>
              </div>

            </div>
          </div>
          <div>
            <button type="submit" class="btn btn-primary mr-2"><i class="fa fa-search"></i> Search</button>
            <button type="button" (click)="clearDebitNoteFilters();" class="btn btn-danger"><i
                class="fa fa-close"></i>
              Clear
            </button>

          </div>
            <!-- <div class="row">
              <div *ngIf="id==''" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label>Vendor Name</label>
                <input type="text" name="vendor_name" class="form-control" placeholder="Vendor Name"
                  [(ngModel)]="filters.vendor" />
              </div>

              <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="vendor">Vendor</label>
                <ng-select (clear)="billsList=[]; isDisableBillPaymentBtn=true" [items]="vendor_lists"
                  [typeahead]="vendorListTypeahead" #vendor="ngModel" required name="vendor" bindLabel="vendor_name"
                  bindValue="_id" [(ngModel)]="filters.vendor_muid"
                  (change)="validateAndGetBillList(); isDisableBillPaymentBtn=false;" placeholder="Select Vendor">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.vendor_name }}
                    </div>
                  </ng-template>

                </ng-select>
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="bill_number">Debit Note Number</label>
                <ng-select [multiple]="true" [items]="billNumberList" [typeahead]="billNumberListTypeahead"
                  name="bill_number" bindLabel="bill_number" bindValue="bill_number" [(ngModel)]="filters.bill_number"
                  placeholder="Bill Number">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.bill_number }}
                    </div>
                  </ng-template>
                </ng-select>

              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="bill_status">Debit Note Status </label>
                <ng-select (change)="validateAndGetBillList()" [items]="bill_status" bindValue="bill_status_id"
                  bindLabel="bill_status_name" id="bill_status" required placeholder="Bill Status" name="bill_status"
                  [multiple]="true" [(ngModel)]="filters.bill_status">

                </ng-select>
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="bill_date">Debit Note Date</label>
                <div class="input-group">
                  <input class="form-control" name="bill_date" placeholder="Select a date" angular-mydatepicker
                    #dp_invoice="angular-mydatepicker" (click)="dp_invoice.toggleCalendar()"
                    [(ngModel)]="filters.bill_date" [options]="billDateOptions" />
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="filters.bill_date"
                      (click)="dp_invoice.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="dp_invoice.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>
              </div>



            </div> -->

          </div>


          <!-- <div class="card-header d-flex align-items-center">


            <h3 class="table-title">Debit Note List</h3>
          </div>
          <button class="btn btn-primary float-right mb-4" routerLink="/bill/debit_note">
            <i class="fa fa-plus"></i> Add Debit Note
          </button> -->

          <!-- <div class="card-footer">
            <button type="submit" class="btn btn-primary mr-2"> Search</button>
            <button (click)="clearCreditBillFilters();" class="btn btn-danger">
              <i class="fa fa-close"></i>Clear
            </button>

          </div> -->
        </div>
      </form>
      <!-- <div class="p-sm-2">
        <button class="btn btn-primary float-right" routerLink="/credit_bill/add">
          <i class="icon icon-add"></i>Add CreditBill
        </button>
      </div> -->

      <div class="card p-sm-0">
        <div class="card-body">

          <ngx-datatable [rows]="credit_billList" [loadingIndicator]="loadingIndicator" [externalPaging]="true"
            [columnMode]="'force'" [limit]="page.limit" [count]="page.count" [externalSorting]="true"
            (sort)="onSort($event)" [sorts]="this.dt_default_sort" [offset]="page.offset"
            (page)="getCreditBillsList($event)" [reorderable]="reorderable" [headerHeight]="50" [footerHeight]="50"
            [rowHeight]="'auto'" class="material striped" [summaryRow]="'true'" [summaryPosition]="'bottom'"
            [summaryHeight]="'auto'">
            <ngx-datatable-column *ngFor="let col of creditbillListColumns" [flexGrow]=" col.flexGrow" [name]="col.name"
              [sortable]="col.sortable" [cellClass]="col.css_class" [prop]="col.prop">
              <ng-template ngx-datatable-header-template let-sort="sortFn">
                <span (click)="sort()">{{ col.name }}</span>
              </ng-template>
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <div [ngSwitch]="col.type">
                  <span *ngSwitchCase="'currency'"> {{row[col.prop]|currency:currency}}</span>
                  <span *ngSwitchCase="'percentage'">{{row[col.prop]}}%</span>
                  <span *ngSwitchCase="'action'">
                    <div class="btn-group mr-3" *ngIf="row.bill_status_name =='Draft'">
                      <a class=" btn btn-primary" name="send{{ i }}" type="button" (click)="confirmCreditNote(row)"
                        rel="tooltip">Confirm</a>
                      <div class="button-row">
                        <div class="btn-group" ngbDropdown placement="bottom-right" container="body" role="group">
                          <a class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></a>
                          <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" (click)="open(debitNoteViewContent, row._id)" mat-mini-fab
                              color="primary" title="View">View</a>
                            <a *ngIf="!row.bill_number" class="dropdown-item" routerLink="/debit-bill/edit/{{ row._id}}"
                              mat-mini-fab color="primary" title="Edit">Edit</a>
                            <a class="dropdown-item " (click)="deleteCreditBill(value)"
                              *ngIf="row.bill_status_name =='Draft'">
                              <span class="text-red">
                                <i class="icon-trash-can3"></i> Delete
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="btn-group mr-3" *ngIf="row.bill_status_name =='opened'">
                      <a class="btn btn-primary" (click)="open(debitNoteViewContent, row._id)">
                        View
                      </a>

                    </div>
                    <div class="btn-group mr-3" *ngIf="row.bill_status_name =='closed'">
                      <a class="btn btn-primary" (click)="open(debitNoteViewContent, row._id)">
                        View
                      </a>

                    </div>

                  </span>
                  <span *ngSwitchCase="'object'"> {{ row[col.prop]? row[col.prop][col.field_name]: '' }}</span>
                  <span *ngSwitchCase="'date'"> {{ row[col.prop]|date }}</span>

                  <span *ngSwitchCase="'status'"> <span
                      [ngClass]="{'badge-warning': row.bill_status==1, 'badge-success': row.bill_status==3,'badge-default': row.bill_status==5}"
                      class="badge">{{
                      row[col.prop]}}</span></span>
                  <span *ngSwitchDefault>
                    {{ row[col.prop] }}
                  </span>
                </div>

              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>

  </div>
</div>

<ng-template #debitNoteViewContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Debit Note View</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
       <app-invoice-view [id]="id" [moduleName]="'debit_note'"></app-invoice-view>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
