import { Component, OnInit, TemplateRef, ViewChild, Input, SimpleChanges, OnChanges } from "@angular/core";
import { Location } from "@angular/common";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { AuthenticationService } from "../../../_services/authentication.service";
import { ExpenseService } from "../../../_services/expense.service";
import { CompanyService } from '@app/_services/company.service';
import { ReceiptsService } from "../../../_services/receipts.service";
@Component({
  selector: "app-expense-view",
  templateUrl: "./expense-view.component.html",
  styleUrls: ["./expense-view.component.scss"],
})
export class ExpenseViewComponent implements OnInit, OnChanges {
  company_id: any;
  branch_id: any;
  // id: string;
  @Input() id: string;
  @Input() moduleName: string;
  expense: any = {};
  company_details: any;
  currency: string;
  is_without_gst: Boolean = false;
  total_tax_amount: any = 0;
  is_same_state: boolean;
  type: string;
  label: string;
  viewDetails: any = {};
  paymentName: string;
  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private expenseService: ExpenseService,
    private companyService: CompanyService,
    private receiptsService: ReceiptsService,

  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.company_id = this.authenticationService.getDefaultCompanyId();
    if(changes.id) {
      this.id = changes.id.currentValue;
      this.type = this.moduleName;
      this.initiateViewPage();
    }
  }

  ngOnInit() {
    // this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    // this.route.paramMap.subscribe((params) => {
      // this.id = params.get("id");
      // this.type = params.get('type');
    
    // });
    this.currency = this.authenticationService.getCompanyDetails()[
      "base_currency"
    ];
  }

  initiateViewPage() {
    if (this.type == "receipt") {
      this.label = 'Receipt';
      this.paymentName = 'Receipt';
      this.viewReceipt();
    } else if (this.type == "payment") {
      this.label = 'Payment';
      this.paymentName = 'Payment';
      this.viewPayment();
    }
  }

  viewReceipt() {
    let condition = {};
    condition['company_id'] = this.company_id;
    condition['_id'] = this.id;

    this.receiptsService.viewreceipt(condition).subscribe((result) => {
      this.viewDetails = {};
      this.viewDetails.paymentMethod = result['data']['draft_data']['payment_object']['payment_method']['name'];
      this.viewDetails.paymentAccount = result['data']['draft_data']['payment_object']['payment_account']['flattened_data']['name'];
      this.viewDetails.paymentDate = result['data']['payment_date'];
      this.viewDetails.paymentVoucherNo = result['data']['draft_data']['payment_object']['voucher_number'];
      this.viewDetails.paymentRefId = result['data']['draft_data']['payment_object']['ref_id'];
      this.viewDetails.items = result['data']['draft_data']['payment_object']['receipt_items'];
      this.viewDetails.totalAmount = result['data']['draft_data']['payment_object']['total_receipt_amount'];

    });
  };


  viewPayment() {
    this.expenseService.viewExpense(this.id).subscribe((result) => {
      this.viewDetails = {};
      this.viewDetails.paymentMethod = result['expense_object']['payment_method']['name'];
      this.viewDetails.paymentAccount = result['expense_object']['payment_account']['flattened_data']['name'];
      this.viewDetails.paymentDate = result['expense_object']['payment_date'];
      this.viewDetails.paymentVoucherNo = result['expense_object']['voucher_number'];
      this.viewDetails.paymentRefId = result['expense_object']['ref_id'];
      this.viewDetails.items = result['expense_object']['expense_items'];
      this.viewDetails.totalAmount = result['expense_object']['total_expense_amount'];
    });
  }


  goBack() { }
}