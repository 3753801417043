<div id="left-sidebar" class="sidebar ">
  <h5 class="brand-name" routerLink="/home">
    AccountingERP
    <!--<a href="javascript:void(0)" class="menu_option float-right"><i class="icon-grid font-16" data-toggle="tooltip" data-placement="left" title="Grid & List Toggle"></i></a>-->
  </h5>
  <nav id="left-sidebar-nav" class="sidebar-nav">
    <ul class="metismenu">
      <ng-template ngFor let-menu [ngForOf]="menus">
        <ng-container *ngIf="menu.role_id.indexOf(authenticationService.getUserRole()) >=0">

          <!-- [ngClass]="menu.is_open==true?'active mm-active':'active'" -->
          <li *ngIf="menu?.is_child!=true" class="treeview" (click)="menu.is_open=!menu.is_open;">
            <a *ngIf="menu.type ==1 && menu?.name!=null" class="nav-link menu-li" routerLink="{{menu.path}}"
              routerLinkActive="active">
              <i class="{{menu?.icon}}"></i>

              <span class="menu-link">{{menu?.name}}</span>
            </a>
            <!-- (click)="menu.is_open ==true?false:true" -->
            <a *ngIf="menu.type ==2" class="has-arrow arrow-c nav-link menu-li" [attr.aria-expanded]="menu.is_open"
              routerLinkActive="active" >
              <i class="{{menu?.icon}}"></i>

              <span class="menu-link">{{menu?.name}}</span>
              <i class="icon icon-angle-left s-18 pull-right"></i>
            </a>

            <ul [ngClass]="menu.is_open==true?'mm-collapse mm-show':'mm-collapse'"
              class="child-menu" *ngIf="menu.childrens.length>0">
              <ng-template ngFor let-child_menu_id [ngForOf]="menu.childrens">
                <ng-container>
                  <!-- *ngIf="userRoleMenuPermissions[authenticationService.getUserRole()].indexOf(child_menu_id)>=0"      -->
                  <li class="" *ngIf="getMenuNameById(child_menu_id)!=false" (click)="$event.stopPropagation();toggleMenuIsOpen(child_menu_id);">
                    <a *ngIf="getMenuType(child_menu_id) ==2" class="has-arrow arrow-c nav-link menu-li"
                      [attr.aria-expanded]="menu.is_open" routerLinkActive="active" href="javascript:void(0);">
                      <i class="{{getMenuICONById(child_menu_id)}}"></i>

                      <span class="menu-link">{{getMenuNameById(child_menu_id)}}</span>
                      <i class="icon icon-angle-left s-18 pull-right"></i>
                    </a>
                  
                    <a *ngIf="getMenuType(child_menu_id) ==1" class="has-arrow arrow-c nav-link menu-li" class="nav-link menu-li"
                      routerLink="{{getMenuURLById(child_menu_id)}}" routerLinkActive="active">
                      <i class="{{getMenuICONById(child_menu_id)}}"></i>

                      {{getMenuNameById(child_menu_id)}}
                    </a>
                    <ul 
                      [ngClass]="getMenuIsOpen(child_menu_id)==true?'mm-collapse mm-show':'mm-collapse'" class="child-child-menu"
                      *ngIf="getMenuChildrensNameById(child_menu_id).length>0">
                      <ng-template ngFor let-child_child_menu_id [ngForOf]="getMenuChildrensNameById(child_menu_id)">
                        <ng-container>
                        <!-- *ngIf="userRoleMenuPermissions[authenticationService.getUserRole()].indexOf(child_menu_id)>=0"      -->
                        <li (click)="$event.stopPropagation()" class="" *ngIf="getMenuNameById(child_child_menu_id)!=false">
                          <a class="has-arrow arrow-c nav-link menu-li" class="nav-link menu-li"
                          routerLink="{{getMenuURLById(child_child_menu_id)}}" href="javascript:void(0)" routerLinkActive="active">
                          <i class="{{getMenuICONById(child_child_menu_id)}}"></i>
    
                          {{getMenuNameById(child_child_menu_id)}}
                        </a>
                        </li>
                      </ng-container>
                    </ng-template>
                    </ul>
                  </li>
                </ng-container>
              </ng-template>
            </ul>
          </li>
        </ng-container>
      </ng-template>
    </ul>
  </nav>
</div>