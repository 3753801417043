import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { AuthenticationService } from "./authentication.service";
@Injectable({ providedIn: "root" })
export class CreditBillService {
  branch_id: string;
  constructor(
    private http: ApiService,
    private authenticationService: AuthenticationService
  ) {
    this.branch_id = this.authenticationService.getDefaultBranchId();
  }

  addCreditBill(credit_bill_data: any) {
    return this.http.post(ApiService.geturl("credit_bill", "add"), {
      data: credit_bill_data,
    });
  }
  viewBill(id: string, condition: {}) {
    // if (this.branch_id != "") {
    //   condition["branch_id"] = this.branch_id;
    // }
    return this.http.post(ApiService.geturl("creditnote", "view"), {
      bill_id: id,
      condition: condition,
    });
  }
  editCreditBill(credit_bill_data: any, id: string) {
    return this.http.post(ApiService.geturl("credit_bill", "edit"), {
      data: credit_bill_data,
      credit_bill_id: id,
    });
  }
  deleteCreditBill(id: string) {
    return this.http.post(ApiService.geturl("creditnote", "delete"), {
      id: id,
    });
  }

  // viewCreditBill(id: string) {
  //   return this.http.post(ApiService.geturl("creditnote", "view"), {
  //     credit_bill_id: id,
  //   });
  // }

  getCreditBills(
    filters: any = {},
    columns: any = {},
    sort: any = {},
    page: any = {}
  ) {
    if (this.branch_id != "") {
      filters["branch_id"] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("creditnote", "viewall"), {
      filters: filters,
      columns: columns,
      sort: sort,
      page: page,
    });
  }
  getCreditNotes(
    filters: any = {},
    columns: any = {},
    sort: any = {},
    page: any = {}
  ) {
    if (this.branch_id != "") {
      filters["branch_id"] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("creditnote", "get_all"), {
      filters: filters,
      columns: columns,
      sort: sort,
      page: page,
    });
  }
  updateCredit(credit_ids, type) {
    return this.http.post(
      ApiService.geturl("creditnote", "update_creditnote"),
      {
        credit_ids: credit_ids,
        type: type,
      }
    );
  }
  confirmCreditNote(row_data) {
    return this.http.post(ApiService.geturl("creditnote", "confirm"), {
      credit_note_data: row_data,
    });
  }
  UpdateCreditStatus(data,condition){
    return this.http.post(ApiService.geturl("creditnote", "update_creditnote_filestatus"), {
      data: data,
      condition: condition
    });
  }
}
