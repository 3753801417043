import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import swal from "sweetalert2";
import { VoucherTypeService } from "../../_services/voucher-type.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { VoucherType } from "../../_models/voucher-type";
import { ThrowStmt } from "@angular/compiler";
import { AuthenticationService } from "../../_services/authentication.service";

@Component({
  selector: "app-voucher-type-add",
  templateUrl: "./voucher-type-add.component.html",
  // styleUrls: ['./voucher-type-add.component.scss']
})
export class VoucherTypeAddComponent implements OnInit, OnChanges {
  @Input() voucherCategoryCode: number = 0;

  id: string;
  is_edit_form: boolean = false;
  voucherType: VoucherType = new VoucherType();
  categoryList: any = [
    { category_name: "sales", category_code: 1 },
    { category_name: "purchase", category_code: 2 },
    { category_name: "cash", category_code: 3 },
    { category_name: "bank", category_code: 4 },
    { category_name: "contra", category_code: 5 },
    { category_name: "journal", category_code: 6 },
  ];
  header: string;
  category_code: any = {};
  company_id: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private voucherTypeService: VoucherTypeService,
    private authenticationService: AuthenticationService

  ) {}

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get("id");

      if (this.id != null) {
        this.getVoucherType(this.voucherCategoryCode);
      }

      var result = this.router.url.match("/edit/");
      if (result != null) {
        if (result[0] === "/edit/" && this.id != null) {
          this.is_edit_form = true;
          this.header = "Edit";
        }
      } else {
        this.header = "Add";
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.voucherCategoryCode.currentValue != undefined) {
      if (changes.voucherCategoryCode.currentValue > 0) {
        let category = this.categoryList.filter(
          (category) =>
            category.category_code == changes.voucherCategoryCode.currentValue
        )[0];

        this.voucherType.category_name = category.category_name;
        this.voucherType.category_code = category.category_code;
      }
    }
  }
  //   this.getVoucher-typesList();

  addVoucherType() {
    if (this.voucherCategoryCode == 0) {
      this.voucherType.category_code = this.category_code.category_code;
      this.voucherType.category_name = this.category_code.category_name;
    }
    this.voucherType.company_id = this.company_id;
    this.voucherTypeService
      .addVoucherType(this.voucherType)
      .subscribe((result) => {
        if (result["status_code"] === 1) {
          if (this.voucherCategoryCode == 0) {
            swal(
              "Added voucher-type sucessfully!",
              "voucher-type added.",
              "success"
            );
            this.router.navigateByUrl("/masters/voucher_type/list");
          }
          if (this.voucherCategoryCode != 0) {
            swal(
              "Added voucher-type sucessfully!",
              "voucher-type added.",
              "success"
            );
            window.location.reload();
            this.router.navigateByUrl("/bill/list");
          }
        } else {
          this.showErrors(result[" response "][" errors "]);
        }
      });
  }
  getVoucherType(voucherCategoryCode) {
    console.log("voucherCategoryCode=====", voucherCategoryCode);
    if (voucherCategoryCode != "null") {
      this.voucherTypeService
        .viewVoucherType(this.id, this.voucherCategoryCode)
        .subscribe((result: VoucherType) => {
          this.voucherType = result;
          this.category_code["category_code"] = result["category_code"];
          this.category_code["category_name"] = result["category_name"];
        });
    }
  }

  addUpdateVoucherType() {
    if (this.is_edit_form === true) {
      this.editVoucherType();
    } else {
      this.addVoucherType();
    }
  }

  editVoucherType() {
    this.voucherTypeService
      .editVoucherType(this.voucherType, this.id)
      .subscribe((result) => {
        if (result["status_code"] == 1) {
          swal(" Success ", " Voucher-type Updated Successfully ", "success");
          this.router.navigateByUrl("/masters/voucher_type/list");
        } else {
          this.showErrors(result[" response "][" errors "]);
        }
      });
  }

  showErrors(errors) {
    let error_string: string;
    error_string = " ";
    errors.forEach((item) => {
      error_string += " < br / > " + item;
    });
    swal({
      title: "Error",
      html: error_string,
      type: "error",
    }).then(function () {});
  }
}
