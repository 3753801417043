import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { Fixedassests } from "../_models/fixed_assests.model";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { FixedAssestsService } from "../_services/fixed-assests.service";
import { AuthenticationService } from "../_services/authentication.service";
@Component({
  selector: "app-fixed-assets",
  templateUrl: "./fixed-assets.component.html",
  styleUrls: ["./fixed-assets.component.scss"],
})
export class FixedAssetsComponent implements OnInit {
  fixed_assests: Fixedassests = new Fixedassests();
  fixed_assests_lists: Object;
  fixedAssestsListTypeahead = new EventEmitter<string>();
  page_icon: any;
  loadingIndicator = false;
  page: any = { offset: 0, pageSize: 10 };
  dt_default_sort = [{ prop: "group_name", dir: "asc" }];
  fixedAssestsList: any = [];
  fixedAssestsListColumns: any = [];
  sort: any = { group_name: 1 };
  columns: any = {};
  company_id: string;
  filters: any = {};
  branch_id: string;
  constructor(
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private fixedAssestsService: FixedAssestsService
  ) {}

  ngOnInit() {
    
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();

    this.fixedAssestsListColumns = [
      {
        prop: "group_name",
        name: "Group Name",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false
      },
      {
        prop: "description",
        name: "Description",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false
      },
      {
        prop: "useful_life",
        name: "Useful Life",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false
      },
      {
        prop: "income_tax_rate",
        name: "Income Tax Rate",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false
      },
      {
        name: "Action",
        type: "action",
        prop: "_id",
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false
      }
    ];
    this.fixedAssestsListColumns.forEach(column => {
      this.columns[column.prop] = 1;
    });
    this.getFixedAssestsList(this.page);

  }

  onSort(event) {
    this.loadingIndicator = true;
    // console.log("event", event);
    this.sort = {};
    let sort_details = event.sorts[0];
    this.sort[sort_details.prop] = sort_details.dir == "asc" ? 1 : -1;
    this.getFixedAssestsList(this.page);
  }

  getFixedAssestsList(page_info: any) {
    if (page_info != null) {
      this.page = page_info;
    }
    this.filters["company_id"] = this.company_id;
    this.fixedAssestsService.getFixedAssests(this.filters, this.columns, this.sort, this.page)
    .subscribe(result => {
      this.loadingIndicator = false;
      this.fixedAssestsList = result["response"]["data"];
      this.page.count = result["response"]["count"];
    });

    // this.fixed_assests_lists = [];
    // let condition: any = {};
    // let columns: any = {};
    // if (searchString != "") {
    //   condition["name"] = searchString;
    // }

    // return this.fixedAssestsService.getFixedAssests(
    //   condition,
    //   columns,
    //   {},
    //   { limit: 10 }
    // );
  }
  // getFixedAssestsSearch() {
  //   this.fixedAssestsListTypeahead
  //     .pipe(
  //       debounceTime(300),
  //       distinctUntilChanged(),
  //       switchMap((searchString) => this.getFixedAssestsList(searchString))
  //     )
  //     .subscribe(
  //       (result) => {
  //         this.cd.markForCheck();
  //         this.fixed_assests_lists = result["response"]["data"];
  //       },
  //       (err) => {
  //         console.log(err);
  //         this.fixed_assests_lists = [];
  //       }
  //     );
  // }
}
