import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from "../../../_services/authentication.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { CreditBillService } from '../../../_services/credit_bill.service';
@Component({
  selector: 'app-debit-bill-view',
  templateUrl: './credit-bill-view.component.html',
  styleUrls: ['./credit-bill-view.component.scss']
})
export class CreditBillViewComponent implements OnInit {
invoice: any = {};
invoice_id: string;
// company_details: any; 
filters: any = {};
company_details: any;
overall_total_amount: number;
  rem_amount: number;
  branch_id: any;
  company_id: any;
  constructor(
    private route: ActivatedRoute,
    private debitBillService: CreditBillService,
    private authenticationService : AuthenticationService
  ) { }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.route.paramMap.subscribe(params => {
      this.invoice_id = params.get("id");
      if (this.invoice_id != "") {
        this.viewInvoice();

      }
    });
  }
  viewInvoice() {
    let total_amount = 0;
    let condition: any = {};
    condition['comapny_id'] = this.company_id;
    this.debitBillService
      .viewBill(this.invoice_id, condition)
      .subscribe(result => {
        this.invoice = result;
        this.invoice.invoice_items.forEach(element => {

          total_amount = total_amount + parseInt(element.actual_amount);
          this.overall_total_amount = total_amount;

        });
        this.rem_amount = this.invoice.balance_amount;

        this.filters["customer_id"] = result["customer"]["_id"];
        this.filters["invoice_id"] = this.invoice_id;
        this.filters['company_id'] = this.company_id;
        // this.getAdvanceAmount(this.filters);
        // this.getCreditAmount(this.filters);
        // if (this.bill.image) {
        //   this.path = this.apiService.getImageUrl(this.bill.image.path);
        // }
        
      });
  }

}
