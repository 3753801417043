<div class="col-12 mb-4">
  <a class="btn btn-primary float-right" routerLink="/support-ticket">Add ticket</a>
  <button (click)="syncTickets()" class="btn btn-info float-right mr-2"><i class="fe fe-refresh-cw"></i></button>
  <!-- <app-report-filters (getFilterData)="search($event)" [filters]="tableColumns" [name]="'Support Tickets'">
    </app-report-filters> -->
</div>
<!-- 
<div class="col-12">
    
</div> -->

<div class="m-1 p-5 card">
  <app-tabular-report [data]="supportTicketList" [pageSize]="10" [columns]="tableColumns"
    (pageChange)="pageChange($event)" (sortChange)="applySort($event)">
  </app-tabular-report>
</div>
<ng-template #actionTemplate let-row="row" let-value="value">
  <a class="btn btn-primary" routerLink="/support-ticket/edit/{{row._id}}"><i class="fe fe-eye"></i></a></ng-template>
