<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header d-flex align-items-center">
          <h3 class="table-title">Expense Payment</h3>
        </div>
        <form novalidate #addReceiptPaymentForm="ngForm">
          <div class="row">

            <!-- <div class="col-2 mb-2">
              Book<div class="form-group col-9 custom-switch col-sm-9 col-md-9 col-xl-9 pl-5">
                <input type="checkbox" value="true" (change)="is_book_and_pay==true? false: true"
                  [(ngModel)]="is_book_and_pay" name="is_book_and_pay" class="custom-control-input"
                  id="is_book_and_pay">
                <label class="custom-control-label" for="is_book_and_pay">Book & Pay</label>
              </div>
            </div> -->

            <!-- <div class="form-group col-3 col-sm-3 col-md-3 col-xl-3 required">

              <div class="form-group col-12 custom-switch col-sm-6 col-md-3 col-xl-3 pl-5">
                <label class="custom-control-label" for="payee_type">Expense</label>
                <input type="checkbox" value="true" [(ngModel)]="is_payee" name="payee_type"
                  (ngModelChange)="clearAmount()" class="custom-control-input" id="payee_type" />

              </div>
            </div> -->
          </div>
          <div class="card-body row">


            <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
              <label class="col-form-label">Payment Method</label>
              <div class="">
                <ng-select [items]="payment_methodList" (change)="getPaymentInChange()"
                  [typeahead]="paymentMethodListTypeahead" name="payment_method" #payment_method="ngModel"
                  [(ngModel)]="expense.payment_method">
                  <ng-template ng-label-tmp let-item="item">
                    {{ item.name }}
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    {{ item.name }}
                  </ng-template>
                </ng-select>
                <app-validation-errors [mformField]="payment_method" label="Payment Method">
                </app-validation-errors>
              </div>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 required">
              <label class="col-form-label">{{paymentMethod}} Accounts</label>
              <div class="">
                <ng-select [items]="payment_accountList" [typeahead]="accountHeadListTypeahead" name="payment_account"
                  id="payment_account" required #payment_account="ngModel" bindLabel="name"
                  [(ngModel)]="expense.payment_account">
                  <ng-template ng-label-tmp let-item="item">
                    <div [ngOptionHighlight]="search">
                      {{ item?.flattened_data?.name }}
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item?.flattened_data?.name }}
                    </div>
                  </ng-template>
                </ng-select>
                <app-validation-errors [mformField]="payment_account" label="Payment Account">
                </app-validation-errors>
              </div>
            </div>
            <!-- <div class="form-group  required col-12 col-sm-6 col-md-3 col-xl-3">
              <label class="col-form-label" for="voucher_type">Voucher Type</label>
              <app-voucher-type (onSelectVoucher)="getVoucherType($event)" [voucherCategoryCode]=4></app-voucher-type>
            </div> -->
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label class="col-form-label" for="ref_id">Ref Id</label>
              <input type="text" class="form-control" name="ref_id" placeholder="Reference ID"
                [(ngModel)]="expense.ref_id" />
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label class="col-form-label" for="ref_id">Voucher Number</label>
              <input type="text" class="form-control" name="voucher_number" placeholder="Voucher Number"
                [(ngModel)]="expense.voucher_number" />
            </div>



            <div *ngIf="is_payee" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label class="col-form-label">Payee</label>
              <div class="">
                <ng-select [items]="payeeList" (change)="checkTaxBasedOnState()" [typeahead]="payeeListTypeahead"
                  name="payee" #payee="ngModel" [(ngModel)]="expense.payee" id="payee" bindLabel="name">
                  <ng-template ng-label-tmp let-item="item">
                    <div [ngOptionHighlight]="search">
                      {{ item?.flattened_data?.name }}
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item?.flattened_data?.name }}
                    </div>
                  </ng-template>
                </ng-select>
                <app-validation-errors [mformField]="payee" label="Payee">
                </app-validation-errors>
              </div>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 required">
              <label class="col-form-label">Expense Date</label>
              <div class="">
                <div class="input-group">
                  <input angular-mydatepicker placeholder="Booking Date" required
                    (click)="booking_date_dp.toggleCalendar()" #booking_date_dp="angular-mydatepicker"
                    class="form-control" name="booking_date" [(ngModel)]="expense.booking_date"
                    [options]="bookingDateDPOptions" />
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="expense.booking_date"
                      (click)="booking_date_dp.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="booking_date_dp.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>
                <app-validation-errors [mformField]="booking_date_dp" label="Booking Date">
                </app-validation-errors>
              </div>
            </div>
            <table class="table table-striped table-bordered">
              <thead class="thead-light">
                <tr>
                  <th style="width: 70%;"  *ngIf="user_id !=8">Expense</th>
                  <!-- <th *ngIf="!is_payee">Tax</th> -->
                  <!-- <th *ngIf="!is_payee">CGST</th>
                  <th *ngIf="!is_payee">SGST</th>
                  <th *ngIf="!is_payee">IGST</th> -->
                  <th>Amount</th>
                  <th style="width: 70%;"  *ngIf="user_id ==8">Notes</th>

                  <!-- <th>TDS</th> -->
                  <!-- <th *ngIf="!is_payee">Total Tax</th> -->

                  <!-- <th>Total Amount</th> -->

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="
                    let expense_item of expense.expense_items;
                    let i = index
                  ">
                  <td  *ngIf="user_id !=8">
                    <ng-select  class="mb-1" [items]="expenseList" [typeahead]="expenseListTypeahead"
                      name="category{{ i }}" required #expense_category="ngModel" [(ngModel)]="expense_item.category"
                      (change)="expense_item.tax=0;
                      getHsnData(i);getCostGroup(expense_item)" id="category{{ i }}" bindLabel="name">
                      <ng-template ng-label-tmp let-item="item">
                        <div [ngOptionHighlight]="search">
                          {{ item?.flattened_data?.name }}
                        </div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <div [ngOptionHighlight]="search">
                          {{ item?.flattened_data?.name }}
                        </div>
                      </ng-template>
                    </ng-select>
                    <app-validation-errors *ngIf="user_id !=8" [mformField]="expense_category" label="Expense">
                    </app-validation-errors>

                    <textarea  maxlength="30" type="textarea" class="form-control" name="description{{ i }}" #description="ngModel"
                      id="description{{ i }}" [(ngModel)]="expense_item.description"
                      placeholder="Notes"></textarea>
                    <app-validation-errors [mformField]="description" label="Description">
                    </app-validation-errors>

                    <!-- <input type="text" class="form-control"
                                        name="category{{i}}"
                                        #category="ngModel"
                                        id="category{{i}}"
                                        [(ngModel)]="receipt_items.category"
                                        placeholder="">
                                        <app-validation-errors
                                        [mformField]="category"
                                        label="Category">
                                        </app-validation-errors> -->
                    <ng-container *ngIf="expense_item?.cost_group_items?.length > 0">
                      <table style="width: 90%;margin-left:10%">
                        <thead class="thead-light">
                          <tr>
                            <th colspan="2">CostCenter - {{expense_item.cost_group_name}}</th>
                          </tr>
                        </thead>
                        <tr *ngFor="let cost_group of expense_item.cost_group_items; let j = index">
                          <td style="width: 80%;">{{cost_group.costcenter_name}}</td>
                          <td><input type="number" class="form-control " placeholder="Amount"
                              (change)="changeExpenseAmount(i,j,$event ,expense_item)"
                              [(ngModel)]="cost_group.cost_group_amount" name="cost_group_amount{{i}}{{j}}"></td>
                        </tr>

                      </table>
                    </ng-container>
                    <!-- <tr
                              *ngFor="let cost_group of expense_items.cost_group_items">
                          <td>
                            {{cost}}
                          </td>

                        </tr> -->
                  </td>

                  <!-- <td *ngIf="!is_payee">
                    <input readonly type=" number" readonly class="form-control" name="tax{{ i }}" #tax="ngModel"
                      id="tax{{ i }}" [(ngModel)]="expense_item.tax" placeholder="" />
                    <app-validation-errors [mformField]="tax" label="Tax">
                    </app-validation-errors>
                  </td> -->
                  <!-- <td *ngIf="!is_payee && is_same_state">
                    <input type="number" readonly class="form-control" name="cgst_tax{{ i }}" #cgst_tax="ngModel"
                      id="cgst_tax{{ i }}" [(ngModel)]="expense_item.cgst_tax" placeholder="" />
                    <app-validation-errors [mformField]="cgst_tax" label="CGST">
                    </app-validation-errors>
                  </td>
                  <td *ngIf="!is_payee && is_same_state">
                    <input type="number" readonly class="form-control" name="sgst_tax{{ i }}" #sgst_tax="ngModel"
                      id="sgst_tax{{ i }}" [(ngModel)]="expense_item.sgst_tax" placeholder="" />
                    <app-validation-errors [mformField]="sgst_tax" label="SGST">
                    </app-validation-errors>
                  </td>
                  <td *ngIf="!is_payee">
                    <input type="number" readonly class="form-control" name="igst_tax{{ i }}" #igst_tax="ngModel"
                      id="igst_tax{{ i }}" [(ngModel)]="expense_item.igst_tax" placeholder="" />
                    <app-validation-errors [mformField]="igst_tax" label="IGST">
                    </app-validation-errors>
                  </td> -->

                  <td>
                    <input type="number" required [min]="0" class="form-control" name="amount{{ i }}" #amount="ngModel"
                      (ngModelChange)="calculateTotalAmount($event, i)" id="amount{{ i }}"
                      [(ngModel)]="expense_item.amount" placeholder="" />
                    <app-validation-errors [mformField]="amount" label="amount">
                    </app-validation-errors>
                  </td>
                  <td  *ngIf="user_id ==8">
                    <textarea  maxlength="30"  type="textarea" required class="form-control" name="description{{ i }}" #description="ngModel"
                    id="description{{ i }}" [(ngModel)]="expense_item.description"
                    placeholder="Description"></textarea>
                  </td>
                  <!-- <td *ngIf="!is_payee">
                    <input type="number" readonly class="form-control" name="total_tax{{ i }}" #tax_amount="ngModel"
                      id="tax_amount{{ i }}" [(ngModel)]="expense_item.tax_amount" placeholder="Total tax" />
                    <app-validation-errors [mformField]="tax_amount" label="Tax Amount">
                    </app-validation-errors>
                  </td> -->
                  <!-- <td>
                    <input type="number" readonly [min]="0" class="form-control" name="total_amount{{ i }}"
                      #total_amount="ngModel" id="total_amount{{ i }}" [(ngModel)]="expense_item.total_amount"
                      placeholder="" />
                    <app-validation-errors [mformField]="total_amount" label="Total Amount">
                    </app-validation-errors>
                  </td> -->
                  <td>
                    <button type="button" class="btn btn-primary mr-2" (click)="addNewItem(i)">
                      <i class="fa fa-plus"></i>
                    </button>
                    <button *ngIf="i > 0" type="button" class="btn btn-danger" (click)="deleteItem(i)">
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="card-footer">
            <button *ngIf="is_payee" (click)="is_book_and_pay=false; addUpdateExpense()" type="button"
              [disabled]="!addReceiptPaymentForm.valid" class="btn btn-primary mr-2">
              Add Expense
            </button>
            <button (click)="is_book_and_pay=true; addUpdateExpense()" type="button"
              [disabled]="!addReceiptPaymentForm.valid || loading==true" class="btn btn-primary">
              {{is_payee==true? 'Add Expense & Payment': 'Expense Payment'}}
              <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
            </button>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>
