import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { HttpClient } from '@angular/common/http';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor(
    private authenticationService: AuthenticationService,
    private permissionService: NgxPermissionsService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    if (environment.production) {
      this.AddUmamiAnalysis();
    }

    // const user_role = {"ADMIN", "MANAGER", "ACCOUNTANT", "USER"};
    this.permissionService.loadPermissions([this.authenticationService.getUserRoleName()]);
    // this.http.get('url').subscribe((permissions : any) => {
    //   this.permissionService.loadPermissions(permissions);
    // });
    // this.authenticationService.getUserRole();
    // this.permissionService.loadPermissions(user_role, (permissionName, permissionStore) => {
    //   return !!permissionStore[permissionName];
    // });
  }

  getDefaultCompanyId() {
    return this.authenticationService.getSelectedCompanyId();
  }
  isAuthenticated() {
    return this.authenticationService.isAuthenticated();
  }

  //#region Umami

  AddUmamiAnalysis() {
    let nodeUmami = document.createElement('script'); // creates the script tag
    nodeUmami.src = environment.umamiInsights.srcUrl; // sets the source (insert url in between quotes)
    nodeUmami.async = true; // makes script run asynchronously
    nodeUmami.defer = true;
    nodeUmami.setAttribute('data-website-id', environment.umamiInsights.appCode);
    // append to head of document
    document.getElementsByTagName('head')[0].append(nodeUmami);
  }

  //#endregion
}
