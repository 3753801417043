export class User {
  // current_selected_company: any = {}
  firstname: string;
  lastname: string;
  gender: number;
  email: string;
  username: string;
  password: string;
  new_password: string;
  confirm_password: string;
  company_ids: any = [];
  company_details: any = [];
  branch_ids: any = [];
  user_role: any = {};
  dob: any;
  address: string;
  phone: number;
  country: object;
  state: object;
  city: string;
  reporting_to: string;
}

