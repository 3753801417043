import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { AuthenticationService } from "./authentication.service";

@Injectable({ providedIn: "root" })
export class DebitBillService {
  branch_id: string;
  constructor(
    private http: ApiService,
    private authenticationService: AuthenticationService
  ) {
    this.branch_id = this.authenticationService.getDefaultBranchId();
  }

  addDebitBill(debit_bill_data: any) {
    return this.http.post(ApiService.geturl("debit_bill", "add"), {
      data: debit_bill_data,
    });
  }
  editDebitBill(debit_bill_data: any, id: string) {
    return this.http.post(ApiService.geturl("debit_bill", "edit"), {
      data: debit_bill_data,
      debit_bill_id: id,
    });
  }
  deleteDebitBill(id: string) {
    return this.http.post(ApiService.geturl("debitnote", "delete"), {
      id: id,
    });
  }

  viewDebitBill(id: string, condition: {}) {
    return this.http.post(ApiService.geturl("debitnote", "view"), {
      invoice_id: id,
      condition: condition,
    });
  }

  getDebitBills(
    filters: any = {},
    columns: any = {},
    sort: any = {},
    page: any = {}
  ) {
    if (this.branch_id != "") {
      filters["branch_id"] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("debitnote", "viewall"), {
      filters: filters,
      columns: columns,
      sort: sort,
      page: page,
    });
  }
  getDebitNotes(
    filters: any = {},
    columns: any = {},
    sort: any = {},
    page: any = {}
  ) {
    if (this.branch_id != "") {
      filters["branch_id"] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("debitnote", "get_all"), {
      filters: filters,
      columns: columns,
      sort: sort,
      page: page,
    });
  }
  updateDebit(debit_ids, type) {
    return this.http.post(ApiService.geturl("debitnote", "update_debitnote"), {
      debit_ids: debit_ids,
      type: type,
    });
  }
  confirmDebitNote(row_data) {
    return this.http.post(ApiService.geturl("debitnote", "confirm"), {
      debit_note_data: row_data,
    });
  }
}
