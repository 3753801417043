import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  Input,
} from "@angular/core";
import swal from "sweetalert2";
import { BillService } from "../../_services/bill.service";
import { AuthenticationService } from "../../_services/authentication.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { MastersService } from "../../_services/masters.service";
import { PaymentSettings } from "../../_models/payment-settings.model";
import { InvoiceService } from "../../_services/invoice.service";
import { AccountsHeadTemplate } from "../../_models/accounts-head-template.model";

@Component({
  selector: "app-map-modal",
  templateUrl: "./map-modal.component.html",
  styleUrls: ["./map-modal.component.scss"],
})
export class MapModalComponent implements OnInit {
  @Input() selectedarray_mapdata: any = [];
  invoice_array = [];
  sum_of_bal_qty: number = 0;
  check_status: boolean;
  transaction_head: string;
  payment_note: string;

  public payment = new PaymentSettings();
  pay_head: string;
  headList: any = [];
  dueDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    dateFormat: "dd.mm.yyyy",
  };
  model: IMyDateModel = null;
  tx_date: IMyDateModel;

  //Common settings
  db_columns: any = {};
  payee: string;
  payee_name: string;
  balance_amt: number = 0;
  payType: any;
  payment_accountList: any = [];
  payment_methodList: any = [
    { name: "Cash", account_head: 1031 },
    { name: "Bank", account_head: 1030 },
    //   { name: "Check", id: 3 }
  ];
  company_id: any;
  payment_array: any = [];
  tx_ref_id: number;
  bill_amount: any;
  advance_amount: any;
  total_selected_amount = 0;

  paymentAccountConfig: AccountsHeadTemplate;

  constructor(
    // private purchaseorderservice: PurchaseorderService,
    private billService: BillService,
    private invoiceService: InvoiceService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private masterService: MastersService,

    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    console.log("---------selectedArray-----<>>>", this.selectedarray_mapdata);
    this.company_id = this.authenticationService.getDefaultCompanyId();

    let condition: any = [];
    condition["_id"] = this.selectedarray_mapdata["bill_ids"];
    condition["bill_number"] = this.selectedarray_mapdata["bill_number"];

    this.getBills(condition, this.selectedarray_mapdata["payment_from"]);
    this.bill_amount = this.selectedarray_mapdata["bill_amount"];
  }

  getBills(bill_ids, payment_from) {
    this.advance_amount = this.selectedarray_mapdata[
      "adv_advance_based_amount"
    ];

    this.selectedarray_mapdata["bill_ids"].forEach((element, key) => {
      this.total_selected_amount =
        this.total_selected_amount +
        parseInt(this.selectedarray_mapdata["bill_amount"][key]);
      this.payment_array.advance_amount_tomap = this.advance_amount;

      this.payment_array.push({
        bill_number: this.selectedarray_mapdata["bill_number"][key],
        bill_ids: this.selectedarray_mapdata["bill_ids"][key],
        bill_amount: this.selectedarray_mapdata["bill_amount"][key],
      });
    });
    console.log(this.payment_array);
  }
  payBill() {
    let successMsg = "";
    let successShortMsg = "";
    this.company_id = this.authenticationService.getDefaultCompanyId();
    let dataObj: any = {};
    if (this.selectedarray_mapdata["payment_from"] == "customer") {
      dataObj = {
        customer_id: this.selectedarray_mapdata["vendor_id"],
        advanceid: this.selectedarray_mapdata["advanceid"],
        txDate: this.tx_date,
        adv_from: "customer",
        advance_amount: this.selectedarray_mapdata["adv_advance_based_amount"],
      };
      successMsg = "Invoice mapped successfully!";
      successShortMsg = "Invoice mapped";
    } else {
      dataObj = {
        vendor_id: this.selectedarray_mapdata["vendor_id"],
        advanceid: this.selectedarray_mapdata["advanceid"],
        txDate: this.tx_date,
        adv_from: "vendor",
        advance_amount: this.selectedarray_mapdata["adv_advance_based_amount"],
      };
      successMsg = "Bills mapped successfully!";
      successShortMsg = "Bills mapped";
    }
    this.billService
      .mapBills(this.payment_array, this.company_id, dataObj)
      .subscribe((result) => {
        swal(successMsg, "bills mapped.", "success");
      });
  }
  updatePaymentAccountLedger(accountHead: any) {
    this.payment.payment_account = accountHead;
  }
  setPaymentAccountHeaderConfig() {
    if (this.payment.payment_method.account_head) {
      this.paymentAccountConfig = {
        accountsHeads: [],
        defualt_value: null,
        is_required: null,
        field_name: null,
        label_name: null,
        is_set: false,
      };
      this.paymentAccountConfig.accountsHeads = [
        this.payment.payment_method.account_head,
      ];
      this.paymentAccountConfig.is_required = true;
      this.paymentAccountConfig.field_name = "payment_account";
      this.paymentAccountConfig.label_name =
        this.payment.payment_method.account_head == 1031
          ? "Cash Accounts"
          : "Bank Accounts";
    }
  }
}
