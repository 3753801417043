<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <form novalidate #searchHsnForm="ngForm" (ngSubmit)="getPaginatedHsn(page)">
        <div class="card">
          <div class="card-body">
            <div class=" mr-3 mb-2 float-right">
              <button class="btn btn-primary float-right mb-4" routerLink="/masters/hsn/item/add">
                <i class="icon icon-add"></i>Add Hsn
              </button>
              <button type="button" class="btn btn-primary float-right mb-4 mr-2"
                (click)="open(content)">Import</button>

            </div>
            <h3 class="table-title"><i class="fa fa-gear"></i> Hsn </h3>

            <div class="card-body row">
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label>HSN Code</label>
                <input type="text" name="hsn_code" class="form-control" placeholder="HSN Code"
                  [(ngModel)]="filter.code">
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label>Chapter</label>
                <input type="number" name="chapter" class="form-control" placeholder="Chapter"
                  [(ngModel)]="filter.chapter">
              </div>
            </div>


            <div>
              <button type="submit" class="btn btn-primary mr-2"><i class="fa fa-search"></i>
                Search</button>
              <button type="button" (click)="clearHsnList();" class="btn btn-danger"><i class="fa fa-close"></i>
                Clear
              </button>

            </div>
          </div>
        </div>
      </form>


      <div class=" card p-sm-0">
        <div class="card-body">

          <ngx-datatable [rows]="hsn.data" [loadingIndicator]="loadingIndicator" [externalPaging]="true"
            [columnMode]="'force'" [count]="hsn.count" [limit]="page.pageSize" [offset]="page.offset"
            (page)="getPaginatedHsn($event)" [externalSorting]=true (sort)="onSort($event)" [footerHeight]="50"
            [rowHeight]="'auto'" class="material striped" [sorts]="this.dt_default_sort">

            <ngx-datatable-column *ngFor="let col of hsnTableListcolumns" [flexGrow]="col.flexGrow" [name]="col.name"
              [sortable]="col.sortable" [prop]="col.prop">
              <ng-template ngx-datatable-header-template let-sort="sortFn">
                <span (click)="sort()">{{ col.name }}</span>
              </ng-template>
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <div [ngSwitch]="col.type">

                  <span *ngSwitchCase="'effects_from'">
                  <span *ngFor="let effective_from of row.effects_from">
                    <span *ngIf="effective_from.is_active == true">
                      <b>CGST</b>-{{effective_from.cgst}},
                      <b>SGST</b>-{{effective_from.sgst}},
                      <b>IGST</b>-{{effective_from.igst}}
                    </span>
                  </span>
                </span>
                  <span *ngSwitchCase="'action'">
                    <div class="button-row">
                      <button class="btn btn-primary mr-2" routerLink="/masters/hsn/item/edit/{{row._id}}" mat-mini-fab color="primary"
                        title="Edit">
                        <i class="fa fa-edit"></i>
                      </button>

                      <!-- <button class="btn btn-danger" (click)="deleteHSN(row._id)" mat-mini-fab color="warn"
                        title="Delete">
                        <i class="fa fa-trash"></i>
                      </button> -->
                    </div>

                  </span>
                  <span *ngSwitchDefault>
                    {{ row[col.prop] }}
                  </span>
                </div>

              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>

        </div>

        <!-- <ng-template #actionTmpl let-value="value">

            <div class="btn-group mr-3">
              <button class="btn btn-primary" routerLink="/hsn/edit/{{value}}" type="button" rel="tooltip">Edit</button>
              <div class="btn-group" ngbDropdown placement="bottom-right" role="group">
                <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <button class="dropdown-item" routerLink="/sales-order/edit/{{value}}" type="button"
                    rel="tooltip">Edit</button>
                  <button class="dropdown-item " (click)="showDeleteProductConfirmDialog(row._id)">
                    <span class="text-red">
                      <i class="icon-trash-can3"></i> Delete
                    </span>
                  </button>
                </div>
              </div>
            </div>



          </ng-template>
        </ngx-datatable>
      </div>
    </div> -->
      </div>
    </div>
  </div>


  <!-- <ngx-datatable-column *ngFor="let col of columns" [flexgrow]=col.flexgrow [name]="col.name">
          <ng-template ngx-datatable-header-template>
            {{col.name}}
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div [ngSwitch]="col.type">
              <span *ngSwitchCase="'action'">
                <div class="button-row">
                  <button class="btn btn-primary" routerLink="/product/edit/{{row._id}}" mat-mini-fab color="primary" title="Edit">
                    <i class="fa fa-edit"></i>
                  </button>
                
                  <button class="btn btn-danger" (click)="showDeleteProductConfirmDialog(row._id)" mat-mini-fab color="warn"
                    title="Delete">
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </span>
            </div>
          </ng-template>
          
        </ngx-datatable-column>
    </div>
    </div> -->

  <!-- </div>
</div> -->

  <!-- <ng-template #actionTmpl let-value="value">

    <div class="btn-group mr-3">
      <button class="btn btn-primary" routerLink="/hsn/edit/{{value}}" type="button" rel="tooltip">Edit</button>
      <div class="btn-group" ngbDropdown placement="bottom-right" role="group">
        <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
        <div class="dropdown-menu" ngbDropdownMenu>
          <button class="dropdown-item" routerLink="/hsn/edit/{{value}}" type="button" rel="tooltip">Edit</button>
          <button class="dropdown-item " (click)="deleteHsn(value)">
            <span class="text-red">
              <i class="icon-trash-can3"></i> Delete
            </span>
          </button>
        </div>
      </div>
    </div>



  </ng-template> -->

  <ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Upload File</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <label>File Upload</label>
      <div class="inline-block">

        <input ngfSelect multiple type="file" [(files)]="files" [accept]="accept" [maxSize]="maxSize"
          [(lastInvalids)]="lastInvalids" (filesChange)="lastFileAt=getDate()" />{{input_file}}
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="uploadFile()">Save</button>
    </div>
  </ng-template>