<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <form novalidate #searchInvoiceForm="ngForm" (ngSubmit)="validateAndGetInvoicesList()">
        <div class="card">
          <div class="card-body">
            <div class=" mr-3 mb-2 float-right">
              <button [disabled]="selected_items_billid.length==0" (click)="openPaymentModal('','bulk')"
                class="btn btn-primary mr-2">
                Receive
              </button>
              <button *ngIf="this.companyDetails.fi_year.status!='Finalized'" class="btn btn-primary " routerLink="/invoice/add">
                <i class="icon icon-add"></i>Add Invoice
              </button>
            </div>
            <h3 class="table-title"><i class="fa fa-file-text"></i> Sales Invoice</h3>

            <div class="row">

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="customer">Customer </label>
                <ng-select (clear)="invoice_lists=[];" [items]="customerList" [typeahead]="customerListTypeahead"
                  name="customer" bindLabel="customer_name" bindValue="_id" [(ngModel)]="filters.customer_muid"
                  (change)="validateAndGetInvoicesList();" placeholder="Select Customer">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.customer_name }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="invoice_number">Invoice Number</label>
                <ng-select [multiple]="true" [items]="invoiceNumberList" [typeahead]="invoiceNumberListTypeahead"
                  name="invoice_number" bindLabel="invoice_number" bindValue="invoice_number"
                  [(ngModel)]="filters.invoice_number" placeholder="Invoice Number">
                  <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{ item.invoice_number }}
                    </div>
                  </ng-template>
                </ng-select>

              </div>
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="invoice_date">Invoice Date</label>
                <div class="input-group">
                  <input class="form-control" name="invoice_date" placeholder="Select a date" angular-mydatepicker
                    #dp_invoice="angular-mydatepicker" (click)="dp_invoice.toggleCalendar()"
                    [(ngModel)]="filters.invoice_date" [options]="invoiceDateOptions" />
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="filters.invoice_date"
                      (click)="dp_invoice.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="dp_invoice.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="due_date">Due Date</label>
                <div class="input-group">
                  <input class="form-control" name="due_date" placeholder="Select a date" angular-mydatepicker
                    #dp_duedate="angular-mydatepicker" (click)="dp_duedate.toggleCalendar()"
                    [(ngModel)]="filters.due_date" [options]="dueDateOptions" />
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="filters.due_date"
                      (click)="dp_duedate.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="dp_duedate.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
                <label for="invoice_status_id">Invoice Status </label>
                <ng-select (change)="validateAndGetInvoicesList()" [items]="invoiceStatusList"
                  bindValue="invoice_status_id" bindLabel="invoice_status_name" required placeholder="Select Statuses"
                  name="invoice_status_id" [multiple]="true" [(ngModel)]="filters.invoice_status">
                </ng-select>
              </div>
              <div class="col-12">
                <button type="submit" class="btn btn-primary mr-2"> <i class="fa fa-search"></i> Search</button>
                <button type="button" (click)="clearInvoiceFilters();" class="btn btn-danger"><i
                    class="fa fa-close"></i>
                  Clear
                </button>
              </div>
            </div>

          </div>
        </div>
      </form>

      <div *ngIf="billListType=='salesadvance'">
        <h5>Advance Amount:{{adv_advance_based_amount | currency: currency}}({{adv_total_amount| currency: currency}})
        </h5>
      </div>

      <h6 style="text-align: center;" *ngIf="is_invoice_receive===true && invoice_lists.length==0">
        Please select the customer to view the receivable list
      </h6>
      <div class="card p-sm-0"
        *ngIf="(filters.customer_muid && is_invoice_receive==true && invoice_lists.length>0) || is_invoice_receive==false">
        <ngx-datatable [rows]="invoice_lists" [loadingIndicator]="loadingIndicator" [externalPaging]="true"
          [columnMode]="'force'" [count]="count" [limit]="page.pageSize" [offset]="page.offset"
          (page)="getInvoiceList($event)" [externalSorting]=true (sort)="onSort($event)" [footerHeight]="50"
          [rowHeight]="'auto'" [selectionType]="'checkbox'" (select)="onSelectOrders($event)" class="material striped"
          [sorts]="this.dt_default_sort">

          <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false" [draggable]="false"
            [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="dispCheckBox">
          </ngx-datatable-column>

          <ngx-datatable-column *ngFor="let col of invoiceOrderTableListColumns|filter:'is_detailed_row':false;"
            [flexGrow]=" col.flexGrow" [name]="col.name" [sortable]="col.sortable" [cellClass]="col.css_class"
            [prop]="col.prop">{{col}}
            <ng-template ngx-datatable-header-template let-sort="sortFn">
              <span (click)="sort()">{{ col.name }}</span>
            </ng-template>
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
              <div [ngSwitch]="col.type">
                <span *ngSwitchCase="'currency'"> {{row[col.prop]|currency:currency}}</span>
                <span *ngSwitchCase="'percentage'">{{row[col.prop]}}%</span>
                <span *ngSwitchCase="'action'">
                  <div class="btn-group mr-3" *ngIf="row.invoice_status_name =='Draft'">
                    <a  *ngIf="user_id!=8" class=" btn btn-primary" name="send{{ i }}" type="button" (click)="sendInvoice(row)"
                      rel="tooltip">Confirm</a>
                      <a  *ngIf="user_id==8" class="btn btn-primary" (click)="open(invoiceViewContent, row._id)">
                        <span class="text-blue"> View </span>
                      </a>
                    <div class="btn-group" ngbDropdown placement="bottom-right" container="body" role="group">
                      <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <button class="dropdown-item" routerLink="/invoice/edit/{{ row._id }}" type="button"
                          rel="tooltip">
                          Edit
                        </button>
                        <a  *ngIf="user_id!=8"  class="dropdown-item " (click)="deleteInvoice(value)">
                          <span class="text-red">
                            <i class="icon-trash-can3"></i> Cancel
                          </span>
                        </a>

                        <a  *ngIf="user_id!=8" class="dropdown-item" (click)="open(invoiceViewContent, row._id)">
                          <span class="text-blue"> View </span>
                        </a>
                        <!-- <a class="dropdown-item " routerLink="/invoice/view/{{row._id}}">
                          <span class="text-blue">
                            <i class="icon-fa-eye"></i> View
                          </span>
                        </a> -->
                      </div>
                    </div>
                  </div>

                  <div class="btn-group mr-3" *ngIf="row.invoice_status_name == 'paid'">
                    <a class="btn btn-primary " (click)="open(invoiceViewContent, row._id)">
                      View
                    </a>
                    <div class="btn-group" ngbDropdown placement="bottom-right" container="body" role="group">
                      <a class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></a>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <a class="dropdown-item cursor" (click)="exportInvoice(row._id, 'invoicepdf')">

                          <i class="fa fa-file-pdf-o"></i> Export Invoice PDF

                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="btn-group mr-3" *ngIf="row.invoice_status_name != 'Draft' && row.invoice_status_name !='paid'
                     && row.invoice_status_name !='cancelled'">
                    <a class="btn btn-primary" name="pay{{i}}" (click)="openPaymentModal(row,'single')" type="button"
                      rel="tooltip">Receive</a>
                    <div class="btn-group" ngbDropdown placement="bottom-right" container="body" role="group">
                      <a class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></a>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <a  *ngIf="user_id!=8" class="dropdown-item cursor" routerLink="/invoice/debit-note/{{row._id}}"
                          rel="tooltip">Credit
                          Note</a>

                        <a class="dropdown-item cursor" (click)="open(invoiceViewContent, row._id)">

                          <i class="icon-fa-eye"></i> View

                        </a>
                        <a class="dropdown-item cursor" (click)="openModal(content,row)">

                          <i class="icon-fa-eye"></i> E-Way Number


                        </a>
                        <a class="dropdown-item cursor" (click)="openModal(deliveryChallan,row)">

                          <i class="icon-fa-eye"></i> Delivery Challan


                        </a>
                        <a class="dropdown-item cursor" (click)="exportInvoice(row._id, 'invoicepdf')">

                          <i class="fa fa-file-pdf-o"></i> Export Invoice PDF

                        </a>
                        <a class="dropdown-item cursor" *ngIf="row.delivery_challan_no !=undefined" (click)="exportInvoice(row._id, 'dcpdf')">

                          <i class="fa fa-file-pdf-o"></i> Export DC PDF

                        </a>
                      </div>
                    </div>
                  </div>


                </span>
                <span *ngSwitchCase="'object'"> {{ row[col.prop][col.field_name] }}</span>
                <span *ngSwitchCase="'date'"> {{ row[col.prop]|date }}</span>

                <span *ngSwitchCase="'status'">
                  <span *ngIf="row[col.prop]=='unpaid'" class="badge badge-warning">Unpaid</span>
                  <span *ngIf="row[col.prop]=='partially_paid'" class="badge badge-info">Partially Paid</span>
                  <span *ngIf="row[col.prop]=='paid'" class="badge badge-success">Paid</span>
                  <span *ngIf="row[col.prop]=='cancelled'" class="badge badge-danger">Cancelled</span>
                  <span *ngIf="row[col.prop]=='Draft'" class="badge badge-default">Draft</span>

                </span>

                <span *ngSwitchDefault>
                  {{ row[col.prop] }}
                </span>
              </div>

            </ng-template>
          </ngx-datatable-column>

          <!-- <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
              let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset">


              <div class="page-count">{{ (curPage * pageSize)-pageSize+1 }} - {{ curPage * pageSize }} of
                {{ rowCount }}
              </div>
              <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="pageSize" [count]="rowCount"
                [hidden]="!((rowCount / pageSize) > 1)" (change)="table.onFooterPage($event)">
              </datatable-pager>
            </ng-template>

          </ngx-datatable-footer> -->
        </ngx-datatable>


        <!-- <ngx-datatable class="material" [rows]="invoice_lists" #InvoiceTable
          [loadingIndicator]="loadingIndicator" [columns]="columns"  footerHeight="50"
          [rowHeight]="'auto'" [reorderable]="reorderable" [offset]="page.offset" [limit]="page.pageSize"
          [columnMode]="'flex'" [sortType]="'multi'" (page)='getInvoiceList($event)' [count]="count"
          [externalPaging]="true">

          <ng-template #actionTmpl let-value="value">

            <div class="btn-group mr-3">
              <button class="btn btn-primary" routerLink="/invoice/edit/{{value}}" type="button"
                rel="tooltip">Edit</button>
              <div class="btn-group" ngbDropdown placement="bottom-right" role="group">
                <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <button class="dropdown-item" routerLink="/invoice/edit/{{value}}" type="button"
                    rel="tooltip">Edit</button>
                  <button class="dropdown-item " (click)="deletePurchaseorder(value)">
                    <span class="text-red">
                      <i class="icon-trash-can3"></i> Delete
                    </span>
                  </button>
                </div>
              </div>
            </div>



          </ng-template>
        </ngx-datatable> -->
      </div>
    </div>
  </div>
</div>
<!-- modal -->
<div *ngIf="billListType=='salesadvance'"><button type="button" (click)="mapBills()" class="btn btn-primary"
    name="map">Map</button></div>

  <ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">E-way number : ({{invoice_number}})</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <label>E-Way</label>
      <div class="inline-block">
        <input type="number" class="form-control" #eway_bill="ngModel" [(ngModel)]="eway_bill_no" name="eway_bill_no" />
      </div>
    </div>
    <div class="modal-body">
      <label>Vehicle Number</label>
      <div class="inline-block">
        <input type="text" class="form-control" #vehicle_no="ngModel" [(ngModel)]="vehicle_number" name="vehicle_number" />
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="updateEway()">Save</button>
    </div>
  </ng-template>
  <ng-template #deliveryChallan let-c="close" let-d="dismiss">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Delivery Challan : ({{invoice_number}})</h4>
      <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <label>Delivery Challan Number</label>
      <div class="inline-block">
        <input type="text" class="form-control" #delivery_challan="ngModel" [(ngModel)]="delivery_challan_no" name="delivery_challan_no" />
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="updateDeliveryChallan()">Save</button>
    </div>
  </ng-template>

<ng-template #invoiceViewContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Sales Invoice View</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
       <app-invoice-view [id]="id" [moduleName]="'salse_invoice'"></app-invoice-view>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
