<div class="">
  <div class="row">
    <div class="col-lg-12">
      <h3> Bill / Expense Uploaded Files</h3>
    </div>
  </div>
  <!-- <div class="p-sm-5">

    <table class="table" *ngIf="fileuploadList.length>0">
      <thead>
        <tr>
          <th scope="col"><b>Image</b></th>
          <th scope="col"><b>Created At</b></th>
          <th scope="col"><b>Is Marked(Bill/Expense)</b></th>
          <th scope="col"><b>Action</b></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let fileupload of fileuploadList">
          <td>
            <a target="_blank" href="{{image_path}}{{fileupload.files[0]['file_path']}}">
              <img *ngIf="fileupload.files[0]['file_type']!='application/pdf'" style="width: 50px;"
                src="{{image_path}}{{fileupload.files[0]['file_path']}}" />
              <i *ngIf="fileupload.files[0]['file_type']=='application/pdf'" class="fa fa-file-pdf-o fa-2x"></i>
            </a>

          </td>
          <td>{{fileupload['created_at']| date}}</td>
          <td *ngIf="fileupload['is_bill_or_invoice']==-1"><span class="badge badge-danger">Unbilled</span></td>
          <td *ngIf="fileupload['is_bill_or_invoice']!=-1"><span class="badge badge-success">Billed</span></td>
          <td>
            <div class="btn-group mr-3" *ngIf="user_id !=8 && fileupload['is_bill_or_invoice']==-1">
              <a class=" btn btn-primary" routerLink="/bill/image/{{fileupload['_id']}}" type="button"
                rel="tooltip">Create as Bill</a>
              <div class="btn-group" ngbDropdown placement="bottom-right" container="body" role="group">
                <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
                <div class="dropdown-menu" ngbDropdownMenu>
                

                  <a class="dropdown-item " routerLink="/bill_expense/image/{{fileupload['_id']}}">
                    <span class="text-blue">
                      <i class="icon-fa-eye"></i> Create as Expense
                    </span>
                  </a>

                  <a class="dropdown-item " (click)="deleteFileUpload(fileupload['_id'])">
                    <span class="text-blue">
                      <i class="icon-fa-eye"></i> Delete
                    </span>
                  </a>
                </div>
              </div>
            </div>


            <div ngbDropdown class="d-inline-block" *ngIf="fileupload.files.length>1">
              <button class="btn btn-info" id="dropdownBasic1" ngbDropdownToggle>
                <i (click)="fileupload.show_more_files=!fileupload.show_more_files" class="fa fa-plus-circle"></i>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <a target="_blank" href="{{image_path}}{{fileupload.files[0]['file_path']}}"
                  *ngFor="let file of fileupload.files" ngbDropdownItem>{{file.file_name}}</a>
              </div>
            </div>
            
            
            <ul *ngIf="fileupload.show_more_files==true">
              <li *ngFor="let file of fileupload.files">{{file.file_name}}</li>
            </ul>
          </td>
        </tr>

      </tbody>
    </table>
  </div> -->
</div>



<div class="card p-sm-0">
        <ngx-datatable [rows]="fileuploadList" [loadingIndicator]="loadingIndicator" [externalPaging]="true"
          [columnMode]="'force'" [count]="count"  [limit]="page.pageSize" [offset]="page.offset"
          (page)="getfileuploadList($event)" [externalSorting]=true (sort)="onSort($event)" [footerHeight]="50"
          [rowHeight]="'auto'" class="material striped" [sorts]="this.dt_default_sort">

          <!-- <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false" [draggable]="false"
            [resizeable]="false" [headerCheckboxable]="true" >
          </ngx-datatable-column> -->

          <ngx-datatable-column *ngFor="let col of fileuploadListColumns "
            [flexGrow]=" col.flexGrow" [name]="col.name" [sortable]="col.sortable" [cellClass]="col.css_class"
            [prop]="col.prop">
            <ng-template ngx-datatable-header-template let-sort="sortFn">
              <span (click)="sort()">{{ col.name }}</span>
            </ng-template>
            <ng-template ngx-datatable-cell-template let-value="value" let-row="row"  let-rowIndex="rowIndex" >
              <div [ngSwitch]="col.type">
               
                <span *ngSwitchCase="'file_path'"> 
                  <a target="_blank" href="{{image_path}}{{row[col.prop][0]['file_path']}}">
                    <img *ngIf="row[col.prop][0]['file_type']!='application/pdf'" style="width: 50px;"
                      src="{{image_path}}{{row[col.prop][0]['file_path']}}" />
                    <i *ngIf="row[col.prop][0]['file_type']=='application/pdf'" class="fa fa-file-pdf-o fa-2x"></i>
                  </a>
                  <!-- <a target="_blank" href="{{image_path}}{{fileupload.files[0]['file_path']}}">
                    <img *ngIf="fileupload.files[0]['file_type']!='application/pdf'" style="width: 50px;"
                      src="{{image_path}}{{fileupload.files[0]['file_path']}}" />
                    <i *ngIf="fileupload.files[0]['file_type']=='application/pdf'" class="fa fa-file-pdf-o fa-2x"></i>
                  </a> -->
                </span>
                <span *ngSwitchCase="'date'"> {{ row[col.prop]|date }}</span>
                <span *ngSwitchCase="'status'">
                  <span *ngIf="row[col.prop]==-1"><span class="badge badge-danger">Unbilled</span></span>
                  <span *ngIf="row[col.prop]!=-1"><span class="badge badge-success">Billed</span></span>
                </span>
                <span *ngSwitchCase="'index'">
                  {{rowIndex+1}}
                </span>
                <span *ngSwitchCase="'action'">
                  <div class="btn-group mr-3" *ngIf="user_id !=8 && row.is_bill_or_invoice==-1">
                    <a class=" btn btn-primary" routerLink="/bill/image/{{row._id}}" type="button"
                      rel="tooltip">Create as Bill</a>
                    <div class="btn-group" ngbDropdown placement="bottom-right" container="body" role="group">
                      <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <!-- <button class="dropdown-item" routerLink="/bill/image/{{fileupload['_id']}}" type="button"
                          rel="tooltip">
                          Create as Bill
                        </button> -->
      
                        <a class="dropdown-item " routerLink="/bill_expense/image/{{row._id}}">
                          <span class="text-blue">
                            <i class="icon-fa-eye"></i> Create as Expense
                          </span>
                        </a>
      
                        <a class="dropdown-item " (click)="deleteFileUpload(fileupload['_id'])">
                          <span class="text-red">
                            <i class="icon-fa-eye"></i> Delete
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </span>

                <span *ngSwitchDefault>
                  {{ row[col.prop] }}
                </span>
              </div>

            </ng-template>
          </ngx-datatable-column>

        </ngx-datatable>

      </div>

<!-- <ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Upload File</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>File Upload</label>
    <div class="inline-block">

      <input multiple type="file" id="file" (change)="onFileChange($event)" />{{files}}
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="uploadFile()">Save</button>
  </div>
</ng-template> -->