<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header d-flex align-items-center">
          <h3 class="table-title">Generate GST</h3>
        </div>
        <form novalidate #addGstForm="ngForm">
          <div class="card-body row">
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="gst_date">Select Date Range:</label>
              <div class="input-group">
                <input class="form-control" name="gst_date" placeholder="Select a date" ngxDaterangepicker
                [ranges]="ranges" [alwaysShowCalendars]="false" [keepCalendarOpeningWithRange]="false"
                [showCustomRangeLabel]="true" [showRangeLabelOnInput]="false" [(ngModel)]="filters.gst_date"
                />
                <div class="input-group-append"> 
                  <button type="button" class="btn btn-secondary" *ngIf="filters.gst_date"
                    (click)="dp_invoice.clearDate()">
                    <i class="fa fa-close"></i>
                  </button>
                </div>
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary">
                    <i class="fa fa-calendar-o"></i>
                  </button>
                </div>
              </div>
            </div>


            <!-- <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="from_date">From Date</label>
              <div class="input-group">
                <input class="form-control" #from_date="ngModel" name="from_date" angular-mydatepicker
                  #dp_from="angular-mydatepicker" required placeholder="Select a date"
                  (click)="dp_from.toggleCalendar()" [(ngModel)]="fromdate" [options]="fromDateOptions" />

                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" (click)="dp_from.clearDate()">
                    <i class="fa fa-close"></i>
                  </button>

                </div>
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" (click)="dp_from.toggleCalendar()">
                    <i class="fa fa-calendar-o"></i>
                  </button>
                </div>
              </div>

            </div> -->
            <!-- <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="to_date">To Date</label>
              <div class="input-group">
                <input class="form-control" #to_date="ngModel" name="to_date" angular-mydatepicker
                  #dp_to="angular-mydatepicker" required placeholder="Select a date" (click)="dp_to.toggleCalendar()"
                  [(ngModel)]="todate" [options]="toDateOptions" />

                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" (click)="dp_to.clearDate()">
                    <i class="fa fa-close"></i>
                  </button>

                </div>
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" (click)="dp_to.toggleCalendar()">
                    <i class="fa fa-calendar-o"></i>
                  </button>
                </div>
              </div>

            </div> -->

          </div>


          <div class="card-footer">
            <button type="submit" (click)="generateGSTR1()"  [disabled]="(!filters.gst_date)"
              class="btn btn-primary mr-2">Generate
              GSTR1 (JSON) <i class="fa fa-spinner fa-spin" *ngIf="loading"></i></button>
            <!-- <button type="button" (click)="generateGSTR2()" [disabled]="!addGstForm.valid"
              class="btn btn-primary">Generate
              GSTR2 purchase order</button> -->
          </div>

        </form>
      </div>
    </div>
  </div>
</div>
