import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import swal from "sweetalert2";
import { VoucherTypeService } from "@src/app/_services/voucher-type.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { AuthenticationService } from "src/app/_services/authentication.service";
import {
  NgbPopoverConfig,
  NgbModal,
  ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import { filter } from "rxjs/operators";
@Component({
  selector: "app-voucher-type",
  templateUrl: "./voucher-type.component.html",
  styleUrls: ["./voucher-type.component.scss"],
})
export class VoucherTypeComponent implements OnInit {
  @Input() defaultVoucher: any={};
  @Input() voucherCategoryCode: number = 0;
  @Output() newItemEvent = new EventEmitter<string>();
  @Output() public onSelectVoucher = new EventEmitter<String>();

  filters: any = {};
  loadingIndicator = false;
  reorderable: any = {};
  voucherType: any = {};
  voucherTypeList: any = [];
  voucherTypeListColumns: any = [];
  page: any = { offset: 0, limit: 10 };
  closeResult: string;
  voucherName: any = {};
  company_id: string;
  currentVoucherType: any = [];
  @ViewChild("actionTmpl", { static: true }) actionTmpl: TemplateRef<any>;
  constructor(
    private voucherTypeservice: VoucherTypeService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal // private VoucherType: VoucherType,
  ) {
    this.voucherTypeListColumns = [
      {
        prop: "category_name",
        name: "Category Name",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
      },
      {
        prop: "category_code",
        name: "Category Code",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
      },
      {
        prop: "code",
        name: "Code",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
      },
      {
        name: "Action",
        type: "action",
        prop: "_id",
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false,
      },
    ];
  }

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    if (this.voucherCategoryCode > 0) {
      // this.filters["category_code"] = this.voucherCategoryCode;
      this.page = { offset: 0, limit: 100 };
    }
    this.getVoucherTypeList(this.page);
  }
  open(modelName) {
    this.modalService.open(modelName, { size: "lg" }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  getVoucherTypeList(page_info: any = {}) {
    this.voucherType.voucher_type = null;
    if (page_info != null) {
      this.page = page_info;
    }
    if (this.defaultVoucher != undefined) {
      this.currentVoucherType = this.defaultVoucher.code;
     this.filters.company_id = this.company_id;
     console.log('JJJHHHH>>>>>', this.filters.company_id);
    this.voucherTypeservice
      .getVoucherType(this.filters, this.page, null)
      .subscribe((result) => {
        this.loadingIndicator = false;
        this.voucherTypeList = result["data"];
        this.page.count = result["count"];
        console.log('GGGGGTTTTT=====>>>', this.voucherTypeList);
        if(this.defaultVoucher.is_set != undefined && this.defaultVoucher.is_set == true) {
          this.voucherType.voucher_type = this.voucherTypeList.filter(
            items => items.code == this.defaultVoucher.code[0])[0];
            this.currentVoucherType = this.defaultVoucher.code;
        }
      });
    }
  }
  clearVoucherTypeFilters() {
    this.filters = {};
    this.getVoucherTypeList({});
  }
  deleteVoucherType(muid) {
    swal({
      title: "Are you sure want to delete?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result) {
        this.voucherTypeservice.deleteVoucherType(muid).subscribe((result) => {
          if (result["status_code"] === 1) {
            swal("Deleted!", "Voucher-type has been deleted.", "success");
            this.getVoucherTypeList({});
          }
        });
      }
    });
  }

  getVoucherType() {
    this.onSelectVoucher.emit(this.voucherType.voucher_type);
  }
}
