export class PaymentSettings {
  account_head: string;
  company_id: string;
  vendor_name: string;
  vendor_id: Object;
  customer_name: string;
  customer_id: Object;
  currency: Object;
  total_payment_amount: number = 0;
  tx_date: Date;
  tx_type: number;
  payment_mode: number;
  payment_method: PaymentMethod = new PaymentMethod();
  payment_note: string;
  party_id: any;
  payment_account: string;
  payment_array: [
    {
      order_number: string;
      order_id: string;
      amount: number;
      to_be_paid: number;
    }
  ];
  total_tax: any;
  bill_number: any;
  ref_id: any;
  voucher_number: string;
  is_direct_payment: boolean;
  order_id: any;
  is_advance: boolean;
  debi_or_credit: boolean;
  is_credit_debit: boolean;
  total_adv_amount: number;
  total_debit_credit_amount: number;
  balance_amount: number;
  paying_amount: any;
  advance_array: any = [];
  debitcredit_array: any = [];
  is_credit_or_debit: string;
}

export class PaymentMethod {
  name: string;
  account_head: number;
}
