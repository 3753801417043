import { Component, OnInit, Input, SimpleChanges, OnChanges } from "@angular/core";
import { Location } from "@angular/common";
// import { PurchaseorderService } from 'app/_services/purchaseorder.service;'
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { AuthenticationService } from "../../../_services/authentication.service";
import { BillService } from "../../../_services/bill.service";
import { Receipts } from "../../../_models/receipts";
import { ReceiptsService } from "../../../_services/receipts.service";
@Component({
  selector: "app-receipts-vendor-view",
  templateUrl: "./receipts-vendor-view.component.html",
  styleUrls: ["./receipts-vendor-view.component.scss"]
})
export class ReceiptvendorViewComponent implements OnInit, OnChanges {
  @Input() id: string;
  @Input() moduleName: string;
  filters: any = {};
  purchaseorder: any = {};
  receiptList: any = [];
  purchase_orderListColumns: any = [];
  page: any = { offset: 0, limit: 10 };
  // id: string;
  // bill: any;
  company_id: string;
  branch_id: string;
  company_details: any;
  is_same_state: boolean = false;
  receipts: Receipts = new Receipts();
  vendor: any = {};
  sort: any = {};
  columns: any = {};
  billsList: any;
  count: any;
  type: string;
  header: string;
  label: string;
  constructor(

    private route: ActivatedRoute,
    private billService: BillService,
    private receiptsService: ReceiptsService,
    private authenticationService: AuthenticationService,
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.company_id = this.authenticationService.getDefaultCompanyId();
    if (changes.id) {
      this.id = changes.id.currentValue;
      this.type = this.moduleName;
      // this.initiateViewPage();
      // this.route.paramMap.subscribe(params => {
      // this.receipt_order_id = params.get("id");
      // if (this.receipt_order_id != "") {
        this.initiateViewPage();
      // this.viewAdvance();
      // }
      // });
    }
  }

  ngOnInit() {
    // this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.route.paramMap.subscribe(params => {
      // this.id = params.get("id");
      // this.type = params.get("type");
     
    });

  }

  initiateViewPage() {
    if (this.type == "purchase_advance") {
      this.header = "Purchase Advance";
      this.label = "Vendor";
    } else if (this.type == "sales_advance") {
      this.header = "Sales Advance";
      this.label = "Customer";
    }
    this.viewAdvance();
  }
  viewAdvance() {
    let condition: any = {};
    condition['company_id'] = this.company_id;
    return this.receiptsService
      .viewPurchaseAdvance(this.id, condition)
      .subscribe((result) => {
        this.receiptList = result;
      });
  }


  // goBack() {
  //     this.location.back();
  // }
}
