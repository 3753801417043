import { Currency } from "../_models/currency.model";
import { Vendor } from "../_models/vendor.model";
import { VoucherType } from "../_models/voucher-type";

export class Bill {
  voucher_type: any = [];
  // voucher_type: VoucherType = new VoucherType();
  vendor: any;
  desc: string;
  bill_number: string;
  purchaseorder_id: string;
  purchase_order_number: string;
  purchase_order_date: any;
  bill_items: any = [];
  balancing_items: any = [];
  add: any = [];
  is_discount: boolean = false;
  is_productwise_discount: boolean = false;
  discount_in_percentage: boolean = false;
  company_id: string;
  branch_id: string;
  billing_date: any;
  due_date: any;
  currency: Currency = new Currency();
  total_amount: number = 0;
  actual_amount: number = 0;
  balance_amount: number;
  total_tax_amount: number = 0;
  total_tax_percentage: number = 0;
  grand_total_amount: number = 0;
  sub_total_amount: number = 0;
  discount: number = 0;
  freight: number = 0;
  transport_charges: number = 0;
  cgst: number = 0;
  sgst: number = 0;
  igst: number = 0;
  actual_total_amount: number = 0;
  debit_date: any;
  credit_notes: any;
  file: File[];
  sales_status: number;
  sales_status_name: string;
  purchase_status_name: string;
  purchase_status: number;
  original_items: any;
  ledger: Ledger = new Ledger();
  bill_sequencenumber: any;
  is_booking_payment: number;
  tx_type: number;
  tds: TDS = new TDS();
  credit_number: any;
  tx_type_id: number;
  is_without_gst: boolean = false;
  is_igst: boolean;
  voucher_name: any;
  voucher_object: any;
  round_of: any;
  invoice_amount_wo_roundof: any;
  round_value: number;
  bill_amount_wo_roundof: any;
  file_uploadimage_id: any;
}

export class TDS {
  nature_of_payment: string;
  section: string;
  tds_percentage: number;
  tds_deducted_amount: number;
}

export class Ledger {
  name: string;
  id: number;
  ref_id: string;
  parent_id: any = [];
  parent_name: any = [];
}
