import {
  Component,
  EventEmitter,
  ChangeDetectorRef,
  OnInit,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { InvoiceService } from "../../_services/invoice.service";
import { AuthenticationService } from "../../_services/authentication.service";
import { PaymentSettings } from "../../_models/payment-settings.model";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { PaymentModalComponent } from "../payment-modal/payment-modal.component";
import { Customer } from "../../_models/customer";
import swal from "sweetalert2";
import { MapModalComponent } from "../map-modal/map-modal.component";
import { ConstantService } from "../../_config/constants";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { CustomerService } from "../../_services/customer.service";
import { Invoice } from "@app/_models/invoice.model";
import { Stream } from "stream";
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: "app-invoice",
  templateUrl: "./invoice.component.html",
  styleUrls: ["./invoice.component.scss"],
  providers: [NgbModalConfig, NgbModal],
})
export class InvoiceComponent implements OnInit {
  customerListTypeahead = new EventEmitter<string>();
  invoiceNumberListTypeahead = new EventEmitter<string>();
  customerList: Customer[] = [];
  filters: any = {};
  page: any = { offset: 0, pageSize: 10 };
  defaultPagination: any = { offset: 0, pageSize: 10 };
  sort: any = { created_at: -1 };
  dt_default_sort = [{ prop: "invoice_number", dir: "desc" }];
  invoice_lists: any = [];
  columns: any = {};
  invoiceOrderTableListColumns: any = [];
  count: number;
  loadingIndicator = false;
  dispCheckBox: boolean = true;
  reorderable = true;
  dueDateOptions: IAngularMyDpOptions = {
    dateRange: true,
    dateFormat: ConstantService.DateFormat,
  };
  invoiceDateOptions: IAngularMyDpOptions = {
    dateRange: true,
    dateFormat: ConstantService.DateFormat,
  };
  model: IMyDateModel = null;
  // @ViewChild("InvoiceTable", { static: false }) table: any;
  selected_items = [];
  selectedOrder: any;
  payType: string = "";
  selecteditem_array: any;
  item_selected: any;
  headList: any;
  transaction_head: string;
  tx_date: IMyDateModel;
  public payment = new PaymentSettings();
  public invoice: Invoice = new Invoice();

  invoice_array: any = [];
  pay_head: string;
  currency: string;
  module_from: string = "sales";
  passed: boolean;
  passed_message: string;
  selected_items_billid = [];
  invoiceStatusList: any = [
    { invoice_status_name: "Unpaid", invoice_status_id: 1 },
    { invoice_status_name: "Partially Paid", invoice_status_id: 2 },
    { invoice_status_name: "Paid", invoice_status_id: 3 },
    { invoice_status_name: "Cancelled", invoice_status_id: 4 },
    { invoice_status_name: "Draft", invoice_status_id: 5 },
  ];
  // @ViewChild("actionTmpl", { static: true }) actionTmpl: TemplateRef<any>;

  company_id: string;
  check_status: boolean;
  selected_items_bill_amount: any;
  selected_items_billnumber: any;
  billListType: string;
  advance_id: any;
  adv_total_amount: any;
  adv_advance_based_amount: any;
  adv_total_bill_amount: any;
  id: any;
  branch_id: any;
  is_invoice_receive = false;
  invoiceNumberList: any[];
  eway_bill_no: number;
  vehicle_number: string;
  invoice_number: any;
  invoice_id: any;
  user_id: any;
  delivery_challan_no: any;
  companyDetails: any;
  selectFiYear: any;

  constructor(
    private invoiceService: InvoiceService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private router: Router,
    private cd: ChangeDetectorRef,
    private customerService: CustomerService,
    private jwtHelper: JwtHelperService,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.filters['invoice_status'] = [1, 2, 5];
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.companyDetails = this.authenticationService.getCompanyDetails();
    this.currency = this.companyDetails.base_currency;

    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];
    this.filters.invoice_date = {
      isRange: true,
      singleDate: null,
      dateRange: {
        beginDate: { year: this.selectFiYear.start_year, month: 4, day: 1 },
        beginJsDate: new Date(this.selectFiYear.start_year, 3, 1),
        endDate: { year: this.selectFiYear.end_year, month: 3, day: 31 },
        endJsDate: new Date(this.selectFiYear.end_year, 2, 31),
      }
    };

    this.currency = this.authenticationService.getCompanyDetails()[
      "base_currency"
    ];
    this.user_id = this.authenticationService.getUserRole();
    this.route.paramMap.subscribe((params: ParamMap) => {
      let isInvoiceReceiveRoute = this.router.url.match("/receive");
      if (isInvoiceReceiveRoute != null) {
        this.is_invoice_receive = true;
        this.invoiceStatusList = this.invoiceStatusList.filter(
          (invoice_status) => invoice_status.invoice_status_id !== 3
        );
        this.filters.invoice_status = [1, 2];
      }
      this.id = params.get("id");
      this.billListType = params.get("type");
      this.advance_id = params.get("advance_id");
      this.getCustomers("").subscribe((result: Customer[]) => {
        this.customerList = result;
      });
      this.getCustomersSearch();
    });
    this.getInvoiceNumberList("").subscribe((result) => {
      this.invoiceNumberList = result["response"]["data"];
    });
    this.getInvoiceNumberListSearch();
    if (this.billListType == "salesadvance") {
      this.filters["vendor_id"] = this.id;
      this.getAdvanceAmount();
    }

    this.invoiceOrderTableListColumns = [
      {
        prop: "invoice_number",
        name: "Invoice No",
        sortable: "invoice_number",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
      },
      {
        prop: "customer",
        name: "Customer",
        sortable: "customer_name",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "object",
        field_name: "customer_name",
      },
      {
        prop: "invoice_date",
        name: "Invoice Date",
        sortable: "invoice_date",
        type: "date",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
      },

      {
        prop: "due_date",
        name: "Due Date",
        sortable: "due_date",
        type: "date",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
      },
      {
        prop: "total_amount",
        name: "Invoice Amount",
        sortable: "invoice_amount",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "currency",
        css_class: "align-right",
      },
      {
        prop: "balance_amount",
        name: "Balance Due",
        sortable: "balance_due",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "currency",
        css_class: "align-right",
      },
      {
        prop: "invoice_status_name",
        name: "Invoice Status",
        sortable: "invoice_status_name",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "status",
      },

      // {
      //   name: "Action",
      //   type: "action",
      //   prop: "_id",
      //   flexGrow: 1,
      //   is_detailed_row: false,
      //   sortable: false,
      // },
    ];
    if (this.id == "" || this.id == null) {
      this.invoiceOrderTableListColumns.push({
        name: "Action",
        type: "action",
        prop: "_id",
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false,
      });
    }
    this.invoiceOrderTableListColumns.forEach((column) => {
      this.columns[column.prop] = 1;
    });
    this.columns["delivery_challan_no"] = 1;
    this.columns["ledger"] = 1;

    if (
      this.route.snapshot.queryParams["q"] != "undefined" &&
      this.is_invoice_receive == false
    ) {
      this.filters["customer_name"] = this.route.snapshot.queryParams["q"];
      this.getInvoiceList(this.page);
    }
    this.tx_date = { isRange: false, singleDate: { jsDate: new Date() } };
  }

  open(content, id) {
    this.id = id;
    this.modalService.open(content, { size: "lg" });
  }

  onSort(event) {
    this.loadingIndicator = true;
    console.log("event", event);
    this.sort = {};
    let sort_details = event.sorts[0];
    this.sort[sort_details.prop] = sort_details.dir == "asc" ? 1 : -1;
    this.getInvoiceList(this.page);
  }

  getInvoiceList(pageInfo: any = {}) {
    this.loadingIndicator = true;
    if (pageInfo !== null) {
      this.page = pageInfo;
    }

    console.log("========this.columns====", this.columns);
    this.filters["company_id"] = this.company_id;
    this.invoiceService
      .getInvoices(this.filters, this.columns, this.sort, this.page)
      .subscribe((result) => {
        // console.log('result',result);
        this.loadingIndicator = false;
        this.invoice_lists = result["response"]["data"];
        this.count = result["response"]["count"];
      });
  }
  clearInvoiceFilters() {
    this.filters = {};
    this.getInvoiceList(this.defaultPagination);
  }

  onSelectOrders(selected) {
    this.selected_items = [];
    this.selected_items_billid = [];
    this.selected_items_billnumber = [];
    this.selected_items_bill_amount = [];

    selected["selected"].forEach((element) => {
      this.selected_items.push(element.customer._id);
      this.selected_items_billid.push(element._id);
      this.selected_items_billnumber.push(element.invoice_number);
      this.selected_items_bill_amount.push(element.balance_amount);
    });
  }
  // payAmountModal(payAmount) {
  //   this.invoice_array = [];
  //   this.invoice_array.amount = 0;
  //   this.balance_amt = 0;
  //   this.invoice_array.debit = 0;

  //   if (this.selected_items.length == 1 || this.selected_items.length > 1) {
  //     this.payment.company_id = this.selecteditem_array[0].company_id;
  //     this.payment.customer_name = this.selecteditem_array[0].customer.customer_name;
  //     this.payment.customer_id = this.selecteditem_array[0].customer;
  //     if (this.payType == "single") {
  //       this.modalService.open(payAmount, { size: "lg" });
  //     } else if (this.payType == "multiple") {
  //       let passed = this.selected_items.every(this.isEqualVendor);
  //       if (passed) {
  //         this.modalService.open(payAmount, { size: "lg" });
  //       } else {
  //         swal("Error!", "Please select same Customer.", "error");
  //       }
  //     } else {
  //       swal("Error!", "Please select an order.", "error");
  //     }

  //     this.selecteditem_array.forEach(element => {
  //       this.payment.currency = element.currency;
  //       this.balance_amt = this.balance_amt + parseInt(element.balance_amount);
  //       this.payment.total_payment_amount = this.balance_amt;
  //       this.invoice_array.push({
  //         invoice_number: element.invoice_number,
  //         invoice_date: element.invoice_date,
  //         invoice_id: element._id,
  //         amount: element.balance_amount,
  //         credit: element.balance_amount,
  //         debit: 0
  //       });
  //     });
  //   } else {
  //     swal("Error!", "Please select an order.", "error");
  //   }
  // }
  isEqualVendor(element, index, array) {
    return element == array[0];
  }

  openPaymentModal(row_data, pay_method) {
    let selected: any = [];

    if (row_data) {
      selected.push(row_data);
      this.onSelectOrders({ selected });
    }

    this.selecteditem_array = [];

    this.item_selected = this.selected_items[0];

    if (pay_method == "bulk") {
      if (this.selected_items.length == 1) {
        this.payType = "single";
        this.passed = true;
      } else {
        this.payType = "multiple";
        this.passed = this.selected_items.every(this.isEqualVendor);
      }
    } else if (pay_method == "single") {
      this.payType = "single";
      this.passed = true;
    }
    if (this.passed) {
      console.log(this.passed)
      const modalRef = this.modalService.open(PaymentModalComponent, {
        size: "lg",
      });

      let dataArray = {
        bill_ids: this.selected_items_billid,
        payment_from: "Sales",
        payment_type: this.payType,
        payment_by: "customer",
        hide_advance_div: true
      };

      modalRef.componentInstance.selectedarray_data = dataArray;
      modalRef.result.then((result) => {
        if (result) {
          this.getInvoiceList(this.page)
        }
      });
      // modalRef.componentInstance.modalReturnResponseEventEmitter.subsribe((result) => {
      //     console.log(result);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    } else {
      swal("Error!", "Select same Customer / choose an order", "error");
    }
  }
  getAdvanceAmount() {
    let filters: any = {};
    if (this.advance_id) {
      filters["advance_id"] = this.advance_id;
    }

    this.company_id = this.authenticationService.getDefaultCompanyId();
    filters["vendor_id"] = this.id;
    filters["company_id"] = this.company_id;

    this.invoiceService.getAdvanceAmount(filters).subscribe((result) => {
      this.adv_total_amount = result["total_adv_data"].reduce(function (a, b) {
        return a + b["advance_amount"];
      }, 0);
      this.adv_advance_based_amount = result["adv_data"][0]["advance_amount"];
      this.adv_total_bill_amount = result["bill_data"].reduce(function (a, b) {
        return a + b["balance_amount"];
      }, 0);
    });
  }

  mapBills() {
    const modalRef = this.modalService.open(MapModalComponent);

    let dataArray = {
      bill_ids: this.selected_items_billid,
      bill_amount: this.selected_items_bill_amount,
      bill_number: this.selected_items_billnumber,
      payment_from: "customer",
      vendor_id: this.id,
      advanceid: this.advance_id,
      adv_total_amount: this.adv_total_amount,
      adv_advance_based_amount: this.adv_advance_based_amount,
      adv_total_bill_amount: this.adv_total_bill_amount,
    };

    modalRef.componentInstance.selectedarray_mapdata = dataArray;
    modalRef.result
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  getCustomersSearch() {
    this.customerListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getCustomers(searchString))
      )
      .subscribe(
        (result: Customer[]) => {
          this.cd.markForCheck();
          this.customerList = result;
        },
        (err) => {
          console.log(err);
          this.customerList = [];
        }
      );
  }

  getInvoiceNumberList(searchString: string) {
    this.invoiceNumberList = [];
    let condition: any = {};
    condition["company_id"] = this.company_id;
    condition["invoice_number_search_string"] = searchString;
    return this.invoiceService.getInvoices(
      condition,
      { invoice_number: 1 },
      {},
      this.defaultPagination
    );
  }

  getInvoiceNumberListSearch() {
    this.invoiceNumberListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getInvoiceNumberList(searchString))
      )
      .subscribe(
        (result: Customer[]) => {
          this.cd.markForCheck();
          this.invoiceNumberList = result["response"]["data"];
        },
        (err) => {
          console.log(err);
          this.invoiceNumberList = [];
        }
      );
  }

  getCustomers(searchString) {
    this.customerList = [];
    let condition: any = {};
    let columns: any = {};
    if (searchString != "") {
      condition["customer_name"] = searchString;
    }
    condition["company_id"] = this.company_id;
    columns["customer_name"] = 1;
    columns["first_name"] = 1;
    columns["last_name"] = 1;
    columns["currency"] = 1;
    columns["address_line1"] = 1;
    columns["billing_address"] = 1;
    columns["shipping_address"] = 1;
    columns["payment_term"] = 1;
    columns["gst_number"] = 1;
    columns["coa_data"] = 1;
    columns["debit_note_data"] = 1;
    columns["country"] = 1;
    columns["state"] = 1;
    columns["phone"] = 1;
    return this.customerService.getCustomersData(condition, columns);
  }

  validateAndGetInvoicesList() {
    if (this.is_invoice_receive == true) {
      if (!this.filters.customer_muid) {
        this.filters.invoice_status =
          this.filters.invoice_status.length == 0
            ? [1, 2]
            : this.filters.invoice_status;
        swal(
          "Warning",
          "You must select the customer to view the receivables",
          "warning"
        );
      } else if (this.filters.invoice_status.length == 0) {
        this.filters.invoice_status = [1, 2];
        swal(
          "Warning",
          "You must select at least any one of the status (Unpaid/Partially Paid)",
          "warning"
        );
        this.getInvoiceList(this.defaultPagination);
      } else {
        this.getInvoiceList(this.defaultPagination);
      }
    } else {
      this.getInvoiceList(this.defaultPagination);
    }
  }
  deleteInvoice(muid) {
    swal({
      title: "Are you sure want to delete?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result) {
        this.invoiceService.deleteInvoice(muid).subscribe((result) => {
          if (result["ok"] === 1) {
            swal("Deleted!", "Invoice has been deleted.", "success");
            this.getInvoiceList({});
          }
        });
      }
    });
  }
  sendInvoice(row_data) {
    if (row_data['ledger'] != undefined && row_data['ledger']['name'] != undefined) {
      swal({
        title: "Approve the Invoice ?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, approve it!",
      }).then((result) => {

        if (result) {
          this.invoiceService.sendInvoice(row_data).subscribe((result) => {
            if (result["ok"] === 1) {
              swal("Success!", "Invoice has been approved.", "success");
              this.getInvoiceList({});
            } else {
              swal("Error", result["error"], "error");
            }
          });
        }
      });
    } else {
      swal("Error!", "Select the ledger for approval.", "error");
    }
  }
  openModal(modelName, row_data) {
    this.eway_bill_no = 0;
    this.vehicle_number = '0';
    this.delivery_challan_no = "";

    this.getEwayNumber(row_data);
    this.getDeliveryChallanNumber(row_data);
    this.invoice_number = "";
    this.invoice_number = "";

    this.invoice_number = row_data["invoice_number"];
    this.invoice_id = row_data["_id"];
    this.modalService.open(modelName);
  }

  getEwayNumber(invoice_data) {
    let condition = {};
    condition["company_id"] = this.company_id;
    this.invoiceService
      .viewInvoice(invoice_data["_id"])
      .subscribe((result) => {
        if (result["response"]["eway_bill_no"] != undefined) {
          this.eway_bill_no = result["response"]["eway_bill_no"];
          this.vehicle_number = result["response"]["vehicle_number"];
        }
      });
  }
  getDeliveryChallanNumber(invoice_data) {
    let condition = {};
    condition["company_id"] = this.company_id;
    this.invoiceService
      .viewInvoice(invoice_data["_id"])
      .subscribe((result) => {
        if (result["response"]["delivery_challan_no"] != undefined) {
          this.delivery_challan_no = result["response"]["delivery_challan_no"];
        }
      });
  }
  updateEway() {
    this.invoice.eway_bill_no = this.eway_bill_no;
    this.invoice.vehicle_number = this.vehicle_number;

    this.invoiceService
      .editEwayorDelivery(this.invoice, this.invoice_id)
      .subscribe((result) => {
        if (result["response"]["status_code"] == 1) {
          this.modalService.dismissAll();

          swal("Success", "E-way updated successfully", "success");
        } else {
          swal("Error", "Some error occured", "error");
        }
      });
  }
  updateDeliveryChallan() {
    this.invoice.delivery_challan_no = this.delivery_challan_no;

    this.invoiceService
      .editEwayorDelivery(this.invoice, this.invoice_id)
      .subscribe((result) => {
        if (result["response"]["status_code"] == 1) {
          this.modalService.dismissAll();

          swal("Success", "Delivery Challan updated successfully", "success");
          this.getInvoiceList({});

        } else {
          swal("Error", "Some error occured", "error");
        }
      });
  }

  exportInvoice(muid: string, exportType: string) {
    this.invoiceService.exportInvoice(muid, exportType).subscribe((result) => {
      window.open(ConstantService.IMG_BASE_URL + result['filename']);
      // window.open(ConstantService.PROTOCOL + ConstantService.DOMAIN + '/' + result['filename']);
    });
  }
}
// export type ExportType = 'pdf' | 'excel';
