import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "../_services/api.service";
@Injectable({
  providedIn: "root"
})
export class CompanyService {
  // uri = "http://localhost:4000/business";

  constructor(private http: ApiService) { }

  // addBusiness(person_name, business_name, business_gst_number) {
  //   const obj = {
  //     person_name: person_name,
  //     business_name: business_name,
  //     business_gst_number: business_gst_number
  //   };
  //   console.log(obj);
  //   this.http
  //     .post(`${this.uri}/add`, obj)
  //     .subscribe(res => console.log("Done"));
  // }

  companyEdit(condition, companyObj: {}) {
    return this.http.post(ApiService.geturl("company", "edit"), { condition: condition, companyObj: companyObj });
  }

  companyAdd(companyObj: {}) {
    return this.http.post(ApiService.geturl("company", "add"), companyObj);
  }

  getCompanies(filters: any = {}) {
    return this.http.post(ApiService.geturl("company", "data"), { filters });
  }
  viewCompany(company_id: any) {
    return this.http.get(ApiService.geturl("company", "view"), { id: company_id });
  }

  uploadLogo(files, data) {
    return this.http.uploadFile(ApiService.geturl('company', 'logoUpload'),
      files,
      data
    );
  }
  getPdfTemplates() {
    return this.http.post(ApiService.geturl('company', 'getPdfTemplates'))
  }
  finalizeFinancialYear(financialYear) {
    return this.http.post(ApiService.geturl('company', 'finalizeFinancialYear'),{financialYear})
  }

}
