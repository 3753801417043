<!-- <div class="container-fluid"> -->
<!-- <div class="row"> -->
<!-- <div class="col-lg-12"> -->
<div class="card">
  <div class="card-header d-flex align-items-center">
    <h3 class="table-title">Purchase Order {{header}} </h3>
    <h6 class="ml-3 pt-2">PO-Number:
      <span class="badge badge-primary ml-2 mb-2">
        {{purchaseorder.purchase_order_number}}
      </span>
    </h6>
  </div>
  <!-- <div class="card-body"> -->
  <!-- <div class="row clearfix"> -->
  <form novalidate #addPurchaseorderForm="ngForm" (ngSubmit)="addUpdatePurchaseorder()">
    <div class="card-body row">
      <div class="form-group required col-8 col-sm-8 col-md-8 col-xl-8">
        <label for="vendor">Vendor</label>
        <ng-select [items]="vendor_lists" [typeahead]="vendorListTypeahead" #vendor="ngModel" required name="vendor"
          (change)="setCurrency(purchaseorder.vendor.currency);getTotalAmount();" bindLabel="vendor_name"
          [(ngModel)]="purchaseorder.vendor" placeholder="Select Vendor">
          <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div [ngOptionHighlight]="search">
              {{ item.vendor_name }} - ({{item.currency?.name}})
            </div>
          </ng-template>
          <!-- <ng-template *ngIf="vendor_lists==''">
                  <button (click)=""> Add Customer</button>
                </ng-template> -->
        </ng-select>
        <!-- <div *ngIf="is_credit_note">
                {{purchaseorder?.vendor?.vendor_name}}
              </div> -->

        <app-validation-errors [mformField]="vendor" label="Vendor"></app-validation-errors>
      </div>
      <div *ngIf="purchaseorder?.vendor?.currency?.name || purchaseorder?.currency?.name"
        class="col-1 col-lg-1 col-lg-1 col-md-1">
        <label class="invisible">Currency</label>
        <span class="badge badge-primary">
          {{is_debit_note? purchaseorder.currency.name: purchaseorder.vendor.currency.name}}
        </span>
      </div>
      <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="purchaseorder_number">Purchase Order Number</label>
              <div> {{purchaseorder.purchase_order_number}}</div>
            </div> -->

      <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
        <label for="purchaseorder_date">Purchase Order Date</label>
        <div class="input-group">
          <input class="form-control" #purchaseorder_date="ngModel" name="purchase_order_date" angular-mydatepicker
            #dp_purchaseorder="angular-mydatepicker" required placeholder="Select a date"
            (click)="dp_purchaseorder.toggleCalendar()" [(ngModel)]="purchaseorder.purchase_order_date"
            [options]="purchaseorderDateOptions" />

          <div class="input-group-append">
            <button type="button" class="btn btn-secondary" *ngIf="purchaseorder.purchase_order_date"
              (click)="dp_purchaseorder.clearDate()">
              <i class="fa fa-close"></i>
            </button>

          </div>
          <div class="input-group-append">
            <button type="button" class="btn btn-secondary" (click)="dp_purchaseorder.toggleCalendar()">
              <i class="fa fa-calendar-o"></i>
            </button>
          </div>
        </div>
        <!-- <div *ngIf="is_credit_note">
                {{purchaseorder.purchase_order_date}}
              </div> -->
        <app-validation-errors [mformField]="purchaseorder_date" label="Purchaseorder Date">
        </app-validation-errors>
      </div>

      <!-- <div class="form-group col-12 required col-sm-6 col-md-3 col-xl-3">
        <label for="due_date">Due Date</label>
        <div class="input-group">
          <input class="form-control" #due_date="ngModel" name="due_date" placeholder="Select a date"
            angular-mydatepicker #dp_duedate="angular-mydatepicker" [(ngModel)]="purchaseorder.due_date"
            [options]="dueDateOptions" (click)="dp_duedate.toggleCalendar()" />
          <div class="input-group-append">
            <button type="button" class="btn btn-secondary" *ngIf="purchaseorder.due_date"
              (click)="dp_duedate.clearDate()">
              <i class="fa fa-close"></i>
            </button>
          </div>
          <div class="input-group-append">
            <button type="button" class="btn btn-secondary" (click)="dp_duedate.toggleCalendar()">
              <i class="fa fa-calendar-o"></i>
            </button>
          </div>
        </div>

        <app-validation-errors [mformField]="due_date" label="Due Date">
        </app-validation-errors>
      </div> -->


      <!-- <div class="form-group col-12  col-sm-6 col-md-3 col-xl-3">
              <label for="notes">Notes</label>
              <textarea class="form-control" name="notes" id="notes" placeholder="Notes"
                [(ngModel)]="purchaseorder.notes"></textarea>
            </div> -->
      <!-- <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="account_head">Account Head-Liability</label>
              <ng-select [items]="account_head_list" #liability="ngModel" required name="account_head" bindLabel="name"
                [(ngModel)]="purchaseorder.account_head" placeholder="Select Liability Account Head">
                <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div [ngOptionHighlight]="search">
                    {{ item.name }}
                  </div>
                </ng-template>
              </ng-select>
              <app-validation-errors [mformField]="account_head" label="Account Head"></app-validation-errors>
            </div> -->
      <div class="card">
        <div class="card-body form-row p-0 pb-3">
          <table class="col-12 table table-stripped table-hover table-bordered">
            <thead class="thead-light">
              <tr>
                <th style="width: 4%;"><b>S.No</b></th>
                <th style="width: 40%;"><b>{{company_details?.purchase_column_settings?.items}}</b></th>
                <!-- <th>{{company_details?.purchase_column_settings?.description}}</th> -->
                <th style="width: 8%;"><b>{{company_details?.purchase_column_settings?.units}}</b></th>
                <th style="width: 10%;"><b>{{company_details?.purchase_column_settings?.price}}</b></th>
                <!-- <th>Tax%</th> -->
                <th style="width: 8%;"><b>Tax</b></th>
                <th style="width: 8%;"><b>Amount</b></th>
                <th style="width: 12%;"><b>Action</b></th>
              </tr>

            </thead>
            <tbody *ngFor="let itemsObj of purchaseorder.purchase_order_items; let i = index">
              <tr>
                <td>
                  {{i+1}}
                </td>
                <td>
                  <ng-select [items]="product_lists" [typeahead]="productListTypeahead" #product="ngModel" required
                    name="items{{i}}" bindLabel="name" [hideSelected]="true"
                    (change)="getPriceFromHSN(itemsObj.product,itemsObj);getTaxAmount(itemsObj);getAmount(itemsObj);"
                    [(ngModel)]="itemsObj.product" placeholder="Select Items">
                    <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <div [ngOptionHighlight]="search">
                        {{ item.name }}
                      </div>
                    </ng-template>
                  </ng-select>
                  <!-- <div *ngIf="is_credit_note">
                          {{itemsObj.product.name}}
                        </div> -->
                  <app-validation-errors [mformField]="product" label="Product"></app-validation-errors>
                  <textarea type="text" class="form-control mt-1" name="description{{i}}" id="description"
                    placeholder="Description" [(ngModel)]="itemsObj.description"></textarea>
                </td>
                <!-- <td>
                        <input type="text" class="form-control" name="description{{i}}" id="description"
                          placeholder="Description" [(ngModel)]="itemsObj.description">

                      </td> -->

                <td>
                  <input type="number" [min]="1" pattern="^\d*[^\.]?$" class="form-control" #units="ngModel" required
                    name="units{{i}}" id="units{{i}}" placeholder="Units" [(ngModel)]="itemsObj.units"
                    (change)="getTaxAmount(itemsObj);getAmount(itemsObj);getTotalAmount();">
                  <app-validation-errors [mformField]="units" label="Units"></app-validation-errors>

                </td>

                <td>
                  <input type="number" [min]="1" pattern="^\d*(\.\d{0,2})?$" class="form-control" #price="ngModel"
                    required name="price{{i}}" id="price{{i}}" placeholder="Price" [(ngModel)]="itemsObj.price"
                    (change)="getTaxAmount(itemsObj);getAmount(itemsObj);getTotalAmount();">
                  <app-validation-errors [mformField]="price" label="Price"></app-validation-errors>
                </td>
                <!-- <td *ngIf="itemsObj.tax==undefined">
                        0%
                        <input type="number" class="form-control" name="tax{{i}}" id="tax"
                          placeholder="Tax in percentage" [(ngModel)]="itemsObj.tax" title="Tax in percentage">
                      </td> -->

                <!-- <td *ngIf="itemsObj.tax!=undefined"
                        title="sgst:{{itemsObj.sgst}} cgst:{{itemsObj.cgst}} igst:{{itemsObj.igst}}">
                        {{itemsObj.tax}}%
                      </td> -->
                <td class="">
                  {{itemsObj.tax_amount| currency: currency}}
                  <span *ngIf="itemsObj.tax!=undefined"
                    title="sgst:{{itemsObj.sgst}} cgst:{{itemsObj.cgst}} igst:{{itemsObj.igst}}">
                    ({{itemsObj.tax}} %)
                  </span>
                </td>
                <td class="">
                  {{itemsObj.amount| currency: currency}}
                </td>

                <td>
                  <button type="button" class="btn btn-danger" (click)="deleteItem(i);getTotalAmount();">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                  <button type="button" class="btn btn-primary ml-1" (click)="addNewItem()">
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- Totals Table -->

          <!-- <div>
                <span class="mr-2 float-right">
                  <b>Actual Total Amount: {{purchaseorder.actual_total_amount}}</b>
                </span>
                <br>
                <span *ngIf="is_same_state" class="mr-2 float-right">
                  <b> CGST Amount: {{purchaseorder.cgst}}</b>
                </span>
                <br *ngIf="is_same_state">
                <span *ngIf="is_same_state" class="mr-2 float-right">
                  <b> SGST Amount: {{purchaseorder.sgst}}</b>
                </span>
                <br *ngIf="is_same_state">
                <span *ngIf="is_same_state==false" class="mr-2 float-right">
                  <b> IGST Amount: {{purchaseorder.igst}}</b>
                </span>
                <br *ngIf="is_same_state==false">
                <span class="mr-2 float-right">
                  <b> Total Tax Amount: {{purchaseorder.total_tax_amount}}</b>
                </span>
                <br>
                <span class="mr-2 float-right">
                  <b>Total Amount: {{purchaseorder.total_amount}}</b>
                </span>
                <br>
              </div> -->
        </div>
        <div class="row justify-content-between">
          <div class="form-group col-sm-4 col-md-4 col-xl-4">
            <label for="notes">Notes</label>
            <textarea class="form-control notes" name="notes" id="notes" placeholder="Notes"
              [(ngModel)]="purchaseorder.notes"></textarea>
          </div>

          <table class="float-right table col-4">
            <tbody>
              <tr>
                <th><b>Actual Total Amount</b></th>
                <td class="money"><b>{{purchaseorder.actual_total_amount| currency: currency}}</b></td>
              </tr>
              <tr *ngIf="is_same_state">
                <th><b>CGST Amount</b></th>
                <td class="money"><b>{{purchaseorder.cgst| currency: currency}}</b></td>
              </tr>
              <tr *ngIf="is_same_state">
                <th><b>SGST Amount</b></th>
                <td class="money"><b>{{purchaseorder.sgst| currency: currency}}</b></td>
              </tr>
              <tr *ngIf="is_same_state==false">
                <th><b>IGST Amount</b></th>
                <td class="money"><b>{{purchaseorder.igst| currency: currency}}</b></td>
              </tr>
              <tr>
                <th><b>Total Amount</b></th>
                <td class="money"><b>{{purchaseorder.total_amount| currency: currency}}</b></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button type="submit" [disabled]="!addPurchaseorderForm.valid || loading==true" class="btn btn-primary">Save
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
      </button>

    </div>
  </form>
  <!-- </div> -->
  <!-- </div> -->
</div>
<!-- </div> -->
<!-- TODO Add vendor in purchase order if there is no data in vendor -->
<!-- <div>
<app-vendors-add></app-vendors-add>
</div> -->
