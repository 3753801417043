<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <form novalidate #searchStocktransactionsForm="ngForm"
        (ngSubmit)="getStocktransactionssList({ offset: 0, pageSize: 10 })">
        <div class="card">
          <div class="card-body">
            <div class="card-header d-flex align-items-center">


              <h3 class="table-title">Stock Transactions List</h3>


            </div>
            <div class="card-body row">
              <!-- <div class="form-group col-sm- ">
        <label class="col-form-label ">Account Head</label>
        <div class="">
<ng-select [items]="account_headList" name = "account_head"
                 #account_head="ngModel" [(ngModel)] = "filters.account_head" 
                class="form-control"
                
                
                >
                <ng-template ng-label-tmp let-item="item">

                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                
                </ng-template>
                <ng-template ng-footer-tmp>
                <button type="button" class="btn btn-primary"><i class="fa fa-plus"></i>Add Account Head</button>
    </ng-template>
                </ng-select>
                
                <app-validation-errors 
                [mformField] = "account_head" 
                label = "Account Head" >
                 </app-validation-errors>
</div> -->
              <!-- </div> -->
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 ">
                <label>Product</label>
                <div class="">
                  <ng-select [items]="product_lists" [typeahead]="productListTypeahead" required name="product"
                    id="product" bindLabel="name" [(ngModel)]="filters.product" placeholder="Select Product">
                    <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <div [ngOptionHighlight]="search">
                        {{ item.name }}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 ">
                <label>In Qty</label>
                <div class="">
                  <input type="number" class="form-control" name="in_qty" #in_qty="ngModel" id="in_qty"
                    [(ngModel)]="filters.in_qty" placeholder="">
                </div>
              </div>
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 ">
                <label class="">Out Qty</label>
                <div class="">
                  <input type="number" class="form-control" name="out_qty" #out_qty="ngModel" id="out_qty"
                    [(ngModel)]="filters.out_qty" placeholder="">
                </div>
              </div>
              <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 ">
                <label class="">Balance Qty</label>
                <div class="">
                  <input type="number" class="form-control" name="bal_qty" #bal_qty="ngModel" id="bal_qty"
                    [(ngModel)]="filters.bal_qty" placeholder="">
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit" class="btn btn-primary mr-2">
                <i class="fa fa-search"></i> Search</button>
              <button (click)="clearStocktransactionsFilters();" class="btn btn-danger">
                <i class="fa fa-close"></i> Clear
              </button>

            </div>
          </div>
        </div>
      </form>
      <!-- <div class="p-sm-2">
        <button class="btn btn-primary float-right" routerLink="/stocktransactions/add">
          <i class="icon icon-add"></i>Add Stocktransactions
        </button>
      </div> -->

      <div class="p-sm-5">

        <ngx-datatable [rows]="stocktransactionsList" [loadingIndicator]="loadingIndicator" [externalPaging]="true"
          [columnMode]="'force'" [limit]="page.pageSize" [count]="page.count" [offset]="page.offset"
          (page)="getStocktransactionssList($event)" [reorderable]="reorderable" [headerHeight]="50" [footerHeight]="50"
          [rowHeight]="'auto'" class="material striped" [summaryRow]="'true'" [summaryPosition]="'bottom'"
          [summaryHeight]="'auto'">

          <ngx-datatable-column *ngFor="let col of stocktransactionsListColumns" [flexGrow]=" col.flexGrow"
            [name]="col.name" [sortable]="col.sortable" [cellClass]="col.css_class" [prop]="col.prop">
            <ng-template ngx-datatable-header-template let-sort="sortFn">
              <span (click)="sort()">{{ col.name }}</span>
            </ng-template>
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
              <div [ngSwitch]="col.type">
                <!-- <span *ngSwitchCase="'currency'"> {{row[col.prop]|currency:currency}}</span>
                <span *ngSwitchCase="'percentage'">{{row[col.prop]}}%</span> -->
                <!-- <span *ngSwitchCase="'action'">
          
                  <div class="btn-group mr-3">
                    <a class="btn btn-primary" routerLink="/contra/edit/{{value}}" type="button" rel="tooltip">Edit</a>
                    <div class="btn-group" ngbDropdown placement="bottom-right" container="body" role="group">
                      <a class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></a>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <a class="dropdown-item" routerLink="/contra/edit/{{value}}" type="button" rel="tooltip">Edit</a>
                        <a class="dropdown-item " (click)="deleteContra(value)">
                          <span class="text-red">
                            <i class="icon-trash-can3"></i> Delete
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
          
          
                </span> -->
                <span *ngSwitchCase="'object'"> {{ row[col.prop][col.field_name] }}</span>
                <span *ngSwitchCase="'date'"> {{ row[col.prop]|date }}</span>
                <span *ngSwitchCase="'tx_type'"> {{ row[col.prop]|tx_type }}</span>
                <span *ngSwitchCase="'tx_ref_id'"> {{ row[col.prop]|ref_link }}--</span>
                <span *ngSwitchCase="'currency'">{{
                  row[col.prop] | currency: currency
                }}</span>
                <span *ngSwitchCase="'status'"> <span class="badge badge-success">{{ row[col.prop]}}</span></span>
                <span *ngSwitchDefault>
                  {{ row[col.prop] }}
                </span>
              </div>

            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>

  </div>
</div>

<ng-template #actionTmpl let-value="value">

  <div class="btn-group mr-3">
    <button class="btn btn-primary" routerLink="/stocktransactions/edit/{{value}}" type="button"
      rel="tooltip">Edit</button>
    <div class="btn-group" ngbDropdown placement="bottom-right" role="group">
      <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
      <div class="dropdown-menu" ngbDropdownMenu>
        <button class="dropdown-item" routerLink="/stocktransactions/edit/{{value}}" type="button"
          rel="tooltip">Edit</button>
        <button class="dropdown-item " (click)="deleteStocktransactions(value)">
          <span class="text-red">
            <i class="icon-trash-can3"></i> Delete
          </span>
        </button>
      </div>
    </div>
  </div>



</ng-template>