import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
  pure: false
})
export class JoinPipe implements PipeTransform {
  transform(items: any[], glue: string = ',', is_icon: boolean = false): any {
    glue = is_icon == true ? '<i class="' + glue + '"></i>' : glue;
    return items.join(glue);
  }
}