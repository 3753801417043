<div class="container-fluid view">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header d-flex align-items-center">
          <div class="row" style="width: 100%;">
            <!-- <div class="col-md-10">
              <h3 class="table-title">{{header}} View</h3>
            </div> -->
            <!-- <div class="col-md-2">
              <button class="table-title btn btn-warning" (click)="goBack()">Go Back</button>
            </div> -->
          </div>
        </div>
        <div class="card-body row">
          <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="customer">{{party}} <span class="badge badge-info">{{viewDetails.currency}}</span></label>
            <div>
              {{viewDetails.party}}
            </div>
          </div>



          <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="currency">Currency</label>
            <div>
              {{partyCurrency}}
            </div>
          </div> -->

          <div *ngIf="viewDetails.orderNumber" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">

            <label for="sales_order_number">{{orderLabel}} #</label>
            <div>{{viewDetails.orderNumber}}</div>

          </div>
          <div *ngIf="viewDetails.orderDate" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="sales_order_date">{{orderLabel}} Date</label>
            <div>
              {{viewDetails.orderDate|date}}
            </div>
          </div>
          <div *ngIf="viewDetails.billNumber" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="invoice_number">{{billLabel}} #</label>
            <div>{{viewDetails.billNumber}}</div>

          </div>

          <div *ngIf="viewDetails.billDate" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="invoice_date">{{billLabel}} Date</label>
            <div>
              {{viewDetails.billDate|date}}
            </div>
          </div>
          <div *ngIf="viewDetails.dueDate" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="due_date">Due Date</label>
            <div>
              {{viewDetails.dueDate|date}}
            </div>
          </div>

          <div *ngIf="viewDetails.ledger" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="customer">{{billLabel}} ledger</label>
            <div>
              {{viewDetails.ledger}}
            </div>
          </div>
          <div *ngIf="viewDetails.vehicle_number != '0' && viewDetails.vehicle_number != null" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="vehicle_number">Vehicle No</label>
            <div>
              {{viewDetails.vehicle_number}}
            </div>
          </div>
          <div *ngIf="viewDetails.eway_bill_no" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="vehicle_number">E-Way No</label>
            <div>
              {{viewDetails.eway_bill_no}}
            </div>
          </div>
          <div *ngIf="viewDetails.delivery_challan_no" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="vehicle_number">Delivery Challan No</label>
            <div>
              {{viewDetails.delivery_challan_no}}
            </div>
          </div>
          <div *ngIf="viewDetails.billingAddress" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="billing_address">Billing Address</label>
            <div>
              {{viewDetails.billingAddress}}
            </div>
          </div>
          <div *ngIf="viewDetails.shipping_address" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="shipping_address">Shipping Address</label>
            <div>
              {{viewDetails.shipping_address}}
            </div>
          </div>
          <div *ngIf="viewDetails.deliveryNote" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="delivery_note">Delivery Note</label>
            <div>
              {{viewDetails.deliveryNote}}
            </div>
          </div>

          <div *ngIf="viewDetails.image" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <a href="{{this.path}}" target="_blank">{{viewDetails.image?.originalname}}</a>
          </div>
          <div class="card">
            <div class="card-body form-row p-0 pb-3">
              <!-- <table class="col-12 table table-hover table-vcenter table_custom text-nowrap spacing5 text-nowrap mb-0"> -->
              <table class="col-12 table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>{{company_details.sales_column_settings.items}}</th>
                    <th>{{company_details.sales_column_settings.description}}</th>
                    <th>{{company_details.sales_column_settings.units}}</th>
                    <th>{{company_details.sales_column_settings.price}}</th>
                    <th *ngIf="viewDetails.totalTaxAmount>0">Tax%</th>
                    <th *ngIf="viewDetails.totalTaxAmount>0">Tax Amount</th>
                    <th *ngIf="viewDetails.isDiscount ==true">DISCOUNT</th>
                    <th>{{company_details.sales_column_settings.amount}}</th>
                  </tr>

                </thead>
                <tbody>
                  <tr *ngFor="let itemsObj of viewDetails.items; let i = index">
                    <td>
                      {{i+1}}
                    </td>
                    <td>
                      {{itemsObj.product.name}}
                    </td>
                    <td>
                      {{itemsObj.description}}
                    <td>
                      {{itemsObj.units}}
                    </td>
                    <td>
                      {{itemsObj.price|currency:viewDetails.currency}}
                    </td>

                    <td *ngIf="viewDetails.totalTaxAmount>0"
                      title="sgst:{{itemsObj.sgst}} cgst:{{itemsObj.cgst}} igst:{{itemsObj.igst}}">
                      {{itemsObj.tax}}%
                    </td>
                    <td *ngIf="viewDetails.totalTaxAmount>0">
                      {{itemsObj.tax_amount|currency:viewDetails.currency}}
                    </td>
                    <td *ngIf="viewDetails.isProductwiseDiscount ==true && viewDetails.discountInPercentage==false">
                      {{itemsObj.discount_in_amount |currency:viewDetails.currency}}</td>
                    <td *ngIf="viewDetails.isProductwiseDiscount ==true && viewDetails.discountInPercentage==true">
                      {{itemsObj.discount_in_percentage}}%</td>
                    <td>
                      {{itemsObj.amount|currency:viewDetails.currency}}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div *ngIf="viewDetails.balancingItems" class="col-12 p-0">
                <!-- <table class="float-right col-4 table table-hover table-vcenter table_custom text-nowrap spacing5 text-nowrap mb-0"> -->
                <table class="float-right col-4 table p-0">
                  <thead>
                    <tr>
                      <th>Account Head</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let balancingObj of viewDetails.balancingItems; let i = index">
                      <td>{{balancingObj.account_head?.flattened_data?.name}}</td>
                      <td>{{balancingObj.amount|currency:viewDetails.currency}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="col-4"></div>
              <div class="col-4">
                <table class="table final-table mt-4">
                  <tbody>
                    <tr>
                      <th><b>Actual Total Amount</b></th>
                      <td>
                        <b>{{viewDetails.actualTotalAmount |currency:viewDetails.currency}}</b>
                      </td>
                    </tr>
                    <tr *ngIf="viewDetails.isDiscount ==true">
                      <th><b>Discount</b></th>
                      <td>
                        <b>{{viewDetails.discount |currency:viewDetails.currency}}</b>
                      </td>
                    </tr>
                    <tr>
                      <th><b>Sub Total Amount</b></th>
                      <td>
                        <b>{{viewDetails.subTotalAmount |currency:viewDetails.currency}}</b>
                      </td>
                    </tr>
                    <tr *ngIf="viewDetails.cgst>0">
                      <th><b>CGST Amount</b></th>
                      <td>
                        <b>{{viewDetails.cgst |currency:viewDetails.currency}}</b>
                      </td>
                    </tr>
                    <tr *ngIf="viewDetails.sgst>0">
                      <th><b>SGST Amount</b></th>
                      <td>
                        <b>{{viewDetails.sgst |currency:viewDetails.currency}}</b>
                      </td>
                    </tr>
                    <tr *ngIf="viewDetails.igst>0">
                      <th><b>IGST Amount</b></th>
                      <td>
                        <b>{{viewDetails.igst |currency:viewDetails.currency}}</b>
                      </td>
                    </tr>
                    <tr *ngIf="viewDetails.totalTaxAmount>0">
                      <th><b>Total Tax Amount</b></th>
                      <td>
                        <b>{{viewDetails.totalTaxAmount |currency:viewDetails.currency}}</b>
                      </td>
                    </tr>
                    <tr>
                      <th><b>Total Amount</b></th>
                      <td>
                        <b>{{viewDetails.totalAmount |currency:viewDetails.currency}}</b>
                      </td>
                    </tr>
                    <!-- <tr>
                    <th><b>Balance Amount</b></th>
                    <td>
                      <b>{{ rem_amount | currency: currency}}</b>
                    </td>
                  </tr> -->
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>