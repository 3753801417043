import { Component, OnInit } from "@angular/core";
import { Product } from "../../../_models/product.model";

@Component({
  selector: "app-product-group-add",
  templateUrl: "./product-group-add.component.html",
  styleUrls: ["./product-group-add.component.scss"],
})
export class ProductGroupAddComponent implements OnInit {
  public product = new Product();
  groupList: any = [];
  loading: boolean = false;

  constructor() {}

  ngOnInit() {}

  addGroupProduct() {}
  addNewGroup() {}
}
