<div id="page_top" class="section-body">
  <div class="container-fluid">
    <!-- <div class="right">
    <button type="button" class="btn btn-primary" routerLink="/company/add/new"
      routerLinkActive="router-link-active">Add Company</button>
  </div> -->
    <!-- <div class="ml-2">
    
    <ng-select (change)="updateDefaultCompany()" [items]="companies_list" [typeahead]="companiesListTypeahead" name="company" bindLabel="company"
      [(ngModel)]="selected_company" placeholder="Select Company">
      <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <div [ngOptionHighlight]="search">
          {{ item?.company }}
        </div>
      </ng-template>
    </ng-select>
  </div> -->
    <form class="row">
      <div class="form-group col-5 required">
        <label>Select Company</label>
        <ng-select [items]="companies_list" (change)="setBranch()" #company_name="ngModel" required
          [typeahead]="companiesListTypeahead" name="company" bindLabel="company" [(ngModel)]="selected_company"
          placeholder="Select Company">
          <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div [ngOptionHighlight]="search">
              {{ item?.company }}
            </div>
          </ng-template>
        </ng-select>
        <app-validation-errors [mformField]="company_name" label="Company"></app-validation-errors>
      </div>
      <div *ngIf="is_branch==true" class="form-group col-5 required">
        <label>Select Branch</label>
        <ng-select [items]="branch_lists" (change)="updateDefaultCompany();" #branch_name="ngModel" required
          name="branch" bindLabel="name" [(ngModel)]="selected_branch" placeholder="Select Branch">
          <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div [ngOptionHighlight]="search">
              {{ item?.name }}
            </div>
          </ng-template>
        </ng-select>
        <app-validation-errors [mformField]="branch_name" label="Branch"></app-validation-errors>
      </div>
      <app-financial-years *ngIf="selected_company"  [financialYears]="financialYearsConfig"></app-financial-years>
      <!-- <a class="btn btn-primary float-right mb-4" routerLink="/masters/hsn/item/add">
        <i class="icon icon-add"></i>Add Hsn
    </a> -->
    </form>
  </div>
</div>