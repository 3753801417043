<div id="page_top" class="section-body sticky-top">
  <div class="container-fluid">
    <div class="page-header">
      <div class="left col-10">

        <div class="dropdown d-flex mr-2" ngbDropdown *ngIf="getDefaultCompanyId() != ''">
          <button (click)="addDropdown=!addDropdown" class="btn dropdown-toggle" type="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" ngbDropdownToggle> <i class="fa fa-2x fa-plus-circle" style="color: #db5644;"></i></button>

          <div class="dropdown-menu  dropdown-menu-arrow" ngbDropdownMenu [ngClass]="addDropdown ? 'show' : ''">
            <ng-template ngFor let-add [ngForOf]="quickAdd">
              <a class="dropdown-item" routerLink="{{add.path}}" ngbDropdownItem>
                {{add.name}}
              </a>
            </ng-template>
          </div>
        </div>
        <h1 class="page-title" *ngIf="getDefaultCompanyId() != ''"> {{pageTitle}} </h1>

        <!-- <div class="input-group search_modules col-4" *ngIf="getDefaultCompanyId() != ''">
          <div class="input-group-prepend">

            <div class="dropdown d-flex" ngbDropdown display="dynamic" placement="bottom-left">
              <button (click)="searchDropdown=!searchDropdown" class="btn btn-outline-primary dropdown-toggle"
                type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                {{current_search_module?.name}} <i class="fa fa-search"></i></button>

              <div class="dropdown-menu dropdown-menu-arrow" [ngClass]="searchDropdown ? 'show' : ''" ngbDropdownMenu>
                <ng-template ngFor let-search [ngForOf]="quickSearch">
                  <a class="dropdown-item" ngbDropdownItem (click)="selectSearchModule(search.id)">
                    {{search.id}} 
                    <i *ngIf="search.isSelected==true" class="fa fa-check"></i> {{search.name}}
                  </a>
                </ng-template>
              </div>
            </div>
          </div>
          <input type="text" [(ngModel)]="search_text" (keyup.enter)="search()" class="form-control"
            aria-label="Text input with dropdown button">
        </div> -->
        <h6 *ngIf="getDefaultCompanyName() != ''" class="ml-2 mt-2 mr-2 company-title"> {{ getDefaultCompanyName() }} </h6>
        <div *ngIf="selected_company != null && selected_company != undefined" >
          <app-financial-years  [financialYears]="financialYearsConfig"></app-financial-years>
        </div>
        <h6 class="ml-2 mt-2" style="text-transform: capitalize;">
          <!-- <span *ngIf="getDefaultCompanyId() != '' && is_head_office">HO: {{ branch_name }} </span> -->
          <span *ngIf="getDefaultCompanyId() != '' && !is_head_office && branch_name!=''">Branch: {{ branch_name }}
          </span>
        </h6>

      </div>
      <div class="right col-2">
        <div class="col mr-1">
          <!-- <a *ngIf="first_name!=''" class="" title="Role: {{role_name}}">{{first_name}}</a> -->
        </div>
        <!-- <div class="col mr-1">
          <a *ngIf="role_name!=''" class="">Role: {{role_name}}</a>
        </div> -->
        <div class="">
          <a type="button" *ngIf="getDefaultCompanyId() != ''" class="btn btn-primary" (click)="clearCompanyData()"
            routerLinkActive="router-link-active"><i class="fa fa-home">&nbsp;</i></a>
        </div>
        <div class="">
          <a type="button" *ngIf="getDefaultCompanyId() == ''" class="btn btn-primary" [routerLink]="['/company/add']"
            routerLinkActive="router-link-active">Add Company</a>
        </div>
        <!-- <div class="ml-2"> -->
        <!-- <select (change)="updateDefaultCompany()" class="form-control" style="width: max-content;" name="company"
            [(ngModel)]="selected_company">
            <option *ngFor="let company_details of companies_list" [ngValue]="company_details">
              {{company_details.company}}</option>
          </select> -->
        <!-- <ng-select (change)="updateDefaultCompany()" [items]="companies_list" [typeahead]="companiesListTypeahead" name="company" bindLabel="company"
            [(ngModel)]="selected_company" placeholder="Select Company">
            <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <div [ngOptionHighlight]="search">
                {{ item?.company }}
              </div>
            </ng-template>
          </ng-select> -->
        <!-- </div> -->

        <div class="notification d-flex">

          <div class="dropdown d-flex" ngbDropdown>
            <a (click)="showDropdown=!showDropdown" class="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
              data-toggle="dropdown" ngbDropdownToggle><i class="fa fa-user" ngbDrop>&nbsp;</i> <span *ngIf="first_name!=''" class="" title="Role: {{role_name}}">{{first_name}}</span></a>
            <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow" ngbDropdownMenu
              [ngClass]="showDropdown ? 'show' : ''">
              <a class="dropdown-item" ngbDropdownItem routerLink="/user/view/{{user_id}}"><i
                  class="dropdown-icon fe fe-user"></i> Profile</a>
                  <a class="dropdown-item" ngbDropdownItem routerLink="/user/reset_password/{{user_id}}"><i
                    class="dropdown-icon fe fe-user"></i> Change password</a>
              <a class="dropdown-item" ngbDropdownItem routerLink="/company/edit/{{company_id}}"><i
                  class="dropdown-icon fe fe-settings"></i>
                Company Info</a>
              <a class="dropdown-item" ngbDropdownItem routerLink="/coa/edit"><i
                  class="dropdown-icon fe fe-settings"></i>
                COA</a>
              <a class="dropdown-item" ngbDropdownItem href="javascript:void(0)"><span class="float-right"><span
                    class="badge badge-primary">6</span></span><i class="dropdown-icon fe fe-mail"></i> Inbox</a>
              <a class="dropdown-item" ngbDropdownItem href="javascript:void(0)"><i
                  class="dropdown-icon fe fe-send"></i> Message</a>
              <div class="dropdown-divider" ></div>
              <a class="dropdown-item" ngbDropdownItem href="javascript:void(0)" routerLink="/support-ticket-list"><i
                  class="dropdown-icon fe fe-help-circle"></i> Feedback </a>
              <a class="dropdown-item" ngbDropdownItem (click)="logout()"><i class="dropdown-icon fe fe-log-out"></i>
                Sign out</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #setDefaultCompanyForm let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Please Select The Company First</h4>
  </div>
  <div class="modal-body">

    <form>
      <!-- <div class="ml-2"> -->
      <ng-select (change)="updateDefaultCompany()" [items]="companies_list" [typeahead]="companiesListTypeahead"
        name="company" bindLabel="company" [(ngModel)]="selected_company" placeholder="Select Company">
        <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <div [ngOptionHighlight]="search">
            {{ item?.company }}
          </div>
        </ng-template>
      </ng-select>
      <!-- </div> -->
    </form>
  </div>
</ng-template>