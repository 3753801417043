import { Component, OnInit, TemplateRef, ViewChild, EventEmitter, ChangeDetectorRef } from "@angular/core";
import swal from "sweetalert2";
import { CreditBillService } from "../../_services/credit_bill.service";
import { AuthenticationService } from "../../_services/authentication.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { from } from "rxjs";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Customer } from "../../_models/customer";
import { CustomerService } from "../../_services/customer.service";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { ConstantService } from "../../_config/constants";
@Component({
  selector: "app-credit_bill",
  templateUrl: "./credit-bill.component.html",
  styleUrls: ["./credit-bill.component.scss"],
})
export class CreditBillComponent implements OnInit {
  loading:boolean =false;
  filters: any = {};
  debit_bill: any = {};
  debit_billList: any = [];
  debitbillListColumns: any = [];
  count: number;
  loadingIndicator = false;
  reorderable = true;
  columns: any = {};
  sort: any = { created_at: -1 };
  dt_default_sort = [{ prop: "vendor", dir: "asc" }];
  page: any = { offset: 0, limit: 10 };
  page_icon: any;
  company_id: string;
  branch_id: any;
  id: any;
  customerList: any = [];
  customerListTypeahead = new EventEmitter<string>();
  creditNoteNumberListTypeahead = new EventEmitter<string>();
  creditNoteNumberList: any[];
  bill_status_list : any = [
    { name: "Opened", id: 1},
    { name: "Closed", id: 3},
    { name: "Cancelled", id: 4},
    { name: "Draft", id: 5}
  ];
  creditnoteDateOptions: IAngularMyDpOptions = {
    dateRange: true,
    dateFormat: ConstantService.DateFormat
  };
  companyDetails: any;
  currency: any;
  selectFiYear: any;
  // @ViewChild("actionTmpl", { static: true }) actionTmpl: TemplateRef<any>;
  constructor(
    private debitBillService: CreditBillService,
    private authenticationService: AuthenticationService,
    public route: ActivatedRoute,
    private modalService: NgbModal,
    private customerService: CustomerService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.companyDetails = this.authenticationService.getCompanyDetails();
    this.currency = this.companyDetails.base_currency;

    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];
    this.filters.co_date = {
      isRange: true,
      singleDate: null,
      dateRange: {
        beginDate: { year: this.selectFiYear.start_year, month: 4, day: 1 },
        beginJsDate: new Date(this.selectFiYear.start_year, 3, 1),
        endDate: { year: this.selectFiYear.end_year, month: 3, day: 31 },
        endJsDate: new Date(this.selectFiYear.end_year, 2, 31),
      }
    };

    this.getCustomers("").subscribe((result: Customer[]) => {
      this.customerList = result;
    });
    this.getCustomersSearch();
    this.getCreditNoteNumberList("").subscribe((result) => {
      this.creditNoteNumberList = result["response"]["data"];
      console.log('this.creditNoteNumberList===>>>>>', this.creditNoteNumberList);
    });
    this.getCreditNoteNumberListSearch();
    this.debitbillListColumns = [
      {
        prop: "customer",
        name: "Customer Name",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "object",
        field_name: "customer_name",
      },
      {
        prop: "credit_date",
        name: "Credit Note Date",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "date",
        // field_name: "vendor_name"
      },
      {
        prop: "credit_number",
        name: "Credit Note Number",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        // type: "object",
        // field_name: "invoice_number",
      },
      {
        prop: "balance_amount",
        name: "Amount",
        sortable: true,
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
      },
      {
        prop: "invoice_status_name",
        name: "Status",
        sortable: "invoice_status_name",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "status",
      },
      {
        name: "Action",
        type: "action",
        prop: "_id",
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false
      }
    ];
    this.debitbillListColumns.forEach((column) => {
      this.columns[column.prop] = 1;
    });
    this.columns.invoice_number = 1;
    this.columns.invoice_status = 1;
    this.page_icon = this.route.snapshot.data.menu.icon;
    if (this.route.snapshot.queryParams["q"] != "undefined")
      this.filters["vendor.name"] = this.route.snapshot.queryParams["q"];
    this.getDebitBillsList(this.page);
  }
  clearCreditNoteFilters() {
    // this.purchaseorder = {};
    this.filters = {};
    this.getDebitBillsList({ offset: 0, pageSize: 10 });
  }
  getCreditNoteNumberList(searchString: string) {
    let condition: any = {};
    condition["company_id"] = this.company_id;
    // if (searchString != "") {
    condition["creditnote_number_search_string"] = searchString;
    // }
    return this.debitBillService.getCreditBills(
      condition,
      { credit_number: 1 },
      {},
      { offset: 0, limit: 10 }
    );
  }

  getCreditNoteNumberListSearch() {
    this.creditNoteNumberListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getCreditNoteNumberList(searchString))
      )
      .subscribe(
        (result) => {
          this.creditNoteNumberList = [];
          this.cd.markForCheck();
          this.creditNoteNumberList = result["response"]["data"];
        },
        (err) => {
          console.log(err);
          this.creditNoteNumberList = [];
        }
      );
  }

  getCustomers(searchString) {
    this.customerList = [];
    let condition: any = {};
    let columns: any = {};
    if (searchString != "") {
      condition["customer_name"] = searchString;
    }
    condition["company_id"] = this.company_id;
    columns["customer_name"] = 1;
    columns["first_name"] = 1;
    columns["last_name"] = 1;
    columns["currency"] = 1;
    columns["address_line1"] = 1;
    columns["billing_address"] = 1;
    columns["shipping_address"] = 1;
    columns["payment_term"] = 1;
    columns["gst_number"] = 1;
    columns["coa_data"] = 1;
    columns["debit_note_data"] = 1;
    columns["country"] = 1;
    columns["state"] = 1;
    columns["phone"] = 1;
    return this.customerService.getCustomersData(condition, columns);
  }

  getCustomersSearch() {
    this.customerListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getCustomers(searchString))
      )
      .subscribe(
        (result: Customer[]) => {
          this.cd.markForCheck();
          this.customerList = result;
        },
        (err) => {
          console.log(err);
          this.customerList = [];
        }
      );
  }

  open(content, id) {
    this.id = id;
    this.modalService.open(content, {size: "lg"});
  }
  onSort(event) {
    this.loadingIndicator = true;
    // console.log("event", event);
    this.sort = {};
    let sort_details = event.sorts[0];
    this.sort[sort_details.prop] = sort_details.dir == "asc" ? 1 : -1;
    this.getDebitBillsList(this.page);
  }

  getDebitBillsList(page_info: any) {
    if (page_info != null) {
      this.page = page_info;
    }
    this.filters["company_id"] = this.company_id;

    this.debitBillService
      .getCreditBills(this.filters, this.columns, this.sort, this.page)
      .subscribe((result) => {
        // this.debit_billListColumns = [purchase_order_number
        //   { name: "Action", cellTemplate: this.actionTmpl, prop: "muid" }
        // ];
        this.debit_billList = result["response"]["data"];
        this.page.count = result["response"]["count"];
      });
  }
  clearDebitBillFilters() {
    this.filters = {};
    this.getDebitBillsList({});
  }
  deleteDebitBill(muid) {
    swal({
      title: "Are you sure want to delete?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result) {
        this.debitBillService.deleteCreditBill(muid).subscribe((result) => {
          if (result["ok"] === 1) {
            swal("Deleted!", "Credit Bill has been deleted.", "success");
            this.getDebitBillsList({});
          }
        });
      }
    });
  }
  confirmDebitNote(row_data){
    // this.loading =true;
    swal({
      title: "Approve the Credit note ?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Approve it!",
    }).then((result) => {
      if (result) {
        this.debitBillService.confirmCreditNote(row_data).subscribe((result) => {
          // this.loading =false;
          if (result["ok"] === 1) {
            swal("Success!", "CreditNote has been approved.", "success");
            this.getDebitBillsList({});
          }
        });
      }
    });

}
}
