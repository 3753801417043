import {ContactAddress} from '../_models/contact_address.model';

export class AddressTemplate {
    action_type: ActionType;
    // address: ContactAddress = new ContactAddress(); 
    contact_address: ContactAddress = new ContactAddress(); 
    user_role?: UserRole;
    address_type?: AddressType;
    user_muid?: string;
    address_muid?: string;
    callbackFunction?: Function;
}

export class AddressTemplateEmitConfig {
    emit_action_type: EmitActionType; 
    address_type: AddressType; 
    user_role: UserRole; 
    user_muid: string; 
    contact_address?: ContactAddress;
}

export type UserRole =  'vendor' | 'customer';
export type ActionType =  'add'| 'table_viewall' | 'view'| 'edit'| 'dropdown_viewall';
export type EmitActionType =  'selected_address';
export type AddressType =  'billing_address'| 'shipping_address';

