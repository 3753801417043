import { TemplateRef } from '@angular/core';


const enum dataTypeEnum {
    Text = 'text',
    Date = 'date',
    Boolean = 'option',
    Number = 'number'
};
const enum summaryType {
    Sum = 'sum',
    Min = 'min',
    Max = 'max',
    Avg = 'avg',
    Distinct = 'distinctcount',
};
export class ReportCustomization {

    report_name: string;
    url: string;
    filters: Filter[];
    columns: Column[];
    apiurl: [string, string];
    menuName: string;
    permissions: [];
    default_filter: Filter = new Filter();

}

export class Filter {
    column: string;
    type: string;
    value: any
}

export class Column {
    prop: string;
    name: string;
    sortable?: string;
    flexGrow?: number;
    show?: boolean;
    isDetailedRow?: boolean;
    filter?: boolean;
    cellTemplate?: TemplateRef<any>;
    pipe?: string;
    cellClass?: string;
    rowClass?: string;
    conditionalClass?: string;
    dataType?: dataTypeEnum;
    summaryRow?: summaryType;
    isGroup?: boolean;
    format?: string;
    filterAPI?: string[];
    getFilterDataByFunction?: Function;
    postSummary?: boolean;
    preSummary?: boolean;
    filterType?: string;
    filterLabel?: string;
    filterId?: string;
}
