import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import swal from "sweetalert2";
import { ExpenseService } from "../../_services/expense.service";
// import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { AuthenticationService } from "../../_services/authentication.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PaymentModalComponent } from "../payment-modal/payment-modal.component";
import { MastersService } from "../../_services/masters.service";
import { ConstantService } from "src/app/_config/constants";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { HsnService } from "@app/_services/hsn.service";
import { SequenceService } from "../../_services/sequence.service";
import { CompanyService } from "../../_services/company.service";
import { BillService } from "../../_services/bill.service";
import { fi } from "date-fns/locale";

@Component({
  selector: "app-expense",
  templateUrl: "./expense.component.html",
  styleUrls: ["./expense.component.scss"],
  providers: [NgbModalConfig, NgbModal],
})
export class ExpenseComponent implements OnInit {
  filters: any = {};
  sort: any = { created_at: -1 };
  dt_default_sort = [{ prop: "payee", dir: "asc" }];
  count: number;
  company_id: string;
  expense: any = {};
  expenseList: any = [];
  expenseListColumns: any = [];
  page: any = { offset: 0, pageSize: 12 };
  loadingIndicator = false;
  reorderable = true;
  dispCheckBox: boolean = true;

  // @ViewChild("expenseTable", { static: true }) table: any;
  columns: any = {};
  page_icon: any;

  selected_items = [];
  selectedOrder: any;
  payType: string = "";
  selecteditem_array: any;
  item_selected: any;

  invoice_array: any = [];
  pay_head: string;
  currency: string;
  module_from: string = "expense";
  passed: boolean;
  passed_message: string;
  selected_items_billid = [];
  selected_type: any;
  passed_type: boolean;
  payeeList: any = [];
  payeeListTypeahead = new EventEmitter<string>();
  payment_dateDpOptions: IAngularMyDpOptions = {
    dateRange: true,
    dateFormat: ConstantService.DateFormat,
  };
  rcm: any = {};
  hsncodeArray: any = [];
  hsnListTypeahead = new EventEmitter<string>();
  expense_id: any;
  is_igst: any;
  company_details: any;
  rcm_number: any;
  sequence_no: any;
  sequence_id: any;
  rcm_order_number: any;
  seq_no: any;
  id: string;
  expensePayeeList: any = [];
  loading: boolean = true;
  billExpenseStatusList: any = [
    { name: "Draft", id: 5 },
    { name: "Paid", id: 3 },
    { name: "Unpaid", id: 1 },
    { name: "Partially Paid", id: 2 },
    { name: "Cancelled", id: 4 }
  ];
  modalReference: any;
  billNumberListTypeahead = new EventEmitter<string>();
  billNumberList: any[];
  companyDetails: any;
  selectFiYear: any;

  constructor(
    private expenseservice: ExpenseService,
    private authenticationService: AuthenticationService,
    public route: ActivatedRoute,
    private modalService: NgbModal,
    private payeeService: MastersService,
    private cd: ChangeDetectorRef,
    private hsnService: HsnService,
    private sequenceService: SequenceService,
    private router: Router,
    private companyService: CompanyService,
    private billService: BillService,

  ) { }

  ngOnInit() {
    this.expensePayeeList = [
      {
        id: 1,
        name: "With payee",
      },
      {
        id: 2,
        name: "Without payee",
      },
    ];
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.companyDetails = this.authenticationService.getCompanyDetails();
    this.currency = this.companyDetails.base_currency;

    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];
    this.filters.payment_date = {
      isRange: true,
      singleDate: null,
      dateRange: {
        beginDate: { year: this.selectFiYear.start_year, month: 4, day: 1 },
        beginJsDate: new Date(this.selectFiYear.start_year, 3, 1),
        endDate: { year: this.selectFiYear.end_year, month: 3, day: 31 },
        endJsDate: new Date(this.selectFiYear.end_year, 2, 31),
      }
    };

    this.getPayee("").subscribe((result) => {
      this.payeeList = result["response"];
    });
    this.getPayeesearch();
    this.currency = this.authenticationService.getCompanyDetails()[
      "base_currency"
    ];

    this.getCompanyDetails();

    this.expenseListColumns = [
      {
        prop: "bill_number",
        name: "Expense Invoice",
        sortable: "expense_invoice",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
      },
      {
        prop: "payee",
        name: "Payee",
        sortable: "Payee",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "payee",
        field_name: "first_name",
      }, {
        prop: "billing_date",
        name: "Date",
        sortable: "billing_date",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "date",
      },
      {
        prop: "total_expense_amount",
        name: "Total Amount",
        sortable: "Total Amount",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "currency",
        css_class: "align-right",
      },
      {
        prop: "balance_amount",
        name: "Balance Amount",
        sortable: "Balance Amount",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "currency",
        css_class: "align-right",
      },

      // {
      //   prop: "payment_method.name",
      //   name: "Payment Method",
      //   sortable: "payment method",
      //   flexGrow: 1,
      //   show: true,
      //   // type: "object",
      //   // field_name: "name",
      //   is_detailed_row: false,
      // },
      {
        prop: "expense_status_name",
        name: "Status",
        sortable: "expense_status_name",
        flexGrow: 1,
        type: "status",
        show: true,
        is_detailed_row: false,
      },
      {
        prop: "is_without_gst",
        name: "is_without_gst",
        sortable: false,
        flexGrow: 1,
        show: false,
        is_detailed_row: false,
      },
      {
        prop: "expense_object",
        name: "expense_object",
        sortable: false,
        flexGrow: 1,
        show: false,
        is_detailed_row: false,
      },
      {
        prop: "rcm",
        name: "rcm",
        sortable: false,
        flexGrow: 1,
        show: false,
        is_detailed_row: false,
      },
      {
        name: "Action",
        type: "action",
        prop: "_id",
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false,
      },
    ];
    this.expenseListColumns.forEach((column) => {
      this.columns[column.prop] = 1;
      this.columns['draft_data'] = 1;
    });
    this.page_icon = this.route.snapshot.data.menu.icon;
    if (this.route.snapshot.queryParams["q"] != "undefined")
      this.filters["payee"] = this.route.snapshot.queryParams["q"];
    this.getExpensesList(this.page);
    this.getBillNumberList("").subscribe((result) => {
      this.billNumberList = result["response"]["data"];
      console.log('this.ref_id_lists====>>>>', this.billNumberList);
    });
    this.getBillNumberListSearch();
    this.getHsncodeSearch();
    this.getHsnCode("").subscribe((result) => {
      this.hsncodeArray = result;
    });
  }
  getCompanyDetails() {
    this.companyService.viewCompany(this.company_id).subscribe((result) => {
      this.company_details = result["response"];
      if (this.id == "" || this.id == null) {
        this.getNextSequence();
      }
      // this.purchaseorderDateOptions.dateFormat = this.company_details.date_format;
      // this.dueDateOptions.dateFormat = this.company_details.date_format;
      // let book_beginning_date = new Date(this.company_details.book_beginning);
      // book_beginning_date.setDate(book_beginning_date.getDate() - 1);
      // let currentDueDateOptions = this.getCopyOfOptions();
      // currentDueDateOptions.disableUntil = {
      //   year: book_beginning_date.getFullYear(),
      //   month: book_beginning_date.getMonth() + 1,
      //   day: book_beginning_date.getDate(),
      // };
      // this.dueDateOptions = currentDueDateOptions;
      // let currentPurchaseOrderDtOptions = this.getCopyOfOptionsDue();
      // currentPurchaseOrderDtOptions.disableUntil = {
      //   year: book_beginning_date.getFullYear(),
      //   month: book_beginning_date.getMonth() + 1,
      //   day: book_beginning_date.getDate(),
      // };
      // this.purchaseorderDateOptions = currentPurchaseOrderDtOptions;
      // this.dueDateOptions = currentDueDateOptions;
      // this.purchaseorder.purchase_order_date = this.purchaseorderDateOptions;
      // this.purchaseorder.purchase_order_date = {
      //   isRange: false,
      //   singleDate: {
      //     jsDate: new Date(),
      //   },
      // };
      // this.purchaseorder.due_date = this.dueDateOptions;
      // this.purchaseorder.due_date = {
      //   isRange: false,
      //   singleDate: {
      //     jsDate: new Date(),
      //   },
      // };
    });
  }

  open(content, id) {
    this.id = id;
    this.modalReference = this.modalService.open(content, { size: "lg" });
  }

  onSort(event) {
    this.loadingIndicator = true;
    // console.log("event", event);
    this.sort = {};
    let sort_details = event.sorts[0];
    this.sort[sort_details.prop] = sort_details.dir == "asc" ? 1 : -1;
    this.getExpensesList(this.page);
  }

  getBillNumberList(searchString) {
    // let condition: any = {};
    // condition["company_id"] = this.company_id;
    // if (searchString != "") {
    // condition["po_number_search_string"] = searchString;
    // }
    // if(condition['vendors']){
    //   condition['payee'] = condition['vendors'];
    // }
    // condition["payee"]['$exists'] = true;
    // condition["bill_number_search_string"] = searchString;
    // if (page_info != null) {
    //   this.page = page_info;
    // }
    if (searchString != "") {
    this.filters["bill_number_search_string"] = searchString;
    }
    this.filters["company_id"] = this.company_id;
    this.filters["payee"] = {};
    if(this.filters['vendors']){
      this.filters['payee'] = this.filters['vendors'];
    }
    this.filters["payee"]['$exists'] = true;
    return this.expenseservice.getExpenseInvoice(
      this.filters,
      this.columns, this.sort, this.page
    );
  }

  getBillNumberListSearch() {
    this.billNumberListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getBillNumberList(searchString))
      )
      .subscribe(
        (result) => {
          this.billNumberList = [];
          this.cd.markForCheck();
          this.billNumberList = result["response"]["data"];
        },
        (err) => {
          console.log(err);
          this.billNumberList = [];
        }
      );
  }

  getPayee(searchString) {
    this.payeeList = [];
    return this.payeeService.getCoaHead(this.company_id, searchString, {
      in: [
        //ConstantService.coa_heads.Accounts_Receivable,
        ConstantService.coa_heads.Accounts_Payable,
      ],
    });
  }

  getPayeesearch() {
    this.payeeListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getPayee(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.payeeList = result["response"];
        },
        (err) => {
          console.log(err);
          this.payeeList = [];
        }
      );
  }

  getExpensesList(page_info: any) {
    if (page_info != null) {
      this.page = page_info;
    }
    this.filters["company_id"] = this.company_id;
    this.filters["payee"] = {};
    if(this.filters['vendors']){
      this.filters['payee'] = this.filters['vendors'];
    }
    this.filters["payee"]['$exists'] = true;

    this.expenseservice
      .getExpenseInvoice(this.filters, this.columns, this.sort, this.page)
      .subscribe((result) => {
        this.loadingIndicator = false;
        this.expenseList = result["response"]["data"];
        this.page.count = result["response"]["count"];
      });
  }
  clearExpenseFilters() {
    this.filters = {};
    this.getExpensesList({ offset: 0, pageSize: 12 });
  }
  deleteExpense(muid) {
    swal({
      title: "Are you sure want to delete?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result) {
        this.expenseservice.deleteExpense(muid).subscribe((result) => {
          if (result["nModified"] === 1) {
            this.getExpensesList({});

            swal("Deleted!", "Expense has been deleted.", "success");
          }
        });
      }
    });
  }
  onSelectOrders(selected) {
    this.selected_items = [];
    this.selected_items_billid = [];
    this.selected_type = [];
    selected["selected"].forEach((element) => {
      if (element.expense_type == "customer") {
        this.selected_type.push("customer");
        this.selected_items.push(element.payee["ref_id"]);
      } else {
        this.selected_type.push("vendor");
        this.selected_items.push(element.payee["ref_id"]);
      }
      this.selected_items_billid.push(element._id);
    });
  }
  isEqualVendor(element, index, array) {
    return element == array[0];
  }
  isEqualType(element, index, array) {
    return element == array[0];
  }

  openPaymentModal(row_data, pay_method) {
    let selected: any = [];
    let payment_by = "";
    if (row_data) {
      selected.push(row_data);
      this.onSelectOrders({ selected });
    }

    this.selecteditem_array = [];

    this.item_selected = this.selected_items[0];

    if (pay_method == "bulk") {
      if (this.selected_items.length == 1) {
        this.payType = "single";
        this.passed = true;
        this.passed_type = true;
      } else {
        this.payType = "multiple";
        this.passed = this.selected_items.every(this.isEqualVendor);
        this.passed_type = this.selected_type.every(this.isEqualType);
      }
    } else if (pay_method == "single") {
      this.payType = "single";
      this.passed = true;
      this.passed_type = true;
    }
    if (this.passed && this.passed_type) {
      const modalRef = this.modalService.open(PaymentModalComponent, {
        size: "lg",
      });

      let dataArray = {
        bill_ids: this.selected_items_billid,
        payment_from: "Expense",
        payment_type: this.payType,
        payment_by: this.selected_type[0],
        hide_advance_div: true,
      };

      modalRef.componentInstance.selectedarray_data = dataArray;
      modalRef.result
        .then((result) => {
          console.log(result);
          this.getExpensesList(this.page);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      swal("Error!", "Select same Payee / choose an order", "error");
    }
  }
  getHsnCode(searchString) {
    let condition: any = {};
    let columns: any = {};
    this.hsncodeArray = [];
    if (searchString != "") {
      condition["search_string"] = searchString;
    }

    condition["effects_from_is_active"] = 1;
    columns["code"] = 1;
    columns["description"] = 1;
    columns["group_code "] = 1;
    columns["country_code"] = 1;
    columns["effects_from"] = 1;

    return this.hsnService.getHsnCode(condition, columns, {}, { limit: 10 });
  }
  getHsncodeSearch() {
    this.hsnListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getHsnCode(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.hsncodeArray = result;
        },
        (err) => {
          console.log(err);
          this.hsncodeArray = [];
        }
      );
  }
  openRcm(data, modal_name) {
    this.getNextSequence();
    this.modalService.open(modal_name, { size: "lg" });
    let condition = {};
    let sort: any = {};
    condition["_id"] =
      data.expense_object["bill_items"][0]["product"]["hsn_muid"];
    if (data["expense_object"]["is_igst"] != undefined) {
      this.is_igst = data["expense_object"]["is_igst"];
    } else {
      this.is_igst = false;
    }
    this.hsnService.getHSNByCondition(condition, sort).subscribe((result) => {
      this.rcm["code"] = result["response"][0];
      this.rcm.actual_amount = data.balance_amount;
      this.calculateTax();
    });
    this.rcm.row_data = data;
  }
  getNextSequence() {
    this.sequenceService
      .viewSequence({ company_id: this.company_id, seq_name: "rcm" })
      .subscribe((result) => {
        if (result["response"] != "" && result["response"] != null) {
          if (result["response"]["seq_no"]) {
            this.sequence_no = result["response"]["seq_no"];
            this.sequence_id = result["response"]["_id"];
            this.rcm.rcm_order_number =
              result["response"]["prefix"] +
              result["response"]["seq_no"]
                .toString()
                .padStart(
                  this.company_details["rcm_column_settings"][
                  "ordernumber_length"
                  ],
                  0
                ) +
              result["response"]["suffix"];
          }
        }
      });
  }
  calculateTax() {
    let data = {};
    let itemsObj = {};
    this.rcm.code["effects_from"].filter((e) => {
      if (e.is_active) {
        data = e;
        itemsObj["tax"] = e.igst;
        itemsObj["sgst"] = e.sgst;
        itemsObj["cgst"] = e.cgst;
        this.getTaxAmount(itemsObj);
      }
    });
  }
  getTaxAmount(taxObj) {
    this.rcm.cgst = 0;
    this.rcm.sgst = 0;
    this.rcm.igst = 0;
    this.rcm.total_tax_amount = 0;
    this.rcm.tax_amount = 0;

    this.rcm.tax_amount = (this.rcm.actual_amount / 100) * taxObj.tax;
    if (this.is_igst) {
      this.rcm.igst = taxObj.tax;
    } else {
      this.rcm.cgst = taxObj.tax / 2;
      this.rcm.sgst = taxObj.tax / 2;
      this.rcm.cgst_amount = this.rcm.tax_amount / 2;
      this.rcm.sgst_amount = this.rcm.tax_amount / 2;
    }
    this.rcm.total_tax_amount = this.rcm.actual_amount + this.rcm.tax_amount;
  }
  fileRcm() {
    this.expenseservice.fileRcm(this.rcm).subscribe((result) => {
      if (result["nModified"] == 1) {
        this.updateSequence();
        swal("RCM updated sucessfully!", "rcm added.", "success");
        this.getExpensesList({});
        this.modalService.dismissAll();
      } else {
        this.showErrors(result["response"]);
      }
    });
  }
  showErrors(errors) {
    let error_string: string;
    error_string = " ";
    errors.forEach((item) => {
      error_string += " < br / > " + item;
    });
    swal({
      title: "Error",
      html: error_string,
      type: "error",
    }).then(function () { });
  }
  showhideColumns(columns) {
    return columns.filter((cols) => {
      return cols.show != false ? true : false;
    });
  }

  updateSequence() {
    let sequence_data: any = {};
    let sequence_muid: any = {};
    sequence_data["seq_no"] = parseInt(this.sequence_no) + 1;
    sequence_muid["muid"] = this.sequence_id;
    sequence_data["company_id"] = this.company_id;
    this.sequenceService
      .editSequence(sequence_data, sequence_muid["muid"])
      .subscribe((result) => { });
  }
  sendExpense(row_data) {
    swal({
      title: "Approve the Expense  ?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Approve it!",
    }).then((result) => {
      if (result) {
        row_data['draft_data']['payment_object']['tx_type'] = 51;
        this.expenseservice.confirmExpense(row_data["draft_data"]["expense_id"], this.company_id).subscribe((result) => {
          if (result["ok"]) {
            this.billService
              .createDoubleEntryTransaction(result["data"]['expense_object'])
              .subscribe((result_payment) => {
                if (result_payment["statusCode"] == 200) {
                  this.loading = false;
                  swal("Success!", "Bill has been approved.", "success");
                  this.getExpensesList({});
                  this.router.navigateByUrl("/bill_expense/list");
                }
                //
              });
          }
        });
      }
    });
  }
}
