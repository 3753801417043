<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header d-flex align-items-center">
          <h3 class="table-title">Contra Add</h3>
        </div>
        <form novalidate #addContraForm="ngForm" (ngSubmit)="addUpdateContra()">
          <div class="card-body row">
            <div class="form-group required col-12 mt-2 pl-5
                                                col-sm-6 col-md-2 col-xl-2">
              <label for="contra_id">Contra Id</label>
              <div> {{contra.contra_id}} </div>
            </div>
            <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
              <label class="">Transaction Date</label>
              <div class="input-group">
                <input angular-mydatepicker #tx_date="ngModel" placeholder="Transaction Date"
                  (click)="tx_date_dp.toggleCalendar()" #tx_date_dp="angular-mydatepicker" class="form-control"
                  name="tx_date" [(ngModel)]="contra.tx_date" [options]="tx_dateDpOptions"
                  (dateChanged)="ontx_dateDateChanged($event)">
                <!-- clear date button -->
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" *ngIf="contra.tx_date"
                    (click)="tx_date_dp.clearDate()">
                    <i class="fa fa-close"></i>
                  </button>
                </div>

                <!-- toggle calendar button -->
                <div class="input-group-append">
                  <button type="button" class="btn btn-secondary" (click)="tx_date_dp.toggleCalendar()">
                    <i class="fa fa-calendar-o"></i>
                  </button>
                </div>
              </div>
              <app-validation-errors [mformField]="tx_date" label="Transaction Date">
              </app-validation-errors>
            </div>

            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label class="" for="voucher_type">Voucher Type</label>
              <app-voucher-type (onSelectVoucher)="getVoucherType($event)" [voucherCategoryCode]=5></app-voucher-type>
            </div>

            <div class="form-group col-12 col-sm-6 col-md-4 col-xl-4 ">
              <label class="">Description</label>
              <div class="">
                <textarea type="textarea" class="form-control" name="memo" #memo="ngModel" id="memo"
                  [(ngModel)]="contra.memo" placeholder=""></textarea>
                <app-validation-errors [mformField]="memo" label="Memo">
                </app-validation-errors>
              </div>
            </div>

            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th style="width: 25%;">Account Head</th>
                  <th>Debit</th>
                  <th>Credit</th>
                  <th>description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let contra_items of contra.contra_items; let i=index;">
                  <td>
                    <ng-select [items]="from_bankList" [typeahead]="fromBankListTypeahead" name="from_bank{{i}}"
                      required #from_bank="ngModel" (change)="validateAccountHead()" [hideSelected]="true"
                      [(ngModel)]="contra_items.account_head" id="from_bank{{i}}" bindLabel="name">
                      <ng-template ng-label-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <div [ngOptionHighlight]="search">
                          <b>{{item?.flattened_data?.name}}</b> - ({{item?.flattened_data?.parent_name[0]}})
                        </div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <div [ngOptionHighlight]="search">
                          <b>{{item?.flattened_data?.name}}</b> - ({{item?.flattened_data?.parent_name[0]}})
                        </div>
                      </ng-template>
                    </ng-select>

                    <app-validation-errors [mformField]="account" label="Account Head">
                    </app-validation-errors>
                  </td>

                  <td><input type="number" [min]="0" class="form-control" name="debit{{i}}" #debit="ngModel"
                      id="debit{{i}}" [(ngModel)]="contra_items.debit" placeholder=""
                      (change)="contra_items.credit=0;sumOfCreditDebit();">
                    <app-validation-errors [mformField]="debit" label="Debit">
                    </app-validation-errors>
                  </td>
                  <td><input type="number" [min]="0" class="form-control" name="credit{{i}}" #credit="ngModel"
                      id="credit{{i}}" [(ngModel)]="contra_items.credit" placeholder=""
                      (change)="contra_items.debit=0;sumOfCreditDebit();">
                    <app-validation-errors [mformField]="credit" label="Credit">
                    </app-validation-errors>
                  </td>
                  <td><textarea type="textarea" class="form-control" name="description{{i}}" #description="ngModel"
                      id="description{{i}}" [(ngModel)]="contra_items.description" placeholder=""></textarea>
                    <app-validation-errors [mformField]="description" label="description">
                    </app-validation-errors>
                  </td>
                  <td>
                    <button type="button" class="btn btn-primary" (click)="addNewItem(i)"><i
                        class="fa fa-plus"></i></button>
                    <button *ngIf="i>0" type="button" class="btn btn-danger" (click)="deleteItem(i)"><i
                        class="fa fa-trash"></i></button></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div style="margin-left: 4rem;">
            <span>
              <b>Sum of Debit: {{sum_of_debit}}</b>
            </span>
            <span style="margin-left: 7rem;">
              <b>Sum of Credit: {{sum_of_credit}}</b>
            </span>
          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-primary" [disabled]="(!addContraForm.valid || sum_of_debit!=sum_of_credit || sum_of_debit <=0 ||  sum_of_credit<=0) || loading==true">Save
              <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
            </button>

          </div>
        </form>
      </div>

    </div>
  </div>
</div>
