<div *ngIf="!isAuthenticated()">
    <router-outlet></router-outlet>
</div>
<ngx-loading-bar includeSpinner="false"></ngx-loading-bar>
<div *ngIf="isAuthenticated()">
    <!-- {{getDefaultCompanyId()}} -->
    <!-- <div *ngIf="getDefaultCompanyId()==''"> -->

    <!-- <app-header></app-header> -->
    <!-- <app-home></app-home> -->
    <!-- <router-outlet></router-outlet> -->

    <!-- </div> -->
    <!-- <div *ngIf="getDefaultCompanyId()!=''"> -->
    <app-sidebar></app-sidebar>
    <app-header></app-header>
    <div class="page">
        <app-page-topbar></app-page-topbar>
        <div class=" mt-3">
            <div class="container-fluid">
                <router-outlet></router-outlet>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
    <!-- </div> -->

</div>