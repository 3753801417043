import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import swal from "sweetalert2";
import { JournalService } from "../../../_services/journal.service";
import { Journal } from "../../../_models/journal";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { MastersService } from "../../../_services/masters.service";
import { AuthenticationService } from "../../../_services/authentication.service";
import { CompanyService } from "../../../_services/company.service";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { distinctUntilChanged, debounceTime, switchMap } from "rxjs/operators";
import { ConstantService } from "src/app/_config/constants";
import { ProductService } from "../../../_services/product.service";
import { differenceInDays } from "date-fns";

@Component({
  selector: "app-journal-add",
  templateUrl: "./stock-journal-add.component.html",
  styleUrls: ["./stock-journal-add.component.scss"],
})
export class StockJournalAddComponent implements OnInit {
  loading: boolean =false;
  id: string;
  is_edit_form: boolean = false;
  journal: Journal = new Journal();
  productList: any = [];
  productListTypeahead = new EventEmitter<string>();
  company_id: string;
  head: any = {};
  journal_dateDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };
  purchaseorderDateOptions: IAngularMyDpOptions = {
    // other options...
    dateRange: false,
    disableUntil: { year: 0, month: 0, day: 0 },
    disableSince: { year: 0, month: 0, day: 0 },
    dateFormat: ConstantService.DateFormat,
  };
  sum_of_debit: number;
  sum_of_credit: number;
  accountList: any[];
  company_details: any;
  sequence_id: any;
  sequence_no: any;
  selectFiYear: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private journalservice: JournalService,
    private countryservice: MastersService,
    private authenticationService: AuthenticationService,
    private companyService: CompanyService,
    private productService: ProductService,

    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.selectFiYear = this.authenticationService.getCompanyDetails()['fi_year'];

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get("id");
      if (this.id != null) {
        this.getJournal();
      }

      var result = this.router.url.match("/edit/");
      if (result != null) {
        if (result[0] === "/edit/" && this.id != null) {
          this.is_edit_form = true;
        }
      } else {
        this.getNextSequence();
        this.journal.journal_date = {
          isRange: false,
          singleDate: {
            jsDate: new Date(),
          },
        };
      }
    });
    this.addNewItem(0);
    this.journal.journal_date={
      isRange: false,
      singleDate: {
        jsDate: differenceInDays(new Date(this.selectFiYear.end_year, 2, 31), new Date()) > 0 && differenceInDays(new Date(), new Date(this.selectFiYear.start_year, 3, 1)) > 0 ? new Date() : new Date(this.selectFiYear.end_year, 2, 31),
      },
    };



    // this.countryservice.getCoa(this.company_id).subscribe((result) => {
    //   this.accountList = result['response'];

    // });

    // this.getCoaHead('').subscribe(result => {
    //   this.productList = result['response'];
    // });
    // this.getCoaHeadSearch();
    this.getproduct();
    this.getCompanyDetails();
  }

  //   this.getJournalsList();

  onjournal_dateDateChanged(event: IMyDateModel): void {
    // date selected
  }
  getNextSequence() {
    let stockjournal_sequence: any = {};
    stockjournal_sequence["seq_name"] = "stockjournal";
    stockjournal_sequence["company_id"] = this.company_id;
    this.journalservice
      .viewSequence(stockjournal_sequence)
      .subscribe((result) => {
        let data = result["response"];
        if (data != "" && data != null) {
          this.journal.journal_no =
            data["prefix"] + data["seq_no"] + data["suffix"];
        }
        this.sequence_id = data["_id"];
        this.sequence_no = data["seq_no"];
      });
  }
  addStockJournal() {
    this.loading =true;
    this.journal.company_id = this.company_id;
    //  this.journal.product = this.journal.journal_items;
    //Journal transaction type id starts from 31
    this.journal.tx_type_id = 101;

    this.journal.journal_date = this.journal.journal_date["singleDate"][
      "jsDate"
    ].toISOString();
    this.journalservice.addStockJournal(this.journal).subscribe((result) => {
      if (result["status_code"] === 1) {
        this.loading =false;
        swal("Added journal sucessfully!", "journal added.", "success");
        this.router.navigateByUrl("/stockjournal/list");
      } else {
        this.showErrors(result[" response "][" errors "]);
      }
    });
  }
  getJournal() {
    this.journalservice.viewJournal(this.id).subscribe((result) => {
      this.journal = result[" response "];
    });
  }

  addUpdateJournal() {
    if (this.is_edit_form === true) {
      this.editJournal();
    } else {
      // this.addJournal();
    }
  }

  editJournal() {
    this.loading =true;
    this.journalservice
      .editJournal(this.journal, this.id)
      .subscribe((result) => {
        if (result[" response "] == 1) {
          this.loading =false;
          swal(" Success ", " Journal Updated Successfully ");
          this.router.navigateByUrl("/journal/list");
        } else {
          this.showErrors(result[" response "][" errors "]);
        }
      });
  }

  sumOfCreditDebit() {
    this.sum_of_debit = 0;
    this.sum_of_credit = 0;
    this.journal.journal_items.forEach((element) => {
      this.sum_of_debit += element.debit;
      this.sum_of_credit += element.credit;
    });
  }

  showErrors(errors) {
    let error_string: string;
    error_string = " ";
    errors.forEach((item) => {
      error_string += " < br / > " + item;
    });
    swal({
      title: "Error",
      html: error_string,
      type: "error",
    }).then(function () {});
  }

  addNewItem(index) {
    this.journal.journal_items.push({
      // account_head: [],
      // debit: "",
      // credit: "",
      // item_value: "",
    });
  }
  deleteItem(index) {
    this.journal.journal_items.splice(index, 1);
  }
  getCoaHead(searchString) {
    this.accountList = [];
    return this.countryservice.getCoaHead(this.company_id, searchString, {
      not_in: [ConstantService.coa_heads.Bank, ConstantService.coa_heads.Cash],
    });
  }

  getCoaHeadSearch() {
    this.productListTypeahead
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchString) => this.getCoaHead(searchString))
      )
      .subscribe(
        (result) => {
          this.cd.markForCheck();
          this.productList = result["response"];
        },
        (err) => {
          console.log(err);
          this.productList = [];
        }
      );
  }
  changeDebit(journal_items) {
    if (journal_items.credit != "") {
      journal_items.debit = 0;
    }
  }

  changeCredit(journal_items) {
    if (journal_items.debit != "") {
      journal_items.credit = 0;
    }
  }
  getproduct() {
    let condition: any = {};
    condition = {
      company_id: this.company_id,

    };
    this.productService
      .getAllProducts(condition, 1, "")
      .subscribe((result) => {
        this.productList = result;
      });
  }

  getCompanyDetails() {
    this.companyService.viewCompany(this.company_id).subscribe((result) => {
      this.company_details = result["response"];
      this.journal_dateDpOptions.dateFormat = this.company_details.date_format;
      let book_beginning_date = new Date(this.company_details.book_beginning);
      book_beginning_date.setDate(book_beginning_date.getDate() - 1);
      let curren_tx_dateOptions = this.getCopyOfOptions();
      curren_tx_dateOptions.disableUntil = {
        year: book_beginning_date.getFullYear(),
        month: book_beginning_date.getMonth() + 1,
        day: book_beginning_date.getDate(),
      };
      curren_tx_dateOptions.disableUntil = {year:this.selectFiYear.start_year, month:3, day:31};
      curren_tx_dateOptions.disableSince = {year:this.selectFiYear.end_year, month:4, day:1};
      this.journal_dateDpOptions = curren_tx_dateOptions;
    });
  }
  getCopyOfOptions(): IAngularMyDpOptions {
    return JSON.parse(JSON.stringify(this.journal_dateDpOptions));
  }
  calculate_totalvalue(journal_items) {
    journal_items.total_value = 0;
    if (journal_items.in_qty > 0) {
      journal_items.total_value =
        journal_items.in_qty * journal_items.item_value;
    } else if (journal_items.out_qty > 0) {
      journal_items.total_value =
        journal_items.out_qty * journal_items.item_value;
    }
  }
}
