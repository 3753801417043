
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';
@Injectable({ providedIn: 'root', })
export class ContraService {
    branch_id: string;
    constructor(private http: ApiService,
        private authenticationService: AuthenticationService) {
        this.branch_id = this.authenticationService.getDefaultBranchId();
    }

    addContra(contra_data: any) {
        if (this.branch_id != '') {
            contra_data['branch_id'] = this.branch_id;
        }
        return this.http.post(ApiService.geturl('contra', 'add'),
            contra_data);
    }
    editContra(contra_data: any, id: string) {

        return this.http.post(ApiService.geturl('contra', 'edit'),
            { data: contra_data, contra_id: id });
    }
    deleteContra(id: string) {
        return this.http.post(ApiService.geturl('contra', 'delete'),
            { id: id });
    }

    viewContra(id: string, condition: {}) {
        console.log('222211001111===', id);
        if (this.branch_id != "") {
            condition["branch_id"] = this.branch_id;
          }
        return this.http.post(ApiService.geturl('contra', 'view'),
            { contra_id: id, condition: condition });
    }

    getContras(filters: any = {}, columns?: any, sort: any = {}, page: any = {}) {
        return this.http.post(ApiService.geturl('contra', 'viewall'),
            { filters: filters, columns: columns, sort: sort, page: page });
    }
}