import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthenticationService } from "./authentication.service";
import {ContactAddress} from '../_models/contact_address.model';
@Injectable({
  providedIn: 'root'
})
export class VendorService {
  branch_id: string;
  constructor(private http: ApiService,
    private authenticationService: AuthenticationService,
  ) {
    this.branch_id = this.authenticationService.getDefaultBranchId();
  }

  createVendor(vendorObj) {
    if (this.branch_id != '') {
      vendorObj['branch_id'] = this.branch_id;
    }
    return this.http.post(ApiService.geturl("vendor", "add"), vendorObj)
  }

  getVendors(filters: any = {}, columns: any = {}, sort: any = {}, page: any = {}) {
    if (this.branch_id != '') {
      filters['branch_id'] = this.branch_id;
    }
    return this.http.post(ApiService.geturl('vendor', 'viewall'),
      { filters: filters, columns: columns, sort: sort, page: page });
  }
  viewVendor(vendor_id: any) {
    return this.http.post(ApiService.geturl('vendor', 'view'),
      { vendor_id });
  }
  editVendor(vendor_data: any, id: string) {

    return this.http.post(ApiService.geturl('vendor', 'edit'),
      { data: vendor_data, vendor_id: id });
  }
  deleteVendor(id: string) {
    return this.http.post(ApiService.geturl('vendor', 'delete'),
      { id: id });
  }
  getVendorData(filters: any = {}, sort: any = {}, page: any = {}) {
    return this.http.post(ApiService.geturl('vendor', 'data'),
      { filters: filters, sort: sort, page: page });
  }
  saveVendorAddress(vendor_muid: string, contact_address: ContactAddress) {
    return this.http.post(ApiService.geturl("vendor", "add_address"), {
      vendor_muid: vendor_muid,
      contact_address: contact_address
    });
  }

  uploadFiles(files, data) {
    console.log("data", data);
    
    return this.http.uploadFile(ApiService.geturl('vendor', 'upload'),
      files,
      data
    );

  }
}
