import { Component, OnChanges, OnInit, Input, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-validation-errors',
  templateUrl: './validation-errors.component.html',
  styleUrls: ['./validation-errors.component.css']
})
export class ValidationErrorsComponent implements OnChanges {

  @Input() mformField: any;
  @Input() label: any;
  fieldDetails: any;
  fieldLabel: any;
  constructor(private renderer2: Renderer2) {
    
  }

  // ngOnChanges(): void {

  // }
  ngOnChanges() {
    this.fieldDetails = this.mformField;
    // console.log(this.fieldDetails);
    // if (this.fieldDetails.errors) {
    //   // this.renderer2.addClass(this.fieldDetails);
    // }
    this.fieldLabel = this.label;
  }
  getValidationRuleInfo(type) {

    // let rule = this.fieldDetails._rawValidators.filter(rule=>);
    return type;
  }
}
