<!-- <div class="container-fluid"> -->
<!-- <div class="row"> -->
<!-- <div class="col-lg-12"> -->
<form novalidate #searchExpenseForm="ngForm" (ngSubmit)="getExpensesList({ offset: 0, pageSize: 12 })">
  <div class="card">
    <div class="card-body">
      <div class="mr-3 mb-2 float-right">
        <button (click)="openPaymentModal('', 'bulk')" class="btn btn-primary mr-2">
          Pay
        </button>
        <div class="btn-group mr-3">
          <button *ngIf="this.companyDetails.fi_year.status!='Finalized'" class="btn btn-primary" type="button" routerLink="/bill/expense/1" rel="tooltip">
            Expense
          </button>
          <!-- <div class="btn-group" role="group"> -->
          <!-- <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button> -->
          <!-- <div class="dropdown-menu" ngbDropdownMenu>

              <button ngbDropdownItem routerLink="/expense/add/">
                Direct Expense
              </button>
            </div> -->
          <!-- </div> -->
        </div>
      </div>
      <h3 class="table-title"><i class="fa fa-user"></i> Expense</h3>

      <div class="card-body row">
        <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
          <label>Expense Amount</label>
          <input type="text" name="total_expense_amount" class="form-control" placeholder="Expense Amount"
            [(ngModel)]="filters.total_expense_amount">
        </div> -->
        <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
          <label>Payee</label>
          <input type="text" name="payee" class="form-control" placeholder="Payee Name" [(ngModel)]="filters.payee">
        </div> -->

        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
          <label class="col-form-label">Payee</label>
          <ng-select [items]="payeeList" [typeahead]="payeeListTypeahead" name="payee" #payee="ngModel"
            [(ngModel)]="filters.vendors" id="payee" bindLabel="name">
            <ng-template ng-label-tmp let-item="item">
              <div [ngOptionHighlight]="search">
                {{ item?.flattened_data?.name }}
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <div [ngOptionHighlight]="search">
                {{ item?.flattened_data?.name }}
              </div>
            </ng-template>
          </ng-select>
          <app-validation-errors [mformField]="payee" label="Payee">
          </app-validation-errors>
        </div>
        <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
          <label class="col-form-label">Expense</label>
          <ng-select [items]="expensePayeeList" name="with_payee" #payee="ngModel" [(ngModel)]="filters.with_payee"
            id="payee" bindLabel="name">
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <div>
                {{ item?.name }}
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <div [ngOptionHighlight]="search">
                {{ item?.flattened_data?.name }}
              </div>
            </ng-template>
          </ng-select>
          <app-validation-errors [mformField]="payee" label="Payee">
          </app-validation-errors>
        </div> -->
        <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
          <label class="col-form-label" for="ref_id">Ref Id</label>
          <input type="text" class="form-control" name="ref_id" placeholder="Reference ID"
            [(ngModel)]="filters.ref_id" />
        </div> -->
        <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
          <label for="ref_id">Ref Id</label>
          <ng-select (clear)="expenseList=[]" [items]="ref_id_lists" [typeahead]="refidListTypeahead"
            #ref_id="ngModel" name="ref_id" bindLabel="ref_id" bindValue="ref_id"
            [(ngModel)]="filters.ref_id" placeholder="Select Ref Id">
            <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <div [ngOptionHighlight]="search">
                {{ item.expense_object?.ref_id }}
              </div>
            </ng-template>

          </ng-select>
        </div> -->
        <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
          <label class="col-form-label" for="voucher_number">Voucher Number</label>
          <input type="text" class="form-control" name="voucher_number" placeholder="Voucher Number"
            [(ngModel)]="filters.voucher_number" />
        </div> -->
        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
          <label for="bill_number">Expense Invoice Number</label>
          <ng-select (clear)="expenseList=[]" [multiple]="true" [items]="billNumberList" [typeahead]="billNumberListTypeahead"
            name="bill_number" bindLabel="bill_number" bindValue="bill_number"
            [(ngModel)]="filters.bill_number" placeholder="Expense Invoice Number">
            <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <div [ngOptionHighlight]="search">
                {{ item.bill_number }}
              </div>
            </ng-template>
          </ng-select>
        </div>

        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 required">
          <label class="col-form-label">Payment Date</label>
          <div class="">
            <div class="input-group">
              <input angular-mydatepicker placeholder="Payment Date" required (click)="payment_date_dp.toggleCalendar()"
                #payment_date_dp="angular-mydatepicker" class="form-control" name="payment_date"
                [(ngModel)]="filters.payment_date" [options]="payment_dateDpOptions" />

              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" *ngIf="expense.payment_date"
                  (click)="payment_date_dp.clearDate()">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <!-- toggle calendar button -->
              <div class="input-group-append">
                <button type="button" class="btn btn-secondary" (click)="payment_date_dp.toggleCalendar()">
                  <i class="fa fa-calendar-o"></i>
                </button>
              </div>
            </div>
            <app-validation-errors [mformField]="payment_date_dp" label="Payment Date">
            </app-validation-errors>
          </div>
        </div>

        <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
          <label for="expense_status" class="col-form-label">Expense Status</label>
          <ng-select [items]="billExpenseStatusList" name="expense_status" bindLabel="name"
            [(ngModel)]="filters.expense_status" placeholder="Select Expense Status" bindValue="id">
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <div>
                {{ item?.name }}
              </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
              <div [ngOptionHighlight]="search">
                {{ item?.flattened_data?.name }}
              </div>
            </ng-template>
          </ng-select>
          <app-validation-errors [mformField]="payee" label="Payee">
          </app-validation-errors>
        </div>

      </div>
      <div>
        <button type="submit" class="btn btn-primary mr-2">
          <i class="fa fa-search"></i> Search
        </button>
        <button type="button" (click)="clearExpenseFilters()" class="btn btn-danger">
          <i class="fa fa-close"></i>
          Clear
        </button>
      </div>
    </div>
  </div>
</form>

<div class="card p-sm-0">
  <div class="card-body">
    <ngx-datatable [rows]="expenseList" [loadingIndicator]="loadingIndicator" [externalPaging]="true"
      [columnMode]="'force'" [limit]="page.pageSize" [offset]="page.offset" (page)="getExpensesList($event)"
      [externalSorting]="true" (sort)="onSort($event)" [reorderable]="reorderable" [footerHeight]="50"
      [rowHeight]="'auto'" [selectionType]="'checkbox'" (select)="onSelectOrders($event)" class="material striped"
      [sorts]="this.dt_default_sort" [count]="page.count" [sortType]="'multi'">
      <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false" [draggable]="false"
        [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="dispCheckBox">
      </ngx-datatable-column>

      <ngx-datatable-column *ngFor="let col of showhideColumns(expenseListColumns)" [flexGrow]="col.flexGrow"
        [name]="col.name" [sortable]="col.sortable" [cellClass]="col.css_class" [prop]="col.prop">
        <ng-template ngx-datatable-header-template let-sort="sortFn" *ngIf="col.show != false">
          <span (click)="sort()">{{ col.name }}</span>
        </ng-template>

        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div [ngSwitch]="col.type">
            <span *ngSwitchCase="'currency'">
              {{ row[col.prop] | currency: currency }}</span>
            <span *ngSwitchCase="'percentage'">{{ row[col.prop] }}%</span>
            <!-- <span *ngSwitchCase="'object'">{{row[col.prop]}}</span>-->
            <span *ngSwitchCase="'action'">
              <div class="btn-group mr-3" *ngIf="row.expense_status_name =='Draft'">
                <a class="btn btn-primary" name="pay{{ i }}" (click)="sendExpense(row)" type="button"
                  rel="tooltip">Confirm</a>
                <div class="btn-group" ngbDropdown container="body" placement="bottom-right" role="group">
                  <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
                  <div class="dropdown-menu" ngbDropdownMenu>
                    <a class="dropdown-item " (click)="open(expenseInvoiceViewContent, row._id)">
                      <span class="text-blue">
                        <i class="icon-fa-eye"></i> View
                      </span>
                    </a>
                    <a class="dropdown-item" routerLink="/bill_expense/edit/{{ value }}" type="button" rel="tooltip">
                      Edit
                    </a>

                    <a class="dropdown-item"
                      *ngxPermissionsOnly="['Elixir - Admin', 'Elixir - Manager', 'Client - Admin', 'Client - Manager']"
                      (click)="deleteExpense(value)">
                      <span class="text-red">
                        <i class="icon-trash-can3"></i> Delete
                      </span>
                    </a>

                  </div>
                </div>
              </div>

              <div class="btn-group mr-3" *ngIf="row.expense_status_name !='paid' && row.expense_status_name !='Draft'">
                <a class="btn btn-primary" name="pay{{ i }}" (click)="openPaymentModal(row, 'single')" type="button"
                  rel="tooltip">Pay</a>

                <div class="btn-group" ngbDropdown placement="bottom-right" container="body" role="group">
                  <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
                  <div class="dropdown-menu" ngbDropdownMenu>
                    <a class="dropdown-item " routerLink="/expense-debitnote/{{ row._id }}">
                      <span class="text-blue">Debit Note </span>
                    </a>
                    <a class="dropdown-item " (click)="open(expenseInvoiceViewContent, row._id)">
                      <i class="icon-fa-eye"></i> View
                    </a>
                  </div>
                </div>

                <div class="btn-group" *ngIf="row?.is_without_gst == true" ngbDropdown container="body"
                  placement="bottom-right" role="group">
                  <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
                  <div class="dropdown-menu" ngbDropdownMenu>

                    <button class="dropdown-item" (click)="openRcm(row, content)" type="button">
                      RCM
                    </button>

                    <a class="dropdown-item " (click)="open(expenseInvoiceViewContent, row._id)">
                      <span class="text-blue">
                        <i class="icon-fa-eye"></i> View
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </span>
            <span *ngSwitchCase="'payee'">
              {{ row.payee?.flattened_data?.name }}</span>
            <span *ngSwitchCase="'date'"> {{ row[col.prop] | date }}</span>

            <span *ngSwitchCase="'status'">
              <span *ngIf="row[col.prop]=='Draft'" class="badge badge-default">{{row[col.prop]}}</span>
              <span *ngIf="row[col.prop]=='Unpaid'" class="badge badge-danger">{{row[col.prop]}}</span>
              <span *ngIf="row[col.prop]=='partially_paid'&& row[col.prop]!='Draft'"
                class="badge badge-warning">{{row[col.prop]}}</span>

              <span *ngIf="row[col.prop]=='paid' && row[col.prop]!='Draft'"
                class="badge badge-success">{{row[col.prop]}}</span>
            </span>
            <span *ngSwitchDefault>
              {{ row[col.prop] }}
            </span>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
<!-- </div> -->

<!-- </div> -->
<!-- </div> -->

<!-- <ng-template #actionTmpl let-value="value">

  <div class="btn-group mr-3">
    <button class="btn btn-primary" routerLink="/expense/edit/{{value}}" type="button" rel="tooltip">Edit</button>
    <div class="btn-group" ngbDropdown container="body" placement="bottom-right" role="group">
      <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
      <div class="dropdown-menu" ngbDropdownMenu>
        <button class="dropdown-item" routerLink="/expense/edit/{{value}}" type="button" rel="tooltip">Edit</button>
        <button class="dropdown-item " (click)="deleteExpense(value)">
          <span class="text-red">
            <i class="icon-trash-can3"></i> Delete
          </span>
        </button>
      </div>
    </div>
  </div>



</ng-template> -->
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">RCM</h4>

    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body form-group col-12">
    <div class="row">
      <div class="form-group required col-8">
        <label for="hsn_code">Hsn Code / SAC Code</label>
        <ng-select class="hsn_select" (change)="calculateTax()" required name="hsn_code" [items]="hsncodeArray"
          [typeahead]="hsnListTypeahead" bindLabel="code" [(ngModel)]="rcm.code" #hsn_code="ngModel"
          placeholder="Select hsn/Sac Code">
          <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <div [ngOptionHighlight]="search">
              {{ item.code }} - {{ item.description }}
            </div>
          </ng-template>
        </ng-select>
        <app-validation-errors [mformField]="hsn_code" label="Hsn"></app-validation-errors>
      </div>
      <div class="form-group col-4">
        <label>RCM Number</label>
        <input type="text" name="rcm_number" class="form-control" [(ngModel)]="rcm.rcm_order_number" id="rcm_number" />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-4">
        <label>Actual Amount: <br /><br />{{
          rcm.actual_amount | currency: currency
          }}</label>
        <!-- <input type="text" name="actual_amount" disabled class="form-control" [(ngModel)]="rcm.actual_amount"
          id="actual_amount"> -->
      </div>

      <div class="form-group col-4" *ngIf="this.is_igst">
        <label>Igst: <br /><br />{{ rcm.tax_amount | currency: currency }}</label>
        <!-- <input type="text" name="tax_amount" disabled class="form-control" [(ngModel)]="rcm.tax_amount" id="tax_amount"> -->
      </div>
      <div class="form-group col-2" *ngIf="this.is_igst == false">
        <label>Cgst:</label>
        <input type="text" name="cgst_amount" disabled class="form-control" [(ngModel)]="rcm.cgst_amount"
          id="cgst_amount" />
      </div>
      <div class="form-group col-2" *ngIf="this.is_igst == false">
        <label>Sgst:</label>
        <input type="text" name="sgst_amount" disabled class="form-control" [(ngModel)]="rcm.sgst_amount"
          id="sgst_amount" />
      </div>
      <div class="form-group col-4">
        <label>Total Amount: <br /><br />
          {{ rcm.total_tax_amount | currency: currency }}
        </label>
        <!-- <input type="text" name="total_tax_amount" disabled class="form-control" [(ngModel)]="rcm.total_tax_amount"
          id="total_tax_amount"> -->
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="fileRcm()">
      Save
    </button>
  </div>
</ng-template>

<ng-template #expenseInvoiceViewContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Expense Invoice View</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-invoice-view [id]="id" [moduleName]="'expense_invoice'"></app-invoice-view>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
