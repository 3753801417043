import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ComponentFactoryResolver,
  Input,
} from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { startOfMonth, endOfMonth } from "date-fns";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { PaymenttransactionsService } from "src/app/_services/paymenttransactions.service";
import { StocktransactionsService } from "src/app/_services/stocktransactions.service";
import { DatePipe } from "@angular/common";
import { TxTypePipe } from "src/app/_pipes/tx_type.pipe";
import { ToByPipe } from "src/app/_pipes/tx_type.pipe";
import { VoucherTypePipe } from "src/app/_pipes/tx_type.pipe";
import { MastersService } from "src/app/_services/masters.service";
import { VendorService } from "@src/app/_services/vendor.service";
import { ReportsService } from "@src/app/_services/reports.service";
import { filter } from "rxjs/operators";

import { element } from "protractor";
import { ConstantService } from "src/app/_config/constants";
import { forkJoin } from "rxjs/internal/observable/forkJoin";
import { Column } from "@app/reports/column";
import { IfStmt } from "@angular/compiler";
import { type } from "os";

// @Component({
//   selector: "app-test-temp",
//   template: `<ng-template #toByPartyName let-row="row"> {{row['credit']>0?'To':'By'}} {{row['party_name']}} </ng-template>`,
// })
// export class DummyComponent implements OnInit {
//   @Input() testTemp: any;
//   ngOnInit() {

//   }
// }

class DateOnlyPipe extends DatePipe {
  public transform(value): any {
    return super.transform(value, "MM/dd/y");
  }
}

class TransactionOnlyPipe extends TxTypePipe {
  public transform(value): any {
    return super.transform(value);
  }
}
class TobyPipe extends ToByPipe {
  public transform(value): any {
    return super.transform(value);
  }
}
class VouchertypePipe extends VoucherTypePipe {
  public transform(value): any {
    return super.transform(value);
  }
}
// class createLedgerPipe extends ToByPipe {
//   public transform(value): any {
//     return super.transform(value);
//   }
// }
@Component({
  selector: "app-cashbook",
  templateUrl: "./cashbook.component.html",
  styleUrls: ["./cashbook.component.scss"],
})
export class CashbookComponent implements OnInit, AfterViewInit {
  filters: any = [];
  customized_query: any = [];
  defaultSort: any = [];
  dynamicFilterData: any = { prop: "", data: [] };
  tableColumns: any = [];
  groupColumns: any = [];
  apiURL: [string, string];
  cashbookList: any = {
    data: [],
    totals: { pre: [], post: [], grandTotal: [] },
  };
  pagination: any = { offset: 0, pageSize: 100 };
  sort: any;
  columns: {};
  company_id: string;
  branch_id: any;
  currency: string;
  page: any;
  loadingIndicator: boolean;
  page_count: any;
  account_head_lists: any[];
  coa_head: any;
  url_2: boolean = false;
  url_1: boolean = false;
  url_3: boolean = false;
  autoCompleteValues: any;
  customizationId: string;
  report_heading: string = "";
  coa_ledger: any;
  party_array: any;
  checkFilter: (obj: any) => boolean;
  url_4: boolean;
  url_5: boolean;
  url_6: boolean = false;
  url_7: boolean = false;
  url_8: boolean = false;
  url_9: boolean = false;
  url_10: boolean = false;
  url_12: boolean = false;
  url_13: boolean = false;
  url_14: boolean = false;
  url_15: boolean = false;
  url_16: boolean = false;
  url_18: boolean = false;
  url_17: boolean;

  in_condition: any = [];
  vendorList: any[];
  // @ViewChild(DummyComponent, { static: true }) toByPartyName: DummyComponent;
  availableTemplates: any;
  defaultFilters: any;
  reportList: any;
  preSummary: any;
  openingBalance: any;
  closingBalance: any;
  postSummary: any;
  url_19: boolean;
  model: any;
  url_20: boolean;
  url_21: boolean;
  url_22: boolean;
  url_23: boolean;
  reportType: number = 1;
  rowColumns: string[];
  summaryColumns: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private paymenttransactionsservice: PaymenttransactionsService,
    private authenticationService: AuthenticationService,
    private mastersService: MastersService,
    private vendorService: VendorService,
    private reportsService: ReportsService,
    private stocktransactionsService: StocktransactionsService
  ) { }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    // this.availableTemplates = {
    //   toByPartyName: this.toByPartyName
    // };
  }
  ngOnInit() {
    //  this.getSupportTickets();

    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.branch_id = this.authenticationService.getDefaultBranchId();
    this.currency = this.authenticationService.getCompanyDetails()[
      "base_currency"
    ];

    this.route.paramMap.subscribe((params) => {
      // var url1 = this.router.url.match("/report/cashbook");
      // var url2 = this.router.url.match("/report/bankbook");
      // var url3 = this.router.url.match("/report/vendor_ledgeraccount");
      // var url4 = this.router.url.match("/report/sales_register");
      // var url5 = this.router.url.match("/report/purchase_register");
      // var url6 = this.router.url.match("/report/sales_ledger");
      // var url7 = this.router.url.match("/report/purchase_ledger");
      // var url8 = this.router.url.match("/report/receipt");
      // var url9 = this.router.url.match("/report/payment");
      // var url10 = this.router.url.match("/report/stock_item_report");
      // var url12 = this.router.url.match("/report/stock_ageing_analysis");
      // let partynameFilterAvail = {};

      switch (this.router.url) {
        case "/report/cashbook":
          this.customizationId = "5f63778103ecb31ca0d53f43";
          // this.getVendorsData("");
          this.url_1 = true;
          // this.filters.push({
          //   column: "account_head.parent_id",
          //   type: "in",
          //   value: [1031],
          // });
          // this.filters.push({
          //   column: "payment_method",
          //   type: "eq",
          //   value: 1031,
          // });
          // this.getData();
          // this.getCommonColumns();

          //API Call
          break;

        case "/report/bankbook":
          this.customizationId = "5f644a85f5dbc70cffe14ea2";
          //  this.getCoaHead("");
          this.url_2 = true;
          // this.filters.push({
          //   column: "account_head.parent_id",
          //   type: "in",
          //   value: [1030],
          // });
          // this.filters.push({
          //   column: "payment_method",
          //   type: "eq",
          //   value: 1030,
          // });
          // this.getCommonColumns();
          // this.getData();
          // this.getCashBookColumns();
          //API Call
          break;

        case "/report/vendor_ledgeraccount":
          // let partynameFilterAvail = {};
          this.customizationId = "5f6303aa936c6e4436de729c";
          this.url_3 = true;
          this.in_condition = [ConstantService.coa_heads.Accounts_Payable];
          // this.filters.push({
          //   column: "tx_type_id",
          //   type: "ne",
          //   value: 11,
          // });
          // this.filters.push({
          //   column: "payment_method",
          //   type: "gte",
          //   value: 1029,
          // });

          // partynameFilterAvail = (obj) => obj.column === "party_name";
          // this.checkFilter = this.filters.some(partynameFilterAvail);
          // console.log(this.checkFilter);
          // this.getVendorLedgerColumns();

          // this.getData();

          break;

        case "/report/ledger":
          this.customizationId = "6048906eef399515d0b2eaf1";
          break;
        case "/report/party":
          this.customizationId = "60489096ef399515d0b2eaf2";
          break;

        case "/report/sales_register":
          this.customizationId = "5f6454a3f5dbc70cffe15080";
          // this.getData();

          this.url_4 = true;
          // let partynameFilterAvail = {};
          this.in_condition = [ConstantService.coa_heads.Accounts_Payable];
          // this.filters.push({
          //   column: "debit",
          //   type: "gte",
          //   value: 0,
          // });
          // this.filters.push({
          //   column: "credit",
          //   type: "eq",
          //   value: 0,
          // });
          // this.filters.push({
          //   column: "tx_type_id",
          //   type: "eq",
          //   value: 11,
          // });
          // this.filters.push({
          //   column: "payment_method",
          //   type: "exists",
          //   value: false,
          // });
          // this.getSalesPurchaseReport();
          break;

        case "/report/purchase_register":
          this.customizationId = "5f645b50f5dbc70cffe151e9";
          this.url_5 = true;
          let partynameFilterAvail = {};
          this.in_condition = [ConstantService.coa_heads.Accounts_Payable];
          // this.filters.push({
          //   column: "credit",
          //   type: "gte",
          //   value: 0,
          // });
          // this.filters.push({
          //   column: "debit",
          //   type: "eq",
          //   value: 0,
          // });
          // this.filters.push({
          //   column: "tx_type_id",
          //   type: "eq",
          //   value: 1,
          // });
          // this.filters.push({
          //   column: "payment_method",
          //   type: "exists",
          //   value: false,
          // });
          // this.getSalesPurchaseReport();
          // this.getData();
          break;

        case "/report/sales_ledger":
          this.customizationId = "5f645d73f5dbc70cffe1525b";
          this.url_6 = true;
          // let partynameFilterAvail = {};
          this.in_condition = [ConstantService.coa_heads.Accounts_Receivable];

          // this.filters.push({
          //   column: "tx_type_id",
          //   type: "eq",
          //   value: 11,
          // });
          // this.filters.push({
          //   column: "payment_method",
          //   type: "exists",
          //   value: false,
          // });
          // this.filters.push({
          //   column: "debit",
          //   type: "ne",
          //   value: 0,
          // });

          // partynameFilterAvail = (obj) => obj.column === "party_name";
          // this.checkFilter = this.filters.some(partynameFilterAvail);
          // console.log(this.checkFilter);
          // this.getCommonColumns();
          // this.getData();

          break;

        case "/report/purchase_ledger":
          this.customizationId = "5f64653ff5dbc70cffe15440";
          this.url_7 = true;
          // let partynameFilterAvail = {};
          this.in_condition = [ConstantService.coa_heads.Accounts_Payable];

          // this.filters.push({
          //   column: "tx_type_id",
          //   type: "eq",
          //   value: 11,
          // });
          // this.filters.push({
          //   column: "payment_method",
          //   type: "exists",
          //   value: false,
          // });
          // this.filters.push({
          //   column: "debit",
          //   type: "ne",
          //   value: 0,
          // });
          // this.filters.push({
          //   column: "payment_method",
          //   type: "gte",
          //   value: 1029,
          // });

          // partynameFilterAvail = (obj) => obj.column === "party_name";
          // this.checkFilter = this.filters.some(partynameFilterAvail);
          // console.log(this.checkFilter);
          // this.getCommonColumns();
          // this.getData();

          break;

        case "/report/receipt":
          this.customizationId = "5f647420f5dbc70cffe156e6";
          this.url_8 = true;
          // this.filters.push({
          //   column: "party_name",
          //   type: "in",
          //   value: this.company_id,
          // });

          // this.getCommonColumns();
          // this.getData();

          break;

        case "/report/payment":
          this.customizationId = "5f64758af5dbc70cffe15746";
          this.url_9 = true;
          // this.getCommonColumns();
          // this.getData();

          break;

        case "/report/stock_item_report":
          this.customizationId = "5f647601f5dbc70cffe15768";
          this.url_10 = true;
          // this.getStockItemReport();
          // this.getStocks();

          break;

        case "/report/stock_ageing_analysis":
          this.customizationId = "5f647654f5dbc70cffe15782";
          this.url_12 = true;
          // this.getCommonColumns();
          // this.getData();
          break;

        case "/report/credit_note":
          this.customizationId = "5f6476b6f5dbc70cffe157a4";
          this.url_13 = true;
          // this.getCommonColumns();
          // this.getData();
          break;

        case "/report/debit_note":
          this.customizationId = "5f647703f5dbc70cffe157b7";
          this.url_14 = true;
          // this.getCommonColumns();
          // this.getData();
          break;

        case "/report/sales_register":
          this.customizationId = "5f6454a3f5dbc70cffe15080";
          this.url_15 = true;
          // this.getCommonColumns();
          // this.getData();
          break;

        case "/report/journal_register":
          this.customizationId = "5f6477b8f5dbc70cffe157fb";
          this.url_16 = true;
          // this.getCommonColumns();
          // this.getData();
          break;
        case "/report/stock_summary":
          this.customizationId = "5f76c6371971b629b3bee3a0";
          this.url_17 = true;
          // this.getCommonColumns();
          // this.getData();
          break;
        case "/report/sales_order_register":
          this.customizationId = "5f647766f5dbc70cffe157e2";
          this.url_18 = true;
          // this.getCommonColumns();
          // this.getData();
          break;
        case "/report/purchase_order_register":
          this.customizationId = "5f9abb0e876e7fb2231cdba6";
          this.url_19 = true;
          // this.getCommonColumns();
          // this.getData();
          break;
        case "/report/bills_payable":
          this.customizationId = "5fb76936a5018ae74b2bb6dd";
          this.url_20 = true;
          // this.getCommonColumns();
          // this.getData();
          break;

        case "/report/bills_receivable":
          this.customizationId = "5fb7adc5a5018ae74b2bcee0";
          this.url_21 = true;
          // this.getCommonColumns();
          // this.getData();
          break;
        case "/report/aeging_report":
          this.customizationId = "5fbb4557a5018ae74b2c6e99";
          this.groupColumns = {
            range: ["0-30", "31-60", "61-90", "91-180", ">180 days"],
          };
          this.url_22 = true;
          // this.getCommonColumns();
          // this.getData();
          break;
        case "/report/hsn_report":
          this.customizationId = "5fc4e47973e3ea61c7f0ff54";

          this.url_23 = true;
          // this.getCommonColumns();
          // this.getData();
          break;
      }

      this.getCoaHead(this.in_condition);
      this.getDisplayColumns();

      this.sort = { updated_at: -1 };
    });

    // this.getBookList();

    this.getReportCustomizationById();
  }
  getTransactionTypes() {
    return [
      { id: 1, name: "Bill" },
      { id: 11, name: "INVOICE" },
      { id: 21, name: "PAYMENT" },
      { id: 31, name: "JOURNAL" },
      { id: 41, name: "CONTRA" },
      { id: 51, name: "EXPENSE" },
      { id: 61, name: "SALES ADVANCE" },
      { id: 71, name: "PURCHASE ADVANCE" },
      { id: 81, name: "CREDIT NOTE" },
      { id: 91, name: "DEBIT NOTE" },
      { id: 101, name: "STOCK JOURNAL" },
      { id: 111, name: "RECEIPTS" },
    ];
  }
  getBookList() {
    let in_condition: any = [];

    if (this.url_1 == true) {
      // this.filters.push({
      //   column: "account_head.parent_id",
      //   type: "in",
      //   value: [1031],
      // });
      // this.filters.push({
      //   column: "payment_method",
      //   type: "eq",
      //   value: 1031,
      // });
    } else if (this.url_2 == true) {
      // this.filters.push({
      //   column: "account_head.parent_id",
      //   type: "in",
      //   value: [1030],
      // });
      // this.filters.push({
      //   column: "payment_method",
      //   type: "eq",
      //   value: 1030,
      // });
    } else if (this.url_3 == true) {
      let partynameFilterAvail = {};
      in_condition = [ConstantService.coa_heads.Accounts_Payable];
      this.filters.push({
        column: "tx_type_id",
        type: "ne",
        value: 11,
      });
      // this.filters.push({
      //   column: "payment_method",
      //   type: "gte",
      //   value: 1029,
      // });

      partynameFilterAvail = (obj) => obj.column === "party_name";
      this.checkFilter = this.filters.some(partynameFilterAvail);
    } else if (this.url_4 == true) {
      // let partynameFilterAvail = {};
      // in_condition = [ConstantService.coa_heads.Accounts_Payable];
      // this.filters.push({
      //   column: "debit",
      //   type: "gte",
      //   value: 0,
      // });
      // this.filters.push({
      //   column: "credit",
      //   type: "eq",
      //   value: 0,
      // });
      // this.filters.push({
      //   column: "tx_type_id",
      //   type: "eq",
      //   value: 11,
      // });
      // this.filters.push({
      //   column: "payment_method",
      //   type: "exists",
      //   value: false,
      // });
    } else if (this.url_5 == true) {
      // let partynameFilterAvail = {};
      // in_condition = [ConstantService.coa_heads.Accounts_Payable];
      // this.filters.push({
      //   column: "credit",
      //   type: "gte",
      //   value: 0,
      // });
      // this.filters.push({
      //   column: "debit",
      //   type: "eq",
      //   value: 0,
      // });
      // this.filters.push({
      //   column: "tx_type_id",
      //   type: "eq",
      //   value: 1,
      // });
      // this.filters.push({
      //   column: "payment_method",
      //   type: "exists",
      //   value: false,
      // });
    } else if (this.url_6 == true) {
      let partynameFilterAvail = {};
      in_condition = [ConstantService.coa_heads.Accounts_Receivable];

      this.filters.push({
        column: "tx_type_id",
        type: "eq",
        value: 11,
      });
      this.filters.push({
        column: "payment_method",
        type: "exists",
        value: false,
      });
      this.filters.push({
        column: "debit",
        type: "ne",
        value: 0,
      });
      // this.filters.push({
      //   column: "payment_method",
      //   type: "gte",
      //   value: 1029,
      // });

      partynameFilterAvail = (obj) => obj.column === "party_name";
      this.checkFilter = this.filters.some(partynameFilterAvail);
    } else if (this.url_7 == true) {
      let partynameFilterAvail = {};
      in_condition = [ConstantService.coa_heads.Accounts_Payable];

      this.filters.push({
        column: "tx_type_id",
        type: "eq",
        value: 11,
      });
      this.filters.push({
        column: "payment_method",
        type: "exists",
        value: false,
      });
      this.filters.push({
        column: "debit",
        type: "ne",
        value: 0,
      });
      // this.filters.push({
      //   column: "payment_method",
      //   type: "gte",
      //   value: 1029,
      // });

      // partynameFilterAvail = (obj) => obj.column === "party_name";
      // this.checkFilter = this.filters.some(partynameFilterAvail);
      // console.log(this.checkFilter);
    } else if (this.url_8 == true) {
      this.filters.push({
        column: "party_name",
        type: "in",
        value: this.company_id,
      });
    } else if (this.url_9 == true) {
    } else if (this.url_10 == true) {
      // this.getStocks();
    }
    this.filters.push({
      column: "company_id",
      type: "in",
      value: this.company_id,
    });
    if (this.branch_id != "") {
      this.filters.push({
        column: "branch_id",
        type: "in",
        value: this.branch_id,
      });
    }

    this.account_head_lists = [];

    if (!this.url_18) {
      let res1 = this.paymenttransactionsservice.getAccountBooksReport(
        this.filters,
        this.customized_query,
        this.columns,
        this.preSummary,
        this.postSummary,
        this.model,

        this.sort,
        this.pagination
      );
    } else if (this.url_18) {
      let res1 = this.paymenttransactionsservice.getSalesorderReport(
        this.filters,
        this.customized_query,
        this.columns,
        this.sort,
        this.pagination
      );
    }

    let res2 = this.mastersService.getCoaHead(this.company_id, "", {
      in: in_condition,
      is_parent: true,
    });
    if (this.url_3 || this.url_6) {
      if (this.checkFilter) {
        this.getData();
      } else {
      }
    } else if (this.url_10) {
      this.getStocks();
    } else {
      this.getData();
    }
  }

  getStockItemReport() {
    this.tableColumns = [
      {
        prop: "tx_date",
        name: "Date",
        sortable: "label",
        dataType: "date",
        filterType: "dateRange",
        pipe: new DateOnlyPipe("en-US"),
        //  // filterData: this.getLabels(),
        filterLabel: "name",
        filterId: "id",
        filter: true,
        flexGrow: 1,
      },
      {
        prop: "product_info.name",
        name: "Particulars",

        dataType: "text",
        filterType: "text",
        // filterData: this.party_array,
        filterLabel: "name",
        filterId: "id",
        filter: true,
      },
      // {
      //   prop: "tx_ref_id.0",
      //   name: "Voucher #",
      //   dataType: "text",
      //   filterType: "text",
      //     filterData: this.getTransactionTypes(),
      //     pipe: new TransactionOnlyPipe(),
      //   filterLabel: "name",
      //    filterId: "id",
      //    filter: true,
      //   flexGrow: true,
      // },
      {
        prop: "in_qty",
        name: "Inward Quantity",
        //sortable: "label",
        dataType: "text",
        filterType: "text",
        //pipe: new DateOnlyPipe("en-US"),
        //  // filterData: this.getLabels(),
        filterLabel: "name",
        filterId: "id",
        filter: true,
        flexGrow: 1,
      },
      {
        prop: "out_qty",
        name: "Outward Quantity",
        //sortable: "label",
        dataType: "text",
        filterType: "text",
        //pipe: new DateOnlyPipe("en-US"),
        //  // filterData: this.getLabels(),
        filterLabel: "name",
        filterId: "id",
        filter: true,
        flexGrow: 1,
      },
    ];
  }

  getVendorLedgerColumns() {
    this.tableColumns = [
      {
        prop: "tx_date",
        name: "Date",
        sortable: "label",
        dataType: "date",
        filterType: "dateRange",
        pipe: new DateOnlyPipe("en-US"),
        //  // filterData: this.getLabels(),
        filterLabel: "name",
        filterId: "id",
        filter: true,
      },
      {
        prop: "credit",
        name: "",
        sortable: "label",
        pipe: new TobyPipe(),
      },
      {
        prop: "party_name",
        name: "Party Name",
        dataType: "text",
        filterType: "typeSelect",
        filterData: this.vendorList,
        getFilterDataByFunction: this.getVendorsData,
        filterLabel: "vendor_name",
        filterId: "_id",
        filter: true,
      },
      {
        prop: "account_head.name",
        name: "Particulars",

        dataType: "text",
        filterType: "typeSelect",
        // filterData: this.party_array,
        filterLabel: "name",
        filterId: "id",
        // filter: true,
      },

      {
        prop: "credit",
        name: "Voucher Type",
        pipe: new VouchertypePipe(),
      },

      {
        prop: "voucher_number",
        name: "Voucher No",
        dataType: "number",
        filterType: "typeSelect",
        //  filterData: this.getTransactionTypes(),
        //  pipe: new TransactionOnlyPipe(),
        filterLabel: "name",
        filterId: "id",
        // filter: true,
      },
      {
        prop: "debit",
        name: "Debit",
        dataType: "number",
        filterType: "number",
        filterLabel: "name",

        filter: true,
      },
      {
        prop: "credit",
        name: "Credit",
        dataType: "number",
        filterType: "text",
        filterLabel: "name",
        filterId: "id",
        //  pipe: new createLedgerPipe(),

        filter: true,
      },
    ];
  }

  getSalesPurchaseReport() {
    this.tableColumns = [
      {
        prop: "tx_date",
        name: "Date",
        sortable: "label",
        dataType: "date",
        filterType: "dateRange",
        pipe: new DateOnlyPipe("en-US"),
        //  // filterData: this.getLabels(),
        filterLabel: "name",
        filterId: "id",
        filter: true,
        flexGrow: 1,
      },
      {
        prop: "party_name",
        name: "Particulars",

        dataType: "text",
        filterType: "text",
        // filterData: this.party_array,
        filterLabel: "name",
        filterId: "id",
        filter: true,
        flexGrow: 4,
        cellClass: "align-left",
      },

      {
        prop: "credit",
        name: "Voucher Type",
        pipe: new VouchertypePipe(),
        flexGrow: 2,
      },

      {
        prop: "tx_ref_id.0",
        name: "Voucher #",
        dataType: "number",
        filterType: "typeSelect",
        //  filterData: this.getTransactionTypes(),
        //  pipe: new TransactionOnlyPipe(),
        filterLabel: "name",
        filterId: "id",
        // filter: true,
        flexGrow: 1.8,
      },
      {
        prop: "debit",
        name: "Debit",
        dataType: "number",
        filterType: "number",
        filterLabel: "name",
        // pipe: new CurrencyPipePipe(),
        filter: true,
        flexGrow: 1,
        cellClass: "align-right",
      },
      {
        prop: "credit",
        name: "Credit",
        dataType: "number",
        filterType: "text",
        filterLabel: "name",
        filterId: "id",
        // pipe: CurrencyPipe,
        //  pipe: new createLedgerPipe(),
        filter: true,
        flexGrow: 1,
        cellClass: "align-right",
      },
    ];
  }

  getCommonColumns() {
    this.tableColumns = [
      {
        prop: "tx_date",
        name: "Date",
        sortable: "label",
        dataType: "date",
        filterType: "dateRange",
        pipe: new DateOnlyPipe("en-US"),
        //  // filterData: this.getLabels(),
        filterLabel: "name",
        filterId: "id",
        filter: true,
        flexGrow: 1,
      },
      // {
      //   prop: "credit",
      //   name: "",
      //   sortable: "label",
      //   pipe: new TobyPipe(),
      //   flexGrow: 0.4,
      //   cellClass: "align-right",
      // },
      {
        prop: "party_name",
        name: "Party Name",

        dataType: "text",
        filterType: "typeSelect",
        filterData: this.vendorList,
        getFilterDataByFunction: this.getVendorsData,
        // filterData: this.party_array,
        filterLabel: "vendor_name",
        filterId: "_id",
        filter: true,
        // flexGrow: 4,
        // cellClass: "align-left",
      },

      {
        prop: "credit",
        name: "Voucher Type",
        pipe: new VouchertypePipe(),
        flexGrow: 2,
      },

      // {
      //   prop: "voucher_number",
      //   name: "Voucher #",
      //   dataType: "number",
      //   filterType: "typeSelect",
      //   //  filterData: this.getTransactionTypes(),
      //   //  pipe: new TransactionOnlyPipe(),
      //   filterLabel: "name",
      //
      //   // filter: true,
      //   flexGrow: 1.8,
      // },
      {
        prop: "debit",
        name: "Debit",
        dataType: "number",
        filterType: "number",
        filterLabel: "name",
        // pipe: new CurrencyPipePipe(),
        filter: true,
        flexGrow: 1,
        cellClass: "align-right",
      },
      {
        prop: "credit",
        name: "Credit",
        dataType: "number",
        filterType: "number",
        filterLabel: "name",
        filterId: "id",
        // pipe: CurrencyPipe,
        //  pipe: new createLedgerPipe(),
        filter: true,
        flexGrow: 1,
        cellClass: "align-right",
      },
    ];
  }

  getData() {
    this.paymenttransactionsservice
      .getAccountBooksReport(
        this.filters,
        this.customized_query,
        this.columns,
        this.preSummary,
        this.postSummary,
        this.model,

        this.sort,
        this.pagination
      )
      .subscribe((result) => {
        this.cashbookList = {};
        this.cashbookList = result;
        console.log("=======result===>>>111===>>>", result);
        // if (result["totals"]["pre"] != undefined) {
        //   this.openingBalance = result["totals"]["pre"][0];
        //   this.closingBalance = result["totals"]["post"][0];
        // }
      });
  }
  getCoaHead(in_condition) {
    // let in_condition = [];
    let filters = {};
    if (in_condition.length > 0) filters["account_head.name"] = in_condition;
    filters["company_id"] = this.authenticationService.getDefaultCompanyId();
    in_condition = [ConstantService.coa_heads.Accounts_Payable];
    this.mastersService
      .getCoaHead(this.company_id, "", {
        in: in_condition,
        is_parent: true,
      })
      .subscribe((data) => {
        this.coa_ledger = { prop: "account_head.name", data: ["response"] };
      });
  }
  getStocks() {
    this.stocktransactionsService
      .getAccountBooksReport(
        this.filters,
        this.columns,
        this.sort,
        this.pagination
      )
      .subscribe((data) => {
        this.cashbookList = data;
      });
  }

  getDisplayColumns() {
    this.columns = {};
    this.tableColumns.forEach((column) => {
      if (column.show !== undefined || column.show !== false) {
        this.columns[column.prop] = 1;
      }
    });
  }
  pageChange($data: any) {
    this.pagination = $data;
    this.getReportData();
  }
  applySort(sorting: any) {
    console.log('rfgergdg', sorting);

    this.sort = sorting;
    this.getReportData();
  }
  search(filterData: any) {
    let replaceParams = {};
    replaceParams[
      "~m~company_id"
    ] = this.authenticationService.getDefaultCompanyId();
    this.filters = [];
    this.filters = this.defaultFilters.concat(filterData);
    this.filters.forEach((filter, key) => {
      if (typeof filter.value == "string") {
        if (filter.value.startsWith("~m~")) {
          this.filters[key]["value"] = replaceParams[filter.value];
        }
      }
    });
    this.getReportData();
    // this.getBookList();
  }

  getParty() {
    this.party_array = [];

    this.coa_ledger.forEach((element) => {
      let party_obj: {};

      party_obj = {
        id: element["flattened_data"]["id"],
        name: element["flattened_data"]["name"],
      };
      this.party_array.push(party_obj);
    });
    return this.party_array;
  }

  getVendorsData = (searchString) => {
    this.vendorList = [];
    let filters = {};
    if (searchString.length > 0) filters["vendor_name"] = searchString;
    filters["company_id"] = this.authenticationService.getDefaultCompanyId();
    this.vendorService.getVendorData(filters).subscribe((result) => {
      this.dynamicFilterData = { prop: "party_id", data: result["response"] };
    });
  };

  inlineTemplateColumns(columns: any) {
    columns.forEach((element, index) => {
      if (element["dataType"] == "template") {
        // const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.getComponentForCardType(this.data.component));
        // const viewContainerRef = this.container;
        columns[index]["template"] = element["template"].replace('"', "`");
      }
    });
    return columns;
  }
  getReportCustomizationById() {
    this.reportsService
      .getCustomizationDetails(this.customizationId)
      .then((result) => {
        this.tableColumns = this.inlineTemplateColumns(
          result["response"]["data"]["columns"]
        );
        this.reportType =
          result["response"]["data"]["report_type"] != undefined
            ? result["response"]["data"]["report_type"]
            : 1;
        if (this.reportType == 2) {
          this.groupColumns = result["response"]["data"]["group_columns"];
          this.rowColumns = result["response"]["data"]["row_columns"];
          this.summaryColumns = result["response"]["data"]["summary_columns"];
        }
        this.defaultFilters = result["response"]["data"]["filters"];
        this.filters = this.defaultFilters;
        if (result["response"]["data"]["preSummary"] != undefined) {
          this.preSummary = result["response"]["data"]["preSummary"];
        }
        if (result["response"]["data"]["model"] != undefined) {
          this.model = result["response"]["data"]["model"];
        }

        if (result["response"]["data"]["postSummary"] != undefined) {
          this.postSummary = result["response"]["data"]["postSummary"];
        }
        if (result["response"]["data"]["customized_query"] != undefined) {
          this.customized_query =
            result["response"]["data"]["customized_query"];
        }
        this.defaultSort = result["response"]["data"]["default_sort"];
        this.apiURL = result["response"]["data"]["apiurl"];

        // this.getReportData();
        this.initiateFilterTypeaheadData();
      });
  }

  getReportData(exportType = '') {
    let replaceParams = {};
    replaceParams[
      "~m~company_id"
    ] = this.authenticationService.getDefaultCompanyId();

    this.filters.forEach((filter, key) => {
      if (typeof filter.value == "string") {
        if (filter.value.startsWith("~m~")) {
          this.filters[key]["value"] = replaceParams[filter.value];
        }
      }
      if (filter.type != "daterange") {
        if (Array.isArray(filter.value) && filter.type == "between") {
          //Condition only for dates
          this.filters[key]["value"][0] = this.stringToDate(filter.value[0]);
          this.filters[key]["value"][1] = this.stringToDate(filter.value[1]);
        }
      } else {
        for (var i in this.filters) {
          if (this.filters[i]["type"] == "between") {
            this.filters.splice(i, 1);
            break;
          }
        }
      }
    });
    this.reportsService
      .getData(
        this.customizationId,
        this.apiURL,
        this.filters,
        this.customized_query,
        this.tableColumns,
        this.preSummary,
        this.postSummary,
        this.model,
        this.sort,
        this.pagination,
        exportType,
      )
      .then((result) => {
        if (exportType == '') {
          this.cashbookList = {};
          this.cashbookList = result;
        } else {
          window.open(ConstantService.IMG_BASE_URL + result['filename']);
        }

        // this.cashbookList.forEach(row => {

        // });
        // if (result["opening_balance"] != undefined) {
        //   this.openingBalance = result["opening_balance"][0];
        //   this.closingBalance = result["closing_balance"][0];
        // }
        //  this.cashbookList["grandTotal"] = [];
        //  this.cashbookList["totals"] = result["count"];

        // if (typeof result["totals"]["pre"] != "undefined") {
        //   console.log("=======result===>>>111===>>>");
        //   this.openingBalance = result["totals"]["pre"][0];
        //   console.log("=======result===>>>111===>>>", this.openingBalance);

        //   this.closingBalance = result["totals"]["post"][0];
        // }
      });
  }

  stringToDate(dateString) {
    switch (dateString) {
      case "startOfMonth":
        // dateString = startOfMonth(new Date());
        dateString = "2020-04-01T18:29:59.999Z";
        break;
      case "endOfMonth":
        // dateString = endOfMonth(new Date());
        dateString = "2021-03-31T18:29:59.999Z";
        break;
    }
    return dateString;
  }
  getFilterTypeaheadData(data: any = {}) {
    console.log("============333=========");

    let replaceParams = {};
    replaceParams[
      "~m~company_id"
    ] = this.authenticationService.getDefaultCompanyId();
    replaceParams[
      "~m~group_id"
    ] = this.authenticationService.getDefaultCompanyId();
    let currentFilter = data.filter;
    let filterCondition: any = {};
    if (currentFilter.filterAPI != undefined) {
      Object.keys(currentFilter.filterAPI.params).forEach((paramKey, key) => {
        if (currentFilter.filterAPI.params[paramKey].startsWith("~m~")) {
          console.log("paramKey=>>", currentFilter.filterAPI.params[paramKey]);
          filterCondition[paramKey] =
            replaceParams[currentFilter.filterAPI.params[paramKey]];
        }
        if (paramKey.startsWith("~m~")) {
          filterCondition[currentFilter.filterAPI.params[paramKey]] =
            data.searchString;
        }
      });
    }
    this.reportsService
      .getFilterTypeaheadData(currentFilter.filterAPI.url, filterCondition)
      .subscribe((result) => {
        this.dynamicFilterData = {};
        this.dynamicFilterData["data"] = result["response"];
        this.dynamicFilterData["prop"] = currentFilter.prop;
      });
  }

  initiateFilterTypeaheadData() {
    console.log("=====>>>");
    this.tableColumns.forEach((filter) => {
      if (
        (filter.filterType == "typeSelect" ||
          filter.filterType == "typeMultiSelect") &&
        filter.filter == true
      )
        this.getFilterTypeaheadData({ filter: filter });
    });
  }
  exportData() {
    console.log("11111", this.customizationId);
    this.reportsService.getReports(this.customizationId).subscribe((result) => {
      this.reportList = result["data"];
    });
  }
}
