import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import swal from 'sweetalert2';
import { TestcollectionService } from '../../_services/testcollection.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
@Component({
  selector: 'app-testcollection',
  templateUrl: './testcollection.component.html',
  styleUrls: ['./testcollection.component.scss']
})
export class TestcollectionComponent implements OnInit {
  filters: any = {};
  testcollection: any = {};
  testcollectionList: any = [];
  testcollectionListColumns: any = [];
  page: any = { offset: 0, limit: 10 };
  loadingIndicator = true;
  reorderable = true;
  @ViewChild('actionTmpl', { static: true }) actionTmpl: TemplateRef<any>;
constructor(
    private testcollectionservice: TestcollectionService

  ) { }


  ngOnInit() {
    this.getTestcollectionsList(this.page);
  }
  getTestcollectionsList(page_info: any) {
    if (page_info != null) {
      this.page = page_info;

    }
    this.testcollectionservice.getTestcollections(this.testcollection, {}, {}, this.page).subscribe(result => {
      this.testcollectionListColumns = [
        { name: 'Customer Name', prop: 'customer' },
        { name: 'Email', prop: 'email' },
        { name: 'First Name Of Contact', prop: 'first_name' },
        { name: 'Last Name Of Contact', prop: 'last_name' },
        { name: 'Phone', prop: 'phone' },

        { name: 'Action', cellTemplate: this.actionTmpl, prop: 'muid' }
      ];
      //this.testcollectionList = result.data;
      //this.page.count = result.count;
    });
  }
  clearTestcollectionFilters() {
    this.testcollection = {};
    this.getTestcollectionsList({});
  }
  deleteTestcollection(muid) {
    swal({
      title: 'Are you sure want to delete?',
      text: 'You won\'t be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result) {
        this.testcollectionservice.deleteTestcollection(muid).subscribe(result => {
          if (result['status_code'] === 1) {
            swal('Deleted!', 'Testcollection has been deleted.', 'success');
            this.getTestcollectionsList({});
          }
        });
      }
    });
  }
}
