<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header d-flex align-items-center">
          <h3 class="table-title">Receipt</h3>
        </div>
        <form novalidate #addReceiptPaymentForm="ngForm" (ngSubmit)="addUpdateReceipt()">
          <div class="card-body row">

            <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
              <label class="">Receipt Method</label>
              <div class="">
                <ng-select [items]="payment_methodList" (change)="getPaymentInChange()"
                  [typeahead]="paymentMethodListTypeahead" name="payment_method" #payment_method="ngModel"
                  [(ngModel)]="receipt.payment_method">
                  <ng-template ng-label-tmp let-item="item">
                    {{item.name}}
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    {{item.name}}
                  </ng-template>
                </ng-select>
                <app-validation-errors [mformField]="payment_method" label="Payment Method">
                </app-validation-errors>
              </div>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 required">
              <label class="">Receipt Account</label>
              <div class="">
                <ng-select [items]="payment_accountList" [typeahead]="accountHeadListTypeahead" name="payment_account"
                  id="payment_account" required #payment_account="ngModel" bindLabel="name"
                  [(ngModel)]="receipt.payment_account">
                  <ng-template ng-label-tmp let-item="item">
                    <div [ngOptionHighlight]="search">
                      {{item?.flattened_data?.name}}
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{item?.flattened_data?.name}}
                    </div>
                  </ng-template>

                </ng-select>
                <app-validation-errors [mformField]="payment_account" label="Payment Account">
                </app-validation-errors>
              </div>
            </div>

            <!-- <div class="form-group  required col-12 col-sm-6 col-md-3 col-xl-3">
              <label class="" for="voucher_type">Voucher Type</label>
              <app-voucher-type (onSelectVoucher)="getVoucherType($event)" [voucherCategoryCode]=3></app-voucher-type>
            </div> -->

            <div class="form-group  col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="ref_id">Ref Id</label>
              <div class="">
                <input type="text" class="form-control" name="ref_id" placeholder="Reference ID"
                  [(ngModel)]="receipt.ref_id" />
              </div>

            </div>
            <div class="form-group  col-6">
              <label for="voucher_number">Voucher No</label>
              <div class="input-group">
                <input type="text" class="form-control" name="voucher_number" placeholder="voucher Number"
                  [(ngModel)]="receipt.voucher_number" />
              </div>

            </div>
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 required">
              <label class="col-form-label ">Receipt Date</label>
              <div class="">
                <div class="input-group">
                  <input angular-mydatepicker placeholder="Payment Date" required
                    (click)="payment_date_dp.toggleCalendar()" #payment_date_dp="angular-mydatepicker"
                    class="form-control" name="payment_date" [(ngModel)]="receipt.payment_date"
                    [options]="payment_dateDpOptions" (dateChanged)="onpayment_dateDateChanged($event)">

                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" *ngIf="receipt.payment_date"
                      (click)="payment_date_dp.clearDate()">
                      <i class="fa fa-close"></i>
                    </button>
                  </div>
                  <!-- toggle calendar button -->
                  <div class="input-group-append">
                    <button type="button" class="btn btn-secondary" (click)="payment_date_dp.toggleCalendar()">
                      <i class="fa fa-calendar-o"></i>
                    </button>
                  </div>
                </div>
                <app-validation-errors [mformField]="payment_date_dp" label="Payment Date">
                </app-validation-errors>
              </div>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3 required" style="display: none;">
              Customer<div class="form-group col-12 custom-switch col-sm-6 col-md-3 col-xl-3 pl-5">
                <input type="checkbox" value="true" [(ngModel)]="is_payee" name="payee_type"
                  (ngModelChange)="clearAmount()" class="custom-control-input" id="payee_type">
                <label class="custom-control-label" for="payee_type">Receipt</label>
              </div>
            </div>

            <div *ngIf="!is_payee" class="form-group col-12 col-sm-6 col-md-3 col-xl-3 required">
              <label class="col-form-label ">Customer</label>
              <div class="">
                <ng-select [items]="payeeList" [typeahead]="payeeListTypeahead" name="payee" required #payee="ngModel"
                  [(ngModel)]="receipt.payee" id="payee" bindLabel="name">
                  <ng-template ng-label-tmp let-item="item">
                    <div [ngOptionHighlight]="search">
                      {{item?.flattened_data?.name}}
                    </div>
                  </ng-template>
                  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                    <div [ngOptionHighlight]="search">
                      {{item?.flattened_data?.name}}
                    </div>
                  </ng-template>
                  <!-- <ng-template ng-footer-tmp>
                    <button type="button" class="btn btn-primary"><i class="fa fa-plus"></i>Add
                      Payee</button>
                  </ng-template> -->
                </ng-select>
                <app-validation-errors [mformField]="payee" label="Payee">
                </app-validation-errors>
              </div>
            </div>

            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th style="width: 20%;" *ngIf="user_id !=8">Receipt
                  </th>
                 
                 
                  <th *ngIf="!is_payee">Tax
                  </th>
                  <th>Amount
                  </th>
                   <th style="width: 20%;" *ngIf="user_id ==8">Notes
                  </th>
                  <th *ngIf="!is_payee">Total Tax
                  </th>

                  <!-- <th>Total Amount
                  </th> -->

                  <th>Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let receipt_item of receipt.receipt_items; let i=index;">
                  <td  *ngIf="user_id !=8">
                    <ng-select  [items]="receiptList" [typeahead]="receiptListTypeahead" name="category{{i}}" required
                      #receipt="ngModel" [(ngModel)]="receipt_item.category" id="category{{i}}" (change)="receipt_item.tax=0;
                      getHsnData(i)" bindLabel="name">
                      <ng-template ng-label-tmp let-item="item">
                        <div [ngOptionHighlight]="search">
                          {{item?.flattened_data?.name}}
                        </div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <div [ngOptionHighlight]="search">
                          {{item?.flattened_data?.name}}
                        </div>
                      </ng-template>
                    </ng-select>
                    <app-validation-errors  *ngIf="user_id !=8" [mformField]="receipt" label="Account Head">
                    </app-validation-errors>
                    <textarea  maxlength="30"  *ngIf="user_id !=8" type="textarea" class="form-control" name="description{{i}}" #description="ngModel"
                      id="description{{i}}" [(ngModel)]="receipt_item.description" placeholder="Notes"></textarea>
                    <app-validation-errors [mformField]="description" label="Description">
                    </app-validation-errors>
                  </td>

                  <td *ngIf="!is_payee">
                    <input type="number" readonly class="form-control" name="tax{{i}}" #tax="ngModel" id="tax{{i}}"
                      [(ngModel)]="receipt_item.tax" placeholder="">
                    <app-validation-errors [mformField]="tax" label="Tax">
                    </app-validation-errors>
                  </td>
                  <td>
                    <input type="number" required [min]="0" class="form-control" name="amount{{i}}" #amount="ngModel"
                      id="amount{{i}}" [(ngModel)]="receipt_item.amount"
                      (ngModelChange)="calculateTotalAmount($event,i)" placeholder="">
                    <app-validation-errors [mformField]="amount" label="Amount">
                    </app-validation-errors>
                  </td>
                  <td *ngIf="user_id ==8">
                    <textarea maxlength="30"  required  type="textarea" class="form-control" name="description{{i}}" #description="ngModel"
                    id="description{{i}}" [(ngModel)]="receipt_item.description" placeholder=""></textarea>
                  </td>
                  <td *ngIf="!is_payee">
                    <input type="number" class="form-control" readonly name="total_tax{{i}}" #tax_amount="ngModel"
                      id="tax_amount{{i}}" [(ngModel)]="receipt_item.tax_amount" placeholder="Total tax">
                    <app-validation-errors [mformField]="tax_amount" label="Tax Amount">
                    </app-validation-errors>
                  </td>
                  <!-- <td>
                    <input type="number" [min]="0" readonly class="form-control" name="total_amount{{i}}"
                      #total_amount="ngModel" id="total_amount{{i}}" [(ngModel)]="receipt_item.total_amount"
                      placeholder="">
                    <app-validation-errors [mformField]="total_amount" label="Total Amount">
                    </app-validation-errors>
                  </td> -->
                  <!--   -->
                  <td><button type="button" class="btn btn-primary" (click)="addNewItem(i)"><i class="fa fa-plus"></i>
                    </button>
                    <button *ngIf="i >0" type="button" class="btn btn-danger" (click)="deleteItem(i)"><i
                        class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer" *ngIf="receipt_formtype !=2">
            <button type="submit" [disabled]="!addReceiptPaymentForm.valid" class="btn btn-primary">Receipt</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>