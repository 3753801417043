<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <form novalidate #searchUsersForm="ngForm" (ngSubmit)="getUsersList({ offset: 0, pageSize: 10 })">
        <div class="card">
          <div class = "card-body">
          <div class="card-header d-flex align-items-center">
            <h3 class="table-title"><i class="fa fa-address-card"></i> Users </h3>
          </div>
          <div class="p-sm-2">
            <button type="button" class="btn btn-primary float-right" routerLink="/user/add">
              <i class="icon icon-add"></i>Add Users
            </button>
          </div>
          <div class="card-body row">
            <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label>User Name</label>
              <input type="text" name="username" class="form-control" placeholder="User Name"
                [(ngModel)]="filters.username">
            </div> -->
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="username">User Name </label>
              <ng-select (clear)="usersList=[];" [items]="userNameList" [typeahead]="userListTypeahead"
                name="username" bindLabel="username" bindValue="username" [(ngModel)]="filters.username"
                placeholder="Select User Name">
                <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div [ngOptionHighlight]="search">
                    {{ item.username }}
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label>Full Name</label>
              <input type="text" name="full_name" class="form-control" placeholder="Full Name"
                [(ngModel)]="filters.full_name">
            </div> -->
            <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label>Email</label>
              <input type="text" name="email" class="form-control" placeholder="Email" [(ngModel)]="filters.email">
            </div> -->
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label for="email">Email </label>
              <ng-select (clear)="usersList=[];" [items]="emailList" [typeahead]="emailListTypeahead"
                name="email" bindLabel="email" bindValue="email" [(ngModel)]="filters.email"
                placeholder="Select Email">
                <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div [ngOptionHighlight]="search">
                    {{ item.email }}
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label>Phone Number</label>
              <input type="number" name="phone" class="form-control" placeholder="Phone" [(ngModel)]="filters.phone">
            </div>
            <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label>User ID</label>
              <input type="text" name="user_id" class="form-control" placeholder="User ID"
                [(ngModel)]="filters.user_id">
            </div> -->
            <div *ngIf="company_id==''" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label>Company</label>
              <ng-select [items]="companies_list" [multiple]="true" (change)="setBranch()"
                [typeahead]="companiesListTypeahead" name="company" bindLabel="company" bindValue="_id"
                [(ngModel)]="filters.companies" placeholder="Select Company">
                <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div [ngOptionHighlight]="search">
                    {{ item?.company }}
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <div *ngIf="is_branch==true" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label>Branch</label>
              <ng-select [items]="branch_lists" [multiple]="true" name="branch" bindLabel="name" bindValue="_id"
                [(ngModel)]="filters.branches" placeholder="Select Branch">
                <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div [ngOptionHighlight]="search">
                    {{ item?.name }}
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label>Role</label>
              <ng-select [items]="user_roles" required #user_role="ngModel" name="user_role" bindLabel="name"
                bindValue="role_id" [(ngModel)]="filters.role_id" placeholder="Select Role">
                <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div [ngOptionHighlight]="search">
                    {{ item.name }}
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label>Status</label>
              <ng-select [items]="user_status" name="user_status" bindLabel="name" bindValue="status_id"
                [(ngModel)]="filters.status" placeholder="Status">
                <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  <div [ngOptionHighlight]="search">
                    {{ item.name }}
                  </div>
                </ng-template>
              </ng-select>
            </div>

            <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
              <label>Reporting To</label>
              <input type="text" name="reporting_to" class="form-control" placeholder="Reporting To"
                [(ngModel)]="filters.reporting_to">
            </div> -->

          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-primary mr-2">
              <i class="fa fa-search"></i> Search
            </button>
            <button type="button" (click)="clearUsersFilters();" class="btn btn-danger">
              <i class="fa fa-close"></i> Clear
            </button>

          </div>
        </div>
      </div>
      </form>


      <div class="card p-sm-0">
        <div class = "card-body">

        <ngx-datatable [rows]="usersList" [loadingIndicator]="loadingIndicator" [externalPaging]="true"
          [columnMode]="'force'" [limit]="page.pageSize" [count]="page.count" [offset]="page.offset"
          (page)="getUsersList($event)" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'"
          class="material striped">

          <ngx-datatable-column *ngFor="let col of usersListColumns" [flexGrow]=" col.flexGrow" [name]="col.name"
            [sortable]="col.sortable" [cellClass]="col.css_class" [prop]="col.prop">
            <ng-template ngx-datatable-header-template let-sort="sortFn">
              <span (click)="sort()">{{ col.name }}</span>
            </ng-template>
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
              <div [ngSwitch]="col.type">
                <span *ngSwitchCase="'currency'"> {{row[col.prop]|currency:currency}}</span>
                <span *ngSwitchCase="'percentage'">{{row[col.prop]}}%</span>
                <span *ngSwitchCase="'action'">

                  <div class="btn-group mr-3">
                    <a class="btn btn-primary" routerLink="/user/edit/{{row._id}}" name="edit{{i}}" type="button"
                      rel="tooltip">Edit</a>
                    <div class="btn-group" ngbDropdown placement="bottom-right" container="body" role="group">
                      <a class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></a>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <a class="dropdown-item" routerLink="/user/reset_password/{{row._id}}" type="button"
                          rel="tooltip">Change Password</a>
                        <a class="dropdown-item" routerLink="/user/edit/{{row._id}}" type="button"
                          rel="tooltip">Edit</a>
                        <a class="dropdown-item " routerLink="/user/view/{{row._id}}">
                          <span class="text-blue">
                            <i class="icon-fa-eye"></i> View
                          </span>
                        </a>
                        <a class="dropdown-item " *ngIf="row.role_id > 1 && row.status_id=='1'"
                          (click)="deleteUser(value)">
                          <span class="text-red">
                            <i class="icon-trash-can3"></i> Delete
                          </span>
                        </a>
                        <a class="dropdown-item " *ngIf="row.role_id > 1 && row.status_id=='1'"
                          (click)="suspendUser(value)">
                          <span class="text-warning">
                            <i class="icon-trash-can3"></i>Suspend
                          </span>
                        </a>
                        <a class="dropdown-item " *ngIf="row.role_id > 1 && row.status_id=='-1'"
                          (click)="activateUser(value)">
                          <span class="text-green">
                            <i class="icon-trash-can3"></i>Active
                          </span>
                        </a>

                      </div>
                    </div>
                  </div>


                </span>
                <span *ngSwitchCase="'object'"> {{ row[col.prop][col.field_name] }}</span>
                <span *ngSwitchCase="'date'"> {{ row[col.prop]|date }}</span>

                <span *ngSwitchCase="'status'">
                  <span *ngIf="row[col.prop]=='active'" class="badge badge-success">{{row[col.prop]}}</span>
                  <span *ngIf="row[col.prop]=='suspended'" class="badge badge-warning">{{row[col.prop]}}</span>
                  <span *ngIf="row[col.prop]=='deleted'" class="badge badge-danger">{{row[col.prop]}}</span>
                  <!-- <span class="badge badge-success">{{ row[col.prop]}}</span> -->
                </span>
                <span *ngSwitchDefault>
                  {{ row[col.prop] }}
                </span>
              </div>

            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
    </div>

  </div>
</div>

<!-- <ng-template #actionTmpl let-value="value">

  <div class="btn-group mr-3">
    <button class="btn btn-primary" routerLink="/users/edit/{{value}}" type="button" rel="tooltip">Edit</button>
    <div class="btn-group" ngbDropdown placement="bottom-right" role="group">
      <button class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
      <div class="dropdown-menu" ngbDropdownMenu>
        <button class="dropdown-item" routerLink="/users/edit/{{value}}" type="button" rel="tooltip">Edit</button>
        <button class="dropdown-item " (click)="deleteUsers(value)">
          <span class="text-red">
            <i class="icon-trash-can3"></i> Delete
          </span>
        </button>
      </div>
    </div>
  </div>



</ng-template> -->