import { Injectable } from "@angular/core";
import { ApiService } from "../_services/api.service";

@Injectable({
  providedIn: "root"
})
export class MastersService {
  constructor(private http: ApiService) { }

  getCurrencies() {
    return this.http.post(ApiService.geturl("masters", "get_currency"));
  }

  getCountries() {
    return this.http.post(ApiService.geturl("masters", "get_countries"));
  }
  getUnits(filters: any = {},
    columns: any = {},
    sort: any = {},
    page: any = {}) {
    return this.http.post(ApiService.geturl("masters", "get_units"), {
      filters: filters,
      columns: columns,
      sort: sort,
      page: page,
    });
  }
  deleteUnits(id: string) {
    return this.http.post(ApiService.geturl("masters", "delete_units"), {
      id: id,
    });
  }
  getCoa(company_id) {
    return this.http.post(ApiService.geturl("masters", "get_coa"), {
      company_id: company_id
    });
  }

  updateCoa(condition, coa_data) {
    return this.http.post(ApiService.geturl("masters", "update_coa"), {
      condition: condition,
      coa_data: coa_data
    });
  }

  addCoa(coa_data) {
    return this.http.post(ApiService.geturl("masters", "add_coa"), {
      coa_data: coa_data
    });
  }
  addUnits(data) {
    return this.http.post(ApiService.geturl("masters", "add_units"), {
      units_data: data
    });
  }
  getCoaHead(company_id, regex_string, condition) {
    return this.http.post(ApiService.geturl("masters", "get_coa_head"), {
      company_id: company_id,
      regex_string: regex_string,
      condition: condition
    });
  }
  createOpeningBalance(data){
    return this.http.post(ApiService.geturl("masters","add_opening_balance"),{
      opening_balance : data
    });
  }
  getOpeningBalance(company_id){
    return this.http.post(ApiService.geturl("masters","get_opening_balance"),{
      company_id : company_id
    });
  }

}
