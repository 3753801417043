import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import swal from "sweetalert2";
import { GstService } from "../_services/gst.service";
import { InvoiceService } from "../_services/invoice.service";
import { CreditBillService } from "../_services/credit_bill.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { AuthenticationService } from "../_services/authentication.service";
import { ConstantService } from "../_config/constants";
import { resolve } from "url";
import { NgbModalConfig, NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
// import { FileSaverService } from 'ngx-filesaver';
// import { saveAs } from 'file-saver';

@Component({
  selector: "app-gst",
  templateUrl: "./gst.component.html",
  styleUrls: ["./gst.component.scss"],
  providers: [NgbModalConfig, NgbModal]
})
export class GstComponent implements OnInit {
  filters: any = {};
  gst: any = {};
  gstList: any = [];
  gstListColumns: any = [];
  page: any = { offset: 0, limit: 10 };
  loadingIndicator = false;
  reorderable = true;
  @ViewChild("actionTmpl", { static: true }) actionTmpl: TemplateRef<any>;
  company_id: any;
  file_path: any;
  loading: boolean = false;
  id: any;
  gstDateOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  gstAmountPaidDateOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: ConstantService.DateFormat,
  };
  submitted_by: any;
  submitted_date: any;
  amount_paid_date: any;
  amount_paid: any;
  ref_no: any;
  selectedRow: any;
  currency: string;
  constructor(private gstservice: GstService,
    private constantService: ConstantService,
    private invoiceService: InvoiceService,
    private creditnoteService: CreditBillService,
    // private _FileSaverService: FileSaverService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.getFilePath();
    this.company_id = this.authenticationService.getDefaultCompanyId();
    this.currency = this.authenticationService.getCompanyDetails()[
      "base_currency"
    ];
    this.gstListColumns = [
      {

        name: "File Name",
        prop: "file_name",
        sortable: "false",
        flexGrow: 2,
        show: true,
        is_detailed_row: false

      },

      {
        name: "From",
        prop: "from_date",
        sortable: "from_date",
        flexGrow: 2,
        show: true,
        type: "date",
        is_detailed_row: false
      }, {
        name: "To",
        prop: "to_date",
        sortable: "to_date",
        flexGrow: 2,
        show: true,
        type: "date",
        is_detailed_row: false
      },
      {
        name: "Submitted By",
        prop: "submitted_by",
        sortable: "submitted_by",
        flexGrow: 2,
        show: true,
        is_detailed_row: false
      },
      {
        name: "Submitted At",
        prop: "submitted_date",
        sortable: "submitted_date",
        type: "date",
        flexGrow: 2,
        show: true,
        is_detailed_row: false
      },
      {
        prop: "amount_paid",
        name: "Amount Paid",
        sortable: "amount_paid",
        flexGrow: 1,
        show: true,
        is_detailed_row: false,
        type: "currency",
        css_class: "align-right"
      },
      {
        name: "Filed Status",
        prop: "is_filed",
        sortable: "is_filed",
        type: "status",
        flexGrow: 2,
        show: true,
        is_detailed_row: false
      },
      {
        name: "Action",
        type: "action",
        prop: "_id",
        flexGrow: 1,
        is_detailed_row: false,
        sortable: false
      }
    ];
    let today = new Date();
    this.amount_paid_date = {
      isRange: false,
      singleDate: {
        jsDate: today,
      },
    };
    this.submitted_date = {
      isRange: false,
      singleDate: {
        jsDate: today,
      },
    };
    this.getGstsList(this.page);
  }

  async updateGst() {
    this.gst.submitted_by = this.submitted_by;
    this.gst.submitted_date = this.submitted_date;
    this.gst.amount_paid_date = this.amount_paid_date;
    this.gst.amount_paid = this.amount_paid;
    this.gst.ref_no = this.ref_no;
    this.gst.company_id = this.company_id;
    // this.gst.filed_status
    // data['filed_status'] = 2;
    await this.fileGst(this.selectedRow);
    this.gstservice
      .editGst(this.gst, this.selectedRow._id)
      .subscribe((result) => {
        if (result["response"]["status_code"] == 1) {
          this.modalService.dismissAll();

          swal("Success", "GST updated successfully", "success");
          this.getGstsList({});
          this.submitted_by = '';
          this.submitted_date = {};
          this.amount_paid = {};
          this.amount_paid_date = {};
          this.ref_no = '';

        } else {
          swal("Error", "Some error occured", "error");
        }
      });
  }

  getGstsList(page_info: any) {
    if (page_info != null) {
      this.page = page_info;
    }
    this.filters["company_id"] = this.company_id;
    let columns = {};
    this.gstservice.getGsts(this.filters, columns, this.page, {}).subscribe(result => {
      // this.gstListColumns = [
      //   { name: "Action", cellTemplate: this.actionTmpl, prop: "muid" }
      // ];
      this.gstList = result["data"];
      this.page.count = result["count"];
    });
  }
  clearGstFilters() {
    this.filters = {};
    this.getGstsList({});
  }
  deleteGst(muid) {
    swal({
      title: "Are you sure want to delete?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result) {
        this.gstservice.deleteGst(muid).subscribe(result => {
          if (result["status_code"] === 1) {
            swal("Deleted!", "Gst has been deleted.", "success");
            this.getGstsList({});
          }
        });
      }
    });
  }

  getFilePath() {
    this.file_path = ConstantService.IMG_BASE_URL;
  }
  async fileGst(row) {
    let condition = {};
    condition['_id'] = row['_id'];
    condition['company_id'] = this.company_id;

    // swal({
    //   title: "Are you sure want to file this Json?",
    //   text: "You won't be able to revert this!",
    //   type: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: "Yes, Mark As Filed !"
    // }).
    // then(result => {
    // if(result){
    this.gstservice.changeGstFiledStatus(condition).subscribe(response => {
      if (response["ok"] == 1) {
        let data: any = {};
        let invoiceCondition: any = {};
        invoiceCondition['invoice_ids'] = [];
        invoiceCondition['company_id'] = this.company_id;
        invoiceCondition['invoice_ids'] = row['invoice_ids'];

        let creditnoteCondition: any = {};
        creditnoteCondition['creditnote_ids'] = [];
        creditnoteCondition['company_id'] = this.company_id;
        creditnoteCondition['creditnote_ids'] = row['creditnote_ids'];

        data['filed_status'] = 2;
        this.invoiceService.UpdateInvoiceStatus(data, invoiceCondition).subscribe(res => { });
        this.creditnoteService.UpdateCreditStatus(data, creditnoteCondition).subscribe(res => { });
        // swal("Success!", "Gst status has been updated successfully.", "success");
        // this.getGstsList({});
      }
      // else{
      // swal("Error!", "Gst status cant be updated.", "error");
      // }
    });
    // }
    // });

  }

  downloadJson(row) {
    let filePath = "";

    filePath = this.file_path + row['file_path'] + "/" + row['file_name'];
    let data = new Blob([filePath], { type: 'application/json' });
  }
  open(gstContent, row) {
    this.selectedRow = row;
    this.modalService.open(gstContent, { size: "lg" });
  }

  generateGSTR1(data) {
    this.loading = true;
    let filters = {};
    let columns = {};
    this.filters['gst_date'] = {};
    this.filters['gst_date']['startDate'] = data.from_date;
    this.filters['gst_date']['endDate'] = data.to_date;
    filters['_id'] = data._id;
    filters['file_name'] = data.file_name;
    filters["gst"] = { from: this.filters['gst_date']['startDate'], to: this.filters['gst_date']['endDate'] };
    filters['company_id'] = this.company_id;
    this.gstservice.generateGSTR1(filters).subscribe((result: any) => {
      if (result["status_code"] == 1) {
        let countString = '';
        result.response.invoiceCount.forEach(element => {
          if (element._id == 4) {
            countString = countString + 'Cancelled-' + element.count
          } else if (element._id == 5) {
            countString = countString + 'Draft-' + element.count
          }
        });
        this.loading = false;
        swal("Success!", "Gst Generated Successfully.<br>" + countString, "success");
        this.router.navigateByUrl("/gst/list");
      } else {
        swal("Error!", "Gst Generation Unsuccessful.", "error");
      }
    });
  }

  reGenerateGSTR1(data) {
    this.loading = true;
    let filters = {};
    let columns = {};
    this.filters['gst_date'] = {};
    this.filters['gst_date']['startDate'] = data.from_date;
    this.filters['gst_date']['endDate'] = data.to_date;
    filters['_id'] = data._id;
    filters['file_name'] = data.file_name;
    filters['count'] = data.count;
    filters["gst"] = { from: this.filters['gst_date']['startDate'], to: this.filters['gst_date']['endDate'] };
    filters['company_id'] = this.company_id;
    this.gstservice.reGenerateGSTR1(filters).subscribe((result: any) => {
      if (result["status_code"] == 1) {
        let countString = '';
        result.response.invoiceCount.forEach(element => {
          if (element._id == 4) {
            countString = countString + 'Cancelled-' + element.count
          } else if (element._id == 5) {
            countString = countString + 'Draft-' + element.count
          }
        });
        this.loading = false;
        swal("Success!", "Gst ReGenerated Successfully.<br>" + countString, "success");
        // this.router.navigateByUrl("/gst/list");
        this.getGstsList({});
      } else {
        swal("Error!", "Gst ReGeneration Unsuccessful.", "error");
        this.loading = false;
      }
    });
  };


  updateStock() {
    this.invoiceService.updateStock().subscribe();
  }

}
