<div class="card">
  <div class="card-header" *ngIf="type=='composite_item'">
    <h4>Add Composite Product</h4>
  </div>
  <div class="card-header" *ngIf="type=='item'">
    <h4>Add Product</h4>
  </div>

  <form class="form-horizontal" (ngSubmit)="addProduct()" #addproductForm="ngForm" novalidate
    enctype="multipart/form-data">
    <div class="card-body">
      <div class="row clearfix">



        <!-- <div class="row col-12 mb-2">
          <div class="form-group col-12 custom-switch col-sm-6 col-md-3 col-xl-3 pl-5">
            <input type="checkbox" value="true" [(ngModel)]="is_sale"
              name="is_sale" class="custom-control-input" id="is_sale">
            <label class="custom-control-label" for="is_sale">Is Sale</label>
          </div>
        </div> -->
        <div class="form-row">
          <div class="row col-12 mb-4 ml-4">
            Goods<div class="form-group col-12 custom-switch col-sm-6 col-md-3 col-xl-3 pl-5">
              <input (change)="onChangeCompanyCategory()" type="checkbox" value="true" [(ngModel)]="is_service"
                name="product_type" class="custom-control-input" id="product_type">
              <label class="custom-control-label" for="product_type">Service</label>
            </div>
          </div>
        </div>
        <div class="form-row m-3">
          <div class="form-group  required col-12 col-sm-6 col-md-4 col-xl-4">
            <label for="name">{{is_service == false ? 'Product Group' : 'Service Group'}}</label>
            <ng-select name="group" #group="ngModel" required [loading]="loading" [addTag]="addNewGroup"
              [items]="groupList" bindLabel="group_name" [(ngModel)]="product.group" placeholder="Select Group">
            </ng-select>

            <app-validation-errors [mformField]="group" label="group">
            </app-validation-errors>

          </div>

          <div class="form-group  required col-12 col-sm-6 col-md-4 col-xl-4">
            <label for="name">{{is_service == false ? 'Product Name' : 'Service Name'}} </label>
            <input type="text" required class="form-control" placeholder="Product Name" id="name" name="name"
              #name="ngModel" [(ngModel)]="product.name">
            <app-validation-errors [mformField]="name" label="Product Name"></app-validation-errors>

          </div>

          <!-- <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="sku">Product SKU</label>
            <input type="text" required class="form-control" placeholder="Product sku" id="sku" name="sku"
              [(ngModel)]="product.sku" [disabled]="is_disable">
              <app-validation-errors [mformField]="name" label="Product SKU"></app-validation-errors>
          </div> -->
          <div *ngIf="is_service == false" class="form-group required col-12 col-sm-6 col-md-4 col-xl-4">
            <label for="unit">UOM</label>
            <ng-select name="product_unit" required #product_unit="ngModel" [items]="unitArray" bindLabel="name"
              [(ngModel)]="product.unit" placeholder="Select Unit">
            </ng-select>
            <app-validation-errors [mformField]="product_unit" label="Unit"></app-validation-errors>

          </div>
          <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3"> 
                        <label for="image">Product Image</label>
                        <input type="file"  name="image" id="image" (change)="onFileSelected($event)" >
                    </div> -->
          <div class="form-group required col-12 col-sm-12 col-md-12 col-xl-12">
            <!-- <div class="form-group required col-12 col-sm-6 col-md-3 col-xl-3"> -->
            <label for="hsn_code">Hsn Code / SAC Code</label>
            <ng-select class="hsn_select" required name="hsn_code" [items]="hsncodeArray" [typeahead]="hsnListTypeahead"
              bindLabel="code" [(ngModel)]="product.code" #hsn_code="ngModel" placeholder="Select hsn/Sac Code">
              <ng-template ng-label-tmp ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div [ngOptionHighlight]="search">
                  {{ item.code }} - {{ item.description }}
                </div>
              </ng-template>
            </ng-select>
            <app-validation-errors [mformField]="hsn_code" label="Hsn"></app-validation-errors>

            <!-- </div> -->
          </div>
          <div class="row col-12 mb-2">

            <div class="form-group col-12 custom-switch col-sm-6 col-md-3 col-xl-3 pl-5">
              <input type="checkbox" value="true" #is_sell="ngModel" [(ngModel)]="product.is_sell" name="is_sell"
                class="custom-control-input" id="is_sell">
              <label class="custom-control-label" for="is_sell">Sales</label>
            </div>
            <div class="form-group col-12 custom-switch col-sm-6 col-md-3 col-xl-3 pl-5">
              <input type="checkbox" value="true" #is_purchase="ngModel" [(ngModel)]="product.is_purchase"
                name="is_purchase" class="custom-control-input" id="is_purchase">
              <label class="custom-control-label" for="is_purchase">Purchase</label>
            </div>
            <div class="form-group col-12 custom-switch col-sm-6 col-md-3 col-xl-3 pl-5">
              <input type="checkbox" value="true" #is_expense="ngModel" [(ngModel)]="product.is_expense"
                name="is_expense" class="custom-control-input" id="is_expense">
              <label class="custom-control-label" for="is_expense">Non Inventory</label>
            </div>
          </div>
          <!-- <div *ngIf="is_service == false" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="product_stock">Opening Stock</label>
            <input type="number" class="form-control" placeholder="Product Stock" id="product_stock"
              name="product_stock" [(ngModel)]="product.product_stock">
          </div> -->
          <!-- <div *ngIf="is_service == false" class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="reorder_point">Reorder Level</label>
            <input type="number" class="form-control" placeholder="Product reorder level" id="reorder_point"
              name="reorder_point" [(ngModel)]="product.reorder_point">
          </div> -->
          <!-- <div class="form-group col-12 col-sm-6 col-md-3 col-xl-3">
            <label for="stock_rate_perunit">Opening Stock Rate/Unit</label>
            <input type="number" class="form-control" placeholder="Product stock rate" id="stock_rate_perunit"
              name="stock_rate_perunit" [(ngModel)]="product.stock_rate_perunit">
          </div> -->

          <!-- <div class="card" >
            <div class="card-body">
              <div class="row col-12">
                <div class="col-6  custom-switch pl-5" >
                  <input type="checkbox" value="purchase" [(ngModel)]="product.is_purchase" name="purchase"
                    class="custom-control-input" id="purchase">
                  <label class="custom-control-label" for="purchase">Buy</label>
                </div>
                <div class="col-6 custom-switch pl-4">
                  <input type="checkbox" value="sell" [(ngModel)]="product.is_sell" name="sell"
                    class="custom-control-input" id="sell">
                  <label class="custom-control-label" for="sell">Sell</label>
                </div>

              </div>
              <div class="row col-12">
                <div class="col-6" >
                  <div *ngIf="product.is_purchase">
                    <div class="form-group">
                      <label for="purchase_price">Cost Price</label>
                      <input type="number" required class="form-control" placeholder="Product purchase_price"
                        id="purchase_price" name="purchase_price" [(ngModel)]="product.purchase.purchase_price">
                    </div>
                    <div class="form-group">
                      <label for="purchase_head">Account</label>
                      <select id="purchase_head" required class="form-control" placeholder="purchase_head"
                        name="purchase_head" [(ngModel)]="product.purchase.purchase_head">
                        <option value="none">Select Purchase Head</option>
                        <option value="Travel cost">Travel cost</option>
                        <option value="Cost of goods">Cost of goods</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <textarea class="form-control" name="purchase_description"
                        [(ngModel)]="product.purchase.purchase_description"
                        placeholder="Purchase Description"></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div *ngIf="product.is_sell">
                    <div class="form-group">
                      <label for="selling_price">Selling Price</label>
                      <input type="number" required class="form-control" placeholder="Product selling_price"
                        id="selling_price" name="selling_price" [(ngModel)]="product.sales.selling_price">
                    </div>
                    <div class="form-group">
                      <label for="selling_head">Account</label>
                      <select id="selling_head" required class="form-control" placeholder="selling_head"
                        name="selling_head" [(ngModel)]="product.sales.selling_head">
                        <option value="none">Select Selling Head</option>
                        <option value="Sales">Sales</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <textarea class="form-control" name="selling_description"
                        [(ngModel)]="product.sales.selling_description" placeholder="Selling Description"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <!-- <div class="card" *ngIf="product.type!=productType.SERVICE">
            <div class="card-header">
              <div class="row col-12">
                
                <div class="col-6 custom-switch pl-5">
                  <input type="checkbox" class="custom-control-input" [(ngModel)]="product.is_track" name="is_track"
                    id="is_track">
                  <label class="custom-control-label" for="is_track">Inventory</label>

                </div>
              </div>
            </div>

          </div> -->
          <div class="card" *ngIf="type == 'composite_item' || is_bom">
            <div class="card-header">
              <h4>Composite Items</h4>
            </div>
            <div class="card-body">
              <div class="row"><button type="button" class="btn btn-primary" (click)="addNewItems()">Insert
                  Items</button></div>
              <div class="row clearfix">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Item Name</th>
                      <th>Quantity</th>
                      <!-- <th>Selling Price</th> -->
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let bom_items of bomArray; let i=index;">
                      <td>
                        <ng-select name="bom_items.product{{i}}" [items]="productArray" bindLabel="name"
                          [(ngModel)]="bom_items.product" placeholder="Select Product">
                        </ng-select>

                        <!-- <app-validation-errors [mformField]="group" label="group">
                      </app-validation-errors> -->

                        <!-- <select required class="form-control" placeholder="product" name="bom_items.product{{i}}"
                          [(ngModel)]="bom_items.product">
                          <option value="">Select Product</option>
                          <option *ngFor="let option of productArray; index as j" [ngValue]="option"> {{ option.name }}
                          </option>
                        </select> -->
                      </td>
                      <td>
                        <input type="number" required class="form-control" placeholder="Item Quantity" id="quantity"
                          name="bom_items.quantity{{i}}" [(ngModel)]="bom_items.quantity">
                      </td>
                      <!-- <td>
                        <input type="number" required class="form-control" placeholder="Item Selling Price"
                          id="item_selling_price" name="bom_items.item_selling_price{{i}}"
                          [(ngModel)]="bom_items.selling_price"></td> -->
                      <td><button type="button" class="btn btn-primary action-btn"
                          (click)="deleteBomItem(i)">Delete</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="card-footer">
      <button type="submit" [disabled]="!addproductForm.valid || loading==true" class="btn btn-primary">Save
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
      </button>
    </div>

  </form>
</div>