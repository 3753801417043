import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';
import { ConstantService } from '../../_config/constants';
import { AuthenticationService } from '../../_services/authentication.service';
import { BillService } from '../../_services/bill.service';
// import {COn}
// IMG_BASE_URL

@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.scss']
})
export class FileuploadComponent implements OnInit {
  modalReference: any;
  validUploadType = ["image/jpeg", "application/pdf"];
  files: File[] = [];

  fileuploadList: any = [];
  fileuploadListColumns: any = [];
  page: any = { offset: 0, pageSize: 10 };
  loadingIndicator = false;
  filters: any = {};
  reorderable = true;

  @ViewChild("actionTmpl", { static: true }) actionTmpl: TemplateRef<any>;
  imagePath: string;
  image_path: string;
  user_id: any;
  sort: any = { created_at: -1 };
  dt_default_sort = [{ prop: "created_at", dir: "desc" }];
  count: number;
  company_id: any;

  constructor(
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private billService: BillService,
    private router: Router,
    private constantService: ConstantService


  ) {

  }

  ngOnInit() {
    this.user_id = this.authenticationService.getUserRole();
    this.company_id = this.authenticationService.getDefaultCompanyId();

    this.getfileuploadList(this.page);
    this.getImageFromFileupload();
  }
  getImagePath(path) {
    this.billService.getImagePath(path);
  }
  getfileuploadList(page_info: any) {
    if (page_info != null) {
      this.page = page_info;
    }
    this.filters['company_id'] = this.company_id;
    this.billService.getFileuploads(this.filters, {'created_at':-1}, null, this.page).subscribe(result => {
      this.loadingIndicator = false;
      this.fileuploadListColumns = [

        // {
        //   prop: "image",
        //   name: "Image",
        //   sortable: "file_name",
        //   flexGrow: 1,
        //   show: true,
        //   is_detailed_row: false,
        //   type: "object",
        //   field_name: "file_name",
        // },

        {
          // prop: "files",
          name: "S.No",
          // sortable: "file_path",
          flexGrow: 1,
          show: true,
          is_detailed_row: false,
          type: "index",
        },
        {
          prop: "files",
          name: "Image",
          sortable: "file_path",
          flexGrow: 1,
          show: true,
          is_detailed_row: false,
          type: "file_path",
        },

        {
          prop: "created_at",
          name: "Created At",
          sortable: "created_at",
          flexGrow: 1,
          show: true,
          is_detailed_row: false,
          type: "date"
        },
        {
          prop: "bill_or_expense_number",
          name: "Bill / Expense Number",
          sortable: "bill_or_expense_number",
          flexGrow: 1,
          show: true,
          is_detailed_row: false,
          type: "bill_expense_number",
        },
        {
          prop: "is_bill_or_invoice",
          name: "Is Marked(Bill / Expense)",
          sortable: "file_path",
          flexGrow: 1,
          show: true,
          is_detailed_row: false,
          type: "status"
          // field_name: "file_path",

        },
        {
          name: "Action",
          type: "action",
          prop: "_id",
          flexGrow: 1,
          is_detailed_row: false,
          sortable: false,
        },

      ];
      this.fileuploadList = result["data"];

      this.count = result["count"];
    });
  }
  open(content) {
    this.modalReference = this.modalService.open(content);
  }

  onFileChange(fileChangeEvent) {
    Object.keys(fileChangeEvent.target.files).forEach(key => {

      const file: File = fileChangeEvent.target.files[key];
      console.log("===file===", file.type);
      if (this.validUploadType.includes(file.type)) {
        this.files.push(file);
      }
    });
  }
  onSort(event) {
    this.loadingIndicator = true;
    console.log("event", event);
    this.sort = {};
    let sort_details = event.sorts[0];
    this.sort[sort_details.prop] = sort_details.dir == "asc" ? 1 : -1;
    this.getfileuploadList(this.page);
  }
  uploadFile() {
    if (this.files.length > 0) {
      let data: any = {
        company_id: this.authenticationService.getDefaultCompanyId(),
        branch_id: this.authenticationService.getDefaultBranchId(),
      };

      this.billService.uploadFiles(this.files, data).subscribe((result) => {
        if (result["_id"] != "") {

          swal("Success", "Bills has been uploaded successfully", "success");
          this.router.navigate(["/bill/list"]);

          this.modalReference.close("content");

        }
      });
    } else {
      swal("Error", "No File Found To Upload", "error");
    }
  }

  getImageFromFileupload() {
    this.image_path = ConstantService.IMG_BASE_URL + '/';
  }

  deleteFileUpload(muid) {
    swal({
      title: "Are you sure want to delete?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result) {
        this.billService.deleteFileUpload(muid).subscribe((result) => {
          // console.log('result===============>', result);

          if (result['data']["ok"] === 1) {
            swal("Deleted!", "File has been deleted.", "success");
            this.getfileuploadList(this.page);
          }
        });
      }
    });
  }

}
