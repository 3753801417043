import { WarehouseContact } from './company.warehouse-contact.model';

export class Warehouse {
    name: String;
    billing_address: String;
    shipping_address: String;
    state: String;
    branch_name: any = {};
    contact_name: String;
    phone_number: String;
    email: String;
    designation: String;
}
